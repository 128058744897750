// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    container: {
      display: "inline-flex",
      backgroundColor: "#ff5645",
      padding: "16px",
      marginBottom: "16px"
    },
    icon: {
      color: Color.neutral[100],
      marginRight: "8px"
    },
    typography: {
      fontFamily: "roboto-medium",
      fontSize: "16px",
      color: Color.neutral[100]
    }
  });
});