// React lib
import React, { useContext } from 'react';

// Context
import { DeliveryContext } from './DeliveryConfiguration';

// Components
import ConfigurationTitle from '../ConfigurationTitle';
import ConfigurationOption from '../ConfigurationOption';

// Constants
import { OPTION_TYPE_RADIO, OPTION_TYPE_CHECKBOX } from '../../../Constants/OptionType';
import { ORDER_POSITION_COUNT, SETTINGS_FOR_ENTRY, VIA_SUPPLIERS, LIST_OF_SUPPLIERS, SELECTION_OF_ORDERS, VIA_ORDER_NUMBER, TYPE_OF_ORDER_INPUT, ENTRY_OPTION } from './DeliveryIds';

// Utils
import { deliveryLocalMapToDatabaseValue, arraysMatch } from "../../../Utils/Utils"

const SettingsForEntry = (props: any) => {
    const {
        defaultFunctionType,
        activeOption,
        setActiveOption,
        entryOption,
        handleChangeEntryOption,
        listOfSuppliers,
        handleChangeListOfSuppliers,
        selectionOfOrders,
        handleChangeSelectionOfOrders,
        orderPositionCount,
        handleChangeOrderPositionCount,
        typeOfOrderInput,
        handleChangeTypeOfOrderInput
    } = useContext(DeliveryContext);

    const entryOptionOption = {
        id: ENTRY_OPTION,
        heading: '',
        title: `Entry Option*`,
        subtitle: ' ',
        isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.entry, deliveryLocalMapToDatabaseValue("entry", entryOption) as string[])) ? true : false,
        optionType: OPTION_TYPE_CHECKBOX,
        allowEmpty: false,
        isValid: entryOption.length > 0 ? true : false,
        options: [
            {
                value: VIA_SUPPLIERS,
                onChange: handleChangeEntryOption,
                checked: entryOption.includes(VIA_SUPPLIERS),
                disabled: false,
                label: `Via Customers`,
                subLabel: `Option to enter the process via shortlist of customers`,
            },
            {
                value: VIA_ORDER_NUMBER,
                onChange: handleChangeEntryOption,
                checked: entryOption.includes(VIA_ORDER_NUMBER),
                disabled: false,
                label: `Via Order Number`,
                subLabel: `Option to enter the process via selection of an order`,
            }
        ],
        collapsedOption: []
    }

    const listOfSuppliersOption = {
        id: LIST_OF_SUPPLIERS,
        heading: 'ENTRY OPTION - VIA CUSTOMERS',
        title: `List Of Customers`,
        subtitle: `Defines which customers will be shown in the list`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.listOfSupplier !== deliveryLocalMapToDatabaseValue("listOfSupplier", listOfSuppliers)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                label: `All Customers`,
                value: `allSuppliers`,
                checked: listOfSuppliers === `allSuppliers`,
                onChange: handleChangeListOfSuppliers,
                disabled: false,
            },
            {
                label: `Only customers with open orders`,
                value: `onlySuppliersWithOpenOrders`,
                checked: listOfSuppliers === `onlySuppliersWithOpenOrders`,
                onChange: handleChangeListOfSuppliers,
                disabled: false,
            }
        ],
        collapsedOption: []
    }

    const selectionOfOrderOption = {
        id: SELECTION_OF_ORDERS,
        heading: 'ENTRY OPTION - VIA CUSTOMERS',
        title: `Selection of Orders`,
        subtitle: `Defines whether in the list of orders only the positions of one, several or all orders are shown. Also it defines whether it is possible to create a Delivery without order reference.`,
        isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.orderSelection, deliveryLocalMapToDatabaseValue("orderSelection", selectionOfOrders) as string[])) ? true : false,
        optionType: OPTION_TYPE_CHECKBOX,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                label: `All order positions`,
                value: `all`,
                checked: selectionOfOrders.includes("all"),
                onChange: handleChangeSelectionOfOrders,
                disabled: false
            },
            {
                label: `Order positions of several orders`,
                value: `multiple_order`,
                checked: selectionOfOrders.includes("multiple_order"),
                onChange: handleChangeSelectionOfOrders,
                disabled: false
            },
            {
                label: `Goods receipt without order reference`,
                value: `unrelated`,
                checked: selectionOfOrders.includes("unrelated"),
                onChange: handleChangeSelectionOfOrders,
                disabled: false
            },
        ],
        collapsedOption: []
    }

    const orderPositionCountOption = {
        id: ORDER_POSITION_COUNT,
        heading: '',
        title: `Order Position Count`,
        subtitle: `Show count of positions for each order in the order selection dialog`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.showOrderPositionCount !== deliveryLocalMapToDatabaseValue("showOrderPositionCount", orderPositionCount)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `show`,
                onChange: handleChangeOrderPositionCount,
                checked: orderPositionCount === `show`,
                disabled: false,
                label: `Show`,
                subLabel: ``
            },
            {
                value: `hide`,
                onChange: handleChangeOrderPositionCount,
                checked: orderPositionCount === `hide`,
                disabled: false,
                label: `Hide`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const typeOfOrderInputOption = {
        id: TYPE_OF_ORDER_INPUT,
        heading: 'ENTRY OPTION - VIA ORDER NUMBER',
        title: `Type of Order Input`,
        subtitle: `Defines which types of input are available for the user`,
        isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.docInputType, deliveryLocalMapToDatabaseValue("docInputType", typeOfOrderInput) as string[])) ? true : false,
        optionType: OPTION_TYPE_CHECKBOX,
        allowEmpty: false,
        isValid: typeOfOrderInput.length ? true : false,
        options: [
            {
                label: `Text input (manually via keypad)`,
                value: `textInput`,
                checked: typeOfOrderInput.includes("textInput"),
                onChange: handleChangeTypeOfOrderInput,
                disabled: false
            },
            {
                label: `Barcode scan`,
                value: `barcodeScan`,
                checked: typeOfOrderInput.includes("barcodeScan"),
                onChange: handleChangeTypeOfOrderInput,
                disabled: false
            },
            {
                label: `OCR scan (Unavailable)`,
                value: `OCR scan (Unavailable)`,
                disabled: true
            },
        ],
        collapsedOption: []
    }

    return (
        <>
            <ConfigurationOption configurationOption={entryOptionOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            {entryOption.includes(VIA_SUPPLIERS) && (
                <>
                    <ConfigurationOption configurationOption={listOfSuppliersOption} activeOption={activeOption} setActiveOption={setActiveOption} />
                    <ConfigurationOption configurationOption={selectionOfOrderOption} activeOption={activeOption} setActiveOption={setActiveOption} />
                    <ConfigurationOption configurationOption={orderPositionCountOption} activeOption={activeOption} setActiveOption={setActiveOption} />
                </>
            )}
            {entryOption.includes(VIA_ORDER_NUMBER) && (
                <ConfigurationOption configurationOption={typeOfOrderInputOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            )}
        </>
    );
};

export default SettingsForEntry;