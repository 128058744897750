import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LOGIN } from '../../Constants/FilePath';
import { AppContext } from '../../App';
const AdminRoute = ({ component: Component, ...rest }) => {
    const { currentUserData } = useContext(AppContext)

    return (
        <Route {...rest} render={props => {
            if (!currentUserData) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: LOGIN }} />
            }

            if (currentUserData.role !== 1) {
                return <Redirect to={{ pathname: '/' }} />
            }

            return <Component {...props} />
        }} />
    )
};

export default AdminRoute;