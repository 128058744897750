export const SETTINGS_FOR_INVENTORY_COUNTING = `settingsForInventoryCounting`

export const ITEM_SELECTION = `itemSelection`
export const USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM = `useOneBatchWithinOneStorageBinForAnItem`
export const BEST_BEFORE_DATE = `bestBeforeDate`
export const BEST_BEFORE_DATE_INPUT_FIELD = `bestBeforeDateInputField`
export const SHOW_EXPECTED_QUANTITY = `showExpectedQuantity`
export const SHOW_WAREHOUSE_MODUS = `showWarehouseModus`
export const ALLOW_ADD_INVENTORY = `allowAddInventory`
export const CONFIRM_NEW_ITEM_NEEDED = `confirmNewItemNeeded`
export const AUTOSELECT_COUNTING_LIST = `autoSelectCountingList`
export const CHOOSE_BIN_LOCATION_BY_LIST = `chooseBinLocationByList`
export const SHOW_EXPECTED_ITEMS = `showExpectedItems`
