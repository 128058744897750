// React Lib
import React from "react"; // Component Styles

import { useStyles } from "./UserRoleStyles"; // @otg-one/ui_components/components

import { Button, Typography } from "../index"; // @otg-one/ui_components/constants

import { Color, FONT_FAMILY } from "../../constants";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery'; // Utils

import { formatNumericalWithThousandSeparator } from "../../utils/Utils";
export var UserRole = function UserRole(props) {
  var classes = useStyles(props);
  var theme = useTheme();
  var isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  var userRoleCount = props.userRoleCount,
      onClick = props.onClick;
  return React.createElement("div", {
    className: classes.userRoleContainer
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
    size: isMobile ? 12 : 16,
    color: Color.neutral[100]
  }, "User Roles"), React.createElement("div", {
    className: classes.userRoleBox
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.CAPITOLIUM_BOLD,
    size: isMobile ? 24 : 56,
    color: Color.neutral[0]
  }, formatNumericalWithThousandSeparator(userRoleCount)), React.createElement(Typography, {
    fontFamily: FONT_FAMILY.CAPITOLIUM_ITALIC,
    size: 12,
    color: Color.neutral[0]
  }, "Roles Configured")), React.createElement(Button, {
    type: "secondary",
    color: "white",
    style: {
      marginTop: isMobile ? 0 : 40
    },
    onClick: onClick
  }, "See All"));
};
UserRole.defaultProps = {
  userRoleCount: 5,
  onClick: function onClick() {}
};
export default React.memo(UserRole);