// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    expandedUserRoleContainer: {
      boxSizing: "border-box",
      padding: 24,
      width: "100%",
      maxHeight: 480,
      backgroundColor: Color.primary[400]
    },
    expandedUserRoleExpandIcon: {
      position: "absolute",
      top: 16,
      right: 16,
      "& svg": {
        width: 24,
        height: 24,
        color: Color.neutral[100],
        transform: "rotate(180deg)"
      }
    },
    expandedUserRoleHeader: {
      marginTop: 16,
      display: "inline-flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "90%"
    },
    expandedUserRoleListContainer: {
      marginTop: 16,
      marginBottom: 30,
      maxHeight: 250,
      overflowY: "auto"
    },
    expandedUserRoleListAction: {
      "& svg": {
        width: 20,
        height: 20,
        color: Color.neutral[300]
      }
    },
    userRoleFunctionContainer: {
      height: 73,
      textAlign: "center"
    },
    userRoleFunction: {
      width: 80,
      position: "relative",
      "& svg": {
        width: 40,
        height: 40,
        color: Color.neutral[100]
      }
    },
    functionStatusContainer: {
      display: "inline-flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "90%"
    },
    functionStatus: {
      width: 80,
      textAlign: "center",
      color: Color.neutral[100],
      "& svg": {
        width: 20,
        height: 20,
        color: Color.neutral[100]
      }
    }
  });
});