// React lib
import React, { useState, createContext, useContext } from 'react';
import { AppContext } from '../../../App';

// Components
import ConfigurationContainer from '../ConfigurationContainer';
import SettingsForInventoryCounting from './SettingsForInventoryCounting';

import { FunctionInventoryCounting, EnterItemNumberPage, BatchesPage, InventoryCountingPositionDetailsPage, InventoryCountingItemSelectionPage, NoPreviewPage, WarehouseModus, NoPreview, InventoryCountingExpectedItems, InventoryCountingListAutoselect, InventoryCountingBinLocations } from '@otg-one/ui_components';
import { SETTINGS_FOR_INVENTORY_COUNTING, ITEM_SELECTION, USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM, BEST_BEFORE_DATE, BEST_BEFORE_DATE_INPUT_FIELD, SHOW_EXPECTED_QUANTITY, SHOW_WAREHOUSE_MODUS, ALLOW_ADD_INVENTORY, CONFIRM_NEW_ITEM_NEEDED, AUTOSELECT_COUNTING_LIST, CHOOSE_BIN_LOCATION_BY_LIST, SHOW_EXPECTED_ITEMS } from './InventoryCountingIds';

// Models
import { Stack } from '../../../Models/Stack';
import { InventoryCounting } from '../../../Models/InventoryCounting'

// Service
import ConfigurationService from "../ConfigurationService";

// Utils
import { inventoryCountingLocalMapToDatabaseValue, arraysMatch, arrayAdjustmentDefault, inventoryCountingDatabaseMapToLocalValue } from "../../../Utils/Utils"
import { STACK_TYPE_APP_FUNCTION } from '../../../Constants/StackType';

export const InventoryCountingContext = createContext<any>(null);
const InventoryCountingContextProvider = InventoryCountingContext.Provider;

// Interface
export interface IInventoryCountingContextInterface {
    defaultFunctionType: any,
    activeOption: string,
    setActiveOption: any,
    itemSelection: string[]
    handleChangeItemSelection: any,
    useOneBatchWithinOneStorageBinForAnItem: string,
    handleChangeUseOneBatchWithinOneStorageBinForAnItem: any,
    bestBeforeDate: string,
    handleChangeBestBeforeDate: any,
    bestBeforeDateInputField: string,
    handleChangeBestBeforeDateInputField: any,
    showExpectedQuantity: string,
    handleChangeShowExpectedQuantity: any,
    warehouseModus: string,
    handleChangeShowWarehouseModus: any,
    isAllowAddItem: string,
    handleChangeAllowAddInventory: any,
    isConfirmNewItem: string,
    handleChangeConfirmNewItem: any,
    isAutoselectCountingList: string,
    handleChangeSelectCountingList: any,
    isChooseLocationPrefilledList: string,
    handleChangeChangeBinLocation: any,
    isShowItemPrefilledList: string,
    handleChangeShowExpectedItems: any,
}

const InventoryCountingConfiguration = (props: any) => {
    const {
        breadcrumb,
        currentUserData,
        getCurrentBreadcrumbData,
        handleShowSnackbar,
        setSnackbarMessage
    } = useContext(AppContext);

    const [appData, setAppData] = useState(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION));
    const [defaultFunctionType, setDefaultFunctionType] = useState(null as any);

    const [isLoaded, setIsLoaded] = useState(false)
    const [isModified, setIsModified] = useState(false)
    const [listError, setListError] = useState([] as string[])

    const [activeOption, setActiveOption] = useState("")
    const [activeOptionName, setActiveOptionName] = useState("")

    const [activePreview, setActivePreview] = useState("" as any)

    const [itemSelection, setItemSelection] = useState(["textInput"] as string[])
    const [useOneBatchWithinOneStorageBinForAnItem, setUseOneBatchWithinOneStorageBinForAnItem] = useState(`disable`)
    const [bestBeforeDate, setBestBeforeDate] = useState("noBbd")
    const [bestBeforeDateInputField, setBestBeforeDateInputField] = useState("inputField")
    const [showExpectedQuantity, setShowExpectedQuantity] = useState('show');
    const [warehouseModus, setWarehouseModus] = useState('warehouseName');
    const [isAllowAddItem, setIsAllowAddItem] = useState('true');
    const [isConfirmNewItem, setIsConfirmNewItem] = useState('false');
    const [isAutoselectCountingList, setIsAutoselectCountingList] = useState('true');
    const [isChooseLocationPrefilledList, setIsChooseLocationPrefilledList] = useState('true');
    const [isShowItemPrefilledList, setIsShowItemPrefilledList] = useState('false');

    const loadDefault = async () => {
        const response = await ConfigurationService.getFunctionType(currentUserData.key, appData.functionTypeId)
        setDefaultFunctionType(response.data);
    }

    const setConfiguration = (masterData: any) => {
        if (masterData.config) {
            Object.keys(masterData.config).map((key: any, index: any) => {
                if (key === "selectWarehouseInput") {
                    setItemSelection(inventoryCountingDatabaseMapToLocalValue("selectWarehouseInput", masterData.config[key]));
                } else if (key === "allowOneBatchForOneBinLoc") {
                    setUseOneBatchWithinOneStorageBinForAnItem(inventoryCountingDatabaseMapToLocalValue("allowOneBatchForOneBinLoc", masterData.config[key]))
                } else if (key === "bestBeforeDate") {
                    setBestBeforeDate(inventoryCountingDatabaseMapToLocalValue("bestBeforeDate", masterData.config[key]))
                } else if (key === "bestBeforeInputType") {
                    setBestBeforeDateInputField(inventoryCountingDatabaseMapToLocalValue("bestBeforeInputType", masterData.config[key]))
                } else if (key === "showExpectedQuantity") {
                    setShowExpectedQuantity(inventoryCountingDatabaseMapToLocalValue("showExpectedQuantity", masterData.config[key]))
                } else if (key === "warehouseLayout") {
                    setWarehouseModus(inventoryCountingDatabaseMapToLocalValue("warehouseLayout", masterData.config[key]))
                } else if (key === "allowAddInventory") {
                    setIsAllowAddItem(inventoryCountingDatabaseMapToLocalValue("allowAddInventory", masterData.config[key]))
                } else if (key === "confirmNewItemNeeded") {
                    setIsConfirmNewItem(inventoryCountingDatabaseMapToLocalValue("confirmNewItemNeeded", masterData.config[key]))
                } else if (key === "selectInventoryListIfOnlyOneAvailable") {
                    setIsAutoselectCountingList(inventoryCountingDatabaseMapToLocalValue("selectInventoryListIfOnlyOneAvailable", masterData.config[key]))
                } else if (key === "chooseBinLocByList") {
                    setIsChooseLocationPrefilledList(inventoryCountingDatabaseMapToLocalValue("chooseBinLocByList", masterData.config[key]))
                } else if (key === "showExpectedItems") {
                    setIsShowItemPrefilledList(inventoryCountingDatabaseMapToLocalValue("showExpectedItems", masterData.config[key]))
                }
            });
        }
    };

    React.useEffect(() => {
        setAppData(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION));
    }, [breadcrumb]);

    React.useEffect(() => {
        if (Object.keys(appData).length !== 0) {
            loadDefault();

            new Promise((resolve, reject) => {
                setConfiguration(appData);

                resolve(true)
            }).then(res => {
                setIsLoaded(true);
            })
        }
    }, [appData]);

    const InventoryCountingMenus = [
        {
            id: SETTINGS_FOR_INVENTORY_COUNTING,
            label: "",
            isCustomized: false,
            submenus: [
                {
                    id: ITEM_SELECTION,
                    label: "Item Selection",
                    isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.selectWarehouseInput, inventoryCountingLocalMapToDatabaseValue("selectWarehouseInput", itemSelection) as string[])) ? true : false,
                    submenus: []
                },
                {
                    id: USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM,
                    label: "Use One Batch Within One Storage Bin For an Item",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.allowOneBatchForOneBinLoc !== inventoryCountingLocalMapToDatabaseValue("allowOneBatchForOneBinLoc", useOneBatchWithinOneStorageBinForAnItem)) ? true : false,
                    submenus: []
                },
                {
                    id: BEST_BEFORE_DATE,
                    label: "Best Before Date",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.bestBeforeDate !== inventoryCountingLocalMapToDatabaseValue("bestBeforeDate", bestBeforeDate)) ? true : false,
                    submenus: []
                },
                {
                    id: BEST_BEFORE_DATE_INPUT_FIELD,
                    label: "Best Before Date Input Field",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.bestBeforeInputType !== inventoryCountingLocalMapToDatabaseValue("bestBeforeInputType", bestBeforeDateInputField)) ? true : false,
                    submenus: []
                },
                {
                    id: SHOW_EXPECTED_QUANTITY,
                    label: "Show Expected Quantity",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.showExpectedQuantity !== inventoryCountingLocalMapToDatabaseValue("showExpectedQuantity", showExpectedQuantity)) ? true : false,
                    submenus: []
                },
                {
                    id: SHOW_WAREHOUSE_MODUS,
                    label: "Show Warehouse Name",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.warehouseLayout !== inventoryCountingLocalMapToDatabaseValue("warehouseLayout", warehouseModus)) ? true : false,
                    submenus: []
                },
                {
                    id: ALLOW_ADD_INVENTORY,
                    label: "Allow Add Inventory",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.allowAddInventory !== inventoryCountingLocalMapToDatabaseValue("allowAddInventory", isAllowAddItem)) ? true : false,
                    renderSubmenu: isAllowAddItem === 'true',
                    submenus: [
                        {
                            id: CONFIRM_NEW_ITEM_NEEDED,
                            label: "Confirm New Item Needed",
                            isCustomized: (defaultFunctionType && defaultFunctionType.config.confirmNewItemNeeded !== inventoryCountingLocalMapToDatabaseValue("confirmNewItemNeeded", isConfirmNewItem)) ? true : false,
                            isShown: isAllowAddItem === 'true',
                        },
                    ]
                },
                {
                    id: AUTOSELECT_COUNTING_LIST,
                    label: "Autoselect Counting List",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.selectInventoryListIfOnlyOneAvailable !== inventoryCountingLocalMapToDatabaseValue("selectInventoryListIfOnlyOneAvailable", isAutoselectCountingList)) ? true : false,
                    submenus: []
                },
                {
                    id: CHOOSE_BIN_LOCATION_BY_LIST,
                    label: "Choose Bin Location By List",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.chooseBinLocByList !== inventoryCountingLocalMapToDatabaseValue("chooseBinLocByList", isChooseLocationPrefilledList)) ? true : false,
                    submenus: []
                },
                {
                    id: SHOW_EXPECTED_ITEMS,
                    label: "Show Expected Items",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.showExpectedItems !== inventoryCountingLocalMapToDatabaseValue("showExpectedItems", isShowItemPrefilledList)) ? true : false,
                    submenus: []
                }
            ]
        }
    ]

    const handleClickSaveConfiguration = () => {
        const tempAppData = { ...appData };

        //to avoid different position number in the object - for customized in system dashboard page
        tempAppData.config = {}

        Object.keys(defaultFunctionType.config).map((key: any) => {
            if (key === "selectWarehouseInput") {
                tempAppData.config.selectWarehouseInput = arrayAdjustmentDefault(inventoryCountingLocalMapToDatabaseValue("selectWarehouseInput", itemSelection), defaultFunctionType.config.selectWarehouseInput);
            } else if (key === "allowOneBatchForOneBinLoc") {
                tempAppData.config.allowOneBatchForOneBinLoc = inventoryCountingLocalMapToDatabaseValue("allowOneBatchForOneBinLoc", useOneBatchWithinOneStorageBinForAnItem);
            } else if (key === "bestBeforeDate") {
                tempAppData.config.bestBeforeDate = inventoryCountingLocalMapToDatabaseValue("bestBeforeDate", bestBeforeDate);
            } else if (key === "bestBeforeInputType") {
                tempAppData.config.bestBeforeInputType = inventoryCountingLocalMapToDatabaseValue("bestBeforeInputType", bestBeforeDateInputField);
            } else if (key === "showExpectedQuantity") {
                tempAppData.config.showExpectedQuantity = inventoryCountingLocalMapToDatabaseValue("showExpectedQuantity", showExpectedQuantity);
            } else if (key === "warehouseLayout") {
                tempAppData.config.warehouseLayout = inventoryCountingLocalMapToDatabaseValue("warehouseLayout", warehouseModus);
            } else if (key === "allowAddInventory") {
                tempAppData.config.allowAddInventory = inventoryCountingLocalMapToDatabaseValue("allowAddInventory", isAllowAddItem);
            } else if (key === "confirmNewItemNeeded") {
                tempAppData.config.confirmNewItemNeeded = inventoryCountingLocalMapToDatabaseValue("confirmNewItemNeeded", isConfirmNewItem);
            } else if (key === "selectInventoryListIfOnlyOneAvailable") {
                tempAppData.config.selectInventoryListIfOnlyOneAvailable = inventoryCountingLocalMapToDatabaseValue("selectInventoryListIfOnlyOneAvailable", isAutoselectCountingList);
            } else if (key === "chooseBinLocByList") {
                tempAppData.config.chooseBinLocByList = inventoryCountingLocalMapToDatabaseValue("chooseBinLocByList", isChooseLocationPrefilledList);
            } else if (key === "showExpectedItems") {
                tempAppData.config.showExpectedItems = inventoryCountingLocalMapToDatabaseValue("showExpectedItems", isShowItemPrefilledList);
            } else {
                tempAppData.config[key] = defaultFunctionType.config[key];
            }
        })

        return ConfigurationService.saveConfiguration(currentUserData.key, tempAppData.key, tempAppData).then((res: any) => {
            setIsModified(false);
            setSnackbarMessage(`${breadcrumb[breadcrumb.length - 2].data.name} - ${appData.name} Configuration has been changed`)
            handleShowSnackbar();
        });
    }

    React.useEffect(() => {
        if (isLoaded) {
            setIsModified(true);
        }
    }, [itemSelection, useOneBatchWithinOneStorageBinForAnItem, bestBeforeDate, bestBeforeDateInputField, showExpectedQuantity, warehouseModus, isAllowAddItem, isConfirmNewItem, isAutoselectCountingList, isChooseLocationPrefilledList, isShowItemPrefilledList]);

    React.useEffect(() => {
        if (isLoaded) {
            if (itemSelection.length <= 0) {
                setListError([...listError, ITEM_SELECTION]);
            } else {
                setListError(listError.filter((event) => (event !== ITEM_SELECTION)));
            }
        }
    }, [itemSelection]);

    const handleChangeItemSelection = (e: any) => {
        if (itemSelection.includes(e.target.value)) {
            setItemSelection(itemSelection.filter((event) => (event !== e.target.value)));
        } else {
            setItemSelection([...itemSelection, e.target.value]);
        }
    }

    const handleChangeUseOneBatchWithinOneStorageBinForAnItem = (e: any) => {
        setUseOneBatchWithinOneStorageBinForAnItem(e.target.value)
    }

    const handleChangeBestBeforeDate = (e: any) => {
        setBestBeforeDate(e.target.value)
    }

    const handleChangeBestBeforeDateInputField = (e: any) => {
        setBestBeforeDateInputField(e.target.value)
    }

    const handleChangeShowExpectedQuantity = (e: any) => {
        setShowExpectedQuantity(e.target.value)
    }

    const handleChangeShowWarehouseModus = (e: any) => {
        setWarehouseModus(e.target.value)
    }

    const handleChangeAllowAddInventory = (e: any) => {
        setIsAllowAddItem(e.target.value)
    }

    const handleChangeConfirmNewItem = (e: any) => {
        setIsConfirmNewItem(e.target.value)
    }

    const handleChangeSelectCountingList = (e: any) => {
        setIsAutoselectCountingList(e.target.value)
    }

    const handleChangeChangeBinLocation = (e: any) => {
        setIsChooseLocationPrefilledList(e.target.value)
    }

    const handleChangeShowExpectedItems = (e: any) => {
        setIsShowItemPrefilledList(e.target.value)
    }

    const handleChangeActiveOption = (id: string) => {
        let element = document.getElementById(id)

        if (element) {
            const bounding = element.getBoundingClientRect()

            if (
                bounding.top >= 0 &&
                bounding.top <= 400 &&
                bounding.left >= 0 &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            ) {
                if (id === SETTINGS_FOR_INVENTORY_COUNTING) {
                    setActiveOption(ITEM_SELECTION)
                }
                else {
                    setActiveOption(id)
                }
            }
        }
    }

    const handleActivePreview = (id: string) => {

        const inventoryCountingDetailPosition = {
            item: "LM4029PH",
            description: "LeMon 4029 Printhead",
            warehouseCode: "02",
            warehouseName: "WH Reserve",
            quantity: 0,
            quantityType: "Palette(n)",
            unit: "Palette(n)"
        }

        const warehouseData =
            [
                {
                    warehouseCode: "01",
                    warehouseName: "WH General"
                },
            ];

        const autoSelectCountingListData =
            [
                {
                    number: "30",
                    date: "2022-01-13",
                    type: "person"
                },
            ];

        const binLocationData =
            [
                {
                    binLocation: "01SF SDF",
                },
                {
                    binLocation: "01SF SDF",
                },
                {
                    binLocation: "01SF SDF",
                },
                {
                    binLocation: "01SF SDF",
                },
            ];

        const ExpectedItemsData =
            [
                {
                    itemName: "01SF SDF",
                    currentQuantity: 5,
                    expectedQuantity: 600
                },
                {
                    itemName: "01SF SDF",
                    currentQuantity: 5,
                    expectedQuantity: 300
                },
                {
                    itemName: "01SF SDF",
                    currentQuantity: 5,
                    expectedQuantity: 200
                },
                {
                    itemName: "01SF SDF",
                    currentQuantity: 5,
                    expectedQuantity: 100
                },
                {
                    itemName: "01SF SDF",
                    currentQuantity: 5,
                    expectedQuantity: 60
                },
            ];

        switch (id) {
            case ITEM_SELECTION:
                if (itemSelection.length > 0) {
                    return setActivePreview(<InventoryCountingItemSelectionPage inputTypes={itemSelection} previewHeight={568} previewWidth={320} />);
                } else {
                    return setActivePreview(<NoPreviewPage type={"emptyValue"} previewHeight={568} previewWidth={320} />);
                }
            case USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM:
                if (useOneBatchWithinOneStorageBinForAnItem) {
                    return setActivePreview(<InventoryCountingPositionDetailsPage data={inventoryCountingDetailPosition} bbdInputFieldType={bestBeforeDateInputField} bbdType={bestBeforeDate} showExpectedQuantity={showExpectedQuantity} previewHeight={568} previewWidth={320} />)
                } else {
                    return setActivePreview(<BatchesPage oneBatchOnly={true} totalBatches={8} bbdInputFieldType={"inputField"} bbdType={"noBbd"} data={[]} previewHeight={568} previewWidth={320} />)
                }
            case BEST_BEFORE_DATE:
                return setActivePreview(<InventoryCountingPositionDetailsPage data={inventoryCountingDetailPosition} bbdInputFieldType={bestBeforeDateInputField} bbdType={bestBeforeDate} showExpectedQuantity={showExpectedQuantity} previewHeight={568} previewWidth={320} />);
            case BEST_BEFORE_DATE_INPUT_FIELD:
                return setActivePreview(<InventoryCountingPositionDetailsPage data={inventoryCountingDetailPosition} bbdInputFieldType={bestBeforeDateInputField} bbdType={bestBeforeDate} showExpectedQuantity={showExpectedQuantity} previewHeight={568} previewWidth={320} />);
            case SHOW_EXPECTED_QUANTITY:
                return setActivePreview(<InventoryCountingPositionDetailsPage data={inventoryCountingDetailPosition} bbdInputFieldType={bestBeforeDateInputField} bbdType={bestBeforeDate} showExpectedQuantity={showExpectedQuantity} previewHeight={568} previewWidth={320} />);
            case SHOW_WAREHOUSE_MODUS:
                return setActivePreview(<WarehouseModus data={warehouseData} warehouseLayout={warehouseModus} previewHeight={568} previewWidth={320} />);
            case ALLOW_ADD_INVENTORY:
                return setActivePreview(<NoPreview type={"emptyValue"} previewHeight={568} previewWidth={320} />);
            case CONFIRM_NEW_ITEM_NEEDED:
                return setActivePreview(<InventoryCountingExpectedItems data={ExpectedItemsData} isListPrefilled={JSON.parse(isShowItemPrefilledList)} isConfirmNeeded={JSON.parse(isConfirmNewItem)} previewHeight={568} previewWidth={320} />);
            case AUTOSELECT_COUNTING_LIST:
                if (JSON.parse(isAutoselectCountingList)) {
                    return setActivePreview(<NoPreviewPage type={"emptyValue"} previewHeight={568} previewWidth={320} />);
                } else {
                    return setActivePreview(<InventoryCountingListAutoselect data={autoSelectCountingListData} previewHeight={568} previewWidth={320} />);
                }
            case CHOOSE_BIN_LOCATION_BY_LIST:
                return setActivePreview(<InventoryCountingBinLocations data={binLocationData} isListPrefilled={JSON.parse(isChooseLocationPrefilledList)} previewHeight={568} previewWidth={320} />);
            case SHOW_EXPECTED_ITEMS:
                return setActivePreview(<InventoryCountingExpectedItems data={ExpectedItemsData} isListPrefilled={JSON.parse(isShowItemPrefilledList)} isConfirmNeeded={false} previewHeight={568} previewWidth={320} />);
            default:
                return setActivePreview(id);
        }
    }

    React.useEffect(() => {
        handleActivePreview(activeOption);

        InventoryCountingMenus.forEach(menu => {
            menu.submenus.forEach((subMenu: any) => {
                if (subMenu.id === activeOption) {
                    setActiveOptionName(subMenu.label)
                }

                subMenu.submenus.forEach((thirdSubmenu: any) => {
                    if (thirdSubmenu.id === activeOption) {
                        setActiveOptionName(thirdSubmenu.label)
                    };
                })
            });
        })

    }, [activeOption, itemSelection, useOneBatchWithinOneStorageBinForAnItem, bestBeforeDate, bestBeforeDateInputField, showExpectedQuantity, warehouseModus, isAllowAddItem, isConfirmNewItem, isAutoselectCountingList, isChooseLocationPrefilledList, isShowItemPrefilledList]);

    const viewPortCallback = () => {
        handleChangeActiveOption(SETTINGS_FOR_INVENTORY_COUNTING)
        handleChangeActiveOption(ITEM_SELECTION)
        handleChangeActiveOption(USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM)
        handleChangeActiveOption(BEST_BEFORE_DATE)
        handleChangeActiveOption(BEST_BEFORE_DATE_INPUT_FIELD)
        handleChangeActiveOption(SHOW_EXPECTED_QUANTITY)
        handleChangeActiveOption(SHOW_WAREHOUSE_MODUS)
        handleChangeActiveOption(ALLOW_ADD_INVENTORY)
        handleChangeActiveOption(CONFIRM_NEW_ITEM_NEEDED)
        handleChangeActiveOption(AUTOSELECT_COUNTING_LIST)
        handleChangeActiveOption(CHOOSE_BIN_LOCATION_BY_LIST)
        handleChangeActiveOption(SHOW_EXPECTED_ITEMS)
    }

    const CountingContextValue: IInventoryCountingContextInterface = {
        defaultFunctionType: defaultFunctionType,
        activeOption: activeOption,
        setActiveOption: handleChangeActiveOption,

        itemSelection,
        handleChangeItemSelection,
        useOneBatchWithinOneStorageBinForAnItem,
        handleChangeUseOneBatchWithinOneStorageBinForAnItem,
        bestBeforeDate: bestBeforeDate,
        handleChangeBestBeforeDate: handleChangeBestBeforeDate,
        bestBeforeDateInputField: bestBeforeDateInputField,
        handleChangeBestBeforeDateInputField: handleChangeBestBeforeDateInputField,
        showExpectedQuantity: showExpectedQuantity,
        handleChangeShowExpectedQuantity: handleChangeShowExpectedQuantity,
        warehouseModus: warehouseModus,
        handleChangeShowWarehouseModus: handleChangeShowWarehouseModus,
        isAllowAddItem: isAllowAddItem,
        handleChangeAllowAddInventory: handleChangeAllowAddInventory,
        isConfirmNewItem: isConfirmNewItem,
        handleChangeConfirmNewItem: handleChangeConfirmNewItem,
        isAutoselectCountingList: isAutoselectCountingList,
        handleChangeSelectCountingList: handleChangeSelectCountingList,
        isChooseLocationPrefilledList: isChooseLocationPrefilledList,
        handleChangeChangeBinLocation: handleChangeChangeBinLocation,
        isShowItemPrefilledList: isShowItemPrefilledList,
        handleChangeShowExpectedItems: handleChangeShowExpectedItems,
    };

    return (

        <ConfigurationContainer
            detectViewportCallback={viewPortCallback}
            activeViewport={activePreview}
            activeOption={activeOption}
            activeOptionName={activeOptionName}
            title={`Inventory Counting`}
            icon={<FunctionInventoryCounting />}
            sidebarMenus={InventoryCountingMenus}
            saveConfiguration={handleClickSaveConfiguration}
            setIsModified={setIsModified}
            isModified={isModified}
            setListError={setListError}
            listError={listError}
            setConfigurationToDefault={() => setConfiguration(defaultFunctionType)}
            setConfigurationToPreviousSetting={() => setConfiguration(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION))}
        >
            <InventoryCountingContextProvider value={CountingContextValue}>
                <SettingsForInventoryCounting />
            </InventoryCountingContextProvider>
        </ConfigurationContainer>

    )
};

export default InventoryCountingConfiguration;