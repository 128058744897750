import axios from 'axios';

import { getApiUrl } from '../../../Constants/Api';


export class PartnerService {

    getTotalCost(userKey: string) {
        return axios.get(getApiUrl() + "partners/" + userKey + "/totalcosts");
    }

    getSystemLog(userKey: string, systemKey?: string) {
        if (systemKey) {
            return axios.get(getApiUrl() + "devicelogs?userkey=" + userKey + "&systemkey=" + systemKey);
        } else {
            return axios.get(getApiUrl() + "devicelogs?userkey=" + userKey);
        }
    }

    getClientsCostAndLogin(userKey: string, year: number = -1) {
        return axios.get(getApiUrl() + "partners/" + userKey + "/clientscostandlogin?year=" + year);
    }

    getClients(userKey: string) {
        return axios.get(getApiUrl() + "clients?userkey=" + userKey);
    }

    deleteClient(userKey: string, clientKey: string) {
        return axios.delete(getApiUrl() + "clients/" + clientKey + "?userkey=" + userKey);
    }

    updatePartner(currentUserData: any, companyName: string, contactName: string, contactMail: string, contactPhone: string, contactAddress: string) {
        let formData = new FormData();
        // new organization detail
        formData.append("companyName", companyName);
        formData.append("contactName", contactName);
        formData.append("contactMail", contactMail);
        formData.append("contactPhone", contactPhone);
        formData.append("contactAddress", contactAddress);
        // existing billing detail
        formData.append("billingOrgName", currentUserData.billingOrgName);
        formData.append("billingContactName", currentUserData.billingContactName);
        formData.append("billingContactMail", currentUserData.billingContactMail);
        return axios.put(getApiUrl() + "partners/" + currentUserData.partner_id + "?userkey=" + currentUserData.key, formData);
    }

    updateBillingDetail(currentUserData: any, billingOrgName: string, billingContactName: string, billingContactMail: string) {
        let formData = new FormData();
        // new organization detail
        formData.append("companyName", currentUserData.companyName);
        formData.append("contactName", currentUserData.companyContactName);
        formData.append("contactMail", currentUserData.companyContactMail);
        formData.append("contactPhone", currentUserData.companyContactPhone);
        formData.append("contactAddress", currentUserData.companyContactAddress);
        // existing billing detail
        formData.append("billingOrgName", billingOrgName);
        formData.append("billingContactName", billingContactName);
        formData.append("billingContactMail", billingContactMail);
        return axios.put(getApiUrl() + "partners/" + currentUserData.partner_id + "?userkey=" + currentUserData.key, formData);
    }

}

export default new PartnerService();