// React libraries
import * as React from 'react'; // Custom Styles

import { useStyles } from "./ListPageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { BlueGradientHeader } from "../../index";
import { ListModal } from "../../index";
import { PageWrapper } from "../../index";
export var ListPage = function ListPage(props) {
  var classes = useStyles(props);
  var isModalOpen = props.isModalOpen,
      headerTitle = props.headerTitle,
      listModalTitle = props.listModalTitle,
      data = props.data,
      previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      listTypes = props.listTypes;
  return React.createElement(PageWrapper, {
    previewWidth: previewWidth,
    previewHeight: previewHeight
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "big",
    showScanner: false,
    previewHeight: previewHeight
  }, React.createElement("p", null, headerTitle))), React.createElement(ListModal, {
    listTypes: listTypes,
    data: data,
    isModalOpen: isModalOpen,
    title: listModalTitle
  })));
};
export default React.memo(ListPage);