import _toConsumableArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import * as React from 'react'; // Custom Styles

import { useStyles } from "./ListSelectionModalStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Modal } from "../../index";
import { GreyButton } from "../../index";
export var ListSelectionModal = function ListSelectionModal(props) {
  var classes = useStyles(props);

  var _React$useState = React.useState([]),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      selected = _React$useState2[0],
      setSelected = _React$useState2[1];

  var isModalOpen = props.isModalOpen,
      title = props.title,
      dataTitle = props.dataTitle,
      listTypes = props.listTypes,
      data = props.data,
      showCount = props.showCount;

  var handleSelectedChange = function handleSelectedChange(value) {
    return function (e) {
      var tempSelected = _toConsumableArray(selected);

      if (listTypes.includes("multiple_order")) {
        if (tempSelected.includes(value)) {
          var index = tempSelected.indexOf(value);

          if (index > -1) {
            tempSelected.splice(index, 1);
          }
        } else if (value == "all_orders" || value == "add_order") {
          tempSelected = [];
          tempSelected.push(value);
        } else {
          if (tempSelected.includes("all_orders") || tempSelected.includes("add_order")) {
            tempSelected = [];
          }

          tempSelected.push(value);
        }

        setSelected(tempSelected);
      }
    };
  };

  var modalWidth = 285;
  var modalHeight = 350; // Reset Data On Option Change

  React.useEffect(function () {
    setSelected([]);
  }, [listTypes]);
  return React.createElement(Modal, {
    isModalOpen: isModalOpen,
    width: modalWidth,
    height: modalHeight
  }, React.createElement("div", {
    className: classes.floatingDiv
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.floatingDivBarTop
  }, React.createElement("p", null, title)), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.floatingDivBarTitle
  }, React.createElement("p", null, dataTitle)), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.floatingDivContentWithTitle,
    style: {
      height: "calc(".concat(modalHeight, "px - 50px - 50px - 40px)")
    }
  }, listTypes.includes("all") && React.createElement(Button, {
    className: classes.floatingDivContentButton + " " + (selected.includes("all_orders") ? classes.floatingDivContentButtonSelected : ""),
    value: "all_orders",
    onClick: handleSelectedChange("all_orders")
  }, "All Orders"), data.map(function (data) {
    return React.createElement(Button, {
      className: classes.floatingDivContentButton + " " + (selected.includes(data.name) ? classes.floatingDivContentButtonSelected : ""),
      value: data.name,
      onClick: handleSelectedChange(data.name)
    }, data.name, " ", showCount ? "(" + data.count + ")" : "");
  }), listTypes.includes("unrelated") && React.createElement(Button, {
    className: classes.floatingDivContentButton + " " + (selected.includes("add_order") ? classes.floatingDivContentButtonSelected : ""),
    value: "add_order",
    onClick: handleSelectedChange("add_order")
  }, "Add an Order")), listTypes.includes("multiple_order") ? React.createElement(React.Fragment, null, React.createElement(Grid, {
    item: true,
    md: 6,
    className: classes.floatingDivBarBottom
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "left"
  }, "Cancel")), React.createElement(Grid, {
    item: true,
    md: 6,
    className: classes.floatingDivBarBottom
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "right",
    selected: !(selected.length < 1),
    disabled: selected.length < 1
  }, "Confirm"))) : React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.floatingDivBarBottom
  }, React.createElement(GreyButton, {
    onClick: function onClick() {}
  }, "Cancel")))));
};
ListSelectionModal.defaultProps = {
  selectFunction: function selectFunction() {},
  setOpenModalState: function setOpenModalState() {}
};
export default React.memo(ListSelectionModal);