import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./PickPackIgnoreItemsPageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { ScanContentBar } from "../ScanContentBar";
import EditIcon from '@material-ui/icons/Edit';
export var PickPackIgnoreItemsPage = function PickPackIgnoreItemsPage(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      data = props.data,
      scanContentBarText = props.scanContentBarText;

  var _React$useState = React.useState(data),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      listData = _React$useState2[0],
      setListData = _React$useState2[1];

  React.useEffect(function () {
    setListData(data);
  }, [data]);
  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "smallWithSub",
    showScanner: true,
    previewHeight: previewHeight
  }, React.createElement("p", null, "Pick and Pack"), React.createElement("sub", null, "Bin Location - ", React.createElement("b", null, "05-G01-R01-P02")))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentTopRadius,
    style: {
      height: previewHeight - 90 - 50 + 10
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(ScanContentBar, {
    text: scanContentBarText
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.listOrderItemsDiv,
    style: {
      height: previewHeight - 90 - 50 - 40 + 10
    }
  }, React.createElement(Grid, {
    container: true,
    className: classes.listOrderItemsContainer
  }, listData.map(function (pickPackItem) {
    return React.createElement(Button, {
      className: classes.listOrderItemsButton
    }, React.createElement(Grid, {
      item: true,
      md: 12
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      item: true,
      md: 10
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      item: true,
      md: 12
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 6
    }, "Item"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 6
    }, "Batch"))), React.createElement(Grid, {
      item: true,
      md: 12
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 6
    }, pickPackItem.item), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 6
    }, pickPackItem.batch))), React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12
    }, "Description"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, pickPackItem.description))), React.createElement(Grid, {
      container: true,
      item: true,
      md: 2,
      alignItems: "center",
      justify: "flex-end",
      style: {
        color: "#2d9dd5"
      }
    }, React.createElement(EditIcon, null)))));
  }))))), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "left"
  }, "Back")), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "right"
  }, "Next"))));
};
PickPackIgnoreItemsPage.defaultProps = {
  scanContentBarText: "Scan Item"
};
export default React.memo(PickPackIgnoreItemsPage);