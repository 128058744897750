import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// React libraries
import * as React from 'react';
import classnames from 'classnames'; // Custom Styles

import { useStyles } from "./BlueGradientHeaderStyles"; // Icon

import Barcode from "../../../icons/Barcode/Barcode";
export var BlueGradientHeader = function BlueGradientHeader(props) {
  var _classnames;

  var classes = useStyles(props);
  var height;
  var type = props.type,
      previewHeight = props.previewHeight,
      showScanner = props.showScanner;
  var blueGradientHeaderClasses = classnames((_classnames = {}, _defineProperty(_classnames, classes.blueGradientHeaderSmall, type === "small" || type === "smallWithSub"), _defineProperty(_classnames, classes.blueGradientHeaderBig, type === "big"), _classnames));

  if (type === "small") {
    height = "75px";
  } else if (type === "smallWithSub") {
    height = "90px";
  } else {
    height = previewHeight * 50 / 100;
  }

  return React.createElement("div", {
    className: blueGradientHeaderClasses,
    style: {
      height: height
    }
  }, showScanner && React.createElement(Barcode, {
    className: classes.scannerIcon
  }), props.children);
};
export default React.memo(BlueGradientHeader);