import React from 'react';

import { useStyles } from './UserRoleDialogStyles';

// Components
import {
    DialogBox,
    Typography,
    FunctionGoodsReceipt,
    FunctionPickPack,
    FunctionInventoryStatus,
    FunctionInventoryTransfer,
    FunctionInventoryCounting,
    FunctionDelivery,
    Color,
    FONT_FAMILY,
    Checkmark,
    Edit,
    Delete,
    Separator,
    Plus,
    Button
} from '@otg-one/ui_components';

import Grid from '@material-ui/core/Grid';

const FUNCTION_ICONS: any = {
    1: <FunctionGoodsReceipt />,
    2: <FunctionPickPack />,
    3: <FunctionInventoryStatus />,
    4: <FunctionInventoryTransfer />,
    5: <FunctionInventoryCounting />,
    6: <FunctionDelivery />
};

const UserRoleFunction = (props: any) => {
    const classes = useStyles(props)
    const { children, icon } = props

    return (
        <div className={classes.userRoleFunctionContainer}>
            <div className={classes.userRoleFunction}>
                {icon}
            </div>
            <Typography
                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                size={10}
                color={Color.primary[500]}
            >
                {children}
            </Typography>
        </div>
    )
};

const ExpandedUserRoleHeader = (props: any) => {
    const classes = useStyles(props);
    const { functions } = props;

    return (
        <>
            <Grid item md={3} />
            <Grid item md={9}>
                <div className={classes.expandedUserRoleHeader}>
                    {functions.map((func: any) => (
                        <UserRoleFunction icon={FUNCTION_ICONS[func.functionTypeId]}>
                            {func.name}
                        </UserRoleFunction>
                    ))}
                </div>
            </Grid>
        </>
    )
}

const ExpandedUserRoleList = (props: any) => {
    const classes = useStyles(props)

    const { functions, role, handleEditRole, handleDeleteRole } = props

    return (
        <>
            <Grid item md={1}>
                <div className={classes.expandedUserRoleListAction}>
                    <Edit onClick={handleEditRole(role)} />
                    <Delete onClick={handleDeleteRole(role)} style={{ marginLeft: 10 }} />
                </div>
            </Grid>
            <Grid item md={2}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                    size={18}
                    color={Color.neutral[600]}
                >
                    {role.name}
                </Typography>
            </Grid>
            <Grid item md={9}>
                <div className={classes.functionStatusContainer}>
                    {functions.map((func: any) => (
                        <div className={classes.functionStatus}>{role.roles[func.functionTypeId] ? (<Checkmark />) : `-`}</div>
                    ))}
                </div>
            </Grid>
        </>
    )
}

const UserRoleDialog = (props: any) => {
    const classes = useStyles(props);
    const { open, onClose, functions, userRoles, handleCreateNewRole, handleEditRole, handleDeleteRole } = props;

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title="User Role"
            content={
                <div style={{ width: 1000, paddingTop: 16, overflow: 'hidden' }}>
                    {userRoles.length > 0 ?
                        <>
                            <Grid container style={{ flexGrow: 1 }} spacing={2} alignItems="center">
                                <ExpandedUserRoleHeader functions={functions} />
                            </Grid>
                            <div className={classes.expandedUserRoleListContainer}>
                                <Grid container style={{ flexGrow: 1 }} spacing={2} alignItems="center">
                                    {userRoles.map((role: any, index: number) => (
                                        <React.Fragment key={`role-${role.name}-${index}`}>
                                            <ExpandedUserRoleList
                                                functions={functions}
                                                role={role}
                                                handleEditRole={handleEditRole}
                                                handleDeleteRole={handleDeleteRole}
                                            />
                                            <Grid item md={12}>
                                                <Separator type="n200" width={1} />
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </div>
                        </> :
                        <Grid container style={{ flexGrow: 1 }} spacing={2} alignItems="center">
                            <Grid item md={12} style={{ textAlign: "center" }}>
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                    size={20}
                                    color={Color.neutral[300]}
                                    style={{ letterSpacing: -0.8 }}
                                >
                                    {`No User Roles Configured`}
                                </Typography>
                            </Grid>
                        </Grid>}

                    <Button
                        icon={<Plus />}
                        alignIcon="left"
                        style={{ outline: 'none' }}
                        onClick={handleCreateNewRole}
                    >
                        {`Add New Role`}
                    </Button>
                </div>
            }
        />
    )
};

export default UserRoleDialog;