// React Lib
import React from "react"; // Component Styles

import { useStyles } from "./ExpandedUserRoleStyles"; // @otg-one/ui_components/components

import { Button, Typography, Separator } from "../index"; // @otg-one/ui_components/icons

import { FunctionGoodsReceipt, FunctionPickPack, FunctionInventoryStatus, FunctionInventoryTransfer, FunctionInventoryCounting, FunctionDelivery, Checkmark, Plus, ArrowDiagonalLeftBottom, Edit, Delete } from "../../icons"; // @otg-one/ui_components/constants

import { Color, FONT_FAMILY } from "../../constants"; // Material UI Core

import Grid from '@material-ui/core/Grid';

var UserRoleFunction = function UserRoleFunction(props) {
  var classes = useStyles(props);
  var children = props.children,
      icon = props.icon;
  return React.createElement("div", {
    className: classes.userRoleFunctionContainer
  }, React.createElement("div", {
    className: classes.userRoleFunction
  }, icon), React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 10,
    color: Color.neutral[100]
  }, children));
};

var ExpandedUserRoleHeader = function ExpandedUserRoleHeader(props) {
  var classes = useStyles(props);
  return React.createElement(React.Fragment, null, React.createElement(Grid, {
    item: true,
    md: 3
  }), React.createElement(Grid, {
    item: true,
    md: 9
  }, React.createElement("div", {
    className: classes.expandedUserRoleHeader
  }, React.createElement(UserRoleFunction, {
    icon: React.createElement(FunctionGoodsReceipt, null)
  }, "Goods Receipt"), React.createElement(UserRoleFunction, {
    icon: React.createElement(FunctionPickPack, null)
  }, "Pick & Pack"), React.createElement(UserRoleFunction, {
    icon: React.createElement(FunctionInventoryStatus, null)
  }, "Inventory Status"), React.createElement(UserRoleFunction, {
    icon: React.createElement(FunctionInventoryTransfer, null)
  }, "Inventory Transfer"), React.createElement(UserRoleFunction, {
    icon: React.createElement(FunctionInventoryCounting, null)
  }, "Inventory Counting"), React.createElement(UserRoleFunction, {
    icon: React.createElement(FunctionDelivery, null)
  }, "Delivery"))));
};

var ExpandedUserRoleList = function ExpandedUserRoleList(props) {
  var classes = useStyles(props);
  var role = props.role;
  return React.createElement(React.Fragment, null, React.createElement(Grid, {
    item: true,
    md: 1
  }, React.createElement("div", {
    className: classes.expandedUserRoleListAction
  }, React.createElement(Edit, null), React.createElement(Delete, {
    style: {
      marginLeft: 10
    }
  }))), React.createElement(Grid, {
    item: true,
    md: 2
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_BOLD,
    size: 18,
    color: Color.neutral[100]
  }, role.name)), React.createElement(Grid, {
    item: true,
    md: 9
  }, React.createElement("div", {
    className: classes.functionStatusContainer
  }, React.createElement("div", {
    className: classes.functionStatus
  }, role.goodsReceipt ? React.createElement(Checkmark, null) : "-"), React.createElement("div", {
    className: classes.functionStatus
  }, role.pickPack ? React.createElement(Checkmark, null) : "-"), React.createElement("div", {
    className: classes.functionStatus
  }, role.inventoryStatus ? React.createElement(Checkmark, null) : "-"), React.createElement("div", {
    className: classes.functionStatus
  }, role.inventoryTransfer ? React.createElement(Checkmark, null) : "-"), React.createElement("div", {
    className: classes.functionStatus
  }, role.inventoryCounting ? React.createElement(Checkmark, null) : "-"), React.createElement("div", {
    className: classes.functionStatus
  }, role.delivery ? React.createElement(Checkmark, null) : "-"))));
};

export var ExpandedUserRole = function ExpandedUserRole(props) {
  var classes = useStyles(props);
  var id = props.id,
      roles = props.roles,
      onClose = props.onClose;
  return React.createElement("div", {
    id: id,
    className: classes.expandedUserRoleContainer
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.CAPITOLIUM_BOLD,
    size: 20,
    color: Color.neutral[100]
  }, "User Roles"), React.createElement("div", {
    className: classes.expandedUserRoleExpandIcon
  }, React.createElement(ArrowDiagonalLeftBottom, {
    onClick: onClose
  })), React.createElement(Grid, {
    container: true,
    style: {
      flexGrow: 1
    },
    spacing: 2,
    alignItems: "center"
  }, React.createElement(ExpandedUserRoleHeader, null)), React.createElement("div", {
    className: classes.expandedUserRoleListContainer
  }, React.createElement(Grid, {
    container: true,
    style: {
      flexGrow: 1
    },
    spacing: 2,
    alignItems: "center"
  }, roles.map(function (role, index) {
    return React.createElement(React.Fragment, {
      key: "role-".concat(role.name, "-").concat(index)
    }, React.createElement(ExpandedUserRoleList, {
      role: role
    }), React.createElement(Grid, {
      item: true,
      md: 12
    }, React.createElement(Separator, {
      type: "n200",
      width: 2
    })));
  }))), React.createElement(Button, {
    color: "white",
    icon: React.createElement(Plus, null),
    alignIcon: "left"
  }, "Add New Role"));
};
ExpandedUserRole.defaultProps = {
  roles: [{
    name: "Admin",
    goodsReceipt: true,
    pickPack: false,
    inventoryStatus: false,
    inventoryTransfer: true,
    inventoryCounting: true,
    delivery: false
  }],
  onClose: function onClose() {}
};
export default React.memo(ExpandedUserRole);