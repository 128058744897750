import axios from 'axios';

import { getApiUrl } from '../../Constants/Api';

export class AuthService {
    register(credentials, recaptchaResponse) {
        return axios.post(getApiUrl() + "auth/register?g-recaptcha-response=" + recaptchaResponse, credentials);
    }

    login(credentials, recaptchaResponse) {
        return axios.post(getApiUrl() + "auth/login?g-recaptcha-response=" + recaptchaResponse, credentials);
    }

    activateUser(activationToken) {
        return axios.get(getApiUrl() + "auth/activateUser/" + activationToken);
    }

    sendActivationLink(email) {
        return axios.get(getApiUrl() + "auth/resendActivation/" + email);
    }

    sendResetPasswordMail(email) {
        return axios.get(getApiUrl() + "auth/sendResetPasswordMail/" + email);
    }

    getUserInfo() {
        return JSON.parse(localStorage.getItem("userInfo"));
    }

    getAuthHeader() {
        return { headers: { "Authorization": this.getUserInfo() } };
    }

    logOut() {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("breadcrumb");
        localStorage.removeItem("clientView");
        this.setUserToken("");
    }

    getUserByKey(key) {
        return axios.get(getApiUrl() + "users/" + key);
    }

    setUserToken(token) {
        axios.defaults.headers.common['Authorization'] = token;
    }

    updateUser(currentUserData, userName) {
        let formData = new FormData();
        formData.append("name", userName);
        return axios.put(getApiUrl() + "users/" + currentUserData.key, formData);
    }

    updateUserImage(currentUserData, userAvatarImage) {
        let formData = new FormData();
        formData.append("userimage", userAvatarImage[0]);
        return axios.post(getApiUrl() + "users/" + currentUserData.key + "/userimage", formData);
    }

    changePassword(currentUserData, currentPassword, newPassword, newRepeatPassword) {
        let formData = new FormData();
        formData.append("currentPassword", currentPassword);
        formData.append("newPassword", newPassword);
        formData.append("newRepeatPassword", newRepeatPassword);
        return axios.put(getApiUrl() + "users/" + currentUserData.key + "/changepassword", formData);
    }

    getBaseInformation() {
        return axios.get(getApiUrl() + "baseinformation");
    }

    updateScenarioVersionForUser(currentUserData, scenarioVersion) {
        let formData = new FormData();
        formData.append("scenarioVersion", scenarioVersion);
        return axios.put(getApiUrl() + "users/" + currentUserData.key + "/updateScenario", formData);
    }
}

export default new AuthService();