import _toConsumableArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React Lib
import React, { useState, useEffect } from "react"; // Component Styles

import { useStyles } from "./SystemLogStyles"; // @otg-one/ui_components/components

import { Chip, Typography, Separator } from "../index"; // @otg-one/ui_components/constants

import { Color, FONT_FAMILY, LogSeverity, getLogSeverityStyle } from "../../constants"; // Material UI Core

import Grid from '@material-ui/core/Grid'; // Icon

import NoMessage from "../../icons/NoMessage/NoMessage";
import { Dropdown } from "../../index";
import moment from 'moment';

var SystemLogListHeader = function SystemLogListHeader(props) {
  return React.createElement(React.Fragment, null, React.createElement(Grid, {
    item: true,
    md: 2
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_BOLD,
    size: 14,
    color: Color.neutral[100]
  }, "Status")), React.createElement(Grid, {
    item: true,
    md: 8
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_BOLD,
    size: 14,
    color: Color.neutral[100]
  }, "Activity")), React.createElement(Grid, {
    item: true,
    md: 2
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_BOLD,
    size: 14,
    color: Color.neutral[100]
  }, "Time")), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(Separator, {
    type: "n200",
    width: 2,
    marginTop: -8
  })));
};

var SystemLogList = function SystemLogList(props) {
  var log = props.log,
      global = props.global;
  var IconLog = getLogSeverityStyle(log.severity).icon;
  var classes = useStyles(props);
  return React.createElement(React.Fragment, null, React.createElement(Grid, {
    item: true,
    md: 2,
    xs: 12
  }, React.createElement("div", {
    style: {
      marginTop: -10
    }
  }, React.createElement(Chip, {
    icon: IconLog,
    backgroundColor: getLogSeverityStyle(log.severity).backgroundColor,
    color: getLogSeverityStyle(log.severity).fontColor
  }, log.severity))), global && React.createElement(Grid, {
    item: true,
    md: 2,
    xs: 12
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 14,
    color: Color.neutral[100],
    className: classes.listItemView
  }, log.client_name, " ", React.createElement("br", {
    className: classes.br
  }), " ", log.system_name)), React.createElement(Grid, {
    item: true,
    md: global ? 6 : 8,
    xs: 12
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 14,
    color: Color.neutral[100],
    className: classes.listItemView
  }, log.message)), React.createElement(Grid, {
    item: true,
    md: 2,
    xs: 12
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 14,
    color: Color.neutral[100],
    className: classes.listItemView
  }, moment(log.createdOn).format('MMM DD, YYYY - HH:mm'))));
};

export var SystemLog = function SystemLog(props) {
  var classes = useStyles(props);
  var id = props.id,
      logs = props.logs,
      global = props.global;

  var _useState = useState(logs),
      _useState2 = _slicedToArray(_useState, 2),
      dataLogs = _useState2[0],
      setDataLogs = _useState2[1];

  var _useState3 = useState(LogSeverity.ALL),
      _useState4 = _slicedToArray(_useState3, 2),
      filterData = _useState4[0],
      setFilterData = _useState4[1];

  useEffect(function () {
    var currentDataLogs = _toConsumableArray(logs);

    var filteredDataLogs = [];

    if (filterData === LogSeverity.ALL) {
      filteredDataLogs = currentDataLogs;
    } else {
      filteredDataLogs = currentDataLogs.filter(function (log) {
        var lc = log.severity;
        return lc.includes(filterData);
      });
    }

    setDataLogs(filteredDataLogs);
  }, [filterData]);
  useEffect(function () {
    setDataLogs(logs);
  }, [logs]);
  var DROPDOWN_MENU = [{
    text: "Show All",
    onClick: function onClick() {
      setFilterData(LogSeverity.ALL);
    }
  }, {
    text: "Trace",
    onClick: function onClick() {
      setFilterData(LogSeverity.TRACE);
    }
  }, {
    text: "Debug",
    onClick: function onClick() {
      setFilterData(LogSeverity.DEBUG);
    }
  }, {
    text: "Info",
    onClick: function onClick() {
      setFilterData(LogSeverity.INFO);
    }
  }, {
    text: "Warn",
    onClick: function onClick() {
      setFilterData(LogSeverity.WARN);
    }
  }, {
    text: "Error",
    onClick: function onClick() {
      setFilterData(LogSeverity.ERROR);
    }
  }, {
    text: "Fatal",
    onClick: function onClick() {
      setFilterData(LogSeverity.FATAL);
    }
  }, {
    text: "Off",
    onClick: function onClick() {
      setFilterData(LogSeverity.OFF);
    }
  }];
  return React.createElement("div", {
    id: id,
    className: classes.systemLogContainer
  }, React.createElement(Typography, {
    id: "".concat(id, "-system-log"),
    fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
    size: 16,
    color: Color.neutral[100]
  }, "System Log"), React.createElement("div", {
    style: {
      position: "absolute",
      top: 16,
      right: 24
    }
  }, React.createElement(Dropdown, {
    menu: DROPDOWN_MENU,
    selected: DROPDOWN_MENU[0].text,
    type: "SELECTION",
    backgroundColor: Color.neutral[0]
  })), React.createElement("div", {
    id: "".concat(id, "-latest-activity-list-container"),
    className: classes.systemLogListContainer,
    style: {
      paddingTop: 10
    }
  }, React.createElement(Grid, {
    container: true,
    style: {
      flexGrow: 1
    },
    spacing: 2,
    alignItems: "center"
  }, dataLogs.length > 0 ? dataLogs.map(function (log, index) {
    return React.createElement(React.Fragment, null, React.createElement(SystemLogList, {
      log: log,
      global: global
    }), index !== dataLogs.length - 1 && React.createElement(Grid, {
      item: true,
      md: 12,
      xs: 12
    }, React.createElement(Separator, {
      type: "n200",
      width: 1,
      marginTop: -8
    })));
  }) : React.createElement(Grid, {
    container: true,
    alignItems: "center",
    justify: "center",
    className: classes.noMessageWrapper
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12,
    container: true,
    justify: "center",
    alignItems: "center"
  }, React.createElement(NoMessage, {
    className: classes.noMessageIcon
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    container: true,
    justify: "center",
    alignItems: "center"
  }, React.createElement("span", {
    className: classes.noMessageContent
  }, "No System Message"))))))));
};
SystemLog.defaultProps = {
  logs: [],
  global: true
};
export default React.memo(SystemLog);