// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    modal: {
      backgroundColor: "#fff",
      margin: 0,
      borderRadius: 8,
      zIndex: 9999999,
      boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2)",
      position: "absolute",
      left: "50%",
      top: "50%"
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
      height: "100%"
    }
  });
});