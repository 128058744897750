import React, { useContext } from 'react';

// Components
import {
    DialogBox,
    Button,
    Typography,
    FONT_FAMILY,
    Color,
    BreadcrumbType
} from '@otg-one/ui_components';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { AppContext } from '../../App';

import { useHistory } from "react-router-dom";

// Service
import AuthService from '../../Pages/Login/AuthService';

import { LOGIN } from '../../Constants/FilePath';

const ExitConfigurationDialog = (props: any) => {
    const { open, onClose, handleSave, disableSaveButton } = props
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const history = useHistory();

    const {
        setBreadcrumb,
        handleChangeBreadcrumb,
        setBlockNavigation,
        tempBreadcrumb,
        setTempBreadcrumb,
        tempPath,
        setTempPath,
        setCurrentUserData
    } = useContext(AppContext)

    const handleExit = () => {
        onClose();
        new Promise((resolve, reject) => {
            setBlockNavigation(false);
            resolve(true)
        }).then(res => {
            if (tempPath === LOGIN) {
                AuthService.logOut();
                setCurrentUserData({});
                setBreadcrumb([]);
                history.push(LOGIN);
            } else {
                setBreadcrumb(tempBreadcrumb);
                history.push(tempPath, tempBreadcrumb);
            }
        })

    }

    const handleSaveAndExit = () => {
        if (!disableSaveButton) {
            handleSave();
        }
        onClose();
        new Promise((resolve, reject) => {
            setBlockNavigation(false);
            resolve(true)
        }).then(res => {
            if (tempPath === LOGIN) {
                AuthService.logOut();
                setCurrentUserData({});
                setBreadcrumb([]);
                history.push(LOGIN);
            } else {
                setBreadcrumb(tempBreadcrumb);
                history.push(tempPath, tempBreadcrumb);
            }
        })
    }

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title="Exit Function Configuration?"
            content={
                <div style={{ width: isMobile ? 'auto' : 600 }}>
                    <Typography
                        fontFamily={FONT_FAMILY.CAPITOLIUM_ITALIC}
                        size={16}
                        color={Color.neutral[900]}
                        style={{ marginBottom: isMobile ? 25 : 50 }}
                    >
                        {`Changes has been made to this function configuration`}
                    </Typography>

                    {isDesktop && (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button type="secondary" style={{ outline: 'none' }} onClick={onClose}>
                                {`Cancel`}
                            </Button>
                            <div>
                                <Button type="secondary" style={{ outline: 'none', marginRight: 24 }} onClick={handleExit}
                                >
                                    {`Discard change and exit`}
                                </Button>
                                <Button style={{ outline: 'none' }} onClick={handleSaveAndExit} disabled={disableSaveButton}
                                >
                                    {`Save and Exit`}
                                </Button>
                            </div>
                        </div>
                    )}
                    {isMobile && (
                        <>
                            <div>
                                <Button type="secondary" style={{ outline: 'none' }} onClick={onClose}>
                                    {`Cancel`}
                                </Button>
                            </div>
                            <div>
                                <Button type="secondary" style={{ outline: 'none' }} onClick={handleExit}
                                >
                                    {`Discard change and exit`}
                                </Button>
                            </div>
                            <Button style={{ outline: 'none' }} onClick={handleSaveAndExit} disabled={disableSaveButton}
                            >
                                {`Save and Exit`}
                            </Button>
                        </>
                    )}
                </div>
            }
        />
    )
};

export default ExitConfigurationDialog;