import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./PickPackFilterPickListPageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { WhiteContentBar } from "../../index";
import GoodsIssue from "../../../icons/GoodsIssue/GoodsIssue";
import SyncIcon from '@material-ui/icons/Sync';
export var PickPackFilterPickListPage = function PickPackFilterPickListPage(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      data = props.data,
      isShowAssignedUser = props.isShowAssignedUser;

  var _React$useState = React.useState(data),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      listData = _React$useState2[0],
      setListData = _React$useState2[1];

  React.useEffect(function () {
    if (isShowAssignedUser) {
      setListData(data.filter(function (q) {
        return q.userId === '1';
      }));
    } else {
      setListData(data);
    }
  }, [data]);
  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "small",
    showScanner: true,
    previewHeight: previewHeight
  }, React.createElement("p", null, "Pick and Pack"))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentTopRadius,
    style: {
      height: previewHeight - 75 - 50 + 10
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: React.createElement("div", {
        style: {
          display: "flex",
          alignItems: "center"
        }
      }, React.createElement(SyncIcon, {
        style: {
          fontSize: 17,
          marginRight: 5
        }
      }), " Positions"),
      rightContent: "8"
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.listOrderItemsDiv,
    style: {
      height: previewHeight - 75 - 50 - 40 + 10
    }
  }, React.createElement(Grid, {
    container: true,
    className: classes.listOrderItemsContainer
  }, listData.map(function (pickPackItem) {
    return React.createElement(Grid, {
      item: true,
      md: 12,
      className: classes.listOrderItemsButton
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      item: true,
      md: 3
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12
    }, "Pick-Number"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, pickPackItem.pickNumber), React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12,
      style: {
        marginTop: "5px"
      }
    }, "Positions"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, pickPackItem.positions))), React.createElement(Grid, {
      item: true,
      md: 7
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12
    }, "Pick-Date"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, pickPackItem.pickDate), React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12,
      style: {
        marginTop: "5px"
      }
    }, "Remarks"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, pickPackItem.remarks))), React.createElement(Grid, {
      container: true,
      item: true,
      md: 2,
      alignItems: "center",
      justify: "center"
    }, React.createElement("div", {
      style: {
        color: "#2d9dd5",
        transform: "rotate(-20deg)"
      }
    }, React.createElement(GoodsIssue, null)))));
  }))))), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(GreyButton, {
    onClick: function onClick() {}
  }, "Back"))));
};
export default React.memo(PickPackFilterPickListPage);