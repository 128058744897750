// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Color, FONT_FAMILY } from '@otg-one/ui_components';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        megaDropdownButton: {
            "&:hover": {
                fontFamily: `${FONT_FAMILY.ROBOTO_BOLD} !important`,
                color: `${Color.primary[600]} !important`
            }
        },

        gridViewSetting: {
            [theme.breakpoints.down('sm')]: {
                display: 'none !important',
            }
        },

        collapseableSetting: {
            display: 'none',

            [theme.breakpoints.down('sm')]: {
                display: 'block',
            }
        },

        drawerChild: {
            width: '90%',
            padding: "18px 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "roboto-medium",
            fontSize: "14px",
            margin: "auto",

            "&:active": {
                borderColor: Color.primary[200],
                backgroundColor: Color.neutral[300],
            }
        },

        settingDrawerChild: {
            width: '90%',
            padding: "18px 0 9px 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "roboto-medium",
            fontSize: "14px",
            margin: "auto",
        },

        settingDrawerSubtitle: {
            fontFamily: FONT_FAMILY.CAPITOLIUM_BOLD,
            paddingBottom: 9,
        },

        settingDrawerSubContent: {
            padding: "9px 0",
            fontFamily: "roboto-light",
            width: '100%',
            textAlign: "center",

            "&:active": {
                borderColor: Color.primary[200],
                backgroundColor: Color.neutral[300],
            }
        },

        separator: {
            width: '90%',
            margin: 'auto',
        }
    } as any)
)