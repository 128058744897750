// React lib
import React, { useContext } from 'react';

// Context
import { GoodsReceiptContext } from './GoodsReceiptConfiguration';

// Components
import ConfigurationTitle from '../ConfigurationTitle';
import ConfigurationOption from '../ConfigurationOption';

// Constants
import { OPTION_TYPE_RADIO, OPTION_TYPE_CHECKBOX } from '../../../Constants/OptionType';
import { FIELDS_DISPLAYED, MORE_OR_LESS_QUANTITIES_ALLOWED, BEST_BEFORE_DATE, BEST_BEFORE_DATE_INPUT_FIELD, SETTINGS_FOR_POSITION_DETAILS, LESS_QUANTITIES_SECURITY_CHECK, OVERBOOKING_SECURITY_CHECK, SERIAL_NUMBER_TYPE } from './GoodsReceiptIds';

// Utils
import { goodsReceiptLocalMapToDatabaseValue, arraysMatch } from "../../../Utils/Utils"

const SettingsForPositionDetails = (props: any) => {
    const {
        defaultFunctionType,
        activeOption,
        setActiveOption,
        fieldsDisplayed,
        handleChangeFieldsDisplayed,
        bestBeforeDate,
        handleChangeBestBeforeDate,
        bestBeforeDateInputField,
        handleChangeBestBeforeDateInputField,
        moreOrLessQuantitiesAllowed,
        handleChangeMoreOrLessQuantitiesAllowed,
        lessQuantitiesSecurityCheck,
        handleChangeLessQuantitiesSecurityCheck,
        overbookingSecurityCheck,
        handleChangeOverbookingSecurityCheck,
        serialNumberType,
        handleChangeSerialNumberType
    } = useContext(GoodsReceiptContext);

    const fieldsDisplayedOption = {
        id: FIELDS_DISPLAYED,
        heading: '',
        title: `Fields Displayed`,
        subtitle: `Defines which field types are shown`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.displayedFields !== goodsReceiptLocalMapToDatabaseValue("displayedFields", fieldsDisplayed)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: 'templateA',
                onChange: handleChangeFieldsDisplayed,
                checked: fieldsDisplayed === 'templateA',
                disabled: false,
                label: `Template A`,
                subLabel: `Position, Item Number, Description, Order Quantity, Open Quantity, Quantity Unit`,
            },
            {
                value: 'templateB',
                onChange: handleChangeFieldsDisplayed,
                checked: fieldsDisplayed === 'templateB',
                disabled: true,
                label: `Template B (Unavailable)`,
                subLabel: `Template A + Consisted Amount Unit`,
            }
        ],
        collapsedOption: []
    }

    const moreOrLessQuantitiesAllowedOption = {
        id: MORE_OR_LESS_QUANTITIES_ALLOWED,
        heading: '',
        title: `More/less Quantities Allowed`,
        subtitle: `Defines whether it is possible to enter more/less quantities with/without security check`,
        isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.allowQtyChange, goodsReceiptLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck) as string[])) ? true : false,
        optionType: OPTION_TYPE_CHECKBOX,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: 'allowLessQuantities',
                onChange: handleChangeMoreOrLessQuantitiesAllowed,
                checked: moreOrLessQuantitiesAllowed.includes('allowLessQuantities'),
                label: `Less Quantities Allowed`,
                subLabel: ``,
                disabled: false,
            },
            {
                value: 'allowOverbooking',
                onChange: handleChangeMoreOrLessQuantitiesAllowed,
                checked: moreOrLessQuantitiesAllowed.includes('allowOverbooking'),
                label: `Overbooking Allowed`,
                subLabel: ``,
                disabled: false,
            }
        ],
        collapsedOption: []
    }

    const lessQuantitiesSecurityCheckOption = {
        id: LESS_QUANTITIES_SECURITY_CHECK,
        heading: 'MORE/LESS QUANTITIES ALLOWED - LESS QUANTITIES ALLOWED',
        title: `Less Quantities Security Check`,
        subtitle: ``,
        isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.allowQtyChange, goodsReceiptLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck) as string[])) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: 'lessQuantityAllowedWithCheck',
                onChange: handleChangeLessQuantitiesSecurityCheck,
                checked: lessQuantitiesSecurityCheck === 'lessQuantityAllowedWithCheck',
                label: `With Security Check`,
                subLabel: ``,
                disabled: false,
            },
            {
                value: 'lessQuantityAllowed',
                onChange: handleChangeLessQuantitiesSecurityCheck,
                checked: lessQuantitiesSecurityCheck === 'lessQuantityAllowed',
                label: `Without Security Check`,
                subLabel: ``,
                disabled: false,
            }
        ],
        collapsedOption: []
    }

    const overbookingSecurityCheckOption = {
        id: OVERBOOKING_SECURITY_CHECK,
        heading: 'MORE/LESS QUANTITIES ALLOWED - OVERBOOKING ALLOWED',
        title: `Overbooking Security Check`,
        subtitle: ``,
        isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.allowQtyChange, goodsReceiptLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck) as string[])) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: 'overbookingAllowedWithCheck',
                onChange: handleChangeOverbookingSecurityCheck,
                checked: overbookingSecurityCheck === 'overbookingAllowedWithCheck',
                label: `With Security Check`,
                subLabel: ``,
                disabled: false,
            },
            {
                value: 'overbookingAllowed',
                onChange: handleChangeOverbookingSecurityCheck,
                checked: overbookingSecurityCheck === 'overbookingAllowed',
                label: `Without Security Check`,
                subLabel: ``,
                disabled: false,
            }
        ],
        collapsedOption: []
    }

    const bestBeforeDateOption = {
        id: BEST_BEFORE_DATE,
        heading: '',
        title: `Best Before Date`,
        subtitle: `Defines if the best before date must be configured for batches`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.bestBeforeDate !== goodsReceiptLocalMapToDatabaseValue("bestBeforeDate", bestBeforeDate)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `noBbd`,
                label: `Do not capture best before date`,
                onChange: handleChangeBestBeforeDate,
                checked: bestBeforeDate === 'noBbd',
                subLabel: ``,
                disabled: false,
            },
            {
                value: `optionalBbd`,
                label: `Capture best before date optional`,
                onChange: handleChangeBestBeforeDate,
                checked: bestBeforeDate === 'optionalBbd',
                subLabel: ``,
                disabled: false,
            },
            {
                value: `showBbd`,
                label: `Capture best before date`,
                onChange: handleChangeBestBeforeDate,
                checked: bestBeforeDate === 'showBbd',
                subLabel: ``,
                disabled: false,
            },
        ],
        collapsedOption: []
    }

    const bestBeforeDateInputFieldOption = {
        id: BEST_BEFORE_DATE_INPUT_FIELD,
        heading: '',
        title: `Best Before Date Input Field`,
        subtitle: `Select the type of input filed of best before date`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.bestBeforeInputType !== goodsReceiptLocalMapToDatabaseValue("bestBeforeInputType", bestBeforeDateInputField)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `inputField`,
                label: `Input field`,
                onChange: handleChangeBestBeforeDateInputField,
                checked: bestBeforeDateInputField === 'inputField',
                subLabel: ``,
                disabled: false,
            },
            {
                value: `datePicker`,
                label: `Date picker`,
                onChange: handleChangeBestBeforeDateInputField,
                checked: bestBeforeDateInputField === 'datePicker',
                subLabel: ``,
                disabled: false,
            }
        ],
        collapsedOption: []
    }

    const serialNumberTypeOption = {
        id: SERIAL_NUMBER_TYPE,
        heading: '',
        title: `Serial Number Type`,
        subtitle: `Enter Serial Number or Manufacturer Serial Number`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.serialType !== goodsReceiptLocalMapToDatabaseValue("serialType", serialNumberType)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `SER`,
                label: `Serial Number`,
                onChange: handleChangeSerialNumberType,
                checked: serialNumberType === 'SER',
                subLabel: ``,
                disabled: false,
            },
            {
                value: `MNF`,
                label: `Manufacturer Serial Number`,
                onChange: handleChangeSerialNumberType,
                checked: serialNumberType === 'MNF',
                subLabel: ``,
                disabled: false,
            }
        ],
        collapsedOption: []
    }

    return (
        <>
            <ConfigurationOption configurationOption={fieldsDisplayedOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={moreOrLessQuantitiesAllowedOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            {moreOrLessQuantitiesAllowed.includes('allowLessQuantities') && (
                <ConfigurationOption configurationOption={lessQuantitiesSecurityCheckOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            )}
            {moreOrLessQuantitiesAllowed.includes('allowOverbooking') && (
                <ConfigurationOption configurationOption={overbookingSecurityCheckOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            )}
            <ConfigurationOption configurationOption={bestBeforeDateOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={bestBeforeDateInputFieldOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={serialNumberTypeOption} activeOption={activeOption} setActiveOption={setActiveOption} />
        </>
    );
};

export default SettingsForPositionDetails;