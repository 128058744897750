// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// Constants
import { Color } from '@otg-one/ui_components';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        expandedUserRoleContainer: {
            boxSizing: "border-box",
            padding: 24,
            width: "100%",
            maxHeight: 480,
            backgroundColor: Color.primary[400],
        },
        expandedUserRoleExpandIcon: {
            position: "absolute",
            top: 16,
            right: 16,
            "& svg": {
                width: 24,
                height: 24,
                color: Color.neutral[100],
                transform: "rotate(180deg)"
            }
        },

        expandedUserRoleHeader: {
            marginTop: 16,
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "90%",
        },

        expandedUserRoleListContainer: {
            height: 240,
            marginTop: 16,
            marginBottom: 30,
            overflowY: 'auto'
        },
        expandedUserRoleListAction: {
            "& svg": {
                width: 20,
                height: 20,
                color: Color.primary[300],
                cursor: "pointer"
            }
        },

        userRoleFunctionContainer: {
            height: 73,
            textAlign: "center"
        },
        userRoleFunction: {
            width: 80,
            position: "relative",
            "& svg": {
                width: 40,
                height: 40,
                color: Color.primary[500]
            }
        },

        functionStatusContainer: {
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "90%",
        },
        functionStatus: {
            width: 80,
            textAlign: "center",
            color: Color.primary[500],
            "& svg": {
                width: 20,
                height: 20,
                color: Color.primary[500]
            }
        }
    })
);