import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import * as React from 'react'; // Custom Styles

import { useStyles } from "./DatePickerModalStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid'; // Material-UI Pickers

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment'; // choose your lib

import moment from "moment";
import { Modal } from "../../index";
import { GreyButton } from "../../index";
export var DatePickerModal = function DatePickerModal(props) {
  var classes = useStyles(props);
  var modalWidth = 310;
  var modalHeight = 455;
  var isModalOpen = props.isModalOpen,
      setInitialValue = props.setInitialValue,
      initialValue = props.initialValue,
      setOpenModalState = props.setOpenModalState;

  var _React$useState = React.useState(moment(new Date()).format("DD MMM YYYY")),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      selectedDate = _React$useState2[0],
      handleDateChange = _React$useState2[1];

  React.useEffect(function () {
    if (initialValue !== "") {
      handleDateChange(initialValue);
    }
  }, [initialValue]);
  return React.createElement(Modal, {
    isModalOpen: isModalOpen,
    width: modalWidth,
    height: modalHeight
  }, React.createElement("div", {
    className: classes.floatingDiv
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.datePickerDiv
  }, React.createElement(MuiPickersUtilsProvider, {
    libInstance: moment,
    utils: MomentUtils
  }, React.createElement(DatePicker, {
    variant: "static",
    value: selectedDate,
    onChange: function onChange(date) {
      return handleDateChange(moment(date).format("DD MMM YYYY"));
    }
  }))), React.createElement(Grid, {
    item: true,
    md: 6,
    className: classes.floatingDivBarBottom
  }, React.createElement(GreyButton, {
    type: "left",
    onClick: function onClick() {
      setInitialValue("");
      setOpenModalState(false);
    }
  }, "Clear")), React.createElement(Grid, {
    item: true,
    md: 6,
    className: classes.floatingDivBarBottom
  }, React.createElement(GreyButton, {
    type: "right",
    onClick: function onClick() {
      setInitialValue(selectedDate);
      setOpenModalState(false);
    }
  }, "Done")))));
};
DatePickerModal.defaultProps = {
  setInitialValue: function setInitialValue() {},
  setOpenModalState: function setOpenModalState() {},
  initialValue: ""
};
export default React.memo(DatePickerModal);