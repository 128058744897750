import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    breadcrumbContainer: _defineProperty({
      width: "100%",
      display: "inline-flex",
      alignItems: "center"
    }, theme.breakpoints.down('sm'), {
      overflowY: 'auto',
      padding: "5%"
    }),
    breadcrumbLabel: _defineProperty({
      userSelect: "none",
      color: Color.neutral[400],
      "&:hover": {
        color: Color.primary[600]
      }
    }, theme.breakpoints.down('sm'), {
      whiteSpace: 'nowrap',
      maxWidth: '7ch',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }),
    breadcrumbLabelLastIndex: _defineProperty({}, theme.breakpoints.down('sm'), {
      whiteSpace: 'nowrap',
      maxWidth: 'none'
    }),
    breadcrumbChevronRight: _defineProperty({
      marginTop: 3,
      marginLeft: 8,
      marginRight: 8,
      width: 24,
      height: 24,
      color: Color.neutral[400]
    }, theme.breakpoints.down('sm'), {
      margin: "6px 2px 0 2px",
      width: 15,
      height: 15
    })
  });
});