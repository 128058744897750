export const SETTINGS_FOR_PICK_AND_PACK = `settingsForPickAndPack`

export const WAREHOUSE_NAME = `warehouseName`
export const LESS_QUANTITIES_ALLOWED = `lessQuantitiesAllowed`
export const QUICK_CHECK = `quickCheck`
export const USE_STORAGE_BOX_SYSTEM = `useStorageBoxSystem`
export const BOXES = `boxes`
export const SHIPPING_DIMENSION = `shippingDimension`
export const MULTISCAN_FOR_BATCHES = `multiscanForBatches`
export const CONTAINER_FOR_EXCESS_WEIGHT = `containerForExcessWeight`
export const CHECK_WEIGHT = `checkWeight`
export const LIMIT_FOR_THE_WEIGHT_CHECK = `limitForTheWeightCheck`
export const WEIGHT_UNIT = `weightUnit`
export const USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM = `useOneBatchWithinOneStorageBinForAnItem`
export const IGNORE_ITEMS = `ignoreItems`
export const COMPLETE_PICK_LIST = `completePickList`
export const SERIAL_NUMBER_TYPE = `serialNumberType`
export const CHECK_DELIVERY_NOTE = `checkDeliveryNote`
export const ACTIVATE_SHIPPING_DIMENSION = `activateShippingDimension`
export const CONFIRM_ITEMS_BY_ONE_CLICK = `confirmItemsByOneClick`
export const WEIGHT_FOR_BIG_SMALL_BOX = `weightForBigSmallBox`
export const WEIGHT_FOR_BIG_BIG_BOX = `weightForBigBigBox`
export const CREATE_DELIVERY_AUTOMATICALLY = `createDeliveryAutomatically`
export const FILTER_PICK_LIST_BY_USER = `filterPickListByUser`