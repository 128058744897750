// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    input: {
      position: "absolute",
      opacity: 0,
      cursor: "pointer",
      height: 0,
      width: 0
    },
    label: {
      display: "block",
      position: "relative",
      paddingLeft: "40px",
      marginBottom: "24px",
      lineHeight: "24px",
      cursor: "pointer",
      userSelect: "none",
      WebkitUserSelect: "none",
      MozUserSelect: "none",
      msUserSelect: "none",
      fontFamily: "roboto",
      fontSize: "14px",
      "&::before": {
        boxSizing: "border-box",
        position: "absolute",
        content: "''",
        top: 0,
        left: 0,
        height: "24px",
        width: "24px",
        borderRadius: "50%",
        borderStyle: "solid",
        borderWidth: "1px"
      },
      "&:hover": {
        fontFamily: "roboto-medium"
      },
      "&::after": {
        position: "absolute",
        content: "''",
        top: "5px",
        left: "5px",
        width: "14px",
        height: "14px",
        borderRadius: "50%",
        display: "none"
      },
      "$input:checked ~ &": {
        fontFamily: "roboto-medium",
        "&::after": {
          display: "block"
        },
        "&$disabled::before": {
          backgroundColor: "transparent"
        }
      },
      "&$disabled": {
        fontFamily: "roboto-medium",
        color: Color.neutral[500],
        "&::before": {
          backgroundColor: Color.neutral[300],
          borderColor: Color.neutral[400]
        },
        "&:hover::before": {
          backgroundColor: Color.neutral[300],
          borderColor: Color.neutral[400]
        },
        "&::after": {
          backgroundColor: Color.neutral[500]
        }
      }
    },
    n900: {
      color: Color.neutral[900],
      "&::before": {
        borderColor: Color.primary[200]
      },
      "&:hover::before": {
        backgroundColor: Color.primary[100],
        borderColor: Color.primary[100]
      },
      "&::after": {
        backgroundColor: Color.primary[600]
      }
    },
    n000: {
      color: Color.neutral[0],
      "&::before": {
        borderColor: Color.neutral[0]
      },
      "&:hover::before": {
        backgroundColor: Color.neutral[300],
        borderColor: Color.neutral[300]
      },
      "&::after": {
        backgroundColor: Color.neutral[100]
      }
    },
    disabled: {}
  });
});