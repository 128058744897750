import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _objectWithoutProperties from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["arrow"];
// React libraries
import React from 'react';
import MUITooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography'; // Etc

import { useStyles } from './TooltipStyles';
import { FONT_FAMILY } from '../../constants';
export var Tooltip = function Tooltip(props) {
  var _useStyles = useStyles(props),
      arrow = _useStyles.arrow,
      classes = _objectWithoutProperties(_useStyles, _excluded);

  var _React$useState = React.useState(null),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      arrowRef = _React$useState2[0],
      setArrowRef = _React$useState2[1];

  return React.createElement(MUITooltip, Object.assign({
    classes: classes,
    PopperProps: {
      popperOptions: {
        modifiers: {
          arrow: {
            enabled: Boolean(arrowRef),
            element: arrowRef
          }
        }
      }
    }
  }, props, {
    title: React.createElement(React.Fragment, null, React.createElement(Typography, {
      style: {
        fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
        fontSize: 12,
        letterSpacing: 0.17
      }
    }, props.title), React.createElement(Typography, {
      style: {
        fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
        fontSize: 10,
        letterSpacing: 0.17
      }
    }, props.subTitle), React.createElement("span", {
      className: arrow,
      ref: setArrowRef
    }))
  }));
};
export default Tooltip;