import { Color } from "./Colors";
import Search from "../icons/Search/Search";
import Bug from "../icons/Bug/Bug";
import Information from "../icons/Information/Information";
import Alert from "../icons/Alert/Alert";
import Close from "../icons/Close/Close";
export var LogSeverity = {
  ALL: "ALL",
  TRACE: "TRACE",
  DEBUG: "DEBUG",
  INFO: "INFO",
  WARN: "WARN",
  ERROR: "ERROR",
  FATAL: "FATAL",
  OFF: "OFF"
};
export var getLogSeverityStyle = function getLogSeverityStyle(severity) {
  switch (severity) {
    case LogSeverity.ALL:
    case LogSeverity.TRACE:
      return {
        "backgroundColor": Color.primary[200],
        "fontColor": Color.primary[900],
        "icon": Search
      };

    case LogSeverity.DEBUG:
      return {
        "backgroundColor": Color.alert[700],
        "fontColor": Color.neutral[900],
        "icon": Bug
      };

    case LogSeverity.INFO:
      return {
        "backgroundColor": Color.primary[600],
        "fontColor": Color.neutral[0],
        "icon": Information
      };

    case LogSeverity.WARN:
      return {
        "backgroundColor": Color.alert[300],
        "fontColor": Color.alert[800],
        "icon": Alert
      };

    case LogSeverity.ERROR:
      return {
        "backgroundColor": Color.danger[100],
        "fontColor": Color.danger[800],
        "icon": Close
      };

    case LogSeverity.FATAL:
      return {
        "backgroundColor": Color.danger[800],
        "fontColor": Color.neutral[0],
        "icon": Close
      };

    case LogSeverity.OFF:
      return {
        "background": Color.neutral[900],
        "font": Color.neutral[0],
        "icon": null
      };
  }
};