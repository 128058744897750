import React, { useState, useEffect } from 'react';

// Components
import {
    Banner,
    Typography,
    FONT_FAMILY,
    Color,
    Button,
    DialogBox,
    Form
} from '@otg-one/ui_components';

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const DeleteClient = (props: any) => {
    const { client, open, onClose, onDelete } = props

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [confirmationText, setConfirmationText] = useState("")
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true)

    useEffect(() => {
        if (confirmationText === `DELETE-CLIENT-${convertToUpperCase(client.name)}`) {
            setIsDeleteButtonDisabled(false)
        }
        else {
            setIsDeleteButtonDisabled(true)
        }
    }, [confirmationText])

    const convertToUpperCase = (string: string) => {
        return string.replace(/ /g, `-`).toUpperCase()
    }

    const handleChangeConfirmationText = (e: any) => {
        setConfirmationText(e.target.value)
    }

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title="Delete Client"
            content={
                <div style={{ maxWidth: isMobile ? 'auto' : 630, paddingTop: 16 }}>
                    <Typography
                        fontFamily={FONT_FAMILY.CAPITOLIUM_ITALIC}
                        size={16}
                        color={Color.neutral[900]}
                        style={{ marginBottom: 13 }}
                    >
                        {`Proceed deleting "${client.name}"?`}
                    </Typography>
                    <Banner maxWidth="100%">
                        Caution! This Action CAN NOT be Undone.
                        <br />
                        All data related to this client will be deleted!
                    </Banner>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                        size={16}
                        color={Color.neutral[900]}
                        style={{
                            marginTop: 35,
                            marginBottom: 7,
                            userSelect: "none",
                            MozUserSelect: "none",
                            WebkitUserSelect: "none",
                            msUserSelect: "none"
                        }}
                    >
                        {`DELETE-CLIENT-${convertToUpperCase(client.name)}`}
                    </Typography>

                    <Form
                        required
                        placeholder="Retype text above to proceed delete function (case sensitive)"
                        value={confirmationText}
                        onChange={handleChangeConfirmationText}
                    />

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: 45 }}>
                        <Button type="secondary" onClick={onClose}>Cancel</Button>
                        <Button style={{ marginLeft: 16 }} disabled={isDeleteButtonDisabled} onClick={onDelete}>Delete Client</Button>
                    </div>
                </div>
            }
        />
    )
};

export default DeleteClient;