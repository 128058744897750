

import axios from 'axios';

import { getApiUrl } from '../../../Constants/Api';


export class BillingService {
  
    async uploadBillingInformation(userKey: string, billingInformations: any) {
        let nonce = await billingInformations.dropInCallback.dropInCallback();
        let data = {...billingInformations, nonce} ; 
        delete data.dropInCallback;

        await axios.post(getApiUrl() + "billings?userkey=" + userKey, data);
    }

    async getClientToken(userKey: string) {
        let clientToken = await axios.get(getApiUrl() + "billings/clientToken?userkey=" + userKey);

        
        return clientToken;
    }

}

export default new BillingService();