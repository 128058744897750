

export const SETTINGS_FOR_ENTRY = `settingsForEntry`
export const ENTRY_OPTION = `entryOption`
export const VIA_SUPPLIERS = `viaSuppliers`
export const LIST_OF_SUPPLIERS = `listOfSuppliers`
export const SELECTION_OF_ORDERS = `selectionOfOrders`

export const VIA_ORDER_NUMBER = `viaOrderNumber`
export const TYPE_OF_ORDER_INPUT = `typeOfOrderInput`

export const SETTINGS_FOR_LIST_OF_POSITION = `settingsForListOfPosition`
export const DISPLAYED_COLUMN = `displayedColumn`
export const NEW_POSITION_ADDABLE = `newPositionAddable`
export const ITEM_SELECTION = `itemSelection`
export const QUICK_CHECK = `quickCheck`
export const WAREHOUSE_NAME = `warehouseName`
export const COMPLETE_ORDER = `completeOrder`
export const ORDER_POSITION_COUNT = `orderPositionCount`
export const GS1_128_BARCODE_SCAN = `gs1128BarcodeScan`
export const GS1_128_BARCODE_SCAN_LEADING_ZEROS = `gs1128BarcodeScanLeadingZeros`
export const ALLOW_EDIT_BUSINESS_PARTNER_REFERENCE_NUMBER = `allowEditBusinessPartnerReferenceNumber`
export const USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM = `useOneBatchWithinOneStorageBinForAnItem`
export const DRAFT_DOCUMENTS_FOR_NEW_LINE = `drafDocumentsForNewLine`

export const SETTINGS_FOR_POSITION_DETAILS = `settingsForPositionDetails`
export const FIELDS_DISPLAYED = `fieldsDisplayed`
export const MORE_OR_LESS_QUANTITIES_ALLOWED = `moreOrLessQuantitiesAllowed`

export const LESS_QUANTITIES_SECURITY_CHECK = `lessQuantitiesSecurityCheck`
export const OVERBOOKING_SECURITY_CHECK = `overbookingSecurityCheck`

export const BEST_BEFORE_DATE = `bestBeforeDate`
export const BEST_BEFORE_DATE_INPUT_FIELD = `bestBeforeDateInputField`
export const SERIAL_NUMBER_TYPE = `serialNumberType`