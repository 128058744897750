// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    separator: {
      border: "none",
      borderTopStyle: "solid"
    },
    n400: {
      borderTopColor: Color.neutral[400]
    },
    n800: {
      borderTopColor: Color.neutral[800]
    },
    n200: {
      borderTopColor: Color.neutral[200]
    },
    n100: {
      borderTopColor: Color.neutral[100]
    }
  });
});