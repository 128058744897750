// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./WeightCheckPageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { WhiteContentBar } from "../../index";
export var WeightCheckPage = function WeightCheckPage(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      data = props.data;
  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "small",
    showScanner: true,
    previewHeight: previewHeight
  }, React.createElement("p", null, "Pick and Pack"))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentTopRadius,
    style: {
      height: previewHeight - 75 - 50 + 10
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "Positions",
      rightContent: "8"
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.listOrderItemsDiv,
    style: {
      height: previewHeight - 75 - 50 - 40 + 10
    }
  }, React.createElement(Grid, {
    container: true,
    className: classes.listOrderItemsContainer
  }, data.map(function (pickPackItem) {
    return React.createElement(Button, {
      className: classes.listOrderItemsButton
    }, React.createElement(Grid, {
      item: true,
      md: 12
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      item: true,
      md: 3
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12
    }, "Pick-Number"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, pickPackItem.pickNumber), React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12,
      style: {
        marginTop: "5px"
      }
    }, "Positions"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, pickPackItem.positions))), React.createElement(Grid, {
      item: true,
      md: 7
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12
    }, "Pick-Date"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, pickPackItem.pickDate), React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12,
      style: {
        marginTop: "5px"
      }
    }, "Remarks"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, pickPackItem.remarks))), React.createElement(Grid, {
      item: true,
      md: 2
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      container: true,
      item: true,
      md: 12,
      alignItems: "center",
      justify: "flex-end"
    }, React.createElement("div", {
      className: classes.redCircle
    }, pickPackItem.redValue)), React.createElement(Grid, {
      container: true,
      item: true,
      md: 12,
      alignItems: "center",
      justify: "flex-end",
      style: {
        marginTop: "15px"
      }
    }, React.createElement("div", {
      className: classes.greenCircle
    }, pickPackItem.greenValue)))))));
  }))))), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(GreyButton, {
    onClick: function onClick() {}
  }, "Back"))));
};
export default React.memo(WeightCheckPage);