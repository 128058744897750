// React lib
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../App';

// Styles
import { useStyles } from './CreateNewClientStyles';

// Components
import {
    Button,
    Form,
    Typography,
    FONT_FAMILY,
    Color,
    Plus,
    Separator
} from '@otg-one/ui_components';

// Material-UI Core
import Grid from '@material-ui/core/Grid';

// Components
import { BreadcrumbType } from '@otg-one/ui_components';

// Models
import { Stack } from '../../../Models/Stack';

import ClientService from "./ClientService";
import { PARTNER_DASHBOARD } from '../../../Constants/FilePath';
import { STACK_TYPE_PARTNER } from '../../../Constants/StackType';

const CreateNewClient = (props: any) => {
    const classes = useStyles(props)

    const {
        currentUserData,
        handleChangeBreadcrumb,
        handleShowSnackbar,
        setSnackbarMessage
    } = useContext(AppContext);


    const [client, setClient] = useState({ name: "" });
    const [message, setMessage] = useState("");

    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleChangeClientLogo = () => {
        const inputEl = document.getElementById("inputClientLogo")

        if (inputEl) {
            inputEl.click()
        }
    };

    const handleChangeClient = (property: string) => (e: React.FormEvent<HTMLInputElement>) => {
        const newClient: any = { ...client };
        newClient[property] = e.currentTarget.value;
        setClient(newClient);

        setIsError(false)
        setErrorMessage("")
    };

    const handleCreateClient = () => {
        if (client.name === "" || (Object.entries(client).length === 0 && client.constructor === Object)) {
            setIsError(true)
            setErrorMessage("Customer name must be filled")
        }
        else {
            setIsError(false)
            setErrorMessage("")

            ClientService.createClient(currentUserData.key, client).then((res: any) => {
                if (res.status === 200) {
                    setSnackbarMessage("New Customer has been created")
                    handleShowSnackbar();
                    handleChangeBreadcrumb(BreadcrumbType.BACK);
                } else {
                    setMessage("Something went wrong when creating a client!");
                }
            });
        }
    };

    return (
        <div className={classes.createNewClientContainer}>
            <Grid container style={{ flexGrow: 1 }} spacing={2}>
                <Grid item md={4} xs={12}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                        size={14}
                        color={Color.neutral[600]}
                    >
                        {`Customer Name`}
                    </Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Form
                        style={{ maxWidth: 375 }}
                        placeholder="Customer Name"
                        required
                        onChange={handleChangeClient("name")}
                        error={isError}
                        errorMessage={isError && errorMessage}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
                </Grid>

                {/* <Grid item md={4}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                        size={14}
                        color={Color.neutral[600]}
                    >
                        Client Logo
                        </Typography>
                </Grid>
                <Grid item md={8}>
                    <div style={{ display: "inline-flex" }}>
                        <div
                            style={{
                                width: 120,
                                height: 120,
                                border: `4px solid ${Color.neutral[300]}`
                            }}
                            onClick={handleChangeClientLogo}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    height: "100%",
                                    background: Color.neutral[300],
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                <input type="file" id="inputClientLogo" style={{ display: "none" }} />
                                <Plus style={{ width: 50, height: 50, color: Color.primary[600] }} />
                            </div>
                        </div>
                        <div>
                            <Button
                                type="secondary"
                                style={{ marginTop: 80, marginLeft: 30 }}
                                onClick={handleChangeClientLogo}
                            >
                                Change logo
                                </Button>
                        </div>
                    </div>
                </Grid>
                <Grid item md={12}>
                    <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
                </Grid> */}

                <Grid item md={4} xs={12}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                        size={14}
                        color={Color.neutral[600]}
                    >
                        Contact Name
                    </Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Form
                        style={{ maxWidth: 375 }}
                        placeholder="Contact Name"
                        onChange={handleChangeClient("contact_name")}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
                </Grid>

                <Grid item md={4} xs={12}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                        size={14}
                        color={Color.neutral[600]}
                    >
                        Email
                    </Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Form
                        style={{ maxWidth: 375 }}
                        placeholder="Email"
                        onChange={handleChangeClient("email")}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
                </Grid>

                <Grid item md={4} xs={12}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                        size={14}
                        color={Color.neutral[600]}
                    >
                        Phone
                    </Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Form
                        style={{ maxWidth: 375 }}
                        placeholder="Phone"
                        onChange={handleChangeClient("phone")}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
                </Grid>

                <Grid item md={4} xs={12}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                        size={14}
                        color={Color.neutral[600]}
                    >
                        Address
                    </Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Form
                        style={{ maxWidth: 375 }}
                        placeholder="Address"
                        multiline
                        rows={4}
                        onChange={handleChangeClient("address")}
                    />
                    <div>
                        <Button
                            style={{ marginTop: 50, outline: "none" }}
                            onClick={handleCreateClient}
                        >
                            Create Customer
                        </Button>
                    </div>
                </Grid>

                <Grid item md={12} xs={12}>
                    <div
                        style={{
                            boxSizing: "border-box",
                            padding: 10,
                            fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
                            color: Color.danger[300],
                            fontSize: 16,
                            textAlign: "center"
                        }}
                    >
                        {message}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default CreateNewClient