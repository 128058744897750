import _objectSpread from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// React libraries
import React from 'react';
import classnames from 'classnames'; // Component Style

import { useStyles } from "./RadioStyles";
export var Radio = function Radio(props) {
  var _classnames;

  var classes = useStyles(props);
  var type = props.type,
      name = props.name,
      value = props.value,
      label = props.label,
      disabled = props.disabled,
      style = props.style,
      checked = props.checked,
      onChange = props.onChange;
  var labelClasses = classnames((_classnames = {}, _defineProperty(_classnames, classes.label, true), _defineProperty(_classnames, classes[type], type), _defineProperty(_classnames, classes.disabled, disabled), _classnames));
  return React.createElement("div", {
    style: _objectSpread({}, style)
  }, React.createElement("input", {
    type: "radio",
    className: classes.input,
    name: name,
    id: "".concat(name, "-").concat(value),
    value: value,
    disabled: disabled,
    onChange: onChange,
    checked: checked
  }), React.createElement("label", {
    className: labelClasses,
    htmlFor: "".concat(name, "-").concat(value)
  }, label));
};
Radio.defaultProps = {
  type: "n900",
  disabled: false,
  onChange: function onChange() {}
};
export default React.memo(Radio);