// React lib
import React, { useContext, useState, useEffect } from 'react';

// Styles
import { useStyles } from './ClientDashboardStyles';

// Material-UI Core
import Grid from '@material-ui/core/Grid';

// Components
import {
    Button,
    Typography,
    Chart,
    TotalCost,
    FONT_FAMILY,
    Color,
    Dropdown,
    Thumbnail,
    ListView,
    Plus,
    System,
    BreadcrumbType,
    Tabs,
    DeviceLimit,
    DeviceLimitType,
    EditDeviceLimit,
    EmptyChartData
} from '@otg-one/ui_components';
import { AppContext } from '../../../App';
import { Stack } from '../../../Models/Stack';
import { SYSTEM_DASHBOARD, SYSTEM_CONFIGURATION, BILLING } from '../../../Constants/FilePath';
import { usePersistedState, getUpperBoundValue, getTicks, generateDropDownItems, getLastNYear } from '../../../Utils/Utils';
import DeleteSystem from '../System/DeleteSystem';
import ClientService from "./ClientService";
import SystemService from "../System/SystemService";
import ClientInfo from './ClientInfo';
import { STACK_TYPE_SYSTEM, STACK_TYPE_CUSTOMER } from '../../../Constants/StackType';
import CopyToProductionDialog from './CopyToProductionDialog';

const CustomerCostAndLogin = (props: any) => {
    const {
        getCurrentBreadcrumbData
    } = useContext(AppContext);

    const [customerCostAndLogin, setCustomerCostAndLogin] = useState(() => []);
    const [client] = useState(getCurrentBreadcrumbData(STACK_TYPE_CUSTOMER));

    useEffect(() => {
        if (Object.keys(client).length !== 0) {
            ClientService.getClientsCostAndLogin(client.key, props.selectedYear).then((res: any) => {
                setCustomerCostAndLogin(res.data);
            });
        }
    }, [client, props.selectedYear]);

    let noChart = true;
    let costMaxValue = 0;
    let loginMaxValue = 0;

    customerCostAndLogin.forEach((row: any, index: any) => {
        if (index > 0) {
            if (row[2] !== 0)
                noChart = false;
            if (row[1] > costMaxValue)
                costMaxValue = row[1];
            if (row[2] > loginMaxValue)
                loginMaxValue = row[2];
        }
    })

    const costUpperBound = getUpperBoundValue(costMaxValue);
    const loginUpperBound = getUpperBoundValue(loginMaxValue);

    return (
        <div style={{ paddingTop: 30 }}>
            <Chart
                chartType="ColumnChart"
                chartData={customerCostAndLogin}
                chartWidth={"100%"}
                chartHeight={"515px"}
                chartOptions={{
                    chartArea: {
                        left: '9%',
                        right: '4%',
                        top: 5,
                        bottom: 100,
                        width: "100%",
                        height: "515"
                    },
                    backgroundColor: "transparent",
                    colors: [Color.primary[900], Color.primary[200]],
                    animation: {
                        startup: true,
                        easing: 'out',
                        duration: 500,
                    },
                    legend: { position: 'bottom' },
                    series: {
                        0: { axis: 'cost', targetAxisIndex: 0 },
                        1: { axis: 'login', targetAxisIndex: 1 }
                    },
                    vAxes: {
                        0: {
                            textStyle: {
                                fontFamily: FONT_FAMILY.ROBOTO_LIGHT,
                                color: Color.neutral[400],
                            },
                            ticks: getTicks(costUpperBound),
                            viewWindow: {
                                min: 0,
                                max: costUpperBound
                            },
                            format: '#,##0.00 €'
                        },
                        1: {
                            textStyle: {
                                fontFamily: FONT_FAMILY.ROBOTO_LIGHT,
                                color: Color.neutral[400],
                            },
                            ticks: getTicks(loginUpperBound),
                            viewWindow: {
                                min: 0,
                                max: loginUpperBound
                            },
                        }
                    },
                    hAxis: {
                        textStyle: {
                            fontFamily: FONT_FAMILY.ROBOTO_LIGHT,
                            color: Color.neutral[400],
                        }
                    },
                    bar: {
                        groupWidth: "64"
                    },
                    tooltip: { isHtml: true, trigger: "visible" }
                }}
                chartEvents={
                    [
                        {
                            eventName: 'ready',
                            callback: (ce: any) => {
                                const chart = ce.chartWrapper.getChart();
                                var defses = document.getElementsByTagName('defs');
                                var gradientInjectFound = false;
                                Array.prototype.forEach.call(defses, function (defs) {
                                    if (defs.getAttribute('id') === 'gradientInject') {
                                        gradientInjectFound = true;
                                    }
                                });
                                if (!gradientInjectFound) {
                                    Array.prototype.forEach.call(defses, function (defs) {
                                        defs.insertAdjacentHTML('afterend', '<defs id="gradientInject"><linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%"><stop offset="0%" style="stop-color:#0077b6;stop-opacity:1" /><stop offset="100%" style="stop-color:#00527f;stop-opacity:1" /></linearGradient></defs>');
                                    });
                                }
                                var rects = chart.container.getElementsByTagName('rect');
                                Array.prototype.forEach.call(rects, function (rect) {
                                    if (rect.getAttribute('fill') === '#00527f') {
                                        rect.setAttribute('fill', 'url(#grad1)');
                                    }
                                });
                                ce.google.visualization.events.addListener(chart, "onmouseover", (e: any) => {
                                    var rects = chart.container.getElementsByTagName('rect');
                                    Array.prototype.forEach.call(rects, function (rect) {
                                        if (rect.getAttribute('fill') === '#00527f') {
                                            rect.setAttribute('fill', 'url(#grad1)');
                                        }
                                    });
                                });
                                ce.google.visualization.events.addListener(chart, "onmouseout", (e: any) => {
                                    var rects = chart.container.getElementsByTagName('rect');
                                    Array.prototype.forEach.call(rects, function (rect) {
                                        if (rect.getAttribute('fill') === '#00527f') {
                                            rect.setAttribute('fill', 'url(#grad1)');
                                        }
                                    });
                                });
                                ce.google.visualization.events.addListener(chart, "animationfinish", (e: any) => {
                                    var rects = chart.container.getElementsByTagName('rect');
                                    Array.prototype.forEach.call(rects, function (rect) {
                                        if (rect.getAttribute('fill') === '#00527f') {
                                            rect.setAttribute('fill', 'url(#grad1)');
                                        }
                                    });
                                });
                            },
                        },
                        {
                            eventName: 'select',
                            callback: (ce: any) => {
                                const chart = ce.chartWrapper.getChart();
                                var rects = chart.container.getElementsByTagName('rect');
                                Array.prototype.forEach.call(rects, function (rect) {
                                    if (rect.getAttribute('fill') === '#00527f') {
                                        rect.setAttribute('fill', 'url(#grad1)');
                                    }
                                });
                            },
                        },
                    ]
                }
            />
            {noChart && (
                <EmptyChartData></EmptyChartData>
            )}
        </div>
    )
}

const ClientDashboard = (props: any) => {
    const classes = useStyles(props);

    const {
        breadcrumb,
        getCurrentBreadcrumbData,
        currentUserData,
        handleChangeBreadcrumb,
        setSnackbarMessage,
        handleShowAlertSnackbar,
        totalCost,
        setTotalCost
    } = useContext(AppContext);

    const [client, setClient] = useState(getCurrentBreadcrumbData(STACK_TYPE_CUSTOMER));
    const [clientActiveDevice, setClientActiveDevice] = useState(0);

    const [systemView, setSystemView] = usePersistedState("systemView", "GRID");
    const [systemList, setSystemList] = useState([]);
    const [systems, setSystems] = useState([]);

    useEffect(() => {
        const clientFromBreadcrumb = getCurrentBreadcrumbData(STACK_TYPE_CUSTOMER);
        setClient(clientFromBreadcrumb);
    }, [breadcrumb]);

    useEffect(() => {
        if (Object.keys(client).length !== 0) {
            getTotalCost();
            getSystems();
        }
    }, [client]);

    const [deleteSystemDialogOpen, setDeleteSystemDialogOpen] = useState(false);
    const [activeSystem, setActiveSystem] = useState(null);

    const [copyFunctionConfigurationDialogOpen, setCopyFunctionConfigurationDialogOpen] = useState(false);
    const [editSystemDeviceLimitDialogOpen, setEditSystemDeviceLimitDialogOpen] = useState(false);

    const [isMouseThumbnailOver, setIsMouseThumbnailOver] = useState(false)
    const [isMouseListOver, setIsMouseListOver] = useState(false)

    const [selectedYear, setSelectedYear] = useState(-1);

    const handleDropDownClick = (value: any) => {
        value = typeof value === 'string' ? -1 : value;
        setSelectedYear(value);
    }

    const DROPDOWN_MENU = generateDropDownItems(getLastNYear(), handleDropDownClick);

    const getSystems = () => {
        ClientService.getSystems(currentUserData.key, client.key).then((res: any) => {
            setSystems(res.data);
            setSystemList(res.data);

            let clientActiveDevice = 0;
            for (const system of res.data) {
                if (system.deploymentEnv === 1) {
                    const deviceLimit = system.deviceLimit == null ? system.defaultDeviceLimit : system.deviceLimit;
                    const activeDevice = system.activeDevice > deviceLimit ? deviceLimit : system.activeDevice;
                    clientActiveDevice += activeDevice;
                }
            }

            setClientActiveDevice(clientActiveDevice);
        });
    };

    const getTotalCost = () => {
        ClientService.getTotalCost(currentUserData.key, client.key).then((res: any) => {
            setTotalCost(res.data);
        }).catch(error => {
            // TODO: error handling
            return error;
        });
    };

    const handleDeleteSystem = (system: any) => () => {
        ClientService.deleteSystem(currentUserData.key, system.key).then((res: any) => {
            handleCloseDeleteSystemDialog();
            getSystems();

            setSnackbarMessage(`System - ${system.name} has been deleted`)
            handleShowAlertSnackbar();
        });
    };

    const handleSubmitCopyFunctionConfig = (systemKey: string, originSystemKey: string) => {
        ClientService.copySystemFunctionConfiguration(currentUserData.key, systemKey, originSystemKey).then((res: any) => {
            getSystems();

            setSnackbarMessage(`Function configuration has been copied`);
            handleShowAlertSnackbar();
        });
    };

    const handleSubmitDeviceLimit = (deviceLimitType: string) => (data: any) => {
        if (deviceLimitType === DeviceLimitType.CLIENT) {
            ClientService.updateClientDeviceLimit(currentUserData.key, data).then((res: any) => {
                getSystems();

                setSnackbarMessage(`Device limit has been changed for client: ${data.name}`);
                handleShowAlertSnackbar();
            });
        } else if (deviceLimitType === DeviceLimitType.SYSTEM) {
            SystemService.updateSystemDeviceLimit(currentUserData.key, data).then((res: any) => {
                getSystems();

                setSnackbarMessage(`Device limit has been changed for system: ${data.name}`);
                handleShowAlertSnackbar();
            });
        }
    };

    const handleCloseDeleteSystemDialog = () => {
        setDeleteSystemDialogOpen(false);
        setActiveSystem(null);
    };

    const handleCloseCopyFunctionConfigurationDialog = () => {
        setCopyFunctionConfigurationDialogOpen(false);
        setActiveSystem(null);
    };

    const handleCloseEditSystemDeviceLimitDialog = () => {
        setEditSystemDeviceLimitDialogOpen(false);
        setActiveSystem(null);
    };

    const handleSystemClick = (system: any) => () => {
        handleChangeBreadcrumb(BreadcrumbType.PUSH, new Stack(STACK_TYPE_SYSTEM, system.name, SYSTEM_DASHBOARD, system));
    };

    const handleCreateNewSystem = () => {
        handleChangeBreadcrumb(BreadcrumbType.PUSH, new Stack('', "Create new System", SYSTEM_CONFIGURATION, { client }));
    };

    const handleChangeSystemView = (view: string) => () => {
        setSystemView(view)
    };

    // const handleChangeSystemSearch = (e: any) => {
    //     let searchKey = e.target.value

    //     let currentSystemList = [...systems];
    //     let filteredSystemList: any = [];

    //     if (searchKey !== "") {
    //         filteredSystemList = currentSystemList.filter((client: any) => {
    //             const name = client.name.toLowerCase();
    //             const pin = client.pin.toLowerCase();
    //             const dbName = client.dbName.toLowerCase();
    //             const systemName = client.systemName.toLowerCase();

    //             const filter = searchKey.toLowerCase();

    //             // console.log(lc + " " + lc.includes(filter));

    //             return name.includes(filter)
    //                 || pin.includes(filter)
    //                 || dbName.includes(filter)
    //                 || systemName.includes(filter)
    //         })
    //     }
    //     else if (searchKey === "") {
    //         filteredSystemList = currentSystemList
    //     }

    //     setSystemList(filteredSystemList)
    // }

    const handleGoToBillingClick = () => {
        handleChangeBreadcrumb(BreadcrumbType.BILLING);
    };

    const handleEditSystemConfiguration = (system: any) => {
        handleChangeBreadcrumb(BreadcrumbType.PUSH, new Stack(STACK_TYPE_SYSTEM, system.name, SYSTEM_DASHBOARD, system), new Stack('', "Edit System Configuration", SYSTEM_CONFIGURATION, system));
    }

    const handleOnIconThumbnailMouseOver = () => { setIsMouseThumbnailOver(true) }
    const handleOnIconThumbnailMouseOut = () => { setIsMouseThumbnailOver(false) }

    const handleOnIconListMouseOver = () => { setIsMouseListOver(true) }
    const handleOnIconListMouseOut = () => { setIsMouseListOver(false) }

    return (
        <>
            <div className={classes.clientDashboardContainer}>
                <Grid container style={{ flexGrow: 1 }} spacing={2}>
                    <Grid item md={4} xs={12}>
                        <Grid item md={12} xs={12}>
                            <TotalCost actionHandler={handleGoToBillingClick} totalCost={totalCost} />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <div style={{ marginTop: 18 }}>
                                <DeviceLimit
                                    data={{ ...client, activeDevice: clientActiveDevice }}
                                    type={DeviceLimitType.CLIENT}
                                    handleSubmitDeviceLimit={handleSubmitDeviceLimit(DeviceLimitType.CLIENT)}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <div className={classes.customerCostAndLoginChart}
                        >
                            {/** TITLE */}
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={18}
                                color={Color.neutral[900]}
                            >
                                {`Customer Cost and Devices`}
                            </Typography>
                            {/** TABS */}
                            <div
                                style={{
                                    marginTop: 75
                                }}
                            >
                                <CustomerCostAndLogin selectedYear={selectedYear} />
                            </div>
                            <div className={classes.chartDropdown}>
                                {<Dropdown
                                    menu={DROPDOWN_MENU}
                                    selected={DROPDOWN_MENU[0].text}
                                    type="SELECTION"
                                    backgroundColor={Color.neutral[0]}
                                />}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div className={classes.clientDashboardConfiguredSystemList}>
                <div className={classes.clientDashboardConfiguredSystemListTopBar}>
                    <Grid container style={{ flexGrow: 1 }} spacing={2}>
                        <Grid item md={8} xs={8}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={24}
                                color={Color.neutral[900]}
                            >
                                {"Configured Systems"}
                            </Typography>
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <div className={classes.iconContainer} style={{ marginRight: 12 }} onMouseOver={handleOnIconThumbnailMouseOver} onMouseOut={handleOnIconThumbnailMouseOut}>
                                    <Thumbnail
                                        style={{
                                            color: systemView === "GRID" ? Color.primary[500] : (isMouseThumbnailOver ? Color.primary[300] : Color.primary[100]),
                                            transition: `color 0.3s ease`
                                        }}
                                        onClick={handleChangeSystemView("GRID")}
                                    />
                                </div>
                                <div className={classes.iconContainer} onMouseOver={handleOnIconListMouseOver} onMouseOut={handleOnIconListMouseOut}>
                                    <ListView
                                        style={{
                                            color: systemView === "LIST" ? Color.primary[500] : (isMouseListOver ? Color.primary[300] : Color.primary[100]),
                                            transition: `color 0.3s ease`
                                        }}
                                        onClick={handleChangeSystemView("LIST")}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            {/* <Form
                                color="white"
                                placeholder="Search"
                                search
                                onChange={handleChangeSystemSearch}
                            /> */}
                            <Button
                                alignIcon="left"
                                icon={<Plus />}
                                onClick={handleCreateNewSystem}
                                style={{ marginTop: 10, outline: "none" }}
                            >
                                {`Create new System`}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ marginTop: 42 }}>
                    <System
                        data={systemList}
                        systemView={systemView}
                        onClick={handleSystemClick}
                        setActiveSystem={setActiveSystem}
                        setDeleteSystemDialogOpen={setDeleteSystemDialogOpen}
                        handleEditSystemConfiguration={handleEditSystemConfiguration}
                        setCopyFunctionConfigurationDialogOpen={setCopyFunctionConfigurationDialogOpen}
                        setEditSystemDeviceLimitDialogOpen={setEditSystemDeviceLimitDialogOpen} />
                </div>
            </div>

            {client.isTest === 0 && (
                <div className={classes.clientDashboardClientInfo}>
                    <ClientInfo client={client} setClient={setClient} />
                </div>
            )}
            {activeSystem && (
                <>
                    <DeleteSystem
                        open={deleteSystemDialogOpen}
                        onClose={handleCloseDeleteSystemDialog}
                        onDelete={handleDeleteSystem(activeSystem)}
                        system={activeSystem}
                    />
                </>
            )}
            <CopyToProductionDialog
                open={copyFunctionConfigurationDialogOpen}
                onClose={handleCloseCopyFunctionConfigurationDialog}
                activeSystem={activeSystem}
                systems={systemList}
                client={client}
                handleSubmitCopyFunctionConfig={handleSubmitCopyFunctionConfig}
            />
            <EditDeviceLimit
                open={editSystemDeviceLimitDialogOpen}
                onClose={handleCloseEditSystemDeviceLimitDialog}
                data={activeSystem || { name: "No system selected", activeDevice: 0, deviceLimit: 0, defaultDeviceLimit: 25 }}
                type={DeviceLimitType.SYSTEM}
                handleSubmitDeviceLimit={handleSubmitDeviceLimit(DeviceLimitType.SYSTEM)}
            />
        </>
    )
};

export default ClientDashboard;