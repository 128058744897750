import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    chartWrapper: {
      position: "relative"
    },
    noChartWrapper: _defineProperty({
      position: "absolute",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%"
    }, theme.breakpoints.down('xs'), {
      padding: '10px 15px'
    }),
    noChartIcon: {
      fontSize: "90px !important",
      width: "90px !important",
      height: "90px !important",
      color: "white !important",
      "& svg": {
        color: "white !important"
      }
    },
    noChartContent: {
      color: "white",
      textAlign: "center",
      marginTop: "20px",
      fontSize: "16px",
      fontWeight: 500
    },
    noChartContentSmall: {
      color: "white",
      textAlign: "center",
      marginTop: "10px",
      fontSize: "12px"
    }
  });
});