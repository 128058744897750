// React lib
import React, { useContext, useState } from 'react';

// Styles
import { useStyles } from './AccountSettingsStyles';

// Components
import {
    Button,
    Form,
    Typography,
    FONT_FAMILY,
    Color,
    Plus,
    Separator,
    Breadcrumb,
    BreadcrumbType
} from '@otg-one/ui_components';

// Material-UI Core
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { AppContext } from '../../App';
import { Stack } from '../../Models/Stack';
import { CHANGE_PASSWORD } from '../../Constants/FilePath';
import AuthService from '../Login/AuthService'
import { getApiUrl } from '../../Constants/Api';
import { STACK_TYPE_PARTNER } from '../../Constants/StackType';

// Components
// import { BreadcrumbType } from '@otg-one/ui_components';

const AccountSettings = (props: any) => {
    const classes = useStyles(props)

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const {
        handleChangeBreadcrumb,
        updateBreadcrumbLabel,
        currentUserData,
        setAvatarKey,
        handleShowSnackbar,
        handleShowAlertSnackbar,
        setSnackbarMessage
    } = useContext(AppContext);

    const [email] = useState(currentUserData.email)
    const [userKey] = useState(currentUserData.key)
    const [message, setMessage] = useState("")
    const [name, setName] = useState(currentUserData.name)
    const [userAvatarImage, setUserAvatarImage] = useState({} as any)
    const [imagePreview, setImagePreview] = useState("")
    const [imageAvailable] = useState(currentUserData.imageuuid != null)

    const [errorName, setErrorName] = useState(false)
    const [errorMsgName, setErrorMsgName] = useState("")

    const handleChangeName = (e: React.FormEvent<HTMLInputElement>) => {
        setErrorName(false);
        setErrorMsgName("");
        setName(e.currentTarget.value);
    };

    const handleChangeUserAvatar = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.files && e.currentTarget.files.length) {
            setUserAvatarImage(e.currentTarget.files);
            const reader = new FileReader();

            reader.addEventListener("load", function () {
                // convert image file to base64 string
                var previewStr = "";
                if (typeof reader.result == "string") {
                    previewStr = reader.result.toString();
                }
                setImagePreview(previewStr);
            }, false);

            if (e.currentTarget.files[0]) {
                reader.readAsDataURL(e.currentTarget.files[0]);
            }
        }
    };

    const triggerChangeUserAvatar = () => {
        const inputEl = document.getElementById("inputUserLogo")
        if (inputEl) {
            inputEl.click()
        }
    };

    const handleUpdateAccount = () => {
        if (name.length < 1) {
            setErrorName(true);
            setErrorMsgName("Please enter your user name!");
            setMessage("Please check your entered values!");
            return;
        }
        AuthService.updateUser(currentUserData, name).then((res: any) => {
            if (res.status === 200) {
                updateBreadcrumbLabel(STACK_TYPE_PARTNER, name);
                setAvatarKey("");
                if (userAvatarImage && userAvatarImage[0]) {
                    AuthService.updateUserImage(currentUserData, userAvatarImage).then((res: any) => {
                        if (res.status === 200) {
                            setAvatarKey("avatarKey");
                            setMessage("Account settings successfully updated!");
                            setSnackbarMessage("Account settings successfully updated!");
                            handleShowSnackbar();
                        } else {
                            setMessage("Something went wrong when updating account settings!");
                            setSnackbarMessage("Something went wrong when updating account settings!");
                            handleShowAlertSnackbar();
                        }
                    });
                } else {
                    setAvatarKey("avatarKey");
                    setMessage("Account settings successfully updated!");
                    setSnackbarMessage("Account settings successfully updated!");
                    handleShowSnackbar();
                }
            } else {
                setMessage("Something went wrong when updating account settings!");
                setSnackbarMessage("Something went wrong when updating account settings!");
                handleShowAlertSnackbar();
            }
        })
    };

    const handleChangePasswordClick = () => {
        handleChangeBreadcrumb(BreadcrumbType.CHANGE_PASSWORD);
    };

    return (
        <div className={classes.accountSettingsContainer}>
            <Grid container style={{ flexGrow: 1 }} spacing={2}>
                <Grid item md={12} xs={12}>
                    <div style={{ width: '100%', textAlign: "right" }}>
                        <Button type="secondary" onClick={handleChangePasswordClick}>
                            {"Change Password"}
                        </Button>
                    </div>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                        size={14}
                        color={Color.neutral[600]}
                    >
                        {`Email`}
                    </Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                        size={14}
                        color={Color.neutral[900]}
                    >
                        {email}
                    </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
                </Grid>

                <Grid item md={4} xs={12}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                        size={14}
                        color={Color.neutral[600]}
                    >
                        {`Username`}
                    </Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Form
                        style={{ maxWidth: 375 }}
                        placeholder="Username"
                        required
                        value={name}
                        onChange={handleChangeName}
                        error={errorName}
                        errorMessage={errorMsgName}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
                </Grid>

                <Grid item md={4} xs={12}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                        size={14}
                        color={Color.neutral[600]}
                    >
                        {`Avatar`}
                    </Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                    <div style={{ display: "inline-flex" }}>
                        <div
                            style={{
                                width: 120,
                                height: 120,
                                border: `4px solid ${Color.neutral[300]}`
                            }}
                            onClick={triggerChangeUserAvatar}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    height: "100%",
                                    background: Color.neutral[300],
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundImage: imagePreview
                                }}
                            >
                                <input type="file" id="inputUserLogo" style={{ display: "none" }} onChange={handleChangeUserAvatar} />
                                {imagePreview &&
                                    <img
                                        src={imagePreview}
                                        alt="preview"
                                        style={{
                                            width: 120,
                                            height: 120
                                        }}
                                    />
                                }

                                {imageAvailable && !imagePreview &&
                                    <img
                                        src={getApiUrl() + "users/" + userKey + "/userimage"}
                                        alt="userimage"
                                        style={{
                                            width: 120,
                                            height: 120
                                        }}
                                    />
                                }
                                {!imageAvailable && !imagePreview &&
                                    <Plus style={{ width: 50, height: 50, color: Color.primary[600] }} />
                                }
                            </div>
                        </div>
                        <div>
                            <Button
                                type="secondary"
                                style={{ marginTop: 80, marginLeft: 30 }}
                                onClick={triggerChangeUserAvatar}
                            >
                                {`Change Avatar`}
                            </Button>
                        </div>
                    </div>
                    <div>
                        <Button
                            style={{
                                marginTop: 50,
                                width: isMobile ? '-webkit-fill-available' : 'auto',
                                justifyContent: isMobile ? 'center' : 'flex-start'
                            }}
                            onClick={handleUpdateAccount}
                        >
                            {"Save Changes"}
                        </Button>
                    </div>
                </Grid>

                <Grid item md={12} xs={12}>
                    <div
                        style={{
                            boxSizing: "border-box",
                            padding: 10,
                            fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
                            color: message === "Account settings successfully updated!" ? Color.success[500] : Color.danger[300],
                            fontSize: 16,
                            textAlign: "center"
                        }}
                    >
                        {message}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default AccountSettings