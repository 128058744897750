import _toConsumableArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./ListOfOrderPositionsPageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'; // Icon

import Edit from "../../../icons/Edit/Edit";
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { WhiteContentBar } from "../../index";
import { KeyboardCompleteModal } from "../../index";
import { BlueLink } from "../../index";
import { AllowScanGs1128Modal } from "../../index";
import { DraftDocumentModal } from "../../index";
export var ListOfOrderPositionsPage = function ListOfOrderPositionsPage(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      templateType = props.templateType,
      warehouseLayout = props.warehouseLayout,
      data = props.data,
      allowEditBpRefNo = props.allowEditBpRefNo,
      completeOrderType = props.completeOrderType,
      quickCheck = props.quickCheck,
      newPositionAddable = props.newPositionAddable,
      headerTitle = props.headerTitle,
      allowScanGS1128 = props.allowScanGS1128,
      draftDocumentSetting = props.draftDocumentSetting,
      subtitle = props.subtitle;
  var bpRefNoHeight;

  if (allowEditBpRefNo) {
    bpRefNoHeight = 40;
  } else {
    bpRefNoHeight = 0;
  }

  var _React$useState = React.useState(""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      bpRefNo = _React$useState2[0],
      setBpRefNo = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      isKeyboardBpRefNoModalOpen = _React$useState4[0],
      setIsKeyboardBpRefNoModalOpen = _React$useState4[1];

  var _React$useState5 = React.useState(data),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      listData = _React$useState6[0],
      setListData = _React$useState6[1];

  var isConfirmed = false;

  if (completeOrderType === "atLeastOnePosition") {
    var tempStatusConfirmed = false;
    listData.map(function (data) {
      if (data.open === data.covered) {
        tempStatusConfirmed = true;
      }
    });

    if (tempStatusConfirmed) {
      isConfirmed = true;
    } else {
      isConfirmed = false;
    }
  } else {
    var _tempStatusConfirmed = true;
    listData.map(function (data) {
      if (data.open !== data.covered) {
        _tempStatusConfirmed = false;
      }
    });

    if (_tempStatusConfirmed) {
      isConfirmed = true;
    } else {
      isConfirmed = false;
    }
  }

  var editListData = function editListData(data) {
    var tempListData = _toConsumableArray(listData);

    tempListData.map(function (tempData) {
      if (tempData.id === data.id) {
        if (tempData.open === tempData.covered) {
          tempData.covered = 0;
        } else {
          tempData.covered = tempData.open;
        }
      }
    });
    setListData(tempListData);
  };

  var allowScanData = {
    nveSscc: "1122111112111112111",
    item: "110002222",
    description: "LeMon 4029 PrintHead",
    quantity: 5,
    batch: "20202022",
    bbd: "12022012"
  }; // Close Dialog On Option Change

  React.useEffect(function () {
    setIsKeyboardBpRefNoModalOpen(false);
  }, [templateType, warehouseLayout, allowEditBpRefNo, completeOrderType, quickCheck, newPositionAddable]);
  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "smallWithSub",
    showScanner: true,
    previewHeight: previewHeight
  }, React.createElement("p", null, headerTitle), React.createElement("sub", null, subtitle, " - ", React.createElement("b", null, "2334")))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentTopRadius,
    style: {
      height: previewHeight - 90 - 50 + 10
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "Positions",
      rightContent: "4"
    }
  })), allowEditBpRefNo && React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "BP Ref. No.",
      rightContent: bpRefNo,
      rightIcon: Edit
    },
    onClick: function onClick() {
      setIsKeyboardBpRefNoModalOpen(!isKeyboardBpRefNoModalOpen);
    }
  })), templateType === "templateA" && React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.listOrderItemsDiv,
    style: {
      height: previewHeight - 90 - 50 - 40 - bpRefNoHeight + 10
    }
  }, React.createElement(Grid, {
    container: true,
    className: classes.listOrderItemsContainer
  }, listData.map(function (orderItem) {
    return React.createElement(Button, {
      className: classes.listOrderItemsButton
    }, React.createElement(Grid, {
      item: true,
      md: 12
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      item: true,
      md: 5
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12
    }, "Item"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, orderItem.item), React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12,
      style: {
        marginTop: "5px"
      }
    }, "Open / Covered"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, orderItem.open + " / " + orderItem.covered + " " + orderItem.openCoveredType))), quickCheck && !orderItem.mandatoryBatchOrSerialNumber ? React.createElement(React.Fragment, null, React.createElement(Grid, {
      item: true,
      md: 6
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12
    }, "Description"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, orderItem.description), React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12,
      style: {
        marginTop: "5px"
      }
    }, "Warehouse"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, warehouseLayout === "warehouseName" ? orderItem.warehouseName : orderItem.warehouseCode))), React.createElement(Grid, {
      container: true,
      item: true,
      md: 1,
      alignItems: "center",
      justify: "flex-end"
    }, React.createElement("input", {
      type: "checkbox",
      name: name,
      id: orderItem.id,
      checked: orderItem.open === orderItem.covered ? true : false,
      onChange: function onChange(e) {
        editListData(orderItem);
      }
    }))) : React.createElement(Grid, {
      item: true,
      md: 7
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12
    }, "Description"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, orderItem.description), React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12,
      style: {
        marginTop: "5px"
      }
    }, "Warehouse"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, warehouseLayout === "warehouseName" ? orderItem.warehouseName : orderItem.warehouseCode))))));
  }), newPositionAddable && React.createElement(Grid, {
    container: true,
    className: classes.whiteContentBarContainer
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueLink, null, "Add New Row"))))), templateType === "templateB" && React.createElement(React.Fragment, null, React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.greyContentBar
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    className: classes.greyLeftContent,
    item: true,
    md: 5
  }, React.createElement("p", null, "Bin Location")), React.createElement(Grid, {
    className: classes.greyRightContent,
    item: true,
    md: 7
  }, React.createElement("p", null, "Open / Covered / Warehouse")))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.listOrderItemsDiv,
    style: {
      height: previewHeight - 90 - 50 - 40 - bpRefNoHeight + 10 - 35
    }
  }, React.createElement(Grid, {
    container: true,
    className: classes.listOrderItemsContainer
  }, listData.map(function (orderItem) {
    return React.createElement(Button, {
      className: classes.listOrderItemsButton
    }, React.createElement(Grid, {
      item: true,
      md: 12
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      item: true,
      md: 6
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, orderItem.item), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, orderItem.description))), quickCheck && !orderItem.mandatoryBatchOrSerialNumber ? React.createElement(React.Fragment, null, React.createElement(Grid, {
      item: true,
      md: 5
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.listOrderItemsButtonRightContent,
      item: true,
      md: 12
    }, orderItem.open + " / " + orderItem.covered + " " + orderItem.openCoveredType), React.createElement(Grid, {
      className: classes.listOrderItemsButtonRightContent,
      item: true,
      md: 12
    }, warehouseLayout === "warehouseName" ? orderItem.warehouseName : orderItem.warehouseCode))), React.createElement(Grid, {
      container: true,
      item: true,
      md: 1,
      alignItems: "center",
      justify: "flex-end"
    }, React.createElement("input", {
      type: "checkbox",
      name: name,
      id: orderItem.id,
      checked: orderItem.open === orderItem.covered ? true : false,
      onChange: function onChange(e) {
        editListData(orderItem);
      }
    }))) : React.createElement(Grid, {
      item: true,
      md: 5
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.listOrderItemsButtonRightContent,
      item: true,
      md: 12
    }, orderItem.open + " / " + orderItem.covered + " " + orderItem.openCoveredType), React.createElement(Grid, {
      className: classes.listOrderItemsButtonRightContent,
      item: true,
      md: 12
    }, warehouseLayout === "warehouseName" ? orderItem.warehouseName : orderItem.warehouseCode))))));
  }), newPositionAddable && React.createElement(Grid, {
    container: true,
    className: classes.whiteContentBarContainer
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueLink, null, "Add New Row")))))))), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "left"
  }, "Back")), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "right",
    disabled: !isConfirmed
  }, "Add"))), React.createElement(KeyboardCompleteModal, {
    title: "BP Ref. No.",
    initialValue: bpRefNo,
    isModalOpen: isKeyboardBpRefNoModalOpen,
    setInitialValue: setBpRefNo,
    setOpenModalState: setIsKeyboardBpRefNoModalOpen
  }), React.createElement(AllowScanGs1128Modal, {
    data: allowScanData,
    isModalOpen: allowScanGS1128
  }), draftDocumentSetting && React.createElement(DraftDocumentModal, {
    draft: draftDocumentSetting.draft,
    isModalOpen: draftDocumentSetting.setting,
    modalHeight: previewHeight,
    modalWidth: previewWidth
  }));
};
ListOfOrderPositionsPage.defaultProps = {
  subtitle: "Business Tools AG"
};
export default React.memo(ListOfOrderPositionsPage);