// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Color } from '@otg-one/ui_components';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        systemConfigurationContainer: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
            },
            display: "inline-flex",
            marginTop: 85 + 27
        },

        settingContainer: {
            alignItems: "center",
            marginTop: "0px",

            [theme.breakpoints.down('sm')]: {
                "& > .MuiGrid-item-13": {
                    padding: "12px 24px"
                }
            }
        },
    })
)