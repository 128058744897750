import _toConsumableArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./BatchesPageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { WhiteContentBar } from "../../index";
import { WhiteContentBarButton } from "../../index";
import { KeyboardCompleteModal } from "../../index";
import { KeyboardNumberModal } from "../../index";
import { DatePickerModal } from "../../index";
import { WarningModal } from "../../index"; // Icon

import Close from "../../../icons/Close/Close";
export var BatchesPage = function BatchesPage(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      data = props.data,
      bbdType = props.bbdType,
      bbdInputFieldType = props.bbdInputFieldType,
      totalBatches = props.totalBatches,
      oneBatchOnly = props.oneBatchOnly;

  var _React$useState = React.useState(data),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      batchList = _React$useState2[0],
      setBatchList = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      isKeyboardQuantityModalOpen = _React$useState4[0],
      setIsKeyboardQuantityModalOpen = _React$useState4[1];

  var _React$useState5 = React.useState(false),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      isKeyboardBbdModalOpen = _React$useState6[0],
      setIsKeyboardBbdModalOpen = _React$useState6[1];

  var _React$useState7 = React.useState(false),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      isDatePickerBbdModalOpen = _React$useState8[0],
      setIsDatePickerBbdModalOpen = _React$useState8[1];

  var _React$useState9 = React.useState(false),
      _React$useState10 = _slicedToArray(_React$useState9, 2),
      isKeyboardBatchModalOpen = _React$useState10[0],
      setIsKeyboardBatchModalOpen = _React$useState10[1];

  var _React$useState11 = React.useState(""),
      _React$useState12 = _slicedToArray(_React$useState11, 2),
      quantity = _React$useState12[0],
      setQuantity = _React$useState12[1];

  var _React$useState13 = React.useState(""),
      _React$useState14 = _slicedToArray(_React$useState13, 2),
      batch = _React$useState14[0],
      setBatch = _React$useState14[1];

  var _React$useState15 = React.useState(""),
      _React$useState16 = _slicedToArray(_React$useState15, 2),
      bbd = _React$useState16[0],
      setBbd = _React$useState16[1];

  var _React$useState17 = React.useState(false),
      _React$useState18 = _slicedToArray(_React$useState17, 2),
      isAddBatchConfirmed = _React$useState18[0],
      setIsAddBatchConfirmed = _React$useState18[1];

  var _React$useState19 = React.useState(0),
      _React$useState20 = _slicedToArray(_React$useState19, 2),
      totalQuantity = _React$useState20[0],
      setTotalQuantity = _React$useState20[1];

  var _React$useState21 = React.useState(false),
      _React$useState22 = _slicedToArray(_React$useState21, 2),
      isOneBatchOnlyModalOpen = _React$useState22[0],
      setIsOneBatchOnlyModalOpen = _React$useState22[1];

  var checkIsAddBatchConfirmed = function checkIsAddBatchConfirmed() {
    if (bbdType === "showBbd") {
      if (quantity !== "" && bbd !== "" && batch !== "") {
        setIsAddBatchConfirmed(true);
      } else {
        setIsAddBatchConfirmed(false);
      }
    } else {
      if (quantity !== "" && batch !== "") {
        setIsAddBatchConfirmed(true);
      } else {
        setIsAddBatchConfirmed(false);
      }
    }
  };

  var addNewBatch = function addNewBatch() {
    var data = {
      batch: batch,
      bbd: bbd,
      quantity: quantity
    };

    if (oneBatchOnly) {
      if (batchList.length === 0) {
        setBatchList([].concat(_toConsumableArray(batchList), [data]));
      } else {
        setIsOneBatchOnlyModalOpen(true);
      }
    } else {
      setBatchList([].concat(_toConsumableArray(batchList), [data]));
    }

    setBatch("");
    setBbd("");
    setQuantity("");
  };

  var removeBatch = function removeBatch(batch) {
    setBatchList(batchList.filter(function (e) {
      return e !== batch;
    }));
  };

  var openBbdInputField = function openBbdInputField() {
    if (bbdInputFieldType === "inputField") {
      setIsKeyboardBbdModalOpen(!isKeyboardBbdModalOpen);
    } else {
      setIsDatePickerBbdModalOpen(!isDatePickerBbdModalOpen);
    }
  };

  React.useEffect(function () {
    var tempTotalQuantity = 0;

    if (batchList.length > 0) {
      batchList.map(function (data) {
        tempTotalQuantity += parseInt(data.quantity);
      });
    }

    setTotalQuantity(tempTotalQuantity);
  }, [batchList]);
  React.useEffect(function () {
    checkIsAddBatchConfirmed();
  }, [quantity, bbd, batch]);
  React.useEffect(function () {
    if (isAddBatchConfirmed) {
      addNewBatch();
    }
  }, [isAddBatchConfirmed]);
  React.useEffect(function () {
    checkIsAddBatchConfirmed();
  }, [batch]); // Close Dialog On Option Change

  React.useEffect(function () {
    setIsKeyboardQuantityModalOpen(false);
    setIsKeyboardBbdModalOpen(false);
    setIsDatePickerBbdModalOpen(false);
    setIsKeyboardBatchModalOpen(false);
    setIsOneBatchOnlyModalOpen(false);
  }, [bbdType, bbdInputFieldType, oneBatchOnly]);
  React.useEffect(function () {
    setBatchList(data);
  }, [oneBatchOnly]);
  var whiteContentBarData = {
    leftContent: totalQuantity + " of " + totalBatches + " added",
    rightContent: ""
  };
  var greyContentPlainHeight;

  if (bbdType === "showBbd" || bbdType === "optionalBbd") {
    greyContentPlainHeight = previewHeight - 90 - 40 - 35 - 210;
  } else {
    greyContentPlainHeight = previewHeight - 90 - 40 - 35 - 155;
  }

  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "smallWithSub",
    showScanner: true,
    previewHeight: previewHeight
  }, React.createElement("p", null, "Add Batches"), React.createElement("sub", null, "Item ", React.createElement("b", null, "LM4029PH - LeMon 4029 Printhead")))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentTopRadius,
    style: {
      height: previewHeight - 90 - 50 + 10
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: whiteContentBarData
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.greyContentBar
  }, React.createElement(Grid, {
    container: true
  }, bbdType === "showBbd" || bbdType === "optionalBbd" ? React.createElement(React.Fragment, null, React.createElement(Grid, {
    className: classes.greyLeftContent,
    item: true,
    md: 4
  }, React.createElement("p", null, "Batch")), React.createElement(Grid, {
    className: classes.greyCenterContent,
    item: true,
    md: 4
  }, React.createElement("p", null, "BBD")), React.createElement(Grid, {
    className: classes.greyCenterContent,
    item: true,
    md: 4
  }, React.createElement("p", null, "Quantity"))) : React.createElement(React.Fragment, null, React.createElement(Grid, {
    className: classes.greyLeftContent,
    item: true,
    md: 8
  }, React.createElement("p", null, "Batch")), React.createElement(Grid, {
    className: classes.greyCenterContent,
    item: true,
    md: 4
  }, React.createElement("p", null, "Quantity"))))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.greyContentPlain,
    style: {
      height: greyContentPlainHeight
    }
  }, React.createElement(Grid, {
    container: true
  }, batchList.length > 0 ? batchList.map(function (batch) {
    return React.createElement(Grid, {
      item: true,
      md: 12,
      className: classes.whiteContentBarWithCloseButton
    }, React.createElement(Grid, {
      container: true
    }, bbdType === "showBbd" || bbdType === "optionalBbd" ? React.createElement(React.Fragment, null, React.createElement(Grid, {
      className: classes.blackLeftContent,
      item: true,
      md: 4
    }, batch.batch), React.createElement(Grid, {
      className: classes.blackCenterContent,
      item: true,
      md: 4
    }, batch.bbd), React.createElement(Grid, {
      className: classes.blackCenterContent,
      item: true,
      md: 4
    }, batch.quantity)) : React.createElement(React.Fragment, null, React.createElement(Grid, {
      className: classes.blackLeftContent,
      item: true,
      md: 8
    }, batch.batch), React.createElement(Grid, {
      className: classes.blackCenterContent,
      item: true,
      md: 4
    }, batch.quantity))), React.createElement(Close, {
      className: classes.whiteContentBarCloseButton,
      onClick: function onClick() {
        removeBatch(batch);
      }
    }));
  }) : React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentBarWithCloseButton
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    className: classes.greyCenterContent,
    item: true,
    md: 12
  }, React.createElement("p", null, "No batches added")))))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.greyContent
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBarButton, {
    data: {
      leftContent: "Enter Batch *",
      leftContentWithRightContent: "Batch",
      rightContent: batch
    },
    onClick: function onClick() {
      setIsKeyboardBatchModalOpen(!isKeyboardBatchModalOpen);
    }
  })), (bbdType === "showBbd" || bbdType === "optionalBbd") && React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBarButton, {
    data: {
      leftContent: "Enter BBD *",
      leftContentWithRightContent: "BBD",
      rightContent: bbd
    },
    onClick: function onClick() {
      openBbdInputField();
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBarButton, {
    data: {
      leftContent: "Enter Quantity *",
      leftContentWithRightContent: "Quantity",
      rightContent: quantity
    },
    onClick: function onClick() {
      setIsKeyboardQuantityModalOpen(!isKeyboardQuantityModalOpen);
    }
  })))))), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "left"
  }, "Cancel")), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "right",
    disabled: batchList.length === 0
  }, "Next"))), React.createElement(KeyboardCompleteModal, {
    title: "Batch",
    initialValue: batch,
    isModalOpen: isKeyboardBatchModalOpen,
    setInitialValue: setBatch,
    setOpenModalState: setIsKeyboardBatchModalOpen
  }), React.createElement(KeyboardNumberModal, {
    title: "Quantity",
    initialValue: quantity,
    isModalOpen: isKeyboardQuantityModalOpen,
    setInitialValue: setQuantity,
    setOpenModalState: setIsKeyboardQuantityModalOpen
  }), React.createElement(KeyboardNumberModal, {
    dateModal: true,
    title: "BBD",
    initialValue: bbd,
    isModalOpen: isKeyboardBbdModalOpen,
    setInitialValue: setBbd,
    setOpenModalState: setIsKeyboardBbdModalOpen
  }), React.createElement(DatePickerModal, {
    isModalOpen: isDatePickerBbdModalOpen,
    initialValue: bbd,
    setInitialValue: setBbd,
    setOpenModalState: setIsDatePickerBbdModalOpen
  }), React.createElement(WarningModal, {
    modalType: "singleOption",
    data: {
      message: "Only one batch can be added"
    },
    isModalOpen: isOneBatchOnlyModalOpen,
    onClickRedButton: function onClickRedButton() {
      setIsOneBatchOnlyModalOpen(false);
    }
  }));
};
export default React.memo(BatchesPage);