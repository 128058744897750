// React lib
import React, { memo } from 'react'

// Components
import {
    Chart,
    FONT_FAMILY,
    Color,
    EmptyChartData,
} from '@otg-one/ui_components'

const DeviceCountHistory = (props: any) => {
    const { data } = props

    let noChart = false;

    return (
        <div style={{ paddingTop: 30 }}>
            <Chart
                chartType="ColumnChart"
                chartData={[
                    ['Month', 'Device Usage'],
                    ...data
                ]}
                chartWidth={"100%"}
                chartHeight={"300px"}
                chartOptions={{
                    chartArea: {
                        left: '9%',
                        right: '4%',
                        top: 5,
                        bottom: 100,
                        width: "100%",
                        height: "300"
                    },
                    backgroundColor: "transparent",
                    colors: [Color.primary[900], Color.primary[200]],
                    animation: {
                        startup: true,
                        easing: 'out',
                        duration: 500,
                    },
                    legend: { position: 'bottom' },
                    series: {
                        0: { axis: 'Year', targetAxisIndex: 0 },
                        1: { axis: 'Device Usage', targetAxisIndex: 1 }
                    },
                    vAxes: {
                        0: {
                            textStyle: {
                                fontFamily: FONT_FAMILY.ROBOTO_LIGHT,
                                color: Color.neutral[400],
                            },
                            // ticks: getTicks(costUpperBound),
                            // viewWindow: {
                            //     min: 0,
                            //     max: costUpperBound
                            // },
                            format: '#,##0 Usage'
                        },
                        1: {
                            textStyle: {
                                fontFamily: FONT_FAMILY.ROBOTO_LIGHT,
                                color: Color.neutral[400],
                            },
                            // ticks: getTicks(loginUpperBound),
                            // viewWindow: {
                            //     min: 0,
                            //     max: loginUpperBound
                            // },
                        }
                    },
                    hAxis: {
                        textStyle: {
                            fontFamily: FONT_FAMILY.ROBOTO_LIGHT,
                            color: Color.neutral[400],
                        }
                    },
                    bar: {
                        groupWidth: "32"
                    },
                    tooltip: { isHtml: true, trigger: "visible" }
                }}
                chartEvents={
                    [
                        {
                            eventName: 'ready',
                            callback: (ce: any) => {
                                const chart = ce.chartWrapper.getChart();
                                var defses = document.getElementsByTagName('defs');
                                var gradientInjectFound = false;
                                Array.prototype.forEach.call(defses, function (defs) {
                                    if (defs.getAttribute('id') === 'gradientInject') {
                                        gradientInjectFound = true;
                                    }
                                });
                                if (!gradientInjectFound) {
                                    Array.prototype.forEach.call(defses, function (defs) {
                                        defs.insertAdjacentHTML('afterend', '<defs id="gradientInject"><linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%"><stop offset="0%" style="stop-color:#0077b6;stop-opacity:1" /><stop offset="100%" style="stop-color:#00527f;stop-opacity:1" /></linearGradient></defs>');
                                    });
                                }
                                var rects = chart.container.getElementsByTagName('rect');
                                Array.prototype.forEach.call(rects, function (rect) {
                                    if (rect.getAttribute('fill') === '#00527f') {
                                        rect.setAttribute('fill', 'url(#grad1)');
                                    }
                                });
                                ce.google.visualization.events.addListener(chart, "onmouseover", (e: any) => {
                                    var rects = chart.container.getElementsByTagName('rect');
                                    Array.prototype.forEach.call(rects, function (rect) {
                                        if (rect.getAttribute('fill') === '#00527f') {
                                            rect.setAttribute('fill', 'url(#grad1)');
                                        }
                                    });
                                });
                                ce.google.visualization.events.addListener(chart, "onmouseout", (e: any) => {
                                    var rects = chart.container.getElementsByTagName('rect');
                                    Array.prototype.forEach.call(rects, function (rect) {
                                        if (rect.getAttribute('fill') === '#00527f') {
                                            rect.setAttribute('fill', 'url(#grad1)');
                                        }
                                    });
                                });
                                ce.google.visualization.events.addListener(chart, "animationfinish", (e: any) => {
                                    var rects = chart.container.getElementsByTagName('rect');
                                    Array.prototype.forEach.call(rects, function (rect) {
                                        if (rect.getAttribute('fill') === '#00527f') {
                                            rect.setAttribute('fill', 'url(#grad1)');
                                        }
                                    });
                                });
                            },
                        },
                        {
                            eventName: 'select',
                            callback: (ce: any) => {
                                const chart = ce.chartWrapper.getChart();
                                var rects = chart.container.getElementsByTagName('rect');
                                Array.prototype.forEach.call(rects, function (rect) {
                                    if (rect.getAttribute('fill') === '#00527f') {
                                        rect.setAttribute('fill', 'url(#grad1)');
                                    }
                                });
                            },
                        },
                    ]
                }
            />
            {noChart && (
                <EmptyChartData />
            )}
        </div>
    )
}

export default memo(DeviceCountHistory)