// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    whiteContentBar: {
      backgroundColor: "#ffffff",
      height: "40px",
      borderBottom: "1px solid #f5f5f5",
      padding: "10px 15px 10px 15px"
    },
    leftContent: {
      fontSize: "12px",
      color: "#abadb1",
      marginTop: "2px",
      textAlign: "left",
      textTransform: "none"
    },
    rightContent: {
      fontSize: "12px",
      color: "black",
      marginTop: "2px",
      textAlign: "right",
      textTransform: "none"
    },
    rightIcon: {
      fontSize: "18px",
      float: "right",
      width: "18px !important",
      height: "18px !important"
    }
  });
});