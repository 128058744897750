// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    noPreviewWrapper: {
      backgroundColor: "rgba(0, 0, 0, 0.8)"
    },
    noPreviewIcon: {
      fontSize: "90px !important",
      width: "90px !important",
      height: "90px !important",
      color: "white !important",
      "& svg": {
        color: "white !important"
      }
    },
    noPreviewContent: {
      color: "white",
      textAlign: "center",
      marginTop: "10px",
      fontSize: "12px"
    }
  });
});