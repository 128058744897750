// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    systemMessageContainer: {
      boxSizing: "border-box",
      padding: 24,
      width: "100%",
      height: 300,
      backgroundColor: Color.neutral[400]
    },
    systemMessageListContainer: {
      maxHeight: 200,
      overflowY: "scroll"
    },
    systemMessageList: {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      height: 65,
      transition: "all .2s ease-in-out",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: Color.neutral[500]
      }
    }
  });
});