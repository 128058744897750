import _objectSpread from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
// React Lib
import React from "react"; // @otg-one/ui_components/constants

import { Color, FONT_FAMILY } from "../../constants"; // Material UI Core

import MUITypography from "@material-ui/core/Typography";
export var Typography = function Typography(props) {
  var id = props.id,
      fontFamily = props.fontFamily,
      size = props.size,
      color = props.color,
      style = props.style,
      className = props.className,
      onMouseOver = props.onMouseOver,
      onMouseOut = props.onMouseOut,
      onClick = props.onClick;
  return React.createElement(MUITypography, {
    id: id,
    style: _objectSpread({
      fontFamily: fontFamily,
      fontSize: size,
      color: color
    }, style),
    className: className,
    onMouseOver: onMouseOver,
    onMouseOut: onMouseOut,
    onClick: onClick
  }, props.children);
};
Typography.defaultProps = {
  fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
  size: 12,
  color: Color.neutral[900]
};
export default React.memo(Typography);