// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    floatingDiv: {
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0 0 5px 1px #d0d0d0",
      overflow: "hidden"
    },
    floatingDivBarTop: {
      height: "50px",
      textAlign: "left",
      "& p": {
        fontSize: "16px",
        fontWeight: "bold",
        marginLeft: "15px"
      }
    },
    keyboardDisplay: {
      height: "50px",
      textAlign: "left",
      borderTop: "1px solid #f4f6f6",
      "& p": {
        fontSize: "28px",
        margin: "0px 15px 0px 15px",
        lineHeight: "50px",
        letterSpacing: "2px",
        overflowX: "auto"
      }
    },
    keyboardButtonDiv: {
      padding: "7px",
      width: "285px",
      height: "240px"
    },
    keyboardButton: {
      textAlign: "center",
      backgroundColor: "#f3f6f6",
      width: "54px",
      height: "57px",
      minWidth: "54px",
      borderRadius: "0px",
      border: "1px solid white",
      boxSizing: "border-box",
      "& p": {
        fontSize: "20px",
        margin: "0px",
        color: "#00a7dd",
        textTransform: "lowercase"
      }
    },
    keyboardButtonCapslock: {
      "& p": {
        textTransform: "uppercase"
      }
    },
    keyboardButtonFix: {
      textAlign: "center",
      backgroundColor: "#f3f6f6",
      width: "54px",
      height: "57px",
      minWidth: "54px",
      borderRadius: "0px",
      border: "1px solid white",
      boxSizing: "border-box",
      color: "black !important",
      fontSize: "16px",
      display: "flex",
      align: "center",
      justifyContent: "center",
      "& p": {
        fontSize: "16px",
        margin: "0px",
        color: "black"
      }
    },
    keyboardIcon: {
      color: "black !important"
    },
    keyboardIconActive: {
      color: "#00a7dd !important",
      "& p": {
        color: "#00a7dd"
      }
    },
    floatingDivBarTitle: {
      textAlign: "left",
      backgroundColor: "#f6891f",
      height: "40px",
      paddingLeft: "15px",
      paddingTop: "10px",
      "& p": {
        fontSize: "15px",
        color: "white",
        margin: "0px"
      }
    },
    floatingDivBarBottom: {
      height: "50px"
    },
    floatingDivContent: {
      overflow: "auto"
    },
    floatingDivContentWithTitle: {
      overflow: "auto"
    },
    floatingDivContentButton: {
      height: "50px",
      width: "100%",
      justifyContent: "left",
      color: "#00a7dd",
      textTransform: "capitalize",
      paddingLeft: "30px",
      marginTop: "2px",
      borderRadius: "0",
      "&:hover": {
        color: "#f6891f"
      }
    },
    floatingDivContentButtonSelected: {
      backgroundColor: "#00a7dd",
      color: "white",
      "&:hover": {
        backgroundColor: "#8fcff6",
        color: "white"
      }
    }
  });
});