export class InventoryStatus {
	public static ENTRY_VIA_ITEM_SELECTION = "viaItemSelection";
	public static ENTRY_VIA_WAREHOUSE_SELECTION = "viaWarehouseSelection";

	public static SELECT_ITEM_INPUT_MANUAL = "manual";
	public static SELECT_ITEM_INPUT_SCAN = "scan";
	public static SELECT_ITEM_INPUT_OCR = "ocr"; // UNAVAILABLE
	public static SELECT_ITEM_INPUT_SHOPPING_ARTICLE = "shopping_article";

	public static WAREHOUSE_LAYOUT_WAREHOUSE_NAME = "warehouseName";
	public static WAREHOUSE_LAYOUT_WAREHOUSE_CODE = "warehouseCode";

	public static SELECT_WAREHOUSE_INPUT_MANUAL = "manual";
	public static SELECT_WAREHOUSE_INPUT_SCAN = "scan";
	public static SELECT_WAREHOUSE_INPUT_OCR = "ocr"; // UNAVAILABLE
	public static SELECT_WAREHOUSE_INPUT_SEARCH = "search"; // DO NOT SHOW! It exists in mapper, but not shown in UI

	public entry: string[];
	public selectItemInput: string[];
	public warehouseLayout: string;
	public selectWarehouseInput: string[];

	public constructor() {
		this.entry = [InventoryStatus.ENTRY_VIA_ITEM_SELECTION, InventoryStatus.ENTRY_VIA_WAREHOUSE_SELECTION];
		this.selectItemInput = [InventoryStatus.SELECT_ITEM_INPUT_SCAN];
		this.warehouseLayout = InventoryStatus.WAREHOUSE_LAYOUT_WAREHOUSE_NAME;
		this.selectWarehouseInput = [InventoryStatus.SELECT_WAREHOUSE_INPUT_SCAN];
	}
}
