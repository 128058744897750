export class GoodsReceipt {
	public static ENTRY_VIA_SUPPLIER = "via_supplier";
	public static ENTRY_VIA_DOCUMENT = "via_document";

	public static LIST_OF_SUPPLIER_ALL_SUPPLIERS = "all_suppliers";
	public static LIST_OF_SUPPLIER_OPEN_ORDER_SUPPLIERS = "open_order_suppliers";

	public static ORDER_SELECTION_ALL = "all";
	public static ORDER_SELECTION_MULTIPLE_ORDER = "multiple_order";
	public static ORDER_SELECTION_UNRELATED = "unrelated";

	public static DOC_INPUT_TYPE_MANUAL = "manual";
	public static DOC_INPUT_TYPE_SCAN = "scan";
	public static DOC_INPUT_TYPE_OCR = "ocr"; // UNAVAILABLE

	public static DISPLAYED_COLUMNS_TEMPLATE_A = "template_a";
	public static DISPLAYED_COLUMNS_TEMPLATE_B = "template_b";

	public static SELECT_ITEM_INPUT_MANUAL = "manual";
	public static SELECT_ITEM_INPUT_SCAN = "scan";
	public static SELECT_ITEM_INPUT_OCR = "ocr"; // UNAVAILABLE
	public static SELECT_ITEM_INPUT_SHOPPING_ARTICLE = "shopping_article";

	public static DISPLAYED_FIELDS_TEMPLATE_A = "template_a";
	public static DISPLAYED_FIELDS_TEMPLATE_B = "template_b"; // UNAVAILABLE

	public static ALLOW_QTY_CHANGE_LOWER_WITH_SECURITY = "lower_with_security";
	public static ALLOW_QTY_CHANGE_LOWER_WITHOUT_SECURITY = "lower_without_security";
	public static ALLOW_QTY_CHANGE_HIGHER_WITH_SECURITY = "higher_with_security";
	public static ALLOW_QTY_CHANGE_HIGHER_WITHOUT_SECURITY = "higher_without_security";

	public static WAREHOUSE_LAYOUT_WAREHOUSE_NAME = "warehouseName";
	public static WAREHOUSE_LAYOUT_WAREHOUSE_CODE = "warehouseCode";

	public static COMPLETE_ORDER_MODE_AT_LEAST_ONCE = "atLeastOnce";
	public static COMPLETE_ORDER_MODE_ALL = "all";

	public static BEST_BEFORE_DATE_OFF = "OFF";
	public static BEST_BEFORE_DATE_OPTIONAL = "OPTIONAL";
	public static BEST_BEFORE_DATE_ON = "ON";

	public static BEST_BEFORE_INPUT_TYPE_INPUT_FIELD = "inputField";
	public static BEST_BEFORE_INPUT_TYPE_DATE_PICKER = "datePicker";

	public entry: string[];
	public listOfSupplier: string;
	public orderSelection: string[];
	public docInputType: string[];
	public displayedColumns: string;
	public addNewPosition: boolean;
	public selectItemInput: string[];
	public quickCheck: boolean;
	public displayedFields: string;
	public allowQtyChange: string[];
	public warehouseLayout: string;
	public completeOrderMode: string;
	public showOrderPositionCount: boolean;
	public useGS1: boolean;
	public gs1FillEanLeadingZeros: boolean;
	public bestBeforeDate: string;
	public bestBeforeInputType: string;
	public allowBpReference: boolean;
	public allowOneBatchForOneBinLoc: boolean;
	public draftDocumentsForNewLine: boolean;

	public constructor() {
		this.entry = [GoodsReceipt.ENTRY_VIA_SUPPLIER, GoodsReceipt.ENTRY_VIA_DOCUMENT];
		this.listOfSupplier = GoodsReceipt.LIST_OF_SUPPLIER_ALL_SUPPLIERS;
		this.orderSelection = [];
		this.docInputType = [GoodsReceipt.DOC_INPUT_TYPE_SCAN];
		this.displayedColumns = GoodsReceipt.DISPLAYED_COLUMNS_TEMPLATE_A;
		this.addNewPosition = false;
		this.selectItemInput = [];
		this.quickCheck = true;
		this.displayedFields = GoodsReceipt.DISPLAYED_FIELDS_TEMPLATE_A;
		this.allowQtyChange = [];
		this.warehouseLayout = GoodsReceipt.WAREHOUSE_LAYOUT_WAREHOUSE_NAME;
		this.completeOrderMode = GoodsReceipt.COMPLETE_ORDER_MODE_AT_LEAST_ONCE;
		this.showOrderPositionCount = false;
		this.useGS1 = false;
		this.gs1FillEanLeadingZeros = false;
		this.bestBeforeDate = GoodsReceipt.BEST_BEFORE_DATE_OFF;
		this.bestBeforeInputType = GoodsReceipt.BEST_BEFORE_INPUT_TYPE_INPUT_FIELD;
		this.allowBpReference = false;
		this.allowOneBatchForOneBinLoc = false;
		this.draftDocumentsForNewLine = false;
	}
}
