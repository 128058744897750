// React libraries
import * as React from 'react'; // Custom Styles

import { useStyles } from "./ListSelectionPageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { BlueGradientHeader } from "../../index";
import { ListSelectionModal } from "../../index";
import { PageWrapper } from "../../index";
export var ListSelectionPage = function ListSelectionPage(props) {
  var classes = useStyles(props);
  var isModalOpen = props.isModalOpen,
      headerTitle = props.headerTitle,
      listSelectionModalTitle = props.listSelectionModalTitle,
      data = props.data,
      previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      dataTitle = props.dataTitle,
      showCount = props.showCount,
      listTypes = props.listTypes;
  return React.createElement(PageWrapper, {
    previewWidth: previewWidth,
    previewHeight: previewHeight
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "big",
    showScanner: false,
    previewHeight: previewHeight
  }, React.createElement("p", null, headerTitle))), React.createElement(ListSelectionModal, {
    showCount: showCount,
    listTypes: listTypes,
    data: data,
    dataTitle: dataTitle,
    isModalOpen: isModalOpen,
    title: listSelectionModalTitle
  })));
};
export default React.memo(ListSelectionPage);