// React lib
import React, { useContext, useEffect, useCallback, useRef, useState } from 'react';
import classnames from 'classnames';

import { useHistory, Prompt } from "react-router-dom";
import { AppContext } from '../../App';

// Styles
import { useStyles } from './ConfigurationContainerStyles';

// @otg-one/ui_components/components
import { Breadcrumb, Button, Typography, FONT_FAMILY, Information, Color, ShowMoreHorizontal, Drawer, Alert, Tooltip, OverflowMenu } from '@otg-one/ui_components';

// Material-UI Core
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

// Utils
import { getActiveViewportName, timeout } from '../../Utils/Utils';
import ConfigurationTitle from './ConfigurationTitle';
import SaveConfigurationDialog from './SaveConfigurationDialog';
import ExitConfigurationDialog from './ExitConfigurationDialog';
import BrowserButtonClickedDialog from './BrowserButtonClickedDialog';
import RestoreToDefaultDialog from './RestoreToDefaultDialog';
import RevertToPreviousSettingDialog from './RevertToPreviousSettingDialog';

const Sidebar = (props: any) => {
    const classes = useStyles(props)

    const { title, icon, menus, activeOption, isScrolling, setIsScrolling, handleClickShowMoreIcon } = props

    return (
        <div className={classes.sidebarContainer}>
            <div className={classes.sidebarTitle}>
                {icon}
                <Typography
                    fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                    size={18}
                    color={Color.neutral[900]}
                >
                    {title}
                </Typography>
                <ShowMoreHorizontal style={{ position: "absolute", top: 0, right: 24, cursor: "pointer" }} onClick={handleClickShowMoreIcon} />
            </div>
            <div style={{ marginLeft: -6 }}>
                <Drawer menus={menus} activeOption={activeOption} isScrolling={isScrolling} setIsScrolling={setIsScrolling} />
            </div>
        </div>
    )
};

const usePrevious = (value: any) => {
    if (typeof value === 'undefined') {
        value = ''
    }

    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const ConfigurationContainer = (props: any) => {
    const classes = useStyles(props);
    const history = useHistory();

    const theme = useTheme()
    const isTablet = useMediaQuery(theme.breakpoints.only('sm'))
    const isPhone = useMediaQuery(theme.breakpoints.only('xs'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    const { children, detectViewportCallback, activeViewport, activeOption, activeOptionName, activeMenu, title, icon, sidebarMenus, saveConfiguration, setIsModified, isModified, setListError, listError, setConfigurationToDefault, setConfigurationToPreviousSetting } = props

    const {
        breadcrumb,
        handleChangeBreadcrumb,
        blockNavigation,
        setBlockNavigation
    } = useContext(AppContext);

    const prevOption = usePrevious({ activeOption });

    // const [isScrollUp, setIsScrollUp] = useState(false) 
    // const [isScrollFromBottom, setIsScrollFromBottom] = useState(false)

    // const [isScrollToBottom, setIsScrollToBottom] = useState(false)
    // const [isScrollFromTop, setIsScrollFromTop] = useState(false)

    const [isFade, setIsFade] = useState(false)

    const [isScrolling, setIsScrolling] = useState(false)
    const [isDownscroll, setIsDownscroll] = useState(false)

    const [executeAnimation, setExecuteAnimation] = useState(false)

    const [saveConfigurationDialogOpen, setSaveConfigurationDialogOpen] = useState(false)
    const [exitConfigurationDialogOpen, setExitConfigurationDialogOpen] = useState(false)
    const [browserButtonClickedDialogOpen, setBrowserButtonClickedDialogOpen] = useState(false)
    const [restoreToDefaultDialogOpen, setRestoreToDefaultDialogOpen] = useState(false)
    const [revertToPreviousSettingDialogOpen, setRevertToPreviousSettingDialogOpen] = useState(false)

    const [restoreToDefaultAndSave, setRestoreToDefaultAndSave] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null)

    let interval: any;
    let lastScrollTop = 0

    const handleOpenSaveConfigurationDialog = () => {
        setBlockNavigation(false)
        setSaveConfigurationDialogOpen(true)
    }

    const handleCloseSaveConfigurationDialog = () => {
        setBlockNavigation(true)
        setSaveConfigurationDialogOpen(false)
    }

    const handleSaveConfiguration = () => {
        if (listError.length <= 0) {
            handleOpenSaveConfigurationDialog()
        }
    }

    const handleClickShowMoreIcon = (event: any) => {
        event.stopPropagation();

        if (!anchorEl) {
            setAnchorEl(event.currentTarget);
        };
    }

    const handleListError = () => {
        const link = document.getElementById("link-error") as any
        if (link) {
            link.href = `#${listError[0]}`
            link.click()
        }
    }

    const handleCloseExitConfigurationDialog = () => {
        setExitConfigurationDialogOpen(false)
    }

    const handleCloseBrowserButtonClickedDialog = () => {
        setBrowserButtonClickedDialogOpen(false)
    }

    const handleCloseRestoreToDefaultDialog = () => {
        setRestoreToDefaultDialogOpen(false)
    }

    const handleCloseRevertToPreviousSettingDialog = () => {
        setRevertToPreviousSettingDialogOpen(false)
    }

    const timeoutCallback = useCallback(event => {
        // Run the callback
        // console.log('Scrolling has stopped.')
        setIsScrolling(false)
    }, [])

    const handleDetectViewport = useCallback(event => {
        /** IF SIDEBAR IS CLICKED, DO NOT SET TO TRUE */
        detectViewportCallback()

        // Clear our timeout throughout the scroll
        window.clearTimeout(interval);

        // Set a timeout to run after scrolling ends
        interval = setTimeout(timeoutCallback, 66)

        let st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        if (st > lastScrollTop) {
            // downscroll code
            // console.log("downscroll")
            setIsDownscroll(true)
        } else {
            // upscroll code
            // console.log("upscroll")
            setIsDownscroll(false)
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    }, [])


    const handleClickRestoreToDefault = (withSave: boolean) => {
        new Promise((resolve, reject) => {
            setConfigurationToDefault();
            resolve(true);
        }).then(res => {
            if (withSave) {
                setRestoreToDefaultAndSave(true);
            }
            setListError([])
        })
    }

    const handleClickRevertToPreviousSetting = () => {
        new Promise((resolve, reject) => {
            setConfigurationToPreviousSetting();
            resolve(true);
        }).then(res => {
            setIsModified(false);
            setListError([])
        })
    }

    useEffect(() => {
        if (restoreToDefaultAndSave) {
            saveConfiguration();
        }
        setRestoreToDefaultAndSave(false)
    }, [restoreToDefaultAndSave])

    useEffect(() => {
        window.addEventListener('scroll', handleDetectViewport, false)

        return () => {
            window.removeEventListener('scroll', handleDetectViewport, false)
        }
    }, [handleDetectViewport])

    useEffect(() => {
        if (isModified) {
            setBlockNavigation(true)
        } else {
            setBlockNavigation(false)
        }
    }, [isModified])

    useEffect(() => {
        setIsFade(true)

        const setFade = async () => {
            await timeout(300)
            setIsFade(false)
        }

        setFade()
        // if (typeof prevOption !== 'undefined') {
        //     if ((prevOption as any).activeOption !== activeOption) {
        //         console.log(activeOption)
        //         if (isDownscroll) {
        //             setExecuteAnimation(true)

        //             setIsScrollUp(true)
        //             setIsScrollFromBottom(false)

        //             const setScrollUp = async () => {
        //                 await timeout(300)
        //                 setIsScrollUp(false)
        //                 setIsScrollFromBottom(true)

        //                 setExecuteAnimation(false)
        //             }

        //             setScrollUp()
        //         }
        //         else {
        //             setExecuteAnimation(true)

        //             setIsScrollToBottom(true)
        //             setIsScrollFromTop(false)

        //             const setScrollDown = async () => {
        //                 await timeout(300)
        //                 setIsScrollToBottom(false)
        //                 setIsScrollFromTop(true)

        //                 setExecuteAnimation(false)
        //             }

        //             setScrollDown()
        //         }
        //     }
        // }
    }, [activeOption])

    const appPreviewContainerClasses = classnames({
        [classes.appPreviewContainer]: true,
        // [classes.appPreviewAnimationSlideTop]: (isDownscroll && executeAnimation) && isScrollUp,
        // [classes.appPreviewAnimationSlideFromBottom]: isDownscroll && isScrollFromBottom,

        // [classes.appPreviewAnimationSlideToBottom]: (!isDownscroll && executeAnimation) && isScrollToBottom,
        // [classes.appPreviewAnimationSlideFromTop]: !isDownscroll && isScrollFromTop
    })

    const handleBlockedNavigation = (...args: any[]) => {
        let nextLocation = args[0];
        let action = args[1];
        if (blockNavigation && !nextLocation.pathname.startsWith(history.location.pathname)) {
            if (action === "POP") {
                setBrowserButtonClickedDialogOpen(true);
            } else {
                setExitConfigurationDialogOpen(true);
            }
            return false;
        }
        return true;
    }

    return (
        <>
            <div id="configurationContainer" className={classes.configurationContainer} onScroll={handleDetectViewport}>
                {/* <div className={classes.configurationHeader}>
                <Breadcrumb stacks={breadcrumb} handleChangeBreadcrumb={handleChangeBreadcrumb} />
                <Button style={{ marginTop: -10 }}>
                    Save
                </Button>
                <Information style={{ marginLeft: 24, marginBottom: 10 }} />
            </div> */}
                <Grid container style={{ flexGrow: 1 }} spacing={2}>
                    {isDesktop && (
                        <>
                            <Grid item md={3}>
                                <Sidebar title={title} icon={icon} menus={sidebarMenus} activeOption={activeOption} isScrolling={isScrolling} setIsScrolling={setIsScrolling} handleClickShowMoreIcon={handleClickShowMoreIcon} />
                            </Grid>
                            <Grid item md={6} style={{ position: "relative" }}>
                                {activeMenu && (
                                    <ConfigurationTitle
                                        id={activeMenu.id}
                                        title={activeMenu.label}
                                        subtitle={activeMenu.subtitle}
                                        isCustomized={activeMenu.isCustomized}
                                    />
                                )}
                                {children}
                                <div style={{ height: "65vh" }} />
                            </Grid>
                        </>
                    )}

                    {isTablet && (
                        <>
                            <Grid item sm={4}>
                                <Sidebar title={title} icon={icon} menus={sidebarMenus} activeOption={activeOption} isScrolling={isScrolling} setIsScrolling={setIsScrolling} handleClickShowMoreIcon={handleClickShowMoreIcon} />
                            </Grid>
                            <Grid item sm={8} style={{ position: "relative" }}>
                                {activeMenu && (
                                    <ConfigurationTitle
                                        id={activeMenu.id}
                                        title={activeMenu.label}
                                        subtitle={activeMenu.subtitle}
                                        isCustomized={activeMenu.isCustomized}
                                    />
                                )}
                                {children}
                                <div style={{ height: "65vh" }} />
                            </Grid>
                        </>
                    )}

                    {isPhone && (
                        <Grid item xs={12} style={{ position: "relative" }}>
                            {activeMenu && (
                                <ConfigurationTitle
                                    id={activeMenu.id}
                                    title={activeMenu.label}
                                    subtitle={activeMenu.subtitle}
                                    isCustomized={activeMenu.isCustomized}
                                />
                            )}
                            {children}
                            <div style={{ height: "65vh" }} />
                        </Grid>
                    )}
                </Grid>

                {(isTablet || isPhone) && (
                    <div style={{ display: isModified ? 'block' : 'none', position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: Color.neutral[0], zIndex: 1000 }}>
                        <Button
                            style={{
                                display: 'flex',
                                flexGrow: 1,
                                outline: 'none',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 60
                            }}
                            onClick={handleSaveConfiguration}
                            disabled={!isModified || listError.length > 0}
                        >
                            {`Save Configuration`}
                        </Button>
                    </div>
                )}

                {isDesktop && (
                    <>
                        <div className={classes.saveConfigurationButton}>
                            <Button style={{ outline: "none" }} onClick={handleSaveConfiguration} disabled={!isModified || listError.length > 0}>
                                {`Save Configuration`}
                            </Button>
                        </div>

                        {listError.length > 0 && (
                            <>
                                <Tooltip title="Unable to Save Config" subTitle="There is configuration error(s), click this icon to show the error" placement="left">
                                    <div className={classes.alertIcon} onClick={handleListError}>
                                        <Alert />
                                        <a id="link-error" style={{ visibility: "hidden" }} />
                                    </div>
                                </Tooltip>
                            </>
                        )}

                        <div className={appPreviewContainerClasses}>
                            <Fade in={!isFade}>
                                <div>
                                    {typeof activeViewport.length !== 'undefined' ? "" : activeViewport}
                                </div>
                            </Fade>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                size={14}
                                color={Color.neutral[400]}
                                style={{ marginTop: "10px", textAlign: "left" }}
                            >
                                {activeOptionName}
                            </Typography>
                        </div>
                    </>
                )}
            </div>
            <SaveConfigurationDialog
                open={saveConfigurationDialogOpen}
                onClose={handleCloseSaveConfigurationDialog}
                handleSave={saveConfiguration}
            />
            <ExitConfigurationDialog
                open={exitConfigurationDialogOpen}
                onClose={handleCloseExitConfigurationDialog}
                handleSave={saveConfiguration}
                disableSaveButton={listError.length > 0}
            />
            <BrowserButtonClickedDialog
                open={browserButtonClickedDialogOpen}
                onClose={handleCloseBrowserButtonClickedDialog}
            />
            <RestoreToDefaultDialog
                open={restoreToDefaultDialogOpen}
                onClose={handleCloseRestoreToDefaultDialog}
                handleRestore={handleClickRestoreToDefault}
            />
            <RevertToPreviousSettingDialog
                open={revertToPreviousSettingDialogOpen}
                onClose={handleCloseRevertToPreviousSettingDialog}
                handleRevert={handleClickRevertToPreviousSetting}
            />
            <OverflowMenu
                anchorElement={anchorEl}
                setAnchorEl={setAnchorEl}
                menuItems={[
                    {
                        text: "Restore to Default",
                        onClick: () => setRestoreToDefaultDialogOpen(true)
                    },
                    {
                        text: "Revert to Previous Setting",
                        onClick: () => setRevertToPreviousSettingDialogOpen(true)
                    }
                ]}
            />
            <Prompt when={isModified} message={handleBlockedNavigation} />
        </>
    )
};

export default ConfigurationContainer;