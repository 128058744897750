// React lib
import React, { useContext, useState, useEffect } from 'react';

// Styles
import { useStyles } from '../Client/ClientDashboardStyles';

// Material-UI Core
import Grid from '@material-ui/core/Grid';

import moment from 'moment';

// Components
import {
    Typography,
    FONT_FAMILY,
    Color,
    Dropdown,
    Separator,
    EmptyChartData,
} from '@otg-one/ui_components';
import { AppContext } from '../../../App';
import { generateDropDownItems, getLastNYear } from '../../../Utils/Utils';
import ClientService from "../Client/ClientService";
import CustomerUsageService from './CustomerUsageService';
import { STACK_TYPE_CUSTOMER } from '../../../Constants/StackType';
import { InfoOutlined, FileCopy } from '@material-ui/icons';
import DeviceWorldMap from './Components/DeviceWorldMap';
import DeviceCountHistory from './Components/DeviceCountHistory';
import DeviceUsage from './Components/DeviceUsage';
import DeviceCountDetailDialog from './Dialogs/DeviceCountDetailDialog';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import IgnoreDeviceDialog from './Dialogs/IgnoreDeviceDialog';

const formatMonthNumberToName = (number: any) => {
    switch (number) {
        case '1':
            return 'Jan'
        case '2':
            return 'Feb'
        case '3':
            return 'Mär'
        case '4':
            return 'Apr'
        case '5':
            return 'Mai'
        case '6':
            return 'Jun'
        case '7':
            return 'Jul'
        case '8':
            return 'Aug'
        case '9':
            return 'Sep'
        case '10':
            return 'Okt'
        case '11':
            return 'Nov'
        case '12':
            return 'Dez'
    }
}

const DeviceCount = (props: any) => {
    const { devices, openDialog } = props

    const { setSnackbarMessage, handleShowSnackbar } = useContext(AppContext)

    const handleShowNotification = () => {
        setSnackbarMessage(`Device information detail copied to clipboard`)
        handleShowSnackbar();
    }

    return (
        <>
            {devices.length > 0 ? devices.map((device: any) => (
                <div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'start',
                            marginTop: 10,
                            marginBottom: 10
                        }}
                    >
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                            size={14}
                            color={Color.neutral[900]}
                            style={{ width: '35%' }}
                        >
                            {device.label}
                        </Typography>
                        <div
                            style={{
                                width: '100%',
                            }}
                        >
                            {device.systems.map((system: any, index: number) => (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'top',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '60%'
                                        }}
                                    >
                                        <Typography
                                            fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                            size={14}
                                            color={Color.neutral[900]}
                                        >
                                            {system.system}
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            width: '25%'
                                        }}
                                    >
                                        <Typography
                                            fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                            size={14}
                                            color={Color.neutral[900]}
                                        >
                                            {`${system.devices.length} Device(s)`}
                                        </Typography>
                                    </div>
                                    <div>
                                        <CopyToClipboard
                                            onCopy={handleShowNotification}
                                            text={
                                                `Partner:\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020${device.partner}\nKunde:\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020${device.client}\nOntego One ID:\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020${device.ontego_id}\nDevice(s):\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020${system.devices.length}\nDevice ID(s):\n\n${system.devices.map((device: any) => `${device.device}\n`).join('')}`
                                            }>
                                            <FileCopy style={{ color: Color.primary[300], cursor: 'pointer' }} />
                                        </CopyToClipboard>
                                        <InfoOutlined
                                            style={{ marginLeft: 12, color: Color.primary[300], cursor: 'pointer' }}
                                            onClick={openDialog(device, index)}
                                        />
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <Separator type="n200" />
                </div>
            )) : (
                <EmptyChartData />
            )}
        </>
    )
}

const NewRegistrations = (props: any) => {
    const { clients } = props

    return (
        <>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                    size={14}
                    color={Color.neutral[900]}
                    style={{ width: '15%' }}
                >
                    {`Company`}
                </Typography>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                    size={14}
                    color={Color.neutral[900]}
                    style={{ width: '20%' }}
                >
                    {`Username`}
                </Typography>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                    size={14}
                    color={Color.neutral[900]}
                    style={{ width: '25%' }}
                >
                    {`Email`}
                </Typography>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                    size={14}
                    color={Color.neutral[900]}
                    style={{ width: '15%' }}
                >
                    {`Address`}
                </Typography>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                    size={14}
                    color={Color.neutral[900]}
                    style={{ width: '8%' }}
                >
                    {`Test`}
                </Typography>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                    size={14}
                    color={Color.neutral[900]}
                    style={{ width: '8%' }}
                >
                    {`Test Login`}
                </Typography>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                    size={14}
                    color={Color.neutral[900]}
                    style={{ width: '8%' }}
                >
                    {`Prod`}
                </Typography>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                    size={14}
                    color={Color.neutral[900]}
                    style={{ width: '8%' }}
                >
                    {`Prod Login`}
                </Typography>
            </div>
            <div style={{ width: '100%', marginBottom: 10, marginTop: 10 }}>
                <Separator />
            </div>
            {clients.map((data: any) => (
                <div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                size={14}
                                color={Color.neutral[900]}
                                style={{ width: '15%' }}
                            >
                                {data.billing_org_name}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                size={14}
                                color={Color.neutral[900]}
                                style={{ width: '20%' }}
                            >
                                {data.contact_name}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                size={14}
                                color={Color.neutral[900]}
                                style={{ width: '25%' }}
                            >
                                {data.email}
                            </Typography>
                            <Typography
                                fontFamily={data.address !== '' ? FONT_FAMILY.ROBOTO_REGULAR : FONT_FAMILY.ROBOTO_REGULAR_ITALIC}
                                size={data.address !== '' ? 14 : 11}
                                color={data.address !== '' ? Color.neutral[900] : Color.neutral[500]}
                                style={{ width: '15%' }}
                            >
                                {data.address !== '' ? data.address : `Keine Addresse`}
                            </Typography>
                            <Typography
                                fontFamily={data.test_system ? (data.test_system.length > 0 ? FONT_FAMILY.ROBOTO_MEDIUM : FONT_FAMILY.ROBOTO_LIGHT) : FONT_FAMILY.ROBOTO_LIGHT}
                                size={14}
                                color={data.test_system ? (data.test_system.length > 0 ? Color.primary[700] : Color.neutral[900]) : Color.neutral[900]}
                                style={{ width: '8%' }}
                            >
                                {data.test_system ? data.test_system.length : '-'}
                            </Typography>
                            <Typography
                                fontFamily={data.test_system_login ? (data.test_system_login.length > 0 ? FONT_FAMILY.ROBOTO_MEDIUM : FONT_FAMILY.ROBOTO_LIGHT) : FONT_FAMILY.ROBOTO_LIGHT}
                                size={14}
                                color={data.test_system_login ? (data.test_system_login.length > 0 ? Color.primary[700] : Color.neutral[900]) : Color.neutral[900]}
                                style={{ width: '8%' }}
                            >
                                {data.test_system_login ? data.test_system_login.length : '-'}
                            </Typography>
                            <Typography
                                fontFamily={data.prod_system ? (data.prod_system.length > 0 ? FONT_FAMILY.ROBOTO_MEDIUM : FONT_FAMILY.ROBOTO_LIGHT) : FONT_FAMILY.ROBOTO_LIGHT}
                                size={14}
                                color={data.prod_system ? (data.prod_system.length > 0 ? Color.primary[700] : Color.neutral[900]) : Color.neutral[900]}
                                style={{ width: '8%' }}
                            >
                                {data.prod_system ? data.prod_system.length : '-'}
                            </Typography>
                            <Typography
                                fontFamily={data.prod_system_login ? (data.prod_system_login.length > 0 ? FONT_FAMILY.ROBOTO_MEDIUM : FONT_FAMILY.ROBOTO_LIGHT) : FONT_FAMILY.ROBOTO_LIGHT}
                                size={14}
                                color={data.prod_system_login ? (data.prod_system_login.length > 0 ? Color.primary[700] : Color.neutral[900]) : Color.neutral[900]}
                                style={{ width: '8%' }}
                            >
                                {data.prod_system_login ? data.prod_system_login.length : '-'}
                            </Typography>
                        </div>
                        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FileCopy style={{ color: Color.primary[300] }} />
                            <InfoOutlined style={{ marginLeft: 12, color: Color.primary[300] }} />
                        </div> */}
                    </div>
                    <Separator type="n200" />
                </div>
            ))}
        </>
    )
}

const CustomerUsageDashboard = (props: any) => {
    const classes = useStyles(props);

    const {
        breadcrumb,
        getCurrentBreadcrumbData,
        currentUserData,
        setTotalCost
    } = useContext(AppContext);

    const [client, setClient] = useState(getCurrentBreadcrumbData(STACK_TYPE_CUSTOMER));
    const [clientActiveDevice, setClientActiveDevice] = useState(0);

    const [systemList, setSystemList] = useState([]);
    const [systems, setSystems] = useState([]);

    /** Device Count */
    const [deviceCount, setDeviceCount] = useState([])
    const [deviceCountMonth, setDeviceCountMonth] = useState(moment().format('YYYY-MM'))
    const [deviceCountDetailDialogOpen, setDeviceCountDetailDialogOpen] = useState(false)
    const [inspectedDevice, setInspectedDevice] = useState(null)

    const [ignoreDeviceDialogOpen, setIgnoreDeviceDialogOpen] = useState(false)
    const [inspectedDeviceId, setInspectedDeviceId] = useState(null)

    const [temp, setTemp] = useState(0)

    /** Map */
    const [mapCount, setMapCount] = useState([])
    const [region, setRegion] = useState('world')

    /** Device Count History */
    const [deviceCountHistory, setDeviceCountHistory] = useState([])
    const [deviceCountHistoryYear, setDeviceCountHistoryYear] = useState(moment().format('YYYY'))
    const [deviceCountHistoryCustomers, setDeviceCountHistoryCustomers] = useState([{
        text: 'Select Customer',
        onClick: () => { }
    }])

    /** Device Usage */
    const [deviceUsage, setDeviceUsage] = useState([])
    const [deviceUsageYear, setDeviceUsageYear] = useState(moment().format('YYYY'))
    const [deviceUsageCustomers, setDeviceUsageCustomers] = useState([{
        text: 'Select Customer',
        onClick: () => { }
    }])
    const [deviceUsageType, setDeviceUsageType] = useState('login')

    /** New registrations */
    const [newRegistrations, setNewRegistrations] = useState([])
    const [newRegistrationsYear, setNewRegistrationsYear] = useState(moment().format('YYYY'))

    useEffect(() => {
        const clientFromBreadcrumb = getCurrentBreadcrumbData(STACK_TYPE_CUSTOMER);
        setClient(clientFromBreadcrumb);
    }, [breadcrumb]);

    useEffect(() => {
        if (Object.keys(client).length !== 0) {
            getTotalCost();
            getSystems();
        }
    }, [client]);

    useEffect(() => {
        CustomerUsageService.getDeviceCount(currentUserData.key, deviceCountMonth).then((res: any) => {
            setDeviceCount(res.data)
        })
    }, [deviceCountMonth, temp])

    useEffect(() => {
        CustomerUsageService.getMapCount(currentUserData.key).then((res: any) => {
            setMapCount(res.data)
        })
    }, [temp])

    useEffect(() => {
        CustomerUsageService.getDeviceCountHistory(currentUserData.key, deviceCountHistoryYear).then((res: any) => {
            const newDeviceCountHistoryCustomers: any = [{ text: "All", onClick: () => handleAllDeviceCountHistory(res.data) }]
            const newDeviceCountHistory: any = []

            res.data.forEach((data: any, index: number) => {
                newDeviceCountHistoryCustomers.push({
                    text: data.client.substring(0, 25),
                    onClick: () => handleChangeDeviceCountHistory(data)
                })

                if (index === 0) {
                    Object.keys(data.deviceCount).forEach(month => {
                        newDeviceCountHistory.push([formatMonthNumberToName(month), data.deviceCount[month].length])
                    })
                } else {
                    Object.keys(data.deviceCount).forEach(month => {
                        let monthNum = parseInt(month) - 1;
                        if (data.deviceCount[month].length > 0) {
                            newDeviceCountHistory[monthNum] = [formatMonthNumberToName(month), newDeviceCountHistory[monthNum][1] + data.deviceCount[month].length]
                        }
                    })
                }
            })

            setDeviceCountHistoryCustomers(newDeviceCountHistoryCustomers)
            setDeviceCountHistory(newDeviceCountHistory)
        })
    }, [deviceCountHistoryYear, temp]);

    useEffect(() => {
        CustomerUsageService.getDeviceUsage(currentUserData.key, deviceUsageYear, deviceUsageType).then((res: any) => {
            const newDeviceUsageCustomers: any = [{ text: "All", onClick: () => handleAllDeviceUsage(res.data) }]
            const newDeviceUsage: any = []

            const el = document.getElementById('device-usage-customer-dropdown-dropdownText')

            if (el) {
                res.data.forEach((data: any, index: number) => {
                    newDeviceUsageCustomers.push({
                        text: data.client.substring(0, 25),
                        onClick: () => handleChangeDeviceUsage(data)
                    })

                    if (el.textContent === data.client) {
                        Object.keys(data.deviceCount).forEach(month => {
                            newDeviceUsage.push([formatMonthNumberToName(month), parseInt(data.deviceCount[month])])
                        })
                    }
                })

                if (el.textContent === "All" || el.textContent === 'Select Customer') {
                    res.data.forEach((data: any, index: number) => {
                        if (index === 0) {
                            Object.keys(data.deviceCount).forEach(month => {
                                newDeviceUsage.push([formatMonthNumberToName(month), parseInt(data.deviceCount[month])])

                            })
                        } else {
                            Object.keys(data.deviceCount).forEach(month => {
                                let monthNum = parseInt(month) - 1;
                                if (data.deviceCount[month] > 0) {
                                    newDeviceUsage[monthNum] = [formatMonthNumberToName(month), parseInt(newDeviceUsage[monthNum][1]) + parseInt(data.deviceCount[month])]
                                }
                            })
                        }
                    })
                }

                setDeviceUsageCustomers(newDeviceUsageCustomers)
                setDeviceUsage(newDeviceUsage)
            }
        })
    }, [deviceUsageYear, deviceUsageType, temp]);

    useEffect(() => {
        CustomerUsageService.getNewRegistrations(currentUserData.key, newRegistrationsYear).then((res: any) => {
            setNewRegistrations(res.data)
        })
    }, [newRegistrationsYear])

    const [selectedYear, setSelectedYear] = useState(-1);

    const handleDropDownClick = (value: any) => {
        value = typeof value === 'string' ? -1 : value;
        setSelectedYear(value);
    }

    const DROPDOWN_MENU = generateDropDownItems(getLastNYear(), handleDropDownClick);

    const getSystems = () => {
        ClientService.getSystems(currentUserData.key, client.key).then((res: any) => {
            setSystems(res.data);
            setSystemList(res.data);

            let clientActiveDevice = 0;
            for (const system of res.data) {
                if (system.deploymentEnv === 1) {
                    const deviceLimit = system.deviceLimit == null ? system.defaultDeviceLimit : system.deviceLimit;
                    const activeDevice = system.activeDevice > deviceLimit ? deviceLimit : system.activeDevice;
                    clientActiveDevice += activeDevice;
                }
            }

            setClientActiveDevice(clientActiveDevice);
        });
    };

    const getTotalCost = () => {
        ClientService.getTotalCost(currentUserData.key, client.key).then((res: any) => {
            setTotalCost(res.data);
        }).catch(error => {
            // TODO: error handling
            return error;
        });
    };

    const handleChangeRegion = (region: string) => () => setRegion(region)
    const handleChangeDeviceCountMonth = (month: string) => () => setDeviceCountMonth(month)
    const handleChangeDeviceCountHistoryYear = (year: string) => () => setDeviceCountHistoryYear(year)
    const handleChangeDeviceUsageType = (type: string) => () => setDeviceUsageType(type)
    const handleChangeNewRegistrationsYear = (year: string) => () => setNewRegistrationsYear(year)

    const handleAllDeviceCountHistory = (data: any) => {
        const newDeviceCountHistory = [] as any

        data.forEach((data: any, index: number) => {
            if (index === 0) {
                Object.keys(data.deviceCount).forEach(month => {
                    newDeviceCountHistory.push([formatMonthNumberToName(month), data.deviceCount[month].length])

                })
            } else {
                Object.keys(data.deviceCount).forEach(month => {
                    let monthNum = parseInt(month) - 1;
                    if (data.deviceCount[month].length > 0) {
                        newDeviceCountHistory[monthNum] = [formatMonthNumberToName(month), newDeviceCountHistory[monthNum][1] + data.deviceCount[month].length]
                    }
                })
            }
        })
        setDeviceCountHistory(newDeviceCountHistory)
    }

    const handleChangeDeviceCountHistory = (data: any) => {
        const newDeviceCountHistory = [] as any

        Object.keys(data.deviceCount).forEach(month => {
            newDeviceCountHistory.push([formatMonthNumberToName(month), data.deviceCount[month].length])
        })
        console.log(newDeviceCountHistory);

        setDeviceCountHistory(newDeviceCountHistory)
    }

    const handleAllDeviceUsage = (data: any) => {
        const newDeviceUsage = [] as any

        data.forEach((data: any, index: number) => {
            if (index === 0) {
                Object.keys(data.deviceCount).forEach(month => {
                    newDeviceUsage.push([formatMonthNumberToName(month), parseInt(data.deviceCount[month])])

                })
            } else {
                Object.keys(data.deviceCount).forEach(month => {
                    let monthNum = parseInt(month) - 1;
                    if (data.deviceCount[month] > 0) {
                        newDeviceUsage[monthNum] = [formatMonthNumberToName(month), parseInt(newDeviceUsage[monthNum][1]) + parseInt(data.deviceCount[month])]
                    }
                })
            }
        })

        setDeviceUsage(newDeviceUsage)
    }

    const handleChangeDeviceUsage = (data: any) => {
        const newDeviceUsage = [] as any

        Object.keys(data.deviceCount).forEach(month => {
            newDeviceUsage.push([formatMonthNumberToName(month), data.deviceCount[month]])
        })

        setDeviceUsage(newDeviceUsage)
    }

    const handleOpenDeviceCountDetailDialog = (device: any, index: number) => () => {
        const data = {
            partner: device.partner,
            client: device.client,
            ontego_id: device.ontego_id,
            system: device.systems[index]
        } as any

        setInspectedDevice(data)
        setDeviceCountDetailDialogOpen(true)
    }
    const handleCloseDeviceCountDetailDialog = () => {
        setInspectedDevice(null)
        setDeviceCountDetailDialogOpen(false)
    }

    const handleOpenIgnoreDeviceDialog = (deviceId: any) => () => {
        setInspectedDeviceId(deviceId)
        setIgnoreDeviceDialogOpen(true)
    }
    const handleCloseIgnoreDeviceDialog = () => {
        setInspectedDevice(null)
        setIgnoreDeviceDialogOpen(false)
    }

    return (
        <>
            <div className={classes.clientDashboardContainer}>
                <Grid container style={{ flexGrow: 1 }} spacing={2}>
                    <Grid item md={12} xs={12}>
                        <div className={classes.deviceCount}>
                            {/** TITLE */}
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={18}
                                color={Color.neutral[900]}
                            >
                                {`Device Count`}
                            </Typography>
                            {/** TABS */}
                            <div
                                style={{
                                    marginTop: 75,
                                    overflowY: 'scroll',
                                    height: '250px'
                                }}
                            >
                                <DeviceCount
                                    devices={deviceCount}
                                    openDialog={handleOpenDeviceCountDetailDialog}
                                />
                            </div>
                            <div className={classes.chartDropdown}>
                                {<Dropdown
                                    menu={[
                                        { text: `${moment().format('YYYY')}-01`, onClick: handleChangeDeviceCountMonth(`${moment().format('YYYY')}-01`) },
                                        { text: `${moment().format('YYYY')}-02`, onClick: handleChangeDeviceCountMonth(`${moment().format('YYYY')}-02`) },
                                        { text: `${moment().format('YYYY')}-03`, onClick: handleChangeDeviceCountMonth(`${moment().format('YYYY')}-03`) },
                                        { text: `${moment().format('YYYY')}-04`, onClick: handleChangeDeviceCountMonth(`${moment().format('YYYY')}-04`) },
                                        { text: `${moment().format('YYYY')}-05`, onClick: handleChangeDeviceCountMonth(`${moment().format('YYYY')}-05`) },
                                        { text: `${moment().format('YYYY')}-06`, onClick: handleChangeDeviceCountMonth(`${moment().format('YYYY')}-06`) },
                                        { text: `${moment().format('YYYY')}-07`, onClick: handleChangeDeviceCountMonth(`${moment().format('YYYY')}-07`) },
                                        { text: `${moment().format('YYYY')}-08`, onClick: handleChangeDeviceCountMonth(`${moment().format('YYYY')}-08`) },
                                        { text: `${moment().format('YYYY')}-09`, onClick: handleChangeDeviceCountMonth(`${moment().format('YYYY')}-09`) },
                                        { text: `${moment().format('YYYY')}-10`, onClick: handleChangeDeviceCountMonth(`${moment().format('YYYY')}-10`) },
                                        { text: `${moment().format('YYYY')}-11`, onClick: handleChangeDeviceCountMonth(`${moment().format('YYYY')}-11`) },
                                        { text: `${moment().format('YYYY')}-12`, onClick: handleChangeDeviceCountMonth(`${moment().format('YYYY')}-12`) }
                                    ]}
                                    selected={moment().format('YYYY-MM')}
                                    type="SELECTION"
                                    backgroundColor={Color.neutral[0]}
                                />}
                            </div>
                        </div>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <div className={classes.deviceCountHistory}>
                            {/** TITLE */}
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={18}
                                color={Color.neutral[900]}
                            >
                                {`Device Count History`}
                            </Typography>
                            {/** TABS */}
                            <div
                                style={{
                                    marginTop: 75
                                }}
                            >
                                <DeviceCountHistory
                                    data={deviceCountHistory}
                                />
                            </div>
                            <div className={classes.secondChartDropdown}>
                                {<Dropdown
                                    menu={[
                                        { text: `${moment().format('YYYY')}`, onClick: handleChangeDeviceCountHistoryYear(`${moment().format('YYYY')}`) },
                                    ]}
                                    selected={moment().format('YYYY')}
                                    type="SELECTION"
                                    backgroundColor={Color.neutral[0]}
                                />}
                            </div>
                            <div className={classes.chartDropdown}>
                                {<Dropdown
                                    menu={deviceCountHistoryCustomers}
                                    selected={deviceCountHistoryCustomers.length > 0 ? deviceCountHistoryCustomers[0].text : 'Select Customer'}
                                    type="SELECTION"
                                    backgroundColor={Color.neutral[0]}
                                />}
                            </div>
                        </div>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <div className={classes.deviceUsage}>
                            {/** TITLE */}
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={18}
                                color={Color.neutral[900]}
                            >
                                {`Device Usage`}
                            </Typography>
                            {/** TABS */}
                            <div
                                style={{
                                    marginTop: 75
                                }}
                            >
                                <DeviceUsage
                                    data={deviceUsage}
                                />
                            </div>
                            <div className={classes.secondChartDropdown}>
                                {<Dropdown
                                    menu={[
                                        { text: `${moment().format('YYYY')}`, onClick: handleChangeDeviceCountHistoryYear(`${moment().format('YYYY')}`) },
                                    ]}
                                    selected={moment().format('YYYY')}
                                    type="SELECTION"
                                    backgroundColor={Color.neutral[0]}
                                />}
                            </div>
                            <div className={classes.chartDropdown}>
                                {<Dropdown
                                    id={`device-usage-customer-dropdown`}
                                    menu={deviceUsageCustomers}
                                    selected={deviceUsageCustomers.length > 0 ? deviceUsageCustomers[0].text : 'Select Customer'}
                                    type="SELECTION"
                                    backgroundColor={Color.neutral[0]}
                                />}
                            </div>
                            <div className={classes.thirdChartDropdown}>
                                {<Dropdown
                                    menu={[
                                        { text: `Login`, onClick: handleChangeDeviceUsageType('login') },
                                        { text: `Error`, onClick: handleChangeDeviceUsageType('error') }
                                    ]}
                                    selected={'Login'}
                                    type="SELECTION"
                                    backgroundColor={Color.neutral[0]}
                                />}
                            </div>
                        </div>
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                height: 650,
                                backgroundColor: Color.neutral[100],
                                boxSizing: "border-box",
                                padding: "16px 24px 16px 24px"
                            }}
                        >
                            {/** TITLE */}
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={18}
                                color={Color.neutral[900]}
                            >
                                {`Map`}
                            </Typography>
                            {/** TABS */}
                            <div
                                style={{
                                    marginTop: 75
                                }}
                            >
                                <DeviceWorldMap
                                    data={mapCount}
                                    region={region}
                                />
                            </div>
                            <div style={{ position: "absolute", top: 16, right: 16 }}>
                                {<Dropdown
                                    menu={[
                                        { text: 'World', onClick: handleChangeRegion('world') },
                                        { text: 'Africa', onClick: handleChangeRegion('002') },
                                        { text: 'Europe', onClick: handleChangeRegion('150') },
                                        { text: 'Americas', onClick: handleChangeRegion('019') },
                                        { text: 'Asia', onClick: handleChangeRegion('142') },
                                        { text: 'Oceania', onClick: handleChangeRegion('009') },
                                    ]}
                                    selected={'World'}
                                    type="SELECTION"
                                    backgroundColor={Color.neutral[0]}
                                />}
                            </div>
                        </div>
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                height: 'fit-content',
                                backgroundColor: Color.neutral[100],
                                boxSizing: "border-box",
                                padding: "16px 24px 16px 24px"
                            }}
                        >
                            {/** TITLE */}
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={18}
                                color={Color.neutral[900]}
                            >
                                {`New Registrations`}
                            </Typography>
                            {/** TABS */}
                            <div
                                style={{
                                    marginTop: 75
                                }}
                            >
                                <NewRegistrations
                                    clients={newRegistrations}
                                />
                            </div>
                            <div style={{ position: "absolute", top: 16, right: 16 }}>
                                {<Dropdown
                                    menu={[
                                        { text: `${moment().format('YYYY')}`, onClick: handleChangeNewRegistrationsYear(`${moment().format('YYYY')}`) },
                                        { text: `2020`, onClick: handleChangeNewRegistrationsYear(`2020`) }
                                    ]}
                                    selected={moment().format('YYYY')}
                                    type="SELECTION"
                                    backgroundColor={Color.neutral[0]}
                                />}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {inspectedDevice && (
                <DeviceCountDetailDialog
                    open={deviceCountDetailDialogOpen}
                    onClose={handleCloseDeviceCountDetailDialog}
                    deviceDetail={inspectedDevice}
                    ignoreDevice={handleOpenIgnoreDeviceDialog}
                />
            )}
            {inspectedDeviceId && (
                <IgnoreDeviceDialog
                    open={ignoreDeviceDialogOpen}
                    onClose={handleCloseIgnoreDeviceDialog}
                    deviceId={inspectedDeviceId}
                    temp={temp}
                    setTemp={setTemp}
                />
            )}
        </>
    )
}

export default CustomerUsageDashboard;