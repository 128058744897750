// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    whiteContentTopRadius: {
      backgroundColor: "#ffffff",
      marginTop: "-10px !important",
      textAlign: "center",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      paddingBottom: "20px",
      overflow: "hidden"
    },
    listOrderItemsDiv: {
      backgroundColor: "#f3f6f6",
      overflow: "auto"
    },
    listOrderItemsButton: {
      backgroundColor: "white",
      borderBottom: "1px solid #d2d4d6",
      marginBottom: "3px",
      padding: "15px",
      textTransform: "capitalize",
      borderRadius: 0
    },
    listOrderItemsButtonTitle: {
      fontSize: "10px",
      color: "#abadb1",
      textAlign: "left"
    },
    listOrderItemsButtonLeftContent: {
      fontSize: "12px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textAlign: "left"
    },
    listOrderItemsButtonRightContent: {
      fontSize: "12px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textAlign: "right"
    },
    greyContentPlain: {
      backgroundColor: "#f3f6f6",
      overflow: "auto"
    },
    greyContent: {
      backgroundColor: "#f3f6f6",
      overflow: "auto",
      padding: "15px"
    },
    greyContentBar: {
      backgroundColor: "#f3f6f6",
      height: "35px",
      borderBottom: "1px solid #f5f5f5",
      padding: "10px"
    },
    greyLeftContent: {
      "& p": {
        fontSize: "12px",
        color: "#abadb1",
        marginTop: 0,
        textAlign: "left",
        textTransform: "capitalize"
      }
    },
    greyCenterContent: {
      "& p": {
        fontSize: "12px",
        marginTop: 0,
        color: "#abadb1",
        textAlign: "center",
        textTransform: "capitalize"
      }
    },
    greyRightContent: {
      "& p": {
        fontSize: "12px",
        marginTop: 0,
        color: "#abadb1",
        textAlign: "right",
        textTransform: "capitalize"
      }
    },
    whiteContentBarWithCloseButton: {
      backgroundColor: "#ffffff",
      height: "40px",
      borderBottom: "1px solid #f5f5f5",
      padding: "10px",
      position: "relative"
    },
    whiteContentBarCloseButton: {
      position: "absolute",
      color: "#00a7dd",
      top: 6,
      right: 15
    },
    blackLeftContent: {
      fontSize: "12px",
      color: "black",
      marginTop: 0,
      textAlign: "left",
      textTransform: "capitalize"
    },
    blackRightContent: {
      fontSize: "12px",
      color: "black",
      marginTop: 0,
      textAlign: "right",
      textTransform: "capitalize"
    },
    blackCenterContent: {
      fontSize: "12px",
      color: "black",
      marginTop: 0,
      textAlign: "center",
      textTransform: "capitalize"
    }
  });
});