import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// React Lib
import React from "react";
import classnames from 'classnames'; // Component Styles

import { useStyles } from "./ButtonStyles"; // @otg-one/ui_components/icons

import ArrowLeft from "../../icons/Arrow/Left";
import ArrowRight from "../../icons/Arrow/Right";

var getIcon = function getIcon(alignment) {
  switch (alignment) {
    case "right":
      return React.createElement(ArrowRight, null);

    case "left":
      return React.createElement(ArrowLeft, null);
  }
};

export var Button = function Button(props) {
  var _classnames;

  var classes = useStyles(props);
  var id = props.id,
      tabIndex = props.tabIndex,
      type = props.type,
      color = props.color,
      disabled = props.disabled,
      icon = props.icon,
      alignIcon = props.alignIcon,
      onClick = props.onClick;
  var iconButton = getIcon(alignIcon);
  /** If icon is supplied from props, replace default arrow icon */

  if (icon) {
    iconButton = icon;
  }

  var buttonClasses = classnames((_classnames = {}, _defineProperty(_classnames, classes.button, true), _defineProperty(_classnames, classes[type], type), _defineProperty(_classnames, classes["".concat(type).concat(color)], "".concat(type).concat(color)), _defineProperty(_classnames, classes["".concat(type, "disabled").concat(color)], disabled), _defineProperty(_classnames, classes.withIcon, typeof alignIcon !== "undefined"), _classnames));
  return React.createElement("div", {
    id: id,
    className: buttonClasses,
    style: props.style,
    onClick: onClick,
    tabIndex: tabIndex
  }, alignIcon === "left" && React.createElement("div", {
    className: classes.buttonIconLeft
  }, iconButton), props.children, alignIcon === "right" && React.createElement("div", {
    className: classes.buttonIconRight
  }, iconButton));
};
Button.defaultProps = {
  tabIndex: 0,
  type: "primary",
  color: "blue",
  disabled: false,
  onClick: function onClick() {}
};
export default React.memo(Button);