// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    whiteContentTopRadius: {
      backgroundColor: "#ffffff",
      marginTop: "-10px !important",
      textAlign: "center",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      paddingBottom: "20px",
      overflow: "hidden"
    },
    contentWrapper: {
      padding: "20px"
    },
    scannerIcon: {
      fontSize: "90px !important",
      color: "black !important",
      height: "90px !important",
      width: "90px !important"
    },
    whiteContentBarContainer: {
      paddingLeft: "20px",
      paddingRight: "20px"
    },
    greyContentBar: {
      backgroundColor: "#f3f6f6",
      height: "35px",
      borderBottom: "1px solid #f5f5f5",
      padding: "10px"
    },
    greyLeftContent: {
      "& p": {
        fontSize: "12px",
        color: "#abadb1",
        marginTop: 0,
        textAlign: "left",
        textTransform: "capitalize"
      }
    },
    greyCenterContent: {
      "& p": {
        fontSize: "12px",
        marginTop: 0,
        color: "#abadb1",
        textAlign: "center",
        textTransform: "capitalize"
      }
    },
    greyRightContent: {
      "& p": {
        fontSize: "12px",
        marginTop: 0,
        color: "#abadb1",
        textAlign: "right",
        textTransform: "capitalize"
      }
    }
  });
});