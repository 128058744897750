export class PickPack {
	public static WAREHOUSE_LAYOUT_WAREHOUSE_NAME = "warehouseName";
	public static WAREHOUSE_LAYOUT_WAREHOUSE_CODE = "warehouseCode";

	public static WEIGHT_UNIT_G = "G";
	public static WEIGHT_UNIT_KG = "KG";

	public static COMPLETE_ORDER_MODE_AT_LEAST_ONCE = "atLeastOnce";
	public static COMPLETE_ORDER_MODE_ALL = "all";

	public warehouseLayout: string;
	public quickCheck: boolean;
	public allowLowerQuantity: boolean;
	public useContainer: boolean;
	public containerUdf: string;
	public palletHeight: string;
	public palletLength: string;
	public palletWidth: string;
	public useWeightLights: boolean;
	public exceededWeightValue: string;
	public containerMultiscan: boolean;
	public allowOneBatchForOneBinLoc: boolean;
	public weightUnit: string;
	public filterItems: string; // array with separator ", "
	public completeOrderMode: string;
	public heavyContainers: string; // array with separator ", "

	public constructor() {
		this.warehouseLayout = PickPack.WAREHOUSE_LAYOUT_WAREHOUSE_NAME;
		this.quickCheck = true;
		this.allowLowerQuantity = false;
		this.useContainer = false;
		this.containerUdf = "";
		this.palletHeight = "";
		this.palletLength = "";
		this.palletWidth = "";
		this.useWeightLights = false;
		this.exceededWeightValue = "";
		this.containerMultiscan = false;
		this.allowOneBatchForOneBinLoc = false;
		this.weightUnit = PickPack.WEIGHT_UNIT_KG;
		this.filterItems = "";
		this.completeOrderMode = PickPack.COMPLETE_ORDER_MODE_AT_LEAST_ONCE;
		this.heavyContainers = "";
	}
}
