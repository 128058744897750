import _objectSpread from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./InventoryCountingPositionDetailsPageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { WhiteContentBar } from "../../index";
import { WhiteContentBarButton } from "../../index";
import { KeyboardNumberModal } from "../../index";
import { KeyboardCompleteModal } from "../../index";
import { DatePickerModal } from "../../index";
export var InventoryCountingPositionDetailsPage = function InventoryCountingPositionDetailsPage(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      data = props.data,
      bbdType = props.bbdType,
      bbdInputFieldType = props.bbdInputFieldType,
      showExpectedQuantity = props.showExpectedQuantity;

  var _React$useState = React.useState(data),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      positionDetailData = _React$useState2[0],
      setPositionDetailData = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      isKeyboardQuantityModalOpen = _React$useState4[0],
      setIsKeyboardQuantityModalOpen = _React$useState4[1];

  var _React$useState5 = React.useState(false),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      isKeyboardBbdModalOpen = _React$useState6[0],
      setIsKeyboardBbdModalOpen = _React$useState6[1];

  var _React$useState7 = React.useState(false),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      isDatePickerBbdModalOpen = _React$useState8[0],
      setIsDatePickerBbdModalOpen = _React$useState8[1];

  var _React$useState9 = React.useState(false),
      _React$useState10 = _slicedToArray(_React$useState9, 2),
      isKeyboardBatchModalOpen = _React$useState10[0],
      setIsKeyboardBatchModalOpen = _React$useState10[1];

  var _React$useState11 = React.useState(""),
      _React$useState12 = _slicedToArray(_React$useState11, 2),
      quantity = _React$useState12[0],
      setQuantity = _React$useState12[1];

  var _React$useState13 = React.useState(""),
      _React$useState14 = _slicedToArray(_React$useState13, 2),
      batch = _React$useState14[0],
      setBatch = _React$useState14[1];

  var _React$useState15 = React.useState(""),
      _React$useState16 = _slicedToArray(_React$useState15, 2),
      bbd = _React$useState16[0],
      setBbd = _React$useState16[1];

  var _React$useState17 = React.useState(false),
      _React$useState18 = _slicedToArray(_React$useState17, 2),
      isAddBatchConfirmed = _React$useState18[0],
      setIsAddBatchConfirmed = _React$useState18[1];

  var openBbdInputField = function openBbdInputField() {
    if (bbdInputFieldType === "inputField") {
      setIsKeyboardBbdModalOpen(!isKeyboardBbdModalOpen);
    } else {
      setIsDatePickerBbdModalOpen(!isDatePickerBbdModalOpen);
    }
  };

  var checkIsAddBatchConfirmed = function checkIsAddBatchConfirmed() {
    if (bbdType === "showBbd") {
      if (quantity !== "" && bbd !== "" && batch !== "") {
        setIsAddBatchConfirmed(true);
      } else {
        setIsAddBatchConfirmed(false);
      }
    } else {
      if (quantity !== "" && batch !== "") {
        setIsAddBatchConfirmed(true);
      } else {
        setIsAddBatchConfirmed(false);
      }
    }
  };

  React.useEffect(function () {
    checkIsAddBatchConfirmed();
  }, [quantity, bbd, batch]);
  React.useEffect(function () {
    setPositionDetailData(_objectSpread(_objectSpread({}, positionDetailData), {}, {
      quantity: parseInt(quantity) ? parseInt(quantity) : 0
    }));
  }, [quantity]); // Close Dialog On Option Change

  React.useEffect(function () {
    setIsKeyboardQuantityModalOpen(false);
    setIsKeyboardBbdModalOpen(false);
    setIsDatePickerBbdModalOpen(false);
    setIsKeyboardBatchModalOpen(false);
  }, [bbdType, bbdInputFieldType]);
  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "smallWithSub",
    showScanner: true,
    previewHeight: previewHeight
  }, React.createElement("p", null, "Inventory Counting"), React.createElement("sub", null, "11111"))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentTopRadius,
    style: {
      height: previewHeight - 90 - 50 + 10
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "Item",
      rightContent: positionDetailData.item
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "Description",
      rightContent: positionDetailData.description
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "Warehouse",
      rightContent: positionDetailData.warehouseCode + " - " + positionDetailData.warehouseName
    }
  })), showExpectedQuantity === 'show' && React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "Quantity",
      rightContent: positionDetailData.quantity + " " + positionDetailData.quantityType
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "Unit",
      rightContent: positionDetailData.unit
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.greyContent,
    style: {
      height: previewHeight - 90 - 50 - 160 + 10
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBarButton, {
    data: {
      leftContent: "Enter Batch *",
      leftContentWithRightContent: "Batch",
      rightContent: batch
    },
    onClick: function onClick() {
      setIsKeyboardBatchModalOpen(!isKeyboardBatchModalOpen);
    }
  })), (bbdType === "showBbd" || bbdType === "optionalBbd") && React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBarButton, {
    data: {
      leftContent: "Enter BBD *",
      leftContentWithRightContent: "BBD",
      rightContent: bbd
    },
    onClick: function onClick() {
      openBbdInputField();
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBarButton, {
    data: {
      leftContent: "Enter Counted Quantity *",
      leftContentWithRightContent: "Counted Quantity",
      rightContent: quantity
    },
    onClick: function onClick() {
      setIsKeyboardQuantityModalOpen(!isKeyboardQuantityModalOpen);
    }
  })))))), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "left"
  }, "Cancel")), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "right",
    disabled: !isAddBatchConfirmed
  }, "Next"))), React.createElement(KeyboardCompleteModal, {
    title: "Batch",
    initialValue: batch,
    isModalOpen: isKeyboardBatchModalOpen,
    setInitialValue: setBatch,
    setOpenModalState: setIsKeyboardBatchModalOpen
  }), React.createElement(KeyboardNumberModal, {
    title: "Quantity",
    initialValue: quantity,
    isModalOpen: isKeyboardQuantityModalOpen,
    setInitialValue: setQuantity,
    setOpenModalState: setIsKeyboardQuantityModalOpen
  }), React.createElement(KeyboardNumberModal, {
    dateModal: true,
    title: "BBD",
    initialValue: bbd,
    isModalOpen: isKeyboardBbdModalOpen,
    setInitialValue: setBbd,
    setOpenModalState: setIsKeyboardBbdModalOpen
  }), React.createElement(DatePickerModal, {
    isModalOpen: isDatePickerBbdModalOpen,
    initialValue: bbd,
    setInitialValue: setBbd,
    setOpenModalState: setIsDatePickerBbdModalOpen
  }));
};
export default React.memo(InventoryCountingPositionDetailsPage);