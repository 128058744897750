// React lib
import React, { useState, useContext } from 'react';
import { AppContext } from '../../../App';

// Styles
import { useStyles } from './SystemDashboardStyles';

// Material-UI Core
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Typography, FONT_FAMILY, Color, Separator, Button, BreadcrumbType } from '@otg-one/ui_components';

// Constants
import { SYSTEM_CONFIGURATION } from '../../../Constants/FilePath';
import { Stack } from '../../../Models/Stack';


const SystemInfo = (props: any) => {
    const classes = useStyles(props);
    const { system } = props

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const {
        handleChangeBreadcrumb
    } = useContext(AppContext);

    const handleClickEditSystemConfiguration = () => {
        handleChangeBreadcrumb(BreadcrumbType.PUSH, new Stack('', "Edit System Configuration", SYSTEM_CONFIGURATION, system));
    }

    let connectionMethodName = ''

    if (system.connectionMethod) {
        system.connectionMethod.split('_').forEach((name: any, index: number) => {
            connectionMethodName += name.charAt(0).toUpperCase() + name.slice(1)
            if (index !== system.connectionMethod.split("_").length - 1) connectionMethodName += " "
        })
    }

    return (
        <Grid container style={{ flexGrow: 1 }} spacing={2} className={classes.systemInfoSeparator}>
            <Grid item md={12} xs={12}>
                <div className={classes.systemInfoHeader}>
                    <Typography
                        fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                        size={isMobile ? 16 : 24}
                        color={Color.neutral[900]}
                        style={{ marginBottom: 20 }}
                    >
                        {`System Info`}
                    </Typography>
                    {isDesktop && (
                        <Button
                            onClick={handleClickEditSystemConfiguration}
                            style={{ outline: "none" }}
                            disabled={system.isDemo}
                        >
                            {`Edit System Configuration`}
                        </Button>
                    )}
                </div>
            </Grid>

            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    {`System Name`}
                </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                    size={12}
                    color={Color.neutral[900]}
                >
                    {system.name}
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
            </Grid>

            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    {`Connection Method`}
                </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                    size={12}
                    color={Color.neutral[900]}
                >
                    {connectionMethodName}
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
            </Grid>

            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    {`Target System URL`}
                </Typography>

            </Grid>
            <Grid item md={8} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                    size={12}
                    color={Color.neutral[900]}
                >
                    {system.url}
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
            </Grid>

            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    {`Preselect Database Name`}
                </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                    size={12}
                    color={Color.neutral[900]}
                >
                    {system.dbName ? system.dbName : `-`}
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
            </Grid>

            {system.connectionMethod === 'integration_framework' && (
                <>
                    <Grid item md={4} xs={12}>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                            size={14}
                            color={Color.neutral[600]}
                        >
                            {`Preselect System ID`}
                        </Typography>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                            size={12}
                            color={Color.neutral[900]}
                        >
                            {system.b1SystemId ? system.b1SystemId : `-`}
                        </Typography>
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
                    </Grid>

                    <Grid item md={4} xs={12}>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                            size={14}
                            color={Color.neutral[600]}
                        >
                            {`Authentication Method`}
                        </Typography>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                            size={12}
                            color={Color.neutral[900]}
                        >
                            {system.authMethod}
                        </Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
                    </Grid>
                </>
            )}


            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    {`Note about custom field`}
                </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                    size={12}
                    color={Color.neutral[900]}
                >
                    {system.note}
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
            </Grid>
            {isMobile && (
                <Grid item sm={12} xs={12}>
                    <div style={{ flexGrow: 1 }}>
                        <Button
                            onClick={handleClickEditSystemConfiguration}
                            style={{ outline: "none", width: '-webkit-fill-available', justifyContent: 'center' }}
                            disabled={system.isDemo}
                        >
                            {`Edit System Configuration`}
                        </Button>
                    </div>
                </Grid>
            )}
        </Grid>
    )
}

export default SystemInfo