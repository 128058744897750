import React, { useState, useContext } from 'react';

// Components
import {
    Typography,
    FONT_FAMILY,
    Color,
    Button,
    DialogBox,
    Form
} from '@otg-one/ui_components';
import AuthService from './AuthService';
import { AppContext } from '../../App';

const ActivateAccountDialog = (props: any) => {
    const { handleShowSnackbar, setSnackbarMessage } = useContext(AppContext)
    const { open, onClose } = props

    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorMsgEmail, setErrorMsgEmail] = useState("")


    const handleChangeEmail = (e: React.FormEvent<HTMLInputElement>) => {
        setErrorEmail(false);
        setErrorMsgEmail("");
        setEmail(e.currentTarget.value)
    };

    const sendActivationMail = () => {
        setMessage("");
        if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            setErrorEmail(true);
            setErrorMsgEmail("Please enter a valid email address!");
            return;
        }
        AuthService.sendActivationLink(email).then((res: any) => {
            setSnackbarMessage("We've sent activation link to your email, please check your inbox and spam folder");
            handleShowSnackbar();

            onClose();
        }, (error: any) => {
            setMessage("Error send activation link!")
        });
    };

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title="Resend Activation Link"
            content={
                <div style={{ width: 480, paddingTop: 16 }}>
                    <Typography
                        fontFamily={FONT_FAMILY.CAPITOLIUM_ITALIC}
                        size={16}
                        color={Color.neutral[900]}
                        style={{ marginBottom: 13 }}
                    >
                        {`Please enter your email address to get your activation link`}
                    </Typography>
                    <Form
                        placeholder="Email address"
                        value={email}
                        onChange={handleChangeEmail}
                        error={errorEmail}
                        errorMessage={errorMsgEmail}
                    />
                    <div
                        style={{
                            boxSizing: "border-box",
                            padding: 10,
                            fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
                            color: Color.danger[300],
                            fontSize: 16,
                            textAlign: "center"
                        }}
                    >
                        {message}
                    </div>

                    <div style={{ display: "flex", justifyContent: "flex-start", marginTop: 20 }}>
                        <Button
                            onClick={sendActivationMail}
                        >Get Activation Link</Button>
                    </div>
                </div>
            }
        />
    )
};

export default ActivateAccountDialog;