// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { FONT_FAMILY } from '../../constants';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    chipContainer: function chipContainer(props) {
      return {
        display: "inline-flex",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "4px 12px 4px 12px",
        borderRadius: 13,
        fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
        fontSize: 12,
        background: props.backgroundColor,
        color: props.color,
        "& svg": {
          width: 16,
          height: 16,
          marginRight: 8
        }
      };
    }
  });
});