// React libraries
import * as React from 'react'; // Custom Styles

import { useStyles } from "./BlueButtonStyles"; // Material-UI Core

import Button from '@material-ui/core/Button';
export var BlueButton = function BlueButton(props) {
  var classes = useStyles(props);
  var onClick = props.onClick,
      disabled = props.disabled,
      selected = props.selected,
      type = props.type;
  return React.createElement(Button, {
    className: classes.blueButton,
    disabled: disabled,
    onClick: onClick
  }, props.children);
};
BlueButton.defaultProps = {
  onClick: function onClick() {}
};
export default React.memo(BlueButton);