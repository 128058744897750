// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    modal: {
      backgroundColor: "rgba(0, 82, 127, 0.70)",
      margin: 0,
      zIndex: 9999999,
      boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2)",
      position: "absolute",
      left: "0",
      top: "0",
      boxSizing: "border-box",
      textAlign: "center"
    },
    whiteBox: {
      background: "rgb(255, 255, 255)",
      borderRadius: "0px",
      boxShadow: "0px 2px 34px 0px rgba(0, 0, 0, 0.23)",
      height: "83px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "150px auto 0 auto",
      width: "90% !important"
    },
    whiteBoxContent: {
      color: "rgb(76, 81, 91)",
      fontSize: "16px"
    }
  });
});