import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
export var NoChart = function NoChart(props) {
  return React.createElement(SvgIcon, Object.assign({}, props), React.createElement("svg", {
    viewBox: "0 0 161 167",
    version: "1.1"
  }, React.createElement("g", {
    id: "Symbols",
    stroke: "none",
    "stroke-width": "1",
    fill: "none",
    "fill-rule": "evenodd"
  }, React.createElement("g", {
    id: "Barchart/Empty-state",
    transform: "translate(-308.000000, -92.000000)"
  }, React.createElement("g", {
    id: "Group-5",
    transform: "translate(197.000000, 91.000000)"
  }, React.createElement("g", {
    id: "No-Chart",
    transform: "translate(109.000000, 0.500000)"
  }, React.createElement("path", {
    d: "M76.1129032,40.2619048 L76.1129032,167.5 L33,167.5 L33,40.2619048 L76.1129032,40.2619048 Z M132,0.5 L132,167.5 L88.8870968,167.5 L88.8870968,0.5 L132,0.5 Z",
    id: "Combined-Shape",
    fill: "#F8F5FB"
  }), React.createElement("path", {
    d: "M-22.8707512,77.1701697 L187.446166,77.2880349 C190.527039,77.2897615 193.02415,79.7868717 193.025876,82.867745 C193.0276,85.9441578 190.535073,88.4394807 187.45866,88.4412048 C187.456579,88.4412059 187.454498,88.4412059 187.452417,88.4412048 L-22.8645008,88.3233395 C-25.9453742,88.3216129 -28.4424844,85.8245027 -28.444211,82.7436294 C-28.445935,79.6672166 -25.9534074,77.1718937 -22.8769947,77.1701697 C-22.8749135,77.1701685 -22.8728324,77.1701685 -22.8707512,77.1701697 Z",
    id: "Rectangle",
    fill: "#FFFFFF",
    opacity: "0.46",
    transform: "translate(82.290833, 82.805687) rotate(-45.000000) translate(-82.290833, -82.805687) "
  })))))));
};
export default NoChart;