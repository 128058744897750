// React lib
import React, { useState, createContext, useContext } from 'react';
import { AppContext } from '../../../App';

// Components
import ConfigurationContainer from '../ConfigurationContainer';

import { SETTINGS_FOR_DELIVERY, VIA_SUPPLIERS, VIA_ORDER_NUMBER, DISPLAYED_COLUMN, NEW_POSITION_ADDABLE, QUICK_CHECK, WAREHOUSE_NAME, COMPLETE_ORDER, ORDER_POSITION_COUNT, FIELDS_DISPLAYED, MORE_OR_LESS_QUANTITIES_ALLOWED, LIST_OF_SUPPLIERS, SELECTION_OF_ORDERS, TYPE_OF_ORDER_INPUT, ITEM_SELECTION, SETTINGS_FOR_ENTRY, ENTRY_OPTION, SETTINGS_FOR_LIST_OF_POSITION, SETTINGS_FOR_POSITION_DETAILS, LESS_QUANTITIES_SECURITY_CHECK, OVERBOOKING_SECURITY_CHECK, SERIAL_NUMBER_TYPE } from './DeliveryIds';
import { FunctionDelivery, SerialNumberType } from '@otg-one/ui_components';

import {
    ListOfOrderPositionsPage, ListPage, ListSelectionPage, EnterItemNumberPage, OrderPositionDetailsPage, NewPositionAddablePage, MenuPage, NoPreviewPage
} from '@otg-one/ui_components';
import SettingsForEntry from './SettingsForEntry';
import SettingsForListPositions from './SettingsForListPositions';
import SettingsForPositionDetails from './SettingsForPositionDetails';

// Models
import { Stack } from '../../../Models/Stack';
import { Delivery } from '../../../Models/Delivery'

// Service
import ConfigurationService from "../ConfigurationService";

// Utils
import { deliveryLocalMapToDatabaseValue, arraysMatch, arrayAdjustmentDefault, deliveryDatabaseMapToLocalValue } from "../../../Utils/Utils"
import { STACK_TYPE_APP_FUNCTION } from '../../../Constants/StackType';

export const DeliveryContext = createContext<any>(null);
const DeliveryContextProvider = DeliveryContext.Provider;

// Interface
export interface IDeliveryContextInterface {
    defaultFunctionType: any,
    activeOption: string,
    setActiveOption: any,

    entryOption: string[],
    handleChangeEntryOption: any,
    viaSuppliers: boolean,
    handleChangeViaSuppliers: any,
    viaOrderNumber: boolean,
    handleChangeViaOrderNumber: any,
    listOfSuppliers: string,
    handleChangeListOfSuppliers: any,
    selectionOfOrders: string[],
    handleChangeSelectionOfOrders: any,
    typeOfOrderInput: string[],
    handleChangeTypeOfOrderInput: any,

    displayedColumn: string,
    handleChangeDisplayedColumn: any,
    newPositionAddable: string,
    handleChangeNewPositionAddable: any,
    itemSelection: string[],
    handleChangeItemSelection: any,
    quickCheck: string,
    handleChangeQuickCheck: any,
    warehouseName: string,
    handleChangeWarehouseName: any,
    completeOrder: string,
    handleChangeCompleteOrder: any,
    orderPositionCount: string,
    handleChangeOrderPositionCount: any,

    fieldsDisplayed: string,
    handleChangeFieldsDisplayed: any,
    moreOrLessQuantitiesAllowed: string[],
    handleChangeMoreOrLessQuantitiesAllowed: any,

    lessQuantitiesSecurityCheck: string,
    handleChangeLessQuantitiesSecurityCheck: any,
    overbookingSecurityCheck: string,
    handleChangeOverbookingSecurityCheck: any,

    serialNumberType: string,
    handleChangeSerialNumberType: any
}

const DeliveryConfiguration = (props: any) => {
    const {
        breadcrumb,
        getCurrentBreadcrumbData,
        currentUserData,
        handleShowSnackbar,
        setSnackbarMessage
    } = useContext(AppContext);

    const [appData, setAppData] = useState(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION));
    const [defaultFunctionType, setDefaultFunctionType] = useState(null as any);

    const [isLoaded, setIsLoaded] = useState(false)
    const [isModified, setIsModified] = useState(false)
    const [listError, setListError] = useState([] as string[])

    const [activeOption, setActiveOption] = useState("")
    const [activeOptionName, setActiveOptionName] = useState("")

    const [activePreview, setActivePreview] = useState("" as any)
    const [activeMenu, setActiveMenu] = useState({})

    const [entryOption, setEntryOption] = useState([] as string[])

    const [viaSuppliers, setViaSuppliers] = useState(false)
    const [listOfSuppliers, setListOfSuppliers] = useState("allSuppliers")
    const [selectionOfOrders, setSelectionOfOrders] = useState(["all"] as string[])

    const [viaOrderNumber, setViaOrderNumber] = useState(false)
    const [typeOfOrderInput, setTypeOfOrderInput] = useState(["textInput"] as string[])

    const [displayedColumn, setDisplayedColumn] = useState(`templateA`)
    const [newPositionAddable, setNewPositionAddable] = useState(`disable`)
    const [itemSelection, setItemSelection] = useState([""] as string[])
    const [quickCheck, setQuickCheck] = useState(`disable`)
    const [warehouseName, setWarehouseName] = useState(`warehouseName`)
    const [completeOrder, setCompleteOrder] = useState(`atLeastOnePosition`)
    const [orderPositionCount, setOrderPositionCount] = useState(`show`)

    const [fieldsDisplayed, setFieldsDisplayed] = useState("templateA")
    const [moreOrLessQuantitiesAllowed, setMoreOrLessQuantitiesAllowed] = useState([] as string[])

    const [moreOrLessQuantitiesAllowedSecurityCheck, setMoreOrLessQuantitiesAllowedSecurityCheck] = useState([] as string[])
    const [lessQuantitiesSecurityCheck, setLessQuantitiesSecurityCheck] = useState('lessQuantityAllowedWithCheck')
    const [overbookingSecurityCheck, setOverbookingSecurityCheck] = useState('overbookingAllowedWithCheck')

    const [serialNumberType, setSerialNumberType] = useState('SER')

    const loadDefault = async () => {
        const response = await ConfigurationService.getFunctionType(currentUserData.key, appData.functionTypeId)
        setDefaultFunctionType(response.data);
    }

    const setConfiguration = (masterData: any) => {
        if (masterData.config) {
            Object.keys(masterData.config).map((key: any, index: any) => {
                if (key === "entry") {
                    setEntryOption(deliveryDatabaseMapToLocalValue("entry", masterData.config[key]));
                } else if (key === "listOfSupplier") {
                    setListOfSuppliers(deliveryDatabaseMapToLocalValue("listOfSupplier", masterData.config[key]))
                } else if (key === "orderSelection") {
                    setSelectionOfOrders(deliveryDatabaseMapToLocalValue("orderSelection", masterData.config[key]));
                } else if (key === "docInputType") {
                    setTypeOfOrderInput(deliveryDatabaseMapToLocalValue("docInputType", masterData.config[key]));
                } else if (key === "displayedColumns") {
                    setDisplayedColumn(deliveryDatabaseMapToLocalValue("displayedColumns", masterData.config[key]))
                } else if (key === "addNewPosition") {
                    setNewPositionAddable(deliveryDatabaseMapToLocalValue("addNewPosition", masterData.config[key]))
                } else if (key === "selectItemInput") {
                    setItemSelection(deliveryDatabaseMapToLocalValue("selectItemInput", masterData.config[key]));
                } else if (key === "quickCheck") {
                    setQuickCheck(deliveryDatabaseMapToLocalValue("quickCheck", masterData.config[key]))
                } else if (key === "warehouseLayout") {
                    setWarehouseName(deliveryDatabaseMapToLocalValue("warehouseLayout", masterData.config[key]))
                } else if (key === "completeOrderMode") {
                    setCompleteOrder(deliveryDatabaseMapToLocalValue("completeOrderMode", masterData.config[key]))
                } else if (key === "showOrderPositionCount") {
                    setOrderPositionCount(deliveryDatabaseMapToLocalValue("showOrderPositionCount", masterData.config[key]))
                } else if (key === "displayedFields") {
                    setFieldsDisplayed(deliveryDatabaseMapToLocalValue("displayedFields", masterData.config[key]))
                } else if (key === "allowQtyChange") {
                    setMoreOrLessQuantitiesAllowed(deliveryDatabaseMapToLocalValue("allowQtyChange", masterData.config[key]).moreOrLessQuantitiesAllowed)
                    setLessQuantitiesSecurityCheck(deliveryDatabaseMapToLocalValue("allowQtyChange", masterData.config[key]).lessQuantitiesSecurityCheck)
                    setOverbookingSecurityCheck(deliveryDatabaseMapToLocalValue("allowQtyChange", masterData.config[key]).overbookingSecurityCheck)
                } else if (key === "serialType") {
                    setSerialNumberType(deliveryDatabaseMapToLocalValue("serialType", masterData.config[key]))
                }
            });
        }
    };

    React.useEffect(() => {
        setAppData(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION));
    }, [breadcrumb]);

    React.useEffect(() => {
        if (Object.keys(appData).length !== 0) {
            loadDefault();
            new Promise((resolve, reject) => {
                setConfiguration(appData);

                resolve(true)
            }).then(res => {
                setIsLoaded(true);
            })
        }
    }, [appData]);

    const DeliveryMenus = [
        {
            id: SETTINGS_FOR_ENTRY,
            label: "Settings For Entry",
            subtitle: `There are 2 options for the entry to the Delivery process: 1. via a list of customers or 2. via entering a document number. If both options are chosen a sub menu will be shown for the user to decide over the preferred entry method.`,
            isCustomized: (defaultFunctionType && (!arraysMatch(defaultFunctionType.config.entry, deliveryLocalMapToDatabaseValue("entry", entryOption) as string[]) ||
                defaultFunctionType.config.listOfSupplier !== deliveryLocalMapToDatabaseValue("listOfSupplier", listOfSuppliers) ||
                !arraysMatch(defaultFunctionType.config.orderSelection, deliveryLocalMapToDatabaseValue("orderSelection", selectionOfOrders) as string[]) ||
                defaultFunctionType.config.showOrderPositionCount !== deliveryLocalMapToDatabaseValue("showOrderPositionCount", orderPositionCount) ||
                !arraysMatch(defaultFunctionType.config.docInputType, deliveryLocalMapToDatabaseValue("docInputType", typeOfOrderInput) as string[])
            )) ? true : false,
            submenus: [
                {
                    id: ENTRY_OPTION,
                    label: "Entry Option",
                    isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.entry, deliveryLocalMapToDatabaseValue("entry", entryOption) as string[])) ? true : false,
                    renderSubmenu: entryOption.length > 0,
                    submenus: [
                        {
                            id: LIST_OF_SUPPLIERS,
                            label: "List of Customers",
                            isCustomized: (defaultFunctionType && defaultFunctionType.config.listOfSupplier !== deliveryLocalMapToDatabaseValue("listOfSupplier", listOfSuppliers)) ? true : false,
                            isShown: entryOption.includes(VIA_SUPPLIERS),
                        },
                        {
                            id: SELECTION_OF_ORDERS,
                            label: "Selection of Orders",
                            isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.orderSelection, deliveryLocalMapToDatabaseValue("orderSelection", selectionOfOrders) as string[])) ? true : false,
                            isShown: entryOption.includes(VIA_SUPPLIERS),
                        },

                        {
                            id: ORDER_POSITION_COUNT,
                            label: "Order Position Count",
                            isCustomized: (defaultFunctionType && defaultFunctionType.config.showOrderPositionCount !== deliveryLocalMapToDatabaseValue("showOrderPositionCount", orderPositionCount)) ? true : false,
                            isShown: entryOption.includes(VIA_SUPPLIERS),
                        },
                        {
                            id: TYPE_OF_ORDER_INPUT,
                            label: "Type of Order Input",
                            isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.docInputType, deliveryLocalMapToDatabaseValue("docInputType", typeOfOrderInput) as string[])) ? true : false,
                            isShown: entryOption.includes(VIA_ORDER_NUMBER)
                        }
                    ]
                }
            ]
        },
        {
            id: SETTINGS_FOR_LIST_OF_POSITION,
            label: "Settings For List of Position",
            subtitle: `In the list of positions all order positions specific to an will be shown. If several orders have been selected all positions of all selected orders will be shown.`,
            isCustomized: (defaultFunctionType && (defaultFunctionType.config.displayedColumns !== deliveryLocalMapToDatabaseValue("displayedColumns", displayedColumn) ||
                defaultFunctionType.config.addNewPosition !== deliveryLocalMapToDatabaseValue("addNewPosition", newPositionAddable) ||
                !arraysMatch(defaultFunctionType.config.selectItemInput, deliveryLocalMapToDatabaseValue("selectItemInput", itemSelection)) ||
                defaultFunctionType.config.quickCheck !== deliveryLocalMapToDatabaseValue("quickCheck", quickCheck) ||
                defaultFunctionType.config.warehouseLayout !== deliveryLocalMapToDatabaseValue("warehouseLayout", warehouseName) ||
                defaultFunctionType.config.completeOrderMode !== deliveryLocalMapToDatabaseValue("completeOrderMode", completeOrder)
            )) ? true : false,
            submenus: [
                {
                    id: DISPLAYED_COLUMN,
                    label: "Displayed Column",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.displayedColumns !== deliveryLocalMapToDatabaseValue("displayedColumns", displayedColumn)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: NEW_POSITION_ADDABLE,
                    label: "New Position Addable",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.addNewPosition !== deliveryLocalMapToDatabaseValue("addNewPosition", newPositionAddable)) ? true : false,
                    renderSubmenu: newPositionAddable === 'enable',
                    submenus: [
                        {
                            id: ITEM_SELECTION,
                            label: "Item Selection",
                            isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.selectItemInput, deliveryLocalMapToDatabaseValue("selectItemInput", itemSelection))) ? true : false,
                            isShown: newPositionAddable === 'enable',
                        },
                    ]
                },
                {
                    id: QUICK_CHECK,
                    label: "Quick Check",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.quickCheck !== deliveryLocalMapToDatabaseValue("quickCheck", quickCheck)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: WAREHOUSE_NAME,
                    label: "Warehouse Name",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.warehouseLayout !== deliveryLocalMapToDatabaseValue("warehouseLayout", warehouseName)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: COMPLETE_ORDER,
                    label: "Complete Order",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.completeOrderMode !== deliveryLocalMapToDatabaseValue("completeOrderMode", completeOrder)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                }
            ]
        },
        {
            id: SETTINGS_FOR_POSITION_DETAILS,
            label: "Settings For Position Details",
            subtitle: `After choosing a position from the list the following details will be displayed.`,
            isCustomized: (defaultFunctionType && (defaultFunctionType.config.displayedFields !== deliveryLocalMapToDatabaseValue("displayedFields", fieldsDisplayed) ||
                !arraysMatch(defaultFunctionType.config.allowQtyChange, deliveryLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck) as string[])
            )) ? true : false,
            submenus: [
                {
                    id: FIELDS_DISPLAYED,
                    label: "Fields Displayed",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.displayedFields !== deliveryLocalMapToDatabaseValue("displayedFields", fieldsDisplayed)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: MORE_OR_LESS_QUANTITIES_ALLOWED,
                    label: "More/less Quantities Allowed",
                    isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.allowQtyChange, deliveryLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck) as string[])) ? true : false,
                    renderSubmenu: moreOrLessQuantitiesAllowed.length > 0,
                    submenus: [
                        {
                            id: LESS_QUANTITIES_SECURITY_CHECK,
                            label: "Less Quantities Security Check",
                            isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.allowQtyChange, deliveryLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck) as string[])) ? true : false,
                            isShown: moreOrLessQuantitiesAllowed.includes('allowLessQuantities'),
                        },
                        {
                            id: OVERBOOKING_SECURITY_CHECK,
                            label: "Overbooking Security Check",
                            isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.allowQtyChange, deliveryLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck) as string[])) ? true : false,
                            isShown: moreOrLessQuantitiesAllowed.includes('allowOverbooking'),
                        }
                    ]
                },
                {
                    id: SERIAL_NUMBER_TYPE,
                    label: "Serial Number Type",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.serialType !== deliveryLocalMapToDatabaseValue("serialType", serialNumberType)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                }
            ]
        }
    ]

    const handleClickSaveConfiguration = () => {
        const tempAppData = { ...appData };

        //to avoid different position number in the object - for customized in system dashboard page
        tempAppData.config = {}

        Object.keys(defaultFunctionType.config).map((key: any) => {
            if (key === "entry") {
                tempAppData.config.entry = arrayAdjustmentDefault(deliveryLocalMapToDatabaseValue("entry", entryOption), defaultFunctionType.config.entry);
            } else if (key === "listOfSupplier") {
                tempAppData.config.listOfSupplier = deliveryLocalMapToDatabaseValue("listOfSupplier", listOfSuppliers);
            } else if (key === "orderSelection") {
                tempAppData.config.orderSelection = arrayAdjustmentDefault(deliveryLocalMapToDatabaseValue("orderSelection", selectionOfOrders), defaultFunctionType.config.orderSelection);
            } else if (key === "docInputType") {
                tempAppData.config.docInputType = arrayAdjustmentDefault(deliveryLocalMapToDatabaseValue("docInputType", typeOfOrderInput), defaultFunctionType.config.docInputType);
            } else if (key === "displayedColumns") {
                tempAppData.config.displayedColumns = deliveryLocalMapToDatabaseValue("displayedColumns", displayedColumn);
            } else if (key === "addNewPosition") {
                tempAppData.config.addNewPosition = deliveryLocalMapToDatabaseValue("addNewPosition", newPositionAddable);
            } else if (key === "selectItemInput") {
                tempAppData.config.selectItemInput = deliveryLocalMapToDatabaseValue("selectItemInput", itemSelection);
            } else if (key === "quickCheck") {
                tempAppData.config.quickCheck = deliveryLocalMapToDatabaseValue("quickCheck", quickCheck);
            } else if (key === "warehouseLayout") {
                tempAppData.config.warehouseLayout = deliveryLocalMapToDatabaseValue("warehouseLayout", warehouseName);
            } else if (key === "completeOrderMode") {
                tempAppData.config.completeOrderMode = deliveryLocalMapToDatabaseValue("completeOrderMode", completeOrder);
            } else if (key === "showOrderPositionCount") {
                tempAppData.config.showOrderPositionCount = deliveryLocalMapToDatabaseValue("showOrderPositionCount", orderPositionCount);
            } else if (key === "displayedFields") {
                tempAppData.config.displayedFields = deliveryLocalMapToDatabaseValue("displayedFields", fieldsDisplayed);
            } else if (key === "allowQtyChange") {
                tempAppData.config.allowQtyChange = arrayAdjustmentDefault(deliveryLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck), defaultFunctionType.config.allowQtyChange);
            } else if (key === "serialType") {
                tempAppData.config.serialType = deliveryDatabaseMapToLocalValue("serialType", serialNumberType);
            } else {
                tempAppData.config[key] = defaultFunctionType.config[key];
            }
        })

        return ConfigurationService.saveConfiguration(currentUserData.key, tempAppData.key, tempAppData).then((res: any) => {
            setIsModified(false);
            setSnackbarMessage(`${breadcrumb[breadcrumb.length - 2].data.name} - ${appData.name} Configuration has been changed`)
            handleShowSnackbar();
        });
    }

    React.useEffect(() => {
        if (isLoaded) {
            setIsModified(true);
        }
    }, [entryOption, viaSuppliers, listOfSuppliers, selectionOfOrders, viaOrderNumber, typeOfOrderInput, displayedColumn, newPositionAddable, itemSelection, quickCheck, warehouseName, completeOrder, orderPositionCount, fieldsDisplayed, moreOrLessQuantitiesAllowed, moreOrLessQuantitiesAllowedSecurityCheck, lessQuantitiesSecurityCheck, overbookingSecurityCheck, serialNumberType]);

    React.useEffect(() => {
        if (isLoaded && entryOption.includes(VIA_ORDER_NUMBER)) {
            if (typeOfOrderInput.length <= 0) {
                setListError([...listError, TYPE_OF_ORDER_INPUT]);
            } else {
                setListError(listError.filter((event) => (event !== TYPE_OF_ORDER_INPUT)));
            }
        }
    }, [typeOfOrderInput]);

    React.useEffect(() => {
        if (isLoaded && newPositionAddable === "enable") {
            if (itemSelection.length <= 0) {
                setListError([...listError, ITEM_SELECTION]);
            } else {
                setListError(listError.filter((event) => (event !== ITEM_SELECTION)));
            }
        }
    }, [itemSelection]);

    React.useEffect(() => {
        if (isLoaded) {
            if (entryOption.length <= 0) {
                setListError([...listError, ENTRY_OPTION]);
            } else {
                setListError(listError.filter((event) => (event !== ENTRY_OPTION)));
            }
        }
    }, [entryOption]);

    const handleChangeEntryOption = (e: any) => {
        if (entryOption.includes(e.target.value)) {
            setEntryOption(entryOption.filter((event) => (event !== e.target.value)));
        } else {
            setEntryOption([...entryOption, e.target.value]);
            if (e.target.value === VIA_SUPPLIERS) {
                setListOfSuppliers(deliveryDatabaseMapToLocalValue("listOfSupplier", defaultFunctionType.config.listOfSupplier) as string);
                setSelectionOfOrders(deliveryDatabaseMapToLocalValue("orderSelection", defaultFunctionType.config.orderSelection) as string[])
            }
            if (e.target.value === VIA_ORDER_NUMBER) {
                setTypeOfOrderInput(deliveryDatabaseMapToLocalValue("docInputType", defaultFunctionType.config.docInputType) as string[]);
            }
        }
    }

    const handleChangeViaSuppliers = () => {
        setViaSuppliers(prev => !prev)
    }

    const handleChangeListOfSuppliers = (e: any) => {
        setListOfSuppliers(e.target.value)
    }

    const handleChangeSelectionOfOrders = (e: any) => {
        if (selectionOfOrders.includes(e.target.value)) {
            setSelectionOfOrders(selectionOfOrders.filter((event) => (event !== e.target.value)));
        } else {
            setSelectionOfOrders([...selectionOfOrders, e.target.value]);
        }
    }

    const handleChangeTypeOfOrderInput = (e: any) => {
        if (typeOfOrderInput.includes(e.target.value)) {
            setTypeOfOrderInput(typeOfOrderInput.filter((event) => (event !== e.target.value)));
        } else {
            setTypeOfOrderInput([...typeOfOrderInput, e.target.value]);
        }
    }

    const handleChangeViaOrderNumber = () => {
        setViaOrderNumber(prev => !prev)
    }

    const handleChangeDisplayedColumn = (e: any) => {
        setDisplayedColumn(e.target.value)
    }

    const handleChangeNewPositionAddable = (e: any) => {
        setNewPositionAddable(e.target.value)
        if (e.target.value === "disable") {
            setItemSelection(deliveryDatabaseMapToLocalValue("selectItemInput", defaultFunctionType.config.selectItemInput));
            setListError(listError.filter((event) => (event !== ITEM_SELECTION)));
        } else {
            setListError([...listError, ITEM_SELECTION]);
        }
    }

    const handleChangeItemSelection = (e: any) => {
        if (itemSelection.includes(e.target.value)) {
            setItemSelection(itemSelection.filter((event) => (event !== e.target.value)));
        } else {
            setItemSelection([...itemSelection, e.target.value]);
        }
    }

    const handleChangeQuickCheck = (e: any) => {
        setQuickCheck(e.target.value)
    }

    const handleChangeWarehouseName = (e: any) => {
        setWarehouseName(e.target.value)
    }

    const handleChangeCompleteOrder = (e: any) => {
        setCompleteOrder(e.target.value)
    }

    const handleChangeOrderPositionCount = (e: any) => {
        setOrderPositionCount(e.target.value)
    }

    const handleChangeFieldsDisplayed = (e: any) => {
        setFieldsDisplayed(e.target.value)
    }

    const handleChangeSerialNumberType = (e: any) => {
        setSerialNumberType(e.target.value)
    }

    const handleChangeMoreOrLessQuantitiesAllowed = (e: any) => {
        if (moreOrLessQuantitiesAllowed.includes(e.target.value)) {
            setMoreOrLessQuantitiesAllowed(moreOrLessQuantitiesAllowed.filter((event) => (event !== e.target.value)));
            if (e.target.value === 'allowLessQuantities') {
                if (moreOrLessQuantitiesAllowedSecurityCheck.includes('lessQuantityAllowedWithCheck')) {
                    setMoreOrLessQuantitiesAllowedSecurityCheck(moreOrLessQuantitiesAllowedSecurityCheck.filter((event) => (event !== 'lessQuantityAllowedWithCheck')));
                }
                else if (moreOrLessQuantitiesAllowedSecurityCheck.includes('lessQuantityAllowed')) {
                    setMoreOrLessQuantitiesAllowedSecurityCheck(moreOrLessQuantitiesAllowedSecurityCheck.filter((event) => (event !== 'lessQuantityAllowed')));
                }
            }
            else if (e.target.value === 'allowOverbooking') {
                if (moreOrLessQuantitiesAllowedSecurityCheck.includes('overbookingAllowedWithCheck')) {
                    setMoreOrLessQuantitiesAllowedSecurityCheck(moreOrLessQuantitiesAllowedSecurityCheck.filter((event) => (event !== 'overbookingAllowedWithCheck')));
                }
                else if (moreOrLessQuantitiesAllowedSecurityCheck.includes('overbookingAllowed')) {
                    setMoreOrLessQuantitiesAllowedSecurityCheck(moreOrLessQuantitiesAllowedSecurityCheck.filter((event) => (event !== 'overbookingAllowed')));
                }
            }
        } else {
            if (e.target.value === 'allowLessQuantities') {
                if (moreOrLessQuantitiesAllowedSecurityCheck.includes('lessQuantityAllowedWithCheck')) {
                    setMoreOrLessQuantitiesAllowedSecurityCheck(moreOrLessQuantitiesAllowedSecurityCheck.filter((event) => (event !== 'lessQuantityAllowedWithCheck')));
                } else {
                    setMoreOrLessQuantitiesAllowedSecurityCheck([...moreOrLessQuantitiesAllowedSecurityCheck, 'lessQuantityAllowedWithCheck']);
                }

                setLessQuantitiesSecurityCheck('lessQuantityAllowedWithCheck')
            }
            else if (e.target.value === 'allowOverbooking') {
                if (moreOrLessQuantitiesAllowedSecurityCheck.includes('overbookingAllowedWithCheck')) {
                    setMoreOrLessQuantitiesAllowedSecurityCheck(moreOrLessQuantitiesAllowedSecurityCheck.filter((event) => (event !== 'overbookingAllowedWithCheck')));
                } else {
                    setMoreOrLessQuantitiesAllowedSecurityCheck([...moreOrLessQuantitiesAllowedSecurityCheck, 'overbookingAllowedWithCheck']);
                }

                setOverbookingSecurityCheck('overbookingAllowedWithCheck')
            }
            setMoreOrLessQuantitiesAllowed([...moreOrLessQuantitiesAllowed, e.target.value]);
        }
    }

    const handleChangeLessQuantitiesSecurityCheck = (e: any) => {
        setLessQuantitiesSecurityCheck(e.target.value)

        const newMoreOrLessQuantitiesAllowedSecurityCheck = [...moreOrLessQuantitiesAllowedSecurityCheck]

        newMoreOrLessQuantitiesAllowedSecurityCheck.forEach((data, index) => {
            if (data === 'lessQuantityAllowedWithCheck'
                || data === 'lessQuantityAllowed'
            ) {
                newMoreOrLessQuantitiesAllowedSecurityCheck.splice(index, 1)
            }
        })

        newMoreOrLessQuantitiesAllowedSecurityCheck.push(e.target.value)

        setMoreOrLessQuantitiesAllowedSecurityCheck(newMoreOrLessQuantitiesAllowedSecurityCheck)
    }

    const handleChangeOverbookingSecurityCheck = (e: any) => {
        setOverbookingSecurityCheck(e.target.value)

        const newMoreOrLessQuantitiesAllowedSecurityCheck = [...moreOrLessQuantitiesAllowedSecurityCheck]

        newMoreOrLessQuantitiesAllowedSecurityCheck.forEach((data, index) => {
            if (data === 'overbookingAllowedWithCheck'
                || data === 'overbookingAllowed'
            ) {
                newMoreOrLessQuantitiesAllowedSecurityCheck.splice(index, 1)
            }
        })

        newMoreOrLessQuantitiesAllowedSecurityCheck.push(e.target.value)

        setMoreOrLessQuantitiesAllowedSecurityCheck(newMoreOrLessQuantitiesAllowedSecurityCheck)
    }

    const handleChangeActiveOption = (id: string) => {
        let element = document.getElementById(id)

        if (element) {
            const bounding = element.getBoundingClientRect()

            if (
                bounding.top >= 0 &&
                bounding.top <= 100 &&
                bounding.left >= 0 &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            ) {
                if (id === SETTINGS_FOR_DELIVERY) {
                    setActiveOption(VIA_SUPPLIERS)
                }
                else {
                    setActiveOption(id)
                }
            }
        }
    }

    const handleActivePreview = (id: string) => {
        const orderItemDataAtLeastOnePosition =
            [
                {
                    id: "1",
                    item: "LM4029PH",
                    description: "LeMon 4029 Printhead",
                    open: 6,
                    covered: 6,
                    openCoveredType: "Palette(n)",
                    warehouseName: "WH General",
                    warehouseCode: "01",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "2",
                    item: "P10010",
                    description: "Sales Set",
                    open: 1,
                    covered: 0,
                    openCoveredType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "02",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "3",
                    item: "C00007",
                    description: "Extern Hard Disk 3TB",
                    open: 12,
                    covered: 0,
                    openCoveredType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "03",
                    mandatoryBatchOrSerialNumber: true
                },
                {
                    id: "4",
                    item: "LM4029ACA",
                    description: "LeMon 4029 Printer AC adapter",
                    open: 6,
                    covered: 0,
                    openCoveredType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "04",
                    mandatoryBatchOrSerialNumber: false
                },
            ];
        const orderItemDataAllPositions =
            [
                {
                    id: "1",
                    item: "LM4029PH",
                    description: "LeMon 4029 Printhead",
                    open: 6,
                    covered: 6,
                    openCoveredType: "Palette(n)",
                    warehouseName: "WH General",
                    warehouseCode: "01",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "2",
                    item: "P10010",
                    description: "Sales Set",
                    open: 1,
                    covered: 1,
                    openCoveredType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "02",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "3",
                    item: "C00007",
                    description: "Extern Hard Disk 3TB",
                    open: 12,
                    covered: 12,
                    openCoveredType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "03",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "4",
                    item: "LM4029ACA",
                    description: "LeMon 4029 Printer AC adapter",
                    open: 6,
                    covered: 6,
                    openCoveredType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "04",
                    mandatoryBatchOrSerialNumber: false
                },
            ];
        const supplierData =
            [
                {
                    name: "Business Tools AG",
                    type: "withOpenOrder",
                    description: "(With open Order)"

                },
                {
                    name: "Pier 1 Importe",
                    type: "noOpenOrder",
                    description: "(No open Order)"
                },
                {
                    name: "Dataquest AG",
                    type: "noOpenOrder",
                    description: "(No open Order)"
                },
                {
                    name: "Sea Corp",
                    type: "withOpenOrder",
                    description: "(With open Order)"
                },
                {
                    name: "OEC 2",
                    type: "noOpenOrder",
                    description: "(No open Order)"
                },
                {
                    name: "ARP Datacon AG",
                    type: "withOpenOrder",
                    description: "(With open Order)"
                },
            ];
        const orderSelectionData =
            [
                {
                    name: "2352",
                    count: 4
                },
                {
                    name: "2232",
                    count: 2

                }
            ];

        const detailPosition = {
            position: 1,
            item: "LM4029PH",
            description: "LeMon 4029 Printhead",
            ordered: 6,
            open: 6,
            orderedOpenType: "Palette(n)",
            coveredQuantity: 0,
            coveredQuantityType: "Palette(n)",
            warehouseList: [
                {
                    name: "01 - WH General",
                    type: "whGeneral"
                },
                {
                    name: "02 - WH Reserve",
                    type: "whReserve"
                },
                {
                    name: "03 - WH Delivery",
                    type: "whDelivery"
                },
                {
                    name: "04 - WH Consignment",
                    type: "whConsignment"
                },
                {
                    name: "05 - WH Bin locations",
                    type: "whBinLocation"
                },
            ],
            binLocationList: [
                {
                    name: "05-G01-R01-P01",
                    type: "binLocationList"
                },
                {
                    name: "05-G01-R01-P02",
                    type: "binLocationList"
                },
                {
                    name: "05-G01-R02-P01",
                    type: "binLocationList"
                },
                {
                    name: "05-G01-R02-P02",
                    type: "binLocationList"
                },
                {
                    name: "05-G01-R03-P01",
                    type: "binLocationList"
                },
                {
                    name: "05-G03-R01-P01",
                    type: "binLocationList"
                },
            ]
        }

        const menuData = [
            {
                name: "Select Customer",
                function: () => { }
            },
            {
                name: "Input Order Number",
                function: () => { }
            }
        ]

        switch (id) {
            case ENTRY_OPTION:
                if (entryOption.includes(VIA_SUPPLIERS) && entryOption.includes(VIA_ORDER_NUMBER)) {
                    return setActivePreview(<MenuPage headerTitle="Delivery" data={menuData} previewWidth={320} previewHeight={568} />);
                } else if (entryOption.includes(VIA_SUPPLIERS)) {
                    return setActivePreview(<ListPage headerTitle="Delivery" listTypes={listOfSuppliers === "allSuppliers" ? ["withOpenOrder", "noOpenOrder"] : ["withOpenOrder"]} listModalTitle="Choose a Customer" data={supplierData} isModalOpen={true} previewWidth={320} previewHeight={568} />);
                } else if (entryOption.includes(VIA_ORDER_NUMBER)) {
                    return setActivePreview(<EnterItemNumberPage headerTitle={"Enter Order Number"} data={{ inputMessage: "Please enter the purchase order number", barcodeMessage: "Please scan the barcode of the order" }} inputTypes={typeOfOrderInput} previewHeight={568} previewWidth={320} />);
                } else {
                    return setActivePreview(<NoPreviewPage type={"emptyValue"} previewHeight={568} previewWidth={320} />);
                }
            case LIST_OF_SUPPLIERS:
                return setActivePreview(<ListPage headerTitle="Delivery" listTypes={listOfSuppliers === "allSuppliers" ? ["withOpenOrder", "noOpenOrder"] : ["withOpenOrder"]} listModalTitle="Choose a Customer" data={supplierData} isModalOpen={true} previewWidth={320} previewHeight={568} />);
            case SELECTION_OF_ORDERS:
                return setActivePreview(<ListSelectionPage previewWidth={320} previewHeight={568} headerTitle={"Delivery"} listSelectionModalTitle={"Choose a Customer"} data={orderSelectionData} dataTitle="Ontego One" listTypes={selectionOfOrders} showCount={orderPositionCount === "show" ? true : false} isModalOpen={true} />);
            case TYPE_OF_ORDER_INPUT:
                if (typeOfOrderInput.length > 0) {
                    return setActivePreview(<EnterItemNumberPage headerTitle={"Enter Order Number"} data={{ inputMessage: "Please enter the purchase order number", barcodeMessage: "Please scan the barcode of the order" }} inputTypes={typeOfOrderInput} previewHeight={568} previewWidth={320} />);
                } else {
                    return setActivePreview(<NoPreviewPage type={"emptyValue"} previewHeight={568} previewWidth={320} />);
                }
            case DISPLAYED_COLUMN:
                return setActivePreview(<ListOfOrderPositionsPage headerTitle={"Delivery"} newPositionAddable={newPositionAddable === "disable" ? false : true} completeOrderType={completeOrder} quickCheck={quickCheck === "disable" ? false : true} allowEditBpRefNo={false} warehouseLayout={warehouseName} data={completeOrder === "atLeastOnePosition" ? orderItemDataAtLeastOnePosition : orderItemDataAllPositions} templateType={displayedColumn} previewHeight={568} previewWidth={320} />);
            case ITEM_SELECTION:
                if (itemSelection.length > 0) {
                    return setActivePreview(<NewPositionAddablePage inputTypes={itemSelection} previewHeight={568} previewWidth={320} />);
                } else {
                    return setActivePreview(<NoPreviewPage type={"emptyValue"} previewHeight={568} previewWidth={320} />);
                }
            case NEW_POSITION_ADDABLE:
                return setActivePreview(<ListOfOrderPositionsPage headerTitle={"Delivery"} newPositionAddable={newPositionAddable === "disable" ? false : true} completeOrderType={completeOrder} quickCheck={quickCheck === "disable" ? false : true} allowEditBpRefNo={false} warehouseLayout={warehouseName} data={completeOrder === "atLeastOnePosition" ? orderItemDataAtLeastOnePosition : orderItemDataAllPositions} templateType={displayedColumn} previewHeight={568} previewWidth={320} />);
            case QUICK_CHECK:
                return setActivePreview(<ListOfOrderPositionsPage headerTitle={"Delivery"} newPositionAddable={newPositionAddable === "disable" ? false : true} completeOrderType={completeOrder} quickCheck={quickCheck === "disable" ? false : true} allowEditBpRefNo={false} warehouseLayout={warehouseName} data={completeOrder === "atLeastOnePosition" ? orderItemDataAtLeastOnePosition : orderItemDataAllPositions} templateType={displayedColumn} previewHeight={568} previewWidth={320} />);
            case WAREHOUSE_NAME:
                return setActivePreview(<ListOfOrderPositionsPage headerTitle={"Delivery"} newPositionAddable={newPositionAddable === "disable" ? false : true} completeOrderType={completeOrder} quickCheck={quickCheck === "disable" ? false : true} allowEditBpRefNo={false} warehouseLayout={warehouseName} data={completeOrder === "atLeastOnePosition" ? orderItemDataAtLeastOnePosition : orderItemDataAllPositions} templateType={displayedColumn} previewHeight={568} previewWidth={320} />);
            case COMPLETE_ORDER:
                return setActivePreview(<ListOfOrderPositionsPage headerTitle={"Delivery"} newPositionAddable={newPositionAddable === "disable" ? false : true} completeOrderType={completeOrder} quickCheck={quickCheck === "disable" ? false : true} allowEditBpRefNo={false} warehouseLayout={warehouseName} data={completeOrder === "atLeastOnePosition" ? orderItemDataAtLeastOnePosition : orderItemDataAllPositions} templateType={displayedColumn} previewHeight={568} previewWidth={320} />);
            case ORDER_POSITION_COUNT:
                return setActivePreview(<ListSelectionPage previewWidth={320} previewHeight={568} headerTitle={"Delivery"} listSelectionModalTitle={"Choose an Order"} data={orderSelectionData} dataTitle="Ontego One" listTypes={selectionOfOrders} showCount={orderPositionCount === "show" ? true : false} isModalOpen={true} />);
            case FIELDS_DISPLAYED:
                return setActivePreview(<OrderPositionDetailsPage headerTitle={"Delivery"} allowedTypes={moreOrLessQuantitiesAllowedSecurityCheck} data={detailPosition} previewHeight={568} previewWidth={320} />);
            case MORE_OR_LESS_QUANTITIES_ALLOWED:
            case LESS_QUANTITIES_SECURITY_CHECK:
            case OVERBOOKING_SECURITY_CHECK:
                return setActivePreview(<OrderPositionDetailsPage headerTitle={"Delivery"} allowedTypes={moreOrLessQuantitiesAllowedSecurityCheck} data={detailPosition} previewHeight={568} previewWidth={320} />);
            case SERIAL_NUMBER_TYPE:
                return setActivePreview(<SerialNumberType totalSerialNumbers={1} value={serialNumberType} data={[]} previewHeight={568} previewWidth={320} />)
            default:
                return setActivePreview(id);
        }
    }

    React.useEffect(() => {
        handleActivePreview(activeOption);

        DeliveryMenus.forEach(menu => {
            menu.submenus.forEach((subMenu: any) => {
                if (subMenu.id === activeOption) {
                    setActiveMenu(menu)
                    setActiveOptionName(subMenu.label)
                }

                subMenu.submenus.forEach((thirdSubmenu: any) => {
                    if (thirdSubmenu.id === activeOption) {
                        setActiveMenu(menu)
                        setActiveOptionName(thirdSubmenu.label)
                    };
                })
            });
        })

    }, [activeOption, entryOption, displayedColumn, listOfSuppliers, selectionOfOrders, typeOfOrderInput, warehouseName, orderPositionCount, completeOrder, quickCheck, newPositionAddable, itemSelection, moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck, serialNumberType]);

    const viewPortCallback = () => {
        // handleChangeActiveOption(SETTINGS_FOR_DELIVERY)
        handleChangeActiveOption(ENTRY_OPTION)

        // handleChangeActiveOption(VIA_SUPPLIERS)
        handleChangeActiveOption(LIST_OF_SUPPLIERS)
        handleChangeActiveOption(SELECTION_OF_ORDERS)
        // handleChangeActiveOption(VIA_ORDER_NUMBER)
        handleChangeActiveOption(TYPE_OF_ORDER_INPUT)

        handleChangeActiveOption(DISPLAYED_COLUMN)
        handleChangeActiveOption(NEW_POSITION_ADDABLE)
        handleChangeActiveOption(ITEM_SELECTION)
        handleChangeActiveOption(QUICK_CHECK)
        handleChangeActiveOption(WAREHOUSE_NAME)
        handleChangeActiveOption(COMPLETE_ORDER)
        handleChangeActiveOption(ORDER_POSITION_COUNT)
        handleChangeActiveOption(FIELDS_DISPLAYED)
        handleChangeActiveOption(MORE_OR_LESS_QUANTITIES_ALLOWED)
        handleChangeActiveOption(LESS_QUANTITIES_SECURITY_CHECK)
        handleChangeActiveOption(OVERBOOKING_SECURITY_CHECK)

        handleChangeActiveOption(SERIAL_NUMBER_TYPE)
    }

    React.useEffect(() => {
        setActiveOption(ENTRY_OPTION)
    }, [])

    const DeliveryContextValue: IDeliveryContextInterface = {
        defaultFunctionType: defaultFunctionType,
        activeOption: activeOption,
        setActiveOption: handleChangeActiveOption,

        entryOption: entryOption,
        handleChangeEntryOption: handleChangeEntryOption,

        viaSuppliers: viaSuppliers,
        handleChangeViaSuppliers: handleChangeViaSuppliers,
        viaOrderNumber: viaOrderNumber,
        handleChangeViaOrderNumber: handleChangeViaOrderNumber,

        handleChangeListOfSuppliers: handleChangeListOfSuppliers,
        listOfSuppliers: listOfSuppliers,
        selectionOfOrders: selectionOfOrders,
        handleChangeSelectionOfOrders: handleChangeSelectionOfOrders,
        typeOfOrderInput: typeOfOrderInput,
        handleChangeTypeOfOrderInput: handleChangeTypeOfOrderInput,

        displayedColumn: displayedColumn,
        handleChangeDisplayedColumn: handleChangeDisplayedColumn,
        newPositionAddable: newPositionAddable,
        handleChangeNewPositionAddable: handleChangeNewPositionAddable,
        itemSelection: itemSelection,
        handleChangeItemSelection: handleChangeItemSelection,
        quickCheck: quickCheck,
        handleChangeQuickCheck: handleChangeQuickCheck,
        warehouseName: warehouseName,
        handleChangeWarehouseName: handleChangeWarehouseName,
        completeOrder: completeOrder,
        handleChangeCompleteOrder: handleChangeCompleteOrder,
        orderPositionCount: orderPositionCount,
        handleChangeOrderPositionCount: handleChangeOrderPositionCount,
        fieldsDisplayed: fieldsDisplayed,
        handleChangeFieldsDisplayed: handleChangeFieldsDisplayed,
        moreOrLessQuantitiesAllowed: moreOrLessQuantitiesAllowed,
        handleChangeMoreOrLessQuantitiesAllowed: handleChangeMoreOrLessQuantitiesAllowed,

        lessQuantitiesSecurityCheck: lessQuantitiesSecurityCheck,
        handleChangeLessQuantitiesSecurityCheck: handleChangeLessQuantitiesSecurityCheck,
        overbookingSecurityCheck: overbookingSecurityCheck,
        handleChangeOverbookingSecurityCheck: handleChangeOverbookingSecurityCheck,

        serialNumberType: serialNumberType,
        handleChangeSerialNumberType: handleChangeSerialNumberType
    };

    return (
        <ConfigurationContainer
            detectViewportCallback={viewPortCallback}
            title={`Delivery`}
            icon={<FunctionDelivery />}
            activeMenu={activeMenu}
            activeViewport={activePreview}
            activeOption={activeOption}
            activeOptionName={activeOptionName}
            sidebarMenus={DeliveryMenus}
            saveConfiguration={handleClickSaveConfiguration}
            setIsModified={setIsModified}
            isModified={isModified}
            setListError={setListError}
            listError={listError}
            setConfigurationToDefault={() => setConfiguration(defaultFunctionType)}
            setConfigurationToPreviousSetting={() => setConfiguration(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION))}
        >
            <DeliveryContextProvider value={DeliveryContextValue}>
                <SettingsForEntry />
                <SettingsForListPositions />
                <SettingsForPositionDetails />
            </DeliveryContextProvider>
        </ConfigurationContainer>

    )
};

export default DeliveryConfiguration;