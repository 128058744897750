// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    modal: {
      backgroundColor: "rgba(255, 255, 255, 0.90)",
      margin: 0,
      zIndex: 9999999,
      boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2)",
      position: "absolute",
      left: "50%",
      top: "50%",
      boxSizing: "border-box"
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
      height: "100%"
    },
    floatingDiv: {
      padding: "15px"
    },
    floatingDivBarTop: {
      height: "50px",
      position: "relative",
      textAlign: "left",
      "& p": {
        fontSize: "10px",
        color: "#00a7dd"
      }
    },
    floatingDivBarBottom: {
      height: "50px"
    },
    floatingDivContent: {
      overflow: "auto",
      position: "relative",
      padding: "0px 15px 15px 15px"
    },
    floatingDivContentBar: {
      borderRadius: "4px",
      backgroundColor: "#f5f5f5",
      width: "100%",
      padding: "10px 15px 10px 15px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginBottom: "10px",
      textAlign: "left"
    },
    blackContent: {
      color: "black",
      marginRight: "10px",
      fontSize: "14px"
    },
    blueContent: {
      color: "#00a7dd",
      fontSize: "14px",
      textAlign: "left"
    },
    scannerIcon: {
      fontSize: "32px !important",
      position: "absolute",
      top: "0px",
      right: "0px",
      color: "black !important"
    }
  });
});