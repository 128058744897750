// React libraries
import * as React from 'react'; // Custom Styles

import { useStyles } from "./AllowScanGs1128ModalStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { GreyButton } from "../../index"; // Icon

import Barcode from "../../../icons/Barcode/Barcode";
export var AllowScanGs1128Modal = function AllowScanGs1128Modal(props) {
  var classes = useStyles(props);
  var modalWidth = 285;
  var modalHeight = 528;
  var isModalOpen = props.isModalOpen,
      data = props.data;
  var marginLeft = modalWidth / 2 * -1;
  var marginTop = modalHeight / 2 * -1;
  return React.createElement("div", {
    style: {
      display: isModalOpen ? "block" : "none"
    }
  }, React.createElement("div", {
    className: classes.overlay
  }), React.createElement("div", {
    className: classes.modal,
    style: {
      marginLeft: marginLeft,
      marginTop: marginTop,
      height: modalHeight,
      width: modalWidth
    }
  }, React.createElement("div", {
    className: classes.floatingDiv
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.floatingDivBarTop
  }, React.createElement("p", null, "Scan NVE/SSCC"), React.createElement(Barcode, {
    className: classes.scannerIcon
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.floatingDivContent,
    style: {
      height: "calc(".concat(modalHeight, "px - 50px - 50px - 30px)")
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.floatingDivContentBar
  }, React.createElement("span", {
    className: classes.blackContent
  }, "NVE/SSCC"), React.createElement("span", {
    className: classes.blueContent
  }, data.nveSscc)), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.floatingDivContentBar
  }, React.createElement("span", {
    className: classes.blackContent
  }, "Item"), React.createElement("span", {
    className: classes.blueContent
  }, data.item)), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.floatingDivContentBar
  }, React.createElement("span", {
    className: classes.blackContent
  }, "Description"), React.createElement("span", {
    className: classes.blueContent
  }, data.description)), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.floatingDivContentBar
  }, React.createElement("span", {
    className: classes.blackContent
  }, "Quantity"), React.createElement("span", {
    className: classes.blueContent
  }, data.quantity)), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.floatingDivContentBar
  }, React.createElement("span", {
    className: classes.blackContent
  }, "Batch"), React.createElement("span", {
    className: classes.blueContent
  }, data.batch)), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.floatingDivContentBar
  }, React.createElement("span", {
    className: classes.blackContent
  }, "BBD"), React.createElement("span", {
    className: classes.blueContent
  }, data.bbd)), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 10,
    style: {
      textAlign: "left"
    }
  }, React.createElement("span", {
    className: classes.blackContent,
    style: {
      marginLeft: "15px"
    }
  }, "New Position")), React.createElement(Grid, {
    item: true,
    md: 2
  }, React.createElement("input", {
    type: "checkbox"
  })))))), React.createElement(Grid, {
    item: true,
    md: 6,
    className: classes.floatingDivBarBottom
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "left"
  }, "Back")), React.createElement(Grid, {
    item: true,
    md: 6,
    className: classes.floatingDivBarBottom
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "right"
  }, "Next"))))));
};
AllowScanGs1128Modal.defaultProps = {
  setOpenModalState: function setOpenModalState() {}
};
export default React.memo(AllowScanGs1128Modal);