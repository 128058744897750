// React lib
import React, { useState, useContext } from 'react';
import { AppContext } from '../../../App';

// Styles
import { useStyles } from './ClientDashboardStyles';

// Material-UI Core
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Typography, FONT_FAMILY, Color, Form, Separator, Plus, Button } from '@otg-one/ui_components';

// Service
import ClientService from './ClientService';

// Constants
import { getApiUrl } from '../../../Constants/Api';
import { STACK_TYPE_CUSTOMER } from '../../../Constants/StackType';

const ClientInfo = (props: any) => {
    const classes = useStyles(props);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    
    const { client, setClient } = props

    const {
        currentUserData,
        handleShowSnackbar,
        handleShowAlertSnackbar,
        setSnackbarMessage,
        updateBreadcrumbLabel
    } = useContext(AppContext);

    const [message, setMessage] = useState("");
    const [clientLogoImage, setClientLogoImage] = useState({} as any)
    const [imagePreview, setImagePreview] = useState("")
    const [imageAvailable] = useState(client.logo != null)

    const [errorName, setErrorName] = useState(false)
    const [errorMsgName, setErrorMsgName] = useState("")

    const handleChangeClient = (property: string) => (e: React.FormEvent<HTMLInputElement>) => {
        if (property === "name") {
            setErrorName(false);
            setErrorMsgName("");
        }
        const newClient: any = { ...client };
        newClient[property] = e.currentTarget.value;
        setClient(newClient);
    };

    const triggerChangeClientLogo = () => {
        const inputEl = document.getElementById("inputClientLogo")
        if (inputEl) {
            inputEl.click();
        }
    };

    const handleChangeClientLogo = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.files && e.currentTarget.files.length) {
            setClientLogoImage(e.currentTarget.files);
            const reader = new FileReader();

            reader.addEventListener("load", function () {
                // convert image file to base64 string
                var previewStr = "";
                if (typeof reader.result == "string") {
                    previewStr = reader.result.toString();
                }
                setImagePreview(previewStr);
            }, false);

            if (e.currentTarget.files[0]) {
                reader.readAsDataURL(e.currentTarget.files[0]);
            }
        }
    }

    const handleUpdateClient = () => {
        if (client.name.length < 1) {
            setErrorName(true);
            setErrorMsgName("Please enter customer name!");
            setMessage("Please check your entered values!");
            return;
        }
        ClientService.updateClient(currentUserData.key, client).then((res: any) => {
            if (res.status === 200) {
                updateBreadcrumbLabel(STACK_TYPE_CUSTOMER, client.name);
                if (clientLogoImage && clientLogoImage[0]) {
                    ClientService.updateClientImage(currentUserData.key, client.key, clientLogoImage).then((res: any) => {
                        if (res.status === 200) {
                            setMessage("Customer successfully updated!");
                            setSnackbarMessage("Customer successfully updated!");
                            handleShowSnackbar();
                        } else {
                            setMessage("Something went wrong when updating customer!");
                            setSnackbarMessage("Something went wrong when updating customer!");
                            handleShowAlertSnackbar();
                        }
                    });
                } else {
                    setMessage("Customer successfully updated!");
                    setSnackbarMessage("Customer successfully updated!");
                    handleShowSnackbar();
                }
            } else {
                setMessage("Something went wrong when updating customer!");
                setSnackbarMessage("Something went wrong when updating customer!");
                handleShowAlertSnackbar();
            }
        })
    };

    return (
        <Grid container style={{ flexGrow: 1 }} spacing={2}>
            <Grid item md={12} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                    size={24}
                    color={Color.neutral[900]}
                    style={{ marginBottom: 20 }}
                >
                    {`Customer Info`}
                </Typography>
            </Grid>

            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    {`Customer Name`}
                </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
                <Form
                    style={{ maxWidth: 375 }}
                    placeholder="Customer Name"
                    required
                    value={client.name}
                    onChange={handleChangeClient("name")}
                    error={errorName}
                    errorMessage={errorMsgName}
                />
            </Grid>
            <Grid item md={12} xs={12}>
                <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
            </Grid>

            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    {`Customer Logo`}
                </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
                <div style={{ display: "inline-flex" }}>
                    <div
                        style={{
                            width: 120,
                            height: 120,
                            // border: `4px solid ${Color.neutral[300]}`
                        }}
                        onClick={triggerChangeClientLogo}
                    >
                        <div className={classes.changeLogo}>
                            <input type="file" id="inputClientLogo" style={{ display: "none" }} onChange={handleChangeClientLogo} />
                            {imagePreview &&
                                <img
                                    src={imagePreview}
                                    alt="customer-logo"
                                    width="100%" height="100%"
                                />
                            }
                            {imageAvailable && !imagePreview &&
                                <img
                                    src={getApiUrl() + "clients/" + client.key + "/clientimage?userkey=" + currentUserData.key}
                                    alt="customer-logo"
                                    width="100%" height="100%"
                                />
                            }
                            {!imageAvailable && !imagePreview &&
                                <Plus style={{ width: 70, height: 70, color: Color.neutral[100] }} />
                            }
                        </div>
                    </div>
                    <div>
                        <Button
                            type="secondary"
                            style={{ marginTop: 80, marginLeft: 30 }}
                            onClick={triggerChangeClientLogo}
                        >
                            {"Change logo"}
                        </Button>
                    </div>
                </div>
            </Grid>
            <Grid item md={12} xs={12}>
                <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
            </Grid>

            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    {`Contact Name`}
                </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
                <Form
                    style={{ maxWidth: 375 }}
                    placeholder="Contact Name"
                    value={client.contact_name}
                    onChange={handleChangeClient("contact_name")}
                />
            </Grid>
            <Grid item md={12} xs={12}>
                <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
            </Grid>

            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    {`Email`}
                </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
                <Form
                    style={{ maxWidth: 375 }}
                    placeholder="Email"
                    value={client.email}
                    onChange={handleChangeClient("email")}
                />
            </Grid>
            <Grid item md={12} xs={12}>
                <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
            </Grid>

            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    {`Phone`}
                </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
                <Form
                    style={{ maxWidth: 375 }}
                    placeholder="Phone"
                    value={client.phone}
                    onChange={handleChangeClient("phone")}
                />
            </Grid>
            <Grid item md={12} xs={12}>
                <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
            </Grid>

            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    {`Address`}
                </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
                <Form
                    style={{ maxWidth: 375 }}
                    placeholder="Address"
                    multiline
                    rows={4}
                    value={client.address}
                    onChange={handleChangeClient("address")}
                />
                <div>
                    <Button
                        style={{ 
                            width: isMobile ? '-webkit-fill-available' : 'auto',
                            justifyContent: isMobile ? 'center' : 'flex-start',
                            marginTop: 50 
                        }}
                        onClick={handleUpdateClient}
                    >
                        {`Save Changes`}
                    </Button>
                </div>
            </Grid>

            <Grid item md={12} xs={12}>
                <div
                    style={{
                        boxSizing: "border-box",
                        padding: 10,
                        fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
                        color: message === "Customer successfully updated!" ? Color.success[500] : Color.danger[300],
                        fontSize: 16,
                        textAlign: "center"
                    }}
                >
                    {message}
                </div>
            </Grid>
        </Grid>
    )
}

export default ClientInfo