import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// React libraries
import React from 'react';
import classnames from 'classnames'; // Component Style

import { useStyles } from './CheckboxStyles'; // Assets

import Checkmark from '../../icons/Checkmark/Checkmark';
export var Checkbox = function Checkbox(props) {
  var _classnames, _classnames2;

  var classes = useStyles(props);
  var type = props.type,
      name = props.name,
      value = props.value,
      label = props.label,
      disabled = props.disabled,
      style = props.style,
      onChange = props.onChange,
      checked = props.checked;
  var labelClasses = classnames((_classnames = {}, _defineProperty(_classnames, classes.label, true), _defineProperty(_classnames, classes[type], type), _defineProperty(_classnames, classes.disabled, disabled), _classnames));
  var checkMarkClasses = classnames((_classnames2 = {}, _defineProperty(_classnames2, classes.checkmark, true), _defineProperty(_classnames2, classes.disabled, disabled), _classnames2));
  return React.createElement("div", {
    style: style
  }, React.createElement("input", {
    type: "checkbox",
    className: classes.input,
    name: name,
    id: "".concat(name, "-").concat(value),
    value: value,
    onChange: onChange,
    disabled: disabled,
    checked: checked
  }), React.createElement("label", {
    className: labelClasses,
    htmlFor: "".concat(name, "-").concat(value)
  }, React.createElement(Checkmark, {
    className: checkMarkClasses
  }), label));
};
Checkbox.defaultProps = {
  type: "n900",
  disabled: false
};
export default React.memo(Checkbox);