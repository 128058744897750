import React from 'react';
export var EmptySystem = function EmptySystem() {
  return React.createElement("svg", {
    width: "300px",
    height: "286px",
    viewBox: "0 0 300 286",
    version: "1.1",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, React.createElement("title", null, "FDA0D84D-B65D-4F56-ACF0-5F5B36FFB2AE@1.00x"), React.createElement("desc", null, "Created with sketchtool."), React.createElement("defs", null, React.createElement("linearGradient", {
    x1: "49.9988159%",
    y1: "100%",
    x2: "49.9988159%",
    y2: "0%",
    id: "linearGradient-1"
  }, React.createElement("stop", {
    "stop-color": "#808080",
    "stop-opacity": "0.25",
    offset: "0%"
  }), React.createElement("stop", {
    "stop-color": "#808080",
    "stop-opacity": "0.12",
    offset: "54%"
  }), React.createElement("stop", {
    "stop-color": "#808080",
    "stop-opacity": "0.1",
    offset: "100%"
  })), React.createElement("linearGradient", {
    x1: "50%",
    y1: "100%",
    x2: "50%",
    y2: "0%",
    id: "linearGradient-2"
  }, React.createElement("stop", {
    "stop-color": "#808080",
    "stop-opacity": "0.25",
    offset: "0%"
  }), React.createElement("stop", {
    "stop-color": "#808080",
    "stop-opacity": "0.12",
    offset: "54%"
  }), React.createElement("stop", {
    "stop-color": "#808080",
    "stop-opacity": "0.1",
    offset: "100%"
  })), React.createElement("linearGradient", {
    x1: "59.8048284%",
    y1: "98.8803643%",
    x2: "40.249467%",
    y2: "1.02948481%",
    id: "linearGradient-3"
  }, React.createElement("stop", {
    "stop-color": "#808080",
    "stop-opacity": "0.25",
    offset: "0%"
  }), React.createElement("stop", {
    "stop-color": "#808080",
    "stop-opacity": "0.12",
    offset: "54%"
  }), React.createElement("stop", {
    "stop-color": "#808080",
    "stop-opacity": "0.1",
    offset: "100%"
  })), React.createElement("linearGradient", {
    x1: "49.9988159%",
    y1: "100%",
    x2: "49.9988159%",
    y2: "0%",
    id: "linearGradient-4"
  }, React.createElement("stop", {
    "stop-color": "#808080",
    "stop-opacity": "0.25",
    offset: "0%"
  }), React.createElement("stop", {
    "stop-color": "#808080",
    "stop-opacity": "0.12",
    offset: "54%"
  }), React.createElement("stop", {
    "stop-color": "#808080",
    "stop-opacity": "0.1",
    offset: "100%"
  })), React.createElement("linearGradient", {
    x1: "50%",
    y1: "100%",
    x2: "50%",
    y2: "0%",
    id: "linearGradient-5"
  }, React.createElement("stop", {
    "stop-color": "#808080",
    "stop-opacity": "0.25",
    offset: "0%"
  }), React.createElement("stop", {
    "stop-color": "#808080",
    "stop-opacity": "0.12",
    offset: "54%"
  }), React.createElement("stop", {
    "stop-color": "#808080",
    "stop-opacity": "0.1",
    offset: "100%"
  })), React.createElement("linearGradient", {
    x1: "50%",
    y1: "99.9144422%",
    x2: "50%",
    y2: "-0.0855578371%",
    id: "linearGradient-6"
  }, React.createElement("stop", {
    "stop-color": "#808080",
    "stop-opacity": "0.25",
    offset: "0%"
  }), React.createElement("stop", {
    "stop-color": "#808080",
    "stop-opacity": "0.12",
    offset: "54%"
  }), React.createElement("stop", {
    "stop-color": "#808080",
    "stop-opacity": "0.1",
    offset: "100%"
  }))), React.createElement("g", {
    id: "No-System",
    stroke: "none",
    "stroke-width": "1",
    fill: "none",
    "fill-rule": "evenodd"
  }, React.createElement("g", {
    id: "Client's-Dashboard---Thumbnail-View",
    transform: "translate(-582.000000, -987.000000)",
    "fill-rule": "nonzero"
  }, React.createElement("g", {
    id: "undraw_no_data_qbuo-(1)",
    transform: "translate(582.000000, 987.000000)"
  }, React.createElement("polygon", {
    id: "Rectangle",
    fill: "#E0E0E0",
    transform: "translate(126.469509, 140.927380) rotate(-12.110000) translate(-126.469509, -140.927380) ",
    points: "46.6654792 30.4146443 206.371279 30.343904 206.273538 251.440116 46.567739 251.510856"
  }), React.createElement("g", {
    id: "Group",
    opacity: "0.5",
    transform: "translate(29.000000, 20.000000)",
    fill: "url(#linearGradient-1)"
  }, React.createElement("polygon", {
    id: "Rectangle",
    transform: "translate(98.040526, 120.942797) rotate(-12.110000) translate(-98.040526, -120.942797) ",
    points: "20.8883922 14.0401625 175.311734 13.9539812 175.19266 227.845432 20.7693181 227.931613"
  })), React.createElement("polygon", {
    id: "Rectangle",
    fill: "#FAFAFA",
    transform: "translate(126.304877, 140.158782) rotate(-12.110000) translate(-126.304877, -140.158782) ",
    points: "51.1612016 35.740586 201.540902 35.6739766 201.448552 244.576979 51.0688517 244.643588"
  }), React.createElement("polygon", {
    id: "Rectangle",
    fill: "url(#linearGradient-2)",
    transform: "translate(104.629352, 37.977708) rotate(-12.110000) translate(-104.629352, -37.977708) ",
    points: "67.3326104 28.9408632 141.934098 28.9078191 141.926093 47.0145523 67.3246059 47.0475964"
  }), React.createElement("path", {
    d: "M99.2518792,11.2363535 C93.5858931,12.4836026 90.0009894,18.3128906 91.2474328,24.2552223 C92.4938763,30.197554 98.0936796,34.0108956 103.748635,32.7636465 C109.403591,31.5163974 112.999525,25.6871094 111.753082,19.7447777 C110.506638,13.802446 104.917865,9.98910444 99.2518792,11.2363535 Z M102.807368,28.2305932 C100.358059,28.8292242 97.7987542,27.8500429 96.331453,25.75293 C94.8641518,23.655817 94.7804011,20.8574606 96.1195351,18.6721321 C97.4586691,16.4868036 99.9546071,15.3487193 102.435111,15.79239 C104.915615,16.2360607 106.887818,18.1733307 107.425459,20.694345 C107.792262,22.3216166 107.511226,24.0316035 106.644533,25.4459575 C105.77784,26.8603114 104.39694,27.8624325 102.807368,28.2305932 Z",
    id: "Shape",
    fill: "url(#linearGradient-3)"
  }), React.createElement("polygon", {
    id: "Rectangle",
    fill: "#00A7DD",
    transform: "translate(104.383129, 36.828008) rotate(-12.110000) translate(-104.383129, -36.828008) ",
    points: "67.8719136 28.1899321 140.901997 28.1575841 140.894345 45.4660831 67.864262 45.4984312"
  }), React.createElement("path", {
    d: "M98.9018856,12.2226892 C93.5032843,13.381532 90.0661029,18.6977371 91.2246185,24.0969322 C92.383134,29.4961272 97.6986654,32.9338643 103.097357,31.7754405 C108.496048,30.6170167 111.933642,25.3010783 110.775545,19.9017934 C110.21935,17.308697 108.655862,15.0428106 106.429079,13.6026735 C104.202296,12.1625364 101.494654,11.6661357 98.9018856,12.2226892 L98.9018856,12.2226892 Z M102.209471,27.6524976 C99.9274093,28.142215 97.5750936,27.2088257 96.2494892,25.287601 C94.9238848,23.3663763 94.8860717,20.8357045 96.1536835,18.8757243 C97.4212952,16.9157441 99.7446736,15.9124768 102.040348,16.3337802 C104.336022,16.7550836 106.151856,18.5179813 106.641063,20.8003738 C107.30894,23.9163475 105.324993,26.9839243 102.209471,27.6524976 L102.209471,27.6524976 Z",
    id: "Shape",
    fill: "#00A7DD"
  }), React.createElement("rect", {
    id: "Rectangle",
    fill: "#E0E0E0",
    x: "94",
    y: "43",
    width: "160",
    height: "221"
  }), React.createElement("g", {
    id: "Group",
    opacity: "0.5",
    transform: "translate(97.000000, 46.000000)",
    fill: "url(#linearGradient-4)"
  }, React.createElement("rect", {
    id: "Rectangle",
    x: "0.102600473",
    y: "0.0439726027",
    width: "154.725177",
    height: "213.783801"
  })), React.createElement("rect", {
    id: "Rectangle",
    fill: "#FFFFFF",
    x: "99",
    y: "48",
    width: "150",
    height: "209"
  }), React.createElement("rect", {
    id: "Rectangle",
    fill: "url(#linearGradient-5)",
    x: "137",
    y: "39",
    width: "75",
    height: "18"
  }), React.createElement("path", {
    d: "M174.5,21 C168.701822,21 164,25.9240246 164,32 C164,38.0759754 168.701822,43 174.5,43 C180.298178,43 185,38.0759754 185,32 C185,25.9240246 180.298178,21 174.5,21 Z M174.5,38.3470226 C171.978642,38.4082486 169.672077,36.9012686 168.665451,34.5350555 C167.658825,32.1688424 168.152575,29.4145501 169.91441,27.5679611 C171.676246,25.721372 174.355356,25.1501741 176.691328,26.1230926 C179.0273,27.0960111 180.555001,29.4193212 180.555711,32 C180.580255,35.4521748 177.873619,38.2755538 174.5,38.3169062 L174.5,38.3470226 Z",
    id: "Shape",
    fill: "url(#linearGradient-6)"
  }), React.createElement("rect", {
    id: "Rectangle",
    fill: "#00A7DD",
    x: "138",
    y: "38",
    width: "73",
    height: "18"
  }), React.createElement("path", {
    d: "M174.000146,22 C169.955583,22 166.309273,24.4363862 164.761486,28.1730755 C163.213698,31.9097647 164.069244,36.2108802 166.929182,39.0708183 C169.78912,41.9307565 174.090235,42.7863015 177.826925,41.2385142 C181.563614,39.6907268 184,36.0444172 184,31.9998539 C184,26.4770871 179.522913,22 174.000146,22 L174.000146,22 Z M174.000146,37.7713413 C170.815443,37.7673802 168.235837,35.1845535 168.235837,31.999848 C168.235837,28.8151424 170.815464,26.2323249 174.000167,26.2283708 C177.18487,26.2244257 179.770889,28.8008368 179.778794,31.9855325 C179.777845,33.5168472 179.168378,34.9850137 178.084568,36.0668091 C177.000758,37.1486046 175.53146,37.7553398 174.000146,37.7534397 L174.000146,37.7713413 Z",
    id: "Shape",
    fill: "#00A7DD"
  }), React.createElement("g", {
    id: "Group",
    opacity: "0.5",
    transform: "translate(2.000000, 270.000000)",
    fill: "#47E6B1"
  }, React.createElement("rect", {
    id: "Rectangle",
    x: "3.37090909",
    y: "0.0181818182",
    width: "1.36727273",
    height: "7.75636364"
  }), React.createElement("polygon", {
    id: "Rectangle",
    transform: "translate(4.056364, 3.898182) rotate(90.000000) translate(-4.056364, -3.898182) ",
    points: "3.37272727 0.02 4.74 0.02 4.74 7.77636364 3.37272727 7.77636364"
  })), React.createElement("g", {
    id: "Group",
    opacity: "0.5",
    transform: "translate(162.000000, 0.000000)",
    fill: "#47E6B1"
  }, React.createElement("rect", {
    id: "Rectangle",
    x: "3.30086957",
    y: "0",
    width: "1.30782609",
    height: "7.75636364"
  }), React.createElement("polygon", {
    id: "Rectangle",
    transform: "translate(3.956522, 3.876364) rotate(90.000000) translate(-3.956522, -3.876364) ",
    points: "3.27288538 0.166798419 4.6401581 0.166798419 4.6401581 7.58592885 3.27288538 7.58592885"
  })), React.createElement("path", {
    d: "M281.510406,283.050065 C280.742658,282.715857 280.347396,281.8592 280.591354,281.05819 C280.610374,280.999007 280.622376,280.937794 280.627115,280.875811 L280.627115,280.875811 C280.649025,280.512152 280.430115,280.177077 280.088288,280.051054 C279.74646,279.925031 279.362435,280.037819 279.143043,280.328671 L279.143043,280.328671 C279.105857,280.378487 279.07465,280.432498 279.050065,280.489594 C278.715857,281.257342 277.8592,281.652604 277.05819,281.408646 C276.999007,281.389626 276.937794,281.377624 276.875811,281.372885 L276.875811,281.372885 C276.512152,281.350975 276.177077,281.569885 276.051054,281.911712 C275.925031,282.25354 276.037819,282.637565 276.328671,282.856957 L276.328671,282.856957 C276.378487,282.894143 276.432498,282.92535 276.489594,282.949935 C277.257342,283.284143 277.652604,284.1408 277.408646,284.94181 C277.389626,285.000993 277.377624,285.062206 277.372885,285.124189 L277.372885,285.124189 C277.350975,285.487848 277.569885,285.822923 277.911712,285.948946 C278.25354,286.074969 278.637565,285.962181 278.856957,285.671329 L278.856957,285.671329 C278.894143,285.621513 278.92535,285.567502 278.949935,285.510406 C279.284143,284.742658 280.1408,284.347396 280.94181,284.591354 C281.000993,284.610374 281.062206,284.622376 281.124189,284.627115 L281.124189,284.627115 C281.487848,284.649025 281.822923,284.430115 281.948946,284.088288 C282.074969,283.74646 281.962181,283.362435 281.671329,283.143043 L281.671329,283.143043 C281.621513,283.105857 281.567502,283.07465 281.510406,283.050065 Z",
    id: "Path",
    fill: "#4D8AF0",
    opacity: "0.5"
  }), React.createElement("path", {
    d: "M49.5104057,208.050065 C48.7426578,207.715857 48.3473963,206.8592 48.5913541,206.05819 C48.6103737,205.999007 48.6223762,205.937794 48.6271148,205.875811 L48.6271148,205.875811 C48.6490248,205.512152 48.4301147,205.177077 48.0882876,205.051054 C47.7464605,204.925031 47.3624346,205.037819 47.1430431,205.328671 L47.1430431,205.328671 C47.1058568,205.378487 47.0746504,205.432498 47.0500651,205.489594 C46.7158566,206.257342 45.8592002,206.652604 45.0581904,206.408646 C44.9990069,206.389626 44.9377943,206.377624 44.8758105,206.372885 L44.8758105,206.372885 C44.512152,206.350975 44.1770769,206.569885 44.0510539,206.911712 C43.9250309,207.25354 44.0378187,207.637565 44.3286708,207.856957 L44.3286708,207.856957 C44.3784867,207.894143 44.4324978,207.92535 44.4895943,207.949935 C45.2573422,208.284143 45.6526037,209.1408 45.4086459,209.94181 C45.3896263,210.000993 45.3776238,210.062206 45.3728852,210.124189 L45.3728852,210.124189 C45.3509752,210.487848 45.5698853,210.822923 45.9117124,210.948946 C46.2535395,211.074969 46.6375654,210.962181 46.8569569,210.671329 L46.8569569,210.671329 C46.8941432,210.621513 46.9253496,210.567502 46.9499349,210.510406 C47.2841434,209.742658 48.1407998,209.347396 48.9418096,209.591354 C49.0009931,209.610374 49.0622057,209.622376 49.1241895,209.627115 L49.1241895,209.627115 C49.487848,209.649025 49.8229231,209.430115 49.9489461,209.088288 C50.0749691,208.74646 49.9621813,208.362435 49.6713292,208.143043 L49.6713292,208.143043 C49.6215133,208.105857 49.5675022,208.07465 49.5104057,208.050065 Z",
    id: "Path",
    fill: "#FDD835",
    opacity: "0.5"
  }), React.createElement("path", {
    d: "M243.510406,25.0500651 C242.742658,24.7158566 242.347396,23.8592002 242.591354,23.0581904 C242.610374,22.9990069 242.622376,22.9377943 242.627115,22.8758105 L242.627115,22.8758105 C242.649025,22.512152 242.430115,22.1770769 242.088288,22.0510539 C241.74646,21.9250309 241.362435,22.0378187 241.143043,22.3286708 L241.143043,22.3286708 C241.105857,22.3784867 241.07465,22.4324978 241.050065,22.4895943 C240.715857,23.2573422 239.8592,23.6526037 239.05819,23.4086459 C238.999007,23.3896263 238.937794,23.3776238 238.875811,23.3728852 L238.875811,23.3728852 C238.512152,23.3509752 238.177077,23.5698853 238.051054,23.9117124 C237.925031,24.2535395 238.037819,24.6375654 238.328671,24.8569569 L238.328671,24.8569569 C238.378487,24.8941432 238.432498,24.9253496 238.489594,24.9499349 C239.257342,25.2841434 239.652604,26.1407998 239.408646,26.9418096 C239.389626,27.0009931 239.377624,27.0622057 239.372885,27.1241895 L239.372885,27.1241895 C239.350975,27.487848 239.569885,27.8229231 239.911712,27.9489461 C240.25354,28.0749691 240.637565,27.9621813 240.856957,27.6713292 L240.856957,27.6713292 C240.894143,27.6215133 240.92535,27.5675022 240.949935,27.5104057 C241.284143,26.7426578 242.1408,26.3473963 242.94181,26.5913541 C243.000993,26.6103737 243.062206,26.6223762 243.124189,26.6271148 L243.124189,26.6271148 C243.487848,26.6490248 243.822923,26.4301147 243.948946,26.0882876 C244.074969,25.7464605 243.962181,25.3624346 243.671329,25.1430431 L243.671329,25.1430431 C243.621513,25.1058568 243.567502,25.0746504 243.510406,25.0500651 L243.510406,25.0500651 Z",
    id: "Path",
    fill: "#FDD835",
    opacity: "0.5"
  }), React.createElement("circle", {
    id: "Oval",
    fill: "#F55F44",
    opacity: "0.5",
    cx: "297",
    cy: "115",
    r: "3"
  }), React.createElement("circle", {
    id: "Oval",
    fill: "#F55F44",
    opacity: "0.5",
    cx: "85",
    cy: "274",
    r: "3"
  }), React.createElement("circle", {
    id: "Oval",
    fill: "#F55F44",
    opacity: "0.5",
    cx: "268",
    cy: "175",
    r: "3"
  }), React.createElement("circle", {
    id: "Oval",
    fill: "#4D8AF0",
    opacity: "0.5",
    cx: "32",
    cy: "35",
    r: "3"
  }), React.createElement("circle", {
    id: "Oval",
    fill: "#47E6B1",
    opacity: "0.5",
    cx: "3",
    cy: "110",
    r: "3"
  })))));
};
export default EmptySystem;