// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    whiteContentTopRadius: {
      backgroundColor: "#ffffff",
      marginTop: "-10px !important",
      textAlign: "center",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      paddingBottom: "20px",
      overflow: "hidden"
    },
    greyContentPlain: {
      backgroundColor: "#f3f6f6",
      overflow: "auto"
    },
    greyContent: {
      backgroundColor: "#f3f6f6",
      overflow: "auto",
      padding: "15px"
    },
    greyCenterContent: {
      "& p": {
        fontSize: "12px",
        marginTop: 0,
        color: "#abadb1",
        textAlign: "center",
        textTransform: "capitalize"
      }
    },
    whiteContentBarWithCloseButton: {
      backgroundColor: "#ffffff",
      height: "45px",
      borderBottom: "1px solid #f5f5f5",
      // padding: "10px",
      position: "relative",
      display: "flex",
      alignItems: "center"
    },
    whiteContentBarEmptyList: {
      backgroundColor: "#ffffff",
      height: "40px",
      borderBottom: "1px solid #f5f5f5",
      padding: "10px",
      position: "relative"
    },
    whiteContentBarCloseButton: {
      position: "absolute",
      color: "#00a7dd",
      right: 15,
      cursor: "pointer"
    },
    blackLeftContent: {
      fontSize: "12px",
      color: "black",
      marginTop: 0,
      textAlign: "left",
      textTransform: "capitalize",
      paddingLeft: "10px"
    }
  });
});