// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Color } from '@otg-one/ui_components';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        '@-webkit-keyframes slide-in-top': {
            '0%': {
                transform: `translateY(-1000px)`,
                opacity: 0
            },
            '100%': {
                transform: `translateY(0)`,
                opacity: 1
            }
        },
        '@keyframes slide-in-top': {
            '0%': {
                transform: `translateY(-1000px)`,
                opacity: 0
            },
            '100%': {
                transform: `translateY(0)`,
                opacity: 1
            }
        },

        '@-webkit-keyframes slide-from-bottom': {
            '0%': {
                transform: `translateY(1000px)`,
                opacity: 0
            },
            '100%': {
                transform: `translateY(0)`,
                opacity: 1
            }
        },
        '@keyframes slide-from-bottom': {
            '0%': {
                transform: `translateY(1000px)`,
                opacity: 0
            },
            '100%': {
                transform: `translateY(0)`,
                opacity: 1
            }
        },

        '@-webkit-keyframes slide-to-bottom': {
            '0%': {
                transform: `translateY(0)`,
                opacity: 1
            },
            '100%': {
                transform: `translateY(1000px)`,
                opacity: 0
            }
        },
        '@keyframes slide-to-bottom': {
            '0%': {
                transform: `translateY(0)`,
                opacity: 1
            },
            '100%': {
                transform: `translateY(1000px)`,
                opacity: 0
            }
        },

        '@-webkit-keyframes slide-from-top': {
            '0%': {
                transform: `translateY(0)`,
                opacity: 1
            },
            '100%': {
                transform: `translateY(-1000px)`,
                opacity: 0
            }
        },
        '@keyframes slide-from-top': {
            '0%': {
                transform: `translateY(0)`,
                opacity: 1
            },
            '100%': {
                transform: `translateY(-1000px)`,
                opacity: 0
            }
        },

        configurationContainer: {
            [theme.breakpoints.down('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "5%"
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "10%"
            }
        },
        configurationHeader: {
            boxSizing: "border-box",
            width: "100%",
            background: Color.neutral[0],
            position: "sticky",
            top: 64,
            zIndex: 9000,
            paddingTop: 20,
            paddingBottom: 20,
            display: "flex",
            alignItems: "flex-end"
        },

        sidebarContainer: {
            position: "sticky",
            top: 150,
            width: "90%",
            borderRight: `2px solid ${Color.neutral[100]}`
        },
        sidebarTitle: {
            display: "inline-flex",
            alignItems: "center",
            "& svg": {
                width: 24,
                height: 24,
                color: Color.primary[500],
                marginRight: 8
            }
        },

        saveConfigurationButton: {
            position: "fixed",
            width: 'fit-content',
            top: '16%',
            [theme.breakpoints.up('md')]: {
                right: "5%",
            },
            [theme.breakpoints.up('lg')]: {
                right: "10%",
            },
            marginRight: 65
        },

        alertIcon: {
            position: "fixed",
            top: '17.3%',
            [theme.breakpoints.up('md')]: {
                right: "5%",
            },
            [theme.breakpoints.up('lg')]: {
                right: "10%",
            },
            marginRight: 25,
            color: Color.danger[400],
            "&:hover": {
                color: Color.danger[300]
            },
            cursor: "pointer"
        },

        appPreviewContainer: {
            position: "fixed",
            top: '23%',
            [theme.breakpoints.up('md')]: {
                right: "5%",
            },
            [theme.breakpoints.up('lg')]: {
                right: "10%",
            },
            width: 320,
            height: 568,
            zIndex: 1000,
            textAlign: "center",
            boxShadow: '0px 2px 24px 0px rgba(0, 0, 0, 0.24)',
            fontFamily: "InterMedium",
        },

        appPreviewAnimationSlideTop: {
            webkitAnimation: `$slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both`,
            animation: `$slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both`
        },
        appPreviewAnimationSlideFromBottom: {
            webkitAnimation: `$slide-from-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`,
            animation: `$slide-from-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`
        },

        appPreviewAnimationSlideToBottom: {
            webkitAnimation: `$slide-to-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both`,
            animation: `$slide-to-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both`
        },
        appPreviewAnimationSlideFromTop: {
            webkitAnimation: `$slide-from-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) reverse both`,
            animation: `$slide-from-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) reverse both`
        }
    } as any)
) 