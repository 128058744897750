// React lib
import React, { useContext, useState } from 'react';

// Styles
import { useStyles } from './ChangePasswordStyles';

// Components
import {
    Button,
    Form,
    Typography,
    FONT_FAMILY,
    Color,
    Plus,
    Separator,
    Breadcrumb
} from '@otg-one/ui_components';

// Material-UI Core
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AuthService from '../Login/AuthService';
import { AppContext } from '../../App';

// Components
// import { BreadcrumbType } from '@otg-one/ui_components';

const ChangePassword = (props: any) => {
    const classes = useStyles(props)

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newRepeatPassword, setNewRepeatPassword] = useState("")
    const [message, setMessage] = useState("")

    const [errorCurrentPassword, setErrorCurrentPassword] = useState(false)
    const [errorMsgCurrentPassword, setErrorMsgCurrentPassword] = useState("")
    const [errorNewPassword, setErrorNewPassword] = useState(false)
    const [errorMsgNewPassword, setErrorMsgNewPassword] = useState("")

    const {
        currentUserData
    } = useContext(AppContext);

    const handleChangeCurrentPassword = (e: React.FormEvent<HTMLInputElement>) => {
        setErrorCurrentPassword(false);
        setErrorMsgCurrentPassword("");
        setCurrentPassword(e.currentTarget.value);
    };

    const handleChangeNewPassword = (e: React.FormEvent<HTMLInputElement>) => {
        setErrorNewPassword(false);
        setErrorMsgNewPassword("");
        setNewPassword(e.currentTarget.value);
    };

    const handleChangeNewRepeatPassword = (e: React.FormEvent<HTMLInputElement>) => {
        setNewRepeatPassword(e.currentTarget.value);
    };

    const executePasswordChange = () => {
        let error = false;
        if (currentPassword.length < 1) {
            setErrorCurrentPassword(true);
            setErrorMsgCurrentPassword("Please enter your current password!");
            error = true;
        }
        if (newPassword.length < 8) {
            setErrorNewPassword(true);
            setErrorMsgNewPassword("Please enter a password with a minimum length of 8 characters!");
            error = true;
        } else if (newPassword !== newRepeatPassword) {
            setErrorNewPassword(true);
            setErrorMsgNewPassword("The passwords do not match!");
            error = true;
        }
        if (error) {
            setMessage("Please check your entered values!");
            return;
        }
        AuthService.changePassword(currentUserData, currentPassword, newPassword, newRepeatPassword).then(res=> {
            console.log(res)
            if (res.status === 200) {
                setMessage("Password successfully changed!");
            } else {
                setMessage(res.statusText);
            }
        }).catch(err => {
            setMessage(err.response.data);
            console.log(err.response.data)
        });
    };

    return (
        <div className={classes.changePasswordContainer}>
            <Grid container style={{ flexGrow: 1 }} spacing={2}>
                <Grid item md={4} xs={12}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                        size={14}
                        color={Color.neutral[600]}
                    >
                        {`Current Password`}
                    </Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Form
                        style={{ maxWidth: 375 }}
                        placeholder="Current Password"
                        required
                        password
                        value={currentPassword}
                        onChange={handleChangeCurrentPassword}
                        error={errorCurrentPassword}
                        errorMessage={errorMsgCurrentPassword}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
                </Grid>

                <Grid item md={4} xs={12}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                        size={14}
                        color={Color.neutral[600]}
                    >
                        {`New Password`}
                    </Typography>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                        size={12}
                        color={Color.neutral[500]}
                        style={{ maxWidth: 330, marginTop: 12 }}
                    >
                        {`At least 8 character, should contain lower case, uppercase, number, and special characters`}
                    </Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Form
                        style={{ maxWidth: 375 }}
                        placeholder="New Password"
                        required
                        password
                        value={newPassword}
                        onChange={handleChangeNewPassword}
                        error={errorNewPassword}
                        errorMessage={errorMsgNewPassword}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
                </Grid>

                <Grid item md={4} xs={12}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                        size={14}
                        color={Color.neutral[600]}
                    >
                        {`Repeat Password`}
                    </Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Form
                        style={{ maxWidth: 375 }}
                        placeholder="Repeat Password"
                        required
                        password
                        value={newRepeatPassword}
                        onChange={handleChangeNewRepeatPassword}
                    />
                </Grid>

                <Grid item md={4} xs={12} />
                <Grid item md={8} xs={12}>
                    <Button
                        style={{
                            marginTop: isMobile ? 20 : 100,
                            width: isMobile ? '-webkit-fill-available' : 'auto',
                            justifyContent: isMobile ? 'center' : 'flex-start'
                        }}
                        onClick={executePasswordChange}
                    >
                        {`Change Password`}
                    </Button>
                </Grid>
                <Grid item md={12} xs={12}>
                    <div
                        style={{
                            boxSizing: "border-box",
                            padding: 10,
                            fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
                            color: message === "Password successfully changed!" ? Color.success[500] : Color.danger[300],
                            fontSize: 16,
                            textAlign: "center"
                        }}
                    >
                        {message}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default ChangePassword