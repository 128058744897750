export var FONT_FAMILY = {
  ROBOTO_THIN: "roboto-thin",
  ROBOTO_THIN_ITALIC: "roboto-thin-italic",
  ROBOTO_LIGHT: "roboto-light",
  ROBOTO_LIGHT_ITALIC: "roboto-light-italic",
  ROBOTO_REGULAR: "roboto",
  ROBOTO_REGULAR_ITALIC: "roboto-italic",
  ROBOTO_MEDIUM: "roboto-medium",
  ROBOTO_MEDIUM_ITALIC: "roboto-medium-italic",
  ROBOTO_BOLD: "roboto-bold",
  ROBOTO_BOLD_ITALIC: "roboto-bold-italic",
  ROBOTO_BLACK: "roboto-black",
  ROBOTO_BLACK_ITALIC: "roboto-black-italic",
  CAPITOLIUM: "capitolium",
  CAPITOLIUM_ITALIC: "capitolium-italic",
  CAPITOLIUM_BOLD: "capitolium-bold"
};