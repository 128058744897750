import React, { useContext, useEffect } from 'react';

// Components
import {
    DialogBox,
    Button,
    Typography,
    FONT_FAMILY,
    Color,
} from '@otg-one/ui_components';

const RestoreToDefaultDialog = (props: any) => {
    const { open, onClose, handleRestore } = props

    const handleRestoreToDefault = () => {
        handleRestore(false)
        onClose()
    }

    const handleRestoreAndSave = () => {
        handleRestore(true)
        onClose()
    }

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title="Restore To Default Setting?"
            content={
                <div style={{ width: 600 }}>
                    <Typography
                        fontFamily={FONT_FAMILY.CAPITOLIUM_ITALIC}
                        size={16}
                        color={Color.neutral[900]}
                        style={{ marginBottom: 50 }}
                    >
                        {`Changes setting to default function configuration`}
                    </Typography>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={onClose} type="secondary" style={{ outline: 'none' }}>
                            {`Cancel`}
                        </Button>
                        <div>
                            <Button
                                type="secondary"
                                style={{ outline: 'none', marginRight: 24 }}
                                onClick={handleRestoreToDefault}
                            >
                                {`Restore to Default`}
                            </Button>
                            <Button
                                style={{ outline: 'none' }}
                                onClick={handleRestoreAndSave}
                            >
                                {`Restore and Save`}
                            </Button>
                        </div>
                    </div>
                </div>
            }
        />
    )
};

export default RestoreToDefaultDialog;