export const SETTINGS_FOR_INVENTORY_STATUS = `settingsForInventoryStatus`

export const SETTINGS_FOR_ENTRY = `settingsForEntry`
export const ENTRY_OPTION = `entryOption`

export const VIA_SCAN_OF_ITEM = `viaScanOfItem`
export const WAREHOUSE_NAME = `warehouseName`
export const ITEM_SELECTION = `itemSelection`

export const VIA_SCAN_OF_WAREHOUSE = `viaScanOfWarehouse`
export const WAREHOUSE_SELECTION = `warehouseSelection`