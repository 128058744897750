// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Color } from '@otg-one/ui_components';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        clientDashboardContainer: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
            },
        },
        clientDashboardConfiguredSystemList: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "1%"
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%"
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "5%"
            },
            marginTop: 32,
            backgroundColor: Color.neutral[100]
        },
        clientDashboardConfiguredSystemListTopBar: {
            paddingTop: 24,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },

        clientDashboardClientInfo: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "1%"
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%"
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "5%"
            },
            marginTop: 50
        },
        changeLogo: {
            display: "flex",
            width: "100%",
            height: "100%",
            background: Color.neutral[300],
            transition: "all 0.2s ease",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
                background: Color.neutral[200],
                transition: "all 0.2s ease"
            }
        },
        iconContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 28,
            height: 28,
            background: 'transparent',
            borderRadius: '50%',
            transition: 'all 0.3s ease',
            "& svg": {
                cursor: "pointer",
                width: 24,
                height: 24,
            },
            "&:hover": {
                background: Color.primary[100],
                transition: 'all 0.3s ease',
            }
        },

        customerCostAndLoginChart: {
            position: "relative",
            width: "100%",
            height: 660,
            backgroundColor: Color.neutral[100],
            boxSizing: "border-box",
            padding: "16px 24px 16px 24px",

            [theme.breakpoints.down('xs')]: {
                padding: '10px 15px',

                '& > div:nth-child(2)': {
                    marginTop: '50px !important',
                }
            },
        },

        deviceCount: {
            position: "relative",
            width: "100%",
            height: 380,
            backgroundColor: Color.neutral[100],
            boxSizing: "border-box",
            padding: "16px 24px 16px 24px",

            [theme.breakpoints.down('xs')]: {
                height: 430,
                padding: '10px 15px',

                '& > div:nth-child(2)': {
                    height: "330px !important",
                    marginTop: '50px !important'
                }
            },
        },

        deviceCountHistory: {
            position: "relative",
            width: "100%",
            height: 380,
            backgroundColor: Color.neutral[100],
            boxSizing: "border-box",
            padding: "16px 24px 16px 24px",

            [theme.breakpoints.down('xs')]: {
                height: 474,
                padding: '10px 15px',

                '& > div:nth-child(2)': {
                    marginTop: '94px !important'
                }
            },
        },

        deviceUsage: {
            position: "relative",
            width: "100%",
            height: 380,
            backgroundColor: Color.neutral[100],
            boxSizing: "border-box",
            padding: "16px 24px 16px 24px",

            [theme.breakpoints.down('xs')]: {
                height: 518,
                padding: '10px 15px',

                '& > div:nth-child(2)': {
                    marginTop: '138px !important'
                }
            },
        },

        chartDropdown: {
            position: "absolute",
            top: 16,
            right: 16,

            [theme.breakpoints.down('xs')]: {
                top: 40,
                right: 10,
            },
        },

        secondChartDropdown: {
            position: "absolute",
            top: 16,
            right: 216,

            [theme.breakpoints.down('xs')]: {
                top: 90,
                right: 10,
            },
        },

        thirdChartDropdown: {
            position: "absolute",
            top: 80,
            right: 16,

            [theme.breakpoints.down('xs')]: {
                top: 140,
                right: 10,
            },
        }
    })
)