import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// React libraries
import React from "react";
import classnames from 'classnames'; // Custom Styles

import { useStyles } from "./CarouselStyles";
export var Carousel = function Carousel(props) {
  var classes = useStyles(props);
  var length = props.length,
      index = props.index,
      onClick = props.onClick,
      isMobile = props.isMobile;
  return React.createElement("div", {
    className: isMobile ? classes.carouselCompoundContainerMobile : classes.carouselCompoundContainer
  }, new Array(length).fill(true).map(function (value, arrIndex) {
    var _classnames;

    var carouselNavClasses = classnames((_classnames = {}, _defineProperty(_classnames, classes.carouselContainer, true), _defineProperty(_classnames, classes.carouselActive, arrIndex === index), _classnames));
    return React.createElement("div", {
      className: carouselNavClasses,
      onClick: onClick(arrIndex)
    });
  }));
};
Carousel.defaultProps = {
  length: 2,
  index: 0,
  onClick: function onClick() {}
};
export default React.memo(Carousel);