import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../App';

// Components
import {
    Typography,
    FONT_FAMILY,
    Color,
    Button,
    DialogBox,
    Radio,
    BreadcrumbType
} from '@otg-one/ui_components';
import { Stack } from '../../../Models/Stack';
import { SYSTEM_CONFIGURATION } from '../../../Constants/FilePath';

const CopyToProductionDialog = (props: any) => {
    const { activeSystem, systems, client, open, onClose, handleSubmitCopyFunctionConfig } = props

    const { handleChangeBreadcrumb } = useContext(AppContext);

    const [selectedSystemKey, setSelectedSystemKey] = useState(null);

    useEffect(() => {
        setSelectedSystemKey(null);
    }, [open]);

    const handleChangeSelectedSystemKey = (e: any) => {
        setSelectedSystemKey(e.target.value);
    };

    const handleSubmit = () => {
        handleSubmitCopyFunctionConfig(selectedSystemKey, activeSystem.key);
        onClose();
    };

    const handleCreateNewSystem = () => {
        handleChangeBreadcrumb(BreadcrumbType.PUSH, new Stack('', "Create new System", SYSTEM_CONFIGURATION, { client }));
    };

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title="Copy Function Configuration"
            content={
                <div style={{ maxWidth: 575, paddingTop: 16 }}>
                    <Typography
                        fontFamily={FONT_FAMILY.CAPITOLIUM_ITALIC}
                        size={16}
                        color={Color.neutral[900]}
                        style={{ marginBottom: 18 }}
                    >
                        {`Please select a system to be copied into`}
                    </Typography>
                    {systems.map((system: any, index: number) => activeSystem && (system.key !== activeSystem.key) && (
                        <Radio
                            name="system_radio"
                            label={system.name}
                            value={system.key}
                            checked={selectedSystemKey === system.key}
                            onChange={handleChangeSelectedSystemKey}
                        />
                    ))}
                    <Button
                        type="secondary"
                        onClick={handleCreateNewSystem}
                    >
                        Create New System
                    </Button>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: 45 }}>
                        <Button type="secondary" onClick={onClose}>Cancel</Button>
                        <Button style={{ marginLeft: 16 }} disabled={selectedSystemKey === null} onClick={handleSubmit}>Copy Function Configuration</Button>
                    </div>
                </div>
            }
        />
    )
};

export default CopyToProductionDialog;