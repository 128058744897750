// React Lib
import React, { useState, useContext } from 'react';
import { Link, useHistory } from "react-router-dom";
import { AppContext } from '../../App';

// Styles
import { useStyles } from './RegisterStyles';

// MUI Core
import Grid from '@material-ui/core/Grid';

// Assets
import RegisterImage from "../../assets/img/register.svg";

// Interface
import { IRegisterInterface } from './RegisterInterface';

// Service
import AuthService from "../Login/AuthService";

// Otg One Components
import { Button, Form, Radio, Checkbox, Typography, FONT_FAMILY, Color } from '@otg-one/ui_components';
import { OntegoOneLogoBlack } from '@otg-one/ui_components';

// Etc
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { LOGIN } from '../../Constants/FilePath';

// Dialog
import ActivateAccountDialog from "../Login/ActivateAccountDialog";

const COMPANY_TYPES = [
    { value: "SAP_BUSINESS_ONE_PARTNER", label: "SAP Business One Partner", disabled: false },
    { value: "SAP_BUSINESS_ONE_END_CUSTOMER", label: "SAP Business End Customer", disabled: false },
];

const Register = (props: IRegisterInterface) => {
    const classes = useStyles(props);
    const history = useHistory();
    const {
        setIsLoading,
        AuthService,
        setToken,
        setupUserData,
        handleChangeBreadcrumb
    } = useContext(AppContext);

    const [companyType, setCompanyType] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [voucherCode, setVoucherCode] = useState("");

    const [message, setMessage] = useState("");
    const [company, setCompany] = useState("");
    const [phone, setPhone] = useState("");
    const [infoText, setInfoText] = useState("");
    const [privacyPolicing, setPrivacyPolicing] = useState(false);

    const [errorCompanyType, setErrorCompanyType] = useState(false);
    const [errorName, setErrorName] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorCompany, setErrorCompany] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
    const [errorPrivacyPolicing, setErrorPrivacyPolicing] = useState(false);

    const [errorMsgCompanyType, setErrorMsgCompanyType] = useState("");
    const [errorMsgName, setErrorMsgName] = useState("");
    const [errorMsgEmail, setErrorMsgEmail] = useState("");
    const [errorMsgCompany, setErrorMsgCompany] = useState("");
    const [errorMsgPassword, setErrorMsgPassword] = useState("");
    const [errorMsgConfirmPassword, setErrorMsgConfirmPassword] = useState("");
    const [errorMsgPrivacyPolicing, setErrorMsgPrivacyPolicing] = useState("");


    const [activateAccountDialogOpen, setActivateAccountDialogOpen] = useState(false);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleOpenActivateAccountDialog = () => {
        setActivateAccountDialogOpen(true);
    };
    const handleCloseActivateAccountDialog = () => {
        setActivateAccountDialogOpen(false);
    };

    const handleChangeEmail = (e: React.FormEvent<HTMLInputElement>) => {
        setErrorEmail(false);
        setErrorMsgEmail("");
        setEmail(e.currentTarget.value)
    };

    const handleChangeName = (e: React.FormEvent<HTMLInputElement>) => {
        setErrorName(false);
        setErrorMsgName("");
        setName(e.currentTarget.value)
    };

    const handleChangePassword = (e: React.FormEvent<HTMLInputElement>) => {
        setErrorPassword(false);
        setErrorMsgPassword("");
        setPassword(e.currentTarget.value)
    };

    const handleChangeConfirmPassword = (e: React.FormEvent<HTMLInputElement>) => {
        setErrorConfirmPassword(false);
        setErrorMsgConfirmPassword("");
        setConfirmPassword(e.currentTarget.value)
    };

    const handleChangeCompany = (e: React.FormEvent<HTMLInputElement>) => {
        setErrorCompany(false);
        setErrorMsgCompany("");
        setCompany(e.currentTarget.value)
    };

    const handleChangePhone = (e: React.FormEvent<HTMLInputElement>) => {
        setPhone(e.currentTarget.value)
    };

    const handleChangeVoucherCode = (e: React.FormEvent<HTMLInputElement>) => {
        setVoucherCode(e.currentTarget.value)
    };

    const handleChangeCompanyType = (e: React.FormEvent<HTMLInputElement>) => {
        setCompanyType(e.currentTarget.value);
    }

    const handleChangeInfoText = (e: React.FormEvent<HTMLInputElement>) => {
        setInfoText(e.currentTarget.value);
    }

    const handleChangePrivacyPolicing = (e: React.FormEvent<HTMLInputElement>) => {
        setErrorPrivacyPolicing(false);
        setErrorMsgPrivacyPolicing("");
        setPrivacyPolicing(e.currentTarget.checked);
    }

    const handleRegister = async () => {

        if (!executeRecaptcha) {
            return;
        }

        let error = false;
        if (password.length < 8) {
            setErrorPassword(true);
            setErrorMsgPassword("The length of the password must be at least 8 characters!");
            error = true;
        }
        if (company.length < 1) {
            setErrorCompany(true);
            setErrorMsgCompany("Please enter a company name!");
            error = true;
        }
        if (name.length < 1) {
            setErrorName(true);
            setErrorMsgName("Please enter your full name!");
            error = true;
        }
        if (email.length < 1) {
            setErrorEmail(true);
            setErrorMsgEmail("Please enter your email address!");
            error = true;
        }
        if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            setErrorEmail(true);
            setErrorMsgEmail("Please enter a valid email address!");
            error = true;
        }
        if (companyType.length < 1) {
            setErrorCompanyType(true);
            setErrorMsgCompanyType("Please select your company type!");
            error = true;
        }
        if (!privacyPolicing) {
            setErrorPrivacyPolicing(true);
            setErrorMsgPrivacyPolicing("Please accept the privacy policing!");
            error = true;
        }

        if (error) {
            setMessage("Please check your entered values!");
            return;
        } else {
            if (confirmPassword !== password) {
                setErrorPassword(true);
                setErrorConfirmPassword(true);
                setMessage("The passwords do not match!");
                return;
            }
        }

        const recaptchaResponse = await executeRecaptcha("register");

        const credentials = { email: email, name: name, password: password, company: company, phone: phone, companyType: companyType, infoText: infoText, voucherCode: voucherCode };
        AuthService.register(credentials, recaptchaResponse).then((res: any) => {
            if (res.status === 200) {
                setMessage("Registration successful!");
                setTimeout(() => {
                    history.push(LOGIN);
                }, 2000);
            } else {
                setMessage("Something went wrong when registering!");
            }
        }, (error: any) => {
            console.log(error.response);
            if (error && error.response && error.response.data) {
                setMessage(error.response.data);
            } else {
                setMessage("Something went wrong when registering!");
            }
        });

    };

    return (
        <>
            <div className={classes.rootContainer}>
                <Grid container style={{ flexGrow: 1, width: "100%", height: "100%" }}>
                    <Grid item md={6} xs={12} style={{ maxHeight: "100%" }}>
                        <div className={classes.registerContainer}>
                            <Grid container style={{ flexGrow: 1 }}>
                                <Grid item md={12} xs={12}>
                                    <OntegoOneLogoBlack style={{
                                        width: 120,
                                        height: 60,
                                        marginTop: 70,
                                        marginBottom: 100
                                    }} />
                                    <Typography
                                        fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                        size={48}
                                        color={Color.neutral[600]}
                                        style={{ letterSpacing: -1, marginBottom: 50 }}
                                    >
                                        Create New Account
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Typography
                                        fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                        size={20}
                                        color={Color.neutral[900]}
                                        style={{ marginBottom: 16 }}
                                    >
                                        My Company is
                                    </Typography>
                                    {COMPANY_TYPES.map((radio, index) => (
                                        <Radio
                                            name="company_type_radio"
                                            value={radio.value}
                                            label={radio.label}
                                            disabled={radio.disabled}
                                            checked={radio.value === companyType}
                                            style={{
                                                marginTop: index === COMPANY_TYPES.length - 1 ? -6 : "auto"
                                            }}
                                            onChange={handleChangeCompanyType}
                                        />

                                    ))}
                                    {errorCompanyType &&
                                        <div
                                            style={{
                                                boxSizing: "border-box",
                                                padding: 10,
                                                fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
                                                color: Color.danger[300],
                                                fontSize: 12,
                                                textAlign: "left"
                                            }}
                                        >
                                            {errorMsgCompanyType}
                                        </div>
                                    }
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Typography
                                        fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                        size={20}
                                        color={Color.neutral[900]}
                                        style={{ marginBottom: 16, marginTop: 50 }}
                                    >
                                        Contact Detail
                                    </Typography>
                                    <Form
                                        placeholder="Full Name"
                                        required
                                        style={{ marginBottom: 16 }}
                                        value={name}
                                        onChange={handleChangeName}
                                        error={errorName}
                                        errorMessage={errorMsgName}
                                    />
                                    <Form
                                        placeholder="E-mail Address"
                                        required
                                        style={{ marginBottom: 16 }}
                                        value={email}
                                        onChange={handleChangeEmail}
                                        error={errorEmail}
                                        errorMessage={errorMsgEmail}
                                    />
                                    <Form
                                        placeholder="Company"
                                        required
                                        style={{ marginBottom: 16 }}
                                        value={company}
                                        onChange={handleChangeCompany}
                                        error={errorCompany}
                                        errorMessage={errorMsgCompany}
                                    />
                                    <Form
                                        placeholder="Phone"
                                        style={{ marginBottom: 16 }}
                                        value={phone}
                                        onChange={handleChangePhone}
                                    />
                                    <Form
                                        placeholder="Info"
                                        multiline
                                        rows="5"
                                        value={infoText}
                                        onChange={handleChangeInfoText}
                                    />
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <Form
                                        placeholder="Voucher Code"
                                        value={voucherCode}
                                        onChange={handleChangeVoucherCode}
                                        style={{ marginTop: 50 }}
                                    />
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Typography
                                        fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                        size={20}
                                        color={Color.neutral[900]}
                                        style={{ marginBottom: 16, marginTop: 50 }}
                                    >
                                        Password
                                    </Typography>
                                    <Form
                                        placeholder="Password"
                                        required
                                        value={password}
                                        password
                                        style={{ marginBottom: 16 }}
                                        onChange={handleChangePassword}
                                        error={errorPassword}
                                        errorMessage={errorMsgPassword}
                                    />
                                    <Form
                                        placeholder="Confirm Password"
                                        required
                                        password
                                        style={{ marginBottom: 16 }}
                                        onChange={handleChangeConfirmPassword}
                                        error={errorConfirmPassword}
                                        errorMessage={errorMsgConfirmPassword}
                                    />
                                </Grid>

                                <Grid item md={12} xs={12} style={{ display: "flex", marginTop: 50 }}>
                                    {errorPrivacyPolicing &&
                                        <div
                                            style={{
                                                boxSizing: "border-box",
                                                padding: 10,
                                                fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
                                                color: Color.danger[300],
                                                fontSize: 12,
                                                textAlign: "left"
                                            }}
                                        >
                                            {errorMsgPrivacyPolicing}
                                        </div>
                                    }
                                </Grid>
                                <Grid item md={11} xs={12}>
                                    <div style={{ display: "flex", marginBottom: 20 }}>
                                        <Checkbox
                                            name="privacy_policing_checkbox"
                                            onChange={handleChangePrivacyPolicing}
                                            required
                                        />
                                        <Typography
                                            fontFamily={FONT_FAMILY.ROBOTO_LIGHT}
                                            size={14}
                                            color={Color.neutral[600]}
                                        >
                                            I agree to
                                        </Typography>
                                        <a target="_blank" href="https://www.ontego.one/imprint.html" style={{ textDecoration: "none" }}>
                                            <Typography
                                                fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                                size={14}
                                                style={{ marginLeft: 3 }}
                                                color={Color.primary[600]}
                                            >
                                                {'Privacy Policing'}
                                            </Typography>
                                        </a>
                                    </div>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <div
                                        style={{
                                            boxSizing: "border-box",
                                            padding: 10,
                                            fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
                                            color: (message === "Registration successful!" ? Color.success[500] : Color.danger[300]),
                                            fontSize: 16,
                                            textAlign: "center"
                                        }}
                                    >
                                        {message}
                                    </div>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Button
                                        alignIcon="right"
                                        style={{ marginTop: 50 }}
                                        onClick={handleRegister}
                                    >
                                        Register
                                    </Button>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <div style={{ display: "flex", marginTop: 140, marginBottom: 15 }}>
                                        <Typography
                                            fontFamily={FONT_FAMILY.ROBOTO_LIGHT}
                                            size={14}
                                            color={Color.neutral[600]}
                                        >
                                            Already have account?
                                        </Typography>
                                        <Link to={LOGIN} style={{ textDecoration: "none" }}>
                                            <Typography
                                                fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                                size={14}
                                                color={Color.primary[600]}
                                                style={{ marginLeft: 10 }}
                                            >
                                                Login
                                            </Typography>
                                        </Link>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <Typography
                                            fontFamily={FONT_FAMILY.ROBOTO_LIGHT}
                                            size={14}
                                            color={Color.neutral[600]}
                                        >
                                            Account Inactive?
                                        </Typography>
                                        <Link to='#' style={{ textDecoration: "none" }}>
                                            <Typography
                                                fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                                size={14}
                                                color={Color.primary[600]}
                                                style={{ marginLeft: 10 }}
                                                onClick={handleOpenActivateAccountDialog}
                                            >
                                                Activate Account
                                            </Typography>
                                        </Link>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                backgroundImage: `url(${RegisterImage})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                            }}
                        />
                    </Grid>
                </Grid>
            </div>

            <ActivateAccountDialog
                open={activateAccountDialogOpen}
                onClose={handleCloseActivateAccountDialog}
            />
        </>
    )
}

export default Register;