// React lib
import React, { useContext } from 'react';

// Context
import { InventoryTransferContext } from './InventoryTransferConfiguration';

// Components
import ConfigurationTitle from '../ConfigurationTitle';
import ConfigurationOption from '../ConfigurationOption';

// Constants
import { OPTION_TYPE_RADIO, OPTION_TYPE_CHECKBOX } from '../../../Constants/OptionType';
import { SETTINGS_FOR_INVENTORY_TRANSFER, SELECT_SOURCE_WAREHOUSE, USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM, SERIAL_NUMBER_TYPE } from './InventoryTransferIds';

// Utils
import { inventoryTransferLocalMapToDatabaseValue, arraysMatch } from "../../../Utils/Utils"

const SettingsForInventoryTransfer = (props: any) => {
    const {
        defaultFunctionType,
        activeOption,
        setActiveOption,
        selectSourceWarehouse,
        handleChangeSelectSourceWarehouse,
        useOneBatchWithinOneStorageBinForAnItem,
        handleChangeUseOneBatchWithinOneStorageBinForAnItem,
        serialNumberType,
        handleChangeSerialNumberType
    } = useContext(InventoryTransferContext);

    const selectSourceWarehouseOption = {
        id: SELECT_SOURCE_WAREHOUSE,
        title: `Select Source Warehouse`,
        subtitle: `How should the warehouse be selected?`,
        isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.selectWarehouseInput, inventoryTransferLocalMapToDatabaseValue("selectWarehouseInput", selectSourceWarehouse) as string[])) ? true : false,
        optionType: OPTION_TYPE_CHECKBOX,
        allowEmpty: false,
        isValid: selectSourceWarehouse.length > 0 ? true : false,
        options: [
            {
                label: `Manual text input of the warehouse`,
                value: `textInput`,
                checked: selectSourceWarehouse.includes(`textInput`),
                onChange: handleChangeSelectSourceWarehouse,
                disabled: false
            },
            {
                label: `Barcode Scan`,
                value: `barcodeScan`,
                checked: selectSourceWarehouse.includes(`barcodeScan`),
                onChange: handleChangeSelectSourceWarehouse,
                disabled: false
            },
            {
                label: `OCR Scan ( Unavailable )`,
                value: `OCR Scan ( Unavailable )`,
                checked: selectSourceWarehouse.includes(`OCR Scan ( Unavailable )`),
                onChange: handleChangeSelectSourceWarehouse,
                disabled: true
            },
        ],
        collapsedOption: []
    }

    const useOneBatchWithinOneStorageBinForAnItemOption = {
        id: USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM,
        title: `Use one batch within one storage bin for an item`,
        subtitle: `Use at most one batch per article for one article`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.allowOneBatchForOneBinLoc !== inventoryTransferLocalMapToDatabaseValue("allowOneBatchForOneBinLoc", useOneBatchWithinOneStorageBinForAnItem)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `disable`,
                onChange: handleChangeUseOneBatchWithinOneStorageBinForAnItem,
                checked: useOneBatchWithinOneStorageBinForAnItem === `disable`,
                disabled: false,
                label: `Disable`,
                subLabel: ``
            },
            {
                value: `enable`,
                onChange: handleChangeUseOneBatchWithinOneStorageBinForAnItem,
                checked: useOneBatchWithinOneStorageBinForAnItem === `enable`,
                disabled: false,
                label: `Enable`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const serialNumberTypeOption = {
        id: SERIAL_NUMBER_TYPE,
        heading: '',
        title: `Serial Number Type`,
        subtitle: `Enter Serial Number or Manufacturer Serial Number`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.serialType !== inventoryTransferLocalMapToDatabaseValue("serialType", serialNumberType)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `SER`,
                label: `Serial Number`,
                onChange: handleChangeSerialNumberType,
                checked: serialNumberType === 'SER',
                subLabel: ``,
                disabled: false,
            },
            {
                value: `MNF`,
                label: `Manufacturer Serial Number`,
                onChange: handleChangeSerialNumberType,
                checked: serialNumberType === 'MNF',
                subLabel: ``,
                disabled: false,
            }
        ],
        collapsedOption: []
    }

    return (
        <>
            <ConfigurationOption configurationOption={selectSourceWarehouseOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={useOneBatchWithinOneStorageBinForAnItemOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={serialNumberTypeOption} activeOption={activeOption} setActiveOption={setActiveOption} />
        </>
    );
};

export default SettingsForInventoryTransfer;