// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    floatingDiv: {
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0 0 5px 1px #d0d0d0",
      overflow: "hidden"
    },
    floatingDivBarBottom: {
      height: "50px"
    },
    floatingDivContentButton: {
      height: "50px",
      width: "100%",
      justifyContent: "left",
      color: "#00a7dd",
      textTransform: "capitalize",
      paddingLeft: "30px",
      marginTop: "2px",
      borderRadius: "0",
      "&:hover": {
        color: "#f6891f"
      }
    },
    floatingDivContentButtonSelected: {
      backgroundColor: "#00a7dd",
      color: "white",
      "&:hover": {
        backgroundColor: "#8fcff6",
        color: "white"
      }
    },
    datePickerDiv: {
      height: "405px"
    }
  });
});