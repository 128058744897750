// React Lib
import React, { useState, useEffect } from 'react'

// Material UI Core
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Typography, FONT_FAMILY, Color, Tooltip } from '@otg-one/ui_components'
import { timeout } from '../../Utils/Utils'

interface IConfigurationTitleProps {
    id: string,
    title: string,
    subtitle: string,
    isCustomized: boolean
}

const ConfigurationTitle = (props: IConfigurationTitleProps) => {
    const { id, title, subtitle, isCustomized } = props

    const theme = useTheme()
    const isPhone = useMediaQuery(theme.breakpoints.only('xs'))

    const [isFade, setIsFade] = useState(false)
    const [titleHeight, setTitleHeight] = useState(0)

    useEffect(() => {
        setIsFade(true)

        const fadeIn = async () => {
            await timeout(300)
            setIsFade(false)
        }

        fadeIn()

        let height = document.getElementById('titleWrapper')?.clientHeight
        if (height) {
            setTitleHeight(height)
        } else {
            setTitleHeight(0)
        }

    }, [title])

    return (
        <>
            <div id={id} />
            <div
                id={"titleWrapper"}
                style={{
                    width: '100%',
                    height: 'fit-content',
                    position: `sticky`,
                    top: 128,
                    left: 0,
                    right: 0,
                    paddingBottom: 20,
                    paddingTop: 20,
                    paddingLeft: 10,
                    marginLeft: -10,
                    background: Color.neutral[0],
                    zIndex: 900,
                    transition: isFade ? 'all 0.3s ease' : '',
                    marginBottom: -titleHeight
                }}
            >
                <div style={{ position: "relative" }}>
                    <Typography fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD} size={30} color={Color.neutral[900]}>
                        {title}
                    </Typography>
                    <Typography fontFamily={FONT_FAMILY.ROBOTO_REGULAR} size={14} color={Color.neutral[400]} style={{ width: isPhone ? 'auto' : 480, marginTop: 16 }}>
                        {subtitle}
                    </Typography>
                    {isCustomized && (
                        <Tooltip title="Customized" placement="right">
                            <div
                                style={{
                                    position: "absolute",
                                    top: 6,
                                    left: -6,
                                    width: 8,
                                    height: 8,
                                    background: Color.alert[700],
                                    borderRadius: "50%"
                                }}
                            />
                        </Tooltip>
                    )}
                </div>
            </div>
        </>
    )
}

export default ConfigurationTitle 