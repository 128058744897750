import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
export var AvatarCompany = function AvatarCompany(props) {
  return React.createElement(SvgIcon, Object.assign({}, props), React.createElement("svg", {
    viewBox: "0 0 32 32",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, React.createElement("path", {
    d: "M16,9.88888889 L16,5 L4,5 L4,27 L28,27 L28,9.88888889 L16,9.88888889 Z M8.8,24.5555556 L6.4,24.5555556 L6.4,22.1111111 L8.8,22.1111111 L8.8,24.5555556 Z M8.8,19.6666667 L6.4,19.6666667 L6.4,17.2222222 L8.8,17.2222222 L8.8,19.6666667 Z M8.8,14.7777778 L6.4,14.7777778 L6.4,12.3333333 L8.8,12.3333333 L8.8,14.7777778 Z M8.8,9.88888889 L6.4,9.88888889 L6.4,7.44444444 L8.8,7.44444444 L8.8,9.88888889 Z M13.6,24.5555556 L11.2,24.5555556 L11.2,22.1111111 L13.6,22.1111111 L13.6,24.5555556 Z M13.6,19.6666667 L11.2,19.6666667 L11.2,17.2222222 L13.6,17.2222222 L13.6,19.6666667 Z M13.6,14.7777778 L11.2,14.7777778 L11.2,12.3333333 L13.6,12.3333333 L13.6,14.7777778 Z M13.6,9.88888889 L11.2,9.88888889 L11.2,7.44444444 L13.6,7.44444444 L13.6,9.88888889 Z M25.6,24.5555556 L16,24.5555556 L16,22.1111111 L18.4,22.1111111 L18.4,19.6666667 L16,19.6666667 L16,17.2222222 L18.4,17.2222222 L18.4,14.7777778 L16,14.7777778 L16,12.3333333 L25.6,12.3333333 L25.6,24.5555556 Z M23.2,14.7777778 L20.8,14.7777778 L20.8,17.2222222 L23.2,17.2222222 L23.2,14.7777778 Z M23.2,19.6666667 L20.8,19.6666667 L20.8,22.1111111 L23.2,22.1111111 L23.2,19.6666667 Z"
  })));
};
export default AvatarCompany;