import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React Lib
import React, { useEffect, useState } from "react"; // Component Styles

import { useStyles } from "./DeviceLimitStyles"; // @otg-one/ui_components/components

import { Chart, Typography, OverflowMenu, EditDeviceLimit } from "../index"; // @otg-one/ui_components/icons

import { ShowMoreHorizontal, Device } from "../../icons"; // @otg-one/ui_components/constants

import { Color, FONT_FAMILY, DeviceLimitType } from "../../constants";
export var DeviceLimit = function DeviceLimit(props) {
  var classes = useStyles(props);
  var type = props.type,
      data = props.data,
      handleSubmitDeviceLimit = props.handleSubmitDeviceLimit;
  var deviceLimit = data.deploymentEnv === 0 || data.deviceLimit == null ? data.defaultDeviceLimit : data.deviceLimit;
  var activeDevice = data.activeDevice > deviceLimit ? deviceLimit : data.activeDevice;
  var deviceLimitLeft = deviceLimit - activeDevice;
  var deviceLimitData = [['Status', 'Count'], ['Active Device', activeDevice], ['Device Limit Left', deviceLimitLeft]];
  var changeDeviceLimitDisabled = false;

  if (type === DeviceLimitType.SYSTEM && data.deploymentEnv === 0) {
    deviceLimitData[1][0] = "Parallel ".concat(deviceLimitData[1][0]);
    deviceLimitData[2][0] = "Parallel ".concat(deviceLimitData[2][0]);
    changeDeviceLimitDisabled = true;
  }

  useEffect(function () {
    if (data.key === "482e610668567b783f1f2ab684a98ed4") {
      deviceLimitData[1][1] = 15;
    } else if (data.key === "386baf44424675ae2198938824c9ab2d") {
      deviceLimitData[1][1] = 13;
    } else if (data.key === "25493550db2679aeb55ea4f757546545") {
      deviceLimitData[1][1] = 17;
    }
  }, []);

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      editDeviceLimitDialogOpen = _useState2[0],
      setEditDeviceLimitDialogOpen = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      anchorEl = _useState4[0],
      setAnchorEl = _useState4[1];

  var handleOpenEditDeviceLimitDialog = function handleOpenEditDeviceLimitDialog() {
    setEditDeviceLimitDialogOpen(true);
  };

  var handleCloseEditDeviceLimitDialog = function handleCloseEditDeviceLimitDialog() {
    setEditDeviceLimitDialogOpen(false);
  };

  var handleClickShowMoreIcon = function handleClickShowMoreIcon(event) {
    event.stopPropagation();

    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }

    ;
  };

  return React.createElement("div", {
    className: classes.deviceLimitContainer
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.CAPITOLIUM_BOLD,
    size: 18,
    color: Color.neutral[900]
  }, "Device Limit"), React.createElement("div", {
    style: {
      position: "relative",
      marginTop: 30
    }
  }, React.createElement(Chart, {
    chartType: "PieChart",
    chartData: deviceLimitData,
    chartWidth: "100%",
    chartHeight: "380px",
    chartOptions: {
      chartArea: {
        left: 20,
        right: 20,
        top: 5,
        bottom: 100,
        width: "100%",
        height: "380"
      },
      backgroundColor: "transparent",
      colors: [Color.primary[600], Color.neutral[200]],
      legend: {
        position: "bottom",
        textStyle: {
          fontFamily: FONT_FAMILY.ROBOTO_LIGHT,
          fontSize: 12,
          color: Color.neutral[900]
        }
      },
      pieHole: 0.6,
      pieSliceText: 'none',
      tooltip: {
        text: 'value'
      }
    }
  }), React.createElement("div", {
    style: {
      position: "absolute",
      top: '25%',
      left: data.deploymentEnv === 0 ? '52%' : '47%'
    }
  }, React.createElement(Device, {
    style: {
      width: 24,
      height: 24,
      color: Color.primary[600],
      marginLeft: data.deploymentEnv === 0 ? -18 : 5
    }
  }), React.createElement("div", {
    style: {
      display: 'flex',
      marginTop: -10,
      marginLeft: data.deploymentEnv === 0 ? -40 : deviceLimitData[1][1] > 10 ? -40 : -20
    }
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_BOLD,
    size: 48,
    color: Color.neutral[400],
    style: {
      letterSpacing: -1.7
    }
  }, deviceLimitData[1][1]), React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_BOLD,
    size: 18,
    color: Color.neutral[600],
    style: {
      marginLeft: 6,
      marginTop: 12,
      width: 50
    }
  }, "/ ".concat(parseInt("".concat(deviceLimitData[1][1]), 10) + parseInt("".concat(deviceLimitData[2][1]), 10)))))), React.createElement(ShowMoreHorizontal, {
    style: {
      position: "absolute",
      top: 20,
      right: 20,
      color: Color.primary[500],
      cursor: "pointer"
    },
    onClick: handleClickShowMoreIcon
  }), !changeDeviceLimitDisabled && React.createElement(OverflowMenu, {
    anchorElement: anchorEl,
    setAnchorEl: setAnchorEl,
    menuItems: [{
      text: "Change Device Limit",
      onClick: function onClick() {
        handleOpenEditDeviceLimitDialog();
      }
    }]
  }), React.createElement(EditDeviceLimit, {
    open: editDeviceLimitDialogOpen,
    onClose: handleCloseEditDeviceLimitDialog,
    data: data,
    type: type,
    handleSubmitDeviceLimit: handleSubmitDeviceLimit
  }));
};
DeviceLimit.defaultProps = {
  type: DeviceLimitType.CLIENT,
  data: {
    key: "1",
    name: "Data Name",
    activeDevice: 0,
    deviceLimit: 25,
    defaultDeviceLimit: 25,
    deploymentEnv: 1
  },
  handleSubmitDeviceLimit: function handleSubmitDeviceLimit(data) {}
};
export default React.memo(DeviceLimit);