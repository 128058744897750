import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React Lib
import React, { useState } from "react"; // Component Styles

import { useStyles } from "./UserAccountStyles"; // @otg-one/ui_components/components

import { Chip, Typography } from "../index"; // @otg-one/ui_components/constants

import { Color, FONT_FAMILY } from "../../constants"; // Assets

import ShowMoreHorizontal from "../../icons/ShowMore/Horizontal";
import { OverflowMenu } from "../OverflowMenu";
import { Delete } from "../../icons";
export var UserAccount = function UserAccount(props) {
  var classes = useStyles(props);
  var id = props.id,
      user = props.user,
      setActiveUser = props.setActiveUser,
      setDeleteUserDialogOpen = props.setDeleteUserDialogOpen,
      onClick = props.onClick;

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      anchorEl = _useState2[0],
      setAnchorEl = _useState2[1];

  var handleClickShowMoreIcon = function handleClickShowMoreIcon(event) {
    event.stopPropagation();

    if (!anchorEl) {
      console.log("OPEN", anchorEl);
      setAnchorEl(event.currentTarget);
    }

    ;
  };

  return React.createElement("div", {
    className: classes.userAccountBox,
    onClick: onClick
  }, React.createElement(ShowMoreHorizontal, {
    onClick: handleClickShowMoreIcon
  }), React.createElement(OverflowMenu, {
    anchorElement: anchorEl,
    setAnchorEl: setAnchorEl,
    menuItems: [{
      text: "Delete",
      icon: React.createElement(Delete, {
        style: {
          color: Color.danger[500]
        }
      }),
      destructive: true,
      onClick: function onClick() {
        setActiveUser(user);
        setDeleteUserDialogOpen(true);
      }
    }]
  }), React.createElement("div", {
    id: "".concat(id, "-user-account-picture"),
    className: classes.userAccountPicture
  }), React.createElement("div", null, React.createElement(Typography, {
    id: "".concat(id, "-user-account-name"),
    fontFamily: FONT_FAMILY.CAPITOLIUM_BOLD,
    size: 24,
    style: {
      marginTop: 20
    }
  }, user.name), React.createElement(Typography, {
    id: "".concat(id, "-user-account-email"),
    fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
    size: 16,
    style: {
      marginTop: 5
    }
  }, user.email), user.status === "ADMIN" && React.createElement(Chip, {
    id: "".concat(id, "-user-account-status"),
    backgroundColor: Color.gradient[100],
    style: {
      marginTop: 30
    }
  }, user.status)));
};
UserAccount.defaultProps = {
  user: {
    img: "",
    name: "Jesse Young",
    email: "jesse.young@commsult.id",
    status: "USER"
  },
  onClick: function onClick() {},
  setActiveUser: null,
  setDeleteUserDialogOpen: function setDeleteUserDialogOpen() {}
};
export default React.memo(UserAccount);