import _toConsumableArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./PickPackCompletePickListPageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { Checkbox } from "@material-ui/core";
export var PickPackCompletePickList = function PickPackCompletePickList(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      data = props.data,
      completeOrderType = props.completeOrderType;

  var _React$useState = React.useState(data),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      locationList = _React$useState2[0],
      setLocationList = _React$useState2[1];

  var _React$useState3 = React.useState(0),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      totalChecked = _React$useState4[0],
      setTotalChecked = _React$useState4[1];

  React.useEffect(function () {
    var tempListData = _toConsumableArray(data);

    if (completeOrderType === 'allPositions') {
      tempListData.map(function (data) {
        data.mandatoryBatchOrSerialNumber = false;
        data.isCheck = true;
      });
      setTotalChecked(data.length);
    } //atLeastOnePosition
    else {
      if (tempListData.find(function (data) {
        return data.isCheck === true;
      })) {
        setTotalChecked(tempListData.filter(function (data) {
          return data.isCheck === true && data.mandatoryBatchOrSerialNumber === false;
        }).length);
      } else {
        tempListData.filter(function (data) {
          return data.mandatoryBatchOrSerialNumber === false;
        })[0].isCheck = true;
        setTotalChecked(1);
      }
    }

    setLocationList(data);
  }, [data, completeOrderType]);

  var editListData = function editListData(data) {
    var tempListData = _toConsumableArray(locationList);

    var countCheck = 0;
    tempListData.map(function (tempData) {
      if (tempData.id === data.id) {
        tempData.isCheck = !tempData.isCheck;
      }

      if (!tempData.mandatoryBatchOrSerialNumber && tempData.isCheck) {
        countCheck += 1;
      }
    });
    setTotalChecked(countCheck);
    setLocationList(tempListData);
  };

  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "smallWithSub",
    showScanner: true,
    previewHeight: previewHeight
  }, React.createElement("p", null, "Pick and Pack"), React.createElement("sub", null, "Pick List ", React.createElement("b", null, "120")))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentTopRadius,
    style: {
      height: previewHeight - 90 - 40
    }
  }, React.createElement(Grid, {
    container: true
  }, locationList.length > 1 && React.createElement(Grid, {
    container: true,
    className: classes.progressBar
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, totalChecked === locationList.length ? "Pick and pack completed" : totalChecked + " from " + locationList.length + " completed")), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.greyContentPlain,
    style: {
      height: previewHeight - 90 - 40 - 30
    }
  }, React.createElement(Grid, {
    container: true
  }, locationList.length > 0 && locationList.map(function (data) {
    return React.createElement(React.Fragment, null, React.createElement(Grid, {
      container: true,
      className: classes.contentBar
    }, React.createElement(Grid, {
      item: true,
      md: 10,
      onClick: function onClick(e) {
        !data.mandatoryBatchOrSerialNumber && editListData(data);
      }
    }, React.createElement(Grid, {
      item: true,
      md: 12
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.contentTitle,
      item: true,
      md: 12
    }, "Bin Location"), React.createElement(Grid, {
      className: classes.blackLeftContent,
      item: true,
      md: 12
    }, data.binLocation)))), !data.mandatoryBatchOrSerialNumber && React.createElement(Grid, {
      item: true,
      md: 2
    }, React.createElement(Checkbox, {
      className: classes.checkboxRoot,
      color: "primary",
      disableRipple: true,
      checked: data.isCheck,
      onChange: function onChange(e) {
        editListData(data);
      }
    }))), React.createElement(Grid, {
      item: true,
      md: 12,
      className: classes.ContentBorder
    }));
  }))))), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "left"
  }, "Back")), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "right",
    disabled: totalChecked === 0
  }, "Add"))));
};
export default React.memo(PickPackCompletePickList);