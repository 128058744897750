// React lib
import React, { useContext } from 'react';

// Context
import { ReceiptFromProductionContext } from './ReceiptFromProductionConfiguration';

// Components
import ConfigurationTitle from '../ConfigurationTitle';
import ConfigurationOption from '../ConfigurationOption';

// Constants
import { OPTION_TYPE_RADIO, OPTION_TYPE_CHECKBOX } from '../../../Constants/OptionType';
import { FIELDS_DISPLAYED, MORE_OR_LESS_QUANTITIES_ALLOWED, BEST_BEFORE_DATE, BEST_BEFORE_DATE_INPUT_FIELD, SETTINGS_FOR_POSITION_DETAILS, LESS_QUANTITIES_SECURITY_CHECK, OVERBOOKING_SECURITY_CHECK, SERIAL_NUMBER_TYPE } from './ReceiptFromProductionIds';

// Utils
import { goodsReceiptLocalMapToDatabaseValue, arraysMatch } from "../../../Utils/Utils"

const SettingsForPositionDetails = (props: any) => {
    const {
        defaultFunctionType,
        activeOption,
        setActiveOption,
        fieldsDisplayed,
        handleChangeFieldsDisplayed,
        bestBeforeDate,
        handleChangeBestBeforeDate,
        bestBeforeDateInputField,
        handleChangeBestBeforeDateInputField,
        moreOrLessQuantitiesAllowed,
        handleChangeMoreOrLessQuantitiesAllowed,
        lessQuantitiesSecurityCheck,
        handleChangeLessQuantitiesSecurityCheck,
        overbookingSecurityCheck,
        handleChangeOverbookingSecurityCheck,
        serialNumberType,
        handleChangeSerialNumberType
    } = useContext(ReceiptFromProductionContext);

    const moreOrLessQuantitiesAllowedOption = {
        id: MORE_OR_LESS_QUANTITIES_ALLOWED,
        heading: '',
        title: `More/less Quantities Allowed`,
        subtitle: `Defines whether it is possible to enter more/less quantities with/without security check`,
        isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.allowQtyChange, goodsReceiptLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck) as string[])) ? true : false,
        optionType: OPTION_TYPE_CHECKBOX,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: 'allowLessQuantities',
                onChange: handleChangeMoreOrLessQuantitiesAllowed,
                checked: moreOrLessQuantitiesAllowed.includes('allowLessQuantities'),
                label: `Less Quantities Allowed`,
                subLabel: ``,
                disabled: false,
            },
            {
                value: 'allowOverbooking',
                onChange: handleChangeMoreOrLessQuantitiesAllowed,
                checked: moreOrLessQuantitiesAllowed.includes('allowOverbooking'),
                label: `Overbooking Allowed`,
                subLabel: ``,
                disabled: false,
            }
        ],
        collapsedOption: []
    }

    const lessQuantitiesSecurityCheckOption = {
        id: LESS_QUANTITIES_SECURITY_CHECK,
        heading: 'MORE/LESS QUANTITIES ALLOWED - LESS QUANTITIES ALLOWED',
        title: `Less Quantities Security Check`,
        subtitle: ``,
        isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.allowQtyChange, goodsReceiptLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck) as string[])) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: 'lessQuantityAllowedWithCheck',
                onChange: handleChangeLessQuantitiesSecurityCheck,
                checked: lessQuantitiesSecurityCheck === 'lessQuantityAllowedWithCheck',
                label: `With Security Check`,
                subLabel: ``,
                disabled: false,
            },
            {
                value: 'lessQuantityAllowed',
                onChange: handleChangeLessQuantitiesSecurityCheck,
                checked: lessQuantitiesSecurityCheck === 'lessQuantityAllowed',
                label: `Without Security Check`,
                subLabel: ``,
                disabled: false,
            }
        ],
        collapsedOption: []
    }

    const overbookingSecurityCheckOption = {
        id: OVERBOOKING_SECURITY_CHECK,
        heading: 'MORE/LESS QUANTITIES ALLOWED - OVERBOOKING ALLOWED',
        title: `Overbooking Security Check`,
        subtitle: ``,
        isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.allowQtyChange, goodsReceiptLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck) as string[])) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: 'overbookingAllowedWithCheck',
                onChange: handleChangeOverbookingSecurityCheck,
                checked: overbookingSecurityCheck === 'overbookingAllowedWithCheck',
                label: `With Security Check`,
                subLabel: ``,
                disabled: false,
            },
            {
                value: 'overbookingAllowed',
                onChange: handleChangeOverbookingSecurityCheck,
                checked: overbookingSecurityCheck === 'overbookingAllowed',
                label: `Without Security Check`,
                subLabel: ``,
                disabled: false,
            }
        ],
        collapsedOption: []
    }

    return (
        <>
            <ConfigurationOption configurationOption={moreOrLessQuantitiesAllowedOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            {moreOrLessQuantitiesAllowed.includes('allowLessQuantities') && (
                <ConfigurationOption configurationOption={lessQuantitiesSecurityCheckOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            )}
            {moreOrLessQuantitiesAllowed.includes('allowOverbooking') && (
                <ConfigurationOption configurationOption={overbookingSecurityCheckOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            )}
        </>
    );
};

export default SettingsForPositionDetails;