import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// React Lib
import React from "react";
import classnames from 'classnames'; // @otg-one/ui_components/components

import { Tooltip } from "../index"; // @otg-one/ui_components/icons

import { Asteriks, Search } from '../../icons'; // @otg-one/ui_components/constants

import { Color } from "../../constants"; // Material UI Core

import FilledInput from '@material-ui/core/FilledInput';
import Typography from '@material-ui/core/Typography'; // Material UI Styles

import { createStyles, makeStyles } from '@material-ui/core/styles';
var useStyles = makeStyles(function (theme) {
  return createStyles({
    root: {
      color: Color.neutral[900],
      fontFamily: "roboto",
      fontSize: "14px",
      // border: `2px solid transparent`,
      borderRadius: "0px"
    },
    grey: {
      backgroundColor: "".concat(Color.neutral[100], " !important"),
      "&:hover": {
        backgroundColor: Color.neutral[100]
      }
    },
    white: {
      backgroundColor: "".concat(Color.neutral[0], " !important"),
      "&:hover": {
        backgroundColor: Color.neutral[0]
      }
    },
    disabled: {
      backgroundColor: "".concat(Color.neutral[300], " !important"),
      color: Color.neutral[500],
      borderBottom: "none !important",
      pointerEvents: "none"
    },
    focused: {},
    error: {
      border: "2px solid ".concat(Color.danger[400]),
      color: Color.neutral[900]
    },
    errorText: {
      marginTop: 3,
      fontFamily: "roboto",
      fontSize: 12,
      letterSpacing: 0.15,
      color: Color.danger[400]
    },
    requiredIcon: {
      position: "absolute",
      right: 10,
      top: 10,
      cursor: "pointer",
      "& svg": {
        width: 12,
        height: 12,
        color: Color.primary[500]
      }
    },
    searchIcon: {
      position: "absolute",
      left: 11,
      fontSize: 24,
      color: Color.neutral[500]
    },
    input: {
      padding: "12px 16px 12px 16px",
      // height: "20px",
      transition: "all .3s ease",
      "&::placeholder": {
        opacity: 1,
        color: Color.neutral[400],
        fontFamily: "roboto",
        fontSize: "14px"
      },
      backgroundColor: "transparent"
    },
    inputWithIconStart: {
      padding: "12px 16px 12px 42px",
      // height: "20px",
      transition: "all .3s ease",
      "&::placeholder": {
        opacity: 1,
        color: Color.neutral[400],
        fontFamily: "roboto",
        fontSize: "14px"
      },
      backgroundColor: "transparent"
    },
    inputBorder: {
      "&:placeholder-shown": {
        borderBottom: "2px solid transparent !important"
      },
      borderBottom: "2px solid ".concat(Color.primary[600])
    },
    multiline: {
      padding: 0
    },
    adornedStart: {
      padding: 0
    },
    adornedEnd: {
      padding: 0
    }
  });
});

var ErrorMessage = function ErrorMessage(props) {
  var classes = useStyles(props);
  return React.createElement(Typography, {
    className: classes.errorText
  }, props.children);
};

export var Form = function Form(props) {
  var _classnames, _classnames2;

  var classes = useStyles(props);
  var id = props.id,
      tabIndex = props.tabIndex,
      icon = props.icon,
      password = props.password,
      number = props.number,
      max = props.max,
      min = props.min,
      search = props.search,
      required = props.required,
      color = props.color,
      placeholder = props.placeholder,
      value = props.value,
      disabled = props.disabled,
      onChange = props.onChange,
      onClick = props.onClick,
      onBlur = props.onBlur,
      onKeyPress = props.onKeyPress,
      multiline = props.multiline,
      rows = props.rows,
      error = props.error,
      errorMessage = props.errorMessage,
      style = props.style;
  var formClasses = {
    root: classnames((_classnames = {}, _defineProperty(_classnames, classes.root, true), _defineProperty(_classnames, classes["".concat(color)], color), _classnames)),
    disabled: classes.disabled,
    focused: classes.focused,
    error: classes.error,
    multiline: classes.multiline,
    input: classnames((_classnames2 = {}, _defineProperty(_classnames2, classes.input, true), _defineProperty(_classnames2, classes.inputBorder, !error), _defineProperty(_classnames2, classes.inputWithIconStart, icon !== null || search === true), _classnames2)),
    adornedStart: classes.adornedStart,
    adornedEnd: classes.adornedEnd
  };
  var type = 'text';

  if (password) {
    type = 'password';
  } else if (number) {
    type = 'number';
  }

  var inputProps = {};

  if (max != null) {
    inputProps.max = max;
  }

  if (min !== null) {
    inputProps.min = min;
  }

  return React.createElement(React.Fragment, null, React.createElement(FilledInput, {
    id: id,
    name: id,
    type: type,
    inputProps: inputProps,
    placeholder: placeholder,
    startAdornment: search ? React.createElement(Search, {
      className: classes.searchIcon
    }) : icon,
    endAdornment: required && React.createElement(Tooltip, {
      title: "Required",
      placement: "right"
    }, React.createElement("div", {
      className: classes.requiredIcon
    }, React.createElement(Asteriks, null))),
    required: required,
    value: value,
    onChange: onChange,
    onClick: onClick,
    onBlur: onBlur,
    onKeyPress: onKeyPress,
    disabled: disabled,
    classes: formClasses,
    multiline: multiline,
    rows: rows,
    fullWidth: true,
    error: error,
    style: style,
    tabIndex: tabIndex,
    disableUnderline: true
  }), React.createElement(ErrorMessage, null, errorMessage));
};
Form.defaultProps = {
  color: "grey",
  icon: null,
  placeholder: " ",
  password: false,
  number: false,
  search: false,
  error: false,
  errorMessage: "",
  required: false,
  disabled: false,
  multiline: false,
  tabIndex: -1
};
export default React.memo(Form);