import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
export var AvatarUser = function AvatarUser(props) {
  return React.createElement(SvgIcon, Object.assign({}, props), React.createElement("svg", {
    viewBox: "0 0 32 32",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, React.createElement("path", {
    d: "M16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 Z M16,3 C8.82029825,3 3,8.82029825 3,16 C3,20.1626702 4.95648462,23.8683789 8.00005559,26.2477279 L8,21 C8,18.34 13.33,17 16,17 C18.6106667,17 23.7644247,18.2811062 23.9921914,20.8246224 L24,21 L24.0009487,26.2469427 C27.0439458,23.8675637 29,20.1622123 29,16 C29,8.82029825 23.1797017,3 16,3 Z M16,7 C18.21,7 20,8.79 20,11 C20,13.21 18.21,15 16,15 C13.79,15 12,13.21 12,11 C12,8.79 13.79,7 16,7 Z"
  })));
};
export default AvatarUser;