// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Color } from '@otg-one/ui_components';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        billingContainer: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "5%",
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%",
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "5%",
            },
            marginTop: 85
        },
        billingPaymentContainer: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "2.5%",
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "2.5%",
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "5%",
            },
            marginTop: 35,
            backgroundColor: Color.neutral[100]
        },
        expansionPanel: {
            boxShadow: "none",
            padding: "0 14px",
            "&::before": {
                display: "none"
            },
            "&.Mui-expanded": {
                margin: 0
            }
        },
        expansionPanelSummary: {
            minHeight: 0,
            padding: "14px 0",
            "& .MuiExpansionPanelSummary-content": {
                margin: 0
            },
            "&.Mui-expanded": {
                minHeight: 0
            }
        },
        expansionPanelDetails: {
            padding: 0
        },
        saveButton: {
            marginTop: 50,
            [theme.breakpoints.down('sm')]: {
                marginTop: 0,
            }
        }
    })
)