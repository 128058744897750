// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Color } from '@otg-one/ui_components';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        createNewClientContainer: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
            },
            marginTop: 85
        },
        clientDashboardConfiguredSystemList: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "1%"
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%"
            },
            marginTop: 16,
            backgroundColor: Color.neutral[100]
        },
        clientDashboardConfiguredSystemListTopBar: {
            paddingTop: 24,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },

        clientDashboardClientInfo: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "1%"
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%"
            },
            marginTop: 50
        }
    })
)