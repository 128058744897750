// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./LeadingZerosPageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { PageWrapper } from "../../index"; // Icon

import Gs1128Barcode from "../../../icons/Barcode/Gs1128Barcode";
import ArrowDown from "../../../icons/Arrow/Down";
export var LeadingZerosPage = function LeadingZerosPage(props) {
  var classes = useStyles(props);
  var addZeros = props.addZeros,
      previewWidth = props.previewWidth,
      previewHeight = props.previewHeight;
  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true,
    alignItems: "center",
    justify: "center",
    style: {
      width: previewWidth,
      height: previewHeight
    }
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12,
    container: true,
    justify: "center",
    alignItems: "center"
  }, React.createElement(Gs1128Barcode, {
    className: classes.barcodeIcon
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    container: true,
    justify: "center",
    alignItems: "center"
  }, React.createElement(ArrowDown, {
    className: classes.arrowDownIcon
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    container: true,
    justify: "center",
    alignItems: "center"
  }, React.createElement("span", {
    className: classes.noPreviewContent
  }, addZeros ? "00000123456789" : "123456789"))))));
};
LeadingZerosPage.defaultProps = {
  addZeros: false
};
export default React.memo(LeadingZerosPage);