import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React Lib
import React, { useState, useEffect } from "react"; // Component Styles

import { useStyles } from "./OverflowMenuSelectionStyles"; // @otg-one/ui_components/components

import { Checkmark } from "../../icons"; // @otg-one/ui_components/constants

import { Color } from "../../constants/Colors"; // Material UI Core

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
export var OverflowMenuSelection = function OverflowMenuSelection(props) {
  var classes = useStyles(props);

  var _useState = useState(""),
      _useState2 = _slicedToArray(_useState, 2),
      activeItem = _useState2[0],
      setActiveItem = _useState2[1];

  var id = props.id,
      setValue = props.setValue,
      anchorElement = props.anchorElement,
      menuItems = props.menuItems,
      setAnchorEl = props.setAnchorEl,
      selectedItem = props.selectedItem;
  useEffect(function () {
    setActiveItem(selectedItem);
  }, []);

  var closeAllMenus = function closeAllMenus() {
    setAnchorEl(null);
  };

  var handleItemClick = function handleItemClick(menuItem) {
    return function () {
      setActiveItem(menuItem.text);
      menuItem.onClick();
    };
  };

  var renderMenuItem = function renderMenuItem(menuItem) {
    var isSelected = activeItem === menuItem.text;

    if (isSelected) {
      setValue(menuItem.text);
    }

    return React.createElement(MenuItem, {
      className: classes.menuItem,
      onClick: handleItemClick(menuItem)
    }, React.createElement("div", {
      className: classes.menuItemCheckIcon,
      style: {
        color: isSelected ? Color.primary[500] : "transparent"
      }
    }, React.createElement(Checkmark, null)), React.createElement("div", {
      className: classes.menuItemText
    }, menuItem.text));
  };

  return React.createElement(Menu, {
    id: id,
    anchorEl: anchorElement,
    classes: {
      paper: classes.menuRoot,
      list: classes.menuList
    },
    PopoverClasses: {
      root: classes.popoverRoot
    },
    keepMounted: true,
    open: Boolean(anchorElement),
    onClose: closeAllMenus
  }, menuItems.map(function (menuItem) {
    return renderMenuItem(menuItem);
  }));
};