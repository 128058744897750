// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./InventoryCountingListAutoselectStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { WhiteContentBar } from "../../index";
import PersonIcon from '@material-ui/icons/Person';
export var InventoryCountingListAutoselect = function InventoryCountingListAutoselect(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      data = props.data;
  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "small",
    showScanner: false,
    previewHeight: previewHeight
  }, React.createElement("p", null, "Inventory Counting"))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentTopRadius,
    style: {
      height: previewHeight - 75 - 50 + 10
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "Positions",
      rightContent: data.length.toString()
    }
  })), React.createElement(Grid, {
    container: true,
    className: classes.headerContentBar
  }, React.createElement("span", null, React.createElement("b", null, "Number")), React.createElement("span", null, React.createElement("b", null, "Date")), React.createElement("span", null, React.createElement("b", null, "Type"))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.listOrderItemsDiv,
    style: {
      height: previewHeight - 75 - 50 - 40 - 22
    }
  }, data.map(function (item) {
    return React.createElement(Grid, {
      item: true,
      md: 12,
      className: classes.contentBarBackLayer
    }, React.createElement(Grid, {
      item: true,
      md: 12,
      className: classes.contentBar
    }, React.createElement(Grid, {
      className: "".concat(classes.blackLeftContent, " ").concat(classes.contentStyle),
      item: true,
      xs: 4
    }, item.number), React.createElement(Grid, {
      className: "".concat(classes.blackMiddleContent, " ").concat(classes.contentStyle),
      item: true,
      xs: 4
    }, item.date), React.createElement(Grid, {
      className: "".concat(classes.blackRightContent, " ").concat(classes.contentStyle),
      item: true,
      xs: 4
    }, React.createElement(PersonIcon, null))));
  })))), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(GreyButton, {
    onClick: function onClick() {}
  }, "Back"))));
};
export default React.memo(InventoryCountingListAutoselect);