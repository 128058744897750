// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    carouselCompoundContainer: {
      paddingLeft: 24,
      backgroundColor: Color.neutral[100],
      borderRadius: 24,
      display: "inline-flex",
      alignItems: "center",
      height: 49
    },
    carouselCompoundContainerMobile: {
      paddingLeft: 24,
      display: "inline-flex",
      alignItems: "center",
      height: 49
    },
    carouselContainer: {
      border: "2px solid ".concat(Color.primary[100]),
      borderRadius: "50%",
      width: 12,
      height: 12,
      marginRight: 24,
      marginTop: 18,
      marginBottom: 18,
      transition: "background-color 0.2s ease",
      "&:hover": {
        backgroundColor: Color.primary[100],
        transition: "background-color 0.2s ease"
      },
      cursor: "pointer"
    },
    carouselActive: {
      border: "2px solid ".concat(Color.primary[400]),
      backgroundColor: Color.primary[400],
      "&:hover": {
        backgroundColor: Color.primary[400],
        transition: "none"
      }
    }
  });
});