import React from 'react';

// Components
import {
    DialogBox,
} from '@otg-one/ui_components';

const ImagePreviewDialog = (props: any) => {
    const { open, onClose, title, imageLink } = props

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title={title}
            style={{ height: '90%', marginTop: 70 }}
            content={
                <div style={{ width: "90%" }}>
                    <img src={imageLink} alt={title}/>
                </div>
            }
        />
    )
};

export default ImagePreviewDialog;