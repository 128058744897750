// React libraries
import React from "react"; // React Google Charts

import { Chart as GoogleChart } from "react-google-charts"; // Material UI Core

import Grid from '@material-ui/core/Grid';
export var Chart = function Chart(props) {
  var id = props.id,
      chartType = props.chartType,
      chartData = props.chartData,
      chartWidth = props.chartWidth,
      chartHeight = props.chartHeight,
      chartOptions = props.chartOptions,
      chartEvents = props.chartEvents,
      useLegendToggle = props.useLegendToggle,
      mapsApiKey = props.mapsApiKey,
      rootProps = props.rootProps;

  var LoadingChart = function LoadingChart() {
    return React.createElement(Grid, {
      container: true,
      alignItems: "center",
      justify: "center",
      style: {
        height: chartHeight,
        width: chartWidth
      }
    });
  };

  return React.createElement("div", {
    id: id
  }, React.createElement(GoogleChart, {
    loader: React.createElement(LoadingChart, null),
    chartType: chartType,
    data: chartData,
    width: chartWidth,
    height: chartHeight,
    options: chartOptions,
    chartEvents: chartEvents,
    legendToggle: useLegendToggle,
    mapsApiKey: mapsApiKey,
    rootProps: rootProps
  }));
};
Chart.defaultProps = {
  id: "",
  chartType: "ColumnChart",
  width: "100%",
  height: "100%",
  useLegendToggle: false,
  mapsApiKey: '',
  rootProps: null
};
export default React.memo(Chart);