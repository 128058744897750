import { useState, useEffect } from 'react';

import { VIA_SUPPLIERS, LIST_OF_SUPPLIERS, SELECTION_OF_ORDERS, VIA_ORDER_NUMBER, TYPE_OF_ORDER_INPUT, DISPLAYED_COLUMN, NEW_POSITION_ADDABLE, QUICK_CHECK, WAREHOUSE_NAME, COMPLETE_ORDER, ORDER_POSITION_COUNT, GS1_128_BARCODE_SCAN, GS1_128_BARCODE_SCAN_LEADING_ZEROS, ALLOW_EDIT_BUSINESS_PARTNER_REFERENCE_NUMBER, FIELDS_DISPLAYED, MORE_OR_LESS_QUANTITIES_ALLOWED, BEST_BEFORE_DATE, BEST_BEFORE_DATE_INPUT_FIELD, ENTRY_OPTION } from "../Pages/Configuration/GoodsReceipt/GoodsReceiptIds";
import { SETTINGS_FOR_DELIVERY, VIA_SUPPLIERS as VIA_SUPPLIERS_DELIVERY, VIA_ORDER_NUMBER as VIA_ORDER_NUMBER_DELIVERY, DISPLAYED_COLUMN as DISPLAYED_COLUMN_DELIVERY, NEW_POSITION_ADDABLE as NEW_POSITION_ADDABLE_DELIVERY, QUICK_CHECK as QUICK_CHECK_DELIVERY, WAREHOUSE_NAME as WAREHOUSE_NAME_DELIVERY, COMPLETE_ORDER as COMPLETE_ORDER_DELIVERY, ORDER_POSITION_COUNT as ORDER_POSITION_COUNT_DELIVERY, FIELDS_DISPLAYED as FIELDS_DISPLAYED_DELIVERY, MORE_OR_LESS_QUANTITIES_ALLOWED as MORE_OR_LESS_QUANTITIES_ALLOWED_DELIVERY, LIST_OF_SUPPLIERS as LIST_OF_SUPPLIERS_DELIVERY, SELECTION_OF_ORDERS as SELECTION_OF_ORDERS_DELIVERY, TYPE_OF_ORDER_INPUT as TYPE_OF_ORDER_INPUT_DELIVERY, ITEM_SELECTION as ITEM_SELECTION_DELIVERY, SETTINGS_FOR_ENTRY as SETTINGS_FOR_ENTRY_DELIVERY, ENTRY_OPTION as ENTRY_OPTION_DELIVERY, SETTINGS_FOR_LIST_OF_POSITION, SETTINGS_FOR_POSITION_DETAILS, LESS_QUANTITIES_SECURITY_CHECK, OVERBOOKING_SECURITY_CHECK } from '../Pages/Configuration/Delivery/DeliveryIds';
import { VIA_SCAN_OF_ITEM, VIA_SCAN_OF_WAREHOUSE, SETTINGS_FOR_INVENTORY_STATUS, WAREHOUSE_NAME as WAREHOUSE_NAME_INVENTORY_STATUS, ITEM_SELECTION, WAREHOUSE_SELECTION, SETTINGS_FOR_ENTRY, ENTRY_OPTION as ENTRY_OPTION_INVENTORY_STATUS } from '../Pages/Configuration/InventoryStatus/InventoryStatusIds';

import { GOODS_RECEIPT_CONFIGURATION, DELIVERY_CONFIGURATION, PICK_AND_PACK_CONFIGURATION, INVENTORY_STATUS_CONFIGURATION, INVENTORY_COUNTING_CONFIGURATION, INVENTORY_TRANSFER_CONFIGURATION, RECEIPT_FROM_PRODUCTION, ISSUE_FROM_PRODUCTION_CONFIGURATION } from '../Constants/FilePath';

import { InventoryTransfer } from '../Models/InventoryTransfer'
import { InventoryStatus } from '../Models/InventoryStatus'
import { InventoryCounting } from '../Models/InventoryCounting'
import { PickPack } from '../Models/PickPack'
import { GoodsReceipt } from '../Models/GoodsReceipt'
import { Delivery } from '../Models/Delivery'
import { ReceiptFromProduction } from '../Models/ReceiptFromProduction';
import { IssueFromProduction } from '../Models/IssueFromProduction';

export const usePersistedState = (key: string, defaultValue: any) => {
    const [state, setState] = useState(() => JSON.parse(localStorage.getItem(key) as string) as any || defaultValue);
    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
}

export const getActiveViewportName = (activeViewport: string) => {
    switch (activeViewport) {
        case ENTRY_OPTION:
            return "Entry Option"
        case VIA_SUPPLIERS:
            return "Via Suppliers"
        case LIST_OF_SUPPLIERS:
            return "List of Suppliers"
        case SELECTION_OF_ORDERS:
            return "Selection of Orders"
        case VIA_ORDER_NUMBER:
            return "Via Order Number"
        case TYPE_OF_ORDER_INPUT:
            return "Type of Order Input"
        case DISPLAYED_COLUMN:
            return "Displayed Column"
        case NEW_POSITION_ADDABLE:
            return "New Position Addable"
        case QUICK_CHECK:
            return "Quick Check"
        case WAREHOUSE_NAME:
            return "Warehouse Name"
        case COMPLETE_ORDER:
            return "Complete Order"
        case ORDER_POSITION_COUNT:
            return "Order Position Count"
        case GS1_128_BARCODE_SCAN:
            return "GS1-128 Barcode Scan"
        case GS1_128_BARCODE_SCAN_LEADING_ZEROS:
            return "Leading Zeros for Item Number of GS1-128 Barcodes"
        case ALLOW_EDIT_BUSINESS_PARTNER_REFERENCE_NUMBER:
            return "Allow Edit Business Partner Reference Number"
        case FIELDS_DISPLAYED:
            return "Fields Displayed"
        case MORE_OR_LESS_QUANTITIES_ALLOWED:
            return "More/Less Quantities Allowed"
        case BEST_BEFORE_DATE:
            return "Best Before Date"
        case BEST_BEFORE_DATE_INPUT_FIELD:
            return "Best Before Date Input Field"
    }
}

export const timeout = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export const getFunctionRoutePath = (functionType: number) => {
    switch (functionType) {
        case 1:
            return GOODS_RECEIPT_CONFIGURATION;
        case 2:
            return PICK_AND_PACK_CONFIGURATION;
        case 3:
            return INVENTORY_STATUS_CONFIGURATION;
        case 4:
            return INVENTORY_TRANSFER_CONFIGURATION;
        case 5:
            return INVENTORY_COUNTING_CONFIGURATION;
        case 6:
            return DELIVERY_CONFIGURATION;
        case 7:
            return RECEIPT_FROM_PRODUCTION;
        case 8:
            return ISSUE_FROM_PRODUCTION_CONFIGURATION;
        default:
            return "";
    };
}

const booleanConverter = (value: string, trueValue: { from: any, to: any }, falseValue: { from: any, to: any }) => {
    if (value === trueValue.from) {
        return trueValue.to
    } else if (value === falseValue.from) {
        return falseValue.to
    } else {
        return falseValue.to
    }
}

const arrayConverter = (value: any[], arrayData: { from: string, to: string }[]) => {
    let tempData: string[] = [];

    value.forEach((value: any) => {
        arrayData.forEach((data: any) => {
            if (value === data.from) {
                tempData.push(data.to);
            }
        })
    })
    return tempData;
}

export const arraysMatch = (array1: string[], array2: string[]) => {
    let result = false;

    if (array1.length !== array2.length) {
        result = false;
    } else {
        let tempResult = true;
        array1.forEach((data: any) => {
            if (!array2.includes(data)) {
                tempResult = false;
            }
        })
        if (tempResult) {
            result = true;
        } else {
            result = false;
        }
    }
    return result;
}

export const arrayAdjustmentDefault = (arrayValue: any[], arrayDefault: any[]) => {
    if (arrayValue.length === arrayDefault.length) {
        let foundAll = true;
        let result: any[] = [];
        arrayDefault.forEach((defaultData: any) => {
            if (arrayValue.includes(defaultData)) {
                result.push(defaultData);
            } else {
                foundAll = false;
            }
        })
        if (foundAll) {
            return result;
        } else {
            return arrayValue;
        }
    } else {
        return arrayValue;
    }
}

// Inventory Transfer
export const inventoryTransferLocalMapToDatabaseValue = (key: string, value: any) => {
    if (key === "selectWarehouseInput") {
        return arrayConverter(value, [
            { from: "textInput", to: InventoryTransfer.SELECT_ITEM_INPUT_MANUAL },
            { from: "barcodeScan", to: InventoryTransfer.SELECT_ITEM_INPUT_SCAN }
        ])
    } else if (key === "allowOneBatchForOneBinLoc") {
        return booleanConverter(value, { from: "enable", to: true }, { from: "disable", to: false })
    } else if (key === "serialType") {
        if (value === "SER") {
            return "SER"
        } else if (value === "MNF") {
            return "MNF"
        }
    }
}

export const inventoryTransferDatabaseMapToLocalValue = (key: string, value: any) => {
    if (key === "selectWarehouseInput") {
        return arrayConverter(value, [
            { from: InventoryTransfer.SELECT_ITEM_INPUT_MANUAL, to: "textInput" },
            { from: InventoryTransfer.SELECT_ITEM_INPUT_SCAN, to: "barcodeScan" }
        ])
    } else if (key === "allowOneBatchForOneBinLoc") {
        return booleanConverter(value, { from: true, to: "enable" }, { from: false, to: "disable" })
    } else if (key === "serialType") {
        if (value === "SER") {
            return "SER"
        } else if (value === "MNF") {
            return "MNF"
        }
    }
}

// Inventory Status
export const inventoryStatusLocalMapToDatabaseValue = (key: string, value: any) => {
    if (key === "entry") {
        return arrayConverter(value, [
            { from: VIA_SCAN_OF_ITEM, to: InventoryStatus.ENTRY_VIA_ITEM_SELECTION },
            { from: VIA_SCAN_OF_WAREHOUSE, to: InventoryStatus.ENTRY_VIA_WAREHOUSE_SELECTION }
        ])

    } else if (key === "warehouseLayout") {
        if (value === "warehouseName") {
            return InventoryStatus.WAREHOUSE_LAYOUT_WAREHOUSE_NAME;
        } else if (value === "warehouseCode") {
            return InventoryStatus.WAREHOUSE_LAYOUT_WAREHOUSE_CODE;
        }
    }
    else if (key === "selectItemInput") {
        return arrayConverter(value, [
            { from: "textInput", to: InventoryStatus.SELECT_ITEM_INPUT_MANUAL },
            { from: "barcodeScan", to: InventoryStatus.SELECT_ITEM_INPUT_SCAN },
            { from: "itemsSearch", to: InventoryStatus.SELECT_ITEM_INPUT_SHOPPING_ARTICLE }
        ])
    }
    else if (key === "selectWarehouseInput") {
        return arrayConverter(value, [
            { from: "textInput", to: InventoryStatus.SELECT_ITEM_INPUT_MANUAL },
            { from: "barcodeScan", to: InventoryStatus.SELECT_ITEM_INPUT_SCAN },
        ])
    }
}

export const inventoryStatusDatabaseMapToLocalValue = (key: string, value: any) => {
    if (key === "entry") {
        return arrayConverter(value, [
            { from: InventoryStatus.ENTRY_VIA_ITEM_SELECTION, to: VIA_SCAN_OF_ITEM },
            { from: InventoryStatus.ENTRY_VIA_WAREHOUSE_SELECTION, to: VIA_SCAN_OF_WAREHOUSE }
        ])

    } else if (key === "warehouseLayout") {
        if (value === InventoryStatus.WAREHOUSE_LAYOUT_WAREHOUSE_NAME) {
            return "warehouseName";
        } else if (value === InventoryStatus.WAREHOUSE_LAYOUT_WAREHOUSE_CODE) {
            return "warehouseCode";
        }
    }
    else if (key === "selectItemInput") {
        return arrayConverter(value, [
            { from: InventoryStatus.SELECT_ITEM_INPUT_MANUAL, to: "textInput" },
            { from: InventoryStatus.SELECT_ITEM_INPUT_SCAN, to: "barcodeScan" },
            { from: InventoryStatus.SELECT_ITEM_INPUT_SHOPPING_ARTICLE, to: "itemsSearch" }
        ])
    }
    else if (key === "selectWarehouseInput") {
        return arrayConverter(value, [
            { from: InventoryStatus.SELECT_ITEM_INPUT_MANUAL, to: "textInput" },
            { from: InventoryStatus.SELECT_ITEM_INPUT_SCAN, to: "barcodeScan" },
        ])
    }
}

// Inventory Counting
export const inventoryCountingLocalMapToDatabaseValue = (key: string, value: any) => {
    if (key === "selectWarehouseInput") {
        return arrayConverter(value, [
            { from: "textInput", to: InventoryCounting.SELECT_ITEM_INPUT_MANUAL },
            { from: "barcodeScan", to: InventoryCounting.SELECT_ITEM_INPUT_SCAN },
        ])
    } else if (key === "allowOneBatchForOneBinLoc") {
        return booleanConverter(value, { from: "enable", to: true }, { from: "disable", to: false })
    } else if (key === "bestBeforeDate") {
        if (value === "noBbd") {
            return InventoryCounting.BEST_BEFORE_DATE_OFF;
        } else if (value === "optionalBbd") {
            return InventoryCounting.BEST_BEFORE_DATE_OPTIONAL;
        } else if (value === "showBbd") {
            return InventoryCounting.BEST_BEFORE_DATE_ON;
        }
    } else if (key === "bestBeforeInputType") {
        if (value === "inputField") {
            return InventoryCounting.BEST_BEFORE_INPUT_TYPE_INPUT_FIELD
        } else if (value === "datePicker") {
            return InventoryCounting.BEST_BEFORE_INPUT_TYPE_DATE_PICKER
        }
    } else if (key === "showExpectedQuantity") {
        return booleanConverter(value, { from: "show", to: true }, { from: "hide", to: false })
    } else if (key === "warehouseLayout") {
        if (value === "warehouseName") {
            return InventoryCounting.WAREHOUSE_LAYOUT_WAREHOUSE_NAME
        } else if (value === "warehouseCode") {
            return InventoryCounting.WAREHOUSE_LAYOUT_WAREHOUSE_CODE
        }
    } else if (key === "allowAddInventory") {
        return booleanConverter(value, { from: "true", to: true }, { from: "false", to: false })
    } else if (key === "confirmNewItemNeeded") {
        return booleanConverter(value, { from: "true", to: true }, { from: "false", to: false })
    } else if (key === "selectInventoryListIfOnlyOneAvailable") {
        return booleanConverter(value, { from: "true", to: true }, { from: "false", to: false })
    } else if (key === "chooseBinLocByList") {
        return booleanConverter(value, { from: "true", to: true }, { from: "false", to: false })
    } else if (key === "showExpectedItems") {
        return booleanConverter(value, { from: "true", to: true }, { from: "false", to: false })
    }
}

export const inventoryCountingDatabaseMapToLocalValue = (key: string, value: any) => {
    if (key === "selectWarehouseInput") {
        return arrayConverter(value, [
            { from: InventoryCounting.SELECT_ITEM_INPUT_MANUAL, to: "textInput" },
            { from: InventoryCounting.SELECT_ITEM_INPUT_SCAN, to: "barcodeScan" },
        ])
    } else if (key === "allowOneBatchForOneBinLoc") {
        return booleanConverter(value, { from: true, to: "enable" }, { from: false, to: "disable" })
    } else if (key === "bestBeforeDate") {
        if (value === InventoryCounting.BEST_BEFORE_DATE_OFF) {
            return "noBbd";
        } else if (value === InventoryCounting.BEST_BEFORE_DATE_OPTIONAL) {
            return "optionalBbd";
        } else if (value === InventoryCounting.BEST_BEFORE_DATE_ON) {
            return "showBbd";
        }
    } else if (key === "bestBeforeInputType") {
        if (value === InventoryCounting.BEST_BEFORE_INPUT_TYPE_INPUT_FIELD) {
            return "inputField";
        } else if (value === InventoryCounting.BEST_BEFORE_INPUT_TYPE_DATE_PICKER) {
            return "datePicker";
        }
    } else if (key === "showExpectedQuantity") {
        return booleanConverter(value, { from: true, to: "show" }, { from: false, to: "hide" })
    } else if (key === "warehouseLayout") {
        if (value === InventoryCounting.WAREHOUSE_LAYOUT_WAREHOUSE_NAME) {
            return "warehouseName";
        } else if (value === InventoryCounting.WAREHOUSE_LAYOUT_WAREHOUSE_CODE) {
            return "warehouseCode";
        }
    } else if (key === "allowAddInventory") {
        return booleanConverter(value, { from: true, to: "true" }, { from: false, to: "false" })
    } else if (key === "confirmNewItemNeeded") {
        return booleanConverter(value, { from: true, to: "true" }, { from: false, to: "false" })
    } else if (key === "selectInventoryListIfOnlyOneAvailable") {
        return booleanConverter(value, { from: true, to: "true" }, { from: false, to: "false" })
    } else if (key === "chooseBinLocByList") {
        return booleanConverter(value, { from: true, to: "true" }, { from: false, to: "false" })
    } else if (key === "showExpectedItems") {
        return booleanConverter(value, { from: true, to: "true" }, { from: false, to: "false" })
    }
}

// Pick and Pack
export const pickAndPackLocalMapToDatabaseValue = (key: string, value: any) => {
    if (key === "warehouseLayout") {
        if (value === "warehouseName") {
            return PickPack.WAREHOUSE_LAYOUT_WAREHOUSE_NAME
        } else if (value === "warehouseCode") {
            return PickPack.WAREHOUSE_LAYOUT_WAREHOUSE_CODE
        }
    } else if (key === "allowLowerQuantity") {
        return booleanConverter(value, { from: "allow", to: true }, { from: "dontAllow", to: false })
    } else if (key === "quickCheck") {
        return booleanConverter(value, { from: "enable", to: true }, { from: "disable", to: false })
    } else if (key === "useContainer") {
        return booleanConverter(value, { from: "enable", to: true }, { from: "disable", to: false })
    } else if (key === "containerUdf") {
        return value;
    } else if (key === "palletHeight") {
        return value;
    } else if (key === "palletLength") {
        return value;
    } else if (key === "palletWidth") {
        return value;
    } else if (key === "containerMultiscan") {
        return booleanConverter(value, { from: "enable", to: true }, { from: "disable", to: false })
    } else if (key === "heavyContainers") {
        let tempData = "";
        if (value) {
            value.forEach((data: any, key: any) => {
                if (key === 0) {
                    tempData = tempData + data.value;
                } else {
                    tempData = tempData + ", " + data.value;
                }
            })
        }
        return tempData;
    } else if (key === "useWeightLights") {
        return booleanConverter(value, { from: "enable", to: true }, { from: "disable", to: false })
    } else if (key === "exceededWeightValue") {
        return value;
    } else if (key === "weightUnit") {
        if (value === "g") {
            return PickPack.WEIGHT_UNIT_G;
        } else if (value === "kg") {
            return PickPack.WEIGHT_UNIT_KG;
        }
    } else if (key === "allowOneBatchForOneBinLoc") {
        return booleanConverter(value, { from: "enable", to: true }, { from: "disable", to: false })
    } else if (key === "filterItems") {
        let tempData = "";
        if (value) {
            value.forEach((data: any, key: any) => {
                if (key === 0) {
                    tempData = tempData + data.value;
                } else {
                    tempData = tempData + ", " + data.value;
                }
            })
        }
        return tempData;
    } else if (key === "completeOrderMode") {
        if (value === "atLeastOnePosition") {
            return PickPack.COMPLETE_ORDER_MODE_AT_LEAST_ONCE;
        } else if (value === "allPositions") {
            return PickPack.COMPLETE_ORDER_MODE_ALL;
        }
    } else if (key === "serialType") {
        if (value === "SER") {
            return "SER"
        } else if (value === "MNF") {
            return "MNF"
        }
    } else if (key === "allowDeliveryPolicing") {
        return booleanConverter(value, { from: "enable", to: true }, { from: "disable", to: false })
    } else if (key === "definePalletDimensions") {
        return booleanConverter(value, { from: "true", to: true }, { from: "false", to: false })
    } else if (key === "confirmByOneClickItems") {
        return booleanConverter(value, { from: "true", to: true }, { from: "false", to: false })
    } else if (key === "bigAndSmallBoxWeightValue") {
        return value;
    } else if (key === "bigAndBigBoxWeightValue") {
        return value;
    } else if (key === "automaticDelivery") {
        return booleanConverter(value, { from: "true", to: true }, { from: "false", to: false })
    } else if (key === "filterPickListsByUser") {
        return booleanConverter(value, { from: "true", to: true }, { from: "false", to: false })
    }
}

export const pickAndPackDatabaseMapToLocalValue = (key: string, value: any) => {
    if (key === "warehouseLayout") {
        if (value === PickPack.WAREHOUSE_LAYOUT_WAREHOUSE_NAME) {
            return "warehouseName";
        } else if (value === PickPack.WAREHOUSE_LAYOUT_WAREHOUSE_CODE) {
            return "warehouseCode";
        }
    } else if (key === "allowLowerQuantity") {
        return booleanConverter(value, { from: true, to: "allow" }, { from: false, to: "dontAllow" })
    } else if (key === "quickCheck") {
        return booleanConverter(value, { from: true, to: "enable" }, { from: false, to: "disable" })
    } else if (key === "useContainer") {
        return booleanConverter(value, { from: true, to: "enable" }, { from: false, to: "disable" })
    } else if (key === "containerUdf") {
        return value;
    } else if (key === "palletHeight") {
        return value;
    } else if (key === "palletLength") {
        return value;
    } else if (key === "palletWidth") {
        return value;
    } else if (key === "containerMultiscan") {
        return booleanConverter(value, { from: true, to: "enable" }, { from: false, to: "disable" })
    } else if (key === "heavyContainers") {
        let tempData: string[] = []

        if (value !== "") {
            const array = value.split(', ');
            array.forEach((data: any) => {
                tempData.push({ value: data, label: data } as any)
            })
        }
        return tempData;
    } else if (key === "useWeightLights") {
        return booleanConverter(value, { from: true, to: "enable" }, { from: false, to: "disable" })
    } else if (key === "exceededWeightValue") {
        return value;
    } else if (key === "weightUnit") {
        if (value === PickPack.WEIGHT_UNIT_G) {
            return "g";
        } else if (value === PickPack.WEIGHT_UNIT_KG) {
            return "kg";
        }
    } else if (key === "allowOneBatchForOneBinLoc") {
        return booleanConverter(value, { from: true, to: "enable" }, { from: false, to: "disable" })
    } else if (key === "filterItems") {
        let tempData: string[] = []

        if (value !== "") {
            const array = value.split(', ');
            array.forEach((data: any) => {
                tempData.push({ value: data, label: data } as any)
            })
        }
        return tempData;
    } else if (key === "completeOrderMode") {
        if (value === PickPack.COMPLETE_ORDER_MODE_AT_LEAST_ONCE) {
            return "atLeastOnePosition";
        } else if (value === PickPack.COMPLETE_ORDER_MODE_ALL) {
            return "allPositions";
        }
    } else if (key === "serialType") {
        if (value === "SER") {
            return "SER"
        } else if (value === "MNF") {
            return "MNF"
        }
    } else if (key === "allowDeliveryPolicing") {
        return booleanConverter(value, { from: true, to: "enable" }, { from: false, to: "disable" })
    } else if (key === "definePalletDimensions") {
        return booleanConverter(value, { from: true, to: "true" }, { from: false, to: "false" })
    } else if (key === "confirmByOneClickItems") {
        return booleanConverter(value, { from: true, to: "true" }, { from: false, to: "false" })
    } else if (key === "bigAndSmallBoxWeightValue") {
        return value;
    } else if (key === "bigAndBigBoxWeightValue") {
        return value;
    } else if (key === "automaticDelivery") {
        return booleanConverter(value, { from: true, to: "true" }, { from: false, to: "false" })
    } else if (key === "filterPickListsByUser") {
        return booleanConverter(value, { from: true, to: "true" }, { from: false, to: "false" })
    }
}

// Goods Receipt
export const goodsReceiptLocalMapToDatabaseValue = (key: string, value: any, lessQuantitiesSecurityCheck?: any, overbookingSecurityCheck?: any) => {
    if (key === "entry") {
        return arrayConverter(value, [
            { from: VIA_SUPPLIERS, to: GoodsReceipt.ENTRY_VIA_SUPPLIER },
            { from: VIA_ORDER_NUMBER, to: GoodsReceipt.ENTRY_VIA_DOCUMENT }
        ])
    } else if (key === "listOfSupplier") {
        if (value === "allSuppliers") {
            return GoodsReceipt.LIST_OF_SUPPLIER_ALL_SUPPLIERS;
        } else if (value === "onlySuppliersWithOpenOrders") {
            return GoodsReceipt.LIST_OF_SUPPLIER_OPEN_ORDER_SUPPLIERS;
        }
    } else if (key === "orderSelection") {
        return arrayConverter(value, [
            { from: "all", to: GoodsReceipt.ORDER_SELECTION_ALL },
            { from: "multiple_order", to: GoodsReceipt.ORDER_SELECTION_MULTIPLE_ORDER },
            { from: "unrelated", to: GoodsReceipt.ORDER_SELECTION_UNRELATED }
        ])
    } else if (key === "docInputType") {
        return arrayConverter(value, [
            { from: "textInput", to: GoodsReceipt.DOC_INPUT_TYPE_MANUAL },
            { from: "barcodeScan", to: GoodsReceipt.DOC_INPUT_TYPE_SCAN },
        ])
    } else if (key === "displayedColumns") {
        if (value === "templateA") {
            return GoodsReceipt.DISPLAYED_COLUMNS_TEMPLATE_A;
        } else if (value === "templateB") {
            return GoodsReceipt.DISPLAYED_COLUMNS_TEMPLATE_B;
        }
    } else if (key === "addNewPosition") {
        return booleanConverter(value, { from: "enable", to: true }, { from: "disable", to: false })
    } else if (key === "selectItemInput") {
        return arrayConverter(value, [
            { from: "textInput", to: GoodsReceipt.SELECT_ITEM_INPUT_MANUAL },
            { from: "barcodeScan", to: GoodsReceipt.SELECT_ITEM_INPUT_SCAN },
            { from: "itemsSearch", to: GoodsReceipt.SELECT_ITEM_INPUT_SHOPPING_ARTICLE }
        ])
    } else if (key === "quickCheck") {
        return booleanConverter(value, { from: "enable", to: true }, { from: "disable", to: false })
    } else if (key === "warehouseLayout") {
        if (value === "warehouseName") {
            return GoodsReceipt.WAREHOUSE_LAYOUT_WAREHOUSE_NAME;
        } else if (value === "warehouseCode") {
            return GoodsReceipt.WAREHOUSE_LAYOUT_WAREHOUSE_CODE;
        }
    } else if (key === "completeOrderMode") {
        if (value === "atLeastOnePosition") {
            return GoodsReceipt.COMPLETE_ORDER_MODE_AT_LEAST_ONCE;
        } else if (value === "allPositions") {
            return GoodsReceipt.COMPLETE_ORDER_MODE_ALL;
        }
    } else if (key === "showOrderPositionCount") {
        return booleanConverter(value, { from: "show", to: true }, { from: "hide", to: false })
    } else if (key === "useGS1") {
        return booleanConverter(value, { from: "allow", to: true }, { from: "dontAllow", to: false })
    } else if (key === "gs1FillEanLeadingZeros") {
        return booleanConverter(value, { from: "addZeroes", to: true }, { from: "dontAdd", to: false })
    } else if (key === "allowBpReference") {
        return booleanConverter(value, { from: "allow", to: true }, { from: "dontAllow", to: false })
    } else if (key === "allowOneBatchForOneBinLoc") {
        return booleanConverter(value, { from: "enable", to: true }, { from: "disable", to: false })
    } else if (key === "draftDocumentsForNewLine") {
        return booleanConverter(value, { from: "enable", to: true }, { from: "disable", to: false })
    } else if (key === "displayedFields") {
        if (value === "templateA") {
            return GoodsReceipt.DISPLAYED_FIELDS_TEMPLATE_A
        } else if (value === "templateB") {
            return GoodsReceipt.DISPLAYED_FIELDS_TEMPLATE_B
        }
    } else if (key === "allowQtyChange") {
        let tempData: string[] = [];
        value.map((data: any) => {
            if (data === "allowLessQuantities") {
                if (lessQuantitiesSecurityCheck === "lessQuantityAllowedWithCheck") {
                    tempData.push(GoodsReceipt.ALLOW_QTY_CHANGE_LOWER_WITH_SECURITY);
                } else {
                    tempData.push(GoodsReceipt.ALLOW_QTY_CHANGE_LOWER_WITHOUT_SECURITY);
                }
            } else if (data === "allowOverbooking") {
                if (overbookingSecurityCheck === "overbookingAllowedWithCheck") {
                    tempData.push(GoodsReceipt.ALLOW_QTY_CHANGE_HIGHER_WITH_SECURITY);
                } else {
                    tempData.push(GoodsReceipt.ALLOW_QTY_CHANGE_HIGHER_WITHOUT_SECURITY);
                }
            }
        })
        return tempData;
    } else if (key === "bestBeforeDate") {
        if (value === "noBbd") {
            return GoodsReceipt.BEST_BEFORE_DATE_OFF;
        } else if (value === "optionalBbd") {
            return GoodsReceipt.BEST_BEFORE_DATE_OPTIONAL;
        } else if (value === "showBbd") {
            return GoodsReceipt.BEST_BEFORE_DATE_ON;
        }
    } else if (key === "bestBeforeInputType") {
        if (value === "inputField") {
            return GoodsReceipt.BEST_BEFORE_INPUT_TYPE_INPUT_FIELD
        } else if (value === "datePicker") {
            return GoodsReceipt.BEST_BEFORE_INPUT_TYPE_DATE_PICKER
        }
    } else if (key === "serialType") {
        if (value === "SER") {
            return "SER"
        } else if (value === "MNF") {
            return "MNF"
        }
    }
}

export const goodsReceiptDatabaseMapToLocalValue = (key: string, value: any) => {
    if (key === "entry") {
        return arrayConverter(value, [
            { from: GoodsReceipt.ENTRY_VIA_SUPPLIER, to: VIA_SUPPLIERS },
            { from: GoodsReceipt.ENTRY_VIA_DOCUMENT, to: VIA_ORDER_NUMBER }
        ])
    } else if (key === "listOfSupplier") {
        if (value === GoodsReceipt.LIST_OF_SUPPLIER_ALL_SUPPLIERS) {
            return "allSuppliers";
        } else if (value === GoodsReceipt.LIST_OF_SUPPLIER_OPEN_ORDER_SUPPLIERS) {
            return "onlySuppliersWithOpenOrders";
        }
    } else if (key === "orderSelection") {
        return arrayConverter(value, [
            { from: GoodsReceipt.ORDER_SELECTION_ALL, to: "all" },
            { from: GoodsReceipt.ORDER_SELECTION_MULTIPLE_ORDER, to: "multiple_order" },
            { from: GoodsReceipt.ORDER_SELECTION_UNRELATED, to: "unrelated" }
        ])
    } else if (key === "docInputType") {
        return arrayConverter(value, [
            { from: GoodsReceipt.DOC_INPUT_TYPE_MANUAL, to: "textInput" },
            { from: GoodsReceipt.DOC_INPUT_TYPE_SCAN, to: "barcodeScan" },
        ])
    } else if (key === "displayedColumns") {
        if (value === GoodsReceipt.DISPLAYED_COLUMNS_TEMPLATE_A) {
            return "templateA";
        } else if (value === GoodsReceipt.DISPLAYED_COLUMNS_TEMPLATE_B) {
            return "templateB";
        }
    } else if (key === "addNewPosition") {
        return booleanConverter(value, { from: true, to: "enable" }, { from: false, to: "disable" })
    } else if (key === "selectItemInput") {
        return arrayConverter(value, [
            { from: GoodsReceipt.SELECT_ITEM_INPUT_MANUAL, to: "textInput" },
            { from: GoodsReceipt.SELECT_ITEM_INPUT_SCAN, to: "barcodeScan" },
            { from: GoodsReceipt.SELECT_ITEM_INPUT_SHOPPING_ARTICLE, to: "itemsSearch" }
        ])
    } else if (key === "quickCheck") {
        return booleanConverter(value, { from: true, to: "enable" }, { from: false, to: "disable" })
    } else if (key === "warehouseLayout") {
        if (value === GoodsReceipt.WAREHOUSE_LAYOUT_WAREHOUSE_NAME) {
            return "warehouseName";
        } else if (value === GoodsReceipt.WAREHOUSE_LAYOUT_WAREHOUSE_CODE) {
            return "warehouseCode";
        }
    } else if (key === "completeOrderMode") {
        if (value === GoodsReceipt.COMPLETE_ORDER_MODE_AT_LEAST_ONCE) {
            return "atLeastOnePosition";
        } else if (value === GoodsReceipt.COMPLETE_ORDER_MODE_ALL) {
            return "allPositions";
        }
    } else if (key === "showOrderPositionCount") {
        return booleanConverter(value, { from: true, to: "show" }, { from: false, to: "hide" })
    } else if (key === "useGS1") {
        return booleanConverter(value, { from: true, to: "allow" }, { from: false, to: "dontAllow" })
    } else if (key === "gs1FillEanLeadingZeros") {
        return booleanConverter(value, { from: true, to: "addZeroes" }, { from: false, to: "dontAdd" })
    } else if (key === "allowBpReference") {
        return booleanConverter(value, { from: true, to: "allow" }, { from: false, to: "dontAllow" })
    } else if (key === "allowOneBatchForOneBinLoc") {
        return booleanConverter(value, { from: true, to: "enable" }, { from: false, to: "disable" })
    } else if (key === "draftDocumentsForNewLine") {
        return booleanConverter(value, { from: true, to: "enable" }, { from: false, to: "disable" })
    } else if (key === "displayedFields") {
        if (value === GoodsReceipt.DISPLAYED_FIELDS_TEMPLATE_A) {
            return "templateA";
        } else if (value === GoodsReceipt.DISPLAYED_FIELDS_TEMPLATE_B) {
            return "templateB";
        }
    } else if (key === "allowQtyChange") {
        let tempResult = {
            moreOrLessQuantitiesAllowed: [] as string[],
            lessQuantitiesSecurityCheck: "" as string,
            overbookingSecurityCheck: "" as string
        }
        let tempData: string[] = [];
        value.map((data: any) => {
            if (data === GoodsReceipt.ALLOW_QTY_CHANGE_LOWER_WITH_SECURITY || data === GoodsReceipt.ALLOW_QTY_CHANGE_LOWER_WITHOUT_SECURITY) {
                tempData.push("allowLessQuantities");

                if (data === GoodsReceipt.ALLOW_QTY_CHANGE_LOWER_WITH_SECURITY) {
                    tempResult.lessQuantitiesSecurityCheck = "lessQuantityAllowedWithCheck";
                } else {
                    tempResult.lessQuantitiesSecurityCheck = "lessQuantityAllowed";
                }
            } else if (data === GoodsReceipt.ALLOW_QTY_CHANGE_HIGHER_WITH_SECURITY || data === GoodsReceipt.ALLOW_QTY_CHANGE_HIGHER_WITHOUT_SECURITY) {
                tempData.push("allowOverbooking");

                if (data === GoodsReceipt.ALLOW_QTY_CHANGE_HIGHER_WITH_SECURITY) {
                    tempResult.overbookingSecurityCheck = "overbookingAllowedWithCheck";
                } else {
                    tempResult.overbookingSecurityCheck = "overbookingAllowed";
                }
            }
        })
        tempResult.moreOrLessQuantitiesAllowed = tempData;

        return tempResult;
    } else if (key === "bestBeforeDate") {
        if (value === GoodsReceipt.BEST_BEFORE_DATE_OFF) {
            return "noBbd";
        } else if (value === GoodsReceipt.BEST_BEFORE_DATE_OPTIONAL) {
            return "optionalBbd";
        } else if (value === GoodsReceipt.BEST_BEFORE_DATE_ON) {
            return "showBbd";
        }
    } else if (key === "bestBeforeInputType") {
        if (value === GoodsReceipt.BEST_BEFORE_INPUT_TYPE_INPUT_FIELD) {
            return "inputField";
        } else if (value === GoodsReceipt.BEST_BEFORE_INPUT_TYPE_DATE_PICKER) {
            return "datePicker";
        }
    }
    else if (key === "serialType") {
        if (value === "SER") {
            return "SER"
        } else if (value === "MNF") {
            return "MNF"
        }
    }
}

// Delivery
export const deliveryLocalMapToDatabaseValue = (key: string, value: any, lessQuantitiesSecurityCheck?: any, overbookingSecurityCheck?: any) => {
    if (key === "entry") {
        return arrayConverter(value, [
            { from: VIA_SUPPLIERS, to: Delivery.ENTRY_VIA_SUPPLIER },
            { from: VIA_ORDER_NUMBER, to: Delivery.ENTRY_VIA_DOCUMENT }
        ])
    } else if (key === "listOfSupplier") {
        if (value === "allSuppliers") {
            return Delivery.LIST_OF_SUPPLIER_ALL_SUPPLIERS;
        } else if (value === "onlySuppliersWithOpenOrders") {
            return Delivery.LIST_OF_SUPPLIER_OPEN_ORDER_SUPPLIERS;
        }
    } else if (key === "orderSelection") {
        return arrayConverter(value, [
            { from: "all", to: Delivery.ORDER_SELECTION_ALL },
            { from: "multiple_order", to: Delivery.ORDER_SELECTION_MULTIPLE_ORDER },
            { from: "unrelated", to: Delivery.ORDER_SELECTION_UNRELATED }
        ])
    } else if (key === "docInputType") {
        return arrayConverter(value, [
            { from: "textInput", to: Delivery.DOC_INPUT_TYPE_MANUAL },
            { from: "barcodeScan", to: Delivery.DOC_INPUT_TYPE_SCAN },
        ])
    } else if (key === "displayedColumns") {
        if (value === "templateA") {
            return Delivery.DISPLAYED_COLUMNS_TEMPLATE_A;
        } else if (value === "templateB") {
            return Delivery.DISPLAYED_COLUMNS_TEMPLATE_B;
        }
    } else if (key === "addNewPosition") {
        return booleanConverter(value, { from: "enable", to: true }, { from: "disable", to: false })
    } else if (key === "selectItemInput") {
        return arrayConverter(value, [
            { from: "textInput", to: Delivery.SELECT_ITEM_INPUT_MANUAL },
            { from: "barcodeScan", to: Delivery.SELECT_ITEM_INPUT_SCAN },
            { from: "itemsSearch", to: Delivery.SELECT_ITEM_INPUT_SHOPPING_ARTICLE }
        ])
    } else if (key === "quickCheck") {
        return booleanConverter(value, { from: "enable", to: true }, { from: "disable", to: false })
    } else if (key === "warehouseLayout") {
        if (value === "warehouseName") {
            return Delivery.WAREHOUSE_LAYOUT_WAREHOUSE_NAME;
        } else if (value === "warehouseCode") {
            return Delivery.WAREHOUSE_LAYOUT_WAREHOUSE_CODE;
        }
    } else if (key === "completeOrderMode") {
        if (value === "atLeastOnePosition") {
            return Delivery.COMPLETE_ORDER_MODE_AT_LEAST_ONCE;
        } else if (value === "allPositions") {
            return Delivery.COMPLETE_ORDER_MODE_ALL;
        }
    } else if (key === "showOrderPositionCount") {
        return booleanConverter(value, { from: "show", to: true }, { from: "hide", to: false })
    } else if (key === "displayedFields") {
        if (value === "templateA") {
            return Delivery.DISPLAYED_FIELDS_TEMPLATE_A
        } else if (value === "templateB") {
            return Delivery.DISPLAYED_FIELDS_TEMPLATE_B
        }
    } else if (key === "allowQtyChange") {
        let tempData: string[] = [];
        value.map((data: any) => {
            if (data === "allowLessQuantities") {
                if (lessQuantitiesSecurityCheck === "lessQuantityAllowedWithCheck") {
                    tempData.push(Delivery.ALLOW_QTY_CHANGE_LOWER_WITH_SECURITY);
                } else {
                    tempData.push(Delivery.ALLOW_QTY_CHANGE_LOWER_WITHOUT_SECURITY);
                }
            } else if (data === "allowOverbooking") {
                if (overbookingSecurityCheck === "overbookingAllowedWithCheck") {
                    tempData.push(Delivery.ALLOW_QTY_CHANGE_HIGHER_WITH_SECURITY);
                } else {
                    tempData.push(Delivery.ALLOW_QTY_CHANGE_HIGHER_WITHOUT_SECURITY);
                }
            }
        })
        return tempData;
    } else if (key === "serialType") {
        if (value === "SER") {
            return "SER"
        } else if (value === "MNF") {
            return "MNF"
        }
    }
}

export const deliveryDatabaseMapToLocalValue = (key: string, value: any) => {
    if (key === "entry") {
        return arrayConverter(value, [
            { from: Delivery.ENTRY_VIA_SUPPLIER, to: VIA_SUPPLIERS },
            { from: Delivery.ENTRY_VIA_DOCUMENT, to: VIA_ORDER_NUMBER }
        ])
    } else if (key === "listOfSupplier") {
        if (value === Delivery.LIST_OF_SUPPLIER_ALL_SUPPLIERS) {
            return "allSuppliers";
        } else if (value === Delivery.LIST_OF_SUPPLIER_OPEN_ORDER_SUPPLIERS) {
            return "onlySuppliersWithOpenOrders";
        }
    } else if (key === "orderSelection") {
        return arrayConverter(value, [
            { from: Delivery.ORDER_SELECTION_ALL, to: "all" },
            { from: Delivery.ORDER_SELECTION_MULTIPLE_ORDER, to: "multiple_order" },
            { from: Delivery.ORDER_SELECTION_UNRELATED, to: "unrelated" }
        ])
    } else if (key === "docInputType") {
        return arrayConverter(value, [
            { from: Delivery.DOC_INPUT_TYPE_MANUAL, to: "textInput" },
            { from: Delivery.DOC_INPUT_TYPE_SCAN, to: "barcodeScan" },
        ])
    } else if (key === "displayedColumns") {
        if (value === Delivery.DISPLAYED_COLUMNS_TEMPLATE_A) {
            return "templateA";
        } else if (value === Delivery.DISPLAYED_COLUMNS_TEMPLATE_B) {
            return "templateB";
        }
    } else if (key === "addNewPosition") {
        return booleanConverter(value, { from: true, to: "enable" }, { from: false, to: "disable" })
    } else if (key === "selectItemInput") {
        return arrayConverter(value, [
            { from: Delivery.SELECT_ITEM_INPUT_MANUAL, to: "textInput" },
            { from: Delivery.SELECT_ITEM_INPUT_SCAN, to: "barcodeScan" },
            { from: Delivery.SELECT_ITEM_INPUT_SHOPPING_ARTICLE, to: "itemsSearch" }
        ])
    } else if (key === "quickCheck") {
        return booleanConverter(value, { from: true, to: "enable" }, { from: false, to: "disable" })
    } else if (key === "warehouseLayout") {
        if (value === Delivery.WAREHOUSE_LAYOUT_WAREHOUSE_NAME) {
            return "warehouseName";
        } else if (value === Delivery.WAREHOUSE_LAYOUT_WAREHOUSE_CODE) {
            return "warehouseCode";
        }
    } else if (key === "completeOrderMode") {
        if (value === Delivery.COMPLETE_ORDER_MODE_AT_LEAST_ONCE) {
            return "atLeastOnePosition";
        } else if (value === Delivery.COMPLETE_ORDER_MODE_ALL) {
            return "allPositions";
        }
    } else if (key === "displayedFields") {
        if (value === Delivery.DISPLAYED_FIELDS_TEMPLATE_A) {
            return "templateA";
        } else if (value === Delivery.DISPLAYED_FIELDS_TEMPLATE_B) {
            return "templateB";
        }
    } else if (key === "showOrderPositionCount") {
        return booleanConverter(value, { from: true, to: "show" }, { from: false, to: "hide" })
    } else if (key === "allowQtyChange") {
        let tempResult = {
            moreOrLessQuantitiesAllowed: [] as string[],
            lessQuantitiesSecurityCheck: "" as string,
            overbookingSecurityCheck: "" as string
        }
        let tempData: string[] = [];
        value.map((data: any) => {
            if (data === Delivery.ALLOW_QTY_CHANGE_LOWER_WITH_SECURITY || data === Delivery.ALLOW_QTY_CHANGE_LOWER_WITHOUT_SECURITY) {
                tempData.push("allowLessQuantities");

                if (data === Delivery.ALLOW_QTY_CHANGE_LOWER_WITH_SECURITY) {
                    tempResult.lessQuantitiesSecurityCheck = "lessQuantityAllowedWithCheck";
                } else {
                    tempResult.lessQuantitiesSecurityCheck = "lessQuantityAllowed";
                }
            } else if (data === Delivery.ALLOW_QTY_CHANGE_HIGHER_WITH_SECURITY || data === Delivery.ALLOW_QTY_CHANGE_HIGHER_WITHOUT_SECURITY) {
                tempData.push("allowOverbooking");

                if (data === Delivery.ALLOW_QTY_CHANGE_HIGHER_WITH_SECURITY) {
                    tempResult.overbookingSecurityCheck = "overbookingAllowedWithCheck";
                } else {
                    tempResult.overbookingSecurityCheck = "overbookingAllowed";
                }
            }
        })
        tempResult.moreOrLessQuantitiesAllowed = tempData;

        return tempResult;
    } else if (key === "serialType") {
        if (value === "SER") {
            return "SER"
        } else if (value === "MNF") {
            return "MNF"
        }
    }
}

//Cost and Device Login Chart
export const getUpperBoundValue = (value: number, roundConstant: number = 5) => {
    const powerOfTens = Math.floor(Math.log10(value));
    const roundingFactor = roundConstant * Math.pow(10, value >= 100 ? powerOfTens - 1 : powerOfTens);
    const costUpperBound = Math.ceil(value / roundingFactor) * roundingFactor;

    return costUpperBound;
}

export const getTicks = (upperBoundValue: number, gridCount: number = 5) => {
    const interval = upperBoundValue / gridCount;
    let value = 0;
    let costTicks = [];

    while (value <= upperBoundValue) {
        costTicks.push(value);
        value += interval;
    }

    return costTicks;
}

export const getLastNYear = (n: number = 5) => {
    const date = new Date();
    let year = date.getFullYear();

    const years: any[] = ['Last 12 Months'];
    while (--n > 0) {
        years.push(--year);
    }

    return years;
}

export const generateDropDownItems = (source: any[], onClick: any) => {
    const dropDownItems = [];

    for (const element of source) {
        const click = () => onClick(element);
        dropDownItems.push({ text: element, onClick: click });
    }

    return dropDownItems;
}



// ReceiptFromProduction
export const receiptFromProductionLocalMapToDatabaseValue = (key: string, value: any, lessQuantitiesSecurityCheck?: any, overbookingSecurityCheck?: any) => {
    if (key === "docInputType") {
        return arrayConverter(value, [
            { from: "textInput", to: ReceiptFromProduction.DOC_INPUT_TYPE_MANUAL },
            { from: "barcodeScan", to: ReceiptFromProduction.DOC_INPUT_TYPE_SCAN },
        ])
    } else if (key === "displayedColumns") {
        if (value === "templateA") {
            return ReceiptFromProduction.DISPLAYED_COLUMNS_TEMPLATE_A;
        } else if (value === "templateB") {
            return ReceiptFromProduction.DISPLAYED_COLUMNS_TEMPLATE_B;
        }
    } else if (key === "quickCheck") {
        return booleanConverter(value, { from: "enable", to: true }, { from: "disable", to: false })
    } else if (key === "warehouseLayout") {
        if (value === "warehouseName") {
            return ReceiptFromProduction.WAREHOUSE_LAYOUT_WAREHOUSE_NAME;
        } else if (value === "warehouseCode") {
            return ReceiptFromProduction.WAREHOUSE_LAYOUT_WAREHOUSE_CODE;
        }
    } else if (key === "completeOrderMode") {
        if (value === "atLeastOnePosition") {
            return ReceiptFromProduction.COMPLETE_ORDER_MODE_AT_LEAST_ONCE;
        } else if (value === "allPositions") {
            return ReceiptFromProduction.COMPLETE_ORDER_MODE_ALL;
        }
    } else if (key === "allowQtyChange") {
        let tempData: string[] = [];
        value.map((data: any) => {
            if (data === "allowLessQuantities") {
                if (lessQuantitiesSecurityCheck === "lessQuantityAllowedWithCheck") {
                    tempData.push(ReceiptFromProduction.ALLOW_QTY_CHANGE_LOWER_WITH_SECURITY);
                } else {
                    tempData.push(ReceiptFromProduction.ALLOW_QTY_CHANGE_LOWER_WITHOUT_SECURITY);
                }
            } else if (data === "allowOverbooking") {
                if (overbookingSecurityCheck === "overbookingAllowedWithCheck") {
                    tempData.push(ReceiptFromProduction.ALLOW_QTY_CHANGE_HIGHER_WITH_SECURITY);
                } else {
                    tempData.push(ReceiptFromProduction.ALLOW_QTY_CHANGE_HIGHER_WITHOUT_SECURITY);
                }
            }
        })
        return tempData;
    }
}


//Issue From Production
export const issueFromProductionLocalMapToDatabaseValue = (key: string, value: any, lessQuantitiesSecurityCheck?: any, overbookingSecurityCheck?: any) => {
    if (key === "docInputType") {
        return arrayConverter(value, [
            { from: "textInput", to: IssueFromProduction.DOC_INPUT_TYPE_MANUAL },
            { from: "barcodeScan", to: IssueFromProduction.DOC_INPUT_TYPE_SCAN },
        ])
    } else if (key === "displayedColumns") {
        if (value === "templateA") {
            return IssueFromProduction.DISPLAYED_COLUMNS_TEMPLATE_A;
        } else if (value === "templateB") {
            return IssueFromProduction.DISPLAYED_COLUMNS_TEMPLATE_B;
        }
    } else if (key === "quickCheck") {
        return booleanConverter(value, { from: "enable", to: true }, { from: "disable", to: false })
    } else if (key === "warehouseLayout") {
        if (value === "warehouseName") {
            return IssueFromProduction.WAREHOUSE_LAYOUT_WAREHOUSE_NAME;
        } else if (value === "warehouseCode") {
            return IssueFromProduction.WAREHOUSE_LAYOUT_WAREHOUSE_CODE;
        }
    } else if (key === "completeOrderMode") {
        if (value === "atLeastOnePosition") {
            return IssueFromProduction.COMPLETE_ORDER_MODE_AT_LEAST_ONCE;
        } else if (value === "allPositions") {
            return IssueFromProduction.COMPLETE_ORDER_MODE_ALL;
        }
    } else if (key === "allowQtyChange") {
        let tempData: string[] = [];
        value.map((data: any) => {
            if (data === "allowLessQuantities") {
                if (lessQuantitiesSecurityCheck === "lessQuantityAllowedWithCheck") {
                    tempData.push(IssueFromProduction.ALLOW_QTY_CHANGE_LOWER_WITH_SECURITY);
                } else {
                    tempData.push(IssueFromProduction.ALLOW_QTY_CHANGE_LOWER_WITHOUT_SECURITY);
                }
            } else if (data === "allowOverbooking") {
                if (overbookingSecurityCheck === "overbookingAllowedWithCheck") {
                    tempData.push(IssueFromProduction.ALLOW_QTY_CHANGE_HIGHER_WITH_SECURITY);
                } else {
                    tempData.push(IssueFromProduction.ALLOW_QTY_CHANGE_HIGHER_WITHOUT_SECURITY);
                }
            }
        })
        return tempData;
    }
}

export const receiptFromProductionDatabaseMapToLocalValue = (key: string, value: any) => {
    if (key === "docInputType") {
        return arrayConverter(value, [
            { from: ReceiptFromProduction.DOC_INPUT_TYPE_MANUAL, to: "textInput" },
            { from: ReceiptFromProduction.DOC_INPUT_TYPE_SCAN, to: "barcodeScan" },
        ])
    } else if (key === "displayedColumns") {
        if (value === ReceiptFromProduction.DISPLAYED_COLUMNS_TEMPLATE_A) {
            return "templateA";
        } else if (value === ReceiptFromProduction.DISPLAYED_COLUMNS_TEMPLATE_B) {
            return "templateB";
        }
    } else if (key === "quickCheck") {
        return booleanConverter(value, { from: true, to: "enable" }, { from: false, to: "disable" })
    } else if (key === "warehouseLayout") {
        if (value === ReceiptFromProduction.WAREHOUSE_LAYOUT_WAREHOUSE_NAME) {
            return "warehouseName";
        } else if (value === ReceiptFromProduction.WAREHOUSE_LAYOUT_WAREHOUSE_CODE) {
            return "warehouseCode";
        }
    } else if (key === "completeOrderMode") {
        if (value === ReceiptFromProduction.COMPLETE_ORDER_MODE_AT_LEAST_ONCE) {
            return "atLeastOnePosition";
        } else if (value === ReceiptFromProduction.COMPLETE_ORDER_MODE_ALL) {
            return "allPositions";
        }
    } else if (key === "allowQtyChange") {
        let tempResult = {
            moreOrLessQuantitiesAllowed: [] as string[],
            lessQuantitiesSecurityCheck: "" as string,
            overbookingSecurityCheck: "" as string
        }
        let tempData: string[] = [];
        value.map((data: any) => {
            if (data === ReceiptFromProduction.ALLOW_QTY_CHANGE_LOWER_WITH_SECURITY || data === ReceiptFromProduction.ALLOW_QTY_CHANGE_LOWER_WITHOUT_SECURITY) {
                tempData.push("allowLessQuantities");

                if (data === ReceiptFromProduction.ALLOW_QTY_CHANGE_LOWER_WITH_SECURITY) {
                    tempResult.lessQuantitiesSecurityCheck = "lessQuantityAllowedWithCheck";
                } else {
                    tempResult.lessQuantitiesSecurityCheck = "lessQuantityAllowed";
                }
            } else if (data === ReceiptFromProduction.ALLOW_QTY_CHANGE_HIGHER_WITH_SECURITY || data === ReceiptFromProduction.ALLOW_QTY_CHANGE_HIGHER_WITHOUT_SECURITY) {
                tempData.push("allowOverbooking");

                if (data === ReceiptFromProduction.ALLOW_QTY_CHANGE_HIGHER_WITH_SECURITY) {
                    tempResult.overbookingSecurityCheck = "overbookingAllowedWithCheck";
                } else {
                    tempResult.overbookingSecurityCheck = "overbookingAllowed";
                }
            }
        })
        tempResult.moreOrLessQuantitiesAllowed = tempData;

        return tempResult;
    }
}


export const issueFromProductionDatabaseMapToLocalValue = (key: string, value: any) => {
    if (key === "docInputType") {
        return arrayConverter(value, [
            { from: IssueFromProduction.DOC_INPUT_TYPE_MANUAL, to: "textInput" },
            { from: IssueFromProduction.DOC_INPUT_TYPE_SCAN, to: "barcodeScan" },
        ])
    } else if (key === "displayedColumns") {
        if (value === IssueFromProduction.DISPLAYED_COLUMNS_TEMPLATE_A) {
            return "templateA";
        } else if (value === IssueFromProduction.DISPLAYED_COLUMNS_TEMPLATE_B) {
            return "templateB";
        }
    } else if (key === "quickCheck") {
        return booleanConverter(value, { from: true, to: "enable" }, { from: false, to: "disable" })
    } else if (key === "warehouseLayout") {
        if (value === IssueFromProduction.WAREHOUSE_LAYOUT_WAREHOUSE_NAME) {
            return "warehouseName";
        } else if (value === IssueFromProduction.WAREHOUSE_LAYOUT_WAREHOUSE_CODE) {
            return "warehouseCode";
        }
    } else if (key === "completeOrderMode") {
        if (value === IssueFromProduction.COMPLETE_ORDER_MODE_AT_LEAST_ONCE) {
            return "atLeastOnePosition";
        } else if (value === IssueFromProduction.COMPLETE_ORDER_MODE_ALL) {
            return "allPositions";
        }
    } else if (key === "allowQtyChange") {
        let tempResult = {
            moreOrLessQuantitiesAllowed: [] as string[],
            lessQuantitiesSecurityCheck: "" as string,
            overbookingSecurityCheck: "" as string
        }
        let tempData: string[] = [];
        value.map((data: any) => {
            if (data === IssueFromProduction.ALLOW_QTY_CHANGE_LOWER_WITH_SECURITY || data === IssueFromProduction.ALLOW_QTY_CHANGE_LOWER_WITHOUT_SECURITY) {
                tempData.push("allowLessQuantities");

                if (data === IssueFromProduction.ALLOW_QTY_CHANGE_LOWER_WITH_SECURITY) {
                    tempResult.lessQuantitiesSecurityCheck = "lessQuantityAllowedWithCheck";
                } else {
                    tempResult.lessQuantitiesSecurityCheck = "lessQuantityAllowed";
                }
            } else if (data === IssueFromProduction.ALLOW_QTY_CHANGE_HIGHER_WITH_SECURITY || data === IssueFromProduction.ALLOW_QTY_CHANGE_HIGHER_WITHOUT_SECURITY) {
                tempData.push("allowOverbooking");

                if (data === IssueFromProduction.ALLOW_QTY_CHANGE_HIGHER_WITH_SECURITY) {
                    tempResult.overbookingSecurityCheck = "overbookingAllowedWithCheck";
                } else {
                    tempResult.overbookingSecurityCheck = "overbookingAllowed";
                }
            }
        })
        tempResult.moreOrLessQuantitiesAllowed = tempData;

        return tempResult;
    }
}
