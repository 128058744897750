import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
export var NoPreview = function NoPreview(props) {
  return React.createElement(SvgIcon, Object.assign({}, props), React.createElement("svg", {
    viewBox: "0 0 60 56",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, React.createElement("path", {
    d: "M30,9 C16.6666667,9 5.28,17.2933333 0.666666667,29 C5.28,40.7066667 16.6666667,49 30,49 C43.3333333,49 54.72,40.7066667 59.3333333,29 C54.72,17.2933333 43.3333333,9 30,9 Z M30,42.3333333 C22.64,42.3333333 16.6666667,36.36 16.6666667,29 C16.6666667,21.64 22.64,15.6666667 30,15.6666667 C37.36,15.6666667 43.3333333,21.64 43.3333333,29 C43.3333333,36.36 37.36,42.3333333 30,42.3333333 Z M30,21 C25.5733333,21 22,24.5733333 22,29 C22,33.4266667 25.5733333,37 30,37 C34.4266667,37 38,33.4266667 38,29 C38,24.5733333 34.4266667,21 30,21 Z"
  }), React.createElement("rect", {
    "fill-opacity": "0.8",
    transform: "translate(30.000000, 29.000000) rotate(-315.000000) translate(-30.000000, -29.000000) ",
    x: "27",
    y: "-8",
    width: "6",
    height: "74",
    rx: "3"
  })));
};
export default NoPreview;