// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    functionButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 8,
      color: "#ffffff !important",
      textAlign: "center",
      textTransform: "capitalize",
      width: "100%"
    },
    functionButtonActive: {
      backgroundColor: "#00a7dd !important",
      "&:hover": {
        backgroundColor: "#8fcff6 !important"
      }
    },
    functionButtonInactive: {
      backgroundColor: "#abadb1 !important",
      color: "white !important"
    }
  });
});