// React Lib
import React from 'react'

// Material UI Core
// import Collapse from '@material-ui/core/Collapse'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

// @otg-one/ui_components
import { Radio, Separator, Checkbox, Typography, FONT_FAMILY, Color, Creatable, Form, Tooltip } from '@otg-one/ui_components'

// Constants
import { OPTION_TYPE_RADIO, OPTION_TYPE_CHECKBOX, OPTION_TYPE_CREATABLE, OPTION_TYPE_TEXTBOX } from '../../Constants/OptionType'

interface IConfigurationTitleProps {
    activeOption?: string,
    setActiveOption?: any,
    configurationOption: any
}

const ConfigurationOption = (props: IConfigurationTitleProps) => {
    const { activeOption, setActiveOption, configurationOption } = props

    const theme = useTheme()
    const isPhone = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <>
            <div id={configurationOption.id} />
            <div
                onClick={() => setActiveOption(configurationOption.id)}
                style={{ maxWidth: 450, marginTop: isPhone ? 250 : 350 }}
            >
                {isPhone && <div style={{ marginBottom: 30 }}><Separator type='n200' /></div>}
                <div style={{ opacity: activeOption === configurationOption.id ? 1 : 0.3, transition: "all 0.25s ease-in" }}>
                    <Typography fontFamily={FONT_FAMILY.ROBOTO_REGULAR} size={12} color={Color.neutral[900]}>
                        {configurationOption.heading}
                    </Typography>
                    <div style={{ position: "relative" }}>
                        <Typography fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD} size={24} color={Color.neutral[900]}>
                            {configurationOption.title}
                        </Typography>
                        {configurationOption.isCustomized && (
                            <Tooltip title="Customized" placement="right">
                                <div
                                    style={{
                                        position: "absolute",
                                        top: 4,
                                        left: -8,
                                        width: 8,
                                        height: 8,
                                        background: Color.alert[700],
                                        borderRadius: "50%"
                                    }}
                                />
                            </Tooltip>
                        )}
                    </div>
                    {configurationOption.subtitle !== '' && (
                        <Typography fontFamily={FONT_FAMILY.ROBOTO_REGULAR} size={14} color={Color.neutral[400]} style={{ width: isPhone ? 'auto' : 380, marginTop: 10, marginBottom: 24 }}>
                            {configurationOption.subtitle}
                        </Typography>
                    )}
                    {configurationOption.optionType === OPTION_TYPE_RADIO && (
                        <>
                            {configurationOption.options.map((option: any, index: number) => (
                                <Radio
                                    name={configurationOption.id}
                                    value={option.value}
                                    onChange={option.onChange}
                                    checked={option.checked}
                                    disabled={option.disabled}
                                    label={
                                        <>
                                            <Typography
                                                fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                                size={14}
                                                color={Color.neutral[900]}
                                            >
                                                {option.label}
                                            </Typography>
                                            <Typography
                                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                                size={14}
                                                color={Color.neutral[400]}
                                            >
                                                {option.subLabel}
                                            </Typography>
                                        </>
                                    }
                                />
                            ))}
                        </>
                    )}
                    {configurationOption.optionType === OPTION_TYPE_CHECKBOX && (
                        <>
                            {configurationOption.options.map((option: any, index: number) => (
                                <Checkbox
                                    name={configurationOption.id}
                                    onChange={option.onChange}
                                    checked={option.checked}
                                    disabled={option.disabled}
                                    value={option.value}
                                    label={
                                        <>
                                            <Typography
                                                fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                                size={14}
                                                color={Color.neutral[900]}
                                            >
                                                {option.label}
                                            </Typography>
                                            <Typography
                                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                                size={14}
                                                color={Color.neutral[400]}
                                            >
                                                {option.subLabel}
                                            </Typography>
                                        </>
                                    }
                                />
                            ))}
                            {!configurationOption.allowEmpty && (
                                !configurationOption.isValid && <p style={{
                                    fontSize: "12px",
                                    color: Color.danger[400],
                                }}>{"Please select at least one of the option"}</p>
                            )}
                        </>
                    )}
                    {configurationOption.optionType === OPTION_TYPE_CREATABLE && (
                        <>
                            {configurationOption.options.map((option: any, index: number) => (
                                <>
                                    <Typography
                                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                        size={14}
                                        color={Color.neutral[900]}
                                    >
                                        {option.label}
                                    </Typography>
                                    <div style={{ width: 375 }}>
                                        <Creatable initialValue={option.value} setInitialValue={option.onChange} />
                                    </div>
                                </>
                            ))}
                            {!configurationOption.allowEmpty && (
                                !configurationOption.isValid && <p style={{
                                    fontSize: "12px",
                                    color: Color.danger[400],
                                }}>{"Field cannot be empty"}</p>
                            )}
                        </>
                    )}
                    {configurationOption.optionType === OPTION_TYPE_TEXTBOX && (
                        <>
                            {configurationOption.options.map((option: any, index: number) => (
                                <>
                                    <Typography
                                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                        size={14}
                                        color={Color.neutral[400]}
                                        style={{ width: 380, marginTop: 24, marginBottom: 10 }}
                                    >
                                        {option.label}
                                    </Typography>
                                    <Form
                                        style={{ width: 375 }}
                                        placeholder={option.placeholder}
                                        required
                                        value={option.value}
                                        onChange={option.onChange}
                                    />
                                </>
                            ))}
                            {!configurationOption.allowEmpty && (
                                !configurationOption.isValid && <p style={{
                                    fontSize: "12px",
                                    color: Color.danger[400],
                                }}>{"Field cannot be empty"}</p>
                            )}
                        </>
                    )}
                </div>
                {/* {configurationOption.collapsedOption.map((collapsedOption: any) => (
                    <Collapse in={collapsedOption.checked}>
                        {collapsedOption.checked && (
                            <div id={collapsedOption.id} />
                        )}
                        <div
                            style={{
                                boxSizing: "border-box",
                                padding: 24,
                                marginTop: '35vh',
                                marginLeft: 50,
                                width: 500,
                                background: Color.neutral[100],
                                opacity: activeOption === collapsedOption.id ? 1 : 0.3
                            }}
                        >
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={30}
                                color={Color.neutral[900]}
                            >
                                {collapsedOption.title}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                size={14}
                                color={Color.neutral[400]}
                                style={{ marginTop: 16, marginBottom: 20 }}
                            >
                                {configurationOption.collapsedOption.subtitle}
                            </Typography>
                            {collapsedOption.optionType === OPTION_TYPE_CHECKBOX && (
                                <>
                                    {collapsedOption.options.map((option: any) => (
                                        <Checkbox
                                            name={collapsedOption.id}
                                            value={option.value}
                                            label={option.label}
                                            disabled={option.disabled}
                                            onChange={option.onChange}
                                            checked={option.checked}
                                        />
                                    ))}
                                </>
                            )}
                            {collapsedOption.optionType === OPTION_TYPE_RADIO && (
                                <>
                                    {collapsedOption.options.map((option: any) => (
                                        <Radio
                                            name={collapsedOption.id}
                                            value={option.value}
                                            onChange={option.onChange}
                                            checked={option.checked}
                                            label={
                                                <>
                                                    <Typography
                                                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                                        size={14}
                                                        color={Color.neutral[900]}
                                                    >
                                                        {option.label}
                                                    </Typography>
                                                    <Typography
                                                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                                        size={14}
                                                        color={Color.neutral[400]}
                                                    >
                                                        {option.subLabel}
                                                    </Typography>
                                                </>
                                            }
                                        />
                                    ))}
                                </>
                            )}
                            {collapsedOption.optionType === OPTION_TYPE_CREATABLE && (
                                <>
                                    {collapsedOption.options.map((option: any) => (
                                        <>
                                            <Typography
                                                fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                                size={14}
                                                color={Color.neutral[900]}
                                            >
                                                {option.label}
                                            </Typography>
                                            <Creatable initialValue={option.value} setInitialValue={option.onChange} placeholder={option.placeholder} />
                                        </>
                                    ))}
                                </>
                            )}
                            {collapsedOption.optionType === OPTION_TYPE_TEXTBOX && (
                                <>
                                    {collapsedOption.options.map((option: any) => (
                                        <>
                                            <Typography
                                                fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                                size={14}
                                                color={Color.neutral[900]}
                                            >
                                                {option.label}
                                            </Typography>
                                            <Form
                                                style={{ width: 375 }}
                                                placeholder={option.placeholder}
                                                required
                                                value={option.value}
                                                onChange={option.onChange}
                                            />
                                        </>
                                    ))}
                                </>
                            )}
                        </div>
                    </Collapse>
                ))} */}
            </div>
        </>
    )
}

export default ConfigurationOption