// React libraries
import * as React from 'react'; // Custom Styles

import { useStyles } from "./ScanContentBarStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
export var ScanContentBar = function ScanContentBar(props) {
  var classes = useStyles(props);
  var text = props.text,
      onClick = props.onClick,
      applyBorderRadius = props.applyBorderRadius;
  return React.createElement(Grid, {
    container: true,
    className: classes.scanContentBar,
    onClick: onClick
  }, React.createElement(Grid, {
    className: classes.content,
    item: true,
    md: 6
  }, text));
};
ScanContentBar.defaultProps = {
  applyBorderRadius: true,
  onClick: function onClick() {}
};
export default React.memo(ScanContentBar);