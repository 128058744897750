import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// React libraries
import * as React from 'react';
import classnames from 'classnames'; // Custom Styles

import { useStyles } from "./FunctionButtonStyles"; // Material-UI Core

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
export var FunctionButton = function FunctionButton(props) {
  var _classnames;

  var classes = useStyles(props);
  var onClick = props.onClick,
      data = props.data,
      previewWidth = props.previewWidth,
      previewHeight = props.previewHeight;
  var functionButtonClasses = classnames((_classnames = {}, _defineProperty(_classnames, classes.functionButton, true), _defineProperty(_classnames, classes.functionButtonActive, data.active), _defineProperty(_classnames, classes.functionButtonInactive, !data.active), _classnames));
  return React.createElement(Button, {
    className: functionButtonClasses,
    onClick: onClick,
    style: {
      height: previewHeight / 3 - 52
    },
    disabled: !data.active
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(data.icon, {
    style: {
      fontSize: previewWidth * 11 / 100
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement("p", {
    style: {
      fontSize: previewWidth * 4 / 100,
      marginTop: 0
    }
  }, data.name))));
};
export default React.memo(FunctionButton);