import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React, { useState } from "react"; // Custom Styles

import { useStyles } from "./DropdownStyles"; // Components

import { Typography, OverflowMenuSelection } from "../index"; // Assets

import { ArrowHeadDown } from "../../icons"; // Constants

import { Color, FONT_FAMILY } from "../../constants";
export var Dropdown = function Dropdown(props) {
  var classes = useStyles(props);
  var id = props.id,
      menu = props.menu,
      type = props.type,
      selected = props.selected,
      backgroundColor = props.backgroundColor;

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      anchorEl = _useState2[0],
      setAnchorEl = _useState2[1];

  var defaultItem = null;

  if (selected) {
    defaultItem = selected;
  } else if (selected === null || typeof selected === 'undefined') {
    defaultItem = menu[0].text;
  }

  var _useState3 = useState(defaultItem),
      _useState4 = _slicedToArray(_useState3, 2),
      selectedItem = _useState4[0],
      setSelectedItem = _useState4[1];

  var handleClickMenuSelection = function handleClickMenuSelection(event) {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }

    ;
  };

  return React.createElement("div", {
    id: id,
    className: classes.dropdownContainer,
    "aria-controls": "".concat(id, "-dropdownMenu"),
    "aria-haspopup": "true",
    onClick: handleClickMenuSelection,
    style: {
      background: backgroundColor
    }
  }, React.createElement("div", {
    id: "".concat(id, "-dropdownText"),
    className: classes.dropdownText
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 14,
    color: Color.neutral[400]
  }, selectedItem)), React.createElement("div", {
    id: "".concat(id, "-dropdownArrow"),
    className: classes.dropdownArrowIcon
  }, React.createElement(ArrowHeadDown, null)), React.createElement(OverflowMenuSelection, {
    id: "".concat(id, "-dropdownMenu"),
    anchorElement: anchorEl,
    menuItems: menu,
    setValue: setSelectedItem,
    setAnchorEl: setAnchorEl,
    selectedItem: selectedItem
  }));
};
Dropdown.defaultProps = {
  id: "",
  menu: [],
  selected: null
};
export default React.memo(Dropdown);