import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
import { SVG_GRADIENT_BLUE } from '../Button/ButtonStyles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    openTicketContainer: _defineProperty({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      boxSizing: "border-box",
      padding: 24,
      width: "100%",
      height: 208,
      backgroundSize: "0% 100%",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "border-box",
      border: "4px solid ".concat(Color.primary[600]),
      borderImage: "url('data:image/svg+xml;base64,".concat(SVG_GRADIENT_BLUE, "') 10% stretch"),
      backgroundImage: Color.gradient[100],
      transition: "all .2s ease-in-out",
      "&:hover": {
        border: "4px solid ".concat(Color.primary[400]),
        backgroundColor: Color.primary[400],
        color: "".concat(Color.neutral[0], " !important")
      }
    }, theme.breakpoints.down('xs'), {
      padding: 15
    }),
    openTicket: {
      display: "flex",
      alignItems: "baseline",
      justifyContent: "center"
    }
  });
});