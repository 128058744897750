// React lib
import React, { useContext } from 'react';

// Context
import { DeliveryContext } from './DeliveryConfiguration';

// Components
import ConfigurationTitle from '../ConfigurationTitle';
import ConfigurationOption from '../ConfigurationOption';

// Constants
import { OPTION_TYPE_RADIO, OPTION_TYPE_CHECKBOX } from '../../../Constants/OptionType';
import { DISPLAYED_COLUMN, NEW_POSITION_ADDABLE, QUICK_CHECK, WAREHOUSE_NAME, COMPLETE_ORDER, ORDER_POSITION_COUNT, ITEM_SELECTION } from './DeliveryIds';

// Utils
import { deliveryLocalMapToDatabaseValue, arraysMatch } from "../../../Utils/Utils"

const SettingsForListPositions = (props: any) => {
    const {
        defaultFunctionType,
        activeOption,
        setActiveOption,
        displayedColumn,
        handleChangeDisplayedColumn,
        newPositionAddable,
        handleChangeNewPositionAddable,
        itemSelection,
        handleChangeItemSelection,
        quickCheck,
        handleChangeQuickCheck,
        warehouseName,
        handleChangeWarehouseName,
        completeOrder,
        handleChangeCompleteOrder,
    } = useContext(DeliveryContext);

    const displayedColumnOption = {
        id: DISPLAYED_COLUMN,
        heading: '',
        title: `Displayed Column`,
        subtitle: `Select appearance of the position list`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.displayedColumns !== deliveryLocalMapToDatabaseValue("displayedColumns", displayedColumn)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `templateA`,
                onChange: handleChangeDisplayedColumn,
                checked: displayedColumn === `templateA`,
                disabled: false,
                label: `Template A`,
                subLabel: `Column Names in Line List`
            },
            {
                value: `templateB`,
                onChange: handleChangeDisplayedColumn,
                checked: displayedColumn === `templateB`,
                disabled: false,
                label: `Template B`,
                subLabel: `Column Names In the Header List`
            }
        ],
        collapsedOption: []
    }

    const newPositionAddableOption = {
        id: NEW_POSITION_ADDABLE,
        heading: '',
        title: `New Position Addable`,
        subtitle: `Defines whether the user can add a position at any time. The position will be assigned to a document (order) or a supplier. If the assignment is not unique (i.e. when several orders have been selected) a selection menu will appear with the relevant order documents`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.addNewPosition !== deliveryLocalMapToDatabaseValue("addNewPosition", newPositionAddable)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `disable`,
                onChange: handleChangeNewPositionAddable,
                checked: newPositionAddable === `disable`,
                disabled: false,
                label: `Disable`,
                subLabel: ``
            },
            {
                value: `enable`,
                onChange: handleChangeNewPositionAddable,
                checked: newPositionAddable === `enable`,
                disabled: false,
                label: `Enable`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const itemSelectionOption = {
        id: ITEM_SELECTION,
        heading: 'NEW POSITION ADDABLE',
        title: `Item Selection`,
        subtitle: `How should the item be selected for the new position?`,
        isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.selectItemInput, deliveryLocalMapToDatabaseValue("selectItemInput", itemSelection))) ? true : false,
        optionType: OPTION_TYPE_CHECKBOX,
        allowEmpty: false,
        isValid: itemSelection.length > 0 ? true : false,
        options: [
            {
                label: `Manual text input of the item number`,
                onChange: handleChangeItemSelection,
                checked: itemSelection.includes('textInput'),
                value: `textInput`,
                disabled: false
            },
            {
                label: `Barcode Scan`,
                onChange: handleChangeItemSelection,
                checked: itemSelection.includes('barcodeScan'),
                value: `barcodeScan`,
                disabled: false
            },
            {
                label: `OCR Scan ( Unavailable )`,
                onChange: handleChangeItemSelection,
                checked: itemSelection.includes('ocrScan'),
                value: `ocrScan`,
                disabled: true
            },
            {
                label: `Items Search`,
                onChange: handleChangeItemSelection,
                checked: itemSelection.includes('itemsSearch'),
                value: `itemsSearch`,
                disabled: false
            }
        ],
        collapsedOption: []
    }

    const quickCheckOption = {
        id: QUICK_CHECK,
        heading: '',
        title: `Quick Check`,
        subtitle: `Defines whether a position can be booked directly from the list (via checkbox). In that case the warehouse as defined in the order will be chosen automatically. The quickcheck checkbox will be hidden for all items with mandatory batch or serial numbers`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.quickCheck !== deliveryLocalMapToDatabaseValue("quickCheck", quickCheck)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `disable`,
                onChange: handleChangeQuickCheck,
                checked: quickCheck === `disable`,
                disabled: false,
                label: `Disable`,
                subLabel: ``
            },
            {
                value: `enable`,
                onChange: handleChangeQuickCheck,
                checked: quickCheck === `enable`,
                disabled: false,
                label: `Enable`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const warehouseNameOption = {
        id: WAREHOUSE_NAME,
        heading: '',
        title: `Warehouse Name`,
        subtitle: `Select display variant for the warehouses`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.warehouseLayout !== deliveryLocalMapToDatabaseValue("warehouseLayout", warehouseName)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `warehouseName`,
                onChange: handleChangeWarehouseName,
                checked: warehouseName === `warehouseName`,
                disabled: false,
                label: `Show Warehouse Name`,
                subLabel: ``
            },
            {
                value: `warehouseCode`,
                onChange: handleChangeWarehouseName,
                checked: warehouseName === `warehouseCode`,
                disabled: false,
                label: `Show Warehouse Code`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const completedOrderOption = {
        id: COMPLETE_ORDER,
        heading: '',
        title: `Complete Order`,
        subtitle: `Defines how many positions must be completed before finishing an order`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.completeOrderMode !== deliveryLocalMapToDatabaseValue("completeOrderMode", completeOrder)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `atLeastOnePosition`,
                onChange: handleChangeCompleteOrder,
                checked: completeOrder === `atLeastOnePosition`,
                disabled: false,
                label: `At least one position`,
                subLabel: ``
            },
            {
                value: `allPositions`,
                onChange: handleChangeCompleteOrder,
                checked: completeOrder === `allPositions`,
                disabled: false,
                label: `All positions`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    return (
        <>
            <ConfigurationOption configurationOption={displayedColumnOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={newPositionAddableOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            {newPositionAddable === 'enable' && (
                <ConfigurationOption configurationOption={itemSelectionOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            )}
            <ConfigurationOption configurationOption={quickCheckOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={warehouseNameOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={completedOrderOption} activeOption={activeOption} setActiveOption={setActiveOption} />
        </>
    );
};

export default SettingsForListPositions;