// React lib
import React, { useContext } from 'react';

// Context
import { PickAndPackContext } from './PickAndPackConfiguration';

// Components
import ConfigurationTitle from '../ConfigurationTitle';
import ConfigurationOption from '../ConfigurationOption';

// Constants
import { OPTION_TYPE_RADIO, OPTION_TYPE_CHECKBOX, OPTION_TYPE_CREATABLE, OPTION_TYPE_TEXTBOX } from '../../../Constants/OptionType';
import { WAREHOUSE_NAME, LESS_QUANTITIES_ALLOWED, SETTINGS_FOR_PICK_AND_PACK, QUICK_CHECK, USE_STORAGE_BOX_SYSTEM, BOXES, SHIPPING_DIMENSION, MULTISCAN_FOR_BATCHES, CONTAINER_FOR_EXCESS_WEIGHT, CHECK_WEIGHT, LIMIT_FOR_THE_WEIGHT_CHECK, WEIGHT_UNIT, USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM, COMPLETE_PICK_LIST, IGNORE_ITEMS, SERIAL_NUMBER_TYPE, CHECK_DELIVERY_NOTE, ACTIVATE_SHIPPING_DIMENSION, CONFIRM_ITEMS_BY_ONE_CLICK, WEIGHT_FOR_BIG_SMALL_BOX, WEIGHT_FOR_BIG_BIG_BOX, CREATE_DELIVERY_AUTOMATICALLY, FILTER_PICK_LIST_BY_USER } from './PickAndPackIds';

// Utils
import { pickAndPackLocalMapToDatabaseValue, arraysMatch } from "../../../Utils/Utils"

const SettingsForDelivery = (props: any) => {
    const {
        defaultFunctionType,
        activeOption,
        setActiveOption,
        warehouseName,
        handleChangeWarehouseName,
        lessQuantitiesAllowed,
        handleChangeLessQuantitiesAllowed,
        quickCheck,
        handleChangeQuickCheck,
        useStorageBoxSystem,
        handleChangeUseStorageBoxSystem,
        boxes,
        handleChangeBoxes,
        shippingDimensionHeight,
        handleChangeShippingDimensionHeight,
        shippingDimensionLength,
        handleChangeShippingDimensionLength,
        shippingDimensionWidth,
        handleChangeShippingDimensionWidth,
        multiscanForBatches,
        handleChangeMultiscanForBatches,
        containerForExcessWeight,
        handleChangeContainerForExcessWeight,
        checkWeight,
        handleChangeCheckWeight,
        limitForTheWeightCheck,
        handleChangeLimitForTheWeightCheck,
        weightUnit,
        handleChangeWeightUnit,
        useOneBatchWithinOneStorageBinForAnItem,
        handleChangeUseOneBatchWithinOneStorageBinForAnItem,
        ignoreItems,
        handleChangeIgnoreItems,
        completePickList,
        handleChangeCompletePickList,
        serialNumberType,
        handleChangeSerialNumberType,
        isCheckDeliveryNote,
        handleChangeIsCheckDeliveryNote,
        isActivateShippingDimension,
        handleChangeIsActiveShippingDimension,
        isConfirmItemsByOneClick,
        handleChangeIsConfirmItemsByOneClick,
        bigSmallBoxWeight,
        handleChangeBigSmallBoxWeight,
        bigBigBoxWeight,
        handleChangeBigBigBoxWeight,
        isCreateDeliveryAutomatically,
        handleChangeIsCreateDeliveryAutomatically,
        isFilterPickListByUser,
        handleChangeIsFilterPickListByUser
    } = useContext(PickAndPackContext);

    const warehouseNameOption = {
        id: WAREHOUSE_NAME,
        title: `Warehouse Name`,
        subtitle: `Select display variant for the warehouses`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.warehouseLayout !== pickAndPackLocalMapToDatabaseValue("warehouseLayout", warehouseName)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `warehouseName`,
                onChange: handleChangeWarehouseName,
                checked: warehouseName === `warehouseName`,
                disabled: false,
                label: `Show Warehouse Name`,
                subLabel: ``
            },
            {
                value: `warehouseCode`,
                onChange: handleChangeWarehouseName,
                checked: warehouseName === `warehouseCode`,
                disabled: false,
                label: `Show Warehouse Code`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const lessQuantitiesAllowedOption = {
        id: LESS_QUANTITIES_ALLOWED,
        title: `Less Quantities Allowed`,
        subtitle: `Defines whether less quantity is allowed for item of picking list`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.allowLowerQuantity !== pickAndPackLocalMapToDatabaseValue("allowLowerQuantity", lessQuantitiesAllowed)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `dontAllow`,
                onChange: handleChangeLessQuantitiesAllowed,
                checked: lessQuantitiesAllowed === `dontAllow`,
                disabled: false,
                label: `Don't Allow`,
                subLabel: ``
            },
            {
                value: `allow`,
                onChange: handleChangeLessQuantitiesAllowed,
                checked: lessQuantitiesAllowed === `allow`,
                disabled: false,
                label: `Allow`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const quickCheckOption = {
        id: QUICK_CHECK,
        title: `Quick Check`,
        subtitle: `Defines whether a position can be booked directly from the list (via checkbox). In that case the warehouse as defined in the order will be chosen automatically. The quickcheck checkbox will be hidden for all items with mandatory batch or serial numbers`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.quickCheck !== pickAndPackLocalMapToDatabaseValue("quickCheck", quickCheck)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `disable`,
                onChange: handleChangeQuickCheck,
                checked: quickCheck === `disable`,
                disabled: false,
                label: `Disable`,
                subLabel: ``
            },
            {
                value: `enable`,
                onChange: handleChangeQuickCheck,
                checked: quickCheck === `enable`,
                disabled: false,
                label: `Enable`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const useStorageBoxSystemOption = {
        id: USE_STORAGE_BOX_SYSTEM,
        title: `Use Storage Box System`,
        subtitle: `Use storage boxes for processing`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.useContainer !== pickAndPackLocalMapToDatabaseValue("useContainer", useStorageBoxSystem)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `disable`,
                onChange: handleChangeUseStorageBoxSystem,
                checked: useStorageBoxSystem === `disable`,
                disabled: false,
                label: `Disable`,
                subLabel: ``
            },
            {
                value: `enable`,
                onChange: handleChangeUseStorageBoxSystem,
                checked: useStorageBoxSystem === `enable`,
                disabled: false,
                label: `Enable`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const boxesOption = {
        id: BOXES,
        heading: `USE STORAGE BOX SYSTEM`,
        title: `Boxes`,
        subtitle: ``,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.containerUdf !== pickAndPackLocalMapToDatabaseValue("containerUdf", boxes)) ? true : false,
        optionType: OPTION_TYPE_TEXTBOX,
        allowEmpty: false,
        isValid: boxes !== "" ? true : false,
        options: [
            {
                value: boxes,
                onChange: handleChangeBoxes,
                placeholder: `Field boxes`,
                label: `Name of the field for the boxes (UDF in Object: Inventory > Pick List - Rows > BoxNo)`,
            },
        ],
        collapsedOption: []
    }

    const shippingDimensionOption = {
        id: SHIPPING_DIMENSION,
        heading: `USE STORAGE BOX SYSTEM`,
        title: `Shipping Dimension`,
        subtitle: ``,
        isCustomized: (defaultFunctionType && (
            defaultFunctionType.config.palletHeight !== pickAndPackLocalMapToDatabaseValue("palletHeight", shippingDimensionHeight) ||
            defaultFunctionType.config.palletLength !== pickAndPackLocalMapToDatabaseValue("palletLength", shippingDimensionLength) ||
            defaultFunctionType.config.palletWidth !== pickAndPackLocalMapToDatabaseValue("palletWidth", shippingDimensionWidth)
        )) ? true : false,
        optionType: OPTION_TYPE_TEXTBOX,
        allowEmpty: false,
        isValid: (shippingDimensionHeight !== "" && shippingDimensionLength !== "" && shippingDimensionWidth !== "") ? true : false,
        options: [
            {
                value: shippingDimensionHeight,
                onChange: handleChangeShippingDimensionHeight,
                placeholder: `Field Height`,
                label: `Name of the field for the height (UDF in Object: Inventory > Pick List - Rows > PackageSizeH)`,
            },
            {
                value: shippingDimensionLength,
                onChange: handleChangeShippingDimensionLength,
                placeholder: `Field Length`,
                label: `Name of the field for the length (UDF in Object: Inventory > Pick List - Rows > PackageSizeL)`,
            },
            {
                value: shippingDimensionWidth,
                onChange: handleChangeShippingDimensionWidth,
                placeholder: `Field Width`,
                label: `Name of the field for the width (UDF in Object: Inventory > Pick List - Rows > PackageSizeW)`,
            },
        ],
        collapsedOption: []
    }

    const multiscanForBatchesOption = {
        id: MULTISCAN_FOR_BATCHES,
        heading: `USE STORAGE BOX SYSTEM`,
        title: `Multiscan for Batches`,
        subtitle: `Collect items with batches through multiscan`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.containerMultiscan !== pickAndPackLocalMapToDatabaseValue("containerMultiscan", multiscanForBatches)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `disable`,
                onChange: handleChangeMultiscanForBatches,
                checked: multiscanForBatches === `disable`,
                disabled: false,
                label: `Disable`,
                subLabel: ``
            },
            {
                value: `enable`,
                onChange: handleChangeMultiscanForBatches,
                checked: multiscanForBatches === `enable`,
                disabled: false,
                label: `Enable`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const containerForExcessWeightOption = {
        id: CONTAINER_FOR_EXCESS_WEIGHT,
        heading: `USE STORAGE BOX SYSTEM`,
        title: `Container for excess weight`,
        subtitle: `Applicable containers for orders that exceed the configured limit`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.heavyContainers !== pickAndPackLocalMapToDatabaseValue("heavyContainers", containerForExcessWeight)) ? true : false,
        optionType: OPTION_TYPE_CREATABLE,
        allowEmpty: false,
        isValid: (containerForExcessWeight && containerForExcessWeight.length > 0) ? true : false,
        options: [
            {
                onChange: handleChangeContainerForExcessWeight,
                value: containerForExcessWeight,
                placeholder: `Add Box`,
                label: ``
            }
        ],
        collapsedOption: []
    }

    const checkWeightOption = {
        id: CHECK_WEIGHT,
        title: `Check Weight`,
        subtitle: `Check weight for positions and mark exceedance`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.useWeightLights !== pickAndPackLocalMapToDatabaseValue("useWeightLights", checkWeight)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `disable`,
                onChange: handleChangeCheckWeight,
                checked: checkWeight === `disable`,
                disabled: false,
                label: `Disable`,
                subLabel: ``
            },
            {
                value: `enable`,
                onChange: handleChangeCheckWeight,
                checked: checkWeight === `enable`,
                disabled: false,
                label: `Enable`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const limitForTheWeightCheckOption = {
        id: LIMIT_FOR_THE_WEIGHT_CHECK,
        heading: `CHECK WEIGHT`,
        title: `Limit for the Weight Check`,
        subtitle: `Set limit for the weight check (in grams)`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.exceededWeightValue !== pickAndPackLocalMapToDatabaseValue("exceededWeightValue", limitForTheWeightCheck)) ? true : false,
        optionType: OPTION_TYPE_TEXTBOX,
        allowEmpty: false,
        isValid: limitForTheWeightCheck !== "" ? true : false,
        options: [
            {
                value: limitForTheWeightCheck,
                onChange: handleChangeLimitForTheWeightCheck,
                placeholder: `Limit`,
                label: ``,
            },
        ],
        collapsedOption: []
    }

    const weightUnitOption = {
        id: WEIGHT_UNIT,
        heading: `CHECK WEIGHT`,
        title: `Weight Unit`,
        subtitle: `Weight unit of items`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.weightUnit !== pickAndPackLocalMapToDatabaseValue("weightUnit", weightUnit)) ? true : false,
        checked: checkWeight === `enable`,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `g`,
                onChange: handleChangeWeightUnit,
                checked: weightUnit === `g`,
                disabled: false,
                label: `g`,
                subLabel: `Set the weight unit in gram (g)`
            },
            {
                value: `kg`,
                onChange: handleChangeWeightUnit,
                checked: weightUnit === `kg`,
                disabled: false,
                label: `kg`,
                subLabel: `Set the weight unit in kilogram (kg)`
            }
        ],
        collapsedOption: []
    }

    const useOneBatchWithinOneStorageBinForAnItemOption = {
        id: USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM,
        title: `Use one batch within one storage bin for an item`,
        subtitle: `Use at most one batch per article for one article`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.allowOneBatchForOneBinLoc !== pickAndPackLocalMapToDatabaseValue("allowOneBatchForOneBinLoc", useOneBatchWithinOneStorageBinForAnItem)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `disable`,
                onChange: handleChangeUseOneBatchWithinOneStorageBinForAnItem,
                checked: useOneBatchWithinOneStorageBinForAnItem === `disable`,
                disabled: false,
                label: `Disable`,
                subLabel: ``
            },
            {
                value: `enable`,
                onChange: handleChangeUseOneBatchWithinOneStorageBinForAnItem,
                checked: useOneBatchWithinOneStorageBinForAnItem === `enable`,
                disabled: false,
                label: `Enable`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const ignoreItemsOption = {
        id: IGNORE_ITEMS,
        title: `Ignore Items`,
        subtitle: `Ignore configured items in pick lists`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.filterItems !== pickAndPackLocalMapToDatabaseValue("filterItems", ignoreItems)) ? true : false,
        optionType: OPTION_TYPE_CREATABLE,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                onChange: handleChangeIgnoreItems,
                value: ignoreItems,
                placeholder: `Add Item`,
                label: ``
            }
        ],

        collapsedOption: []
    }

    const completePickListOption = {
        id: COMPLETE_PICK_LIST,
        title: `Complete Pick List`,
        subtitle: `Defines how many positions must be completed before finishing a pick list`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.completeOrderMode !== pickAndPackLocalMapToDatabaseValue("completeOrderMode", completePickList)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `atLeastOnePosition`,
                onChange: handleChangeCompletePickList,
                checked: completePickList === `atLeastOnePosition`,
                disabled: false,
                label: `At least one position`,
                subLabel: ``
            },
            {
                value: `allPositions`,
                onChange: handleChangeCompletePickList,
                checked: completePickList === `allPositions`,
                disabled: false,
                label: `All positions`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const serialNumberTypeOption = {
        id: SERIAL_NUMBER_TYPE,
        heading: '',
        title: `Serial Number Type`,
        subtitle: `Enter Serial Number or Manufacturer Serial Number`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.serialType !== pickAndPackLocalMapToDatabaseValue("serialType", serialNumberType)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `SER`,
                label: `Serial Number`,
                onChange: handleChangeSerialNumberType,
                checked: serialNumberType === 'SER',
                subLabel: ``,
                disabled: false,
            },
            {
                value: `MNF`,
                label: `Manufacturer Serial Number`,
                onChange: handleChangeSerialNumberType,
                checked: serialNumberType === 'MNF',
                subLabel: ``,
                disabled: false,
            }
        ],
        collapsedOption: []
    }

    const checkDeliveryNoteOption = {
        id: CHECK_DELIVERY_NOTE,
        heading: '',
        title: `Check Delivery Note`,
        subtitle: `Option to show the delivery note`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.allowDeliveryPolicing !== pickAndPackLocalMapToDatabaseValue("allowDeliveryPolicing", isCheckDeliveryNote)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: 'disable',
                label: `No Menu`,
                onChange: handleChangeIsCheckDeliveryNote,
                checked: isCheckDeliveryNote === 'disable',
                subLabel: ``,
                disabled: false,
            },
            {
                value: 'enable',
                label: `Show Menu`,
                onChange: handleChangeIsCheckDeliveryNote,
                checked: isCheckDeliveryNote === 'enable',
                subLabel: ``,
                disabled: false,
            }
        ],
        collapsedOption: []
    }

    const activateShippingDimension = {
        id: ACTIVATE_SHIPPING_DIMENSION,
        heading: '',
        title: `Activate Shipping Dimension`,
        subtitle: `Define the shipping dimension`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.definePalletDimensions !== pickAndPackLocalMapToDatabaseValue("definePalletDimensions", isActivateShippingDimension)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: 'false',
                label: `Don't define shipping dimension`,
                onChange: handleChangeIsActiveShippingDimension,
                checked: isActivateShippingDimension === 'false',
                subLabel: ``,
                disabled: false,
            },
            {
                value: 'true',
                label: `Define shipping dimension`,
                onChange: handleChangeIsActiveShippingDimension,
                checked: isActivateShippingDimension === 'true',
                subLabel: ``,
                disabled: false,
            }
        ],
        collapsedOption: []
    }

    const confirmItemsByOneClickOption = {
        id: CONFIRM_ITEMS_BY_ONE_CLICK,
        heading: '',
        title: `Confirm Items By One Click`,
        subtitle: `Define item which can be selected in list all at once`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.confirmByOneClickItems !== pickAndPackLocalMapToDatabaseValue("confirmByOneClickItems", isConfirmItemsByOneClick)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: 'false',
                label: `Don't confirm by one click`,
                onChange: handleChangeIsConfirmItemsByOneClick,
                checked: isConfirmItemsByOneClick === 'false',
                subLabel: ``,
                disabled: false,
            },
            {
                value: 'true',
                label: `Confirm by one click`,
                onChange: handleChangeIsConfirmItemsByOneClick,
                checked: isConfirmItemsByOneClick === 'true',
                subLabel: ``,
                disabled: false,
            }
        ],
        collapsedOption: []
    }

    const weightForBigSmallBoxOption = {
        id: WEIGHT_FOR_BIG_SMALL_BOX,
        heading: '',
        title: `Weight for two boxes (big and small box)`,
        subtitle: ``,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.bigAndSmallBoxWeightValue !== pickAndPackLocalMapToDatabaseValue("bigAndSmallBoxWeightValue", bigSmallBoxWeight)) ? true : false,
        optionType: OPTION_TYPE_TEXTBOX,
        allowEmpty: true,
        isValid: bigSmallBoxWeight !== "" ? true : false,
        options: [
            {
                value: bigSmallBoxWeight,
                onChange: handleChangeBigSmallBoxWeight,
                placeholder: `Weight`,
                label: `When weight is over the configured value, two boxes are use. One big and one small
                box`,
            },
        ],
        collapsedOption: []
    }

    const weightForBigBigBoxOption = {
        id: WEIGHT_FOR_BIG_BIG_BOX,
        heading: '',
        title: `Weight for two boxes (big and big box)`,
        subtitle: ``,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.bigAndBigBoxWeightValue !== pickAndPackLocalMapToDatabaseValue("bigAndBigBoxWeightValue", bigBigBoxWeight)) ? true : false,
        optionType: OPTION_TYPE_TEXTBOX,
        allowEmpty: true,
        isValid: bigBigBoxWeight !== "" ? true : false,
        options: [
            {
                value: bigBigBoxWeight,
                onChange: handleChangeBigBigBoxWeight,
                placeholder: `Weight`,
                label: `When weight is over the configured value, two boxes are use. One big and one big
                box`,
            },
        ],
        collapsedOption: []
    }

    const createDeliveryAutomaticallyOption = {
        id: CREATE_DELIVERY_AUTOMATICALLY,
        heading: '',
        title: `Create Delivery Automatically`,
        subtitle: `Create delivery automatically while sending pick list`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.automaticDelivery !== pickAndPackLocalMapToDatabaseValue("automaticDelivery", isCreateDeliveryAutomatically)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: 'false',
                label: `Delivery is not created`,
                onChange: handleChangeIsCreateDeliveryAutomatically,
                checked: isCreateDeliveryAutomatically === 'false',
                subLabel: ``,
                disabled: false,
            },
            {
                value: 'true',
                label: `Delivery is created automatically`,
                onChange: handleChangeIsCreateDeliveryAutomatically,
                checked: isCreateDeliveryAutomatically === 'true',
                subLabel: ``,
                disabled: false,
            }
        ],
        collapsedOption: []
    }

    const filterPickListByUserOption = {
        id: FILTER_PICK_LIST_BY_USER,
        heading: '',
        title: `Filter Pick List By User`,
        subtitle: `Pick list is only selectable for assigned user`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.filterPickListsByUser !== pickAndPackLocalMapToDatabaseValue("filterPickListsByUser", isFilterPickListByUser)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: 'true',
                label: `Show assigned pick lists`,
                onChange: handleChangeIsFilterPickListByUser,
                checked: isFilterPickListByUser === 'true',
                subLabel: ``,
                disabled: false,
            },
            {
                value: 'false',
                label: `Show all pick lists`,
                onChange: handleChangeIsFilterPickListByUser,
                checked: isFilterPickListByUser === 'false',
                subLabel: ``,
                disabled: false,
            }
        ],
        collapsedOption: []
    }


    return (
        <>
            <ConfigurationOption configurationOption={warehouseNameOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            {/* <ConfigurationOption configurationOption={lessQuantitiesAllowedOption} activeOption={activeOption} setActiveOption={setActiveOption} /> */}
            {/* <ConfigurationOption configurationOption={quickCheckOption} activeOption={activeOption} setActiveOption={setActiveOption} /> */}
            <ConfigurationOption configurationOption={useStorageBoxSystemOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            {useStorageBoxSystem === 'enable' && (
                <>
                    <ConfigurationOption configurationOption={boxesOption} activeOption={activeOption} setActiveOption={setActiveOption} />
                    <ConfigurationOption configurationOption={activateShippingDimension} activeOption={activeOption} setActiveOption={setActiveOption} />
                    {isActivateShippingDimension === 'true' && (
                        <ConfigurationOption configurationOption={shippingDimensionOption} activeOption={activeOption} setActiveOption={setActiveOption} />
                    )}
                    <ConfigurationOption configurationOption={multiscanForBatchesOption} activeOption={activeOption} setActiveOption={setActiveOption} />
                    <ConfigurationOption configurationOption={containerForExcessWeightOption} activeOption={activeOption} setActiveOption={setActiveOption} />
                    <ConfigurationOption configurationOption={checkDeliveryNoteOption} activeOption={activeOption} setActiveOption={setActiveOption} />
                    <ConfigurationOption configurationOption={weightForBigSmallBoxOption} activeOption={activeOption} setActiveOption={setActiveOption} />
                    <ConfigurationOption configurationOption={weightForBigBigBoxOption} activeOption={activeOption} setActiveOption={setActiveOption} />
                </>
            )}
            <ConfigurationOption configurationOption={checkWeightOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            {checkWeight === 'enable' && (
                <>
                    <ConfigurationOption configurationOption={limitForTheWeightCheckOption} activeOption={activeOption} setActiveOption={setActiveOption} />
                    <ConfigurationOption configurationOption={weightUnitOption} activeOption={activeOption} setActiveOption={setActiveOption} />
                </>
            )}
            <ConfigurationOption configurationOption={useOneBatchWithinOneStorageBinForAnItemOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={ignoreItemsOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={completePickListOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={serialNumberTypeOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            {/* <ConfigurationOption configurationOption={confirmItemsByOneClickOption} activeOption={activeOption} setActiveOption={setActiveOption} /> */}
            <ConfigurationOption configurationOption={createDeliveryAutomaticallyOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={filterPickListByUserOption} activeOption={activeOption} setActiveOption={setActiveOption} />
        </>
    );
};

export default SettingsForDelivery;