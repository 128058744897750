import _objectSpread from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./OrderPositionDetailsPageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { WhiteContentBar } from "../../index";
import { WhiteContentBarButton } from "../../index";
import { ListModal } from "../../index";
import { KeyboardNumberModal } from "../../index";
import { BlueLink } from "../../index";
import { WarningModal } from "../../index";
import { KeyboardCompleteModal } from "../../index";
import { DatePickerModal } from "../../index";
export var OrderPositionDetailsPage = function OrderPositionDetailsPage(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      data = props.data,
      allowedTypes = props.allowedTypes,
      headerTitle = props.headerTitle,
      notAllowScanGS1128 = props.notAllowScanGS1128,
      subtitle = props.subtitle,
      orderOrPlan = props.orderOrPlan;

  var _React$useState = React.useState(data),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      positionDetailData = _React$useState2[0],
      setPositionDetailData = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      isListWarehouseModalOpen = _React$useState4[0],
      setIsListWarehouseModalOpen = _React$useState4[1];

  var _React$useState5 = React.useState(false),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      isListBinLocationModalOpen = _React$useState6[0],
      setIsListBinLocationModalOpen = _React$useState6[1];

  var _React$useState7 = React.useState(false),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      isKeyboardQuantityModalOpen = _React$useState8[0],
      setIsKeyboardQuantityModalOpen = _React$useState8[1];

  var _React$useState9 = React.useState(""),
      _React$useState10 = _slicedToArray(_React$useState9, 2),
      quantity = _React$useState10[0],
      setQuantity = _React$useState10[1];

  var _React$useState11 = React.useState(false),
      _React$useState12 = _slicedToArray(_React$useState11, 2),
      isKeyboardBatchModalOpen = _React$useState12[0],
      setIsKeyboardBatchModalOpen = _React$useState12[1];

  var _React$useState13 = React.useState(""),
      _React$useState14 = _slicedToArray(_React$useState13, 2),
      batch = _React$useState14[0],
      setBatch = _React$useState14[1];

  var _React$useState15 = React.useState(false),
      _React$useState16 = _slicedToArray(_React$useState15, 2),
      isDatePickerBbdModalOpen = _React$useState16[0],
      setIsDatePickerBbdModalOpen = _React$useState16[1];

  var _React$useState17 = React.useState(""),
      _React$useState18 = _slicedToArray(_React$useState17, 2),
      bbd = _React$useState18[0],
      setBbd = _React$useState18[1];

  var _React$useState19 = React.useState(data.warehouseList[0]),
      _React$useState20 = _slicedToArray(_React$useState19, 2),
      selectedWarehouseList = _React$useState20[0],
      setSelectedWarehouseList = _React$useState20[1];

  var _React$useState21 = React.useState(data.binLocationList[0]),
      _React$useState22 = _slicedToArray(_React$useState21, 2),
      selectedBinLocationList = _React$useState22[0],
      setSelectedBinLocationList = _React$useState22[1];

  var _React$useState23 = React.useState(false),
      _React$useState24 = _slicedToArray(_React$useState23, 2),
      isLessQuantityNotAllowedModalOpen = _React$useState24[0],
      setIsLessQuantityNotAllowedModalOpen = _React$useState24[1];

  var _React$useState25 = React.useState(false),
      _React$useState26 = _slicedToArray(_React$useState25, 2),
      isOverbookingNotAllowedModalOpen = _React$useState26[0],
      setIsOverbookingNotAllowedModalOpen = _React$useState26[1];

  var _React$useState27 = React.useState(false),
      _React$useState28 = _slicedToArray(_React$useState27, 2),
      isLessQuantityAllowedWithCheckModalOpen = _React$useState28[0],
      setIsLessQuantityAllowedWithCheckModalOpen = _React$useState28[1];

  var _React$useState29 = React.useState(false),
      _React$useState30 = _slicedToArray(_React$useState29, 2),
      isOverbookingAllowedWithCheckModalOpen = _React$useState30[0],
      setIsOverbookingAllowedWithCheckModalOpen = _React$useState30[1];

  var _React$useState31 = React.useState(false),
      _React$useState32 = _slicedToArray(_React$useState31, 2),
      isConfirmed = _React$useState32[0],
      setIsConfirmed = _React$useState32[1];

  var selectWarehouseList = function selectWarehouseList(data) {
    setSelectedWarehouseList(data);

    if (data.type === "whBinLocation") {
      setIsListBinLocationModalOpen(true);
    }
  };

  var selectBinLocationList = function selectBinLocationList(data) {
    setSelectedBinLocationList(data);
  };

  React.useEffect(function () {
    if (parseInt(quantity) < positionDetailData.open) {
      if (allowedTypes.includes("lessQuantityAllowedWithCheck")) {
        setIsLessQuantityAllowedWithCheckModalOpen(true);
      } else if (allowedTypes.includes("lessQuantityAllowed")) {
        setIsConfirmed(true);
      } else {
        setIsLessQuantityNotAllowedModalOpen(true);
        setIsConfirmed(false);
      }
    } else if (parseInt(quantity) > positionDetailData.open) {
      if (allowedTypes.includes("overbookingAllowedWithCheck")) {
        setIsOverbookingAllowedWithCheckModalOpen(true);
      } else if (allowedTypes.includes("overbookingAllowed")) {
        setIsConfirmed(true);
      } else {
        setIsOverbookingNotAllowedModalOpen(true);
        setIsConfirmed(false);
      }
    }

    setPositionDetailData(_objectSpread(_objectSpread({}, positionDetailData), {}, {
      coveredQuantity: parseInt(quantity) ? parseInt(quantity) : 0
    }));
  }, [quantity]); // Close Dialog On Option Change

  React.useEffect(function () {
    setIsListWarehouseModalOpen(false);
    setIsListBinLocationModalOpen(false);
    setIsKeyboardQuantityModalOpen(false);
    setIsLessQuantityNotAllowedModalOpen(false);
    setIsOverbookingNotAllowedModalOpen(false);
    setIsLessQuantityAllowedWithCheckModalOpen(false);
    setIsOverbookingAllowedWithCheckModalOpen(false);
    setIsKeyboardBatchModalOpen(false);
    setIsDatePickerBbdModalOpen(false);
    setPositionDetailData(_objectSpread(_objectSpread({}, positionDetailData), {}, {
      coveredQuantity: 0
    }));
    setQuantity("");
  }, [allowedTypes, notAllowScanGS1128]);
  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "smallWithSub",
    showScanner: true,
    previewHeight: previewHeight
  }, React.createElement("p", null, headerTitle), React.createElement("sub", null, subtitle, " - ", React.createElement("b", null, "2334")))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentTopRadius,
    style: {
      height: previewHeight - 90 - 50 + 10
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "Position / item",
      rightContent: positionDetailData.position + " / " + positionDetailData.item
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "Description",
      rightContent: positionDetailData.description
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "".concat(orderOrPlan, " / Open"),
      rightContent: positionDetailData.ordered + " / " + positionDetailData.open + " " + positionDetailData.orderedOpenType
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "Covered Quantity",
      rightContent: positionDetailData.coveredQuantity + " " + positionDetailData.coveredQuantityType
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.greyContent,
    style: {
      height: previewHeight - 90 - 50 - 160 + 10
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, selectedWarehouseList.type === "whBinLocation" ? React.createElement(WhiteContentBarButton, {
    data: {
      leftContent: "Enter Bin Location *",
      leftContentWithRightContent: "Bin Location",
      rightContent: selectedBinLocationList.name
    },
    onClick: function onClick() {
      setIsListWarehouseModalOpen(!isListWarehouseModalOpen);
    }
  }) : React.createElement(WhiteContentBarButton, {
    data: {
      leftContent: "Enter Warehouse *",
      leftContentWithRightContent: "Warehouse",
      rightContent: selectedWarehouseList.name
    },
    onClick: function onClick() {
      setIsListWarehouseModalOpen(!isListWarehouseModalOpen);
    }
  })), notAllowScanGS1128 && React.createElement(React.Fragment, null, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBarButton, {
    data: {
      leftContent: "Enter Batch *",
      leftContentWithRightContent: "Batch",
      rightContent: batch
    },
    onClick: function onClick() {
      setIsKeyboardBatchModalOpen(!isKeyboardBatchModalOpen);
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBarButton, {
    data: {
      leftContent: "Enter BBD *",
      leftContentWithRightContent: "BBD",
      rightContent: bbd
    },
    onClick: function onClick() {
      setIsDatePickerBbdModalOpen(!isDatePickerBbdModalOpen);
    }
  }))), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBarButton, {
    data: {
      leftContent: "Enter Quantity *",
      leftContentWithRightContent: "Quantity",
      rightContent: quantity
    },
    onClick: function onClick() {
      setIsKeyboardQuantityModalOpen(!isKeyboardQuantityModalOpen);
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12
  }, selectedWarehouseList.type === "whBinLocation" && React.createElement(BlueLink, null, "Additional Bin Locations")))))), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "left"
  }, "Cancel")), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "right",
    disabled: !isConfirmed
  }, "Next"))), React.createElement(ListModal, {
    title: "Choose Warehouse",
    isModalOpen: isListWarehouseModalOpen,
    setOpenModalState: setIsListWarehouseModalOpen,
    data: data.warehouseList,
    initialSelected: selectedWarehouseList,
    setInitialSelected: selectWarehouseList
  }), React.createElement(ListModal, {
    title: "Choose First Bin Location",
    isModalOpen: isListBinLocationModalOpen,
    setOpenModalState: setIsListBinLocationModalOpen,
    data: data.binLocationList,
    initialSelected: selectedBinLocationList,
    setInitialSelected: selectBinLocationList
  }), React.createElement(KeyboardNumberModal, {
    title: "Quantity",
    initialValue: quantity,
    isModalOpen: isKeyboardQuantityModalOpen,
    setInitialValue: setQuantity,
    setOpenModalState: setIsKeyboardQuantityModalOpen
  }), React.createElement(KeyboardCompleteModal, {
    title: "Batch",
    initialValue: batch,
    isModalOpen: isKeyboardBatchModalOpen,
    setInitialValue: setBatch,
    setOpenModalState: setIsKeyboardBatchModalOpen
  }), React.createElement(DatePickerModal, {
    isModalOpen: isDatePickerBbdModalOpen,
    initialValue: bbd,
    setInitialValue: setBbd,
    setOpenModalState: setIsDatePickerBbdModalOpen
  }), React.createElement(WarningModal, {
    modalType: "singleOption",
    data: {
      message: "Less quantity is not allowed"
    },
    isModalOpen: isLessQuantityNotAllowedModalOpen,
    onClickRedButton: function onClickRedButton() {
      setIsLessQuantityNotAllowedModalOpen(false);
      setQuantity("");
    }
  }), React.createElement(WarningModal, {
    modalType: "singleOption",
    data: {
      message: "Overbooking is not allowed"
    },
    isModalOpen: isOverbookingNotAllowedModalOpen,
    onClickRedButton: function onClickRedButton() {
      setIsOverbookingNotAllowedModalOpen(false);
      setQuantity("");
    }
  }), React.createElement(WarningModal, {
    modalType: "doubleOption",
    data: {
      message: "The covered quantity is less than the open quantity. Do you want to continue?"
    },
    isModalOpen: isLessQuantityAllowedWithCheckModalOpen,
    onClickRedButton: function onClickRedButton() {
      setIsLessQuantityAllowedWithCheckModalOpen(false);
      setIsConfirmed(true);
    },
    onClickGreenButton: function onClickGreenButton() {
      setIsLessQuantityAllowedWithCheckModalOpen(false);
      setQuantity("");
      setIsConfirmed(false);
    }
  }), React.createElement(WarningModal, {
    modalType: "doubleOption",
    data: {
      message: "The covered quantity is higher than the open quantity. Do you want to continue?"
    },
    isModalOpen: isOverbookingAllowedWithCheckModalOpen,
    onClickRedButton: function onClickRedButton() {
      setIsOverbookingAllowedWithCheckModalOpen(false);
      setIsConfirmed(true);
    },
    onClickGreenButton: function onClickGreenButton() {
      setIsOverbookingAllowedWithCheckModalOpen(false);
      setQuantity("");
      setIsConfirmed(false);
    }
  }));
};
OrderPositionDetailsPage.defaultProps = {
  allowScanGS1128: false,
  subtitle: "Business Tools AG",
  orderOrPlan: "Ordered"
};
export default React.memo(OrderPositionDetailsPage);