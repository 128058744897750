// React Lib
import React from "react"; // Component Styles

import { useStyles } from "./ChipStyles"; // @otg-one/ui_components/components

import { Typography } from "../index"; // @otg-one/ui_components/constants

import { Color, FONT_FAMILY } from "../../constants";
import { ProductionSystem, StagedSystem } from "../../icons";
export var Chip = function Chip(props) {
  var classes = useStyles(props);
  var id = props.id,
      icon = props.icon,
      color = props.color,
      children = props.children,
      onClick = props.onClick,
      style = props.style;
  var iconUsed = null;

  if (!icon) {
    if (children === 'production' || children === 'PRODUCTION') {
      iconUsed = React.createElement(ProductionSystem, {
        style: {
          color: Color.primary[300]
        }
      });
    } else if (children === 'test' || children === 'TEST') {
      iconUsed = React.createElement(StagedSystem, {
        style: {
          color: Color.neutral[0]
        }
      });
    }
  } else {
    iconUsed = React.createElement(props.icon, {
      style: {
        color: color
      }
    });
  }

  return React.createElement("div", {
    id: id,
    className: classes.chipContainer,
    style: style,
    onClick: onClick
  }, iconUsed, React.createElement(Typography, {
    id: "".concat(id, "-chip-text"),
    fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
    size: 12,
    color: color
  }, children.toUpperCase()));
};
Chip.defaultProps = {
  children: "",
  backgroundColor: Color.primary[400],
  color: Color.neutral[0],
  icon: null,
  onClick: function onClick() {}
};
export default React.memo(Chip);