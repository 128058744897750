// React lib
import React, { useState, useContext } from 'react';

import { useStyles } from './SettingsStyle';

// Material-UI Core
import Grid from '@material-ui/core/Grid';

// Components
import {
    Button,
    Typography,
    FONT_FAMILY,
    Dropdown,
    BreadcrumbType,
    DialogBox,
    Color,
    ArrowDown,
    Separator
} from '@otg-one/ui_components';
import { AppContext } from '../../App';
import { Stack } from '../../Models/Stack';
import { ORGANIZATION, BILLING, ACCOUNT_SETTINGS, CHANGE_PASSWORD } from '../../Constants/FilePath';
// import { STACK_TYPE_ORGANIZATION, STACK_TYPE_BILLING } from '../../Constants/StackType';

// const DROPDOWN_MENU = [
//     {
//         text: "English",
//         onClick: () => { },
//     },
//     {
//         text: "German",
//         onClick: () => { },
//     },
// ]

const MegaDropdownButton = (props: any) => {
    const classes = useStyles(props)

    const { children, onMouseOver, onMouseOut, onClick } = props

    return (
        <div
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onClick={onClick}
            style={{ cursor: "pointer" }}
        >
            <Typography
                fontFamily={FONT_FAMILY.ROBOTO_LIGHT}
                color={Color.neutral[900]}
                size={14}
                className={classes.megaDropdownButton}
            >
                {children}
            </Typography>
        </div>
    )
}


export const MegaDropdownSettings = (props: any) => {
    const classes = useStyles(props)
    const {
        handleChangeBreadcrumb,
        handleCloseNavbar
    } = useContext(AppContext);

    const [isOrganizationButtonHovered, setIsOrganizationButtonHovered] = useState(false)
    const [isBillingButtonHovered, setIsBillingButtonHovered] = useState(false)
    const [isAccountButtonHovered, setIsAccountButtonHovered] = useState(false)

    const handleChangeOrganizationButtonOnMouseOver = () => { setIsOrganizationButtonHovered(true) }
    const handleChangeOrganizationButtonOnMouseOut = () => { setIsOrganizationButtonHovered(false) }

    const handleChangeBillingButtonOnMouseOver = () => { setIsBillingButtonHovered(true) }
    const handleChangeBillingButtonOnMouseOut = () => { setIsBillingButtonHovered(false) }

    const handleChangeAccountButtonOnMouseOver = () => { setIsAccountButtonHovered(true) }
    const handleChangeAccountButtonOnMouseOut = () => { setIsAccountButtonHovered(false) }

    const { handleOpenScenarioInstallationDialog, newScenarioAvailable } = props

    const handleOrganizationDetailClick = () => {
        handleCloseNavbar();
        handleChangeBreadcrumb(BreadcrumbType.ORGANIZATION);
    };

    const handleBillingDetailClick = () => {
        handleCloseNavbar();
        handleChangeBreadcrumb(BreadcrumbType.BILLING_DETAIL);
    };

    const handleBillingHistoryClick = () => {
        handleCloseNavbar();
        handleChangeBreadcrumb(BreadcrumbType.BILLING_HISTORY);
    };

    const handleAccountSettingsClick = () => {
        handleCloseNavbar();
        handleChangeBreadcrumb(BreadcrumbType.ACCOUNT_SETTINGS);
    };

    const handleChangePasswordClick = () => {
        handleCloseNavbar();
        handleChangeBreadcrumb(BreadcrumbType.CHANGE_PASSWORD);
    };

    let badgeNew = <div></div>;
    if (newScenarioAvailable) {
        badgeNew = <div style={{ position: "absolute", bottom: 48, right: -10, outline: "none", backgroundColor: "#ffdf00", zIndex: 2, borderRadius: "50%", padding: 5, fontSize: 12 }}>{'New'}</div>;
    }

    return (
        <>
            <Grid className={classes.gridViewSetting} container style={{ flexGrow: 1, position: "relative", zIndex: 1000 }} spacing={2}>
                <Grid container item md={12} justify="flex-start">
                    <Grid item md={3} />
                    <Grid item md={2}>
                        <Typography
                            fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                            size={20}
                            style={{
                                width: "fit-content",
                                marginBottom: 9,
                                borderBottom: isOrganizationButtonHovered ? `2px solid ${Color.primary[600]}` : `2px solid transparent`
                            }}
                        >
                            {`Organization`}
                        </Typography>
                        <MegaDropdownButton
                            onMouseOver={handleChangeOrganizationButtonOnMouseOver}
                            onMouseOut={handleChangeOrganizationButtonOnMouseOut}
                            onClick={handleOrganizationDetailClick}
                        >
                            {`Organization Detail`}
                        </MegaDropdownButton>
                    </Grid>
                    <Grid item md={2}>
                        <Typography
                            fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                            size={20}
                            style={{
                                width: "fit-content",
                                marginBottom: 9,
                                borderBottom: isBillingButtonHovered ? `2px solid ${Color.primary[600]}` : `2px solid transparent`
                            }}
                        >
                            {`Billing`}
                        </Typography>
                        <MegaDropdownButton
                            onMouseOver={handleChangeBillingButtonOnMouseOver}
                            onMouseOut={handleChangeBillingButtonOnMouseOut}
                            onClick={handleBillingDetailClick}
                        >
                            {`Billing Detail`}
                        </MegaDropdownButton>
                        <MegaDropdownButton
                            onMouseOver={handleChangeBillingButtonOnMouseOver}
                            onMouseOut={handleChangeBillingButtonOnMouseOut}
                            onClick={handleBillingHistoryClick}
                        >
                            {`Billing History`}
                        </MegaDropdownButton>
                    </Grid>
                    <Grid item md={2}>
                        <Typography
                            fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                            size={20}
                            style={{
                                width: "fit-content",
                                marginBottom: 9,
                                borderBottom: isAccountButtonHovered ? `2px solid ${Color.primary[600]}` : `2px solid transparent`
                            }}
                        >
                            {`Account`}
                        </Typography>
                        <MegaDropdownButton
                            onMouseOver={handleChangeAccountButtonOnMouseOver}
                            onMouseOut={handleChangeAccountButtonOnMouseOut}
                            onClick={handleAccountSettingsClick}
                        >
                            {`Account Settings`}
                        </MegaDropdownButton>
                        <MegaDropdownButton
                            onMouseOver={handleChangeAccountButtonOnMouseOver}
                            onMouseOut={handleChangeAccountButtonOnMouseOut}
                            onClick={handleChangePasswordClick}
                        >
                            {`Change Password`}
                        </MegaDropdownButton>
                    </Grid>
                </Grid>
                <div style={{ width: '100%', position: "absolute", bottom: 20, right: 7, display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={handleOpenScenarioInstallationDialog} style={{ position: "absolute", bottom: 20, outline: "none", zIndex: 1 }}>
                        {`Scenario Installation Guide`}
                    </Button>
                    {badgeNew}
                </div>
                {/* <Grid container item md={3}>
                    <Grid item md={12}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={20}
                                style={{ display: "block", marginRight: 190 }}
                            >
                                {`Language`}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item md={12}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>

                            <Dropdown
                                menu={DROPDOWN_MENU}
                                type="SELECTION"
                            />
                        </div>
                    </Grid>
                </Grid> */}
            </Grid>

            <div className={classes.collapseableSetting}>
                <div className={classes.separator} >
                    <Separator type="n400" width={1} />
                </div>

                <div
                    className={classes.settingDrawerChild}
                >
                    <div className={classes.settingDrawerSubtitle}>
                        {`Organization`}
                    </div>
                    <div
                        onClick={handleOrganizationDetailClick}
                        className={classes.settingDrawerSubContent}
                    >
                        {`Organization Detail`}
                    </div>
                </div>

                <div className={classes.separator} >
                    <Separator type="n200" width={1} />
                </div>

                <div
                    className={classes.settingDrawerChild}
                >
                    <div className={classes.settingDrawerSubtitle}>
                        {`Billing`}
                    </div>
                    <div
                        onClick={handleBillingDetailClick}
                        className={classes.settingDrawerSubContent}
                    >
                        {`Billing Detail`}
                    </div>
                    <div
                        onClick={handleBillingHistoryClick}
                        className={classes.settingDrawerSubContent}
                    >
                        {`Billing History`}
                    </div>
                </div>

                <div className={classes.separator} >
                    <Separator type="n200" width={1} />
                </div>

                <div
                    className={classes.settingDrawerChild}
                >
                    <div className={classes.settingDrawerSubtitle}>
                        {`Account`}
                    </div>
                    <div
                        onClick={handleAccountSettingsClick}
                        className={classes.settingDrawerSubContent}
                    >
                        {`Account Settings`}
                    </div>
                    <div
                        onClick={handleChangePasswordClick}
                        className={classes.settingDrawerSubContent}
                    >
                        {`Change Password`}
                    </div>
                </div>

                <div className={classes.separator} >
                    <Separator type="n200" width={1} />
                </div>

                <div
                    onClick={handleOpenScenarioInstallationDialog}
                    className={classes.drawerChild}
                >
                    {`Scenario Installation Guide`}
                </div>
            </div>
        </>
    )
}

export default React.memo(MegaDropdownSettings)