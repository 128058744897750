// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    scanContentBar: function scanContentBar(props) {
      return {
        backgroundColor: "#FFD124",
        height: "40px",
        borderBottom: "1px solid #f5f5f5",
        padding: "10px 15px 10px 15px",
        borderRadius: props.applyBorderRadius ? "5px 5px 0 0" : "",
        cursor: "pointer"
      };
    },
    content: {
      fontSize: "12px",
      color: "black",
      marginTop: "2px",
      textAlign: "left",
      textTransform: "none"
    }
  });
});