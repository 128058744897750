import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./InventoryCountingExpectedItemsStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { ScanContentBar } from "../../index";
import { Close } from "../../..";
import WarningModal from "../WarningModal";
export var InventoryCountingExpectedItems = function InventoryCountingExpectedItems(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      data = props.data,
      scanContentBarText = props.scanContentBarText,
      isListPrefilled = props.isListPrefilled,
      isConfirmNeeded = props.isConfirmNeeded;

  var _React$useState = React.useState(data),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      itemList = _React$useState2[0],
      setItemList = _React$useState2[1];

  React.useEffect(function () {
    setItemList(data);
  }, [data]);

  var removeItem = function removeItem(itemData) {
    setItemList(itemList.filter(function (e) {
      return e !== itemData;
    }));
  };

  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "smallWithSub",
    showScanner: true,
    previewHeight: previewHeight
  }, React.createElement("p", null, "Inventory Counting"), React.createElement("sub", null, "Bin Location - ", React.createElement("b", null, "05-G01-R01-P03")))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentTopRadius,
    style: {
      height: previewHeight - 90 - 40
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(ScanContentBar, {
    text: scanContentBarText
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.textInputBox
  }, React.createElement("span", null, "Enter Manually")), React.createElement(Grid, {
    container: true,
    className: classes.headerContentBar
  }, React.createElement("span", null, React.createElement("b", null, "Item")), React.createElement("span", null, React.createElement("b", null, "Counted Quantity"))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.greyContentPlain,
    style: {
      height: previewHeight - 90 - 40 - 40 - 70
    }
  }, isListPrefilled ? React.createElement(Grid, {
    container: true
  }, itemList.length > 0 && itemList.map(function (data) {
    return React.createElement(React.Fragment, null, React.createElement(Grid, {
      item: true,
      md: 12,
      className: classes.whiteContentBarWithCloseButton
    }, React.createElement(Grid, {
      className: classes.blackLeftContent,
      item: true,
      xs: 5,
      style: {
        alignSelf: "center",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }
    }, data.itemName), React.createElement(Grid, {
      className: classes.blackRightContent,
      item: true,
      xs: 5
    }, data.currentQuantity + " / " + data.expectedQuantity + " pc"), React.createElement(Grid, {
      item: true,
      xs: 2
    }, React.createElement(Close, {
      className: classes.whiteContentBarCloseButton,
      onClick: function onClick() {
        removeItem(data);
      }
    }))), React.createElement(Grid, {
      item: true,
      md: 12,
      className: classes.ContentBorder
    }));
  })) : React.createElement(Grid, {
    container: true,
    justifyContent: "center",
    className: classes.noContentBar
  }, React.createElement("span", null, React.createElement("b", null, "Please Scan Item")))))), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "left"
  }, "Cancel")), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "right"
  }, "Confirm"))), React.createElement(WarningModal, {
    modalType: "doubleOption",
    isModalOpen: isConfirmNeeded,
    data: {
      message: "Do you want to use Rainbow Fotopapier for new position?"
    }
  }));
};
InventoryCountingExpectedItems.defaultProps = {
  scanContentBarText: "Please scan Item..."
};
export default React.memo(InventoryCountingExpectedItems);