import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import * as React from 'react'; // Custom Styles

import { useStyles } from "./WhiteContentBarButtonStyles"; // Material-UI Core

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
export var WhiteContentBarButton = function WhiteContentBarButton(props) {
  var classes = useStyles(props);
  var onClick = props.onClick,
      data = props.data,
      backgroundColor = props.backgroundColor;

  var _React$useState = React.useState(data.leftContent),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      leftContent = _React$useState2[0],
      setLeftContent = _React$useState2[1];

  var _React$useState3 = React.useState(data.rightContent),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      rightContent = _React$useState4[0],
      setRightContent = _React$useState4[1];

  var tempBackgroundColor;

  if (backgroundColor === "white") {
    tempBackgroundColor = "#ffffff";
  } else {
    tempBackgroundColor = "#f5f5f5";
  }

  React.useEffect(function () {
    if (data.rightContent === "") {
      setLeftContent(data.leftContent);
    } else {
      setLeftContent(data.leftContentWithRightContent);
    }

    setRightContent(data.rightContent);
  }, [data.rightContent]);
  return React.createElement(Button, {
    onClick: onClick,
    style: {
      backgroundColor: tempBackgroundColor
    },
    className: classes.whiteContentBarButton
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    className: classes.leftContent,
    item: true,
    md: 6
  }, leftContent), React.createElement(Grid, {
    className: classes.rightContent,
    item: true,
    md: 6
  }, rightContent)));
};
WhiteContentBarButton.defaultProps = {
  backgroundColor: "white"
};
export default React.memo(WhiteContentBarButton);