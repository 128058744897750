

import axios from 'axios';

import { getApiUrl } from '../../../Constants/Api';

export class CustomerUsageService {
    getDeviceCount(userKey: string, month: any) {
        return axios.get(`${getApiUrl()}customerUsage/deviceCount/${month}?userkey=${userKey}`);
    }

    getMapCount(userKey: string) {
        return axios.get(`${getApiUrl()}customerUsage/mapCount?userkey=${userKey}`);
    }

    getDeviceCountHistory(userKey: string, year: any) {
        return axios.get(`${getApiUrl()}customerUsage/deviceCountHistory/${year}?userkey=${userKey}`);
    }

    getDeviceUsage(userKey: string, year: any, type: string) {
        return axios.get(`${getApiUrl()}customerUsage/deviceUsage/${year}/${type}?userkey=${userKey}`);
    }

    getNewRegistrations(userKey: string, year: any) {
        return axios.get(`${getApiUrl()}customerUsage/newRegistrations/${year}?userkey=${userKey}`);
    }

    ignoreDevice(userKey: string, deviceId: string) {
        const body = { deviceId }
        return axios.post(`${getApiUrl()}ignoreddevices?userkey=${userKey}`, body)
    }
}

export default new CustomerUsageService()