import React, { useState, useContext } from 'react';
import { AppContext } from '../../App';

// Material-UI Core
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

// Styles
import { useStyles } from './OrganizationStyles';
import { Breadcrumb, Button, Form, Typography, FONT_FAMILY, Color, Separator, Plus, UserAccount, BreadcrumbType } from '@otg-one/ui_components';
import { Stack } from '../../Models/Stack';
import { CREATENEWUSER } from '../../Constants/FilePath';
import DeleteUser from './DeleteUser';
import PartnerService from '../Dashboard/Partner/PartnerService';

const USER_ACCOUNT = [
    {
        img: "",
        name: "Jesse Young",
        email: "jesse.young@commsult.id",
        status: ""
    },
    {
        img: "",
        name: "Leonardo Kurnia",
        email: "leonardo.kurnia@commsult.id",
        status: "ADMIN"
    },
    {
        img: "",
        name: "Jordi Bernandi",
        email: "jordi.bernandi@commsult.id",
        status: "ADMIN"
    },
    {
        img: "",
        name: "Mohammed Elfaza",
        email: "mohammed.elfaza@commsult.id",
        status: "ADMIN"
    }
]

const OrganizationDetail = (props: any) => {
    const {
        currentUserData
    } = useContext(AppContext);

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [company, setCompany] = useState(currentUserData.companyName)
    const [contactName, setContactName] = useState(currentUserData.companyContactName)
    const [contactMail, setContactMail] = useState(currentUserData.companyContactMail)
    const [contactPhone, setContactPhone] = useState(currentUserData.companyContactPhone)
    const [contactAddress, setContactAddress] = useState(currentUserData.companyContactAddress)
    const [message, setMessage] = useState("")

    const [errorCompany, setErrorCompany] = useState(false)
    const [errorContactName, setErrorContactName] = useState(false)
    const [errorContactMail, setErrorContactMail] = useState(false)
    const [errorMsgCompany, setErrorMsgCompany] = useState("")
    const [errorMsgContactName, setErrorMsgContactName] = useState("")
    const [errorMsgContactMail, setErrorMsgContactMail] = useState("")

    const handleChangeCompanyName = (e: React.FormEvent<HTMLInputElement>) => {
        setErrorCompany(false);
        setErrorMsgCompany("");
        setCompany(e.currentTarget.value);
    };

    const handleChangeContactMail = (e: React.FormEvent<HTMLInputElement>) => {
        setErrorContactMail(false);
        setErrorMsgContactMail("");
        setContactMail(e.currentTarget.value);
    };

    const handleChangeContactName = (e: React.FormEvent<HTMLInputElement>) => {
        setErrorContactName(false);
        setErrorMsgContactName("");
        setContactName(e.currentTarget.value);
    };

    const handleChangeContactPhone = (e: React.FormEvent<HTMLInputElement>) => {
        setContactPhone(e.currentTarget.value);
    };

    const handleChangeContactAddress = (e: React.FormEvent<HTMLInputElement>) => {
        setContactAddress(e.currentTarget.value);
    };

    const handleUpdateOrganization = () => {
        let error = false;
        if (contactName.length < 1) {
            setErrorContactName(true);
            setErrorMsgContactName("Please enter a contact name!");
            error = true;
        }
        if (company.length < 1) {
            setErrorCompany(true);
            setErrorMsgCompany("Please enter a company name!");
            error = true;
        }
        if (contactMail.length < 1) {
            setErrorContactMail(true);
            setErrorMsgContactMail("Please enter a contact email address!");
            error = true;
        } else if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(contactMail))) {
            setErrorContactMail(true);
            setErrorMsgContactMail("Please enter a valid email address!");
            error = true;
        }
        if (error) {
            setMessage("Please check your entered values!");
            return;
        }

        PartnerService.updatePartner(currentUserData, company, contactName, contactMail, contactPhone, contactAddress).then((res: any) => {
            if (res.status === 200) {
                currentUserData.companyName = company;
                currentUserData.companyContactName = contactName;
                currentUserData.companyContactMail = contactMail;
                currentUserData.companyContactPhone = contactPhone;
                currentUserData.companyContactAddress = contactAddress;
                setMessage("Organisation details successfully updated!");
            } else {
                setMessage("Something went wrong when updating organization details!");
            }
        });
    };

    return (
        <>
            <Grid item md={12} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                    size={24}
                    color={Color.neutral[900]}
                    style={{ marginBottom: 20 }}
                >
                    Organization Detail
                </Typography>
            </Grid>

            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    Organization Name
                </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
                <Form
                    style={{ maxWidth: 375 }}
                    placeholder="Organization Name"
                    required
                    value={company}
                    onChange={handleChangeCompanyName}
                    error={errorCompany}
                    errorMessage={errorMsgCompany}
                />
            </Grid>

            <Grid item md={12} xs={12}>
                <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
            </Grid>

            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    Contact Name
                </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
                <Form
                    style={{ maxWidth: 375 }}
                    placeholder="Contact Name"
                    required
                    value={contactName}
                    onChange={handleChangeContactName}
                    error={errorContactName}
                    errorMessage={errorMsgContactName}
                />
            </Grid>
            <Grid item md={12} xs={12}>
                <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
            </Grid>

            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    Email
                </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
                <Form
                    style={{ maxWidth: 375 }}
                    placeholder="Email"
                    required
                    value={contactMail}
                    onChange={handleChangeContactMail}
                    error={errorContactMail}
                    errorMessage={errorMsgContactMail}
                />
            </Grid>
            <Grid item md={12} xs={12}>
                <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
            </Grid>

            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    Phone
                </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
                <Form
                    style={{ maxWidth: 375 }}
                    placeholder="Phone"
                    value={contactPhone}
                    onChange={handleChangeContactPhone}
                />
            </Grid>
            <Grid item md={12} xs={12}>
                <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
            </Grid>

            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    Address
                </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
                <Form
                    style={{ maxWidth: 375 }}
                    placeholder="Address"
                    multiline
                    rows={4}
                    value={contactAddress}
                    onChange={handleChangeContactAddress}
                />
                <div>
                    <Button
                        style={{
                            marginTop: 50,
                            width: isMobile ? '-webkit-fill-available' : 'auto',
                            justifyContent: isMobile ? 'center' : 'flex-start'
                        }}
                        onClick={handleUpdateOrganization}
                    >
                        Save Changes
                    </Button>
                </div>
            </Grid>

            <Grid item md={12} xs={12}>
                <div
                    style={{
                        boxSizing: "border-box",
                        padding: 10,
                        fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
                        color: message === "Organisation details successfully updated!" ? Color.success[500] : Color.danger[300],
                        fontSize: 16,
                        textAlign: "center"
                    }}
                >
                    {message}
                </div>
            </Grid>
        </>
    )
}

export const Organization = (props: any) => {
    const classes = useStyles(props);

    return (
        <>
            <div className={classes.dashboardContainer}>
                <Grid container style={{ flexGrow: 1 }} spacing={2}>
                    <OrganizationDetail />
                </Grid>
            </div>
        </>
    )
}

export default Organization