import _toConsumableArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./SerialNumberTypeStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { WhiteContentBar } from "../../index";
import { WhiteContentBarButton } from "../../index";
import { KeyboardCompleteModal } from "../../index";
import { WarningModal } from "../../index"; // Icon

import Close from "../../../icons/Close/Close";
export var SerialNumberType = function SerialNumberType(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      data = props.data,
      totalSerialNumbers = props.totalSerialNumbers,
      value = props.value;

  var _React$useState = React.useState(data),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      serialNumberList = _React$useState2[0],
      setSerialNumberList = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      isKeyboardOpen = _React$useState4[0],
      setIsKeyboardOpen = _React$useState4[1];

  var _React$useState5 = React.useState(""),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      serialNumber = _React$useState6[0],
      setSerialNumber = _React$useState6[1];

  var _React$useState7 = React.useState(0),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      totalQuantity = _React$useState8[0],
      setTotalQuantity = _React$useState8[1];

  var _React$useState9 = React.useState(false),
      _React$useState10 = _slicedToArray(_React$useState9, 2),
      isMaxSerialNumberModalOpen = _React$useState10[0],
      setIsMaxSerialNumberModalOpen = _React$useState10[1];

  var _React$useState11 = React.useState("Serial Number"),
      _React$useState12 = _slicedToArray(_React$useState11, 2),
      rightContent = _React$useState12[0],
      setRightContent = _React$useState12[1];

  var addNewSerialNumber = function addNewSerialNumber() {
    var data = {
      serialNumber: serialNumber
    };

    if (serialNumberList.length < totalSerialNumbers) {
      setSerialNumberList([].concat(_toConsumableArray(serialNumberList), [data]));
    } else {
      setIsMaxSerialNumberModalOpen(true);
    }

    setSerialNumber("");
  };

  var removeSerialNumber = function removeSerialNumber(serialNumber) {
    setSerialNumberList(serialNumberList.filter(function (e) {
      return e !== serialNumber;
    }));
  };

  React.useEffect(function () {
    setTotalQuantity(serialNumberList.length);
  }, [serialNumberList]);
  React.useEffect(function () {
    if (serialNumber) {
      addNewSerialNumber();
    }
  }, [serialNumber]);
  React.useEffect(function () {
    if (value === "SER") {
      setRightContent("Serial Number");
    } //value === "MNF"
    else {
      setRightContent("Manufacturer Serial Number");
    }
  }, [value]);
  var whiteContentBarData = {
    leftContent: totalQuantity + " of " + totalSerialNumbers + " added",
    rightContent: ""
  };
  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "smallWithSub",
    showScanner: true,
    previewHeight: previewHeight
  }, React.createElement("p", null, "Add Serial Numbers"), React.createElement("sub", null, "Item ", React.createElement("b", null, "S20000 - Server Point 10000")))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentTopRadius,
    style: {
      height: previewHeight - 90 - 50 + 10
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: whiteContentBarData
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.greyContentPlain,
    style: {
      height: previewHeight - 90 - 40 - 35 - 155 + 75
    }
  }, React.createElement(Grid, {
    container: true
  }, serialNumberList.length > 0 ? serialNumberList.map(function (data) {
    return React.createElement(Grid, {
      item: true,
      md: 12,
      className: classes.whiteContentBarWithCloseButton
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.blackLeftContent,
      item: true,
      xs: 3,
      style: {
        alignSelf: "center",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }
    }, data.serialNumber), React.createElement(Grid, {
      className: classes.blackLeftContent,
      item: true,
      xs: 8
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      item: true,
      xs: 12,
      style: {
        color: "red"
      }
    }, rightContent)))), React.createElement(Close, {
      className: classes.whiteContentBarCloseButton,
      onClick: function onClick() {
        removeSerialNumber(data);
      }
    }));
  }) : React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentBarEmptyList
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    className: classes.greyCenterContent,
    item: true,
    md: 12
  }, React.createElement("p", null, "No Serial Number added")))))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.greyContent
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBarButton, {
    data: {
      leftContent: "Enter Serial Number *",
      leftContentWithRightContent: "Serial Number",
      rightContent: serialNumber
    },
    onClick: function onClick() {
      setIsKeyboardOpen(!isKeyboardOpen);
    }
  })))))), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "left"
  }, "Cancel")), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "right",
    disabled: serialNumberList.length === 0
  }, "Next"))), React.createElement(KeyboardCompleteModal, {
    title: "Serial Number",
    initialValue: serialNumber,
    isModalOpen: isKeyboardOpen,
    setInitialValue: setSerialNumber,
    setOpenModalState: setIsKeyboardOpen
  }), React.createElement(WarningModal, {
    modalType: "singleOption",
    data: {
      message: "Only ".concat(totalSerialNumbers, " serial numbers can be added")
    },
    isModalOpen: isMaxSerialNumberModalOpen,
    onClickRedButton: function onClickRedButton() {
      setIsMaxSerialNumberModalOpen(false);
    }
  }));
};
export default React.memo(SerialNumberType);