// React libraries
import React from 'react'; // Component Styles

import { useStyles } from "./BannerStyles"; // Material-UI Core

import Typography from "@material-ui/core/Typography";
import { Alert } from '../../icons';
export var Banner = function Banner(props) {
  var classes = useStyles(props);
  var width = props.width,
      maxWidth = props.maxWidth,
      maxHeight = props.maxHeight,
      children = props.children;
  return React.createElement("div", {
    className: classes.container,
    style: {
      width: width,
      maxWidth: maxWidth,
      maxHeight: maxHeight
    }
  }, React.createElement(Alert, {
    className: classes.icon
  }), React.createElement(Typography, {
    className: classes.typography
  }, children));
};
Banner.defaultProps = {
  maxWidth: "none",
  maxHeight: "none"
};