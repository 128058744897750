import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React Lib
import React, { useState } from "react"; // Component Styles

import { useStyles } from "./ClientStyles"; // @otg-one/ui_components/components

import { Typography, Tooltip, OverflowMenu } from "../index"; // @otg-one/ui_components/icons

import { StarOutlined, StarFilled, ShowMoreHorizontal, StagedSystem, ProductionSystem, Delete, AvatarCompany, ArrowUp } from "../../icons"; // @otg-one/ui_components/constants

import { Color, FONT_FAMILY } from "../../constants"; // Material UI Core

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Grid from '@material-ui/core/Grid'; // Utils

import { formatNumericalWithThousandSeparator } from "../../utils/Utils";
var headCells = [{
  id: 'favourite',
  numeric: false,
  sortable: true,
  disablePadding: true,
  label: "Favourite"
}, {
  id: 'avatar',
  numeric: false,
  sortable: false,
  disablePadding: true,
  label: "Avatar"
}, {
  id: 'name',
  numeric: false,
  sortable: true,
  disablePadding: false,
  label: "Client's Name"
}, {
  id: 'cost',
  numeric: true,
  sortable: true,
  disablePadding: false,
  label: "This Month's Bill"
}, {
  id: 'totalTestSystem',
  numeric: true,
  sortable: true,
  disablePadding: false,
  label: "Test System"
}, {
  id: 'totalProductionSystem',
  numeric: true,
  sortable: true,
  disablePadding: false,
  label: "Production System"
}, {
  id: 'moreAction',
  numeric: true,
  sortable: false,
  disablePadding: false,
  label: "More Action"
}];

var CustomTableHead = function CustomTableHead(props) {
  var classes = useStyles(props);
  var order = props.order,
      setOrder = props.setOrder,
      orderBy = props.orderBy,
      setOrderBy = props.setOrderBy;

  var onRequestSort = function onRequestSort(event, property) {
    var isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  var createSortHandler = function createSortHandler(property) {
    return function (event) {
      onRequestSort(event, property);
    };
  };

  return React.createElement(TableHead, null, React.createElement(TableRow, {
    style: {
      borderBottom: "2px solid ".concat(Color.neutral[300])
    }
  }, headCells.map(function (headCell) {
    return React.createElement(TableCell, {
      key: headCell.id,
      align: headCell.numeric ? 'right' : 'left',
      sortDirection: orderBy === headCell.id ? order : false,
      style: {
        fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
        color: Color.neutral[600]
      }
    }, headCell.sortable ? React.createElement(TableSortLabel, {
      active: orderBy === headCell.id,
      direction: orderBy === headCell.id ? order : 'asc',
      onClick: headCell.sortable && createSortHandler(headCell.id),
      IconComponent: ArrowUp
    }, headCell.label, orderBy === headCell.id ? React.createElement("span", {
      className: classes.visuallyHidden
    }, order === 'desc' ? 'sorted descending' : 'sorted ascending') : null) : React.createElement(React.Fragment, null, headCell.label));
  })));
};

var ClientListHeader = function ClientListHeader(props) {
  var classes = useStyles(props);
  var id = props.id,
      data = props.data,
      order = props.order,
      setOrder = props.setOrder,
      orderBy = props.orderBy,
      setOrderBy = props.setOrderBy,
      children = props.children;
  return React.createElement("div", {
    className: classes.root
  }, React.createElement(TableContainer, null, React.createElement(Table, {
    className: classes.table
  }, React.createElement(CustomTableHead, {
    classes: classes,
    order: order,
    setOrder: setOrder,
    orderBy: orderBy,
    setOrderBy: setOrderBy,
    rowCount: data.length
  }), React.createElement(TableBody, null, children))));
};

var ClientView = function ClientView(props) {
  var classes = useStyles(props);
  var id = props.id,
      clientView = props.clientView,
      data = props.data,
      onClick = props.onClick,
      setActiveClient = props.setActiveClient,
      setDeleteClientDialogOpen = props.setDeleteClientDialogOpen,
      handleUpdateClient = props.handleUpdateClient;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isHover = _useState2[0],
      setIsHover = _useState2[1];

  var _useState3 = useState(null),
      _useState4 = _slicedToArray(_useState3, 2),
      anchorEl = _useState4[0],
      setAnchorEl = _useState4[1];

  var handleClickShowMoreIcon = function handleClickShowMoreIcon(event) {
    event.stopPropagation();

    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }

    ;
  };

  var handleOnMouseOver = function handleOnMouseOver() {
    setIsHover(true);
  };

  var handleOnMouseOut = function handleOnMouseOut() {
    setIsHover(false);
  };

  var handleFavouriteClick = function handleFavouriteClick() {
    data.isFavourite = data.isFavourite === 0 ? 1 : 0;
    handleUpdateClient(data);
  };

  var isTest = data.isTest === 1;
  return React.createElement(React.Fragment, null, clientView === "GRID" ? React.createElement("div", {
    id: "".concat(id, "-container"),
    style: {
      position: "relative"
    }
  }, React.createElement("div", {
    id: id,
    className: classes.clientBoxContainer
  }, React.createElement("div", {
    id: "".concat(id, "-client-box"),
    className: classes.clientBox,
    style: {
      backgroundColor: isTest ? isHover ? Color.neutral[500] : Color.neutral[600] : isHover ? Color.primary[400] : "transparent",
      border: isTest ? isHover ? "4px solid ".concat(Color.neutral[500]) : "4px solid ".concat(Color.neutral[600]) : isHover ? "4px solid ".concat(Color.primary[400]) : "4px solid ".concat(Color.primary[900]),
      color: !isTest && isHover && "".concat(Color.neutral[0], " !important")
    },
    onMouseOver: handleOnMouseOver,
    onMouseOut: handleOnMouseOut,
    onClick: onClick
  }, React.createElement("div", {
    id: "".concat(id, "-client-logo"),
    className: classes.clientLogo
  }, data.logo ? React.createElement("img", {
    src: data.logo,
    width: "100%",
    height: "100%",
    style: {
      objectFit: 'contain',
      borderRadius: '50%'
    }
  }) : React.createElement(AvatarCompany, {
    style: {
      width: 36,
      height: 36,
      color: Color.neutral[400]
    }
  })), React.createElement(Typography, {
    id: "".concat(id, "-client-name"),
    fontFamily: FONT_FAMILY.CAPITOLIUM_BOLD,
    size: 18,
    color: isTest ? Color.neutral[0] : isHover ? Color.neutral[0] : Color.neutral[700],
    style: {
      marginTop: 21
    }
  }, data.name), React.createElement("div", {
    id: "".concat(id, "-client-box-info"),
    className: classes.clientBoxInfo
  }, React.createElement("div", {
    id: "".concat(id, "-client-box-cost")
  }, !isTest && React.createElement(Typography, {
    id: "".concat(id, "-client-cost"),
    fontFamily: FONT_FAMILY.ROBOTO_BOLD,
    size: 36,
    color: isHover ? Color.neutral[0] : Color.neutral[400]
  }, "".concat(formatNumericalWithThousandSeparator(data.cost), " \u20AC"))), React.createElement("div", {
    id: "".concat(id, "-client-box-system"),
    className: classes.clientBoxSystems
  }, React.createElement(Tooltip, {
    title: "Test System",
    placement: "right"
  }, React.createElement("div", {
    style: {
      marginTop: 2
    }
  }, React.createElement(StagedSystem, {
    style: {
      width: 24,
      height: 24,
      color: isTest ? Color.neutral[400] : isHover ? Color.neutral[0] : Color.primary[400]
    }
  }))), React.createElement(Typography, {
    id: "".concat(id, "-client-system-staged"),
    fontFamily: FONT_FAMILY.ROBOTO_BOLD,
    size: 18,
    color: isTest ? Color.neutral[400] : isHover ? Color.neutral[0] : Color.neutral[400],
    style: {
      marginLeft: 5
    }
  }, formatNumericalWithThousandSeparator(data.totalTestSystem)), !isTest && React.createElement(React.Fragment, null, React.createElement(Tooltip, {
    title: "Production System",
    placement: "right"
  }, React.createElement("div", {
    style: {
      marginTop: 2
    }
  }, React.createElement(ProductionSystem, {
    style: {
      width: 24,
      height: 24,
      color: isTest ? Color.neutral[500] : isHover ? Color.neutral[0] : Color.primary[400],
      marginLeft: 25
    }
  }))), React.createElement(Typography, {
    id: "".concat(id, "-client-system-non-staged"),
    fontFamily: FONT_FAMILY.ROBOTO_BOLD,
    size: 18,
    color: isTest ? Color.neutral[300] : isHover ? Color.neutral[0] : Color.neutral[400],
    style: {
      marginLeft: 5
    }
  }, formatNumericalWithThousandSeparator(data.totalProductionSystem))))))), !isTest && (data.isFavourite === 0 ? React.createElement(StarOutlined, {
    id: "".concat(id, "-client-star-icon"),
    style: {
      position: "absolute",
      top: 40,
      right: 50,
      width: 24,
      height: 24,
      cursor: "pointer",
      color: isTest ? Color.neutral[0] : isHover ? Color.neutral[0] : Color.primary[400]
    },
    onMouseOver: handleOnMouseOver,
    onMouseOut: handleOnMouseOut,
    onClick: handleFavouriteClick
  }) : React.createElement(StarFilled, {
    id: "".concat(id, "-client-star-icon"),
    style: {
      position: "absolute",
      top: 40,
      right: 50,
      width: 24,
      height: 24,
      cursor: "pointer",
      color: isTest ? Color.neutral[0] : isHover ? Color.neutral[0] : Color.primary[400]
    },
    onMouseOver: handleOnMouseOver,
    onMouseOut: handleOnMouseOut,
    onClick: handleFavouriteClick
  })), React.createElement(ShowMoreHorizontal, {
    id: "".concat(id, "-client-showmore-horiz-icon"),
    style: {
      position: "absolute",
      top: 40,
      right: 16,
      width: 24,
      height: 24,
      cursor: "pointer",
      color: isTest ? Color.neutral[0] : isHover ? Color.neutral[0] : Color.primary[400]
    },
    onClick: handleClickShowMoreIcon,
    onMouseOver: handleOnMouseOver,
    onMouseOut: handleOnMouseOut
  })) : React.createElement(TableRow, {
    hover: true,
    onClick: onClick,
    tabIndex: -1,
    key: data.name,
    style: {
      height: 85,
      borderBottom: "1px solid ".concat(Color.neutral[400]),
      backgroundColor: isTest && (isHover ? Color.neutral[500] : Color.neutral[600]),
      transition: 'all 0.3s ease'
    },
    onMouseOver: handleOnMouseOver,
    onMouseOut: handleOnMouseOut
  }, React.createElement(TableCell, {
    align: 'left'
  }, React.createElement("div", {
    className: classes.favouriteIconContainer
  }, !isTest && (data.isFavourite === 1 ? React.createElement(StarFilled, {
    onClick: handleFavouriteClick
  }) : React.createElement(StarOutlined, {
    onClick: handleFavouriteClick
  })))), React.createElement(TableCell, {
    align: 'left',
    style: {
      fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
      color: isTest ? Color.neutral[0] : Color.neutral[600]
    }
  }, data.logo ? React.createElement("img", {
    src: data.logo,
    width: "36px",
    height: "36px"
  }) : React.createElement(AvatarCompany, {
    style: {
      width: 36,
      height: 36,
      color: Color.neutral[400]
    }
  })), React.createElement(TableCell, {
    align: 'left',
    style: {
      fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
      color: isTest ? Color.neutral[0] : Color.neutral[600]
    }
  }, data.name), React.createElement(TableCell, {
    align: 'right',
    style: {
      fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
      color: isTest ? Color.neutral[0] : Color.neutral[600]
    }
  }, React.createElement("div", {
    style: {
      marginRight: 40
    }
  }, isTest ? "-" : "".concat(formatNumericalWithThousandSeparator(data.cost), " \u20AC"))), React.createElement(TableCell, {
    align: 'right',
    style: {
      fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
      color: isTest ? Color.neutral[0] : Color.neutral[600]
    }
  }, React.createElement("div", {
    style: {
      marginRight: 40
    }
  }, formatNumericalWithThousandSeparator(data.totalTestSystem))), React.createElement(TableCell, {
    align: 'right',
    style: {
      fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
      color: isTest ? Color.neutral[0] : Color.neutral[600]
    }
  }, React.createElement("div", {
    style: {
      marginRight: 50
    }
  }, isTest ? "-" : formatNumericalWithThousandSeparator(data.totalProductionSystem))), React.createElement(TableCell, {
    align: 'right',
    style: {
      position: "relative"
    }
  }, React.createElement("div", {
    className: classes.moreActionIconContainer
  }, React.createElement(ShowMoreHorizontal, {
    id: data.name,
    style: {
      color: isTest ? Color.neutral[0] : Color.primary[500]
    },
    onClick: handleClickShowMoreIcon,
    ariaControls: data.name
  })))), !isTest && React.createElement(OverflowMenu, {
    anchorElement: anchorEl,
    setAnchorEl: setAnchorEl,
    menuItems: [{
      text: "Delete",
      icon: React.createElement(Delete, {
        style: {
          color: Color.danger[500]
        }
      }),
      destructive: true,
      // disabled: isTest,
      onClick: function onClick() {
        setActiveClient(data);
        setDeleteClientDialogOpen(true);
      }
    }]
  }));
};

export var Client = function Client(props) {
  var id = props.id,
      clientView = props.clientView,
      data = props.data,
      onClick = props.onClick,
      setActiveClient = props.setActiveClient,
      setDeleteClientDialogOpen = props.setDeleteClientDialogOpen,
      handleUpdateClient = props.handleUpdateClient,
      userBaseUrl = props.userBaseUrl,
      currentUserData = props.currentUserData,
      order = props.order,
      setOrder = props.setOrder,
      orderBy = props.orderBy,
      setOrderBy = props.setOrderBy;

  var desc = function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }

    if (b[orderBy] > a[orderBy]) {
      return 1;
    }

    return 0;
  };

  var stableSort = function stableSort(array, cmp) {
    var stabilizedThis = array.map(function (el, index) {
      return [el, index];
    });
    stabilizedThis.sort(function (a, b) {
      var order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(function (el) {
      return el[0];
    });
  };

  var getSorting = function getSorting(order, orderBy) {
    return order === 'desc' ? function (a, b) {
      return desc(a, b, orderBy);
    } : function (a, b) {
      return -desc(a, b, orderBy);
    };
  };

  return React.createElement(Grid, {
    container: true,
    style: {
      flexGrow: 1
    },
    spacing: 2
  }, clientView === "GRID" ? React.createElement(React.Fragment, null, data.length > 0 ? React.createElement(React.Fragment, null, data.map(function (client, index) {
    if (client.isTest || client.imageUuid) {
      client.logo = userBaseUrl + "clients/" + client.key + "/clientimage?userkey=" + currentUserData.key;
    }

    return React.createElement(Grid, {
      item: true,
      md: 4,
      xs: 12,
      key: "client-".concat(index)
    }, React.createElement(ClientView, {
      clientView: clientView,
      data: client,
      onClick: onClick(client),
      setActiveClient: setActiveClient,
      setDeleteClientDialogOpen: setDeleteClientDialogOpen,
      handleUpdateClient: handleUpdateClient
    }));
  })) : React.createElement(Grid, {
    item: true,
    md: 12,
    style: {
      textAlign: "center"
    }
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 30,
    color: Color.neutral[300],
    style: {
      letterSpacing: -0.8,
      marginTop: 90
    }
  }, "No Search Result"))) : React.createElement(React.Fragment, null, React.createElement(ClientListHeader, {
    data: data,
    order: order,
    setOrder: setOrder,
    orderBy: orderBy,
    setOrderBy: setOrderBy
  }, data.length > 0 && React.createElement(React.Fragment, null, stableSort(data, getSorting(order, orderBy)).map(function (client, index) {
    if (client.isTest || client.imageUuid) {
      client.logo = userBaseUrl + "clients/" + client.key + "/clientimage?userkey=" + currentUserData.key;
    }

    return React.createElement(ClientView, {
      clientView: clientView,
      data: client,
      onClick: onClick(client),
      setActiveClient: setActiveClient,
      setDeleteClientDialogOpen: setDeleteClientDialogOpen,
      handleUpdateClient: handleUpdateClient
    });
  }))), data.length === 0 && React.createElement(Grid, {
    item: true,
    md: 12,
    style: {
      textAlign: "center"
    }
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 30,
    color: Color.neutral[300],
    style: {
      letterSpacing: -0.8,
      marginTop: 90
    }
  }, "No Search Result"))));
};
Client.defaultProps = {
  id: "client",
  data: [{
    logo: '',
    name: "Client's Name",
    cost: 413,
    isTest: 0,
    isFavourite: 0,
    totalTestSystem: 12,
    totalProductionSystem: 23
  }],
  onClick: function onClick() {},
  setActiveClient: null,
  setDeleteClientDialogOpen: function setDeleteClientDialogOpen() {},
  handleUpdateClient: function handleUpdateClient() {}
};
export default React.memo(Client);