// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    whiteContentTopRadius: {
      backgroundColor: "#ffffff",
      marginTop: "-10px !important",
      textAlign: "center",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      paddingBottom: "20px",
      overflow: "hidden"
    },
    progressBar: {
      width: "95%",
      padding: "8px 10px",
      fontSize: "10px",
      color: "#9D9D9D",
      borderBottom: "1px solid #EFEFEF",
      textAlign: "center",
      margin: "auto"
    },
    greyContentPlain: {
      // backgroundColor: "#f3f6f6",
      overflow: "auto"
    },
    greyContent: {
      backgroundColor: "#f3f6f6",
      overflow: "auto",
      padding: "15px"
    },
    whiteContentBarWithCloseButton: {
      backgroundColor: "#ffffff",
      height: "45px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        borderLeft: "2px solid #fbc700",
        backgroundColor: "#f1f1f1"
      }
    },
    ContentBorder: {
      height: '4px',
      borderTop: '1px solid #9D9D9D',
      backgroundColor: '#EFEFEF'
    },
    contentTitle: {
      fontSize: "10px",
      color: "#9D9D9D",
      marginBottom: 2,
      textAlign: "left",
      padding: "0 15px"
    },
    blackLeftContent: {
      fontSize: "12px",
      color: "black",
      marginTop: 0,
      textAlign: "left",
      textTransform: "capitalize",
      padding: "0 15px"
    }
  });
});