// React Lib
import React from "react"; // Component Styles

import { useStyles } from "./BreadcrumbStyles"; // @otg-one/ui_components/components

import { Typography } from "../Typography"; // @otg-one/ui_components/icons

import { ChevronRight } from "../../icons"; // @otg-one/ui_components/constants

import { Color, FONT_FAMILY } from "../../constants";
import { useMediaQuery, useTheme } from "@material-ui/core";
export var BreadcrumbType = {
  ORGANIZATION: "ORGANIZATION",
  BILLING: "BILLING",
  BILLING_DETAIL: "BILLING_DETAIL",
  BILLING_HISTORY: "BILLING_HISTORY",
  ACCOUNT_SETTINGS: "ACCOUNT_SETTINGS",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  PUSH: "PUSH",
  POP: "POP",
  BACK: "BACK"
};
export var Breadcrumb = function Breadcrumb(props) {
  var classes = useStyles(props);
  var theme = useTheme();
  var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  var id = props.id,
      stacks = props.stacks,
      handleChangeBreadcrumb = props.handleChangeBreadcrumb;

  var handleStackClick = function handleStackClick(index) {
    return function () {
      handleChangeBreadcrumb(BreadcrumbType.POP, stacks[index]);
    };
  };

  return React.createElement("div", {
    id: id,
    className: classes.breadcrumbContainer
  }, stacks.map(function (stack, index) {
    return React.createElement(React.Fragment, null, index !== 0 && React.createElement(ChevronRight, {
      className: classes.breadcrumbChevronRight
    }), React.createElement("div", {
      onClick: handleStackClick(index)
    }, React.createElement(Typography, {
      fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
      size: 8,
      color: index === stacks.length - 1 ? Color.neutral[500] : Color.neutral[300],
      className: index === stacks.length - 1 ? classes.breadcrumbLabelLastIndex : classes.breadcrumbLabel,
      style: {
        marginBottom: -6
      }
    }, stack.type), React.createElement(Typography, {
      fontFamily: FONT_FAMILY.CAPITOLIUM_BOLD,
      size: isMobile ? 12 : 16,
      color: index === stacks.length - 1 && Color.neutral[900],
      style: {
        cursor: index !== stacks.length - 1 && "pointer",
        marginTop: stack.type === '' ? 11 : 0
      },
      className: index === stacks.length - 1 ? classes.breadcrumbLabelLastIndex : classes.breadcrumbLabel
    }, stack.label)));
  }));
};
Breadcrumb.defaultProps = {
  stacks: [],
  handleChangeBreadcrumb: function handleChangeBreadcrumb() {}
};
export default React.memo(Breadcrumb);