// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    greyButton: {
      backgroundColor: "#f5f5f5 !important",
      width: "100% !important",
      height: "50px !important",
      color: "#00a7dd !important",
      fontSize: "16px !important",
      borderRadius: "0 !important",
      textTransform: "none"
    },
    greyButtonLeft: {
      borderRight: "2px solid white !important",
      borderTop: "4px solid white !important"
    },
    greyButtonRight: {
      borderLeft: "2px solid white !important",
      borderTop: "4px solid white !important"
    },
    greyButtonDisabled: {
      backgroundColor: "#f5f8f8 !important",
      color: "#d9dbdd !important"
    },
    greyButtonSelected: {
      backgroundColor: "#00a7dd !important",
      color: "white !important",
      "&:hover": {
        backgroundColor: "#8fcff6 !important",
        color: "white !important"
      }
    }
  });
});