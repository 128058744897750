import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../App';
import ImagePreviewDialog from '../../../Dialogs/ImagePreview/ImagePreviewDialog';

import {
    Information
} from '@otg-one/ui_components';

import { Grid, Hidden, useMediaQuery, useTheme } from '@material-ui/core';

import { Stepper, Typography, FONT_FAMILY, Color, Button, Form, Separator, Radio, DialogBox, BreadcrumbType } from '@otg-one/ui_components';

// import TechnologyLandscape from '../../../assets/img/technologyLandscape.svg';

import { useStyles } from './SystemConfigurationStyles';

import { Stack } from '../../../Models/Stack';

import Moment from 'moment';

import SystemService from "./SystemService";
import { SYSTEM_DASHBOARD } from '../../../Constants/FilePath';
import { STACK_TYPE_SYSTEM } from '../../../Constants/StackType';
import { NO_AUTH, BASIC_AUTH, B1_AUTH, B1_MOBILE } from '../../../Constants/AuthMethod';
import { useHistory, Link } from 'react-router-dom';

import targetSystemUrlImage from '../../../assets/img/targetSystemUrl.png';
import authMethodImage from '../../../assets/img/authMethod.png';
import targetDbNameImage from '../../../assets/img/dbName.png';
import systemIdImage from '../../../assets/img/systemId.png';

const GeneralSettings = (props: any) => {
    const classes = useStyles(props);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { data, handleChangeData, systemNameIsError, targetSystemURLIsError, preselectDBNameIsError, handleOpenDialog, openHelpTargetSystemUrl, openHelpTargetDbName, openHelpSystemId } = props

    return (
        <Grid container spacing={4} className={classes.settingContainer}>
            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                    size={12}
                    color={Color.neutral[500]}
                >
                    In order to gain access to your Business One System via OntegoOne we require some system and access data from you.<br />
                    Here is a quick overview of the secure communication:
                </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <Button
                    type="secondary"
                    color="blue"
                    onClick={handleOpenDialog}
                    style={{ outline: "none" }}
                >
                    {`Technology Landscape`}
                </Button>
            </Grid>
            <Hidden smDown>
                <Grid item md={4} />
            </Hidden>
            <Grid item md={12} xs={12}>
                <Separator type="n200" />
            </Grid>
            {/* <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    {`Connection Method`}
                </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <Radio
                    name="connectionMethod"
                    label="Service Layer"
                    value={'service_layer'}
                    checked={data.connectionMethod === 'service_layer'}
                    // disabled={data.deploymentEnvChanged === "true"}
                    onChange={handleChangeData("connectionMethod")}
                />
                <Radio
                    name="connectionMethod"
                    label="Integration Framework"
                    value={'integration_framework'}
                    checked={data.connectionMethod === 'integration_framework'}
                    // disabled={data.deploymentEnvChanged === "true"}
                    onChange={handleChangeData("connectionMethod")}
                />
            </Grid>
            <Grid item md={12} xs={12}>
                <Separator type="n200" />
            </Grid> */}
            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    System Name
                </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <Form
                    color="grey"
                    placeholder="Insert System Name"
                    required
                    value={data.name}
                    onChange={handleChangeData("name")}
                    error={systemNameIsError}
                    errorMessage={systemNameIsError && "System name must be filled"}
                />
            </Grid>
            <Hidden smDown>
                <Grid item md={4} />
            </Hidden>
            <Grid item md={12} xs={12}>
                <Separator type="n200" />
            </Grid>
            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    Target System URL
                    <Information style={{ width: 12, height: 20, marginLeft: 3, cursor: "pointer" }} onClick={openHelpTargetSystemUrl} />
                </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <Form
                    color="grey"
                    placeholder="Insert Target System URL"
                    required
                    value={data.url}
                    onChange={handleChangeData("url")}
                    error={targetSystemURLIsError}
                    errorMessage={targetSystemURLIsError && "Target System URL must be filled"}
                />
            </Grid>

            <Hidden smDown>
                <Grid item md={4} />
            </Hidden>

            <Grid item md={4} xs={12} style={{ paddingTop: "0px", marginTop: isMobile ? "-12px" : "-24px", wordBreak: "break-word" }}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                    size={12}
                    color={Color.neutral[500]}
                >
                    {`Please enter the connection to the Business One target system. We need the complete URL to the ${data.connectionMethod === 'service_layer' ? 'Service Layer' : 'Scenario-Package'} (f.e. ${data.connectionMethod === 'service_layer' ? 'https://192.168.200.10:50000/b1s/v2' : 'https://192.168.200.10:8443/B1iXcellerator/exec/ipo/vP.001sap0000.in_HCSX/com.sap.b1i.vplatform.runtime/INB_HT_CALL_SYNC_XPT/INB_HT_CALL_SYNC_XPT.ipo/proc/Logistic'}). The system does not have to be accessible from the outside of your network. If you need external access, we recommend using a web server with a proxy that connects with your B1 system.`}
                </Typography>
            </Grid>
            <Hidden smDown>
                <Grid item md={8} />
            </Hidden>
            <Grid item md={12} xs={12}>
                <Separator type="n200" />
            </Grid>
            <Grid item md={4}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    {`Preselect database name ${data.connectionMethod !== 'service_layer' ? '(optional)' : ''}`}
                    <Information style={{ width: 12, height: 20, marginLeft: 3, cursor: "pointer" }} onClick={openHelpTargetDbName} />
                </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <Form
                    color="grey"
                    placeholder="Database Name"
                    required={data.connectionMethod === 'service_layer'}
                    value={data.dbName}
                    onChange={handleChangeData("dbName")}
                    error={data.connectionMethod === 'service_layer' ? preselectDBNameIsError : false}
                    errorMessage={preselectDBNameIsError && 'Database name must be filled'}
                />
            </Grid>
            <Hidden smDown>
                <Grid item md={4} />
            </Hidden>
            <Grid item md={4} xs={12} style={{ paddingTop: "0px", marginTop: isMobile ? "-12px" : "-24px" }}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                    size={12}
                    color={Color.neutral[500]}
                >
                    You can predefine the Business One database name for the mobile OntegoOne App which drops the selection option from the login dialogue. It also enables support for the authentication method B1 Authentication.
                </Typography>
            </Grid>
            {data.connectionMethod !== 'service_layer' && (
                <>
                    <Hidden smDown>
                        <Grid item md={8} />
                    </Hidden>
                    <Grid item md={12} xs={12}>
                        <Separator type="n200" />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                            size={14}
                            color={Color.neutral[600]}
                        >
                            Preselect system id (optional)
                            <Information style={{ width: 12, height: 20, marginLeft: 3, cursor: "pointer" }} onClick={openHelpSystemId} />
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Form
                            color="grey"
                            placeholder="System ID"
                            value={data.b1SystemId}
                            onChange={handleChangeData("b1SystemId")}
                        />
                    </Grid>
                    <Hidden smDown>
                        <Grid item md={4} />
                    </Hidden>
                    <Grid item md={4} xs={12} style={{ paddingTop: "0px", marginTop: isMobile ? "-12px" : "-24px" }}>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                            size={12}
                            color={Color.neutral[500]}
                        >
                            You can predefine the Business One system id for the mobile OntegoOne App which drops the request to the Business One System for getting the systems.
                        </Typography>
                    </Grid>
                    <Hidden smDown>
                        <Grid item md={8} />
                        <Grid item md={12} />
                    </Hidden>
                </>
            )}
        </Grid>
    );
};

const SystemAccess = (props: any) => {
    const classes = useStyles(props);

    const { data, handleChangeData, message, scheduledDate, handleOpenSystemStatusDialog, handleCancelChangeStatus, openHelpAuthMethod } = props;
    const formattedDate = Moment(scheduledDate).format("DD MMM YYYY");

    return (
        <Grid container spacing={4} className={classes.settingContainer}>
            {data.connectionMethod !== 'service_layer' && (
                <>
                    <Grid item md={4} xs={12}>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                            size={14}
                            color={Color.neutral[600]}
                        >
                            Authentication Method
                            <Information style={{ width: 12, height: 20, marginLeft: 3, cursor: "pointer" }} onClick={openHelpAuthMethod} />
                        </Typography>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                            size={12}
                            color={Color.neutral[500]}
                            style={{ marginTop: "13px" }}
                        >
                            OntegoOne supports several authentication methods of the Business One Integration Framework. Please select the implemented method in your Integration Framework:
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Radio name="authenticationMethod" label="No Authentication" value={NO_AUTH} checked={data.authMethod === NO_AUTH} onChange={handleChangeData("authMethod")} />
                        <Radio name="authenticationMethod" label="Basic Authentication" value={BASIC_AUTH} checked={data.authMethod === BASIC_AUTH} onChange={handleChangeData("authMethod")} />
                        {data.authMethod === BASIC_AUTH && (
                            <Grid container spacing={2} style={{ paddingLeft: "40px", marginBottom: "24px" }}>
                                <Grid item md={6} xs={12} >
                                    <Form
                                        color="grey"
                                        placeholder="Runtime User"
                                        value={data.runtimeUser}
                                        onChange={handleChangeData("runtimeUser")}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Form
                                        color="grey"
                                        placeholder="Runtime Password"
                                        password
                                        value={data.runtimePassword}
                                        onChange={handleChangeData("runtimePassword")}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        <Radio name="authenticationMethod" label="B1 Authentication" value={B1_AUTH} checked={data.authMethod === B1_AUTH} onChange={handleChangeData("authMethod")} />
                        <Radio name="authenticationMethod" label="sap.B1Mobile Authentication" value={B1_MOBILE} checked={data.authMethod === B1_MOBILE} onChange={handleChangeData("authMethod")} />
                    </Grid>
                    <Hidden smDown>
                        <Grid item md={2} />
                    </Hidden>
                    <Grid item md={12} xs={12}>
                        <Separator type="n200" />
                    </Grid>
                </>
            )}
            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    Note about Custom Fields
                </Typography>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                    size={12}
                    color={Color.neutral[500]}
                    style={{ marginTop: "13px" }}
                >
                    At this time OntegoOne does not support any Custom Fields from Business One. Please tell us about your requirements and we will get in touch with you soon in order to discuss possible implementation strategies in future versions.
                </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                <Form
                    color="grey"
                    placeholder="Comment"
                    multiline
                    rows={5}
                    value={data.note}
                    onChange={handleChangeData("note")}
                />
            </Grid>
            <Hidden smDown>
                <Grid item md={2} />
            </Hidden >

            <Grid item md={12} xs={12}>
                <Separator type="n200" />
            </Grid>
            <Grid item md={4} xs={12}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                    size={14}
                    color={Color.neutral[600]}
                >
                    System Status
                </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <Radio
                    name="systemStatus"
                    label="Test"
                    value={0}
                    checked={data.deploymentEnv === 0}
                    disabled={data.deploymentEnvChanged === "true"}
                    onChange={data.key ? handleOpenSystemStatusDialog : handleChangeData("deploymentEnv")}
                />
                <Radio
                    name="systemStatus"
                    label="Production"
                    value={1}
                    checked={data.deploymentEnv === 1}
                    disabled={data.deploymentEnvChanged === "true"}
                    onChange={data.key ? handleOpenSystemStatusDialog : handleChangeData("deploymentEnv")}
                />
                {data.deploymentEnvChanged === "true" && (
                    <>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                            size={14}
                            color={Color.neutral[900]}
                            style={{ marginTop: "13px" }}
                        >
                            System status changed to {
                                <label style={{ fontWeight: 'bold' }}>
                                    {data.deploymentEnv === 0 ? "Production" : "Test"}
                                </label>
                            } scheduled at {
                                <label style={{ fontWeight: 'bold' }}>
                                    {formattedDate}.
                                </label>
                            } &nbsp;&nbsp; {
                                <Button
                                    type="secondary"
                                    color={Color.danger[300]}
                                    onClick={handleCancelChangeStatus}
                                    style={{
                                        outline: "none",
                                        color: Color.danger[300]
                                    }}
                                >
                                    {`Cancel`}
                                </Button>}
                        </Typography>
                    </>
                )}
            </Grid>
            <Hidden smDown>
                <Grid item md={4} />
            </Hidden>
            <Grid item md={12} xs={12}>
                <div
                    style={{
                        boxSizing: "border-box",
                        padding: 10,
                        fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
                        color: Color.danger[300],
                        fontSize: 16,
                        textAlign: "center"
                    }}
                >
                    {message}
                </div>
            </Grid>
            <Hidden smDown>
                <Grid item md={12} />
            </Hidden>
        </Grid >
    );
};

const SystemConfiguration = (props: any) => {
    const classes = useStyles(props);
    const history = useHistory();

    const {
        getCurrentBreadcrumbData,
        currentUserData,
        handleChangeBreadcrumb,
        setSnackbarMessage,
        handleShowSnackbar
    } = useContext(AppContext);

    const system = getCurrentBreadcrumbData();
    let editMode = false;

    if (system.key) {
        editMode = true;
    }

    const defaultData = {
        name: "",
        connectionMethod: 'service_layer',
        url: "",
        dbName: "",
        b1SystemId: "",
        deploymentEnv: 0,
        deploymentEnvChanged: "false",
        authMethod: NO_AUTH,
        runtimeUser: "",
        runtimePassword: "",
        note: ""
    };

    const [systemStatusDialog, setSystemStatusDialog] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [message, setMessage] = useState("");

    const [data, setData] = useState(editMode ? system : defaultData);

    const [activeStep, setActiveStep] = useState(0);

    const [systemNameIsError, setSystemNameIsError] = useState(false)
    const [targetSystemURLIsError, setTargetSystemURLIsError] = useState(false)
    const [preselectDBNameIsError, setPreselectDBNameIsError] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [activeStep]);

    useEffect(() => {
        setData(editMode ? system : defaultData);
    }, [system]);

    const getScheduledDate = () => {
        const date = new Date();
        const nextStartOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);

        return nextStartOfMonth;
    }

    const formattedDate = Moment(getScheduledDate()).format("dddd, DD/MM/YY");

    const handleOpenSystemStatusDialog = () => setSystemStatusDialog(true);
    const handleCloseSystemStatusDialog = () => setSystemStatusDialog(false);
    const handleOpenDialog = () => setDialogOpen(true);
    const handleCloseDialog = () => setDialogOpen(false);

    const handleCancelChangeStatus = () => {
        changeDeploymentEnvChanged();
    }

    const handleNextCallback = () => {
        if (activeStep === 0) {
            if (data.name === "") {
                setSystemNameIsError(true)
            }
            if (data.url === "") {
                setTargetSystemURLIsError(true)
            }

            if (data.connectionMethod === 'service_layer') {
                if (data.dbName === "") {
                    setPreselectDBNameIsError(true)
                }

                if (data.name !== "" && data.url !== "" && (data.connectionMethod === 'service_layer' && data.dbName !== "")) {
                    setActiveStep(prevActiveStep => prevActiveStep + 1);
                }
            }
            else if (data.connectionMethod === 'integration_framework') {
                if (data.name !== "" && data.url !== "") {
                    setActiveStep(prevActiveStep => prevActiveStep + 1);
                }
            }
        }
        else {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
    }
    const handleBackCallback = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    const handleChangeStatus = () => {
        changeDeploymentEnvChanged();
        handleCloseSystemStatusDialog();
    }

    const changeDeploymentEnvChanged = () => {
        const newData: any = { ...data };
        newData.deploymentEnvChanged = newData.deploymentEnvChanged === "false" ? "true" : "false";
        setData(newData);
    }

    const handleChangeData = (property: string) => (e: React.FormEvent<HTMLInputElement>) => {
        setSystemNameIsError(false)
        setTargetSystemURLIsError(false)
        setPreselectDBNameIsError(false)

        const newData: any = { ...data };
        if (property === "deploymentEnv")
            newData[property] = data.deploymentEnv === 0 ? 1 : 0;
        else if (property === "authMethod") {
            const authMethod = e.currentTarget.value;
            newData[property] = authMethod;
            if (authMethod !== BASIC_AUTH) {
                newData.runtimeUser = "";
                newData.runtimePassword = "";
            }
        } else {
            newData[property] = e.currentTarget.value;
        }
        setData(newData);
    };

    const handleSubmitSystem = () => {
        if (editMode) {
            SystemService.editSystem(currentUserData.key, data).then((res: any) => {
                if (res.status === 200) {
                    setSnackbarMessage("System has been updated")
                    handleShowSnackbar();
                    handleChangeBreadcrumb(BreadcrumbType.POP, new Stack(STACK_TYPE_SYSTEM, res.data.name, SYSTEM_DASHBOARD, res.data));
                } else {
                    setMessage("Something went wrong when updating a system!");
                }
            });
        } else {
            SystemService.createSystem(currentUserData.key, system.client.key, data).then((res: any) => {
                if (res.status === 200) {
                    setSnackbarMessage("New System has been created")
                    handleShowSnackbar();
                    handleChangeBreadcrumb(BreadcrumbType.BACK);
                } else {
                    setMessage("Something went wrong when creating a system!");
                }
            });
        }
    };

    const [imagePreview, setImagePreview] = useState({ open: false, image: "", title: "" });
    const handleCloseImagePreviewDialog = () => {
        setImagePreview({ open: false, image: "", title: "" })
    }
    const openHelpTargetSystemUrl = () => {
        setImagePreview({ open: true, image: targetSystemUrlImage, title: "Target System URL" })
    }

    const openHelpTargetDbName = () => {
        setImagePreview({ open: true, image: targetDbNameImage, title: "Preselect Database Name " })
    }

    const openHelpSystemId = () => {
        setImagePreview({ open: true, image: systemIdImage, title: "Preselect System Id" })
    }

    const openHelpAuthMethod = () => {
        setImagePreview({ open: true, image: authMethodImage, title: "Authentication Method" })
    }

    const steps = [
        {
            label: "General Settings",
            content: <GeneralSettings
                data={data}
                handleChangeData={handleChangeData}
                systemNameIsError={systemNameIsError}
                targetSystemURLIsError={targetSystemURLIsError}
                preselectDBNameIsError={preselectDBNameIsError}
                handleOpenDialog={handleOpenDialog}
                openHelpTargetSystemUrl={openHelpTargetSystemUrl}
                openHelpTargetDbName={openHelpTargetDbName}
                openHelpSystemId={openHelpSystemId}
                handle
            />
        },
        {
            label: "System Access",
            content: <SystemAccess
                data={data}
                scheduledDate={getScheduledDate()}
                handleChangeData={handleChangeData}
                handleCancelChangeStatus={handleCancelChangeStatus}
                handleOpenSystemStatusDialog={handleOpenSystemStatusDialog}
                message={message}
                openHelpAuthMethod={openHelpAuthMethod}
            />
        }
    ];

    const handleCancel = () => {
        handleChangeBreadcrumb(BreadcrumbType.BACK);
    }

    return (
        <div style={{ width: "100%", height: "100%", marginTop: -143 }}>
            <div className={classes.systemConfigurationContainer}>
                {/* <Breadcrumb
                    stacks={breadcrumb}
                    handleChangeBreadcrumb={handleChangeBreadcrumb}
                /> */}
            </div>
            <Stepper
                steps={steps}
                activeStep={activeStep}
                submitButtonLabel="Save System Settings"
                submitCallback={handleSubmitSystem}
                nextCallback={handleNextCallback}
                backCallback={handleBackCallback}
                handleCancel={handleCancel}
            />
            <DialogBox
                open={dialogOpen}
                handleClose={handleCloseDialog}
                title="Technology Landscape"
                content={<img src="https://www.ontego.one/static/technologyLandscape.png" alt="technology-landscape" width="1000px" />}
            />
            <ImagePreviewDialog
                open={imagePreview.open}
                onClose={handleCloseImagePreviewDialog}
                title={imagePreview.title}
                imageLink={imagePreview.image}
            />
            {editMode && (
                <DialogBox
                    open={systemStatusDialog}
                    handleClose={handleCloseSystemStatusDialog}
                    title="Change System Status"
                    content={
                        <>
                            <br />
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_ITALIC}
                                size={16}
                                color={Color.neutral[900]}
                            >
                                Status will be changed on "{formattedDate}"
                            </Typography>
                            <br />
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                size={14}
                                color={Color.neutral[900]}
                            >
                                To prevent bill miscalculation the status will not be changed immediately
                                <br />
                                The change will be effective <label style={{ fontWeight: 'bold' }}>on the first day of next month</label>
                            </Typography>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 60 }}>
                                <div style={{ display: 'flex', right: 0 }}>
                                    <Button
                                        type="secondary"
                                        style={{ outline: 'none', marginRight: 20 }}
                                        onClick={handleCloseSystemStatusDialog}
                                    >
                                        {`Cancel`}
                                    </Button>
                                    <Button
                                        onClick={handleChangeStatus}
                                        style={{ outline: 'none' }}>
                                        {'Change Status'}
                                    </Button>
                                </div>
                            </div>
                        </>
                    }
                />
            )}
        </div>
    );
};

export default SystemConfiguration;