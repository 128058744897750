// React Lib
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { AppContext } from '../../App';

// Styles
import { useStyles } from './LoginStyles';

// MUI Core
import Grid from '@material-ui/core/Grid';

// Assets
import LoginImage from "../../assets/img/login.svg";

// Interface
import { ILoginInterface } from './LoginInterface';

// Otg One Components
import { Button, Form, Typography, FONT_FAMILY, Color, BreadcrumbType } from '@otg-one/ui_components';
import { OntegoOneLogoBlack } from '@otg-one/ui_components';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

// Etc
import { PARTNER_DASHBOARD } from '../../Constants/FilePath';
import { Stack } from '../../Models/Stack';

import moment, { Moment } from 'moment';
import { timeout } from '../../Utils/Utils';
import { STACK_TYPE_PARTNER } from '../../Constants/StackType';
import ResetPasswordDialog from './ResetPasswordDialog';
import ActivateAccountDialog from './ActivateAccountDialog';
import ActivationSuccessfulDialog from './ActivationSuccessfulDialog';
import ActivationFailedDialog from './ActivationFailedDialog';

import { REGISTER } from '../../Constants/FilePath';

const env = process.env.REACT_APP_ENV;

const jwt_decode = require('jwt-decode');

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const Login = (props: ILoginInterface) => {
    const classes = useStyles(props);
    const {
        setIsLoading,
        AuthService,
        setToken,
        setupUserData,
        handleChangeBreadcrumb,
        topNavbarLogoClick
    } = useContext(AppContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");

    const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
    const [activateAccountDialogOpen, setActivateAccountDialogOpenn] = useState(false);

    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const [errorMessage, setErrorMessage] = useState(" ");

    const handleOpenResetPasswordDialog = () => { setResetPasswordDialogOpen(true) };
    const handleCloseResetPasswordDialog = () => { setResetPasswordDialogOpen(false) };

    const handleOpenActivateAccountDialog = () => { setActivateAccountDialogOpenn(true) };
    const handleCloseActivateAccountDialog = () => { setActivateAccountDialogOpenn(false) };

    const [activationSuccessful, setActivationSuccessful] = useState(false);
    const [activationFailed, setActivationFailed] = useState(false);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleUserKeyPress = useCallback((event: any) => {
        const { keyCode } = event;

        if (keyCode === 13) {
            /** Cannot call login() directly, used click() instead */
            document.getElementById('loginButton')!.click()
        }
    }, []);

    let query = useQuery();

    useEffect(() => {
        if (AuthService.getUserInfo()) {
            topNavbarLogoClick();
        }
    }, []);

    useEffect(() => {
        // if activationCode -> try to activate
        let activationToken = query.get("activationToken");
        if (activationToken) {
            AuthService.activateUser(activationToken).then((res: any) => {
                setActivationSuccessful(true);
                window.addEventListener('keydown', handleUserKeyPress);

                return () => {
                    window.removeEventListener('keydown', handleUserKeyPress);
                };
            }, (error: any) => {
                setActivationFailed(true);
                window.addEventListener('keydown', handleUserKeyPress);

                return () => {
                    window.removeEventListener('keydown', handleUserKeyPress);
                };
            });
        }
        else {
            window.addEventListener('keydown', handleUserKeyPress);

            return () => {
                window.removeEventListener('keydown', handleUserKeyPress);
            };
        }
    }, [handleUserKeyPress]);

    /** Automated greetings based on local system time */
    const getGreetingTime = (date: Moment) => {
        let greetings = "";

        if (!date || !date.isValid()) {
            return;
        }

        const split_afternoon = 12;
        const split_evening = 17;
        const currentHour = parseFloat(date.format("HH"));

        if (currentHour >= split_afternoon
            && currentHour <= split_evening
        ) {
            greetings = "Good Afternoon!";
        }
        else if (currentHour >= split_evening) {
            greetings = "Good Evening!";
        }
        else {
            greetings = "Good Morning!";
        }

        return greetings;
    };

    const handleChangeEmail = (e: React.FormEvent<HTMLInputElement>) => {
        setEmail(e.currentTarget.value)

        if (emailError) {
            setEmailError(false)
            setPasswordError(false)
            setErrorMessage(" ")
        }
    };

    const handleChangePassword = (e: React.FormEvent<HTMLInputElement>) => {
        setPassword(e.currentTarget.value)

        if (passwordError) {
            setEmailError(false)
            setPasswordError(false)
            setErrorMessage(" ")
        }
    };

    const handleCloseActivationSuccessfulDialog = () => {
        setActivationSuccessful(false);
    };

    const handleCloseActivationFailedDialog = () => {
        setActivationFailed(false);
    };

    const login = async (e: any) => {
        e.preventDefault();
        setIsLoading(true)

        if (!executeRecaptcha) {
            return;
        }

        if (email === "" || password === "") {
            setEmailError(true)
            setPasswordError(true)

            setErrorMessage('Email or Password cannot be empty!')

            setIsLoading(false)
            return;
        }

        const recaptchaResponse = await executeRecaptcha("login");

        // verify request
        const credentials = { email: email, password: password, name: "NAME TEST" };
        AuthService.login(credentials, recaptchaResponse).then((res: any) => {
            if (res.data.login) {
                localStorage.setItem("userInfo", JSON.stringify(res.data.token));

                AuthService.setUserToken(res.data.token);

                const decoded = jwt_decode(AuthService.getUserInfo());

                // Set current user
                setupUserData();
                AuthService.getUserByKey(decoded.key).then((res: any) => {
                    handleChangeBreadcrumb(BreadcrumbType.PUSH, new Stack(STACK_TYPE_PARTNER, res.data.name, PARTNER_DASHBOARD));
                })
            } else {
                setEmailError(true)
                setPasswordError(true)
                if (res.data.msg) {
                    setErrorMessage(res.data.msg);
                } else {
                    setErrorMessage("Email or Password is incorrect!");
                }

                setIsLoading(false)
            }
        }, (error: any) => {
            setErrorMessage("Internal error during user login!");
            setIsLoading(false)
        });

        await timeout(2000)
        setIsLoading(false)
    };

    return (
        <>
            <div id="rootContainer" className={classes.rootContainer}>
                <Grid container style={{ flexGrow: 1, width: "100%", height: "100%" }}>
                    <Grid item md={6} xs={12}>
                        <div id="loginContainer" className={classes.loginContainer}>
                            <Grid container style={{ flexGrow: 1 }}>
                                <Grid item md={12} xs={12}>
                                    <OntegoOneLogoBlack
                                        style={{
                                            width: 120,
                                            height: 60,
                                            marginTop: 70,
                                            marginBottom: 100
                                        }}
                                    />
                                    <Typography
                                        fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                        size={48}
                                        color={Color.neutral[600]}
                                        style={{ letterSpacing: -1, marginBottom: 35 }}
                                    >
                                        {getGreetingTime(moment())}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Form
                                        id="emailForm"
                                        placeholder="E-mail"
                                        required
                                        value={email}
                                        onChange={handleChangeEmail}
                                        error={emailError}
                                        style={{ marginBottom: 20 }}
                                        tabIndex={1}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Form
                                        id="passwordForm"
                                        placeholder="Password"
                                        required
                                        password
                                        value={password}
                                        onChange={handleChangePassword}
                                        error={passwordError}
                                        errorMessage={errorMessage}
                                        style={{ marginBottom: 14 }}
                                        tabIndex={2}
                                    />
                                    <div style={{ textAlign: "right" }}>
                                        <Typography
                                            fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                            size={14}
                                            color={Color.primary[600]}
                                            onClick={handleOpenResetPasswordDialog}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {`Forgot Password`}
                                        </Typography>
                                    </div>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <div
                                        style={{
                                            boxSizing: "border-box",
                                            padding: 10,
                                            fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
                                            color: Color.danger[300],
                                            fontSize: 16,
                                            textAlign: "center"
                                        }}
                                    >
                                        {message}
                                    </div>

                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <div tabIndex={3}>
                                        <Button
                                            id="loginButton"
                                            alignIcon="right"
                                            onClick={login}
                                        >
                                            {`Login`}
                                        </Button>
                                    </div>
                                </Grid>
                                <div className={classes.loginBottomAction}>
                                    <div style={{ display: "flex", marginBottom: 15 }}>
                                        <Typography
                                            fontFamily={FONT_FAMILY.ROBOTO_LIGHT}
                                            size={14}
                                            color={Color.neutral[600]}
                                        >
                                            {`Don't have an account yet?`}
                                        </Typography>
                                        <Link
                                            to={REGISTER} style={{ textDecoration: "none" }}
                                        >
                                            <Typography
                                                fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                                size={14}
                                                color={Color.primary[600]}
                                                style={{ marginLeft: 10, cursor: "pointer" }}
                                            >
                                                {`Register`}
                                            </Typography>
                                        </Link>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <Typography
                                            fontFamily={FONT_FAMILY.ROBOTO_LIGHT}
                                            size={14}
                                            color={Color.neutral[600]}
                                        >
                                            {`Account Inactive?`}
                                        </Typography>
                                        <Typography
                                            fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                            size={14}
                                            color={Color.primary[600]}
                                            style={{ marginLeft: 10, cursor: "pointer" }}
                                            onClick={handleOpenActivateAccountDialog}
                                        >
                                            {`Activate Account`}
                                        </Typography>
                                    </div>
                                </div>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                backgroundImage: `url(${LoginImage})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover"
                            }}
                        />
                    </Grid>
                </Grid>
            </div>

            <ResetPasswordDialog
                open={resetPasswordDialogOpen}
                onClose={handleCloseResetPasswordDialog}
            />

            <ActivateAccountDialog
                open={activateAccountDialogOpen}
                onClose={handleCloseActivateAccountDialog}
            />

            <ActivationSuccessfulDialog
                open={activationSuccessful}
                onClose={handleCloseActivationSuccessfulDialog}
            />

            <ActivationFailedDialog
                open={activationFailed}
                onClose={handleCloseActivationFailedDialog}
            />
        </>
    )
}

export default Login;