// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./ItemInventoryStatusListPageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { WhiteContentBar } from "../../index";
export var ItemInventoryStatusListPage = function ItemInventoryStatusListPage(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      warehouseLayout = props.warehouseLayout,
      data = props.data,
      itemName = props.itemName,
      totalItem = props.totalItem,
      totalItemType = props.totalItemType;
  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "smallWithSub",
    showScanner: true,
    previewHeight: previewHeight
  }, React.createElement("p", null, "Inventory Status"), React.createElement("sub", null, "Item - ", React.createElement("b", null, "LM4029PH")))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentNormal,
    style: {
      height: previewHeight - 90 - 50 + 10
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "Name",
      rightContent: itemName
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "Total",
      rightContent: totalItem + " " + totalItemType
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.greyContentBar
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    className: classes.greyLeftContent,
    item: true,
    md: 8
  }, React.createElement("p", null, "Warehouse")), React.createElement(Grid, {
    className: classes.greyRightContent,
    item: true,
    md: 4
  }, React.createElement("p", null, "Stock")))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.listOrderItemsDiv,
    style: {
      height: previewHeight - 90 - 50 - 40 - 40 - 35 + 10
    }
  }, React.createElement(Grid, {
    container: true,
    className: classes.listOrderItemsContainer
  }, data.map(function (inventoryStatusItem) {
    return React.createElement(Button, {
      className: classes.listOrderItemsButton
    }, React.createElement(Grid, {
      item: true,
      md: 12
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 8
    }, warehouseLayout === "warehouseCode" ? inventoryStatusItem.warehouseCode : inventoryStatusItem.warehouseName), React.createElement(Grid, {
      className: classes.listOrderItemsButtonRightContent,
      item: true,
      md: 4
    }, inventoryStatusItem.stock + " " + inventoryStatusItem.stockType))));
  }))))), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(GreyButton, {
    onClick: function onClick() {}
  }, "Back"))));
};
export default React.memo(ItemInventoryStatusListPage);