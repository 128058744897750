// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Color } from '@otg-one/ui_components';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dashboardContainer: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
            }
        },
        dashboardSystemApplicationFunction: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "1%"
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%"
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "5%"
            },
            marginTop: 16,
            backgroundColor: Color.neutral[100]
        },

        systemDashboardApplicationFunctions: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "1%"
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%"
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "5%"
            },
            marginTop: 32,
            backgroundColor: Color.neutral[100]
        },

        systemDashboardMaximizedSystemLog: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "1%"
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%"
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "5%"
            },
            backgroundColor: Color.neutral[400]
        },

        systemDashboardTopbar: {
            paddingTop: 24,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },

        systemDashboardSystemInfo: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "1%"
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%"
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "5%"
            },
            marginTop: 20
        },

        aplicationCard: {
            margin: 10,

            [theme.breakpoints.down('xs')]: {
                flexBasis: '100%',
                flexGrow: 0,
                maxWidth: "43%"
            }
        },

        systemInfoHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',

            [theme.breakpoints.down('xs')]: {
                display: "block",
            }
        },

        systemInfoSeparator: {
            "& hr": {
                marginTop: '0 !important',
                marginBottom: '0 !important'
            }
        },

        customerCostAndLoginChart: {
            position: "relative",
            width: "100%",
            height: 435,
            backgroundColor: Color.neutral[100],
            boxSizing: "border-box",
            padding: "16px 24px 16px 24px",

            [theme.breakpoints.down('xs')]: {
                padding: '10px 15px',

                '& > div:nth-child(2)': {
                    marginTop: '50px !important'
                }
            },
        },

        chartDropdown: {
            position: "absolute",
            top: 16,
            right: 16,

            [theme.breakpoints.down('xs')]: {
                top: 40,
                right: 10,
            }
        }
    })
)