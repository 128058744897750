// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    whiteContentNormal: {
      backgroundColor: "#ffffff",
      marginTop: "-10px",
      textAlign: "center",
      paddingTop: "24px"
    }
  });
});