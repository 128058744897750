// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    whiteContentTopRadius: {
      backgroundColor: "#ffffff",
      marginTop: "-10px !important",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      overflow: "hidden"
    },
    listItemsDiv: {
      overflow: "auto"
    },
    confirmAllButton: {
      borderBottom: "1px solid #d2d4d6",
      textAlign: "center",
      fontSize: 14,
      color: "#2d9dd5",
      fontWeight: "bold",
      padding: "8px 0"
    },
    listItemsContainer: {
      backgroundColor: "#f3f6f6"
    },
    listItemsButton: {
      backgroundColor: "white",
      borderBottom: "1px solid #d2d4d6",
      marginBottom: "3px",
      padding: "2px 15px 5px 15px",
      textTransform: "capitalize",
      borderRadius: 0,
      width: "100%"
    },
    listItemsButtonQuantity: {
      fontSize: 12,
      textAlign: "end"
    },
    listItemsButtonBox: {
      backgroundColor: "red",
      fontSize: 10,
      marginBottom: 1,
      color: "#FFFFFF",
      padding: "1px 0"
    },
    listItemsButtonBoxTitle: {
      fontSize: 9,
      textAlign: "left",
      color: "#abadb1",
      lineHeight: 1.5
    }
  });
});