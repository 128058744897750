// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    systemLogContainer: {
      width: "100%",
      backgroundColor: Color.neutral[400]
    },
    systemLogListContainer: {
      marginTop: 40,
      height: 200,
      maxHeight: 200,
      overflowY: "scroll",
      overflowX: "hidden"
    },
    systemLogList: {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      height: 40
    }
  });
});