// React libraries
import * as React from 'react'; // Custom Styles

import { useStyles } from "./DraftDocumentModalStyles";
export var DraftDocumentModal = function DraftDocumentModal(props) {
  var classes = useStyles(props);
  var isModalOpen = props.isModalOpen,
      draft = props.draft,
      modalWidth = props.modalWidth,
      modalHeight = props.modalHeight;
  return React.createElement("div", {
    style: {
      display: isModalOpen ? "block" : "none"
    }
  }, React.createElement("div", {
    className: classes.modal,
    style: {
      height: modalHeight,
      width: modalWidth
    }
  }, React.createElement("div", {
    className: classes.whiteBox
  }, React.createElement("p", null, draft ? "Document is saved as draft" : "Document is saved"))));
};
DraftDocumentModal.defaultProps = {
  setOpenModalState: function setOpenModalState() {},
  draft: false
};
export default React.memo(DraftDocumentModal);