// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    whiteContentTopRadius: {
      backgroundColor: "#ffffff",
      marginTop: "-10px !important",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      overflow: "hidden"
    },
    listOrderItemsDiv: {
      overflow: "auto"
    },
    listOrderItemsContainer: {
      backgroundColor: "#f3f6f6"
    },
    listOrderItemsButton: {
      backgroundColor: "white",
      borderBottom: "1px solid #d2d4d6",
      marginBottom: "3px",
      padding: "15px",
      textTransform: "capitalize",
      borderRadius: 0,
      width: "100%"
    },
    listOrderItemsButtonTitle: {
      fontSize: "10px",
      color: "#abadb1",
      textAlign: "left"
    },
    listOrderItemsButtonLeftContent: {
      fontSize: "12px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textAlign: "left"
    },
    listOrderItemsButtonRightContent: {
      fontSize: "12px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textAlign: "right"
    },
    greyContentBar: {
      backgroundColor: "#f3f6f6",
      height: "35px",
      borderBottom: "1px solid #f5f5f5",
      padding: "10px"
    },
    greyLeftContent: {
      "& p": {
        fontSize: "12px",
        color: "#abadb1",
        marginTop: 0,
        textAlign: "left",
        textTransform: "capitalize"
      }
    },
    greyCenterContent: {
      "& p": {
        fontSize: "12px",
        marginTop: 0,
        color: "#abadb1",
        textAlign: "center",
        textTransform: "capitalize"
      }
    },
    greyRightContent: {
      "& p": {
        fontSize: "12px",
        marginTop: 0,
        color: "#abadb1",
        textAlign: "right",
        textTransform: "capitalize"
      }
    },
    whiteContentBarContainer: {
      backgroundColor: "#ffffff"
    }
  });
});