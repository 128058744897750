// React Lib
import React from "react"; // Component Styles

import { useStyles } from "./SystemMessageStyles"; // @otg-one/ui_components/components

import { Typography, Chip, Separator } from "../index"; // @otg-one/ui_components/icons
// @otg-one/ui_components/constants

import { Color, FONT_FAMILY, getLogSeverityStyle } from "../../constants"; // Material UI Core

import Grid from '@material-ui/core/Grid';
export var SystemMessage = function SystemMessage(props) {
  var classes = useStyles(props);
  var id = props.id,
      messages = props.messages;
  return React.createElement("div", {
    id: id,
    className: classes.systemMessageContainer
  }, React.createElement(Typography, {
    id: "".concat(id, "-system-message-title"),
    fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
    size: 16,
    color: Color.neutral[0]
  }, "System Messages"), React.createElement("div", {
    id: "".concat(id, "-system-message-container"),
    className: classes.systemMessageListContainer
  }, messages.map(function (message, index) {
    return React.createElement(React.Fragment, null, React.createElement(SystemMessageList, {
      id: "".concat(id, "-").concat(index),
      key: "".concat(message.systemName, "-").concat(index),
      message: message
    }), index !== messages.length - 1 && React.createElement(Separator, {
      type: "n100"
    }));
  })));
};

var SystemMessageList = function SystemMessageList(props) {
  var classes = useStyles(props);
  var id = props.id,
      message = props.message;
  return React.createElement("div", {
    className: classes.systemMessageList
  }, React.createElement(Grid, {
    container: true,
    spacing: 2
  }, React.createElement(Grid, {
    item: true,
    md: 1,
    style: {
      textAlign: "center"
    }
  }, React.createElement(Chip, {
    backgroundColor: getLogSeverityStyle(message.severity).backgroundColor,
    color: getLogSeverityStyle(message.severity).fontColor
  }, message.severity)), React.createElement(Grid, {
    item: true,
    md: 2
  }, React.createElement(Typography, {
    id: "".concat(id, "-system-name"),
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 14,
    color: Color.neutral[0]
  }, message.systemName)), React.createElement(Grid, {
    item: true,
    md: 5
  }, React.createElement(Typography, {
    id: "".concat(id, "-system-message"),
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 14,
    color: Color.neutral[0]
  }, message.systemMessage)), React.createElement(Grid, {
    item: true,
    md: 2
  }, React.createElement(Typography, {
    id: "".concat(id, "-system-message-date"),
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 14,
    color: Color.neutral[0]
  }, message.date)), React.createElement(Grid, {
    item: true,
    md: 2
  }, React.createElement(Typography, {
    id: "".concat(id, "-system-message-time"),
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 14,
    color: Color.neutral[0]
  }, message.time))));
};

SystemMessage.defaultProps = {
  messages: []
};
export default React.memo(SystemMessage);