import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import * as React from 'react'; // Custom Styles

import { useStyles } from "./KeyboardNumberModalStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Modal } from "../../index";
import { GreyButton } from "../../index"; // Icon

import Backspace from "../../../icons/Backspace/Backspace";
export var KeyboardNumberModal = function KeyboardNumberModal(props) {
  var classes = useStyles(props);
  var isModalOpen = props.isModalOpen,
      title = props.title,
      setInitialValue = props.setInitialValue,
      setOpenModalState = props.setOpenModalState,
      initialValue = props.initialValue,
      dateModal = props.dateModal;

  var _React$useState = React.useState(""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      display = _React$useState2[0],
      setDisplay = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      isConfirmed = _React$useState4[0],
      setIsConfirmed = _React$useState4[1];

  React.useEffect(function () {
    setDisplay(initialValue);
  }, [initialValue, isModalOpen]);
  React.useEffect(function () {
    checkIsConfirmed();
  }, [display]);

  var handleAppendChange = function handleAppendChange(value) {
    setDisplay(display.concat(value));
  };

  var handleEraseChange = function handleEraseChange() {
    setDisplay(display.substring(0, display.length - 1));
  };

  var checkIsConfirmed = function checkIsConfirmed() {
    if (dateModal) {
      if (display.length === 6) {
        setIsConfirmed(true);
      } else {
        setIsConfirmed(false);
      }
    } else {
      if (display.length > 0) {
        setIsConfirmed(true);
      } else {
        setIsConfirmed(false);
      }
    }
  };

  var modalWidth = 285;
  var modalHeight = 350;
  return React.createElement(Modal, {
    isModalOpen: isModalOpen,
    width: modalWidth,
    height: modalHeight
  }, React.createElement("div", {
    className: classes.floatingDiv
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.floatingDivBarTop
  }, React.createElement("p", null, title)), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.keyboardDisplay
  }, React.createElement("p", null, display)), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.keyboardButtonDiv
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 4
  }, React.createElement(Button, {
    className: classes.keyboardButton,
    onClick: function onClick() {
      return handleAppendChange(1);
    }
  }, React.createElement("p", null, "1"))), React.createElement(Grid, {
    item: true,
    md: 4
  }, React.createElement(Button, {
    className: classes.keyboardButton,
    onClick: function onClick() {
      return handleAppendChange(2);
    }
  }, React.createElement("p", null, "2"))), React.createElement(Grid, {
    item: true,
    md: 4
  }, React.createElement(Button, {
    className: classes.keyboardButton,
    onClick: function onClick() {
      return handleAppendChange(3);
    }
  }, React.createElement("p", null, "3"))), React.createElement(Grid, {
    item: true,
    md: 4
  }, React.createElement(Button, {
    className: classes.keyboardButton,
    onClick: function onClick() {
      return handleAppendChange(4);
    }
  }, React.createElement("p", null, "4"))), React.createElement(Grid, {
    item: true,
    md: 4
  }, React.createElement(Button, {
    className: classes.keyboardButton,
    onClick: function onClick() {
      return handleAppendChange(5);
    }
  }, React.createElement("p", null, "5"))), React.createElement(Grid, {
    item: true,
    md: 4
  }, React.createElement(Button, {
    className: classes.keyboardButton,
    onClick: function onClick() {
      return handleAppendChange(6);
    }
  }, React.createElement("p", null, "6"))), React.createElement(Grid, {
    item: true,
    md: 4
  }, React.createElement(Button, {
    className: classes.keyboardButton,
    onClick: function onClick() {
      return handleAppendChange(7);
    }
  }, React.createElement("p", null, "7"))), React.createElement(Grid, {
    item: true,
    md: 4
  }, React.createElement(Button, {
    className: classes.keyboardButton,
    onClick: function onClick() {
      return handleAppendChange(8);
    }
  }, React.createElement("p", null, "8"))), React.createElement(Grid, {
    item: true,
    md: 4
  }, React.createElement(Button, {
    className: classes.keyboardButton,
    onClick: function onClick() {
      return handleAppendChange(9);
    }
  }, React.createElement("p", null, "9"))), React.createElement(Grid, {
    item: true,
    md: 4
  }, dateModal ? React.createElement(Button, {
    className: classes.keyboardButton,
    onClick: function onClick() {
      return handleAppendChange("");
    }
  }, React.createElement("p", null, "")) : React.createElement(Button, {
    className: classes.keyboardButton,
    onClick: function onClick() {
      return handleAppendChange(".");
    }
  }, React.createElement("p", null, "."))), React.createElement(Grid, {
    item: true,
    md: 4
  }, React.createElement(Button, {
    className: classes.keyboardButton,
    onClick: function onClick() {
      return handleAppendChange(0);
    }
  }, React.createElement("p", null, "0"))), React.createElement(Grid, {
    item: true,
    md: 4
  }, React.createElement(Button, {
    className: classes.keyboardButton,
    onClick: function onClick() {
      return handleEraseChange();
    }
  }, React.createElement(Backspace, {
    style: {
      color: "black"
    }
  }))))), React.createElement(Grid, {
    item: true,
    md: 6,
    className: classes.floatingDivBarBottom
  }, React.createElement(GreyButton, {
    type: "left",
    onClick: function onClick() {
      setOpenModalState(false);
      setDisplay(initialValue);
    }
  }, "Cancel")), React.createElement(Grid, {
    item: true,
    md: 6,
    className: classes.floatingDivBarBottom
  }, React.createElement(GreyButton, {
    type: "right",
    onClick: function onClick() {
      setInitialValue(display);
      setOpenModalState(false);
      setDisplay("");
    },
    disabled: !isConfirmed
  }, "Confirm")))));
};
KeyboardNumberModal.defaultProps = {
  setInitialValue: function setInitialValue() {},
  setOpenModalState: function setOpenModalState() {},
  initialValue: "",
  dateModal: false
};
export default React.memo(KeyboardNumberModal);