import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React Lib
import React, { useState, forwardRef, useImperativeHandle } from "react"; // Component Styles

import { useStyles } from "./TopNavbarStyles"; // @otg-one/ui_components/components

import { Button } from '../index'; // @otg-one/ui_components/icons

import { OntegoOneLogoWhiteAlt, Settings, AvatarUser } from '../../icons';
import MenuIcon from '@material-ui/icons/Menu'; // @otg-one/ui_components/constants

import { Color } from "../../constants"; // Material UI Core

import Fade from '@material-ui/core/Fade';
import { Drawer, Collapse } from "@material-ui/core";
import { Separator } from "../Separator";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

var AccountBox = function AccountBox(props) {
  var classes = useStyles(props);
  var avatar = props.avatar,
      avatarKey = props.avatarKey,
      onAccountBoxClick = props.onAccountBoxClick;
  return React.createElement(React.Fragment, null, avatar ? React.createElement("div", {
    className: classes.topNavbarAccountImage,
    onClick: onAccountBoxClick
  }, React.createElement("img", {
    key: avatarKey,
    src: avatar,
    alt: "avatar",
    style: {
      width: 32,
      height: 32,
      borderRadius: "50%"
    }
  })) : React.createElement(AvatarUser, {
    className: classes.topNavbarAvatarImage,
    onClick: onAccountBoxClick
  }));
};

export var TopNavbar = forwardRef(function (props, ref) {
  var classes = useStyles(props);

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isMegaDropdownOpen = _useState2[0],
      setIsMegaDropdownOpen = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      open = _useState4[0],
      setOpen = _useState4[1];

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      openSettingDrawer = _useState6[0],
      setOpenSettingDrawer = _useState6[1];

  var id = props.id,
      isAdmin = props.isAdmin,
      avatar = props.avatar,
      avatarKey = props.avatarKey,
      onLogoClick = props.onLogoClick,
      onSettingsClick = props.onSettingsClick,
      onAccountBoxClick = props.onAccountBoxClick,
      onLogoutClick = props.onLogoutClick,
      onCustomerUsageClick = props.onCustomerUsageClick,
      onGetTheAppClick = props.onGetTheAppClick,
      onPricingAndLicenseClick = props.onPricingAndLicenseClick,
      megaDropdownComponents = props.megaDropdownComponents,
      newScenarioAvailable = props.newScenarioAvailable;

  var handleOpenMegaDropdown = function handleOpenMegaDropdown() {
    setIsMegaDropdownOpen(true);
  };

  var handleCloseMegaDropdown = function handleCloseMegaDropdown() {
    setIsMegaDropdownOpen(false);
  };

  var handleOpenSetting = function handleOpenSetting() {
    setOpenSettingDrawer(!openSettingDrawer);
  };

  var toggleDrawer = function toggleDrawer(open) {
    return function (event) {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }

      setOpenSettingDrawer(false);
      setOpen(open);
    };
  };

  var badgeNew = React.createElement("div", null);

  if (newScenarioAvailable) {
    badgeNew = React.createElement("div", {
      style: {
        outline: 'none',
        float: "right",
        marginTop: -25,
        marginRight: -5,
        backgroundColor: "#ffdf00",
        zIndex: 2,
        borderRadius: "50%",
        padding: 5,
        fontSize: 12,
        textDecoration: "none",
        color: "#000000"
      }
    });
  }

  var closeDrawer = function closeDrawer() {
    setOpenSettingDrawer(false);
    setOpen(false);
  };

  useImperativeHandle(ref, function () {
    return {
      closeDrawer: closeDrawer
    };
  });
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: classes.topNavbarContainer
  }, React.createElement("div", {
    className: classes.topNavbar
  }, React.createElement("div", {
    className: classes.topNavbarIcon
  }, React.createElement(OntegoOneLogoWhiteAlt, {
    className: classes.topNavbarLogo,
    onClick: onLogoClick
  })), React.createElement("div", {
    className: classes.topNavbarChildContainer
  }, isAdmin && React.createElement(Button, {
    type: "secondary",
    color: "white",
    onClick: onCustomerUsageClick,
    style: {
      outline: 'none',
      marginRight: 30
    }
  }, "Customer Usage Analytics"), React.createElement(Button, {
    type: "secondary",
    color: "white",
    onClick: onGetTheAppClick,
    style: {
      outline: 'none',
      marginRight: 30
    }
  }, "Get the App"), React.createElement(Button, {
    type: "secondary",
    color: "white",
    onClick: onPricingAndLicenseClick,
    style: {
      outline: 'none',
      marginRight: 10
    }
  }, "Pricing and License"), React.createElement("div", {
    className: classes.topNavbarSettingsIconContainer,
    style: {
      backgroundColor: isMegaDropdownOpen ? Color.neutral[0] : "transparent"
    },
    onMouseOver: handleOpenMegaDropdown,
    onMouseOut: handleCloseMegaDropdown
  }, React.createElement(Settings, {
    className: classes.topNavbarSettingsIcon,
    style: {
      color: isMegaDropdownOpen ? Color.primary[700] : Color.primary[100]
    },
    onClick: onSettingsClick
  }), badgeNew), React.createElement(AccountBox, {
    avatarKey: avatarKey,
    avatar: avatar,
    onAccountBoxClick: onAccountBoxClick
  }), React.createElement(Button, {
    id: "".concat(id, "logoutButton"),
    color: "white",
    onClick: onLogoutClick,
    style: {
      outline: 'none'
    }
  }, "Logout")), React.createElement("div", {
    className: classes.topNavbarMenuDropdown,
    onClick: toggleDrawer(!open)
  }, React.createElement(MenuIcon, {
    style: {
      color: "white"
    }
  }))), React.createElement(Fade, {
    in: isMegaDropdownOpen,
    timeout: 200
  }, React.createElement("div", {
    className: classes.topNavbarChildDropdown,
    onMouseOver: handleOpenMegaDropdown,
    onMouseOut: handleCloseMegaDropdown
  }, megaDropdownComponents))), React.createElement(Drawer, {
    className: classes.drawer,
    anchor: 'top',
    open: open,
    onClose: toggleDrawer(false),
    classes: {
      paper: classes.drawerPaper,
      paperAnchorTop: classes.anchorTop
    }
  }, React.createElement("div", {
    className: classes.drawerList
  }, isAdmin && React.createElement(React.Fragment, null, React.createElement("div", {
    className: classes.drawerChild,
    onClick: onCustomerUsageClick
  }, "Customer Usage Analytics"), React.createElement("div", {
    className: classes.separator
  }, React.createElement(Separator, {
    type: "n400",
    width: 1
  }))), React.createElement("div", {
    className: classes.drawerChild,
    onClick: onGetTheAppClick
  }, "Get the App"), React.createElement("div", {
    className: classes.separator
  }, React.createElement(Separator, {
    type: "n400",
    width: 1
  })), React.createElement("div", {
    className: classes.drawerChild,
    onClick: onPricingAndLicenseClick
  }, "Pricing and License"), React.createElement("div", {
    className: classes.separator
  }, React.createElement(Separator, {
    type: "n400",
    width: 1
  })), React.createElement("div", {
    className: classes.drawerChild,
    onClick: handleOpenSetting
  }, React.createElement("div", {
    className: classes.topNavbarSettingsIconContainer,
    style: {
      backgroundColor: isMegaDropdownOpen ? Color.neutral[0] : "transparent"
    }
  }, React.createElement(Settings, {
    className: classes.topNavbarSettingsIcon,
    style: {
      color: isMegaDropdownOpen ? Color.primary[700] : Color.primary[100]
    },
    onClick: onSettingsClick
  })), "Setting", badgeNew, openSettingDrawer ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null)), React.createElement("div", {
    style: {
      width: '100%'
    }
  }, React.createElement(Collapse, {
    in: openSettingDrawer,
    timeout: "auto",
    unmountOnExit: true
  }, megaDropdownComponents)), React.createElement("div", {
    className: classes.separator
  }, React.createElement(Separator, {
    type: "n400",
    width: 1
  })), React.createElement("div", {
    className: classes.drawerChild,
    onClick: onAccountBoxClick
  }, React.createElement(AccountBox, {
    avatarKey: avatarKey,
    avatar: avatar,
    onAccountBoxClick: onAccountBoxClick
  }), "Profile"), React.createElement("div", {
    className: classes.separator
  }, React.createElement(Separator, {
    type: "n400",
    width: 1
  })), React.createElement("div", {
    className: classes.drawerChild,
    onClick: onLogoutClick
  }, "Logout"))));
});
TopNavbar.defaultProps = {
  avatar: null,
  avatarKey: "",
  onLogoClick: function onLogoClick() {},
  onPricingAndLicenseClick: function onPricingAndLicenseClick() {},
  onCustomerUsageClick: function onCustomerUsageClick() {},
  onGetTheAppClick: function onGetTheAppClick() {},
  onSettingsClick: function onSettingsClick() {},
  onAccountBoxClick: function onAccountBoxClick() {},
  onLogoutClick: function onLogoutClick() {},
  isAdmin: false,
  megaDropdownComponents: React.createElement(React.Fragment, null)
};
export default React.memo(TopNavbar);