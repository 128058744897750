import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React Lib
import React, { useState } from "react"; // Component Styles

import { useStyles } from "./ApplicationCardStyles";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery'; // @otg-one/ui_components/components

import { Chip, Typography } from "../index"; // @otg-one/ui_components/icons

import { ShowMoreHorizontal } from "../../icons"; // @otg-one/ui_components/constants

import { Color, FONT_FAMILY } from "../../constants";
import { OverflowMenu } from "../OverflowMenu";
import { getFunctionIcon } from "../../utils/Utils";
export var ApplicationCard = function ApplicationCard(props) {
  var classes = useStyles(props);
  var theme = useTheme();
  var isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  var id = props.id,
      application = props.application,
      setActiveApplication = props.setActiveApplication,
      setDeactivateApplicationDialogOpen = props.setDeactivateApplicationDialogOpen,
      onClick = props.onClick,
      activateApplication = props.activateApplication;

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      anchorEl = _useState2[0],
      setAnchorEl = _useState2[1];

  var handleClickShowMoreIcon = function handleClickShowMoreIcon(event) {
    event.stopPropagation();

    if (!anchorEl) {
      console.log("OPEN", anchorEl);
      setAnchorEl(event.currentTarget);
    }

    ;
  };

  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: classes.applicationCardContainer,
    onClick: application.name != 'Receipt From Production' && application.name != 'Issue From Production' ? onClick : function () {}
  }, React.createElement("div", {
    className: classes.applicationCard
  }, getFunctionIcon(application.functionTypeId), React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 14,
    color: Color.neutral[100],
    style: {
      marginTop: 18
    }
  }, application.name), isMobile && React.createElement(React.Fragment, null, application.isChanged && React.createElement(Chip, {
    color: Color.neutral[900],
    style: {
      background: Color.alert[700],
      marginTop: 3
    }
  }, "Customized"))), isDesktop && React.createElement(React.Fragment, null, application.isChanged && React.createElement(Chip, {
    color: Color.neutral[900],
    style: {
      background: Color.alert[700],
      position: "absolute",
      top: 16,
      left: 16
    }
  }, "Customized")), application.name != 'Receipt From Production' && application.name != 'Issue From Production' && React.createElement(ShowMoreHorizontal, {
    className: classes.applicationCardShowMoreIcon,
    onClick: handleClickShowMoreIcon
  })), application.name != 'Receipt From Production' && application.name != 'Issue From Production' && React.createElement(OverflowMenu, {
    anchorElement: anchorEl,
    setAnchorEl: setAnchorEl,
    menuItems: application.isActive === 1 ? [{
      text: "Deactivate",
      onClick: function onClick() {
        setActiveApplication(application);
        setDeactivateApplicationDialogOpen(true);
      }
    }] : [{
      text: "Activate",
      destructive: true,
      onClick: function onClick() {
        setActiveApplication(application);
        activateApplication(application);
      }
    }]
  }));
};
ApplicationCard.defaultProps = {
  application: {
    name: "Goods Receipt",
    functionTypeId: 1,
    isActive: 0,
    isChanged: false
  },
  onClick: function onClick() {},
  setActiveApplication: null,
  setDeactivateApplicationDialogOpen: function setDeactivateApplicationDialogOpen() {},
  activateApplication: function activateApplication() {}
};
export default React.memo(ApplicationCard);