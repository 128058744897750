// React libraries
import * as React from 'react'; // Custom Styles

import { useStyles } from "./WhiteContentBarStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
export var WhiteContentBar = function WhiteContentBar(props) {
  var classes = useStyles(props);
  var data = props.data,
      onClick = props.onClick,
      iconColor = props.iconColor;
  return React.createElement(Grid, {
    container: true,
    className: classes.whiteContentBar,
    onClick: onClick
  }, React.createElement(Grid, {
    className: classes.leftContent,
    item: true,
    md: 6
  }, data.leftContent), data.rightIcon ? React.createElement(React.Fragment, null, React.createElement(Grid, {
    className: classes.rightContent,
    item: true,
    md: 5
  }, data.rightContent), React.createElement(Grid, {
    item: true,
    md: 1,
    container: true,
    alignItems: "center",
    justify: "flex-end"
  }, React.createElement(data.rightIcon, {
    style: {
      color: iconColor
    },
    className: classes.rightIcon
  }))) : React.createElement(Grid, {
    className: classes.rightContent,
    item: true,
    md: 6
  }, data.rightContent));
};
WhiteContentBar.defaultProps = {
  onClick: function onClick() {},
  iconColor: "#abadb1"
};
export default React.memo(WhiteContentBar);