// React Lib
import React, { useState, useEffect, useRef } from 'react';
import { Switch, Route, useHistory } from "react-router-dom";

// Pages
import MegaDropdownSettings from './AdditionalComponents/MegaDropdown/Settings';

import Register from './Pages/Register/Register';
import Login from './Pages/Login/Login';

import PartnerDashboard from './Pages/Dashboard/Partner/PartnerDashboard';
import ClientDashboard from './Pages/Dashboard/Client/ClientDashboard';
import SystemDashboard from './Pages/Dashboard/System/SystemDashboard';

import CustomerUsageDashboard from './Pages/Dashboard/CustomerUsage/CustomerUsageDashboard';

import Organization from './Pages/Organization/Organization';
import Billing from './Pages/Dashboard/Billing/Billing';

import CreateNewClient from './Pages/Dashboard/Client/CreateNewClient';
import SystemConfiguration from './Pages/Dashboard/System/SystemConfiguration';
import CreateNewUser from './Pages/Organization/CreateNewUser';

import AccountSettings from './Pages/User/AccountSettings';
import ChangePassword from './Pages/User/ChangePassword';

import GoodsReceiptConfiguration from './Pages/Configuration/GoodsReceipt/GoodsReceiptConfiguration';
import DeliveryConfiguration from './Pages/Configuration/Delivery/DeliveryConfiguration';
import PickAndPackConfiguration from './Pages/Configuration/PickAndPack/PickAndPackConfiguration';
import InventoryStatusConfiguration from './Pages/Configuration/InventoryStatus/InventoryStatusConfiguration';
import InventoryCountingConfiguration from './Pages/Configuration/InventoryCounting/InventoryCountingConfiguration';
import InventoryTransferConfiguration from './Pages/Configuration/InventoryTransfer/InventoryTransferConfiguration';
import IssueFromProductionConfiguration from './Pages/Configuration/IssueFromProduction/IssueFromProductionConfiguration';

// add Receipt From Production
import ReceiptFromProductionConfigurations from './Pages/Configuration/ReceiptFromProduction/ReceiptFromProductionConfiguration';

import ScenarioInstallationGuideDialog from './Dialogs/ScenarioInstallationGuide/ScenarioInstallationGuideDialog';
import PricingAndLicenseDialog from './Dialogs/PricingAndLicense/PricingAndLicense';
import GetTheAppDialog from './Dialogs/GetTheApp/GetTheApp';
import RestrictedCockpitDialog from './Dialogs/RestrictedCockpit/RestrictedCockpit';


import NotFound404 from './Pages/Error/NotFound404';

// Components
import { TopNavbar, BreadcrumbType, Color, Typography, FONT_FAMILY, Breadcrumb, Checkmark, Snackbar, Alert, DialogBox } from '@otg-one/ui_components';

// Material UI Core
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core';

import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Models
import { Stack } from './Models/Stack';

// Service
import AuthService from './Pages/Login/AuthService';
import PartnerService from './Pages/Dashboard/Partner/PartnerService';

// Utils
import { redirectToLogin } from './Utils/Auth';
import { redirectToRegister } from './Utils/Auth';
import { usePersistedState } from './Utils/Utils';

// Constants
import { STACK_TYPE_PARTNER, STACK_TYPE_APP_FUNCTION, STACK_TYPE_CUSTOMER, STACK_TYPE_SYSTEM } from './Constants/StackType';
import { LOGIN, REGISTER, PARTNER_DASHBOARD, CLIENT_DASHBOARD, ORGANIZATION, SYSTEM_DASHBOARD, BILLING, GOODS_RECEIPT_CONFIGURATION, SYSTEM_CONFIGURATION, CREATENEWCLIENT, DELIVERY_CONFIGURATION, PICK_AND_PACK_CONFIGURATION, INVENTORY_STATUS_CONFIGURATION, INVENTORY_COUNTING_CONFIGURATION, INVENTORY_TRANSFER_CONFIGURATION, CREATENEWUSER, CHANGE_PASSWORD, ACCOUNT_SETTINGS, CUSTOMER_USAGE_DASHBOARD, RECEIPT_FROM_PRODUCTION, ISSUE_FROM_PRODUCTION_CONFIGURATION } from './Constants/FilePath';
import { getApiUrl } from './Constants/Api';

// Etc
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ExitConfigurationDialog from './Pages/Configuration/ExitConfigurationDialog';
import AdminRoute from './Utils/Route/AdminRoute';

const jwt_decode = require('jwt-decode');

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: 2000,
            background: `rgba(0, 82, 127, 0.8)`,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline"
        },
        breadcrumbContainer: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
            },
            position: "fixed",
            display: "flex",
            alignItems: "center",
            width: '100%',
            height: 64,
            top: 64,
            backgroundColor: Color.neutral[0],
            zIndex: 1000,
        }
    })
);

// Interface
export interface IAppContextInterface {
    /** Global Loading */
    setIsLoading: any,

    /** Auth Service */
    AuthService: any,

    /** Context Data */
    currentUserData: object,
    setCurrentUserData: any,
    token: string,
    setToken: any,
    avatarKey: any,
    setAvatarKey: any,
    baseInformation: any

    /** Clients */
    clients: any,
    setClients: any,
    clientList: any,
    setClientList: any,
    clientsCostAndLogin: any,
    setClientsCostAndLogin: any,
    totalCost: any,
    setTotalCost: any,
    systemLog: any,
    setSystemLog: any,
    setBaseInformation: any,

    /** Functions */
    setupUserData: any,

    /** Breadcrumb */
    breadcrumb: any[],
    setBreadcrumb: any,
    getCurrentBreadcrumbData: (stackType: string) => any,
    handleChangeBreadcrumb: (type: string, stack: Stack) => any,
    updateBreadcrumbLabel: (type: string, label: string) => any,
    blockNavigation: boolean,
    setBlockNavigation: any,
    tempBreadcrumb: any[],
    setTempBreadcrumb: any,
    tempPath: any,
    setTempPath: any,

    /** Snackbar */
    handleShowSnackbar: any,
    handleShowAlertSnackbar: any,
    setSnackbarMessage: any,

    topNavbarLogoClick: any,
    handleUpdateScenarioVersionForUser: any,
    handleCloseNavbar: any
}

export const AppContext = React.createContext<any>(null);
const AppContextProvider = AppContext.Provider;

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

const RootContainer = (props: any) => {
    /**
     * Container size is the same as screen size
     */
    return (
        <div style={{ width: "100%", height: "100%" }}>
            {props.children}
        </div>
    )
}

let snackbarTimeout: any;
let alertSnackbarTimeout: any;

const App = (props: any) => {
    const classes = useStyles();
    const history = useHistory();
    const navbarRef = useRef<any>();

    const theme = useTheme();
    const isScreenSizeLimit = useMediaQuery(theme.breakpoints.down('md'));
    const detectSize = () => {
        if (window.innerWidth < 900) {
            return true
        } else {
            return false
        }
    }
    const [openDialogScreenLimit, setOpenDialogScreenLimit] = useState(() => detectSize())


    const handleResize = () => {
        if (window.innerWidth < 900) {
            setOpenDialogScreenLimit(true)
        } else {
            setOpenDialogScreenLimit(false)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })
    const handleCloseDialogScreenLimit = () => setOpenDialogScreenLimit(!openDialogScreenLimit)

    const cancel = (e: any) => {
        logout(e);
        handleCloseDialogScreenLimit();
    }

    const [isLoading, setIsLoading] = useState(false);

    const [clients, setClients] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [clientsCostAndLogin, setClientsCostAndLogin] = useState([]);
    const [totalCost, setTotalCost] = useState();
    const [systemLog, setSystemLog] = useState([]);

    const [currentUserData, setCurrentUserData] = useState({} as any);
    const [token, setToken] = useState("");
    const [breadcrumb, setBreadcrumb] = usePersistedState('breadcrumb', []);
    const [avatarKey, setAvatarKey] = useState("");
    const [baseInformation, setBaseInformation] = useState({} as any);

    const [scenarioInstallationDialogOpen, setScenarioInstallationDialogOpen] = useState(false);
    const [pricingAndLicenseDialogOpen, setPricingAndLicenseDialogOpen] = useState(false);
    const [getTheAppDialogOpen, setGetTheAppDialogOpen] = useState(false);

    const [exitConfigurationDialogOpen, setExitConfigurationDialogOpen] = useState(false);

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showAlertSnackbar, setShowAlertSnackbar] = useState(false);

    const [snackbarMessage, setSnackbarMessage] = useState(false);

    const [blockNavigation, setBlockNavigation] = useState(false);
    const [tempBreadcrumb, setTempBreadcrumb] = useState(breadcrumb);
    const [tempPath, setTempPath] = useState(breadcrumb);

    const handleOpenScenarioInstallationDialog = () => {
        handleCloseNavbar();
        setScenarioInstallationDialogOpen(true)
    };
    const handleCloseScenarioInstallationDialog = () => setScenarioInstallationDialogOpen(false);


    const handleOpenPricingAndLicenseDialog = () => {
        handleCloseNavbar();
        setPricingAndLicenseDialogOpen(true)
    };
    const handleClosePricingAndLicenseDialog = () => setPricingAndLicenseDialogOpen(false);

    const handleOpenGetTheAppDialog = () => {
        handleCloseNavbar();
        setGetTheAppDialogOpen(true)
    };
    const handleCloseGetTheAppDialog = () => setGetTheAppDialogOpen(false);

    const handleOpenExitConfigurationDialog = () => setExitConfigurationDialogOpen(true);
    const handleCloseExitConfigurationDialog = () => setExitConfigurationDialogOpen(false);

    const handleOpenCustomerUsagePage = () => {
        handleCloseNavbar();
        handleChangeBreadcrumb(BreadcrumbType.PUSH, new Stack('', 'Customer Usage', CUSTOMER_USAGE_DASHBOARD));
        history.push(CUSTOMER_USAGE_DASHBOARD);
    }

    const handleCloseNavbar = () => {
        if (navbarRef.current) {
            navbarRef.current.closeDrawer();
        }
    }

    const handleShowSnackbar = () => {
        setShowSnackbar(true)

        snackbarTimeout = setTimeout(() => {
            setShowSnackbar(false)
        }, 5000);
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false)
        clearTimeout(snackbarTimeout)
    };

    const handleShowAlertSnackbar = () => {
        setShowAlertSnackbar(true)

        alertSnackbarTimeout = setTimeout(() => {
            setShowAlertSnackbar(false)
        }, 5000);
    };

    const handleCloseAlertSnackbar = () => {
        setShowAlertSnackbar(false)
        clearTimeout(alertSnackbarTimeout)
    };

    useEffect(() => {
        let prevLocation: string;
        const backListener = history.listen((location, action) => {
            if (action === "POP") {
                if (location.pathname !== LOGIN && location.pathname !== REGISTER) {
                    if (AuthService.getUserInfo()) {
                        if (prevLocation.split("#")[0] !== location.pathname.split("#")[0]) {
                            setBreadcrumb(location.state);
                        }
                    } else {
                        redirectToLogin(history);
                    }
                }
            }
            prevLocation = location.pathname;
        });

        return () => {
            backListener();
        };
    }, []);

    useEffect(() => {
        /**
         * Check user authentication whenever user navigates the page
         * if is not authenticated, user is redirected to login page
         */
        if (AuthService.getUserInfo()) {
            if (checkIfTokenExpired()) {
                logoutAction();
            } else {
                setupUserData();
                window.scrollTo(0, 0);
                topNavbarLogoClick();
            }
        } else {
            if (history && history.location && history.location.pathname === REGISTER) {
                redirectToRegister(history);
            } else {
                redirectToLogin(history);
            }
        }
    }, []);

    useEffect(() => {
        if (Object.keys(currentUserData).length !== 0) {
            updateBreadcrumbLabel(STACK_TYPE_PARTNER, currentUserData.name);
        }
    }, [currentUserData]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [breadcrumb]);

    const checkIfTokenExpired = () => {
        if (!AuthService.getUserInfo()) { return true }
        const decoded = jwt_decode(AuthService.getUserInfo());
        return decoded.exp < Date.now() / 1000;
    };

    const setupUserData = () => {
        // Decode token to get user data
        const decoded = jwt_decode(AuthService.getUserInfo());
        // Set token
        AuthService.setUserToken(AuthService.getUserInfo());
        // Set current user
        AuthService.getUserByKey(decoded.key).then((res: any) => {
            setCurrentUserData(res.data);

            AuthService.getBaseInformation().then((resBaseInformation: any) => {
                const newScenarioAvailable = res.data.scenarioVersion !== resBaseInformation.data.scenarioVersion;
                const baseData = { ...resBaseInformation.data, newScenarioAvailable }
                setBaseInformation(baseData);
            });

            PartnerService.getClients(res.data.key).then((res: any) => {
                setClients(res.data);
                setClientList(res.data);
            });

            PartnerService.getTotalCost(res.data.key).then((res: any) => {
                setTotalCost(res.data);
            });

            PartnerService.getClientsCostAndLogin(res.data.key).then((res: any) => {
                setClientsCostAndLogin(res.data);
            });

            PartnerService.getSystemLog(res.data.key).then((res: any) => {
                setSystemLog(res.data);
            });
        })
    };

    const logout = (e: any) => {
        e.preventDefault();
        handleCloseNavbar();
        logoutAction();
    };

    const logoutAction = () => {
        redirectToLogin(history);
        setTempPath(LOGIN);
        if (!blockNavigation) {
            AuthService.logOut();
            setCurrentUserData({});
            setClients([]);
            setClientList([]);
            setTotalCost(undefined);
            setClientsCostAndLogin([]);
            setSystemLog([]);
            setBreadcrumb([]);
            setBaseInformation({});
        }
    };

    const handleUpdateScenarioVersionForUser = () => {
        AuthService.updateScenarioVersionForUser(currentUserData, baseInformation.scenarioVersion).then((res: any) => {
            setBaseInformation({ ...baseInformation, newScenarioAvailable: false });
        });
    }

    const topNavbarLogoClick = () => {
        handleCloseNavbar();
        if (breadcrumb.length === 0) {
            handleChangeBreadcrumb(BreadcrumbType.PUSH, new Stack(STACK_TYPE_PARTNER, currentUserData.name, PARTNER_DASHBOARD));
        } else {
            handleChangeBreadcrumb(BreadcrumbType.POP, new Stack(STACK_TYPE_PARTNER, currentUserData.name, PARTNER_DASHBOARD));
        }
    };

    const handleAccountBoxClick = () => {
        handleCloseNavbar();
        handleChangeBreadcrumb(BreadcrumbType.ACCOUNT_SETTINGS);
    };

    const updateBreadcrumbLabel = (type: string, label: string) => {
        const newBreadcrumb = [...breadcrumb];
        newBreadcrumb.forEach(breadcrumb => {
            if (breadcrumb.type === type) {
                breadcrumb.label = label;
            }
        });
        setBreadcrumb(newBreadcrumb);
    };

    const getCurrentBreadcrumbData = (stackType: string) => {
        let breadcrumbIndex = breadcrumb.length - 1;
        switch (stackType) {
            case STACK_TYPE_CUSTOMER:
                breadcrumbIndex = 1;
                break;
            case STACK_TYPE_SYSTEM:
                breadcrumbIndex = 2;
                break;
            case STACK_TYPE_APP_FUNCTION:
                breadcrumbIndex = 3;
                break;
        }

        return breadcrumb[breadcrumbIndex] && breadcrumb[breadcrumbIndex].data ? breadcrumb[breadcrumbIndex].data : {};
    };

    const handleChangeBreadcrumb = (type: string, ...stacks: any) => {
        // if (breadcrumb[breadcrumb.length - 1].type === STACK_TYPE_APP_FUNCTION) {
        //     isAllow = false;
        //     handleOpenExitConfigurationDialog()
        // }
        const newBreadcrumb = [...breadcrumb];

        let path = "";

        switch (type) {
            /** Static Path */
            case BreadcrumbType.ORGANIZATION:
                newBreadcrumb.splice(1, newBreadcrumb.length - 1, new Stack('', "Organization Detail", ORGANIZATION));
                path = ORGANIZATION;
                break;
            case BreadcrumbType.BILLING:
                newBreadcrumb.splice(1, newBreadcrumb.length - 1, new Stack('', "Billing", BILLING));
                path = BILLING;
                break;
            case BreadcrumbType.BILLING_DETAIL:
                newBreadcrumb.splice(1, newBreadcrumb.length - 1, new Stack('', "Billing", `${BILLING}#billingDetail`));
                path = `${BILLING}#billingDetail`;
                break;
            case BreadcrumbType.BILLING_HISTORY:
                newBreadcrumb.splice(1, newBreadcrumb.length - 1, new Stack('', "Billing", `${BILLING}#billingHistory`));
                path = `${BILLING}#billingHistory`;
                break;
            case BreadcrumbType.ACCOUNT_SETTINGS:
                newBreadcrumb.splice(1, newBreadcrumb.length - 1, new Stack('', "Account Settings", ACCOUNT_SETTINGS));
                path = ACCOUNT_SETTINGS;
                break;
            case BreadcrumbType.CHANGE_PASSWORD:
                newBreadcrumb.splice(1, newBreadcrumb.length - 1, new Stack('', "Account Settings", ACCOUNT_SETTINGS), new Stack('', "Change Password", CHANGE_PASSWORD));
                path = CHANGE_PASSWORD;
                break;

            /** From <Link /> or function outside breadcrumb component */
            case BreadcrumbType.PUSH:
                for (const stack of stacks) {
                    if (newBreadcrumb.length > 0) {
                        if (newBreadcrumb[newBreadcrumb.length - 1].path !== stack.path) {
                            newBreadcrumb.push(stack);
                        }
                    }
                    else if (newBreadcrumb.length === 0) {
                        newBreadcrumb.push(stack);
                    }
                    path = stack.path;
                }
                break;
            /** From <Link /> in breadcrumb component */
            case BreadcrumbType.POP:
                while (true) {
                    if (newBreadcrumb[newBreadcrumb.length - 1].path === stacks[0].path) {
                        newBreadcrumb[newBreadcrumb.length - 1] = stacks[0];
                        break;
                    }
                    else {
                        newBreadcrumb.pop();
                    }
                }
                path = stacks[0].path;
                break;
            case BreadcrumbType.BACK:
                newBreadcrumb.pop();
                path = newBreadcrumb[newBreadcrumb.length - 1].path;
                break;
        }
        if (!blockNavigation) {
            setBreadcrumb(newBreadcrumb);
        }
        setTempBreadcrumb(newBreadcrumb);
        setTempPath(path);

        history.push(path, newBreadcrumb);
    };

    const AppContextValue: IAppContextInterface = {
        setIsLoading: setIsLoading,
        AuthService: AuthService,
        currentUserData: currentUserData,
        setCurrentUserData: setCurrentUserData,
        token: token,
        setToken: setToken,
        setupUserData: setupUserData,
        avatarKey: avatarKey,
        setAvatarKey: setAvatarKey,

        clients: clients,
        setClients: setClients,
        clientList: clientList,
        setClientList: setClientList,
        clientsCostAndLogin: clientsCostAndLogin,
        setClientsCostAndLogin: setClientsCostAndLogin,
        totalCost: totalCost,
        setTotalCost: setTotalCost,
        systemLog: systemLog,
        setSystemLog: setSystemLog,
        baseInformation: baseInformation,
        setBaseInformation: setBaseInformation,

        breadcrumb: breadcrumb,
        setBreadcrumb: setBreadcrumb,
        getCurrentBreadcrumbData: getCurrentBreadcrumbData,
        handleChangeBreadcrumb: handleChangeBreadcrumb,
        updateBreadcrumbLabel: updateBreadcrumbLabel,
        blockNavigation: blockNavigation,
        setBlockNavigation: setBlockNavigation,
        tempBreadcrumb: tempBreadcrumb,
        setTempBreadcrumb: setTempBreadcrumb,
        tempPath: tempPath,
        setTempPath: setTempPath,

        handleShowSnackbar: handleShowSnackbar,
        handleShowAlertSnackbar: handleShowAlertSnackbar,
        setSnackbarMessage: setSnackbarMessage,

        topNavbarLogoClick: topNavbarLogoClick,
        handleUpdateScenarioVersionForUser: handleUpdateScenarioVersionForUser,
        handleCloseNavbar: handleCloseNavbar
    };

    useEffect(() => {
        const handleScroll = () => {
            const elem = document.getElementById('breadcrumb-container')

            if (elem) {
                const top = (window.pageYOffset || elem.scrollTop) - (elem.clientTop || 0)

                if (top > 20) {
                    elem.style.boxShadow = '0px 2px 4px 0px rgba(56, 61, 71, 0.05)';
                    elem.style.transition = 'all 0.3s ease-in-out';
                }
                else if (top < 20) {
                    elem.style.boxShadow = 'none';
                    elem.style.transition = 'all 0.3s ease-in-out';
                }
            }
        };

        window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener("scroll", handleScroll)
    });

    return (
        <RootContainer>
            <AppContextProvider value={AppContextValue}>
                {/** Only shows top navbar when user has successfully logged in */}
                {AuthService.getUserInfo() && (
                    <>
                        <TopNavbar
                            ref={navbarRef}
                            id="mainTopNavBar_"
                            onLogoClick={topNavbarLogoClick}
                            onCustomerUsageClick={handleOpenCustomerUsagePage}
                            onGetTheAppClick={handleOpenGetTheAppDialog}
                            onPricingAndLicenseClick={handleOpenPricingAndLicenseDialog}
                            onAccountBoxClick={handleAccountBoxClick}
                            isAdmin={currentUserData.role === 1}
                            onLogoutClick={logout}
                            megaDropdownComponents={<MegaDropdownSettings handleOpenScenarioInstallationDialog={handleOpenScenarioInstallationDialog} newScenarioAvailable={baseInformation.newScenarioAvailable} />}
                            avatar={currentUserData.imageuuid ? getApiUrl() + "users/" + currentUserData.key + "/userimage" : ""}
                            avatarKey={avatarKey}
                            newScenarioAvailable={baseInformation.newScenarioAvailable}
                        />
                        <div
                            id="breadcrumb-container"
                            className={classes.breadcrumbContainer}
                        >
                            <Breadcrumb
                                stacks={breadcrumb}
                                handleChangeBreadcrumb={handleChangeBreadcrumb}
                            />
                        </div>
                    </>
                )}

                <div style={{ width: '100%', height: '100%', marginTop: 140 }}>
                    <Switch>
                        {/** Login & Register Components */}
                        <Route exact path={LOGIN}>
                            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
                                <Login />
                            </GoogleReCaptchaProvider>
                        </Route>
                        <Route exact path={REGISTER}>
                            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
                                <Register />
                            </GoogleReCaptchaProvider>
                        </Route>

                        {/** Dashboard Components */}
                        <Route exact path={["/", PARTNER_DASHBOARD]}>
                            <PartnerDashboard />
                        </Route>
                        <Route exact path={CLIENT_DASHBOARD}>
                            <ClientDashboard />
                        </Route>
                        <Route exact path={SYSTEM_DASHBOARD}>
                            <SystemDashboard />
                        </Route>

                        <AdminRoute component={CustomerUsageDashboard} exact path={CUSTOMER_USAGE_DASHBOARD} />

                        {/** Client & System Components */}
                        <Route exact path={SYSTEM_CONFIGURATION}>
                            <SystemConfiguration />
                        </Route>

                        <Route exact path={CREATENEWCLIENT}>
                            <CreateNewClient />
                        </Route>
                        <Route exact path={CREATENEWUSER}>
                            <CreateNewUser />
                        </Route>

                        {/** Other Components */}
                        <Route exact path={ORGANIZATION}>
                            <Organization />
                        </Route>
                        <Route exact path={BILLING}>
                            <Billing />
                        </Route>
                        <Route exact path={ACCOUNT_SETTINGS}>
                            <AccountSettings />
                        </Route>
                        <Route exact path={CHANGE_PASSWORD}>
                            <ChangePassword />
                        </Route>

                        {/** Function Configuration Components */}
                        <Route exact path={GOODS_RECEIPT_CONFIGURATION}>
                            <GoodsReceiptConfiguration />
                        </Route>
                        <Route exact path={DELIVERY_CONFIGURATION}>
                            <DeliveryConfiguration />
                        </Route>
                        <Route exact path={PICK_AND_PACK_CONFIGURATION}>
                            <PickAndPackConfiguration />
                        </Route>
                        <Route exact path={INVENTORY_STATUS_CONFIGURATION}>
                            <InventoryStatusConfiguration />
                        </Route>
                        <Route exact path={INVENTORY_COUNTING_CONFIGURATION}>
                            <InventoryCountingConfiguration />
                        </Route>
                        <Route exact path={INVENTORY_TRANSFER_CONFIGURATION}>
                            <InventoryTransferConfiguration />
                        </Route>
                        <Route exact path={RECEIPT_FROM_PRODUCTION}>
                            <ReceiptFromProductionConfigurations />
                        </Route>
                        <Route exact path={ISSUE_FROM_PRODUCTION_CONFIGURATION}>
                            <IssueFromProductionConfiguration />
                        </Route>

                        {/** Page Not Found Components */}
                        <Route exact path="*">
                            <NotFound404 />
                        </Route>
                    </Switch >
                </div >

                {/** Backdrop for login loading */}
                < Backdrop className={classes.backdrop} open={isLoading} >
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={48}
                                color={Color.neutral[100]}
                                style={{ marginBottom: 50, marginTop: 200 }}
                            >
                                {`Welcome,`}
                            </Typography>
                            {typeof currentUserData.name !== 'undefined' && (
                                <Fade in={typeof currentUserData.name !== 'undefined'}>
                                    <Typography
                                        fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                        size={48}
                                        color={Color.neutral[100]}
                                        style={{ marginLeft: 10, marginBottom: 50, marginTop: 200 }}
                                    >
                                        {currentUserData.name}
                                    </Typography>
                                </Fade>
                            )}
                        </div>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                            size={16}
                            color={Color.neutral[100]}
                            style={{ marginBottom: 24 }}
                        >
                            {`Logging In`}
                        </Typography>
                        <CircularProgress
                            size={68}
                            thickness={4}
                            style={{ color: Color.primary[200] }}
                        />
                    </div>
                </Backdrop >

                {/** Backdrop for screen size warning */}
                {/* <Backdrop className={classes.backdrop} open={isScreenSizeLimit}>
                    <div style={{ marginTop: 100 }}>
                        <Alert style={{ width: 134, height: 134, color: Color.neutral[0] }} />
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={20}
                                color={Color.neutral[100]}
                                style={{ marginBottom: 10, marginTop: 30 }}
                            >
                                {`Display too small`}
                            </Typography>
                        </div>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                            size={14}
                            color={Color.neutral[100]}
                        >
                            {`Please use bigger display`}
                        </Typography>
                    </div>
                </Backdrop> */}

                {/* <DialogBox
                    open={openDialogScreenLimit}
                    handleClose={handleCloseDialogScreenLimit}
                    title="test"
                    content={
                        <>
                        <div>{`${openDialogScreenLimit}`}</div>
                        </>
                    }
                /> */}


                {/** Dialogs */}
                <ScenarioInstallationGuideDialog
                    open={scenarioInstallationDialogOpen}
                    onClose={handleCloseScenarioInstallationDialog}
                    newScenarioAvailable={baseInformation.newScenarioAvailable}
                    handleUpdateScenarioVersionForUser={handleUpdateScenarioVersionForUser}
                />
                <GetTheAppDialog
                    open={getTheAppDialogOpen}
                    onClose={handleCloseGetTheAppDialog}
                />
                <PricingAndLicenseDialog
                    open={pricingAndLicenseDialogOpen}
                    onClose={handleClosePricingAndLicenseDialog}
                />
                <ExitConfigurationDialog
                    open={exitConfigurationDialogOpen}
                    onClose={handleCloseExitConfigurationDialog}
                />

                <RestrictedCockpitDialog
                    open={openDialogScreenLimit}
                    onOK={handleCloseDialogScreenLimit}
                    onCancel={cancel}
                />

                {/** Snackbars */}
                <Snackbar show={showSnackbar} onClose={handleCloseSnackbar} icon={<Checkmark />}>
                    {snackbarMessage}
                </Snackbar>
                <Snackbar show={showAlertSnackbar} onClose={handleCloseAlertSnackbar} icon={<Alert />}>
                    {snackbarMessage}
                </Snackbar>
            </AppContextProvider >
        </RootContainer >
    )
}

export default App;
