// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./ModalStyles";
export var Modal = function Modal(props) {
  var classes = useStyles(props);
  var width = props.width,
      height = props.height,
      isModalOpen = props.isModalOpen,
      zIndex = props.zIndex;
  var marginLeft = width / 2 * -1;
  var marginTop = height / 2 * -1;
  return React.createElement("div", {
    style: {
      display: isModalOpen ? "block" : "none"
    }
  }, React.createElement("div", {
    className: classes.overlay
  }), React.createElement("div", {
    className: classes.modal,
    style: {
      marginLeft: marginLeft,
      marginTop: marginTop,
      height: height,
      width: width
    }
  }, props.children));
};
export default React.memo(Modal);