// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./PageWrapperStyles"; // Material-UI Core

import Paper from '@material-ui/core/Paper';
export var PageWrapper = function PageWrapper(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight;
  return React.createElement(Paper, {
    className: classes.paper,
    style: {
      width: previewWidth,
      height: previewHeight
    }
  }, props.children);
};
export default React.memo(PageWrapper);