import _objectSpread from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
// Constants
import { Color } from "../../constants/Colors";
export var useStyles = function useStyles(color) {
  return {
    multiValue: function multiValue(provided, state) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        padding: '2px 10px 2px 10px',
        background: Color.gradient[100],
        borderRadius: 16,
        pointerEvents: "none",
        "&:hover": {
          background: Color.danger[300]
        }
      });
    },
    multiValueLabel: function multiValueLabel(provided, state) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        color: Color.neutral[0],
        fontFamily: "roboto",
        fontSize: "12px",
        fontWeight: "normal",
        letterSpacing: "0px"
      });
    },
    multiValueRemove: function multiValueRemove(provided, state) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        cursor: 'pointer',
        pointerEvents: "auto",
        "& svg": {
          width: 16,
          height: 16,
          color: Color.neutral[0]
        },
        "&:hover": {
          background: 'transparent'
        }
      });
    },
    control: function control(provided, state) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        borderRadius: "0px",
        minHeight: "44px",
        border: "none",
        boxShadow: "none",
        borderBottom: "2px solid ".concat(Color.primary[600]),
        "&:hover": {
          border: "none",
          borderBottom: "2px solid ".concat(Color.primary[600])
        },
        background: color === "grey" ? Color.neutral[100] : Color.neutral[0]
      });
    },
    dropdownIndicator: function dropdownIndicator(provided, state) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        display: "none"
      });
    },
    indicatorSeparator: function indicatorSeparator(provided, state) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        display: "none"
      });
    },
    placeholder: function placeholder(provided, state) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        fontFamily: "roboto",
        color: Color.neutral[400]
      });
    },
    menu: function menu(provided, state) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        borderRadius: "0px",
        boxShadow: "0px 2px 29px 0px rgba(3, 3, 3, 0.15)"
      });
    },
    menuList: function menuList(provided, state) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        fontFamily: "roboto-light",
        color: Color.neutral[900],
        padding: 0,
        height: "40px"
      });
    },
    option: function option(provided, state) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        cursor: "pointer",
        background: Color.neutral[0],
        paddingTop: "10px",
        paddingBottom: "11px",
        "&:hover": {
          background: Color.neutral[100]
        }
      });
    }
  };
};