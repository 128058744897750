// React lib
import React, { useContext } from 'react';

// Context
import { InventoryCountingContext } from './InventoryCountingConfiguration';

// Components
import ConfigurationTitle from '../ConfigurationTitle';
import ConfigurationOption from '../ConfigurationOption';

// Constants
import { OPTION_TYPE_RADIO, OPTION_TYPE_CHECKBOX } from '../../../Constants/OptionType';
import { SETTINGS_FOR_INVENTORY_COUNTING, ITEM_SELECTION, USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM, BEST_BEFORE_DATE_INPUT_FIELD, BEST_BEFORE_DATE, SHOW_EXPECTED_QUANTITY, SHOW_WAREHOUSE_MODUS, ALLOW_ADD_INVENTORY, AUTOSELECT_COUNTING_LIST, CHOOSE_BIN_LOCATION_BY_LIST, SHOW_EXPECTED_ITEMS, CONFIRM_NEW_ITEM_NEEDED } from './InventoryCountingIds';

// Utils
import { inventoryCountingLocalMapToDatabaseValue, arraysMatch } from "../../../Utils/Utils"

const SettingsForCounting = (props: any) => {
    const {
        defaultFunctionType,
        activeOption,
        setActiveOption,
        itemSelection,
        handleChangeItemSelection,
        useOneBatchWithinOneStorageBinForAnItem,
        handleChangeUseOneBatchWithinOneStorageBinForAnItem,
        bestBeforeDate,
        handleChangeBestBeforeDate,
        bestBeforeDateInputField,
        handleChangeBestBeforeDateInputField,
        showExpectedQuantity,
        handleChangeShowExpectedQuantity,
        warehouseModus,
        handleChangeShowWarehouseModus,
        isAllowAddItem,
        handleChangeAllowAddInventory,
        isConfirmNewItem,
        handleChangeConfirmNewItem,
        isAutoselectCountingList,
        handleChangeSelectCountingList,
        isChooseLocationPrefilledList,
        handleChangeChangeBinLocation,
        isShowItemPrefilledList,
        handleChangeShowExpectedItems,
    } = useContext(InventoryCountingContext);

    const itemSelectionOption = {
        id: ITEM_SELECTION,
        title: `Item Selection`,
        subtitle: `How should the item be selected for the new position?`,
        isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.selectWarehouseInput, inventoryCountingLocalMapToDatabaseValue("selectWarehouseInput", itemSelection) as string[])) ? true : false,
        optionType: OPTION_TYPE_CHECKBOX,
        allowEmpty: false,
        isValid: itemSelection.length > 0 ? true : false,
        options: [
            {
                label: `Manual text input of the item number`,
                value: `textInput`,
                checked: itemSelection.includes("textInput"),
                onChange: handleChangeItemSelection,
                disabled: false
            },
            {
                label: `Barcode Scan`,
                value: `barcodeScan`,
                checked: itemSelection.includes("barcodeScan"),
                onChange: handleChangeItemSelection,
                disabled: false
            },
            {
                label: `OCR Scan ( Unavailable )`,
                value: `OCR Scan ( Unavailable )`,
                disabled: true
            }
        ],
        collapsedOption: []
    }

    const useOneBatchWithinOneStorageBinForAnItemOption = {
        id: USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM,
        title: `Use one batch within one storage bin for an item`,
        subtitle: `Use at most one batch per article for one article`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.allowOneBatchForOneBinLoc !== inventoryCountingLocalMapToDatabaseValue("allowOneBatchForOneBinLoc", useOneBatchWithinOneStorageBinForAnItem)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `disable`,
                onChange: handleChangeUseOneBatchWithinOneStorageBinForAnItem,
                checked: useOneBatchWithinOneStorageBinForAnItem === `disable`,
                disabled: false,
                label: `Disable`,
                subLabel: ``
            },
            {
                value: `enable`,
                onChange: handleChangeUseOneBatchWithinOneStorageBinForAnItem,
                checked: useOneBatchWithinOneStorageBinForAnItem === `enable`,
                disabled: false,
                label: `Enable`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const bestBeforeDateOption = {
        id: BEST_BEFORE_DATE,
        title: `Best Before Date`,
        subtitle: `Defines if the best before date must be configured for batches`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.bestBeforeDate !== inventoryCountingLocalMapToDatabaseValue("bestBeforeDate", bestBeforeDate)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `noBbd`,
                label: `Do not capture best before date`,
                onChange: handleChangeBestBeforeDate,
                checked: bestBeforeDate === 'noBbd',
                subLabel: ``,
                disabled: false,
            },
            {
                value: `optionalBbd`,
                label: `Capture best before date optional`,
                onChange: handleChangeBestBeforeDate,
                checked: bestBeforeDate === 'optionalBbd',
                subLabel: ``,
                disabled: false,
            },
            {
                value: `showBbd`,
                label: `Capture best before date`,
                onChange: handleChangeBestBeforeDate,
                checked: bestBeforeDate === 'showBbd',
                subLabel: ``,
                disabled: false,
            },
        ],
        collapsedOption: []
    }

    const bestBeforeDateInputFieldOption = {
        id: BEST_BEFORE_DATE_INPUT_FIELD,
        title: `Best Before Date Input Field`,
        subtitle: `Select the type of input filed of best before date`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.bestBeforeInputType !== inventoryCountingLocalMapToDatabaseValue("bestBeforeInputType", bestBeforeDateInputField)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `inputField`,
                label: `Input field`,
                onChange: handleChangeBestBeforeDateInputField,
                checked: bestBeforeDateInputField === 'inputField',
                subLabel: ``,
                disabled: false,
            },
            {
                value: `datePicker`,
                label: `Date picker`,
                onChange: handleChangeBestBeforeDateInputField,
                checked: bestBeforeDateInputField === 'datePicker',
                subLabel: ``,
                disabled: false,
            }
        ],
        collapsedOption: []
    }

    const showExpectedQuantityOption = {
        id: SHOW_EXPECTED_QUANTITY,
        title: `Show Expected Quantity`,
        subtitle: `Show expected quantity for items`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.showExpectedQuantity !== inventoryCountingLocalMapToDatabaseValue("showExpectedQuantity", showExpectedQuantity)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `hide`,
                onChange: handleChangeShowExpectedQuantity,
                checked: showExpectedQuantity === `hide`,
                disabled: false,
                label: `Don't Show`,
                subLabel: ``
            },
            {
                value: `show`,
                onChange: handleChangeShowExpectedQuantity,
                checked: showExpectedQuantity === `show`,
                disabled: false,
                label: `Show`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const showWarehouseModus = {
        id: SHOW_WAREHOUSE_MODUS,
        title: `Show Warehouse Name`,
        subtitle: `Show Warehouse Code or Warehouse Name`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.warehouseLayout !== inventoryCountingLocalMapToDatabaseValue("warehouseLayout", warehouseModus)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `warehouseCode`,
                onChange: handleChangeShowWarehouseModus,
                checked: warehouseModus === `warehouseCode`,
                disabled: false,
                label: `Warehouse Code`,
                subLabel: ``
            },
            {
                value: `warehouseName`,
                onChange: handleChangeShowWarehouseModus,
                checked: warehouseModus === `warehouseName`,
                disabled: false,
                label: `Warehouse Name`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const allowAddNewItem = {
        id: ALLOW_ADD_INVENTORY,
        title: `Add Not Available Items`,
        subtitle: `Allow count items for bin location, which is not available in this bin location`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.allowAddInventory !== inventoryCountingLocalMapToDatabaseValue("allowAddInventory", isAllowAddItem)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: 'true',
                onChange: handleChangeAllowAddInventory,
                checked: isAllowAddItem === 'true',
                disabled: false,
                label: `Allow Add Items`,
                subLabel: ``
            },
            {
                value: 'false',
                onChange: handleChangeAllowAddInventory,
                checked: isAllowAddItem === 'false',
                disabled: false,
                label: `Don't Allow`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const confirmAddNewItem = {
        id: CONFIRM_NEW_ITEM_NEEDED,
        heading: `Add Not Available Items`,
        title: `Confirm Not Available Item Needed`,
        subtitle: `Confirm count item which is not available on current bin location`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.confirmNewItemNeeded !== inventoryCountingLocalMapToDatabaseValue("confirmNewItemNeeded", isConfirmNewItem)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: 'true',
                onChange: handleChangeConfirmNewItem,
                checked: isConfirmNewItem === 'true',
                disabled: false,
                label: `Confirm Needed`,
                subLabel: ``
            },
            {
                value: 'false',
                onChange: handleChangeConfirmNewItem,
                checked: isConfirmNewItem === 'false',
                disabled: false,
                label: `Don't Confirm`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const autoSelectCountingList = {
        id: AUTOSELECT_COUNTING_LIST,
        title: `Autoselect Counting List`,
        subtitle: `Select counting list, when only one list is available`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.selectInventoryListIfOnlyOneAvailable !== inventoryCountingLocalMapToDatabaseValue("selectInventoryListIfOnlyOneAvailable", isAutoselectCountingList)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: 'true',
                onChange: handleChangeSelectCountingList,
                checked: isAutoselectCountingList === 'true',
                disabled: false,
                label: `Autoselect`,
                subLabel: ``
            },
            {
                value: 'false',
                onChange: handleChangeSelectCountingList,
                checked: isAutoselectCountingList === 'false',
                disabled: false,
                label: `No Autoselect`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const chooseBinByLocation = {
        id: CHOOSE_BIN_LOCATION_BY_LIST,
        title: `Choose Bin Location By List`,
        subtitle: `Select bin location by prefilled list`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.chooseBinLocByList !== inventoryCountingLocalMapToDatabaseValue("chooseBinLocByList", isChooseLocationPrefilledList)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: 'true',
                onChange: handleChangeChangeBinLocation,
                checked: isChooseLocationPrefilledList === 'true',
                disabled: false,
                label: `Prefilled List`,
                subLabel: ``
            },
            {
                value: 'false',
                onChange: handleChangeChangeBinLocation,
                checked: isChooseLocationPrefilledList === 'false',
                disabled: false,
                label: `Empty List`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const showExpectedItems = {
        id: SHOW_EXPECTED_ITEMS,
        title: `Show Expected Items`,
        subtitle: `Show items which are exspected for selected bin location`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.showExpectedItems !== inventoryCountingLocalMapToDatabaseValue("showExpectedItems", isShowItemPrefilledList)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: 'true',
                onChange: handleChangeShowExpectedItems,
                checked: isShowItemPrefilledList === 'true',
                disabled: false,
                label: `Prefilled List`,
                subLabel: ``
            },
            {
                value: 'false',
                onChange: handleChangeShowExpectedItems,
                checked: isShowItemPrefilledList === 'false',
                disabled: false,
                label: `Empty List`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    return (
        <>
            <ConfigurationOption configurationOption={itemSelectionOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={useOneBatchWithinOneStorageBinForAnItemOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={bestBeforeDateOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={bestBeforeDateInputFieldOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={showExpectedQuantityOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={showWarehouseModus} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={allowAddNewItem} activeOption={activeOption} setActiveOption={setActiveOption} />
            {
                isAllowAddItem === 'true' &&
                <ConfigurationOption configurationOption={confirmAddNewItem} activeOption={activeOption} setActiveOption={setActiveOption} />
            }
            <ConfigurationOption configurationOption={autoSelectCountingList} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={chooseBinByLocation} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={showExpectedItems} activeOption={activeOption} setActiveOption={setActiveOption} />
        </>
    );
};

export default SettingsForCounting;