import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React Lib
import React, { useState } from "react"; // Component Styles

import { useStyles } from "./LatestActivityStyles"; // @otg-one/ui_components/components

import { Button, Typography, Separator } from "../index"; // @otg-one/ui_components/icons

import { Settings } from '../../icons'; // @otg-one/ui_components/constants

import { Color, FONT_FAMILY } from "../../constants";
export var LatestActivity = function LatestActivity(props) {
  var classes = useStyles(props);
  var id = props.id,
      activities = props.activities;
  return React.createElement("div", {
    id: id,
    className: classes.latestActivityContainer
  }, React.createElement(Typography, {
    id: "".concat(id, "-latest-activity"),
    fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
    size: 16,
    color: Color.neutral[0]
  }, "Latest Activity"), React.createElement("div", {
    id: "".concat(id, "-latest-activity-list-container"),
    className: classes.latestActivityListContainer
  }, activities.map(function (activity, index) {
    return React.createElement(React.Fragment, null, React.createElement(LatestActivityList, {
      id: "".concat(id, "-").concat(index),
      key: "".concat(activity.name, "-").concat(index),
      activity: activity
    }), index !== activities.length - 1 && React.createElement(Separator, null));
  })), React.createElement(Button, {
    id: "".concat(id, "-latest-activity-monitor-button"),
    type: "secondary",
    color: "white",
    alignIcon: "right",
    style: {
      marginTop: 5
    }
  }, "Go To Activity Monitor"));
};

var LatestActivityList = function LatestActivityList(props) {
  var classes = useStyles(props);

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isHover = _useState2[0],
      setIsHover = _useState2[1];

  var id = props.id,
      activity = props.activity;

  var handleOnMouseOver = function handleOnMouseOver() {
    setIsHover(true);
  };

  var handleOnMouseOut = function handleOnMouseOut() {
    setIsHover(false);
  };

  return React.createElement("div", {
    className: classes.latestActivityList,
    onMouseOver: handleOnMouseOver,
    onMouseOut: handleOnMouseOut
  }, React.createElement("div", {
    id: "".concat(id, "-activity-picture"),
    className: classes.activityListPicture
  }, React.createElement("img", {
    src: activity.img,
    width: "40",
    height: "40",
    style: {
      borderRadius: "50%",
      objectFit: "contain"
    }
  })), React.createElement("div", {
    className: classes.activityListName
  }, React.createElement(Typography, {
    id: "".concat(id, "-activity-name"),
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 14,
    color: Color.neutral[300]
  }, activity.name)), React.createElement("div", {
    className: classes.activityListInfo
  }, React.createElement(Typography, {
    id: "".concat(id, "-activity-info"),
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 14,
    color: Color.neutral[300]
  }, activity.activity)), React.createElement("div", {
    className: classes.activityListDate
  }, React.createElement(Typography, {
    id: "".concat(id, "-activity-date"),
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 14,
    color: Color.neutral[300]
  }, activity.date)), React.createElement("div", {
    className: classes.activityListTime
  }, React.createElement(Typography, {
    id: "".concat(id, "-activity-time"),
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 14,
    color: Color.neutral[300]
  }, activity.time)), isHover && React.createElement("div", {
    className: classes.activityListAction
  }, React.createElement(Settings, {
    id: "".concat(id, "-activity-settings"),
    style: {
      width: 24,
      height: 24,
      color: Color.primary[600]
    }
  })));
};

LatestActivity.defaultProps = {
  activities: []
};
export default React.memo(LatestActivity);