// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    barcodeIcon: {
      fontSize: "90px !important",
      width: "90px !important",
      height: "90px !important"
    },
    arrowDownIcon: {
      fontSize: "19px !important",
      width: "19px !important",
      height: "19px !important",
      color: "#d0d0d0 !important",
      "& svg": {
        color: "#d0d0d0 !important"
      },
      marginTop: "40px",
      marginBottom: "40px"
    },
    noPreviewContent: {
      textAlign: "center",
      fontSize: "14px"
    }
  });
});