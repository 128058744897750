// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    blueButton: {
      background: "#00a7dd !important",
      borderRadius: 8,
      width: "80%",
      border: 0,
      color: "white !important",
      height: 48,
      padding: "0 30px",
      textTransform: "capitalize",
      fontSize: "16px"
    }
  });
});