import React from 'react';

// Components
import {
    DialogBox,
    Button,
    Typography,
    FONT_FAMILY,
    Color
} from '@otg-one/ui_components';

const BrowserButtonClickedDialog = (props: any) => {
    const { open, onClose } = props

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title="Browser Back or Forward Button Clicked"
            content={
                <div style={{ width: 600 }}>
                    <Typography
                        fontFamily={FONT_FAMILY.CAPITOLIUM_ITALIC}
                        size={16}
                        color={Color.neutral[900]}
                        style={{ marginBottom: 50 }}
                    >
                        {`Changes has been made to this function configuration. Browser Back and Forward button are not allowed.`}
                    </Typography>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button style={{ outline: 'none' }} onClick={onClose}>
                            {`OK`}
                        </Button>
                    </div>
                </div>
            }
        />
    )
};

export default BrowserButtonClickedDialog;