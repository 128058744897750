export class ReceiptFromProduction {

	public static DOC_INPUT_TYPE_MANUAL = "manual";
	public static DOC_INPUT_TYPE_SCAN = "scan";
	public static DOC_INPUT_TYPE_OCR = "ocr"; // UNAVAILABLE

	public static DISPLAYED_COLUMNS_TEMPLATE_A = "template_a";
	public static DISPLAYED_COLUMNS_TEMPLATE_B = "template_b";

	public static ALLOW_QTY_CHANGE_LOWER_WITH_SECURITY = "lower_with_security";
	public static ALLOW_QTY_CHANGE_LOWER_WITHOUT_SECURITY = "lower_without_security";
	public static ALLOW_QTY_CHANGE_HIGHER_WITH_SECURITY = "higher_with_security";
	public static ALLOW_QTY_CHANGE_HIGHER_WITHOUT_SECURITY = "higher_without_security";

	public static WAREHOUSE_LAYOUT_WAREHOUSE_NAME = "warehouseName";
	public static WAREHOUSE_LAYOUT_WAREHOUSE_CODE = "warehouseCode";

	public static COMPLETE_ORDER_MODE_AT_LEAST_ONCE = "atLeastOnce";
	public static COMPLETE_ORDER_MODE_ALL = "all";


	public docInputType: string[];
	public displayedColumns: string;
	public quickCheck: boolean;
	public allowQtyChange: string[];
	public warehouseLayout: string;
	public completeOrderMode: string;

	public constructor() {
		this.docInputType = [ReceiptFromProduction.DOC_INPUT_TYPE_SCAN];
		this.displayedColumns = ReceiptFromProduction.DISPLAYED_COLUMNS_TEMPLATE_A;
		this.quickCheck = true;
		this.allowQtyChange = [];
		this.warehouseLayout = ReceiptFromProduction.WAREHOUSE_LAYOUT_WAREHOUSE_NAME;
		this.completeOrderMode = ReceiptFromProduction.COMPLETE_ORDER_MODE_AT_LEAST_ONCE;
	}
}
