// React lib
import React, { useContext } from 'react';

// Context
import { IssueFromProductionContext } from './IssueFromProductionConfiguration';

// Components
import ConfigurationTitle from '../ConfigurationTitle';
import ConfigurationOption from '../ConfigurationOption';

// Constants
import { OPTION_TYPE_RADIO, OPTION_TYPE_CHECKBOX } from '../../../Constants/OptionType';
import { TYPE_OF_ORDER_INPUT } from './IssueFromProductionIds';

// Utils
import { issueFromProductionLocalMapToDatabaseValue, arraysMatch } from "../../../Utils/Utils"

const SettingsForEntry = (props: any) => {
    const {
        defaultFunctionType,
        activeOption,
        setActiveOption,
        typeOfOrderInput,
        handleChangeTypeOfOrderInput
    } = useContext(IssueFromProductionContext);

    const typeOfOrderInputOption = {
        id: TYPE_OF_ORDER_INPUT,
        heading: 'ENTRY OPTION - VIA ORDER NUMBER',
        title: `Type of Order Input`,
        subtitle: `Defines which types of input are available for the user`,
        isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.docInputType, issueFromProductionLocalMapToDatabaseValue("docInputType", typeOfOrderInput) as string[])) ? true : false,
        optionType: OPTION_TYPE_CHECKBOX,
        allowEmpty: false,
        isValid: typeOfOrderInput.length > 0 ? true : false,
        options: [
            {
                label: `Text input (manually via keypad)`,
                value: `textInput`,
                checked: typeOfOrderInput.includes("textInput"),
                onChange: handleChangeTypeOfOrderInput,
                disabled: false
            },
            {
                label: `Barcode scan`,
                value: `barcodeScan`,
                checked: typeOfOrderInput.includes("barcodeScan"),
                onChange: handleChangeTypeOfOrderInput,
                disabled: false
            },
            {
                label: `OCR scan (Unavailable)`,
                value: `OCR scan (Unavailable)`,
                disabled: true
            },
        ],
        collapsedOption: []
    };

    return (
        <ConfigurationOption configurationOption={typeOfOrderInputOption} activeOption={activeOption} setActiveOption={setActiveOption} />
    );
};

export default SettingsForEntry;