// React Lib
import React from "react"; // Component Styles

import { useStyles } from "./TotalCostStyles"; // @otg-one/ui_components/components

import { Button, Typography } from "../index"; // @otg-one/ui_components/constants

import { Color, FONT_FAMILY } from "../../constants"; // Utils

import { formatNumericalWithThousandSeparator } from "../../utils/Utils";
export var TotalCost = function TotalCost(props) {
  var classes = useStyles(props);
  var id = props.id,
      totalCost = props.totalCost,
      actionHandler = props.actionHandler;
  return React.createElement("div", {
    id: "".concat(id, "-total-cost-container"),
    className: classes.totalCostContainer
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
    size: 16,
    color: Color.neutral[100]
  }, "Total Cost This Month"), React.createElement("div", {
    id: "".concat(id, "-total-cost"),
    className: classes.totalCost
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.CAPITOLIUM_BOLD,
    size: 56,
    color: Color.neutral[0]
  }, "".concat(formatNumericalWithThousandSeparator(totalCost.price), " \u20AC")), React.createElement(Typography, {
    fontFamily: FONT_FAMILY.CAPITOLIUM_ITALIC,
    size: 12,
    color: Color.neutral[0]
  }, " ")), actionHandler ? React.createElement(Button, {
    id: "".concat(id, "-total-price-box-button"),
    type: "secondary",
    color: "white",
    alignIcon: "right",
    style: {
      outline: "none"
    },
    onClick: actionHandler
  }, "Go To Billing") : React.createElement("div", {
    style: {
      height: 20
    }
  }));
};
TotalCost.defaultProps = {
  totalCost: {
    price: 0,
    billedDate: "31 January 2019"
  }
};
export default React.memo(TotalCost);