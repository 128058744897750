

import axios from 'axios';

import { getApiUrl } from '../../../Constants/Api';


export class ClientService {

    getTotalCost(userKey: string, clientKey: string) {
        return axios.get(getApiUrl() + "clients/" + clientKey + "/totalcosts?userkey=" + userKey);
    }

    getClientsCostAndLogin(clientKey: string, year: number = -1) {
        return axios.get(getApiUrl() + "clients/" + clientKey + "/clientcostandlogin?year=" + year);
    }

    createClient(userKey: string, client: object) {
        return axios.post(getApiUrl() + "clients?userkey=" + userKey, client);
    }

    getSystems(userKey: string, clientKey?: string) {
        if (clientKey) {
            return axios.get(getApiUrl() + "systems?userkey=" + userKey + "&clientkey=" + clientKey);
        } else {
            return axios.get(getApiUrl() + "systems?userkey=" + userKey);
        }
    }

    updateClientImage(userKey: string, clientKey: string, files: any) {
        let formData = new FormData();
        formData.append("clientimage", files[0]);
        return axios.post(getApiUrl() + "clients/" + clientKey + "/clientimage?userkey=" + userKey, formData);
    }

    deleteSystem(userKey: string, systemKey: string) {
        return axios.delete(getApiUrl() + "systems/" + systemKey + "?userkey=" + userKey);
    }

    updateClient(userKey: string, client: any) {
        return axios.put(getApiUrl() + "clients/" + client.key + "?userkey=" + userKey, client);
    }

    updateClientDeviceLimit(userKey: string, client: any) {
        return axios.put(getApiUrl() + "clients/" + client.key + "/devicelimit?userkey=" + userKey, client);
    }

    copySystemFunctionConfiguration(userKey: string, systemKey: string, originSystemKey: string) {
        return axios.put(getApiUrl() + "systems/" + systemKey + "/copyfunctionconfig?userkey=" + userKey + "&originsystemkey=" + originSystemKey);
    }

}

export default new ClientService();