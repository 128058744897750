// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FONT_FAMILY } from "../../../constants";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    paper: {
      position: "relative",
      overflow: "hidden",
      boxSizing: "border-box",
      fontFamily: FONT_FAMILY.ROBOTO_LIGHT
    }
  });
});