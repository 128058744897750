// React lib
import React, { useContext } from 'react';

// Context
import { ReceiptFromProductionContext } from './ReceiptFromProductionConfiguration';

// Components
import ConfigurationTitle from '../ConfigurationTitle';
import ConfigurationOption from '../ConfigurationOption';

// Constants
import { OPTION_TYPE_RADIO, OPTION_TYPE_CHECKBOX } from '../../../Constants/OptionType';
import { DISPLAYED_COLUMN, NEW_POSITION_ADDABLE, QUICK_CHECK, WAREHOUSE_NAME, COMPLETE_ORDER, ORDER_POSITION_COUNT, GS1_128_BARCODE_SCAN, GS1_128_BARCODE_SCAN_LEADING_ZEROS, ALLOW_EDIT_BUSINESS_PARTNER_REFERENCE_NUMBER, ITEM_SELECTION, USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM, DRAFT_DOCUMENTS_FOR_NEW_LINE } from './ReceiptFromProductionIds';

// Utils
import { goodsReceiptLocalMapToDatabaseValue, arraysMatch } from "../../../Utils/Utils"

const SettingsForListPositions = (props: any) => {
    const {
        defaultFunctionType,
        activeOption,
        setActiveOption,
        displayedColumn,
        handleChangeDisplayedColumn,
        newPositionAddable,
        handleChangeNewPositionAddable,
        itemSelection,
        handleChangeItemSelection,
        quickCheck,
        handleChangeQuickCheck,
        warehouseName,
        handleChangeWarehouseName,
        completeOrder,
        handleChangeCompleteOrder,
        gs1128BarcodeScan,
        handleChangeGs1128BarcodeScan,
        gs1128BarcodeScanLeadingZeros,
        handleChangeGs1128BarcodeScanLeadingZeros,
        allowEditBusinessPartnerReferenceNumber,
        handleChangeAllowEditBusinessPartnerReferenceNumber,
        useOneBatchWithinOneStorageBinForAnItem,
        handleChangeUseOneBatchWithinOneStorageBinForAnItem,
        draftDocumentsForNewLine,
        handleChangeDraftDocumentsForNewLine
    } = useContext(ReceiptFromProductionContext);

    const displayedColumnOption = {
        id: DISPLAYED_COLUMN,
        heading: '',
        title: `Displayed Column`,
        subtitle: `Select appearance of the position list`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.displayedColumns !== goodsReceiptLocalMapToDatabaseValue("displayedColumns", displayedColumn)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `templateA`,
                onChange: handleChangeDisplayedColumn,
                checked: displayedColumn === `templateA`,
                disabled: false,
                label: `Template A`,
                subLabel: `Column Names in Line List`
            },
            {
                value: `templateB`,
                onChange: handleChangeDisplayedColumn,
                checked: displayedColumn === `templateB`,
                disabled: false,
                label: `Template B`,
                subLabel: `Column Names In the Header List`
            }
        ],
        collapsedOption: []
    }

    const quickCheckOption = {
        id: QUICK_CHECK,
        heading: '',
        title: `Quick Check`,
        subtitle: `Defines whether a position can be booked directly from the list (via checkbox). In that case the warehouse as defined in the order will be chosen automatically. The quickcheck checkbox will be hidden for all items with mandatory batch or serial numbers`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.quickCheck !== goodsReceiptLocalMapToDatabaseValue("quickCheck", quickCheck)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `disable`,
                onChange: handleChangeQuickCheck,
                checked: quickCheck === `disable`,
                disabled: false,
                label: `Disable`,
                subLabel: ``
            },
            {
                value: `enable`,
                onChange: handleChangeQuickCheck,
                checked: quickCheck === `enable`,
                disabled: false,
                label: `Enable`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const warehouseNameOption = {
        id: WAREHOUSE_NAME,
        heading: '',
        title: `Warehouse Name`,
        subtitle: `Select display variant for the warehouses`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.warehouseLayout !== goodsReceiptLocalMapToDatabaseValue("warehouseLayout", warehouseName)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `warehouseName`,
                onChange: handleChangeWarehouseName,
                checked: warehouseName === `warehouseName`,
                disabled: false,
                label: `Show Warehouse Name`,
                subLabel: ``
            },
            {
                value: `warehouseCode`,
                onChange: handleChangeWarehouseName,
                checked: warehouseName === `warehouseCode`,
                disabled: false,
                label: `Show Warehouse Code`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const completedOrderOption = {
        id: COMPLETE_ORDER,
        heading: '',
        title: `Complete Order`,
        subtitle: `Defines how many positions must be completed before finishing an order`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.completeOrderMode !== goodsReceiptLocalMapToDatabaseValue("completeOrderMode", completeOrder)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `atLeastOnePosition`,
                onChange: handleChangeCompleteOrder,
                checked: completeOrder === `atLeastOnePosition`,
                disabled: false,
                label: `At least one position`,
                subLabel: ``
            },
            {
                value: `allPositions`,
                onChange: handleChangeCompleteOrder,
                checked: completeOrder === `allPositions`,
                disabled: false,
                label: `All positions`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    return (
        <>
            <ConfigurationOption configurationOption={displayedColumnOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={quickCheckOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={warehouseNameOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={completedOrderOption} activeOption={activeOption} setActiveOption={setActiveOption} />
        </>
    );
};

export default SettingsForListPositions;