// React libraries
import * as React from 'react'; // Custom Styles

import { useStyles } from "./WarningModalStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Modal } from "../../index";
export var WarningModal = function WarningModal(props) {
  var classes = useStyles(props);
  var modalWidth = 285;
  var modalHeight = 150;
  var isModalOpen = props.isModalOpen,
      data = props.data,
      onClickRedButton = props.onClickRedButton,
      onClickGreenButton = props.onClickGreenButton,
      modalType = props.modalType;
  return React.createElement(Modal, {
    isModalOpen: isModalOpen,
    width: modalWidth,
    height: modalHeight
  }, React.createElement("div", {
    className: classes.floatingDiv
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    container: true,
    item: true,
    md: 12,
    className: classes.floatingDivContent,
    alignItems: "center",
    justify: "center",
    style: {
      height: modalHeight - 50
    }
  }, data.message), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.floatingDivBarBottom
  }, React.createElement(Grid, {
    container: true
  }, modalType === "singleOption" && React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(Button, {
    className: classes.redButton,
    onClick: onClickRedButton
  }, "Ok")), modalType === "doubleOption" && React.createElement(React.Fragment, null, React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(Button, {
    className: classes.redButton,
    onClick: onClickRedButton
  }, "Yes")), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(Button, {
    className: classes.greenButton,
    onClick: onClickGreenButton
  }, "No"))))))));
};
WarningModal.defaultProps = {
  setOpenModalState: function setOpenModalState() {},
  onClickRedButton: function onClickRedButton() {},
  onClickGreenButton: function onClickGreenButton() {}
};
export default React.memo(WarningModal);