import React, { useEffect, useState } from 'react';

// Components
import {
    DialogBox,
    Button,
    Typography,
    FONT_FAMILY,
    Color,
    Barcode,
    Download
} from '@otg-one/ui_components';

import Grid from '@material-ui/core/Grid';

import { ReactComponent as AndroidQRCode } from './android_qr.svg';
import { ReactComponent as IOSQRCode } from './ios_qr.svg';

import { ReactComponent as AndroidStore } from './android_store.svg';
import { ReactComponent as IOSStore } from './ios_store.svg';
import { useStyles } from './GetTheAppStyles';
import { Hidden } from '@material-ui/core';

const GetTheAppDialog = (props: any) => {
    const classes = useStyles(props);
    const { open, onClose } = props;

    var os = "IOS or Android?";

    var store = <div className={classes.qrCodeContainer} >
        <div style={{ maxHeight: 180, textAlign: "center" }}>
            <Hidden smDown>
                <IOSQRCode />
            </Hidden>
            <a rel="noopener noreferrer" target="_blank" href="https://apps.apple.com/us/app/ontegoone/id1496118481" style={{ textDecoration: "none" }}>
                <div className={classes.storeIcon}>
                    <IOSStore />
                </div>
            </a>
        </div>
        <div style={{ maxHeight: 180, textAlign: "center" }}>
            <Hidden smDown>
                <AndroidQRCode />
            </Hidden>
            <a rel="noopener noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=de.commsult.ontego.one.logistics&gl=DE" style={{ textDecoration: "none" }}>
                <div className={classes.storeIcon}>
                    <AndroidStore />
                </div>
            </a>
        </div>
    </div>;

    if (navigator.userAgent.match(/(iPhone|iPod|iPad)/)) {
        os = "";
        store = <div className={classes.qrCodeContainer} style={{ justifyContent: "center" }}>
            <div style={{ maxHeight: 180, textAlign: "center" }}>
                <Hidden smDown>
                    <IOSQRCode />
                </Hidden>
                <a rel="noopener noreferrer" target="_blank" href="https://apps.apple.com/us/app/ontegoone/id1496118481" style={{ textDecoration: "none" }}>
                    <div className={classes.storeIcon}>
                        <IOSStore />
                    </div>
                </a>
            </div>
        </div>;
    } else if (navigator.userAgent.match(/(Android)/)) {
        os = "";
        store = <div className={classes.qrCodeContainer} style={{ justifyContent: "center" }}>
            <div style={{ maxHeight: 180, textAlign: "center" }}>
                <Hidden smDown>
                    <AndroidQRCode />
                </Hidden>
                <a rel="noopener noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=de.commsult.ontego.one.logistics&gl=DE" style={{ textDecoration: "none" }}>
                    <div className={classes.storeIcon}>
                        <AndroidStore />
                    </div>
                </a>
            </div>
        </div>;
    }

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title="Get the app"
            content={
                <div style={{ maxWidth: 900, paddingTop: 16, overflow: "hidden" }}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <Hidden smDown>
                                <Typography
                                    fontFamily={FONT_FAMILY.CAPITOLIUM_ITALIC}
                                    size={16}
                                    color={Color.neutral[900]}
                                    style={{ marginBottom: 22 }}
                                >
                                    {os}
                                </Typography>
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                    size={14}
                                    color={Color.neutral[900]}
                                    style={{ marginBottom: 20, height: 40 }}
                                >
                                    {`Please scan the QR Code to follow the link to the App store.`}
                                </Typography>

                            </Hidden>

                            <Hidden mdUp>
                                <Typography
                                    fontFamily={FONT_FAMILY.CAPITOLIUM_ITALIC}
                                    size={16}
                                    color={Color.neutral[900]}
                                    style={{ marginBottom: 15, textAlign: "center" }}
                                >
                                    {os}
                                </Typography>

                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                    size={14}
                                    color={Color.neutral[900]}
                                    style={{ textAlign: "center" }}
                                >
                                    {`Please click the icon to follow the link to the App store.`}
                                </Typography>
                            </Hidden>

                            {store}
                        </Grid>
                        <Grid item md={1} xs={12} />
                        <Grid item md={5} xs={12}>
                            <div style={{ textAlign: "center" }}>
                                <Hidden smDown>
                                    <Typography
                                        fontFamily={FONT_FAMILY.CAPITOLIUM_ITALIC}
                                        size={16}
                                        color={Color.neutral[900]}
                                        style={{ textAlign: "left", marginBottom: 22 }}
                                    >
                                        {`Sample Barcode`}
                                    </Typography>

                                    <Typography
                                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                        size={14}
                                        color={Color.neutral[900]}
                                        style={{ textAlign: "left", marginBottom: 20, height: 40 }}
                                    >
                                        {`To use all the functions of the demo app you will need some data and barcodes in the PDF.`}
                                    </Typography>

                                    <div style={{ marginBottom: 20 }}>
                                        <Barcode
                                            style={{
                                                width: 220,
                                                height: 220,
                                                color: Color.neutral[900]
                                            }}
                                        />
                                    </div>
                                </Hidden>

                                <Hidden mdUp>
                                    <Typography
                                        fontFamily={FONT_FAMILY.CAPITOLIUM_ITALIC}
                                        size={16}
                                        color={Color.neutral[900]}
                                        style={{ textAlign: "center", marginBottom: 15, marginTop: 10 }}
                                    >
                                        {`Sample Barcode`}
                                    </Typography>

                                    <Typography
                                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                        size={14}
                                        color={Color.neutral[900]}
                                        style={{ textAlign: "center", marginBottom: 20, }}
                                    >
                                        {`To use all the functions of the demo app you will need some data and barcodes in the PDF.`}
                                    </Typography>
                                </Hidden>

                                <a rel="noopener noreferrer" target="_blank" href="https://www.ontego.one/static/barcodes_ontego_one_online.pdf" style={{ textDecoration: "none" }}>
                                    <Button alignIcon="left" icon={<Download />} style={{ outline: 'none' }}>
                                        {`Download sample barcodes`}
                                    </Button>
                                </a>
                            </div>
                        </Grid>
                    </Grid>
                </div >
            }
        />
    )
};

export default GetTheAppDialog;