// React lib
import React, { useContext } from 'react';

// Context
import { GoodsReceiptContext } from './GoodsReceiptConfiguration';

// Components
import ConfigurationTitle from '../ConfigurationTitle';
import ConfigurationOption from '../ConfigurationOption';

// Constants
import { OPTION_TYPE_RADIO, OPTION_TYPE_CHECKBOX } from '../../../Constants/OptionType';
import { DISPLAYED_COLUMN, NEW_POSITION_ADDABLE, QUICK_CHECK, WAREHOUSE_NAME, COMPLETE_ORDER, ORDER_POSITION_COUNT, GS1_128_BARCODE_SCAN, GS1_128_BARCODE_SCAN_LEADING_ZEROS, ALLOW_EDIT_BUSINESS_PARTNER_REFERENCE_NUMBER, ITEM_SELECTION, USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM, DRAFT_DOCUMENTS_FOR_NEW_LINE } from './GoodsReceiptIds';

// Utils
import { goodsReceiptLocalMapToDatabaseValue, arraysMatch } from "../../../Utils/Utils"

const SettingsForListPositions = (props: any) => {
    const {
        defaultFunctionType,
        activeOption,
        setActiveOption,
        displayedColumn,
        handleChangeDisplayedColumn,
        newPositionAddable,
        handleChangeNewPositionAddable,
        itemSelection,
        handleChangeItemSelection,
        quickCheck,
        handleChangeQuickCheck,
        warehouseName,
        handleChangeWarehouseName,
        completeOrder,
        handleChangeCompleteOrder,
        gs1128BarcodeScan,
        handleChangeGs1128BarcodeScan,
        gs1128BarcodeScanLeadingZeros,
        handleChangeGs1128BarcodeScanLeadingZeros,
        allowEditBusinessPartnerReferenceNumber,
        handleChangeAllowEditBusinessPartnerReferenceNumber,
        useOneBatchWithinOneStorageBinForAnItem,
        handleChangeUseOneBatchWithinOneStorageBinForAnItem,
        draftDocumentsForNewLine,
        handleChangeDraftDocumentsForNewLine
    } = useContext(GoodsReceiptContext);

    const displayedColumnOption = {
        id: DISPLAYED_COLUMN,
        heading: '',
        title: `Displayed Column`,
        subtitle: `Select appearance of the position list`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.displayedColumns !== goodsReceiptLocalMapToDatabaseValue("displayedColumns", displayedColumn)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `templateA`,
                onChange: handleChangeDisplayedColumn,
                checked: displayedColumn === `templateA`,
                disabled: false,
                label: `Template A`,
                subLabel: `Column Names in Line List`
            },
            {
                value: `templateB`,
                onChange: handleChangeDisplayedColumn,
                checked: displayedColumn === `templateB`,
                disabled: false,
                label: `Template B`,
                subLabel: `Column Names In the Header List`
            }
        ],
        collapsedOption: []
    }

    const newPositionAddableOption = {
        id: NEW_POSITION_ADDABLE,
        heading: '',
        title: `New Position Addable`,
        subtitle: `Defines whether the user can add a position at any time. The position will be assigned to a document (order) or a supplier. If the assignment is not unique (i.e. when several orders have been selected) a selection menu will appear with the relevant order documents`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.addNewPosition !== goodsReceiptLocalMapToDatabaseValue("addNewPosition", newPositionAddable)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `disable`,
                onChange: handleChangeNewPositionAddable,
                checked: newPositionAddable === `disable`,
                disabled: false,
                label: `Disable`,
                subLabel: ``
            },
            {
                value: `enable`,
                onChange: handleChangeNewPositionAddable,
                checked: newPositionAddable === `enable`,
                disabled: false,
                label: `Enable`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const itemSelectionOption = {
        id: ITEM_SELECTION,
        heading: 'NEW POSITION ADDABLE',
        title: `Item Selection`,
        subtitle: `How should the item be selected for the new position?`,
        isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.selectItemInput, goodsReceiptLocalMapToDatabaseValue("selectItemInput", itemSelection))) ? true : false,
        optionType: OPTION_TYPE_CHECKBOX,
        allowEmpty: false,
        isValid: itemSelection.length > 0 ? true : false,
        options: [
            {
                label: `Manual text input of the item number`,
                onChange: handleChangeItemSelection,
                checked: itemSelection.includes('textInput'),
                value: `textInput`,
                disabled: false
            },
            {
                label: `Barcode Scan`,
                onChange: handleChangeItemSelection,
                checked: itemSelection.includes('barcodeScan'),
                value: `barcodeScan`,
                disabled: false
            },
            {
                label: `OCR Scan ( Unavailable )`,
                onChange: handleChangeItemSelection,
                checked: itemSelection.includes('ocrScan'),
                value: `ocrScan`,
                disabled: true
            },
            {
                label: `Items Search`,
                onChange: handleChangeItemSelection,
                checked: itemSelection.includes('itemsSearch'),
                value: `itemsSearch`,
                disabled: false
            }
        ],
        collapsedOption: []
    }

    const quickCheckOption = {
        id: QUICK_CHECK,
        heading: '',
        title: `Quick Check`,
        subtitle: `Defines whether a position can be booked directly from the list (via checkbox). In that case the warehouse as defined in the order will be chosen automatically. The quickcheck checkbox will be hidden for all items with mandatory batch or serial numbers`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.quickCheck !== goodsReceiptLocalMapToDatabaseValue("quickCheck", quickCheck)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `disable`,
                onChange: handleChangeQuickCheck,
                checked: quickCheck === `disable`,
                disabled: false,
                label: `Disable`,
                subLabel: ``
            },
            {
                value: `enable`,
                onChange: handleChangeQuickCheck,
                checked: quickCheck === `enable`,
                disabled: false,
                label: `Enable`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const warehouseNameOption = {
        id: WAREHOUSE_NAME,
        heading: '',
        title: `Warehouse Name`,
        subtitle: `Select display variant for the warehouses`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.warehouseLayout !== goodsReceiptLocalMapToDatabaseValue("warehouseLayout", warehouseName)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `warehouseName`,
                onChange: handleChangeWarehouseName,
                checked: warehouseName === `warehouseName`,
                disabled: false,
                label: `Show Warehouse Name`,
                subLabel: ``
            },
            {
                value: `warehouseCode`,
                onChange: handleChangeWarehouseName,
                checked: warehouseName === `warehouseCode`,
                disabled: false,
                label: `Show Warehouse Code`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const completedOrderOption = {
        id: COMPLETE_ORDER,
        heading: '',
        title: `Complete Order`,
        subtitle: `Defines how many positions must be completed before finishing an order`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.completeOrderMode !== goodsReceiptLocalMapToDatabaseValue("completeOrderMode", completeOrder)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `atLeastOnePosition`,
                onChange: handleChangeCompleteOrder,
                checked: completeOrder === `atLeastOnePosition`,
                disabled: false,
                label: `At least one position`,
                subLabel: ``
            },
            {
                value: `allPositions`,
                onChange: handleChangeCompleteOrder,
                checked: completeOrder === `allPositions`,
                disabled: false,
                label: `All positions`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    

    const gs1128BarcodeScanOption = {
        id: GS1_128_BARCODE_SCAN,
        heading: ``,
        title: `GS1-128 Barcode Scan`,
        subtitle: `Allow scan of composed barcodes using GS1-128`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.useGS1 !== goodsReceiptLocalMapToDatabaseValue("useGS1", gs1128BarcodeScan)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `dontAllow`,
                onChange: handleChangeGs1128BarcodeScan,
                checked: gs1128BarcodeScan === `dontAllow`,
                disabled: false,
                label: `Don't Allow`,
                subLabel: ``
            },
            {
                value: `allow`,
                onChange: handleChangeGs1128BarcodeScan,
                checked: gs1128BarcodeScan === `allow`,
                disabled: false,
                label: `Allow`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const gs1128BarcodeScanLeadingZerosOption = {
        id: GS1_128_BARCODE_SCAN_LEADING_ZEROS,
        heading: `GS1-128 BARCODE SCAN`,
        title: `Leading zeros for item number of GS1-128 barcodes`,
        subtitle: `Add leading zeros to the scanned item number (GTIN) contained in GS1-128 barcodes until the length of the number is 14`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.gs1FillEanLeadingZeros !== goodsReceiptLocalMapToDatabaseValue("gs1FillEanLeadingZeros", gs1128BarcodeScanLeadingZeros)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                label: `Don't Add`,
                value: `dontAdd`,
                onChange: handleChangeGs1128BarcodeScanLeadingZeros,
                checked: gs1128BarcodeScanLeadingZeros === `dontAdd`,
                disabled: false,
            },
            {
                label: `Add Zeroes`,
                value: `addZeroes`,
                onChange: handleChangeGs1128BarcodeScanLeadingZeros,
                checked: gs1128BarcodeScanLeadingZeros === `addZeroes`,
                disabled: false,
            }

        ],
        collapsedOption: []
    }

    const allowEditBusinessPartnerReferenceNumberOption = {
        id: ALLOW_EDIT_BUSINESS_PARTNER_REFERENCE_NUMBER,
        heading: '',
        title: `Allow Edit Business Partner Reference Number`,
        subtitle: `Allow edit business partner reference number for order. To edit the reference number, please select exactly one order`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.allowBpReference !== goodsReceiptLocalMapToDatabaseValue("allowBpReference", allowEditBusinessPartnerReferenceNumber)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: "dontAllow",
                onChange: handleChangeAllowEditBusinessPartnerReferenceNumber,
                checked: allowEditBusinessPartnerReferenceNumber === "dontAllow",
                label: `Don't Allow`,
                subLabel: ``
            },
            {
                value: "allow",
                onChange: handleChangeAllowEditBusinessPartnerReferenceNumber,
                checked: allowEditBusinessPartnerReferenceNumber === "allow",
                label: `Allow`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const useOneBatchWithinOneStorageBinForAnItemOption = {
        id: USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM,
        heading: '',
        title: `Use one batch within one storage bin for an item`,
        subtitle: `Use at most one batch per article for one article`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.allowOneBatchForOneBinLoc !== goodsReceiptLocalMapToDatabaseValue("allowOneBatchForOneBinLoc", useOneBatchWithinOneStorageBinForAnItem)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `disable`,
                onChange: handleChangeUseOneBatchWithinOneStorageBinForAnItem,
                checked: useOneBatchWithinOneStorageBinForAnItem === `disable`,
                disabled: false,
                label: `Disable`,
                subLabel: ``
            },
            {
                value: `enable`,
                onChange: handleChangeUseOneBatchWithinOneStorageBinForAnItem,
                checked: useOneBatchWithinOneStorageBinForAnItem === `enable`,
                disabled: false,
                label: `Enable`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    const draftDocumentsForNewLineOption = {
        id: DRAFT_DOCUMENTS_FOR_NEW_LINE,
        heading: '',
        title: `Draft documents for new line`,
        subtitle: `Create a draft document when a new position is added to an order`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.draftDocumentsForNewLine !== goodsReceiptLocalMapToDatabaseValue("draftDocumentsForNewLine", draftDocumentsForNewLine)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                value: `disable`,
                onChange: handleChangeDraftDocumentsForNewLine,
                checked: draftDocumentsForNewLine === `disable`,
                disabled: false,
                label: `Disable`,
                subLabel: ``
            },
            {
                value: `enable`,
                onChange: handleChangeDraftDocumentsForNewLine,
                checked: draftDocumentsForNewLine === `enable`,
                disabled: false,
                label: `Enable`,
                subLabel: ``
            }
        ],
        collapsedOption: []
    }

    return (
        <>
            <ConfigurationOption configurationOption={displayedColumnOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={newPositionAddableOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            {newPositionAddable === 'enable' && (
                <ConfigurationOption configurationOption={itemSelectionOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            )}
            <ConfigurationOption configurationOption={quickCheckOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={warehouseNameOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={completedOrderOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={gs1128BarcodeScanOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            {gs1128BarcodeScan === 'allow' && (
                <ConfigurationOption configurationOption={gs1128BarcodeScanLeadingZerosOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            )}
            <ConfigurationOption configurationOption={allowEditBusinessPartnerReferenceNumberOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={useOneBatchWithinOneStorageBinForAnItemOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            <ConfigurationOption configurationOption={draftDocumentsForNewLineOption} activeOption={activeOption} setActiveOption={setActiveOption} />
        </>
    );
};

export default SettingsForListPositions;