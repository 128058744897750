import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// React libraries
import * as React from 'react';
import classnames from 'classnames'; // Custom Styles

import { useStyles } from "./ListModalStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Modal } from "../../index";
import { GreyButton } from "../../index";
export var ListModal = function ListModal(props) {
  var classes = useStyles(props);
  var modalWidth = 285;
  var modalHeight = 350;
  var isModalOpen = props.isModalOpen,
      title = props.title,
      data = props.data,
      initialSelected = props.initialSelected,
      listTypes = props.listTypes;
  return React.createElement(Modal, {
    isModalOpen: isModalOpen,
    width: modalWidth,
    height: modalHeight
  }, React.createElement("div", {
    className: classes.floatingDiv
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.floatingDivBarTop
  }, React.createElement("p", null, title)), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.floatingDivContent,
    style: {
      height: "calc(".concat(modalHeight, "px - 50px - 50px)")
    }
  }, data.map(function (data) {
    var _classnames;

    var floatingDivContentButtonClasses = classnames((_classnames = {}, _defineProperty(_classnames, classes.floatingDivContentButton, true), _defineProperty(_classnames, classes.floatingDivContentButtonActive, data === initialSelected), _classnames));

    if (data.alwaysShow) {
      return React.createElement(Button, {
        className: floatingDivContentButtonClasses,
        style: {
          color: "#f6891f"
        },
        onClick: function onClick() {
          props.setInitialSelected(data);
          props.setOpenModalState(false);
        }
      }, data.name);
    } else if (listTypes) {
      if (listTypes.includes(data.type)) {
        return React.createElement(Button, {
          className: floatingDivContentButtonClasses,
          onClick: function onClick() {
            props.setInitialSelected(data);
            props.setOpenModalState(false);
          }
        }, data.name, " ", React.createElement("span", {
          className: classes.dataDescription
        }, data.description));
      } else {
        return;
      }
    } else {
      return React.createElement(Button, {
        className: floatingDivContentButtonClasses,
        onClick: function onClick() {
          props.setInitialSelected(data);
          props.setOpenModalState(false);
        }
      }, data.name);
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.floatingDivBarBottom
  }, React.createElement(GreyButton, {
    onClick: function onClick() {
      return props.setOpenModalState(false);
    }
  }, "Cancel")))));
};
ListModal.defaultProps = {
  setInitialSelected: function setInitialSelected() {},
  setOpenModalState: function setOpenModalState() {},
  initialSelected: {}
};
export default React.memo(ListModal);