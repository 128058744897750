import _toConsumableArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React, { useState, useEffect } from "react"; // Custom Styles
// import { useStyles } from "./OverflowMenuStyles";
// Assets

import ArrowHeadRight from "../../icons/ArrowHead/Right"; // Material UI Core

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles } from "@material-ui/core";
import { Color } from "../../constants";
var useStyles = makeStyles(function (theme) {
  return createStyles({
    popoverRoot: {
      zIndex: 12000 + " !important"
    },
    menuRoot: {
      overflow: "visible",
      borderRadius: 0,
      minWidth: 200,
      boxShadow: "0px 2px 29px 0px rgba(3, 3, 3, 0.15)"
    },
    menuList: {
      padding: 0
    },
    menuItemDestructive: {
      height: 36,
      fontFamily: "roboto-light",
      fontSize: 12,
      color: Color.neutral[900],
      cursor: "pointer",
      display: "flex",
      overflow: "visible",
      "&:hover": {
        backgroundColor: Color.danger[300],
        color: Color.neutral[0],
        "& $listItemIconRoot": {
          color: Color.neutral[0]
        }
      }
    },
    menuItem: {
      height: 36,
      fontFamily: "roboto-light",
      fontSize: 12,
      color: Color.neutral[900],
      cursor: "pointer",
      display: "flex",
      overflow: "visible",
      "& a": {
        color: Color.neutral[900]
      },
      "&:hover": {
        backgroundColor: Color.neutral[100]
      },
      "&.Mui-disabled": {
        backgroundColor: Color.neutral[300],
        color: Color.neutral[400],
        cursor: "default",
        pointerEvents: "none",
        "& a": {
          color: Color.neutral[400]
        },
        "&:hover": {
          backgroundColor: Color.neutral[300]
        }
      },
      "&.MuiListItem-divider": {
        borderBottom: "1px solid ".concat(Color.neutral[400])
      }
    },
    menuItemDivider: {
      borderBottom: "2px solid red"
    },
    text: {
      alignItems: "center",
      display: "flex"
    },
    arrowHeadIcon: {
      position: "absolute",
      right: 0,
      paddingLeft: 10,
      color: Color.neutral[500]
    },
    subMenu: {
      minWidth: 200,
      borderRadius: 0,
      opacity: 0,
      position: "absolute",
      left: "100%",
      transform: "scale(1.75, 0.5625)",
      transformOrigin: "top right",
      transition: "opacity 0.3ms 0ms, transform 0.3ms 0ms",
      top: 0,
      visibility: "hidden",
      boxShadow: "0px 2px 29px 0px rgba(3, 3, 3, 0.15)"
    },
    subMenuListRoot: {
      padding: 0
    },
    subMenuOpen: {
      transform: "scale(1, 1) translateZ(0px)",
      visibility: "visible",
      opacity: 1
    },
    listItemIconRoot: {
      minWidth: 0,
      marginTop: 4,
      marginRight: 8,
      color: Color.neutral[400]
    }
  });
});
export var OverflowMenu = function OverflowMenu(props) {
  var classes = useStyles(props);

  var _useState = useState([]),
      _useState2 = _slicedToArray(_useState, 2),
      menus = _useState2[0],
      setMenus = _useState2[1];

  var _useState3 = useState([]),
      _useState4 = _slicedToArray(_useState3, 2),
      subMenuStates = _useState4[0],
      setSubMenuStates = _useState4[1];

  var id = props.id,
      anchorElement = props.anchorElement,
      setAnchorEl = props.setAnchorEl,
      menuItems = props.menuItems;
  useEffect(function () {
    var intialDepth = 0;
    var counter = 1;

    var assignDepth = function assignDepth(menuItems, depth) {
      menuItems.forEach(function (menuItem) {
        menuItem.key = counter++;
        menuItem.depth = depth + 1;

        if ('subMenuItems' in menuItem) {
          if (menuItem.subMenuItems.length > 0) {
            assignDepth(menuItem.subMenuItems, menuItem.depth);
          }
        }
      });
    };

    assignDepth(menuItems, intialDepth);
    setMenus(menuItems);
  }, [menuItems]);

  var closeAllMenus = function closeAllMenus() {
    setSubMenuStates([]);
    setAnchorEl(null);
  };

  var handleItemClick = function handleItemClick(menuItem) {
    return function (event) {
      event.stopPropagation(); // Filter same and deeper level of group

      var filterDepth = function filterDepth(depth) {
        return function (state) {
          return state.depth >= depth;
        };
      };

      var hasSubMenu = !!(menuItem.subMenuItems && menuItem.subMenuItems.length);

      if (hasSubMenu) {
        // hide already open sub menus and open the requested sub menu
        var newSubMenuStates = _toConsumableArray(subMenuStates);

        var clickedSubMenuState = subMenuStates.find(function (menuState) {
          return menuState.key === menuItem.key;
        });
        var sameSubMenuDepthState = newSubMenuStates.filter(filterDepth(clickedSubMenuState.depth)); // Set open to true for clicked sub menu

        if (newSubMenuStates) {
          clickedSubMenuState.anchorElement = event.target;
          clickedSubMenuState.open = !clickedSubMenuState.open;
        } // Set open to false for other clicked sub menu on the same level and children level


        sameSubMenuDepthState.forEach(function (sameSubMenuState) {
          if (sameSubMenuState.key !== clickedSubMenuState.key) {
            if (sameSubMenuState.open) {
              sameSubMenuState.open = false;
              sameSubMenuState.anchorElement = null;
            }
          }
        });
        setSubMenuStates(newSubMenuStates);
      } else {
        closeAllMenus();
      }

      if (menuItem.onClick) {
        menuItem.onClick();
      }
    };
  };

  var renderMenuItem = function renderMenuItem(menuItem) {
    var newSubMenuStates = _toConsumableArray(subMenuStates);

    var hasIcon = !!menuItem.icon;
    var hasSubMenu = !!(menuItem.subMenuItems && menuItem.subMenuItems.length);
    var subMenuState = newSubMenuStates.find(function (menuState) {
      return menuState.key === menuItem.key;
    }); // initialize state for sub menu

    if (hasSubMenu && !subMenuState) {
      subMenuState = {
        key: menuItem.key,
        depth: menuItem.depth,
        anchorElement: null,
        open: false
      };
      newSubMenuStates.push(subMenuState);
      setSubMenuStates(newSubMenuStates);
    }

    return React.createElement(MenuItem, {
      key: menuItem.key,
      disabled: menuItem.disabled,
      divider: menuItem.divider,
      className: menuItem.destructive ? classes.menuItemDestructive : classes.menuItem,
      onClick: handleItemClick(menuItem)
    }, hasIcon && React.createElement("div", {
      className: classes.listItemIconRoot
    }, menuItem.icon), React.createElement("div", {
      className: classes.text
    }, menuItem.text), hasSubMenu && React.createElement(React.Fragment, null, React.createElement(ArrowHeadRight, {
      className: classes.arrowHeadIcon
    }), React.createElement(Paper, {
      className: "".concat(classes.subMenu, " ").concat(subMenuState.open ? classes.subMenuOpen : "")
    }, React.createElement(MenuList, {
      classes: {
        root: classes.subMenuListRoot
      }
    }, menuItem.subMenuItems.map(function (subMenuItem) {
      return renderMenuItem(subMenuItem);
    })))));
  };

  return React.createElement(Menu, {
    id: id,
    anchorEl: anchorElement,
    classes: {
      paper: classes.menuRoot,
      list: classes.menuList
    },
    PopoverClasses: {
      root: classes.popoverRoot
    },
    open: Boolean(anchorElement),
    onClose: closeAllMenus
  }, menus.map(function (menuItem) {
    return renderMenuItem(menuItem);
  }));
};