import React, { useContext, useEffect } from 'react';

// Components
import {
    DialogBox,
    Button,
    Typography,
    FONT_FAMILY,
    Color,
    BreadcrumbType,
} from '@otg-one/ui_components';

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { AppContext } from '../../App';

const SaveConfigurationDialog = (props: any) => {
    const { open, onClose, handleSave } = props

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const {
        handleChangeBreadcrumb,
        setBlockNavigation,
        blockNavigation
    } = useContext(AppContext)

    const handleSaveAndStay = () => {
        handleSave().then(() => {
            onClose()
            setBlockNavigation(false)
        })
    }

    const handleSaveAndExit = () => {
        handleSave().then(() => {
            handleChangeBreadcrumb(BreadcrumbType.BACK)
            onClose()
            setBlockNavigation(false)
        })
    }

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title="Save Configuration?"
            content={
                <div style={{ width: isMobile ? 'auto' : 600 }}>
                    <Typography
                        fontFamily={FONT_FAMILY.CAPITOLIUM_ITALIC}
                        size={16}
                        color={Color.neutral[900]}
                        style={{ marginBottom: isMobile ? 25 : 50 }}
                    >
                        {`Changes has been made to this function configuration`}
                    </Typography>

                    {isDesktop && (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button onClick={onClose} type="secondary" style={{ outline: 'none' }}>
                                {`Cancel`}
                            </Button>
                            <div>
                                <Button
                                    type="secondary"
                                    style={{ outline: 'none', marginRight: 24 }}
                                    onClick={handleSaveAndStay}
                                >
                                    {`Save and stay at this page`}
                                </Button>
                                <Button
                                    style={{ outline: 'none' }}
                                    onClick={handleSaveAndExit}
                                >
                                    {`Save and exit to System Dashboard`}
                                </Button>
                            </div>
                        </div>
                    )}
                    {isMobile && (
                        <>
                            <div>
                                <Button onClick={onClose} type="secondary" style={{ outline: 'none' }}>
                                    {`Cancel`}
                                </Button>
                            </div>
                            <div>
                                <Button
                                    type="secondary"
                                    style={{ outline: 'none' }}
                                    onClick={handleSaveAndStay}
                                >
                                    {`Save and stay at this page`}
                                </Button>
                            </div>
                            <Button
                                style={{ outline: 'none' }}
                                onClick={handleSaveAndExit}
                            >
                                {`Save and exit to System Dashboard`}
                            </Button>
                        </>
                    )}
                </div >
            }
        />
    )
};

export default SaveConfigurationDialog;