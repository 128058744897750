// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Color } from '@otg-one/ui_components';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootContainer: {
            flexGrow: 1,
            width: "100%",
            height: "100%",
            maxHeight: "100%",
            position: "relative",
            marginTop: "-140px"
        },
        registerContainer: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "25%",
                paddingRight: "15%",
                paddingBottom: "10%",
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "5%",
            },
            flexGrow: 1,
            boxSizing: "border-box",
            maxHeight: "100%",
            overflow: "auto"
        }
    })
)