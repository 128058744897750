import React from "react";
import { FunctionGoodsReceipt, FunctionPickPack, FunctionInventoryStatus, FunctionInventoryTransfer, FunctionInventoryCounting, FunctionDelivery, ReceiptFromProduction, IssueFromProduction } from '../icons';
export var formatNumericalWithThousandSeparator = function formatNumericalWithThousandSeparator(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
export var getFunctionIcon = function getFunctionIcon(functionType) {
  switch (functionType) {
    case 1:
      return React.createElement(FunctionGoodsReceipt, null);

    case 2:
      return React.createElement(FunctionPickPack, null);

    case 3:
      return React.createElement(FunctionInventoryStatus, null);

    case 4:
      return React.createElement(FunctionInventoryTransfer, null);

    case 5:
      return React.createElement(FunctionInventoryCounting, null);

    case 6:
      return React.createElement(FunctionDelivery, null);

    case 7:
      return React.createElement(ReceiptFromProduction, null);

    case 8:
      return React.createElement(IssueFromProduction, null);
  }

  ;
};