// React lib
import React, { useState, createContext, useContext } from 'react';
import { AppContext } from '../../../App';

// Components
import ConfigurationContainer from '../ConfigurationContainer';
import SettingsForListPositions from './SettingsForListPositions';
import SettingsForEntry from './SettingForEntry';
import SettingsForPositionDetails from './SettingsForPositionDetails';
import { FunctionGoodsReceipt, SerialNumberType } from '@otg-one/ui_components';
import { SETTINGS_FOR_ENTRY, SETTINGS_FOR_LIST_OF_POSITION, SETTINGS_FOR_POSITION_DETAILS, TYPE_OF_ORDER_INPUT, DISPLAYED_COLUMN, QUICK_CHECK, WAREHOUSE_NAME, COMPLETE_ORDER, MORE_OR_LESS_QUANTITIES_ALLOWED, LESS_QUANTITIES_SECURITY_CHECK, OVERBOOKING_SECURITY_CHECK } from './IssueFromProductionIds';

import {
    ListOfOrderPositionsPage, ListPage, ListSelectionPage, EnterItemNumberPage, OrderPositionDetailsPage, BatchesPage, NewPositionAddablePage, NoPreviewPage, MenuPage, LeadingZerosPage
} from '@otg-one/ui_components';

// Models
import { Stack } from '../../../Models/Stack';
import { IssueFromProduction } from '../../../Models/IssueFromProduction'

// Service
import ConfigurationService from "../ConfigurationService";

// Utils
import { issueFromProductionLocalMapToDatabaseValue, arraysMatch, arrayAdjustmentDefault, issueFromProductionDatabaseMapToLocalValue } from "../../../Utils/Utils"
import { STACK_TYPE_APP_FUNCTION } from '../../../Constants/StackType';

export const IssueFromProductionContext = createContext<any>(null);
const IssueFromProductionContextProvider = IssueFromProductionContext.Provider;

//Interface
export interface IIssueFromProductionContextInterface {
    defaultFunctionType: any,
    activeOption: string,
    setActiveOption: any,

    typeOfOrderInput: string[],
    handleChangeTypeOfOrderInput: any,

    displayedColumn: string,
    handleChangeDisplayedColumn: any,
    quickCheck: string,
    handleChangeQuickCheck: any,
    warehouseName: string,
    handleChangeWarehouseName: any,
    completeOrder: string,
    handleChangeCompleteOrder: any,

    moreOrLessQuantitiesAllowed: string[],
    handleChangeMoreOrLessQuantitiesAllowed: any

    lessQuantitiesSecurityCheck: string,
    handleChangeLessQuantitiesSecurityCheck: any,
    overbookingSecurityCheck: string,
    handleChangeOverbookingSecurityCheck: any
}

const IssueFromProductionConfiguration = (props: any) => {
    const {
        breadcrumb,
        getCurrentBreadcrumbData,
        currentUserData,
        handleShowSnackbar,
        setSnackbarMessage
    } = useContext(AppContext);

    const [appData, setAppData] = useState(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION));
    const [defaultFunctionType, setDefaultFunctionType] = useState(null as any);

    const [isLoaded, setIsLoaded] = useState(false)
    const [isModified, setIsModified] = useState(false)
    const [listError, setListError] = useState([] as string[])

    const [activeOption, setActiveOption] = useState("")
    const [activeOptionName, setActiveOptionName] = useState("")

    const [activePreview, setActivePreview] = useState("" as any)
    const [activeMenu, setActiveMenu] = useState({})

    const [typeOfOrderInput, setTypeOfOrderInput] = useState(["textInput"] as string[])

    const [displayedColumn, setDisplayedColumn] = useState(`templateA`)
    const [quickCheck, setQuickCheck] = useState(`disable`)
    const [warehouseName, setWarehouseName] = useState(`warehouseName`)
    const [completeOrder, setCompleteOrder] = useState(`atLeastOnePosition`)

    const [moreOrLessQuantitiesAllowed, setMoreOrLessQuantitiesAllowed] = useState([] as string[])

    const [moreOrLessQuantitiesAllowedSecurityCheck, setMoreOrLessQuantitiesAllowedSecurityCheck] = useState([] as string[])
    const [lessQuantitiesSecurityCheck, setLessQuantitiesSecurityCheck] = useState('lessQuantityAllowedWithCheck')
    const [overbookingSecurityCheck, setOverbookingSecurityCheck] = useState('overbookingAllowedWithCheck')

    const loadDefault = async () => {
        const response = await ConfigurationService.getFunctionType(currentUserData.key, appData.functionTypeId)
        setDefaultFunctionType(response.data);
    };

    const setConfiguration = (masterData: any) => {
        if (masterData.config) {
            Object.keys(masterData.config).map((key: any, index: any) => {
                if (key === "docInputType") {
                    setTypeOfOrderInput(issueFromProductionDatabaseMapToLocalValue("docInputType", masterData.config[key]))
                } else if (key === "displayedColumns") {
                    setDisplayedColumn(issueFromProductionDatabaseMapToLocalValue("displayedColumns", masterData.config[key]))
                } else if (key === "quickCheck") {
                    setQuickCheck(issueFromProductionDatabaseMapToLocalValue("quickCheck", masterData.config[key]))
                } else if (key === "warehouseLayout") {
                    setWarehouseName(issueFromProductionDatabaseMapToLocalValue("warehouseLayout", masterData.config[key]))
                } else if (key === "completeOrderMode") {
                    setCompleteOrder(issueFromProductionDatabaseMapToLocalValue("completeOrderMode", masterData.config[key]))
                } else if (key === "allowQtyChange") {
                    setMoreOrLessQuantitiesAllowed(issueFromProductionDatabaseMapToLocalValue("allowQtyChange", masterData.config[key]).moreOrLessQuantitiesAllowed);
                    setLessQuantitiesSecurityCheck(issueFromProductionDatabaseMapToLocalValue("allowQtyChange", masterData.config[key]).lessQuantitiesSecurityCheck);
                    setOverbookingSecurityCheck(issueFromProductionDatabaseMapToLocalValue("allowQtyChange", masterData.config[key]).overbookingSecurityCheck);
                }
            });
        }
    };

    React.useEffect(() => {
        setAppData(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION));
    }, [breadcrumb]);

    React.useEffect(() => {
        if (Object.keys(appData).length !== 0) {
            loadDefault();

            new Promise((resolve, reject) => {
                setConfiguration(appData);

                resolve(true)
            }).then(res => {
                setIsLoaded(true);
            })
        }
    }, [appData]);

    const IssueFromProductionMenus = [
        {
            id: SETTINGS_FOR_ENTRY,
            label: "Settings For Entry",
            // subtitle: `There are two options for the entry to the Goods Receipt process: 1. via a list of suppliers or 2. via entering a document number. If both options are chosen a sub menu will be shown to the user to decide over the preferred entry method.`,
            isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.docInputType, issueFromProductionLocalMapToDatabaseValue("docInputType", typeOfOrderInput) as string[])) ? true : false,
            submenus: [
                {
                    id: TYPE_OF_ORDER_INPUT,
                    label: "Type of Order Input",
                    isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.docInputType, issueFromProductionLocalMapToDatabaseValue("docInputType", typeOfOrderInput) as string[])) ? true : false,
                    submenus: []
                }
            ]
        },
        {
            id: SETTINGS_FOR_LIST_OF_POSITION,
            label: "Settings For List of Position",
            // subtitle: `In the list of positions all order positions specific to an order will be shown. If several orders have been selected all positions of all selected orders will be shown.`,
            isCustomized: (defaultFunctionType && (defaultFunctionType.config.displayedColumns !== issueFromProductionLocalMapToDatabaseValue("displayedColumns", displayedColumn) ||
                defaultFunctionType.config.quickCheck !== issueFromProductionLocalMapToDatabaseValue("quickCheck", quickCheck) ||
                defaultFunctionType.config.warehouseLayout !== issueFromProductionLocalMapToDatabaseValue("warehouseLayout", warehouseName) ||
                defaultFunctionType.config.completeOrderMode !== issueFromProductionLocalMapToDatabaseValue("completeOrderMode", completeOrder)
            )) ? true : false,
            submenus: [
                {
                    id: DISPLAYED_COLUMN,
                    label: "Displayed Column",
                    isCustomized: (defaultFunctionType && (defaultFunctionType.config.displayedColumns !== issueFromProductionLocalMapToDatabaseValue("displayedColumns", displayedColumn))) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: QUICK_CHECK,
                    label: "Quick Check",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.quickCheck !== issueFromProductionLocalMapToDatabaseValue("quickCheck", quickCheck)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: WAREHOUSE_NAME,
                    label: "Warehouse Name",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.warehouseLayout !== issueFromProductionLocalMapToDatabaseValue("warehouseLayout", warehouseName)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: COMPLETE_ORDER,
                    label: "Complete Order",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.completeOrderMode !== issueFromProductionLocalMapToDatabaseValue("completeOrderMode", completeOrder)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                }
            ]
        },
        {
            id: SETTINGS_FOR_POSITION_DETAILS,
            label: "Settings For Position Details",
            // subtitle: `After choosing a position from the list the following details will be displayed.`,
            isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.allowQtyChange, issueFromProductionLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck) as string[])) ? true : false,
            submenus: [
                {
                    id: MORE_OR_LESS_QUANTITIES_ALLOWED,
                    label: "More/less Quantities Allowed",
                    isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.allowQtyChange, issueFromProductionLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck) as string[])) ? true : false,
                    renderSubmenu: moreOrLessQuantitiesAllowed.length > 0,
                    submenus: [
                        {
                            id: LESS_QUANTITIES_SECURITY_CHECK,
                            label: "Less Quantities Security Check",
                            isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.allowQtyChange, issueFromProductionLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck) as string[])) ? true : false,
                            isShown: moreOrLessQuantitiesAllowed.includes('allowLessQuantities')
                        },
                        {
                            id: OVERBOOKING_SECURITY_CHECK,
                            label: "Overbooking Security Check",
                            isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.allowQtyChange, issueFromProductionLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck) as string[])) ? true : false,
                            isShown: moreOrLessQuantitiesAllowed.includes('allowOverbooking')
                        }
                    ]
                }
            ]
        }
    ];

    const handleClickSaveConfiguration = () => {
        const tempAppData = { ...appData };

        //to avoid different position number in the object - for customized in system dashboard page
        tempAppData.config = {}

        Object.keys(defaultFunctionType.config).map((key: any) => {
            if (key === "docInputType") {
                tempAppData.config.docInputType = arrayAdjustmentDefault(issueFromProductionLocalMapToDatabaseValue("docInputType", typeOfOrderInput), defaultFunctionType.config.docInputType);
            } else if (key === "displayedColumns") {
                tempAppData.config.displayedColumns = issueFromProductionLocalMapToDatabaseValue("displayedColumns", displayedColumn);
            } else if (key === "quickCheck") {
                tempAppData.config.quickCheck = issueFromProductionLocalMapToDatabaseValue("quickCheck", quickCheck);
            } else if (key === "warehouseLayout") {
                tempAppData.config.warehouseLayout = issueFromProductionLocalMapToDatabaseValue("warehouseLayout", warehouseName);
            } else if (key === "completeOrderMode") {
                tempAppData.config.completeOrderMode = issueFromProductionLocalMapToDatabaseValue("completeOrderMode", completeOrder);
            } else if (key === "allowQtyChange") {
                tempAppData.config.allowQtyChange = arrayAdjustmentDefault(issueFromProductionLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck), defaultFunctionType.config.allowQtyChange);
            } else {
                tempAppData.config[key] = defaultFunctionType.config[key];
            }
        })

        return ConfigurationService.saveConfiguration(currentUserData.key, tempAppData.key, tempAppData).then((res: any) => {
            setIsModified(false);
            setSnackbarMessage(`${breadcrumb[breadcrumb.length - 2].data.name} - ${appData.name} Configuration has been changed`)
            handleShowSnackbar();
        });
    };

    React.useEffect(() => {
        if (isLoaded) {
            setIsModified(true);
        }
    }, [typeOfOrderInput, displayedColumn, quickCheck, warehouseName, completeOrder, moreOrLessQuantitiesAllowed, moreOrLessQuantitiesAllowedSecurityCheck, lessQuantitiesSecurityCheck, overbookingSecurityCheck]);

    React.useEffect(() => {
        if (isLoaded) {
            if (typeOfOrderInput.length <= 0) {
                setListError([...listError, TYPE_OF_ORDER_INPUT]);
            } else {
                setListError(listError.filter((event) => (event !== TYPE_OF_ORDER_INPUT)));
            }
        }
    }, [typeOfOrderInput]);

    const handleChangeTypeOfOrderInput = (e: any) => {
        if (typeOfOrderInput.includes(e.target.value)) {
            setTypeOfOrderInput(typeOfOrderInput.filter((event) => (event !== e.target.value)));
        } else {
            setTypeOfOrderInput([...typeOfOrderInput, e.target.value]);
        }
    }

    const handleChangeDisplayedColumn = (e: any) => {
        setDisplayedColumn(e.target.value)
    }

    const handleChangeQuickCheck = (e: any) => {
        setQuickCheck(e.target.value)
    }

    const handleChangeWarehouseName = (e: any) => {
        setWarehouseName(e.target.value)
    }

    const handleChangeCompleteOrder = (e: any) => {
        setCompleteOrder(e.target.value)
    }

    const handleChangeMoreOrLessQuantitiesAllowed = (e: any) => {
        if (moreOrLessQuantitiesAllowed.includes(e.target.value)) {
            setMoreOrLessQuantitiesAllowed(moreOrLessQuantitiesAllowed.filter((event) => (event !== e.target.value)));
            if (e.target.value === 'allowLessQuantities') {
                if (moreOrLessQuantitiesAllowedSecurityCheck.includes('lessQuantityAllowedWithCheck')) {
                    setMoreOrLessQuantitiesAllowedSecurityCheck(moreOrLessQuantitiesAllowedSecurityCheck.filter((event) => (event !== 'lessQuantityAllowedWithCheck')));
                }
                else if (moreOrLessQuantitiesAllowedSecurityCheck.includes('lessQuantityAllowed')) {
                    setMoreOrLessQuantitiesAllowedSecurityCheck(moreOrLessQuantitiesAllowedSecurityCheck.filter((event) => (event !== 'lessQuantityAllowed')));
                }
            }
            else if (e.target.value === 'allowOverbooking') {
                if (moreOrLessQuantitiesAllowedSecurityCheck.includes('overbookingAllowedWithCheck')) {
                    setMoreOrLessQuantitiesAllowedSecurityCheck(moreOrLessQuantitiesAllowedSecurityCheck.filter((event) => (event !== 'overbookingAllowedWithCheck')));
                }
                else if (moreOrLessQuantitiesAllowedSecurityCheck.includes('overbookingAllowed')) {
                    setMoreOrLessQuantitiesAllowedSecurityCheck(moreOrLessQuantitiesAllowedSecurityCheck.filter((event) => (event !== 'overbookingAllowed')));
                }
            }
        } else {
            if (e.target.value === 'allowLessQuantities') {
                if (moreOrLessQuantitiesAllowedSecurityCheck.includes('lessQuantityAllowedWithCheck')) {
                    setMoreOrLessQuantitiesAllowedSecurityCheck(moreOrLessQuantitiesAllowedSecurityCheck.filter((event) => (event !== 'lessQuantityAllowedWithCheck')));
                } else {
                    setMoreOrLessQuantitiesAllowedSecurityCheck([...moreOrLessQuantitiesAllowedSecurityCheck, 'lessQuantityAllowedWithCheck']);
                }

                setLessQuantitiesSecurityCheck('lessQuantityAllowedWithCheck')
            }
            else if (e.target.value === 'allowOverbooking') {
                if (moreOrLessQuantitiesAllowedSecurityCheck.includes('overbookingAllowedWithCheck')) {
                    setMoreOrLessQuantitiesAllowedSecurityCheck(moreOrLessQuantitiesAllowedSecurityCheck.filter((event) => (event !== 'overbookingAllowedWithCheck')));
                } else {
                    setMoreOrLessQuantitiesAllowedSecurityCheck([...moreOrLessQuantitiesAllowedSecurityCheck, 'overbookingAllowedWithCheck']);
                }

                setOverbookingSecurityCheck('overbookingAllowedWithCheck')
            }
            setMoreOrLessQuantitiesAllowed([...moreOrLessQuantitiesAllowed, e.target.value]);
        }
    }

    const handleChangeLessQuantitiesSecurityCheck = (e: any) => {
        setLessQuantitiesSecurityCheck(e.target.value)

        const newMoreOrLessQuantitiesAllowedSecurityCheck = [...moreOrLessQuantitiesAllowedSecurityCheck]

        newMoreOrLessQuantitiesAllowedSecurityCheck.forEach((data, index) => {
            if (data === 'lessQuantityAllowedWithCheck' || data === 'lessQuantityAllowed') {
                newMoreOrLessQuantitiesAllowedSecurityCheck.splice(index, 1)
            }
        })

        newMoreOrLessQuantitiesAllowedSecurityCheck.push(e.target.value)

        setMoreOrLessQuantitiesAllowedSecurityCheck(newMoreOrLessQuantitiesAllowedSecurityCheck)
    }

    const handleChangeOverbookingSecurityCheck = (e: any) => {
        setOverbookingSecurityCheck(e.target.value)

        const newMoreOrLessQuantitiesAllowedSecurityCheck = [...moreOrLessQuantitiesAllowedSecurityCheck]

        newMoreOrLessQuantitiesAllowedSecurityCheck.forEach((data, index) => {
            if (data === 'overbookingAllowedWithCheck' || data === 'overbookingAllowed') {
                newMoreOrLessQuantitiesAllowedSecurityCheck.splice(index, 1)
            }
        })

        newMoreOrLessQuantitiesAllowedSecurityCheck.push(e.target.value)

        setMoreOrLessQuantitiesAllowedSecurityCheck(newMoreOrLessQuantitiesAllowedSecurityCheck)
    }

    const handleChangeActiveOption = (id: string) => {
        let element = document.getElementById(id)

        if (element) {
            const bounding = element.getBoundingClientRect()

            if (
                bounding.top >= 0 &&
                bounding.top <= 400 &&
                bounding.left >= 0 &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            ) {
                setActiveOption(id)
            }
        }
    }

    const handleActivePreview = (id: string) => {
        const orderItemDataAtLeastOnePosition =
            [
                {
                    id: "1",
                    item: "LM4029PH",
                    description: "LeMon 4029 Printhead",
                    open: 6,
                    covered: 6,
                    openCoveredType: "Palette(n)",
                    warehouseName: "WH General",
                    warehouseCode: "01",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "2",
                    item: "P10010",
                    description: "Sales Set",
                    open: 1,
                    covered: 0,
                    openCoveredType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "02",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "3",
                    item: "C00007",
                    description: "Extern Hard Disk 3TB",
                    open: 12,
                    covered: 0,
                    openCoveredType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "03",
                    mandatoryBatchOrSerialNumber: true
                },
                {
                    id: "4",
                    item: "LM4029ACA",
                    description: "LeMon 4029 Printer AC adapter",
                    open: 6,
                    covered: 0,
                    openCoveredType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "04",
                    mandatoryBatchOrSerialNumber: false
                },
            ];

        const orderItemDataAllPositions =
            [
                {
                    id: "1",
                    item: "LM4029PH",
                    description: "LeMon 4029 Printhead",
                    open: 6,
                    covered: 6,
                    openCoveredType: "Palette(n)",
                    warehouseName: "WH General",
                    warehouseCode: "01",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "2",
                    item: "P10010",
                    description: "Sales Set",
                    open: 1,
                    covered: 1,
                    openCoveredType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "02",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "3",
                    item: "C00007",
                    description: "Extern Hard Disk 3TB",
                    open: 12,
                    covered: 12,
                    openCoveredType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "03",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "4",
                    item: "LM4029ACA",
                    description: "LeMon 4029 Printer AC adapter",
                    open: 6,
                    covered: 6,
                    openCoveredType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "04",
                    mandatoryBatchOrSerialNumber: false
                },
            ];

        const detailPosition = {
            position: 1,
            item: "LM4029PH",
            description: "LeMon 4029 Printhead",
            ordered: 6,
            open: 6,
            orderedOpenType: "Palette(n)",
            coveredQuantity: 0,
            coveredQuantityType: "Palette(n)",
            warehouseList: [
                {
                    name: "01 - WH General",
                    type: "whGeneral"
                },
                {
                    name: "02 - WH Reserve",
                    type: "whReserve"
                },
                {
                    name: "03 - WH Delivery",
                    type: "whDelivery"
                },
                {
                    name: "04 - WH Consignment",
                    type: "whConsignment"
                },
                {
                    name: "05 - WH Bin locations",
                    type: "whBinLocation"
                },
            ],
            binLocationList: [
                {
                    name: "05-G01-R01-P01",
                    type: "binLocationList"
                },
                {
                    name: "05-G01-R01-P02",
                    type: "binLocationList"
                },
                {
                    name: "05-G01-R02-P01",
                    type: "binLocationList"
                },
                {
                    name: "05-G01-R02-P02",
                    type: "binLocationList"
                },
                {
                    name: "05-G01-R03-P01",
                    type: "binLocationList"
                },
                {
                    name: "05-G03-R01-P01",
                    type: "binLocationList"
                },
            ]
        }

        switch (id) {
            case TYPE_OF_ORDER_INPUT:
                if (typeOfOrderInput.length > 0) {
                    return setActivePreview(<EnterItemNumberPage headerTitle={"Enter Order Number"} data={{ inputMessage: "Please enter the purchase order number", barcodeMessage: "Please scan the barcode of the order" }} inputTypes={typeOfOrderInput} previewHeight={568} previewWidth={320} />);
                } else {
                    return setActivePreview(<NoPreviewPage type={"emptyValue"} previewHeight={568} previewWidth={320} />);
                }
            case DISPLAYED_COLUMN:
                return setActivePreview(<ListOfOrderPositionsPage headerTitle={"Issue From Production"} newPositionAddable={false} completeOrderType={completeOrder} quickCheck={quickCheck === "disable" ? false : true} allowEditBpRefNo={false} warehouseLayout={warehouseName} data={completeOrder === "atLeastOnePosition" ? orderItemDataAtLeastOnePosition : orderItemDataAllPositions} templateType={displayedColumn} previewHeight={568} previewWidth={320} />);
            case QUICK_CHECK:
                return setActivePreview(<ListOfOrderPositionsPage headerTitle={"Issue From Production"} newPositionAddable={false} completeOrderType={completeOrder} quickCheck={quickCheck === "disable" ? false : true} allowEditBpRefNo={false} warehouseLayout={warehouseName} data={completeOrder === "atLeastOnePosition" ? orderItemDataAtLeastOnePosition : orderItemDataAllPositions} templateType={displayedColumn} previewHeight={568} previewWidth={320} />);
            case WAREHOUSE_NAME:
                return setActivePreview(<ListOfOrderPositionsPage headerTitle={"Issue From Production"} newPositionAddable={false} completeOrderType={completeOrder} quickCheck={quickCheck === "disable" ? false : true} allowEditBpRefNo={false} warehouseLayout={warehouseName} data={completeOrder === "atLeastOnePosition" ? orderItemDataAtLeastOnePosition : orderItemDataAllPositions} templateType={displayedColumn} previewHeight={568} previewWidth={320} />);
            case COMPLETE_ORDER:
                return setActivePreview(<ListOfOrderPositionsPage headerTitle={"Issue From Production"} newPositionAddable={false} completeOrderType={completeOrder} quickCheck={quickCheck === "disable" ? false : true} allowEditBpRefNo={false} warehouseLayout={warehouseName} data={completeOrder === "atLeastOnePosition" ? orderItemDataAtLeastOnePosition : orderItemDataAllPositions} templateType={displayedColumn} previewHeight={568} previewWidth={320} />);
            case MORE_OR_LESS_QUANTITIES_ALLOWED:
            case LESS_QUANTITIES_SECURITY_CHECK:
            case OVERBOOKING_SECURITY_CHECK:
                return setActivePreview(<OrderPositionDetailsPage headerTitle={"Issue From Production"} allowedTypes={moreOrLessQuantitiesAllowedSecurityCheck} data={detailPosition} previewHeight={568} previewWidth={320} />);
            default:
                return setActivePreview(id);
        }
    }

    React.useEffect(() => {
        handleActivePreview(activeOption);

        IssueFromProductionMenus.forEach(menu => {
            menu.submenus.forEach((subMenu: any) => {
                if (subMenu.id === activeOption) {
                    setActiveMenu(menu)
                    setActiveOptionName(subMenu.label)
                }
            });
        })
    }, [activeOption, displayedColumn, warehouseName, typeOfOrderInput, moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck, completeOrder, quickCheck]);

    const viewPortCallback = () => {
        // handleChangeActiveOption(SETTINGS_FOR_ENTRY)
        handleChangeActiveOption(TYPE_OF_ORDER_INPUT)

        // handleChangeActiveOption(SETTINGS_FOR_LIST_OF_POSITION)
        handleChangeActiveOption(DISPLAYED_COLUMN)
        handleChangeActiveOption(QUICK_CHECK)
        handleChangeActiveOption(WAREHOUSE_NAME)
        handleChangeActiveOption(COMPLETE_ORDER)

        // handleChangeActiveOption(SETTINGS_FOR_POSITION_DETAILS)
        handleChangeActiveOption(MORE_OR_LESS_QUANTITIES_ALLOWED)
        handleChangeActiveOption(LESS_QUANTITIES_SECURITY_CHECK)
        handleChangeActiveOption(OVERBOOKING_SECURITY_CHECK)
    };

    const IssueFromProductionContextValue: IIssueFromProductionContextInterface = {
        defaultFunctionType: defaultFunctionType,
        activeOption: activeOption,
        setActiveOption: handleChangeActiveOption,

        typeOfOrderInput: typeOfOrderInput,
        handleChangeTypeOfOrderInput: handleChangeTypeOfOrderInput,

        displayedColumn: displayedColumn,
        handleChangeDisplayedColumn: handleChangeDisplayedColumn,
        quickCheck: quickCheck,
        handleChangeQuickCheck: handleChangeQuickCheck,
        warehouseName: warehouseName,
        handleChangeWarehouseName: handleChangeWarehouseName,
        completeOrder: completeOrder,
        handleChangeCompleteOrder: handleChangeCompleteOrder,

        moreOrLessQuantitiesAllowed: moreOrLessQuantitiesAllowed,
        handleChangeMoreOrLessQuantitiesAllowed: handleChangeMoreOrLessQuantitiesAllowed,

        lessQuantitiesSecurityCheck: lessQuantitiesSecurityCheck,
        handleChangeLessQuantitiesSecurityCheck: handleChangeLessQuantitiesSecurityCheck,
        overbookingSecurityCheck: overbookingSecurityCheck,
        handleChangeOverbookingSecurityCheck: handleChangeOverbookingSecurityCheck,
    };


    return (
        <ConfigurationContainer
            detectViewportCallback={viewPortCallback}
            title={`Issue From Production`}
            icon={<FunctionGoodsReceipt />}
            activeMenu={activeMenu}
            activeViewport={activePreview}
            activeOption={activeOption}
            activeOptionName={activeOptionName}
            sidebarMenus={IssueFromProductionMenus}
            saveConfiguration={handleClickSaveConfiguration}
            setIsModified={setIsModified}
            isModified={isModified}
            setListError={setListError}
            listError={listError}
            setConfigurationToDefault={() => setConfiguration(defaultFunctionType)}
            setConfigurationToPreviousSetting={() => setConfiguration(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION))}
        >
            <IssueFromProductionContextProvider value={IssueFromProductionContextValue}>
                <SettingsForEntry />
                <SettingsForListPositions />
                <SettingsForPositionDetails />
            </IssueFromProductionContextProvider>
        </ConfigurationContainer>
    )
}

export default IssueFromProductionConfiguration