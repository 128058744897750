// Material UI Styles
import { makeStyles, createStyles } from '@material-ui/core/styles'; // Etc

import { arrowGenerator } from './ArrowGenerator';
import { Color } from '../../constants/Colors';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    arrow: {
      position: 'absolute',
      fontSize: 6,
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid'
      },
      boxShadow: '0px 2px 16px 0px rgba(0, 0, 0, 0.25)'
    },
    popper: arrowGenerator(Color.primary[900]),
    tooltip: {
      position: 'relative',
      borderRadius: 0,
      padding: 10,
      backgroundColor: Color.primary[900],
      color: Color.neutral[100],
      boxShadow: '0px 2px 16px 0px rgba(0, 0, 0, 0.25)',
      maxWidth: "150px"
    },
    tooltipPlacementLeft: {
      margin: '0 8px'
    },
    tooltipPlacementRight: {
      margin: '0 8px'
    },
    tooltipPlacementTop: {
      margin: '8px 0'
    },
    tooltipPlacementBottom: {
      margin: '8px 0'
    }
  });
});