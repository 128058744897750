// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    input: {
      position: "absolute",
      opacity: 0,
      cursor: "pointer",
      height: 0,
      width: 0
    },
    label: {
      display: "block",
      position: "relative",
      paddingLeft: "40px",
      marginBottom: "24px",
      lineHeight: "24px",
      cursor: "pointer",
      userSelect: "none",
      WebkitUserSelect: "none",
      MozUserSelect: "none",
      msUserSelect: "none",
      fontFamily: "roboto",
      fontSize: "14px",
      "&::before": {
        boxSizing: "border-box",
        position: "absolute",
        content: "''",
        top: 0,
        left: 0,
        height: "24px",
        width: "24px"
      },
      "&:hover": {
        fontFamily: "roboto-medium",
        "&::before": {
          border: "2px solid ".concat(Color.primary[400])
        }
      },
      "$input:checked ~ &": {
        fontFamily: "roboto-medium",
        "& $checkmark": {
          display: "block"
        }
      },
      "&$disabled": {
        fontFamily: "roboto-medium",
        color: Color.neutral[500],
        "&::before": {
          backgroundColor: Color.neutral[400],
          border: "none"
        },
        "&:hover::before": {
          backgroundColor: Color.neutral[400],
          border: "none"
        }
      }
    },
    checkmark: {
      position: "absolute",
      top: 0,
      left: 0,
      height: "24px",
      width: "24px",
      zIndex: 1,
      color: Color.primary[400],
      display: "none",
      "&$disabled": {
        color: Color.neutral[500]
      }
    },
    n900: {
      color: Color.neutral[900],
      "&::before": {
        backgroundColor: Color.neutral[100]
      }
    },
    n000: {
      color: Color.neutral[0],
      "&::before": {
        backgroundColor: Color.neutral[0]
      }
    },
    disabled: {}
  });
});