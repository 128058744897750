// React lib
import React, { useState, createContext, useContext } from 'react';
import { AppContext } from '../../../App';

// Components
import ConfigurationContainer from '../ConfigurationContainer';
import SettingsForPickAndPack from './SettingsForPickAndPack';

import { FunctionPickPack, ListOfPickPackPositionsPage, PickPackPositionDetailsPage, BatchesPage, WeightCheckPage, NoPreviewPage, SerialNumberType, WarehouseModus, PickPackIgnoreItemsPage, PickPackCompletePickList, MenuPage, PickPackConfirmItemPage, PickPackFilterPickListPage } from '@otg-one/ui_components';
import { SETTINGS_FOR_PICK_AND_PACK, WAREHOUSE_NAME, LESS_QUANTITIES_ALLOWED, QUICK_CHECK, USE_STORAGE_BOX_SYSTEM, MULTISCAN_FOR_BATCHES, CHECK_WEIGHT, WEIGHT_UNIT, USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM, IGNORE_ITEMS, COMPLETE_PICK_LIST, BOXES, SHIPPING_DIMENSION, CONTAINER_FOR_EXCESS_WEIGHT, LIMIT_FOR_THE_WEIGHT_CHECK, SERIAL_NUMBER_TYPE, CHECK_DELIVERY_NOTE, ACTIVATE_SHIPPING_DIMENSION, CONFIRM_ITEMS_BY_ONE_CLICK, WEIGHT_FOR_BIG_SMALL_BOX, WEIGHT_FOR_BIG_BIG_BOX, CREATE_DELIVERY_AUTOMATICALLY, FILTER_PICK_LIST_BY_USER } from './PickAndPackIds';

// Models
import { Stack } from '../../../Models/Stack';
import { PickPack } from '../../../Models/PickPack'

// Service
import ConfigurationService from "../ConfigurationService";

// Utils
import { pickAndPackLocalMapToDatabaseValue, arraysMatch, pickAndPackDatabaseMapToLocalValue } from "../../../Utils/Utils"
import { STACK_TYPE_APP_FUNCTION } from '../../../Constants/StackType';

export const PickAndPackContext = createContext<any>(null);
const PickAndPackContextProvider = PickAndPackContext.Provider;

// Interface
export interface IPickAndPackContextInterface {
    defaultFunctionType: any,
    activeOption: string,
    setActiveOption: any,
    warehouseName: string
    handleChangeWarehouseName: any
    lessQuantitiesAllowed: string
    handleChangeLessQuantitiesAllowed: any
    quickCheck: string
    handleChangeQuickCheck: any
    useStorageBoxSystem: string
    handleChangeUseStorageBoxSystem: any
    boxes: string
    handleChangeBoxes: any
    shippingDimensionHeight: string
    handleChangeShippingDimensionHeight: any
    shippingDimensionLength: string
    handleChangeShippingDimensionLength: any
    shippingDimensionWidth: string
    handleChangeShippingDimensionWidth: any
    multiscanForBatches: string
    handleChangeMultiscanForBatches: any
    containerForExcessWeight: {}[]
    handleChangeContainerForExcessWeight: any
    checkWeight: string
    handleChangeCheckWeight: any
    limitForTheWeightCheck: string
    handleChangeLimitForTheWeightCheck: any
    weightUnit: string
    handleChangeWeightUnit: any
    useOneBatchWithinOneStorageBinForAnItem: string
    handleChangeUseOneBatchWithinOneStorageBinForAnItem: any
    ignoreItems: {}[]
    handleChangeIgnoreItems: any
    completePickList: string
    handleChangeCompletePickList: any,
    serialNumberType: string,
    handleChangeSerialNumberType: any,
    isCheckDeliveryNote: string,
    handleChangeIsCheckDeliveryNote: any,
    isActivateShippingDimension: string,
    handleChangeIsActiveShippingDimension: any,
    isConfirmItemsByOneClick: string,
    handleChangeIsConfirmItemsByOneClick: any,
    bigSmallBoxWeight: number,
    handleChangeBigSmallBoxWeight: any,
    bigBigBoxWeight: number,
    handleChangeBigBigBoxWeight: any,
    isCreateDeliveryAutomatically: string,
    handleChangeIsCreateDeliveryAutomatically: any,
    isFilterPickListByUser: string,
    handleChangeIsFilterPickListByUser: any
}

const DeliveryConfiguration = (props: any) => {
    const {
        breadcrumb,
        currentUserData,
        getCurrentBreadcrumbData,
        handleShowSnackbar,
        setSnackbarMessage
    } = useContext(AppContext);

    const [appData, setAppData] = useState(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION));
    const [defaultFunctionType, setDefaultFunctionType] = useState(null as any);

    const [isLoaded, setIsLoaded] = useState(false)
    const [isModified, setIsModified] = useState(false)
    const [listError, setListError] = useState([] as string[])

    const [activeOption, setActiveOption] = useState("")
    const [activeOptionName, setActiveOptionName] = useState("")

    const [activePreview, setActivePreview] = useState("" as any)
    const [activeMenu, setActiveMenu] = useState({})

    const [warehouseName, setWarehouseName] = useState(`warehouseName`)
    const [lessQuantitiesAllowed, setLessQuantitiesAllowed] = useState(`dontAllow`)
    const [quickCheck, setQuickCheck] = useState(`disable`)
    const [useStorageBoxSystem, setUseStorageBoxSystem] = useState(`disable`)
    const [boxes, setBoxes] = useState(``)
    const [shippingDimensionHeight, setShippingDimensionHeight] = useState(``)
    const [shippingDimensionLength, setShippingDimensionLength] = useState(``)
    const [shippingDimensionWidth, setShippingDimensionWidth] = useState(``)
    const [multiscanForBatches, setMultiscanForBatches] = useState(`disable`)
    const [containerForExcessWeight, setContainerForExcessWeight] = useState([] as {}[])
    const [checkWeight, setCheckWeight] = useState(`disable`)
    const [limitForTheWeightCheck, setLimitForTheWeightCheck] = useState(``)
    const [weightUnit, setWeightUnit] = useState(`g`)
    const [useOneBatchWithinOneStorageBinForAnItem, setUseOneBatchWithinOneStorageBinForAnItem] = useState(`disable`)
    const [ignoreItems, setIgnoreItems] = useState([] as {}[])
    const [completePickList, setCompletePickList] = useState(`atLeastOnePosition`)

    const [serialNumberType, setSerialNumberType] = useState('SER')

    const [isCheckDeliveryNote, setIsCheckDeliveryNote] = useState('disable')
    const [isActivateShippingDimension, setIsActiveShippingDimension] = useState('false')

    const [isConfirmItemsByOneClick, setIsConfirmItemsByOneClick] = useState('false')

    const [bigSmallBoxWeight, setBigSmallBoxWeight] = useState(0)
    const [bigBigBoxWeight, setbigBigBoxWeight] = useState(0)

    const [isCreateDeliveryAutomatically, setIsCreateDeliveryAutomatically] = useState('false')

    const [isFilterPickListByUser, setIsFilterPickListByUser] = useState('true')

    const loadDefault = async () => {
        const response = await ConfigurationService.getFunctionType(currentUserData.key, appData.functionTypeId)
        setDefaultFunctionType(response.data);
    }

    const setConfiguration = (masterData: any) => {
        if (masterData.config) {
            Object.keys(masterData.config).map((key: any, index: any) => {
                if (key === "warehouseLayout") {
                    setWarehouseName(pickAndPackDatabaseMapToLocalValue("warehouseLayout", masterData.config[key]));
                } else if (key === "allowLowerQuantity") {
                    setLessQuantitiesAllowed(pickAndPackDatabaseMapToLocalValue("allowLowerQuantity", masterData.config[key]));
                } else if (key === "quickCheck") {
                    setQuickCheck(pickAndPackDatabaseMapToLocalValue("quickCheck", masterData.config[key]));
                } else if (key === "useContainer") {
                    setUseStorageBoxSystem(pickAndPackDatabaseMapToLocalValue("useContainer", masterData.config[key]));
                } else if (key === "containerUdf") {
                    setBoxes(pickAndPackDatabaseMapToLocalValue("containerUdf", masterData.config[key]));
                } else if (key === "palletHeight") {
                    setShippingDimensionHeight(pickAndPackDatabaseMapToLocalValue("palletHeight", masterData.config[key]));
                } else if (key === "palletLength") {
                    setShippingDimensionLength(pickAndPackDatabaseMapToLocalValue("palletLength", masterData.config[key]));
                } else if (key === "palletWidth") {
                    setShippingDimensionWidth(pickAndPackDatabaseMapToLocalValue("palletWidth", masterData.config[key]));
                } else if (key === "containerMultiscan") {
                    setMultiscanForBatches(pickAndPackDatabaseMapToLocalValue("containerMultiscan", masterData.config[key]));
                } else if (key === "heavyContainers") {
                    const tempDataResult = pickAndPackDatabaseMapToLocalValue("heavyContainers", masterData.config[key])
                    if (tempDataResult.length > 0) {
                        tempDataResult.forEach((result: any) => {
                            containerForExcessWeight.push(result);
                        })
                    } else {
                        setContainerForExcessWeight([]);
                    }
                } else if (key === "useWeightLights") {
                    setCheckWeight(pickAndPackDatabaseMapToLocalValue("useWeightLights", masterData.config[key]));
                } else if (key === "exceededWeightValue") {
                    setLimitForTheWeightCheck(pickAndPackDatabaseMapToLocalValue("exceededWeightValue", masterData.config[key]));
                } else if (key === "weightUnit") {
                    setWeightUnit(pickAndPackDatabaseMapToLocalValue("weightUnit", masterData.config[key]));
                } else if (key === "allowOneBatchForOneBinLoc") {
                    setUseOneBatchWithinOneStorageBinForAnItem(pickAndPackDatabaseMapToLocalValue("allowOneBatchForOneBinLoc", masterData.config[key]));
                } else if (key === "filterItems") {
                    const tempDataResult = pickAndPackDatabaseMapToLocalValue("filterItems", masterData.config[key]);
                    if (tempDataResult.length > 0) {
                        tempDataResult.forEach((result: any) => {
                            ignoreItems.push(result);
                        })
                    } else {
                        setIgnoreItems([]);
                    }

                } else if (key === "completeOrderMode") {
                    setCompletePickList(pickAndPackDatabaseMapToLocalValue("completeOrderMode", masterData.config[key]));
                } else if (key === "serialType") {
                    setSerialNumberType(pickAndPackDatabaseMapToLocalValue("serialType", masterData.config[key]))
                } else if (key === "allowDeliveryPolicing") {
                    setIsCheckDeliveryNote(pickAndPackDatabaseMapToLocalValue("allowDeliveryPolicing", masterData.config[key]));
                } else if (key === "definePalletDimensions") {
                    setIsActiveShippingDimension(pickAndPackDatabaseMapToLocalValue("definePalletDimensions", masterData.config[key]));
                } else if (key === "confirmByOneClickItems") {
                    setIsConfirmItemsByOneClick(pickAndPackDatabaseMapToLocalValue("confirmByOneClickItems", masterData.config[key]));
                } else if (key === "bigAndSmallBoxWeightValue") {
                    setBigSmallBoxWeight(pickAndPackDatabaseMapToLocalValue("bigAndSmallBoxWeightValue", masterData.config[key]));
                } else if (key === "bigAndBigBoxWeightValue") {
                    setbigBigBoxWeight(pickAndPackDatabaseMapToLocalValue("bigAndBigBoxWeightValue", masterData.config[key]));
                } else if (key === "automaticDelivery") {
                    setIsCreateDeliveryAutomatically(pickAndPackDatabaseMapToLocalValue("automaticDelivery", masterData.config[key]))
                } else if (key === "filterPickListsByUser") {
                    setIsFilterPickListByUser(pickAndPackDatabaseMapToLocalValue("filterPickListsByUser", masterData.config[key]));
                }
            });
        }
    };

    React.useEffect(() => {
        setAppData(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION));
    }, [breadcrumb]);

    React.useEffect(() => {
        if (Object.keys(appData).length !== 0) {
            loadDefault();

            new Promise((resolve, reject) => {
                setConfiguration(appData);

                resolve(true)
            }).then(res => {
                setIsLoaded(true);
            })
        }
    }, [appData]);

    const PickAndPackMenus = [
        {
            id: SETTINGS_FOR_PICK_AND_PACK,
            label: "",
            isCustomized: false,
            submenus: [
                {
                    id: WAREHOUSE_NAME,
                    label: "Warehouse Name",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.warehouseLayout !== pickAndPackLocalMapToDatabaseValue("warehouseLayout", warehouseName)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                // {
                //     id: LESS_QUANTITIES_ALLOWED,
                //     label: "Less Quantities Allowed",
                //     isCustomized: (defaultFunctionType && defaultFunctionType.config.allowLowerQuantity !== pickAndPackLocalMapToDatabaseValue("allowLowerQuantity", lessQuantitiesAllowed)) ? true : false,
                //     renderSubmenu: false,
                //     submenus: []
                // },
                // {
                //     id: QUICK_CHECK,
                //     label: "Quickcheck",
                //     isCustomized: (defaultFunctionType && defaultFunctionType.config.quickCheck !== pickAndPackLocalMapToDatabaseValue("quickCheck", quickCheck)) ? true : false,
                //     renderSubmenu: false,
                //     submenus: []
                // },
                {
                    id: USE_STORAGE_BOX_SYSTEM,
                    label: "Use Storage Box System",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.useContainer !== pickAndPackLocalMapToDatabaseValue("useContainer", useStorageBoxSystem)) ? true : false,
                    renderSubmenu: useStorageBoxSystem === 'enable',
                    submenus: [
                        {
                            id: BOXES,
                            label: "Boxes",
                            isCustomized: (defaultFunctionType && defaultFunctionType.config.containerUdf !== pickAndPackLocalMapToDatabaseValue("containerUdf", boxes)) ? true : false,
                            isShown: useStorageBoxSystem === 'enable',
                        },
                        {
                            id: ACTIVATE_SHIPPING_DIMENSION,
                            label: "Activate Shipping Dimension",
                            isCustomized: (defaultFunctionType && defaultFunctionType.config.definePalletDimensions !== pickAndPackLocalMapToDatabaseValue("definePalletDimensions", isActivateShippingDimension)) ? true : false,
                            isShown: useStorageBoxSystem === 'enable',
                        },
                        {
                            id: SHIPPING_DIMENSION,
                            label: "Shipping Dimension",
                            isCustomized: (defaultFunctionType && (
                                defaultFunctionType.config.palletHeight !== pickAndPackLocalMapToDatabaseValue("palletHeight", shippingDimensionHeight) ||
                                defaultFunctionType.config.palletLength !== pickAndPackLocalMapToDatabaseValue("palletLength", shippingDimensionLength) ||
                                defaultFunctionType.config.palletWidth !== pickAndPackLocalMapToDatabaseValue("palletWidth", shippingDimensionWidth)
                            )) ? true : false,
                            isShown: (useStorageBoxSystem === 'enable' && isActivateShippingDimension === 'true'),
                        },
                        {
                            id: MULTISCAN_FOR_BATCHES,
                            label: "Multiscan For Batches",
                            isCustomized: (defaultFunctionType && defaultFunctionType.config.containerMultiscan !== pickAndPackLocalMapToDatabaseValue("containerMultiscan", multiscanForBatches)) ? true : false,
                            isShown: useStorageBoxSystem === 'enable',
                        },
                        {
                            id: CONTAINER_FOR_EXCESS_WEIGHT,
                            label: "Container For Excess Weight",
                            isCustomized: (defaultFunctionType && defaultFunctionType.config.heavyContainers !== pickAndPackLocalMapToDatabaseValue("heavyContainers", containerForExcessWeight)) ? true : false,
                            isShown: useStorageBoxSystem === 'enable',
                        },
                        {
                            id: CHECK_DELIVERY_NOTE,
                            label: "Check Delivery Note",
                            isCustomized: (defaultFunctionType && defaultFunctionType.config.allowDeliveryPolicing !== pickAndPackLocalMapToDatabaseValue("allowDeliveryPolicing", isCheckDeliveryNote)) ? true : false,
                            isShown: useStorageBoxSystem === 'enable',
                        },
                        {
                            id: WEIGHT_FOR_BIG_SMALL_BOX,
                            label: "Weight for two boxes (big box and small box)",
                            isCustomized: (defaultFunctionType && defaultFunctionType.config.bigAndSmallBoxWeightValue !== pickAndPackLocalMapToDatabaseValue("bigAndSmallBoxWeightValue", bigSmallBoxWeight)) ? true : false,
                            isShown: useStorageBoxSystem === 'enable',
                        },
                        {
                            id: WEIGHT_FOR_BIG_BIG_BOX,
                            label: "Weight for two boxes (big box and big box)",
                            isCustomized: (defaultFunctionType && defaultFunctionType.config.bigAndBigBoxWeightValue !== pickAndPackLocalMapToDatabaseValue("bigAndBigBoxWeightValue", bigBigBoxWeight)) ? true : false,
                            isShown: useStorageBoxSystem === 'enable',
                        }
                    ]
                },
                {
                    id: CHECK_WEIGHT,
                    label: "Check Weight",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.useWeightLights !== pickAndPackLocalMapToDatabaseValue("useWeightLights", checkWeight)) ? true : false,
                    renderSubmenu: checkWeight === 'enable',
                    submenus: [
                        {
                            id: LIMIT_FOR_THE_WEIGHT_CHECK,
                            label: "Limit For The Weight Check",
                            isCustomized: (defaultFunctionType && defaultFunctionType.config.exceededWeightValue !== pickAndPackLocalMapToDatabaseValue("exceededWeightValue", limitForTheWeightCheck)) ? true : false,
                            isShown: checkWeight === 'enable',
                        },
                        {
                            id: WEIGHT_UNIT,
                            label: "Weight Unit",
                            isCustomized: (defaultFunctionType && defaultFunctionType.config.weightUnit !== pickAndPackLocalMapToDatabaseValue("weightUnit", weightUnit)) ? true : false,
                            isShown: checkWeight === 'enable',
                        },
                    ]
                },
                {
                    id: USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM,
                    label: "Use One Batch Within One Storage Bin For An Item",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.allowOneBatchForOneBinLoc !== pickAndPackLocalMapToDatabaseValue("allowOneBatchForOneBinLoc", useOneBatchWithinOneStorageBinForAnItem)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: IGNORE_ITEMS,
                    label: "Ignore Items",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.filterItems !== pickAndPackLocalMapToDatabaseValue("filterItems", ignoreItems)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: COMPLETE_PICK_LIST,
                    label: "Complete Pick List",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.completeOrderMode !== pickAndPackLocalMapToDatabaseValue("completeOrderMode", completePickList)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: SERIAL_NUMBER_TYPE,
                    label: "Serial Number Type",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.serialType !== pickAndPackLocalMapToDatabaseValue("serialType", serialNumberType)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                // {
                //     id: CONFIRM_ITEMS_BY_ONE_CLICK,
                //     label: "Confirm Items By One Click",
                //     isCustomized: (defaultFunctionType && defaultFunctionType.config.confirmByOneClickItems !== pickAndPackLocalMapToDatabaseValue("confirmByOneClickItems", isConfirmItemsByOneClick)) ? true : false,
                //     renderSubmenu: false,
                //     submenus: []
                // },
                {
                    id: CREATE_DELIVERY_AUTOMATICALLY,
                    label: "Create Delivery Automatically",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.automaticDelivery !== pickAndPackLocalMapToDatabaseValue("automaticDelivery", isCreateDeliveryAutomatically)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: FILTER_PICK_LIST_BY_USER,
                    label: "Filter Pick List By User",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.filterPickListsByUser !== pickAndPackLocalMapToDatabaseValue("filterPickListsByUser", isFilterPickListByUser)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                }
            ]
        }
    ]

    const handleClickSaveConfiguration = () => {
        const tempAppData = { ...appData };

        //to avoid different position number in the object - for customized in system dashboard page
        tempAppData.config = {}

        Object.keys(defaultFunctionType.config).map((key: any) => {
            if (key === "warehouseLayout") {
                tempAppData.config.warehouseLayout = pickAndPackLocalMapToDatabaseValue("warehouseLayout", warehouseName);
            } else if (key === "allowLowerQuantity") {
                tempAppData.config.allowLowerQuantity = pickAndPackLocalMapToDatabaseValue("allowLowerQuantity", lessQuantitiesAllowed);
            } else if (key === "quickCheck") {
                tempAppData.config.quickCheck = pickAndPackLocalMapToDatabaseValue("quickCheck", quickCheck);
            } else if (key === "useContainer") {
                tempAppData.config.useContainer = pickAndPackLocalMapToDatabaseValue("useContainer", useStorageBoxSystem);
            } else if (key === "containerUdf") {
                tempAppData.config.containerUdf = pickAndPackLocalMapToDatabaseValue("containerUdf", boxes);
            } else if (key === "palletHeight") {
                tempAppData.config.palletHeight = pickAndPackLocalMapToDatabaseValue("palletHeight", shippingDimensionHeight);
            } else if (key === "palletLength") {
                tempAppData.config.palletLength = pickAndPackLocalMapToDatabaseValue("palletLength", shippingDimensionLength);
            } else if (key === "palletWidth") {
                tempAppData.config.palletWidth = pickAndPackLocalMapToDatabaseValue("palletWidth", shippingDimensionWidth);
            } else if (key === "containerMultiscan") {
                tempAppData.config.containerMultiscan = pickAndPackLocalMapToDatabaseValue("containerMultiscan", multiscanForBatches);
            } else if (key === "heavyContainers") {
                tempAppData.config.heavyContainers = pickAndPackLocalMapToDatabaseValue("heavyContainers", containerForExcessWeight);
            } else if (key === "useWeightLights") {
                tempAppData.config.useWeightLights = pickAndPackLocalMapToDatabaseValue("useWeightLights", checkWeight);
            } else if (key === "exceededWeightValue") {
                tempAppData.config.exceededWeightValue = pickAndPackLocalMapToDatabaseValue("exceededWeightValue", limitForTheWeightCheck);
            } else if (key === "weightUnit") {
                tempAppData.config.weightUnit = pickAndPackLocalMapToDatabaseValue("weightUnit", weightUnit);
            } else if (key === "allowOneBatchForOneBinLoc") {
                tempAppData.config.allowOneBatchForOneBinLoc = pickAndPackLocalMapToDatabaseValue("allowOneBatchForOneBinLoc", useOneBatchWithinOneStorageBinForAnItem);
            } else if (key === "filterItems") {
                tempAppData.config.filterItems = pickAndPackLocalMapToDatabaseValue("filterItems", ignoreItems);
            } else if (key === "completeOrderMode") {
                tempAppData.config.completeOrderMode = pickAndPackLocalMapToDatabaseValue("completeOrderMode", completePickList);
            } else if (key === "serialType") {
                tempAppData.config.serialType = pickAndPackLocalMapToDatabaseValue("serialType", serialNumberType);
            } else if (key === "allowDeliveryPolicing") {
                tempAppData.config.allowDeliveryPolicing = pickAndPackLocalMapToDatabaseValue("allowDeliveryPolicing", isCheckDeliveryNote);
            } else if (key === "definePalletDimensions") {
                tempAppData.config.definePalletDimensions = pickAndPackLocalMapToDatabaseValue("definePalletDimensions", isActivateShippingDimension);
            } else if (key === "confirmByOneClickItems") {
                tempAppData.config.confirmByOneClickItems = pickAndPackLocalMapToDatabaseValue("confirmByOneClickItems", isConfirmItemsByOneClick);
            } else if (key === "bigAndSmallBoxWeightValue") {
                tempAppData.config.bigAndSmallBoxWeightValue = pickAndPackLocalMapToDatabaseValue("bigAndSmallBoxWeightValue", bigSmallBoxWeight);
            } else if (key === "bigAndBigBoxWeightValue") {
                tempAppData.config.bigAndBigBoxWeightValue = pickAndPackLocalMapToDatabaseValue("bigAndBigBoxWeightValue", bigBigBoxWeight);
            } else if (key === "automaticDelivery") {
                tempAppData.config.automaticDelivery = pickAndPackLocalMapToDatabaseValue("automaticDelivery", isCreateDeliveryAutomatically);
            } else if (key === "filterPickListsByUser") {
                tempAppData.config.filterPickListsByUser = pickAndPackLocalMapToDatabaseValue("filterPickListsByUser", isFilterPickListByUser);
            } else {
                tempAppData.config[key] = defaultFunctionType.config[key];
            }
        })

        return ConfigurationService.saveConfiguration(currentUserData.key, tempAppData.key, tempAppData).then((res: any) => {
            setIsModified(false);
            setSnackbarMessage(`${breadcrumb[breadcrumb.length - 2].data.name} - ${appData.name} Configuration has been changed`)
            handleShowSnackbar();
        });
    }

    React.useEffect(() => {
        if (isLoaded) {
            setIsModified(true);
        }
    }, [warehouseName, lessQuantitiesAllowed, quickCheck, useStorageBoxSystem, boxes, shippingDimensionHeight, shippingDimensionLength, shippingDimensionWidth, multiscanForBatches, containerForExcessWeight, checkWeight, limitForTheWeightCheck, weightUnit, useOneBatchWithinOneStorageBinForAnItem, ignoreItems, completePickList, serialNumberType, isCheckDeliveryNote, isActivateShippingDimension, isConfirmItemsByOneClick, bigSmallBoxWeight, bigBigBoxWeight, isCreateDeliveryAutomatically, isFilterPickListByUser]);

    React.useEffect(() => {
        if (isLoaded && useStorageBoxSystem === "enable") {
            if (boxes === "") {
                setListError([...listError, BOXES]);
            } else {
                setListError(listError.filter((event) => (event !== BOXES)));
            }
        }
    }, [boxes]);

    React.useEffect(() => {
        if (isLoaded && useStorageBoxSystem === "enable") {
            if (shippingDimensionHeight === "" || shippingDimensionLength === "" || shippingDimensionWidth === "") {
                setListError([...listError, SHIPPING_DIMENSION]);
            } else {
                setListError(listError.filter((event) => (event !== SHIPPING_DIMENSION)));
            }
        }
    }, [shippingDimensionHeight, shippingDimensionLength, shippingDimensionWidth]);

    React.useEffect(() => {
        if (isLoaded && useStorageBoxSystem === "enable") {
            if (containerForExcessWeight && containerForExcessWeight.length <= 0) {
                setListError([...listError, CONTAINER_FOR_EXCESS_WEIGHT]);
            } else {
                setListError(listError.filter((event) => (event !== CONTAINER_FOR_EXCESS_WEIGHT)));
            }
        }
    }, [containerForExcessWeight]);

    React.useEffect(() => {
        if (isLoaded && checkWeight === "enable") {
            if (limitForTheWeightCheck === "") {
                setListError([...listError, LIMIT_FOR_THE_WEIGHT_CHECK]);
            } else {
                setListError(listError.filter((event) => (event !== LIMIT_FOR_THE_WEIGHT_CHECK)));
            }
        }
    }, [limitForTheWeightCheck]);


    const handleChangeWarehouseName = (e: any) => {
        setWarehouseName(e.target.value)
    }

    const handleChangeQuickCheck = (e: any) => {
        setQuickCheck(e.target.value)
    }

    const handleChangeLessQuantitiesAllowed = (e: any) => {
        setLessQuantitiesAllowed(e.target.value)
    }

    const handleChangeUseStorageBoxSystem = (e: any) => {
        setUseStorageBoxSystem(e.target.value)
        if (e.target.value === "disable") {
            setBoxes(pickAndPackDatabaseMapToLocalValue("containerUdf", defaultFunctionType.config.containerUdf));
            setShippingDimensionHeight(pickAndPackDatabaseMapToLocalValue("palletHeight", defaultFunctionType.config.palletHeight));
            setShippingDimensionLength(pickAndPackDatabaseMapToLocalValue("palletLength", defaultFunctionType.config.palletLength));
            setShippingDimensionWidth(pickAndPackDatabaseMapToLocalValue("palletWidth", defaultFunctionType.config.palletWidth));
            setMultiscanForBatches(pickAndPackDatabaseMapToLocalValue("containerMultiscan", defaultFunctionType.config.containerMultiscan));
            setContainerForExcessWeight(pickAndPackDatabaseMapToLocalValue("heavyContainers", defaultFunctionType.config.heavyContainers));

            setListError(listError.filter((event) => (event !== BOXES && event !== SHIPPING_DIMENSION && event !== CONTAINER_FOR_EXCESS_WEIGHT)));
        } else {
            setListError([...listError, BOXES, SHIPPING_DIMENSION, CONTAINER_FOR_EXCESS_WEIGHT]);
        }
    }

    const handleChangeBoxes = (e: any) => {
        setBoxes(e.target.value)
    }

    const handleChangeShippingDimensionHeight = (e: any) => {
        setShippingDimensionHeight(e.target.value)
    }

    const handleChangeShippingDimensionLength = (e: any) => {
        setShippingDimensionLength(e.target.value)
    }

    const handleChangeShippingDimensionWidth = (e: any) => {
        setShippingDimensionWidth(e.target.value)
    }

    const handleChangeMultiscanForBatches = (e: any) => {
        setMultiscanForBatches(e.target.value)
    }

    const handleChangeContainerForExcessWeight = (e: any) => {
        setContainerForExcessWeight(e)
    }

    const handleChangeCheckWeight = (e: any) => {
        setCheckWeight(e.target.value)
        if (e.target.value === "disable") {
            setLimitForTheWeightCheck(pickAndPackDatabaseMapToLocalValue("exceededWeightValue", defaultFunctionType.config.exceededWeightValue));
            setWeightUnit(pickAndPackDatabaseMapToLocalValue("weightUnit", defaultFunctionType.config.weightUnit));

            setListError(listError.filter((event) => (event !== LIMIT_FOR_THE_WEIGHT_CHECK)));
        } else {
            setListError([...listError, LIMIT_FOR_THE_WEIGHT_CHECK]);
        }
    }

    const handleChangeLimitForTheWeightCheck = (e: any) => {
        setLimitForTheWeightCheck(e.target.value)
    }

    const handleChangeWeightUnit = (e: any) => {
        setWeightUnit(e.target.value)
    }

    const handleChangeUseOneBatchWithinOneStorageBinForAnItem = (e: any) => {
        setUseOneBatchWithinOneStorageBinForAnItem(e.target.value)
    }

    const handleChangeIgnoreItems = (e: any) => {
        setIgnoreItems(e)
    }

    const handleChangeCompletePickList = (e: any) => {
        setCompletePickList(e.target.value)
    }

    const handleChangeSerialNumberType = (e: any) => {
        setSerialNumberType(e.target.value)
    }

    const handleChangeIsCheckDeliveryNote = (e: any) => {
        setIsCheckDeliveryNote(e.target.value)
    }

    const handleChangeIsActiveShippingDimension = (e: any) => {
        setIsActiveShippingDimension(e.target.value)
    }

    const handleChangeIsConfirmItemsByOneClick = (e: any) => {
        setIsConfirmItemsByOneClick(e.target.value)
    }

    const handleChangeBigSmallBoxWeight = (e: any) => {
        setBigSmallBoxWeight(e.target.value)
    }

    const handleChangeBigBigBoxWeight = (e: any) => {
        setbigBigBoxWeight(e.target.value)
    }

    const handleChangeIsCreateDeliveryAutomatically = (e: any) => {
        setIsCreateDeliveryAutomatically(e.target.value)
    }

    const handleChangeIsFilterPickListByUser = (e: any) => {
        setIsFilterPickListByUser(e.target.value)
    }

    const handleChangeActiveOption = (id: string) => {
        let element = document.getElementById(id)

        if (element) {
            const bounding = element.getBoundingClientRect()

            if (
                bounding.top >= 0 &&
                bounding.top <= 400 &&
                bounding.left >= 0 &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            ) {
                if (id === SETTINGS_FOR_PICK_AND_PACK) {
                    setActiveOption(WAREHOUSE_NAME)
                }
                else {
                    setActiveOption(id)
                }
            }
        }
    }

    const handleActivePreview = (id: string) => {

        const filterData = (listData: any) => {
            listData.map((data: any) => {
                if (ignoreItems) {

                    ignoreItems.map((ignoreItem: any) => {
                        if (data.item === ignoreItem.value) {
                            listData = listData.filter(function (event: any) {
                                return event.item !== ignoreItem.value;
                            });
                        }
                    })
                }

            })
            return listData;
        }

        const pickPackItemDataAtLeastOnePosition =
            [
                {
                    id: "1",
                    item: "P10010",
                    description: "Sales Set",
                    released: 3,
                    picked: 3,
                    releasedPickedType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "01",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "2",
                    item: "C00007",
                    description: "Extern Hard Disk 3TB",
                    released: 3,
                    picked: 0,
                    releasedPickedType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "02",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "3",
                    item: "LM4029ACA",
                    description: "LeMon 4029 Printer Adapter",
                    released: 3,
                    picked: 0,
                    releasedPickedType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "03",
                    mandatoryBatchOrSerialNumber: true
                },
            ];
        const pickPackItemDataAllPositions =
            [
                {
                    id: "1",
                    item: "P10010",
                    description: "Sales Set",
                    released: 3,
                    picked: 3,
                    releasedPickedType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "01",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "2",
                    item: "C00007",
                    description: "Extern Hard Disk 3TB",
                    released: 3,
                    picked: 3,
                    releasedPickedType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "02",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "3",
                    item: "LM4029ACA",
                    description: "LeMon 4029 Printer Adapter",
                    released: 3,
                    picked: 3,
                    releasedPickedType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "03",
                    mandatoryBatchOrSerialNumber: false
                },
            ];

        const detailPosition = {
            position: 1,
            item: "LM4029PH",
            description: "LeMon 4029 Printhead",
            releasedQuantity: 6,
            releasedQuantityType: "Palette(n)",
            coveredQuantity: 0,
            coveredQuantityType: "Palette(n)",
            warehouseList: [
                {
                    name: "01 - WH General",
                    type: "whGeneral"
                },
                {
                    name: "02 - WH Reserve",
                    type: "whReserve"
                },
                {
                    name: "03 - WH Delivery",
                    type: "whDelivery"
                },
                {
                    name: "04 - WH Consignment",
                    type: "whConsignment"
                },
                {
                    name: "05 - WH Bin locations",
                    type: "whBinLocation"
                },
            ],
            binLocationList: [
                {
                    name: "05-G01-R01-P01",
                    type: "binLocationList"
                },
                {
                    name: "05-G01-R01-P02",
                    type: "binLocationList"
                },
                {
                    name: "05-G01-R02-P01",
                    type: "binLocationList"
                },
                {
                    name: "05-G01-R02-P02",
                    type: "binLocationList"
                },
                {
                    name: "05-G01-R03-P01",
                    type: "binLocationList"
                },
                {
                    name: "05-G03-R01-P01",
                    type: "binLocationList"
                },
            ]
        }

        const weightCheckData =
            [
                {
                    id: "1",
                    pickNumber: "12345",
                    pickDate: "2020-01-24",
                    positions: "5",
                    remarks: "Pick list 1",
                    redValue: 2,
                    greenValue: 0
                },
                {
                    id: "2",
                    pickNumber: "12346",
                    pickDate: "2020-01-24",
                    positions: "5",
                    remarks: "Pick list 2",
                    redValue: 1,
                    greenValue: 0
                },
                {
                    id: "3",
                    pickNumber: "12347",
                    pickDate: "2020-01-24",
                    positions: "5",
                    remarks: "Pick list 3",
                    redValue: 2,
                    greenValue: 0
                },
            ];

        const warehouseModusData = [
            {
                warehouseCode: '01',
                warehouseName: 'Allgemeines Lager'
            }
        ]

        const ignoreItemsData = [
            {
                id: '1',
                item: 'G011',
                description: 'Item 11',
                batch: '2020002',
            },
            {
                id: '2',
                item: 'G012',
                description: 'Item 12',
                batch: '11111111',
            },
            {
                id: '3',
                item: 'G010',
                description: 'Item 10',
                batch: '20202',
            }
        ]

        const completePickListData = [
            {
                id: '1',
                binLocation: '01SF SDF',
                isCheck: true,
                mandatoryBatchOrSerialNumber: false
            },
            {
                id: '2',
                binLocation: '01SF SDF',
                isCheck: false,
                mandatoryBatchOrSerialNumber: false
            },
            {
                id: '3',
                binLocation: '01SF SDF',
                isCheck: false,
                mandatoryBatchOrSerialNumber: true
            }
        ]

        const checkDeliveryNoteData = [
            {
                name: 'Picking',
                function: undefined
            },
            {
                name: 'Check Delivery Paper',
                function: undefined
            }
        ]

        const confirmItemsByOneClickData = [
            {
                id: "1",
                quantity: 18,
                box1Value: 11,
                box2Value: 12,
            },
            {
                id: "2",
                quantity: 3,
                box1Value: 7,
                box2Value: 8,
            },
            {
                id: "3",
                quantity: 20,
                box1Value: 15,
                box2Value: 16
            },
        ]

        const filterPickListByUserData = [
            {
                id: "1",
                userId: "1",
                pickNumber: "111",
                pickDate: "2022-01-15",
                positions: "0",
                remarks: "",
            }
        ]

        const filterPickListByUseCompleterData = [
            {
                id: "1",
                userId: "1",
                pickNumber: "111",
                pickDate: "2022-01-15",
                positions: "0",
                remarks: "",
            },
            {
                id: "2",
                userId: "2",
                pickNumber: "222",
                pickDate: "2022-01-15",
                positions: "0",
                remarks: "",
            },
            {
                id: "3",
                userId: "3",
                pickNumber: "333",
                pickDate: "2022-01-15",
                positions: "0",
                remarks: "",
            }
        ]

        switch (id) {
            case QUICK_CHECK:
                return setActivePreview(<ListOfPickPackPositionsPage newPositionAddable={true} completeOrderType={completePickList} quickCheck={quickCheck === "disable" ? false : true} data={filterData(completePickList === "atLeastOnePosition" ? pickPackItemDataAtLeastOnePosition : pickPackItemDataAllPositions)} warehouseLayout={warehouseName} previewHeight={568} previewWidth={320} />);
            case WAREHOUSE_NAME:
                return setActivePreview(<WarehouseModus scanContentBarText='Scan warehouse/bin location' totalWarehouse={1} data={warehouseModusData} warehouseLayout={warehouseName} previewHeight={568} previewWidth={320} />);
            case LESS_QUANTITIES_ALLOWED:
                return setActivePreview(<PickPackPositionDetailsPage lessQuantitiesAllowed={lessQuantitiesAllowed === "dontAllow" ? false : true} data={detailPosition} previewHeight={568} previewWidth={320} />);
            case COMPLETE_PICK_LIST:
                return setActivePreview(<PickPackCompletePickList completeOrderType={completePickList} data={completePickListData} previewHeight={568} previewWidth={320} />);
            case USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM:
                return setActivePreview(<BatchesPage oneBatchOnly={useOneBatchWithinOneStorageBinForAnItem === "disable" ? false : true} totalBatches={8} bbdInputFieldType={"inputField"} bbdType={"noBbd"} data={[]} previewHeight={568} previewWidth={320} />);
            case IGNORE_ITEMS:
                return setActivePreview(<PickPackIgnoreItemsPage scanContentBarText='Scan item' data={ignoreItemsData} previewHeight={568} previewWidth={320} />);
            case USE_STORAGE_BOX_SYSTEM:
            case BOXES:
            case SHIPPING_DIMENSION:
            case MULTISCAN_FOR_BATCHES:
            case CONTAINER_FOR_EXCESS_WEIGHT:
            case ACTIVATE_SHIPPING_DIMENSION:
            case WEIGHT_FOR_BIG_SMALL_BOX:
            case WEIGHT_FOR_BIG_BIG_BOX:
            case CREATE_DELIVERY_AUTOMATICALLY:
                return setActivePreview(<NoPreviewPage previewHeight={568} previewWidth={320} />);
            case CHECK_DELIVERY_NOTE:
                if (isCheckDeliveryNote === 'enable') {
                    return setActivePreview(<MenuPage headerTitle="Pick and Pack" data={checkDeliveryNoteData} previewHeight={568} previewWidth={320} />)
                }
                else {
                    return setActivePreview(<NoPreviewPage previewHeight={568} previewWidth={320} />);
                }
            case CHECK_WEIGHT:
                if (checkWeight === "disable") {
                    return setActivePreview(<NoPreviewPage previewHeight={568} previewWidth={320} />);
                } else {
                    return setActivePreview(<WeightCheckPage data={weightCheckData} previewHeight={568} previewWidth={320} />);
                }
            case LIMIT_FOR_THE_WEIGHT_CHECK:
                return setActivePreview(<NoPreviewPage previewHeight={568} previewWidth={320} />);
            case SERIAL_NUMBER_TYPE:
                return setActivePreview(<SerialNumberType totalSerialNumbers={1} value={serialNumberType} data={[]} previewHeight={568} previewWidth={320} />)
            case CONFIRM_ITEMS_BY_ONE_CLICK:
                return setActivePreview(<PickPackConfirmItemPage scanContentBarText={`Please scan box..`} data={confirmItemsByOneClickData} isConfirmAllExist={JSON.parse(isConfirmItemsByOneClick)} previewHeight={568} previewWidth={320} />);
            case FILTER_PICK_LIST_BY_USER:
                return setActivePreview(<PickPackFilterPickListPage isShowAssignedUser={JSON.parse(isFilterPickListByUser)} data={isFilterPickListByUser === 'false' ? filterPickListByUseCompleterData : filterPickListByUserData} previewHeight={568} previewWidth={320} />);
            default:
                return setActivePreview(id);
        }
    }

    React.useEffect(() => {
        handleActivePreview(activeOption);

        PickAndPackMenus.forEach(menu => {
            menu.submenus.forEach((subMenu: any) => {
                if (subMenu.id === activeOption) {
                    setActiveMenu(menu)
                    setActiveOptionName(subMenu.label)
                }

                subMenu.submenus.forEach((thirdSubmenu: any) => {
                    if (thirdSubmenu.id === activeOption) {
                        setActiveMenu(menu)
                        setActiveOptionName(thirdSubmenu.label)
                    };
                })
            });
        })

    }, [activeOption, warehouseName, lessQuantitiesAllowed, quickCheck, completePickList, useOneBatchWithinOneStorageBinForAnItem, checkWeight, ignoreItems, boxes, serialNumberType, isCheckDeliveryNote, isActivateShippingDimension, isConfirmItemsByOneClick, bigSmallBoxWeight, bigBigBoxWeight, isCreateDeliveryAutomatically, isFilterPickListByUser]);

    const viewPortCallback = () => {
        handleChangeActiveOption(SETTINGS_FOR_PICK_AND_PACK)
        handleChangeActiveOption(WAREHOUSE_NAME)
        handleChangeActiveOption(LESS_QUANTITIES_ALLOWED)
        handleChangeActiveOption(QUICK_CHECK)
        handleChangeActiveOption(USE_STORAGE_BOX_SYSTEM)
        handleChangeActiveOption(BOXES)
        handleChangeActiveOption(SHIPPING_DIMENSION)
        handleChangeActiveOption(MULTISCAN_FOR_BATCHES)
        handleChangeActiveOption(CONTAINER_FOR_EXCESS_WEIGHT)
        handleChangeActiveOption(CHECK_WEIGHT)
        handleChangeActiveOption(LIMIT_FOR_THE_WEIGHT_CHECK)
        handleChangeActiveOption(WEIGHT_UNIT)
        handleChangeActiveOption(USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM)
        handleChangeActiveOption(IGNORE_ITEMS)
        handleChangeActiveOption(COMPLETE_PICK_LIST)
        handleChangeActiveOption(SERIAL_NUMBER_TYPE)
        handleChangeActiveOption(CHECK_DELIVERY_NOTE)
        handleChangeActiveOption(ACTIVATE_SHIPPING_DIMENSION)
        handleChangeActiveOption(CONFIRM_ITEMS_BY_ONE_CLICK)
        handleChangeActiveOption(WEIGHT_FOR_BIG_SMALL_BOX)
        handleChangeActiveOption(WEIGHT_FOR_BIG_BIG_BOX)
        handleChangeActiveOption(CREATE_DELIVERY_AUTOMATICALLY)
        handleChangeActiveOption(FILTER_PICK_LIST_BY_USER)
    }

    const PickAndPackContextValue: IPickAndPackContextInterface = {
        defaultFunctionType: defaultFunctionType,
        activeOption: activeOption,
        setActiveOption: handleChangeActiveOption,

        warehouseName,
        handleChangeWarehouseName,
        lessQuantitiesAllowed,
        handleChangeLessQuantitiesAllowed,
        quickCheck,
        handleChangeQuickCheck,
        useStorageBoxSystem,
        handleChangeUseStorageBoxSystem,
        boxes,
        handleChangeBoxes,
        shippingDimensionHeight,
        handleChangeShippingDimensionHeight,
        shippingDimensionLength,
        handleChangeShippingDimensionLength,
        shippingDimensionWidth,
        handleChangeShippingDimensionWidth,
        multiscanForBatches,
        handleChangeMultiscanForBatches,
        containerForExcessWeight,
        handleChangeContainerForExcessWeight,
        checkWeight,
        handleChangeCheckWeight,
        limitForTheWeightCheck,
        handleChangeLimitForTheWeightCheck,
        weightUnit,
        handleChangeWeightUnit,
        useOneBatchWithinOneStorageBinForAnItem,
        handleChangeUseOneBatchWithinOneStorageBinForAnItem,
        ignoreItems,
        handleChangeIgnoreItems,
        completePickList,
        handleChangeCompletePickList,
        serialNumberType: serialNumberType,
        handleChangeSerialNumberType: handleChangeSerialNumberType,
        isCheckDeliveryNote: isCheckDeliveryNote,
        handleChangeIsCheckDeliveryNote: handleChangeIsCheckDeliveryNote,
        isActivateShippingDimension: isActivateShippingDimension,
        handleChangeIsActiveShippingDimension: handleChangeIsActiveShippingDimension,
        isConfirmItemsByOneClick: isConfirmItemsByOneClick,
        handleChangeIsConfirmItemsByOneClick: handleChangeIsConfirmItemsByOneClick,
        bigSmallBoxWeight: bigSmallBoxWeight,
        handleChangeBigSmallBoxWeight: handleChangeBigSmallBoxWeight,
        bigBigBoxWeight: bigBigBoxWeight,
        handleChangeBigBigBoxWeight: handleChangeBigBigBoxWeight,
        isCreateDeliveryAutomatically: isCreateDeliveryAutomatically,
        handleChangeIsCreateDeliveryAutomatically: handleChangeIsCreateDeliveryAutomatically,
        isFilterPickListByUser: isFilterPickListByUser,
        handleChangeIsFilterPickListByUser: handleChangeIsFilterPickListByUser
    };

    return (

        <ConfigurationContainer
            detectViewportCallback={viewPortCallback}
            title={`Pick & Pack`}
            icon={<FunctionPickPack />}
            activeMenu={activeMenu}
            activeViewport={activePreview}
            activeOption={activeOption}
            activeOptionName={activeOptionName}
            sidebarMenus={PickAndPackMenus}
            saveConfiguration={handleClickSaveConfiguration}
            setIsModified={setIsModified}
            isModified={isModified}
            setListError={setListError}
            listError={listError}
            setConfigurationToDefault={() => setConfiguration(defaultFunctionType)}
            setConfigurationToPreviousSetting={() => setConfiguration(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION))}
        >
            <PickAndPackContextProvider value={PickAndPackContextValue}>
                <SettingsForPickAndPack />
            </PickAndPackContextProvider>
        </ConfigurationContainer>

    )
};

export default DeliveryConfiguration;