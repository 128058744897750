import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React from 'react'; // Component Style
// import { useStyles } from "./TabsStyles";
// Material-UI Styles

import MUITabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab'; // Components

import { Separator } from '../index'; // Material-UI Styles

import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors"; // Constants
// import { Color } from '../../constants';
// Assets
// const TabContent = props => (
//     <div
//         style={{
//             marginTop: 50,
//             minHeight: 50,
//             backgroundColor: Color.neutral[100],
//             fontFamily: "roboto"
//         }}
//     >
//         {props.children}
//     </div>
// );

var useStyles = makeStyles(function (theme) {
  return createStyles({
    tab: {
      minWidth: 0,
      padding: "10px 16px",
      marginRight: "16px",
      fontFamily: "roboto",
      fontSize: "16px",
      letterSpacing: "0.2px",
      opacity: 1,
      textTransform: "uppercase",
      color: Color.neutral[300],
      "&:hover": {
        borderBottom: "4px solid ".concat(Color.primary[100]),
        paddingBottom: "6px"
      },
      "&.Mui-selected": {
        fontFamily: "roboto-bold",
        color: Color.primary[400]
      },
      "&.Mui-disabled": {
        opacity: 1,
        fontFamily: "roboto-medium",
        color: Color.neutral[500]
      }
    },
    indicator: {
      height: "4px",
      backgroundColor: Color.primary[400]
    }
  });
});
export var Tabs = function Tabs(props) {
  var classes = useStyles(props);
  var tabItems = props.tabItems,
      defaultTabIndex = props.defaultTabIndex;

  var _React$useState = React.useState(defaultTabIndex),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      value = _React$useState2[0],
      setValue = _React$useState2[1];

  var handleChange = function handleChange(event, newValue) {
    setValue(newValue);
  };

  return React.createElement("div", null, React.createElement(MUITabs, {
    classes: {
      indicator: classes.indicator
    },
    value: value,
    onChange: handleChange
  }, tabItems.map(function (tabItem) {
    return React.createElement(Tab, {
      id: "tabTitle_" + tabItem.id,
      className: classes.tab,
      label: tabItem.label,
      disabled: tabItem.disabled
    });
  })), React.createElement(Separator, {
    marginTop: "-2.5px"
  }), tabItems.map(function (tabItem, index) {
    return React.createElement("div", {
      // hidden={value !== index}
      style: {
        visibility: value !== index ? "hidden" : "visible",
        position: "relative",
        width: "100%"
      }
    }, React.createElement("div", {
      id: "tabContent_" + tabItem.id,
      style: {
        position: "absolute",
        width: "100%"
      }
    }, tabItem.content));
  }));
};
Tabs.defaultProps = {
  defaultTabIndex: 0
};
export default React.memo(Tabs);