// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
import { SVG_GRADIENT_BLUE } from '../Button/ButtonStyles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    // For Client View
    clientBoxContainer: {
      width: "100%",
      height: 175,
      display: "flex",
      alignItems: "flex-end"
    },
    clientBox: {
      position: "relative",
      boxSizing: "border-box",
      padding: 20,
      cursor: "pointer",
      width: "100%",
      height: 150,
      backgroundSize: "0% 100%",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "border-box",
      border: "4px solid ".concat(Color.primary[600]),
      borderImage: "url('data:image/svg+xml;base64,".concat(SVG_GRADIENT_BLUE, "') 10% stretch"),
      // backgroundImage: Color.gradient[100],
      transition: "all .2s ease-in-out"
    },
    clientLogo: {
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 54,
      height: 54,
      boxSizing: "border-box",
      // padding: 16,
      backgroundColor: Color.neutral[0],
      borderRadius: "50%",
      left: 21,
      top: -27
    },
    clientBoxInfo: {
      display: "flex",
      alignItems: "baseline",
      justifyContent: "space-between",
      marginTop: 10
    },
    clientBoxSystems: {
      display: "inline-flex",
      alignItems: "center"
    },
    favouriteIconContainer: {
      "& svg": {
        cursor: "pointer",
        marginLeft: 20,
        color: Color.primary[500],
        transition: "color 0.3 ease",
        "&:hover": {
          color: Color.primary[300],
          transition: "color 0.3 ease"
        }
      }
    },
    moreActionIconContainer: {
      position: "absolute",
      top: 30,
      right: 40,
      cursor: "pointer"
    },
    // tableRow: (props: any) => ({
    //     "&:hover": {
    //         backgroundColor: props.data.isTest === 1 && Color.neutral[500]
    //     }
    // }),
    // End Client View
    // For List Header
    root: {
      width: '100%'
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 750
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    }
  });
});