import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  var _stepperContainer, _bottomNav;

  return createStyles({
    /** MuiStepIcon */
    horizontal: {
      paddingLeft: "16px",
      paddingRight: "16px",
      "&:first-child": {
        paddingLeft: "0px"
      },
      "&:last-child": {
        paddingRight: "0px"
      }
    },

    /** MuiStepIcon */
    root: {
      width: "24px",
      height: "24px",
      borderRadius: "12px",
      fontFamily: "roboto-medium",
      fontSize: "16px",
      background: Color.neutral[300],
      color: Color.neutral[100]
    },
    active: {
      background: Color.primary[400],
      color: Color.neutral[100]
    },
    completed: {
      borderRadius: "12px",
      background: Color.primary[400],
      color: Color.neutral[100]
    },
    iconText: {
      fontFamily: "roboto-medium",
      fontSize: "16px",
      marginLeft: "7px",
      lineHeight: "25px"
    },

    /** MuiStepLabel */
    iconContainer: {
      paddingRight: "16px",
      display: "flex",
      alignItems: "center"
    },
    label: {
      fontFamily: "roboto",
      fontSize: "16px",
      letterSpacing: "0.2px",
      color: Color.neutral[400]
    },
    labelActive: {
      fontFamily: "roboto-medium"
    },
    labelCompleted: {
      color: "".concat(Color.neutral[400], " !important")
    },
    rootContainer: {
      display: "flex",
      flexDirection: "column",
      minHeight: "calc(100% - 85px - 27px)"
    },
    stepperContainer: (_stepperContainer = {}, _defineProperty(_stepperContainer, theme.breakpoints.up('md'), {
      paddingLeft: "5%",
      paddingRight: "5%"
    }), _defineProperty(_stepperContainer, theme.breakpoints.up('lg'), {
      paddingLeft: "10%",
      paddingRight: "10%"
    }), _defineProperty(_stepperContainer, theme.breakpoints.down('sm'), {
      paddingLeft: "5%",
      paddingRight: "5%"
    }), _stepperContainer),
    stepper: {
      "&.MuiStepper-root": {
        position: "sticky",
        top: "64px",
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    bottomNav: (_bottomNav = {
      marginTop: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "64px",
      background: Color.neutral[800]
    }, _defineProperty(_bottomNav, theme.breakpoints.up('md'), {
      paddingLeft: "5%",
      paddingRight: "5%"
    }), _defineProperty(_bottomNav, theme.breakpoints.up('lg'), {
      paddingLeft: "10%",
      paddingRight: "10%"
    }), _defineProperty(_bottomNav, theme.breakpoints.down('sm'), {
      marginTop: "10%",
      paddingLeft: "5%",
      paddingRight: "5%"
    }), _bottomNav)
  });
});