import React from "react";
import DropIn from "braintree-web-drop-in-react";
import './store.css';
 
class Store extends React.Component {
  instance;
 
  componentDidMount() {
    this.props.registerDropInCallback( async () => {
      const { nonce } = await this.instance.requestPaymentMethod();
      return nonce;
    })
  }
 
  render() {
    if (this.props.tokenizationKeys === "") {
      return (
        <div>
          <h1>Loading...</h1>
        </div>
      );
    } else {
      return (
        <div>
          <DropIn
          options={{
            authorization:
            this.props.tokenizationKeys
          }}
          onInstance={instance => (this.instance = instance )}
        />
        </div>
      );
    }
  }
}

export default Store;