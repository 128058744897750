// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    blueLink: {
      textDecoration: "none !important",
      "& p": {
        color: "#00a7dd !important",
        textAlign: "center",
        textTransform: "capitalize",
        fontSize: "14px"
      }
    },
    rightIcon: {
      color: "#00a7dd !important",
      fontSize: "18px !important",
      width: "18px !important",
      height: "18px !important",
      marginLeft: "5px",
      position: "relative",
      top: "2px"
    }
  });
});