// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    editDeviceLimitDialogContainer: {
      maxWidth: 500,
      paddingTop: 16
    },
    editDeviceLimitDialogActions: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginTop: 45
    }
  });
});