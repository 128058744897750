import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    systemLogContainer: {
      boxSizing: "border-box",
      padding: 24,
      width: "100%",
      height: 260,
      backgroundColor: Color.neutral[400],
      position: "relative"
    },
    systemLogListContainer: {
      marginTop: 16,
      height: 170,
      maxHeight: 170,
      overflowY: "scroll",
      overflowX: "hidden"
    },
    systemLogList: {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      height: 40
    },
    noMessageWrapper: {
      backgroundColor: "rgba(0, 0, 0, 0)",
      width: "100%",
      height: "100%"
    },
    noMessageIcon: {
      fontSize: "90px !important",
      width: "90px !important",
      height: "90px !important",
      color: "white !important",
      "& svg": {
        color: "white !important"
      }
    },
    noMessageContent: {
      color: "white",
      textAlign: "center",
      marginTop: "10px",
      fontSize: "16px",
      fontWeight: 500
    },
    listItemView: _defineProperty({
      marginTop: -10
    }, theme.breakpoints.down('sm'), {
      '&::before': {
        content: '"• "'
      }
    }),
    br: _defineProperty({}, theme.breakpoints.down('sm'), {
      content: '""',
      '&::before': {
        content: '" - "'
      }
    })
  });
});