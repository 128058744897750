// React Lib
import React from 'react'; // Component Styles

import { useStyles } from './MaximizedSystemLogStyles'; // @otg-one/ui_components/components

import { Switch, Chip, Typography, Separator } from '../index';
import { Bug, Download } from '../../icons'; // @otg-one/ui_components/constants

import { Color, FONT_FAMILY, getLogSeverityStyle } from "../../constants"; // Material UI Core

import Grid from '@material-ui/core/Grid';
import { Button } from '../Button';

var SystemLogListHeader = function SystemLogListHeader(props) {
  return React.createElement(React.Fragment, null, React.createElement(Grid, {
    item: true,
    md: 2
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_BOLD,
    size: 14,
    color: Color.neutral[100]
  }, "Status")), React.createElement(Grid, {
    item: true,
    md: 3
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_BOLD,
    size: 14,
    color: Color.neutral[100]
  }, "Device ID")), React.createElement(Grid, {
    item: true,
    md: 3
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_BOLD,
    size: 14,
    color: Color.neutral[100]
  }, "Last Login")), React.createElement(Grid, {
    item: true,
    md: 2
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_BOLD,
    size: 14,
    color: Color.neutral[100]
  }, "Debug")), React.createElement(Grid, {
    item: true,
    md: 2
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_BOLD,
    size: 14,
    color: Color.neutral[100]
  }, "Download")), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(Separator, {
    type: "n200",
    width: 2,
    marginTop: -8
  })));
};

var SystemLogList = function SystemLogList(props) {
  var log = props.log,
      handleChangeDebug = props.handleChangeDebug;
  return React.createElement(React.Fragment, null, React.createElement(Grid, {
    item: true,
    md: 2
  }, React.createElement("div", {
    style: {
      marginTop: -10
    }
  }, React.createElement(Chip, {
    backgroundColor: getLogSeverityStyle(log.status).backgroundColor,
    color: getLogSeverityStyle(log.status).fontColor
  }, log.status))), React.createElement(Grid, {
    item: true,
    md: 3
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 14,
    color: Color.neutral[100],
    style: {
      marginTop: -10
    }
  }, log.deviceId)), React.createElement(Grid, {
    item: true,
    md: 3
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 14,
    color: Color.neutral[100],
    style: {
      marginTop: -10
    }
  }, log.lastLogin)), React.createElement(Grid, {
    item: true,
    md: 2
  }, React.createElement("div", {
    style: {
      marginTop: -10,
      display: 'flex',
      alignItems: 'center'
    }
  }, React.createElement(Switch, {
    checked: log.isDebug,
    handleChange: function handleChange(e) {
      return handleChangeDebug(e, log);
    }
  }), React.createElement(Bug, {
    style: {
      width: 24,
      height: 24,
      color: log.isDebug ? Color.primary[500] : Color.neutral[300],
      marginLeft: 8
    }
  }))), React.createElement(Grid, {
    item: true,
    md: 2
  }, React.createElement("div", {
    style: {
      marginTop: -14,
      display: 'flex',
      alignItems: 'center'
    }
  }, React.createElement(Button, {
    type: "secondary",
    color: "white",
    alignIcon: "left",
    icon: React.createElement(Download, null)
  }, "Download"))));
};

export var MaximizedSystemLog = function MaximizedSystemLog(props) {
  var classes = useStyles(props);
  var id = props.id,
      logs = props.logs,
      handleChangeDebug = props.handleChangeDebug;
  return React.createElement("div", {
    id: id,
    className: classes.systemLogContainer
  }, React.createElement("div", {
    id: "".concat(id, "-latest-activity-list-container"),
    className: classes.systemLogListContainer
  }, React.createElement(Grid, {
    container: true,
    style: {
      flexGrow: 1
    },
    spacing: 2,
    alignItems: "center"
  }, React.createElement(SystemLogListHeader, null), logs.map(function (log, index) {
    return React.createElement(React.Fragment, null, React.createElement(SystemLogList, {
      log: log,
      handleChangeDebug: handleChangeDebug
    }), index !== logs.length - 1 && React.createElement(Grid, {
      item: true,
      md: 12
    }, React.createElement(Separator, {
      type: "n200",
      width: 1,
      marginTop: -8
    })));
  }))));
};
MaximizedSystemLog.defaultProps = {
  logs: []
};
export default React.memo(MaximizedSystemLog);