import React, { useContext, useState, useEffect, useRef } from 'react';
import { AppContext } from '../../../App';

// Material-UI Core
import Grid from '@material-ui/core/Grid';

// Components
import {
    Form,
    Typography,
    Tabs,
    Chart,
    TotalCost,
    OpenTicket,
    SystemMessage,
    Dropdown,
    Client,
    Thumbnail,
    ListView,
    FONT_FAMILY,
    Color,
    Button,
    Breadcrumb,
    BreadcrumbType,
    LogSeverity,
    DialogBox,
    ArrowDown,
    SystemLog,
    EmptyChartData
} from '@otg-one/ui_components';
import { useStyles } from './PartnerDashboardStyles';
import Plus from '@otg-one/ui_components/lib/icons/Plus/Plus';
import { CLIENT_DASHBOARD, BILLING, CREATENEWCLIENT } from '../../../Constants/FilePath';
import { Stack } from '../../../Models/Stack';

import PartnerService from './PartnerService';
import ClientService from '../Client/ClientService';
import { usePersistedState, getUpperBoundValue, getTicks, generateDropDownItems, getLastNYear } from '../../../Utils/Utils';
import DeleteClient from '../Client/DeleteClient';
import { getApiUrl } from '../../../Constants/Api';
import { STACK_TYPE_CUSTOMER } from '../../../Constants/StackType';

const CustomerCostAndLogin = (props: any) => {
    const {
        currentUserData,
        clientsCostAndLogin,
        setClientsCostAndLogin
    } = useContext(AppContext)

    useEffect(() => {
        PartnerService.getClientsCostAndLogin(currentUserData.key, props.selectedYear).then((res: any) => {
            setClientsCostAndLogin(res.data);
        });
    }, [props.selectedYear]);

    let noChart = true;
    let costMaxValue = 0;
    let loginMaxValue = 0;

    if (clientsCostAndLogin) {
        clientsCostAndLogin.forEach((row: any, index: any) => {
            if (index > 0) {
                if (row[2] !== 0)
                    noChart = false;
                if (row[1] > costMaxValue)
                    costMaxValue = row[1];
                if (row[2] > loginMaxValue)
                    loginMaxValue = row[2];
            }
        })
    }

    const costUpperBound = getUpperBoundValue(costMaxValue);
    const loginUpperBound = getUpperBoundValue(loginMaxValue);

    return (
        <div style={{ paddingTop: 30 }}>
            <Chart
                chartType="ColumnChart"
                chartData={clientsCostAndLogin}
                chartWidth={"100%"}
                chartHeight={"300px"}
                chartOptions={{
                    chartArea: {
                        left: '9%',
                        right: '6%',
                        top: 5,
                        bottom: 60,
                        width: "100%",
                        height: "350"
                    },
                    backgroundColor: "transparent",
                    colors: [Color.primary[900], Color.primary[200]],
                    animation: {
                        startup: true,
                        easing: 'out',
                        duration: 500,
                    },
                    legend: { position: 'bottom' },
                    series: {
                        0: { axis: 'cost', targetAxisIndex: 0 },
                        1: { axis: 'login', targetAxisIndex: 1 }
                    },
                    vAxes: {
                        0: {
                            textStyle: {
                                fontFamily: FONT_FAMILY.ROBOTO_LIGHT,
                                color: Color.neutral[400],
                            },
                            ticks: getTicks(costUpperBound),
                            viewWindow: {
                                min: 0,
                                max: costUpperBound
                            },
                            format: '#,##0.00 €'
                        },
                        1: {
                            textStyle: {
                                fontFamily: FONT_FAMILY.ROBOTO_LIGHT,
                                color: Color.neutral[400]
                            },
                            ticks: getTicks(loginUpperBound),
                            viewWindow: {
                                min: 0,
                                max: loginUpperBound
                            },
                        }
                    },
                    hAxis: {
                        textStyle: {
                            fontFamily: FONT_FAMILY.ROBOTO_LIGHT,
                            color: Color.neutral[400],
                        }
                    },
                    bar: {
                        groupWidth: "64"
                    },
                    tooltip: { isHtml: true, trigger: "visible" }
                }}
                chartEvents={
                    [
                        {
                            eventName: 'ready',
                            callback: (ce: any) => {
                                const chart = ce.chartWrapper.getChart();
                                var defses = document.getElementsByTagName('defs');
                                var gradientInjectFound = false;
                                Array.prototype.forEach.call(defses, function (defs) {
                                    if (defs.getAttribute('id') === 'gradientInject') {
                                        gradientInjectFound = true;
                                    }
                                });
                                if (!gradientInjectFound) {
                                    Array.prototype.forEach.call(defses, function (defs) {
                                        defs.insertAdjacentHTML('afterend', '<defs id="gradientInject"><linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%"><stop offset="0%" style="stop-color:#0077b6;stop-opacity:1" /><stop offset="100%" style="stop-color:#00527f;stop-opacity:1" /></linearGradient></defs>');
                                    });
                                }
                                var rects = chart.container.getElementsByTagName('rect');
                                Array.prototype.forEach.call(rects, function (rect) {
                                    if (rect.getAttribute('fill') === '#00527f') {
                                        rect.setAttribute('fill', 'url(#grad1)');
                                    }
                                });
                                ce.google.visualization.events.addListener(chart, "onmouseover", (e: any) => {
                                    var rects = chart.container.getElementsByTagName('rect');
                                    Array.prototype.forEach.call(rects, function (rect) {
                                        if (rect.getAttribute('fill') === '#00527f') {
                                            rect.setAttribute('fill', 'url(#grad1)');
                                        }
                                    });
                                });
                                ce.google.visualization.events.addListener(chart, "onmouseout", (e: any) => {
                                    var rects = chart.container.getElementsByTagName('rect');
                                    Array.prototype.forEach.call(rects, function (rect) {
                                        if (rect.getAttribute('fill') === '#00527f') {
                                            rect.setAttribute('fill', 'url(#grad1)');
                                        }
                                    });
                                });
                                ce.google.visualization.events.addListener(chart, "animationfinish", (e: any) => {
                                    var rects = chart.container.getElementsByTagName('rect');
                                    Array.prototype.forEach.call(rects, function (rect) {
                                        if (rect.getAttribute('fill') === '#00527f') {
                                            rect.setAttribute('fill', 'url(#grad1)');
                                        }
                                    });
                                });
                            },
                        },
                        {
                            eventName: 'select',
                            callback: (ce: any) => {
                                const chart = ce.chartWrapper.getChart();
                                var rects = chart.container.getElementsByTagName('rect');
                                Array.prototype.forEach.call(rects, function (rect) {
                                    if (rect.getAttribute('fill') === '#00527f') {
                                        rect.setAttribute('fill', 'url(#grad1)');
                                    }
                                });
                            },
                        },
                    ]
                }
            />
            {noChart && (
                <EmptyChartData></EmptyChartData>
            )}
        </div>
    )
}

const PartnerDashboard = (props: any) => {
    const classes = useStyles(props);

    const {
        clients,
        setClients,
        clientList,
        setClientList,
        totalCost,
        setTotalCost,
        breadcrumb,
        currentUserData,
        handleChangeBreadcrumb,
        setSnackbarMessage,
        handleShowSnackbar,
        setClientsCostAndLogin,
        handleShowAlertSnackbar,
        systemLog,
        setSystemLog
    } = useContext(AppContext);

    const [clientView, setClientView] = usePersistedState("clientView", "GRID")
    const [deleteClientDialogOpen, setDeleteClientDialogOpen] = useState(false);
    const [activeClient, setActiveClient] = useState(null)

    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('cost')

    const [isMouseThumbnailOver, setIsMouseThumbnailOver] = useState(false)
    const [isMouseListOver, setIsMouseListOver] = useState(false)

    const [selectedYear, setSelectedYear] = useState(-1);

    /** Get Partner Data */
    useEffect(() => {
        if (Object.keys(currentUserData).length !== 0) {
            getTotalCost()
            getSystemLog()
            getClients()
        }
    }, [currentUserData]);

    const handleDropDownClick = (value: any) => {
        value = typeof value === 'string' ? -1 : value;
        setSelectedYear(value);
    }

    const DROPDOWN_MENU = generateDropDownItems(getLastNYear(), handleDropDownClick);

    const handleClientBoxClick = (client: any) => () => {
        handleChangeBreadcrumb(BreadcrumbType.PUSH, new Stack(STACK_TYPE_CUSTOMER, client.name, CLIENT_DASHBOARD, client))
    }

    const handleGoToBillingClick = () => {
        handleChangeBreadcrumb(BreadcrumbType.BILLING);
    };

    const getTotalCost = () => {
        PartnerService.getTotalCost(currentUserData.key).then((res: any) => {
            setTotalCost(res.data);
        }).catch(error => {
            // TODO: error handling
            return error;
        });
    }

    const getSystemLog = () => {
        PartnerService.getSystemLog(currentUserData.key).then((res: any) => {
            setSystemLog(res.data);
        }).catch(error => {
            // TODO: error handling
            return error;
        });
    }

    const getClients = () => {
        PartnerService.getClients(currentUserData.key).then((res: any) => {
            setClients(res.data);
            setClientList(res.data);
        });
    };

    const handleUpdateClient = (client: any) => {
        ClientService.updateClient(currentUserData.key, client).then((res: any) => {
            getClients();
        });
    };

    const handleCloseDeleteClientDialog = () => {
        setDeleteClientDialogOpen(false);
        setActiveClient(null);
    };

    const handleDeleteClient = (client: any) => () => {
        PartnerService.deleteClient(currentUserData.key, client.key).then((res: any) => {
            handleCloseDeleteClientDialog();
            getClients();

            setSnackbarMessage(`Customer - ${client.name} has been deleted`)
            handleShowAlertSnackbar();
        });
    };

    const handleCreateNewClient = () => {
        handleChangeBreadcrumb(BreadcrumbType.PUSH, new Stack('', "Create New Customer", CREATENEWCLIENT))
    }

    const handleChangeClientView = (view: string) => () => {
        setClientView(view)
    }

    const handleChangeClientSearch = (e: any) => {
        let searchKey = e.target.value;

        let currentClientList = [...clients];
        let filteredClientList: any = [];

        if (searchKey !== "") {
            filteredClientList = currentClientList.filter((client: any) => {
                const lc = client.name.toLowerCase();
                const filter = searchKey.toLowerCase();

                // console.log(lc + " " + lc.includes(filter));

                return lc.includes(filter);
            })
        }
        else if (searchKey === "") {
            filteredClientList = currentClientList
        }

        setClientList(filteredClientList)
    }

    const handleOnIconThumbnailMouseOver = () => { setIsMouseThumbnailOver(true) }
    const handleOnIconThumbnailMouseOut = () => { setIsMouseThumbnailOver(false) }

    const handleOnIconListMouseOver = () => { setIsMouseListOver(true) }
    const handleOnIconListMouseOut = () => { setIsMouseListOver(false) }

    return (
        <>
            <div id="partnerDashboardContainer" className={classes.dashboardContainer}>
                <Grid container style={{ flexGrow: 1 }} spacing={2}>
                    {/** Total cost, open ticket, charts */}
                    <Grid item md={4} xs={12}>
                        <Grid item md={12} xs={12}>
                            <TotalCost actionHandler={handleGoToBillingClick} totalCost={totalCost} />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <div style={{ marginTop: 18 }}>
                                <OpenTicket ticket={0} />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <div
                            className={classes.customerCostAndLoginChart}
                        >
                            {/** TITLE */}
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={18}
                                color={Color.neutral[900]}
                            >
                                {`Customers Cost and Devices`}
                            </Typography>
                            {/** CHART */}
                            <div
                                style={{
                                    marginTop: 40
                                }}
                            >
                                <CustomerCostAndLogin selectedYear={selectedYear} />
                            </div>
                            <div className={classes.chartDropdown}>
                                {<Dropdown
                                    menu={DROPDOWN_MENU}
                                    selected={DROPDOWN_MENU[0].text}
                                    type="SELECTION"
                                    backgroundColor={Color.neutral[0]}
                                />}
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <SystemLog logs={systemLog} global={true} />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.dashboardClientList}>
                <div className={classes.dashboardClientListTopbar}>
                    <Grid container style={{}} spacing={2}>
                        <Grid item md={8} xs={12}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={24}
                                color={Color.neutral[900]}
                            >
                                {`Customers`}
                            </Typography>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <div className={classes.iconContainer} style={{ marginRight: 12 }} onMouseOver={handleOnIconThumbnailMouseOver} onMouseOut={handleOnIconThumbnailMouseOut}>
                                    <Thumbnail
                                        style={{
                                            color: clientView === "GRID" ? Color.primary[500] : (isMouseThumbnailOver ? Color.primary[300] : Color.primary[100]),
                                            transition: `color 0.3s ease`
                                        }}
                                        onClick={handleChangeClientView("GRID")}
                                    />
                                </div>
                                <div className={classes.iconContainer} onMouseOver={handleOnIconListMouseOver} onMouseOut={handleOnIconListMouseOut}>
                                    <ListView
                                        style={{
                                            color: clientView === "LIST" ? Color.primary[500] : (isMouseListOver ? Color.primary[300] : Color.primary[100]),
                                            transition: `color 0.3s ease`
                                        }}
                                        onClick={handleChangeClientView("LIST")}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Form
                                color="white"
                                placeholder="Search"
                                search
                                onChange={handleChangeClientSearch}
                            />
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <Button
                                    alignIcon="left"
                                    icon={<Plus />}
                                    onClick={handleCreateNewClient}
                                    style={{ outline: "none" }}
                                >
                                    {`Create new Customer`}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <Client
                    clientView={clientView}
                    data={clientList}
                    onClick={handleClientBoxClick}
                    setActiveClient={setActiveClient}
                    setDeleteClientDialogOpen={setDeleteClientDialogOpen}
                    handleUpdateClient={handleUpdateClient}
                    order={order}
                    setOrder={setOrder}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    userBaseUrl={getApiUrl()}
                    currentUserData={currentUserData}
                />
            </div>
            {activeClient && (
                <DeleteClient
                    open={deleteClientDialogOpen}
                    onClose={handleCloseDeleteClientDialog}
                    onDelete={handleDeleteClient(activeClient)}
                    client={activeClient}
                />
            )}
        </>
    )
};

export default PartnerDashboard;