// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    // general
    blueGradientHeaderSmall: {
      boxSizing: "border-box",
      backgroundImage: "linear-gradient(#00a7dd, #8fcff6)",
      color: " #ffffff",
      padding: "16px 24px 0px 24px",
      width: "100%",
      textAlign: "left",
      "& p": {
        fontSize: "19px",
        marginTop: "0px",
        marginBottom: "5px"
      },
      "& sub": {
        fontSize: "14px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
      }
    },
    blueGradientHeaderSmallSub: {
      textAlign: "left",
      fontSize: "15px !important",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    },
    blueGradientHeaderBig: {
      boxSizing: "border-box",
      backgroundImage: "linear-gradient(#00a7dd, #8fcff6)",
      color: " #ffffff",
      padding: "24px 24px 0px 24px",
      textAlign: "center",
      width: "100%",
      "& p": {
        fontSize: "24px",
        marginTop: "0px"
      }
    },
    scannerIcon: {
      fontSize: "24px !important",
      position: "absolute",
      top: "15px",
      right: "15px",
      color: "black !important"
    }
  });
});