// React lib
import React, { useState, createContext, useContext } from 'react';
import { AppContext } from '../../../App';

// Components
import ConfigurationContainer from '../ConfigurationContainer';
import SettingsForListPositions from './SettingsForListPositions';
import SettingsForEntry from './SettingsForEntry';
import SettingsForPositionDetails from './SettingsForPositionDetails';
import { FunctionGoodsReceipt, SerialNumberType } from '@otg-one/ui_components';
import { SETTINGS_FOR_ENTRY, ENTRY_OPTION, VIA_SUPPLIERS, SETTINGS_FOR_LIST_OF_POSITION, DISPLAYED_COLUMN, SETTINGS_FOR_POSITION_DETAILS, FIELDS_DISPLAYED, VIA_ORDER_NUMBER, NEW_POSITION_ADDABLE, ITEM_SELECTION, QUICK_CHECK, WAREHOUSE_NAME, COMPLETE_ORDER, ORDER_POSITION_COUNT, GS1_128_BARCODE_SCAN, ALLOW_EDIT_BUSINESS_PARTNER_REFERENCE_NUMBER, MORE_OR_LESS_QUANTITIES_ALLOWED, BEST_BEFORE_DATE, BEST_BEFORE_DATE_INPUT_FIELD, LIST_OF_SUPPLIERS, SELECTION_OF_ORDERS, TYPE_OF_ORDER_INPUT, USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM, DRAFT_DOCUMENTS_FOR_NEW_LINE, GS1_128_BARCODE_SCAN_LEADING_ZEROS, LESS_QUANTITIES_SECURITY_CHECK, OVERBOOKING_SECURITY_CHECK, SERIAL_NUMBER_TYPE } from './GoodsReceiptIds';

import {
    ListOfOrderPositionsPage, ListPage, ListSelectionPage, EnterItemNumberPage, OrderPositionDetailsPage, BatchesPage, NewPositionAddablePage, NoPreviewPage, MenuPage, LeadingZerosPage
} from '@otg-one/ui_components';

// Models
import { Stack } from '../../../Models/Stack';
import { GoodsReceipt } from '../../../Models/GoodsReceipt'

// Service
import ConfigurationService from "../ConfigurationService";

// Utils
import { goodsReceiptLocalMapToDatabaseValue, arraysMatch, arrayAdjustmentDefault, goodsReceiptDatabaseMapToLocalValue } from "../../../Utils/Utils"
import { STACK_TYPE_APP_FUNCTION } from '../../../Constants/StackType';

export const GoodsReceiptContext = createContext<any>(null);
const GoodsReceiptContextProvider = GoodsReceiptContext.Provider;

// Interface
export interface IGoodsReceiptContextInterface {
    defaultFunctionType: any,
    activeOption: string,
    setActiveOption: any,

    entryOption: string[],
    handleChangeEntryOption: any,
    viaSuppliers: boolean,
    handleChangeViaSuppliers: any,
    viaOrderNumber: boolean,
    handleChangeViaOrderNumber: any,
    listOfSuppliers: string,
    handleChangeListOfSuppliers: any,
    selectionOfOrders: string[],
    handleChangeSelectionOfOrders: any,
    typeOfOrderInput: string[],
    handleChangeTypeOfOrderInput: any,

    displayedColumn: string,
    handleChangeDisplayedColumn: any,
    newPositionAddable: string,
    handleChangeNewPositionAddable: any,
    itemSelection: string[],
    handleChangeItemSelection: any,
    quickCheck: string,
    handleChangeQuickCheck: any,
    warehouseName: string,
    handleChangeWarehouseName: any,
    completeOrder: string,
    handleChangeCompleteOrder: any,
    orderPositionCount: string,
    handleChangeOrderPositionCount: any,
    gs1128BarcodeScan: string,
    handleChangeGs1128BarcodeScan: any,
    gs1128BarcodeScanLeadingZeros: string,
    handleChangeGs1128BarcodeScanLeadingZeros: any,
    allowEditBusinessPartnerReferenceNumber: string,
    handleChangeAllowEditBusinessPartnerReferenceNumber: any,
    useOneBatchWithinOneStorageBinForAnItem: string,
    handleChangeUseOneBatchWithinOneStorageBinForAnItem: any,
    draftDocumentsForNewLine: string,
    handleChangeDraftDocumentsForNewLine: any,

    fieldsDisplayed: string,
    handleChangeFieldsDisplayed: any,
    bestBeforeDate: string,
    handleChangeBestBeforeDate: any,
    bestBeforeDateInputField: string,
    handleChangeBestBeforeDateInputField: any,
    moreOrLessQuantitiesAllowed: string[],
    handleChangeMoreOrLessQuantitiesAllowed: any

    lessQuantitiesSecurityCheck: string,
    handleChangeLessQuantitiesSecurityCheck: any,
    overbookingSecurityCheck: string,
    handleChangeOverbookingSecurityCheck: any,

    serialNumberType: string,
    handleChangeSerialNumberType: any
}

const GoodsReceiptConfiguration = (props: any) => {
    const {
        breadcrumb,
        getCurrentBreadcrumbData,
        currentUserData,
        handleShowSnackbar,
        setSnackbarMessage
    } = useContext(AppContext);

    const [appData, setAppData] = useState(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION));
    const [defaultFunctionType, setDefaultFunctionType] = useState(null as any);

    const [isLoaded, setIsLoaded] = useState(false)
    const [isModified, setIsModified] = useState(false)
    const [listError, setListError] = useState([] as string[])

    const [activeOption, setActiveOption] = useState("")
    const [activeOptionName, setActiveOptionName] = useState("")

    const [activePreview, setActivePreview] = useState("" as any)
    const [activeMenu, setActiveMenu] = useState({})

    const [entryOption, setEntryOption] = useState([] as string[])

    const [viaSuppliers, setViaSuppliers] = useState(false)
    const [listOfSuppliers, setListOfSuppliers] = useState("allSuppliers")
    const [selectionOfOrders, setSelectionOfOrders] = useState(["all"] as string[])

    const [viaOrderNumber, setViaOrderNumber] = useState(false)
    const [typeOfOrderInput, setTypeOfOrderInput] = useState(["textInput"] as string[])

    const [displayedColumn, setDisplayedColumn] = useState(`templateA`)
    const [newPositionAddable, setNewPositionAddable] = useState(`disable`)
    const [itemSelection, setItemSelection] = useState([""] as string[])
    const [quickCheck, setQuickCheck] = useState(`disable`)
    const [warehouseName, setWarehouseName] = useState(`warehouseName`)
    const [completeOrder, setCompleteOrder] = useState(`atLeastOnePosition`)
    const [orderPositionCount, setOrderPositionCount] = useState(`show`)
    const [gs1128BarcodeScan, setGs1128BarcodeScan] = useState(`dontAllow`)
    const [gs1128BarcodeScanLeadingZeros, setGs1128BarcodeScanLeadingZeros] = useState(`addZeroes`)
    const [allowEditBusinessPartnerReferenceNumber, setAllowEditBusinessPartnerReferenceNumber] = useState("dontAllow")
    const [useOneBatchWithinOneStorageBinForAnItem, setUseOneBatchWithinOneStorageBinForAnItem] = useState(`disable`)
    const [draftDocumentsForNewLine, setDraftDocumentsForNewLine] = useState(`disable`)

    const [fieldsDisplayed, setFieldsDisplayed] = useState("templateA")
    const [bestBeforeDate, setBestBeforeDate] = useState("noBbd")
    const [bestBeforeDateInputField, setBestBeforeDateInputField] = useState("inputField")
    const [moreOrLessQuantitiesAllowed, setMoreOrLessQuantitiesAllowed] = useState([] as string[])

    const [moreOrLessQuantitiesAllowedSecurityCheck, setMoreOrLessQuantitiesAllowedSecurityCheck] = useState([] as string[])
    const [lessQuantitiesSecurityCheck, setLessQuantitiesSecurityCheck] = useState('lessQuantityAllowedWithCheck')
    const [overbookingSecurityCheck, setOverbookingSecurityCheck] = useState('overbookingAllowedWithCheck')

    const [serialNumberType, setSerialNumberType] = useState('SER')

    const loadDefault = async () => {
        const response = await ConfigurationService.getFunctionType(currentUserData.key, appData.functionTypeId)
        setDefaultFunctionType(response.data);
    }

    const setConfiguration = (masterData: any) => {
        if (masterData.config) {
            Object.keys(masterData.config).map((key: any, index: any) => {
                if (key === "entry") {
                    setEntryOption(goodsReceiptDatabaseMapToLocalValue("entry", masterData.config[key]));
                } else if (key === "listOfSupplier") {
                    setListOfSuppliers(goodsReceiptDatabaseMapToLocalValue("listOfSupplier", masterData.config[key]))
                } else if (key === "orderSelection") {
                    setSelectionOfOrders(goodsReceiptDatabaseMapToLocalValue("orderSelection", masterData.config[key]));
                } else if (key === "docInputType") {
                    setTypeOfOrderInput(goodsReceiptDatabaseMapToLocalValue("docInputType", masterData.config[key]));
                } else if (key === "displayedColumns") {
                    setDisplayedColumn(goodsReceiptDatabaseMapToLocalValue("displayedColumns", masterData.config[key]))
                } else if (key === "addNewPosition") {
                    setNewPositionAddable(goodsReceiptDatabaseMapToLocalValue("addNewPosition", masterData.config[key]))
                } else if (key === "selectItemInput") {
                    setItemSelection(goodsReceiptDatabaseMapToLocalValue("selectItemInput", masterData.config[key]));
                } else if (key === "quickCheck") {
                    setQuickCheck(goodsReceiptDatabaseMapToLocalValue("quickCheck", masterData.config[key]))
                } else if (key === "warehouseLayout") {
                    setWarehouseName(goodsReceiptDatabaseMapToLocalValue("warehouseLayout", masterData.config[key]))
                } else if (key === "completeOrderMode") {
                    setCompleteOrder(goodsReceiptDatabaseMapToLocalValue("completeOrderMode", masterData.config[key]))
                } else if (key === "showOrderPositionCount") {
                    setOrderPositionCount(goodsReceiptDatabaseMapToLocalValue("showOrderPositionCount", masterData.config[key]))
                } else if (key === "useGS1") {
                    setGs1128BarcodeScan(goodsReceiptDatabaseMapToLocalValue("useGS1", masterData.config[key]))
                } else if (key === "gs1FillEanLeadingZeros") {
                    setGs1128BarcodeScanLeadingZeros(goodsReceiptDatabaseMapToLocalValue("gs1FillEanLeadingZeros", masterData.config[key]))
                } else if (key === "allowBpReference") {
                    setAllowEditBusinessPartnerReferenceNumber(goodsReceiptDatabaseMapToLocalValue("allowBpReference", masterData.config[key]))
                } else if (key === "allowOneBatchForOneBinLoc") {
                    setUseOneBatchWithinOneStorageBinForAnItem(goodsReceiptDatabaseMapToLocalValue("allowOneBatchForOneBinLoc", masterData.config[key]))
                } else if (key === "draftDocumentsForNewLine") {
                    setDraftDocumentsForNewLine(goodsReceiptDatabaseMapToLocalValue("draftDocumentsForNewLine", masterData.config[key]))
                } else if (key === "displayedFields") {
                    setFieldsDisplayed(goodsReceiptDatabaseMapToLocalValue("displayedFields", masterData.config[key]))
                } else if (key === "allowQtyChange") {
                    setMoreOrLessQuantitiesAllowed(goodsReceiptDatabaseMapToLocalValue("allowQtyChange", masterData.config[key]).moreOrLessQuantitiesAllowed);
                    setLessQuantitiesSecurityCheck(goodsReceiptDatabaseMapToLocalValue("allowQtyChange", masterData.config[key]).lessQuantitiesSecurityCheck);
                    setOverbookingSecurityCheck(goodsReceiptDatabaseMapToLocalValue("allowQtyChange", masterData.config[key]).overbookingSecurityCheck);
                } else if (key === "bestBeforeDate") {
                    setBestBeforeDate(goodsReceiptDatabaseMapToLocalValue("bestBeforeDate", masterData.config[key]))
                } else if (key === "bestBeforeInputType") {
                    setBestBeforeDateInputField(goodsReceiptDatabaseMapToLocalValue("bestBeforeInputType", masterData.config[key]))
                } else if (key === "serialType") {
                    setSerialNumberType(goodsReceiptDatabaseMapToLocalValue("serialType", masterData.config[key]))
                }
            });
        }
    };

    React.useEffect(() => {
        setAppData(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION));
    }, [breadcrumb]);

    React.useEffect(() => {
        if (Object.keys(appData).length !== 0) {
            loadDefault();

            new Promise((resolve, reject) => {
                setConfiguration(appData);

                resolve(true)
            }).then(res => {
                setIsLoaded(true);
            })
        }
    }, [appData]);

    const GoodsReceiptMenus = [
        {
            id: SETTINGS_FOR_ENTRY,
            label: "Settings For Entry",
            subtitle: `There are two options for the entry to the Goods Receipt process: 1. via a list of suppliers or 2. via entering a document number. If both options are chosen a sub menu will be shown to the user to decide over the preferred entry method.`,
            isCustomized: (defaultFunctionType && (!arraysMatch(defaultFunctionType.config.entry, goodsReceiptLocalMapToDatabaseValue("entry", entryOption) as string[]) ||
                defaultFunctionType.config.listOfSupplier !== goodsReceiptLocalMapToDatabaseValue("listOfSupplier", listOfSuppliers) ||
                !arraysMatch(defaultFunctionType.config.orderSelection, goodsReceiptLocalMapToDatabaseValue("orderSelection", selectionOfOrders) as string[]) ||
                defaultFunctionType.config.showOrderPositionCount !== goodsReceiptLocalMapToDatabaseValue("showOrderPositionCount", orderPositionCount) ||
                !arraysMatch(defaultFunctionType.config.docInputType, goodsReceiptLocalMapToDatabaseValue("docInputType", typeOfOrderInput) as string[])
            )) ? true : false,
            submenus: [
                {
                    id: ENTRY_OPTION,
                    label: "Entry Option",
                    isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.entry, goodsReceiptLocalMapToDatabaseValue("entry", entryOption) as string[])) ? true : false,
                    renderSubmenu: entryOption.length > 0,
                    submenus: [
                        {
                            id: LIST_OF_SUPPLIERS,
                            label: "List of Suppliers",
                            isCustomized: (defaultFunctionType && defaultFunctionType.config.listOfSupplier !== goodsReceiptLocalMapToDatabaseValue("listOfSupplier", listOfSuppliers)) ? true : false,
                            isShown: entryOption.includes(VIA_SUPPLIERS),
                        },
                        {
                            id: SELECTION_OF_ORDERS,
                            label: "Selection of Orders",
                            isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.orderSelection, goodsReceiptLocalMapToDatabaseValue("orderSelection", selectionOfOrders) as string[])) ? true : false,
                            isShown: entryOption.includes(VIA_SUPPLIERS),
                        },
                        {
                            id: ORDER_POSITION_COUNT,
                            label: "Order Position Count",
                            isCustomized: (defaultFunctionType && defaultFunctionType.config.showOrderPositionCount !== goodsReceiptLocalMapToDatabaseValue("showOrderPositionCount", orderPositionCount)) ? true : false,
                            isShown: entryOption.includes(VIA_SUPPLIERS),
                        },
                        {
                            id: TYPE_OF_ORDER_INPUT,
                            label: "Type of Order Input",
                            isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.docInputType, goodsReceiptLocalMapToDatabaseValue("docInputType", typeOfOrderInput) as string[])) ? true : false,
                            isShown: entryOption.includes(VIA_ORDER_NUMBER)
                        }
                    ]
                }
            ]
        },
        {
            id: SETTINGS_FOR_LIST_OF_POSITION,
            label: "Settings For List of Position",
            subtitle: `In the list of positions all order positions specific to an order will be shown. If several orders have been selected all positions of all selected orders will be shown.`,
            isCustomized: (defaultFunctionType && (defaultFunctionType.config.displayedColumns !== goodsReceiptLocalMapToDatabaseValue("displayedColumns", displayedColumn) ||
                defaultFunctionType.config.addNewPosition !== goodsReceiptLocalMapToDatabaseValue("addNewPosition", newPositionAddable) ||
                !arraysMatch(defaultFunctionType.config.selectItemInput, goodsReceiptLocalMapToDatabaseValue("selectItemInput", itemSelection)) ||
                defaultFunctionType.config.quickCheck !== goodsReceiptLocalMapToDatabaseValue("quickCheck", quickCheck) ||
                defaultFunctionType.config.warehouseLayout !== goodsReceiptLocalMapToDatabaseValue("warehouseLayout", warehouseName) ||
                defaultFunctionType.config.completeOrderMode !== goodsReceiptLocalMapToDatabaseValue("completeOrderMode", completeOrder) ||
                defaultFunctionType.config.useGS1 !== goodsReceiptLocalMapToDatabaseValue("useGS1", gs1128BarcodeScan) ||
                defaultFunctionType.config.gs1FillEanLeadingZeros !== goodsReceiptLocalMapToDatabaseValue("gs1FillEanLeadingZeros", gs1128BarcodeScanLeadingZeros) ||
                defaultFunctionType.config.allowBpReference !== goodsReceiptLocalMapToDatabaseValue("allowBpReference", allowEditBusinessPartnerReferenceNumber) ||
                defaultFunctionType.config.allowOneBatchForOneBinLoc !== goodsReceiptLocalMapToDatabaseValue("allowOneBatchForOneBinLoc", useOneBatchWithinOneStorageBinForAnItem) ||
                defaultFunctionType.config.draftDocumentsForNewLine !== goodsReceiptLocalMapToDatabaseValue("draftDocumentsForNewLine", draftDocumentsForNewLine)
            )) ? true : false,
            submenus: [
                {
                    id: DISPLAYED_COLUMN,
                    label: "Displayed Column",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.displayedColumns !== goodsReceiptLocalMapToDatabaseValue("displayedColumns", displayedColumn)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: NEW_POSITION_ADDABLE,
                    label: "New Position Addable",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.addNewPosition !== goodsReceiptLocalMapToDatabaseValue("addNewPosition", newPositionAddable)) ? true : false,
                    renderSubmenu: newPositionAddable === 'enable',
                    submenus: [
                        {
                            id: ITEM_SELECTION,
                            label: "Item Selection",
                            isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.selectItemInput, goodsReceiptLocalMapToDatabaseValue("selectItemInput", itemSelection))) ? true : false,
                            isShown: newPositionAddable === 'enable',
                        },
                    ]
                },
                {
                    id: QUICK_CHECK,
                    label: "Quick Check",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.quickCheck !== goodsReceiptLocalMapToDatabaseValue("quickCheck", quickCheck)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: WAREHOUSE_NAME,
                    label: "Warehouse Name",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.warehouseLayout !== goodsReceiptLocalMapToDatabaseValue("warehouseLayout", warehouseName)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: COMPLETE_ORDER,
                    label: "Complete Order",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.completeOrderMode !== goodsReceiptLocalMapToDatabaseValue("completeOrderMode", completeOrder)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: GS1_128_BARCODE_SCAN,
                    label: "GS1-128 Barcode Scan",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.useGS1 !== goodsReceiptLocalMapToDatabaseValue("useGS1", gs1128BarcodeScan)) ? true : false,
                    renderSubmenu: gs1128BarcodeScan === 'allow',
                    submenus: [
                        {
                            id: GS1_128_BARCODE_SCAN_LEADING_ZEROS,
                            label: "GS1-128 Barcode Scan Leading Zeros",
                            isCustomized: (defaultFunctionType && defaultFunctionType.config.gs1FillEanLeadingZeros !== goodsReceiptLocalMapToDatabaseValue("gs1FillEanLeadingZeros", gs1128BarcodeScanLeadingZeros)) ? true : false,
                            isShown: gs1128BarcodeScan === 'allow',
                        },
                    ]
                },
                {
                    id: ALLOW_EDIT_BUSINESS_PARTNER_REFERENCE_NUMBER,
                    label: "Edit Business Partner Reference Number",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.allowBpReference !== goodsReceiptLocalMapToDatabaseValue("allowBpReference", allowEditBusinessPartnerReferenceNumber)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM,
                    label: "Use One Batch Within One Storage Bin For An Item",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.allowOneBatchForOneBinLoc !== goodsReceiptLocalMapToDatabaseValue("allowOneBatchForOneBinLoc", useOneBatchWithinOneStorageBinForAnItem)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: DRAFT_DOCUMENTS_FOR_NEW_LINE,
                    label: "Draft Documents For New Line",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.draftDocumentsForNewLine !== goodsReceiptLocalMapToDatabaseValue("draftDocumentsForNewLine", draftDocumentsForNewLine)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
            ]
        },
        {
            id: SETTINGS_FOR_POSITION_DETAILS,
            label: "Settings For Position Details",
            subtitle: `After choosing a position from the list the following details will be displayed.`,
            isCustomized: (defaultFunctionType && (defaultFunctionType.config.displayedFields !== goodsReceiptLocalMapToDatabaseValue("displayedFields", fieldsDisplayed) ||
                !arraysMatch(defaultFunctionType.config.allowQtyChange, goodsReceiptLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck) as string[]) ||
                defaultFunctionType.config.bestBeforeDate !== goodsReceiptLocalMapToDatabaseValue("bestBeforeDate", bestBeforeDate) ||
                defaultFunctionType.config.bestBeforeInputType !== goodsReceiptLocalMapToDatabaseValue("bestBeforeInputType", bestBeforeDateInputField) ||
                defaultFunctionType.config.serialNumberType !== goodsReceiptLocalMapToDatabaseValue("serialNumberType", serialNumberType)
            )) ? true : false,
            submenus: [
                {
                    id: FIELDS_DISPLAYED,
                    label: "Fields Displayed",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.displayedFields !== goodsReceiptLocalMapToDatabaseValue("displayedFields", fieldsDisplayed)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: MORE_OR_LESS_QUANTITIES_ALLOWED,
                    label: "More/less Quantities Allowed",
                    isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.allowQtyChange, goodsReceiptLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck) as string[])) ? true : false,
                    renderSubmenu: moreOrLessQuantitiesAllowed.length > 0,
                    submenus: [
                        {
                            id: LESS_QUANTITIES_SECURITY_CHECK,
                            label: "Less Quantities Security Check",
                            isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.allowQtyChange, goodsReceiptLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck) as string[])) ? true : false,
                            isShown: moreOrLessQuantitiesAllowed.includes('allowLessQuantities')
                        },
                        {
                            id: OVERBOOKING_SECURITY_CHECK,
                            label: "Overbooking Security Check",
                            isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.allowQtyChange, goodsReceiptLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck) as string[])) ? true : false,
                            isShown: moreOrLessQuantitiesAllowed.includes('allowOverbooking')
                        }
                    ]
                },
                {
                    id: BEST_BEFORE_DATE,
                    label: "Best Before Date",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.bestBeforeDate !== goodsReceiptLocalMapToDatabaseValue("bestBeforeDate", bestBeforeDate)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: BEST_BEFORE_DATE_INPUT_FIELD,
                    label: "Best Before Date Input Field",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.bestBeforeInputType !== goodsReceiptLocalMapToDatabaseValue("bestBeforeInputType", bestBeforeDateInputField)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                },
                {
                    id: SERIAL_NUMBER_TYPE,
                    label: "Serial Number Type",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.serialType !== goodsReceiptLocalMapToDatabaseValue("serialType", serialNumberType)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                }
            ]
        }
    ];

    const handleClickSaveConfiguration = () => {
        const tempAppData = { ...appData };

        //to avoid different position number in the object - for customized in system dashboard page
        tempAppData.config = {}

        Object.keys(defaultFunctionType.config).map((key: any) => {
            if (key === "entry") {
                tempAppData.config.entry = arrayAdjustmentDefault(goodsReceiptLocalMapToDatabaseValue("entry", entryOption), defaultFunctionType.config.entry);
            } else if (key === "listOfSupplier") {
                tempAppData.config.listOfSupplier = goodsReceiptLocalMapToDatabaseValue("listOfSupplier", listOfSuppliers);
            } else if (key === "orderSelection") {
                tempAppData.config.orderSelection = arrayAdjustmentDefault(goodsReceiptLocalMapToDatabaseValue("orderSelection", selectionOfOrders), defaultFunctionType.config.orderSelection);
            } else if (key === "docInputType") {
                tempAppData.config.docInputType = arrayAdjustmentDefault(goodsReceiptLocalMapToDatabaseValue("docInputType", typeOfOrderInput), defaultFunctionType.config.docInputType);
            } else if (key === "displayedColumns") {
                tempAppData.config.displayedColumns = goodsReceiptLocalMapToDatabaseValue("displayedColumns", displayedColumn);
            } else if (key === "addNewPosition") {
                tempAppData.config.addNewPosition = goodsReceiptLocalMapToDatabaseValue("addNewPosition", newPositionAddable);
            } else if (key === "selectItemInput") {
                tempAppData.config.selectItemInput = arrayAdjustmentDefault(goodsReceiptLocalMapToDatabaseValue("selectItemInput", itemSelection), defaultFunctionType.config.selectItemInput);
            } else if (key === "quickCheck") {
                tempAppData.config.quickCheck = goodsReceiptLocalMapToDatabaseValue("quickCheck", quickCheck);
            } else if (key === "warehouseLayout") {
                tempAppData.config.warehouseLayout = goodsReceiptLocalMapToDatabaseValue("warehouseLayout", warehouseName);
            } else if (key === "completeOrderMode") {
                tempAppData.config.completeOrderMode = goodsReceiptLocalMapToDatabaseValue("completeOrderMode", completeOrder);
            } else if (key === "showOrderPositionCount") {
                tempAppData.config.showOrderPositionCount = goodsReceiptLocalMapToDatabaseValue("showOrderPositionCount", orderPositionCount);
            } else if (key === "useGS1") {
                tempAppData.config.useGS1 = goodsReceiptLocalMapToDatabaseValue("useGS1", gs1128BarcodeScan);
            } else if (key === "gs1FillEanLeadingZeros") {
                tempAppData.config.gs1FillEanLeadingZeros = goodsReceiptLocalMapToDatabaseValue("gs1FillEanLeadingZeros", gs1128BarcodeScanLeadingZeros);
            } else if (key === "allowBpReference") {
                tempAppData.config.allowBpReference = goodsReceiptLocalMapToDatabaseValue("allowBpReference", allowEditBusinessPartnerReferenceNumber);
            } else if (key === "allowOneBatchForOneBinLoc") {
                tempAppData.config.allowOneBatchForOneBinLoc = goodsReceiptLocalMapToDatabaseValue("allowOneBatchForOneBinLoc", useOneBatchWithinOneStorageBinForAnItem);
            } else if (key === "draftDocumentsForNewLine") {
                tempAppData.config.draftDocumentsForNewLine = goodsReceiptLocalMapToDatabaseValue("draftDocumentsForNewLine", draftDocumentsForNewLine);
            } else if (key === "displayedFields") {
                tempAppData.config.displayedFields = goodsReceiptLocalMapToDatabaseValue("displayedFields", fieldsDisplayed);
            } else if (key === "allowQtyChange") {
                tempAppData.config.allowQtyChange = arrayAdjustmentDefault(goodsReceiptLocalMapToDatabaseValue("allowQtyChange", moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck), defaultFunctionType.config.allowQtyChange);
            } else if (key === "bestBeforeDate") {
                tempAppData.config.bestBeforeDate = goodsReceiptLocalMapToDatabaseValue("bestBeforeDate", bestBeforeDate);
            } else if (key === "bestBeforeInputType") {
                tempAppData.config.bestBeforeInputType = goodsReceiptLocalMapToDatabaseValue("bestBeforeInputType", bestBeforeDateInputField);
            } else if (key === "serialType") {
                tempAppData.config.serialType = goodsReceiptDatabaseMapToLocalValue("serialType", serialNumberType);
            } else {
                tempAppData.config[key] = defaultFunctionType.config[key];
            }
        })

        return ConfigurationService.saveConfiguration(currentUserData.key, tempAppData.key, tempAppData).then((res: any) => {
            setIsModified(false);
            setSnackbarMessage(`${breadcrumb[breadcrumb.length - 2].data.name} - ${appData.name} Configuration has been changed`)
            handleShowSnackbar();
        });
    }

    React.useEffect(() => {
        if (isLoaded) {
            setIsModified(true);
        }
    }, [entryOption, viaSuppliers, listOfSuppliers, selectionOfOrders, viaOrderNumber, typeOfOrderInput, displayedColumn, newPositionAddable, itemSelection, quickCheck, warehouseName, completeOrder, orderPositionCount, gs1128BarcodeScan, gs1128BarcodeScanLeadingZeros, allowEditBusinessPartnerReferenceNumber, useOneBatchWithinOneStorageBinForAnItem, draftDocumentsForNewLine, fieldsDisplayed, bestBeforeDate, bestBeforeDateInputField, moreOrLessQuantitiesAllowed, moreOrLessQuantitiesAllowedSecurityCheck, lessQuantitiesSecurityCheck, overbookingSecurityCheck, serialNumberType]);

    React.useEffect(() => {
        if (isLoaded && entryOption.includes(VIA_ORDER_NUMBER)) {
            if (typeOfOrderInput.length <= 0) {
                setListError([...listError, TYPE_OF_ORDER_INPUT]);
            } else {
                setListError(listError.filter((event) => (event !== TYPE_OF_ORDER_INPUT)));
            }
        }
    }, [typeOfOrderInput]);

    React.useEffect(() => {
        if (isLoaded && newPositionAddable === "enable") {
            if (itemSelection.length <= 0) {
                setListError([...listError, ITEM_SELECTION]);
            } else {
                setListError(listError.filter((event) => (event !== ITEM_SELECTION)));
            }
        }
    }, [itemSelection]);

    React.useEffect(() => {
        if (isLoaded) {
            if (entryOption.length <= 0) {
                setListError([...listError, ENTRY_OPTION]);
            } else {
                setListError(listError.filter((event) => (event !== ENTRY_OPTION)));
            }
        }
    }, [entryOption]);

    const handleChangeEntryOption = (e: any) => {
        if (entryOption.includes(e.target.value)) {
            setEntryOption(entryOption.filter((event) => (event !== e.target.value)));
        } else {
            setEntryOption([...entryOption, e.target.value]);
            if (e.target.value === VIA_SUPPLIERS) {
                setListOfSuppliers(goodsReceiptDatabaseMapToLocalValue("listOfSupplier", defaultFunctionType.config.listOfSupplier) as string);
                setSelectionOfOrders(goodsReceiptDatabaseMapToLocalValue("orderSelection", defaultFunctionType.config.orderSelection) as string[])
            }
            if (e.target.value === VIA_ORDER_NUMBER) {
                setTypeOfOrderInput(goodsReceiptDatabaseMapToLocalValue("docInputType", defaultFunctionType.config.docInputType) as string[]);
            }
        }
    }

    const handleChangeViaSuppliers = () => {
        setViaSuppliers(prev => !prev)
    }

    const handleChangeListOfSuppliers = (e: any) => {
        setListOfSuppliers(e.target.value)
    }

    const handleChangeSelectionOfOrders = (e: any) => {
        if (selectionOfOrders.includes(e.target.value)) {
            setSelectionOfOrders(selectionOfOrders.filter((event) => (event !== e.target.value)));
        } else {
            setSelectionOfOrders([...selectionOfOrders, e.target.value]);
        }
    }

    const handleChangeTypeOfOrderInput = (e: any) => {
        if (typeOfOrderInput.includes(e.target.value)) {
            setTypeOfOrderInput(typeOfOrderInput.filter((event) => (event !== e.target.value)));
        } else {
            setTypeOfOrderInput([...typeOfOrderInput, e.target.value]);
        }
    }

    const handleChangeViaOrderNumber = () => {
        setViaOrderNumber(prev => !prev)
    }

    const handleChangeDisplayedColumn = (e: any) => {
        setDisplayedColumn(e.target.value)
    }

    const handleChangeNewPositionAddable = (e: any) => {
        setNewPositionAddable(e.target.value)
        if (e.target.value === "disable") {
            setItemSelection(goodsReceiptDatabaseMapToLocalValue("selectItemInput", defaultFunctionType.config.selectItemInput));
            setListError(listError.filter((event) => (event !== ITEM_SELECTION)));
        } else {
            setListError([...listError, ITEM_SELECTION]);
        }
    }

    const handleChangeItemSelection = (e: any) => {
        if (itemSelection.includes(e.target.value)) {
            setItemSelection(itemSelection.filter((event) => (event !== e.target.value)));
        } else {
            setItemSelection([...itemSelection, e.target.value]);
        }
    }

    const handleChangeQuickCheck = (e: any) => {
        setQuickCheck(e.target.value)
    }

    const handleChangeWarehouseName = (e: any) => {
        setWarehouseName(e.target.value)
    }

    const handleChangeCompleteOrder = (e: any) => {
        setCompleteOrder(e.target.value)
    }

    const handleChangeOrderPositionCount = (e: any) => {
        setOrderPositionCount(e.target.value)
    }

    const handleChangeGs1128BarcodeScan = (e: any) => {
        setGs1128BarcodeScan(e.target.value)
        if (e.target.value === "dontAllow") {
            setGs1128BarcodeScanLeadingZeros(goodsReceiptDatabaseMapToLocalValue("useGS1", defaultFunctionType.config.useGS1));
        }
    }

    const handleChangeGs1128BarcodeScanLeadingZeros = (e: any) => {
        setGs1128BarcodeScanLeadingZeros(e.target.value)
    }

    const handleChangeAllowEditBusinessPartnerReferenceNumber = (e: any) => {
        setAllowEditBusinessPartnerReferenceNumber(e.target.value)
    }

    const handleChangeUseOneBatchWithinOneStorageBinForAnItem = (e: any) => {
        setUseOneBatchWithinOneStorageBinForAnItem(e.target.value)
    }

    const handleChangeDraftDocumentsForNewLine = (e: any) => {
        setDraftDocumentsForNewLine(e.target.value)
    }

    const handleChangeFieldsDisplayed = (e: any) => {
        setFieldsDisplayed(e.target.value)
    }

    const handleChangeBestBeforeDate = (e: any) => {
        setBestBeforeDate(e.target.value)
    }

    const handleChangeBestBeforeDateInputField = (e: any) => {
        setBestBeforeDateInputField(e.target.value)
    }

    const handleChangeSerialNumberType = (e: any) => {
        setSerialNumberType(e.target.value)
    }

    const handleChangeMoreOrLessQuantitiesAllowed = (e: any) => {
        if (moreOrLessQuantitiesAllowed.includes(e.target.value)) {
            setMoreOrLessQuantitiesAllowed(moreOrLessQuantitiesAllowed.filter((event) => (event !== e.target.value)));
            if (e.target.value === 'allowLessQuantities') {
                if (moreOrLessQuantitiesAllowedSecurityCheck.includes('lessQuantityAllowedWithCheck')) {
                    setMoreOrLessQuantitiesAllowedSecurityCheck(moreOrLessQuantitiesAllowedSecurityCheck.filter((event) => (event !== 'lessQuantityAllowedWithCheck')));
                }
                else if (moreOrLessQuantitiesAllowedSecurityCheck.includes('lessQuantityAllowed')) {
                    setMoreOrLessQuantitiesAllowedSecurityCheck(moreOrLessQuantitiesAllowedSecurityCheck.filter((event) => (event !== 'lessQuantityAllowed')));
                }
            }
            else if (e.target.value === 'allowOverbooking') {
                if (moreOrLessQuantitiesAllowedSecurityCheck.includes('overbookingAllowedWithCheck')) {
                    setMoreOrLessQuantitiesAllowedSecurityCheck(moreOrLessQuantitiesAllowedSecurityCheck.filter((event) => (event !== 'overbookingAllowedWithCheck')));
                }
                else if (moreOrLessQuantitiesAllowedSecurityCheck.includes('overbookingAllowed')) {
                    setMoreOrLessQuantitiesAllowedSecurityCheck(moreOrLessQuantitiesAllowedSecurityCheck.filter((event) => (event !== 'overbookingAllowed')));
                }
            }
        } else {
            if (e.target.value === 'allowLessQuantities') {
                if (moreOrLessQuantitiesAllowedSecurityCheck.includes('lessQuantityAllowedWithCheck')) {
                    setMoreOrLessQuantitiesAllowedSecurityCheck(moreOrLessQuantitiesAllowedSecurityCheck.filter((event) => (event !== 'lessQuantityAllowedWithCheck')));
                } else {
                    setMoreOrLessQuantitiesAllowedSecurityCheck([...moreOrLessQuantitiesAllowedSecurityCheck, 'lessQuantityAllowedWithCheck']);
                }

                setLessQuantitiesSecurityCheck('lessQuantityAllowedWithCheck')
            }
            else if (e.target.value === 'allowOverbooking') {
                if (moreOrLessQuantitiesAllowedSecurityCheck.includes('overbookingAllowedWithCheck')) {
                    setMoreOrLessQuantitiesAllowedSecurityCheck(moreOrLessQuantitiesAllowedSecurityCheck.filter((event) => (event !== 'overbookingAllowedWithCheck')));
                } else {
                    setMoreOrLessQuantitiesAllowedSecurityCheck([...moreOrLessQuantitiesAllowedSecurityCheck, 'overbookingAllowedWithCheck']);
                }

                setOverbookingSecurityCheck('overbookingAllowedWithCheck')
            }
            setMoreOrLessQuantitiesAllowed([...moreOrLessQuantitiesAllowed, e.target.value]);
        }
    }

    const handleChangeLessQuantitiesSecurityCheck = (e: any) => {
        setLessQuantitiesSecurityCheck(e.target.value)

        const newMoreOrLessQuantitiesAllowedSecurityCheck = [...moreOrLessQuantitiesAllowedSecurityCheck]

        newMoreOrLessQuantitiesAllowedSecurityCheck.forEach((data, index) => {
            if (data === 'lessQuantityAllowedWithCheck'
                || data === 'lessQuantityAllowed'
            ) {
                newMoreOrLessQuantitiesAllowedSecurityCheck.splice(index, 1)
            }
        })

        newMoreOrLessQuantitiesAllowedSecurityCheck.push(e.target.value)

        setMoreOrLessQuantitiesAllowedSecurityCheck(newMoreOrLessQuantitiesAllowedSecurityCheck)
    }

    const handleChangeOverbookingSecurityCheck = (e: any) => {
        setOverbookingSecurityCheck(e.target.value)

        const newMoreOrLessQuantitiesAllowedSecurityCheck = [...moreOrLessQuantitiesAllowedSecurityCheck]

        newMoreOrLessQuantitiesAllowedSecurityCheck.forEach((data, index) => {
            if (data === 'overbookingAllowedWithCheck'
                || data === 'overbookingAllowed'
            ) {
                newMoreOrLessQuantitiesAllowedSecurityCheck.splice(index, 1)
            }
        })

        newMoreOrLessQuantitiesAllowedSecurityCheck.push(e.target.value)

        setMoreOrLessQuantitiesAllowedSecurityCheck(newMoreOrLessQuantitiesAllowedSecurityCheck)
    }

    const handleChangeActiveOption = (id: string) => {
        let element = document.getElementById(id)

        if (element) {
            const bounding = element.getBoundingClientRect()

            if (
                bounding.top >= 0 &&
                bounding.top <= 400 &&
                bounding.left >= 0 &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            ) {
                setActiveOption(id)
            }
        }
    }

    const handleActivePreview = (id: string) => {
        const orderItemDataAtLeastOnePosition =
            [
                {
                    id: "1",
                    item: "LM4029PH",
                    description: "LeMon 4029 Printhead",
                    open: 6,
                    covered: 6,
                    openCoveredType: "Palette(n)",
                    warehouseName: "WH General",
                    warehouseCode: "01",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "2",
                    item: "P10010",
                    description: "Sales Set",
                    open: 1,
                    covered: 0,
                    openCoveredType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "02",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "3",
                    item: "C00007",
                    description: "Extern Hard Disk 3TB",
                    open: 12,
                    covered: 0,
                    openCoveredType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "03",
                    mandatoryBatchOrSerialNumber: true
                },
                {
                    id: "4",
                    item: "LM4029ACA",
                    description: "LeMon 4029 Printer AC adapter",
                    open: 6,
                    covered: 0,
                    openCoveredType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "04",
                    mandatoryBatchOrSerialNumber: false
                },
            ];
        const orderItemDataAllPositions =
            [
                {
                    id: "1",
                    item: "LM4029PH",
                    description: "LeMon 4029 Printhead",
                    open: 6,
                    covered: 6,
                    openCoveredType: "Palette(n)",
                    warehouseName: "WH General",
                    warehouseCode: "01",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "2",
                    item: "P10010",
                    description: "Sales Set",
                    open: 1,
                    covered: 1,
                    openCoveredType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "02",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "3",
                    item: "C00007",
                    description: "Extern Hard Disk 3TB",
                    open: 12,
                    covered: 12,
                    openCoveredType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "03",
                    mandatoryBatchOrSerialNumber: false
                },
                {
                    id: "4",
                    item: "LM4029ACA",
                    description: "LeMon 4029 Printer AC adapter",
                    open: 6,
                    covered: 6,
                    openCoveredType: "pc",
                    warehouseName: "WH General",
                    warehouseCode: "04",
                    mandatoryBatchOrSerialNumber: false
                },
            ];
        const supplierData =
            [
                {
                    name: "No Business Partner",
                    alwaysShow: true

                },
                {
                    name: "Business Tools AG",
                    type: "withOpenOrder",
                    description: "(With open Order)"

                },
                {
                    name: "Pier 1 Importe",
                    type: "noOpenOrder",
                    description: "(No open Order)"
                },
                {
                    name: "Dataquest AG",
                    type: "noOpenOrder",
                    description: "(No open Order)"
                },
                {
                    name: "Sea Corp",
                    type: "withOpenOrder",
                    description: "(With open Order)"
                },
                {
                    name: "OEC 2",
                    type: "noOpenOrder",
                    description: "(No open Order)"
                },
                {
                    name: "ARP Datacon AG",
                    type: "withOpenOrder",
                    description: "(With open Order)"
                },
            ];
        const orderSelectionData =
            [
                {
                    name: "2352",
                    count: 4
                },
                {
                    name: "2232",
                    count: 2

                }
            ];

        const detailPosition = {
            position: 1,
            item: "LM4029PH",
            description: "LeMon 4029 Printhead",
            ordered: 6,
            open: 6,
            orderedOpenType: "Palette(n)",
            coveredQuantity: 0,
            coveredQuantityType: "Palette(n)",
            warehouseList: [
                {
                    name: "01 - WH General",
                    type: "whGeneral"
                },
                {
                    name: "02 - WH Reserve",
                    type: "whReserve"
                },
                {
                    name: "03 - WH Delivery",
                    type: "whDelivery"
                },
                {
                    name: "04 - WH Consignment",
                    type: "whConsignment"
                },
                {
                    name: "05 - WH Bin locations",
                    type: "whBinLocation"
                },
            ],
            binLocationList: [
                {
                    name: "05-G01-R01-P01",
                    type: "binLocationList"
                },
                {
                    name: "05-G01-R01-P02",
                    type: "binLocationList"
                },
                {
                    name: "05-G01-R02-P01",
                    type: "binLocationList"
                },
                {
                    name: "05-G01-R02-P02",
                    type: "binLocationList"
                },
                {
                    name: "05-G01-R03-P01",
                    type: "binLocationList"
                },
                {
                    name: "05-G03-R01-P01",
                    type: "binLocationList"
                },
            ]
        }

        const menuData = [
            {
                name: "Select Supplier",
                function: () => { }
            },
            {
                name: "Input Order Number",
                function: () => { }
            }
        ]

        switch (id) {
            case ENTRY_OPTION:
                if (entryOption.includes(VIA_SUPPLIERS) && entryOption.includes(VIA_ORDER_NUMBER)) {
                    return setActivePreview(<MenuPage headerTitle="Goods Receipt" data={menuData} previewWidth={320} previewHeight={568} />);
                } else if (entryOption.includes(VIA_SUPPLIERS)) {
                    return setActivePreview(<ListPage headerTitle="Goods Receipt" listTypes={listOfSuppliers === "allSuppliers" ? ["withOpenOrder", "noOpenOrder"] : ["withOpenOrder"]} listModalTitle="Choose an Order" data={supplierData} isModalOpen={true} previewWidth={320} previewHeight={568} />);
                } else if (entryOption.includes(VIA_ORDER_NUMBER)) {
                    return setActivePreview(<EnterItemNumberPage headerTitle={"Enter Order Number"} data={{ inputMessage: "Please enter the purchase order number", barcodeMessage: "Please scan the barcode of the order" }} inputTypes={typeOfOrderInput} previewHeight={568} previewWidth={320} />);
                } else {
                    return setActivePreview(<NoPreviewPage type={"emptyValue"} previewHeight={568} previewWidth={320} />);
                }
            case LIST_OF_SUPPLIERS:
                return setActivePreview(<ListPage headerTitle="Goods Receipt" listTypes={listOfSuppliers === "allSuppliers" ? ["withOpenOrder", "noOpenOrder"] : ["withOpenOrder"]} listModalTitle="Choose an Order" data={supplierData} isModalOpen={true} previewWidth={320} previewHeight={568} />);
            case SELECTION_OF_ORDERS:
                return setActivePreview(<ListSelectionPage previewWidth={320} previewHeight={568} headerTitle={"Goods Receipt"} listSelectionModalTitle={"Choose an Order"} data={orderSelectionData} dataTitle="Ontego One" listTypes={selectionOfOrders} showCount={orderPositionCount === "show" ? true : false} isModalOpen={true} />);
            case TYPE_OF_ORDER_INPUT:
                if (typeOfOrderInput.length > 0) {
                    return setActivePreview(<EnterItemNumberPage headerTitle={"Enter Order Number"} data={{ inputMessage: "Please enter the purchase order number", barcodeMessage: "Please scan the barcode of the order" }} inputTypes={typeOfOrderInput} previewHeight={568} previewWidth={320} />);
                } else {
                    return setActivePreview(<NoPreviewPage type={"emptyValue"} previewHeight={568} previewWidth={320} />);
                }
            case DISPLAYED_COLUMN:
                return setActivePreview(<ListOfOrderPositionsPage headerTitle={"Goods Receipt"} newPositionAddable={newPositionAddable === "disable" ? false : true} completeOrderType={completeOrder} quickCheck={quickCheck === "disable" ? false : true} allowEditBpRefNo={allowEditBusinessPartnerReferenceNumber === "dontAllow" ? false : true} warehouseLayout={warehouseName} data={completeOrder === "atLeastOnePosition" ? orderItemDataAtLeastOnePosition : orderItemDataAllPositions} templateType={displayedColumn} previewHeight={568} previewWidth={320} />);
            case ITEM_SELECTION:
                if (itemSelection.length > 0) {
                    return setActivePreview(<NewPositionAddablePage inputTypes={itemSelection} previewHeight={568} previewWidth={320} />);
                } else {
                    return setActivePreview(<NoPreviewPage type={"emptyValue"} previewHeight={568} previewWidth={320} />);
                }
            case NEW_POSITION_ADDABLE:
                return setActivePreview(<ListOfOrderPositionsPage headerTitle={"Goods Receipt"} newPositionAddable={newPositionAddable === "disable" ? false : true} completeOrderType={completeOrder} quickCheck={quickCheck === "disable" ? false : true} allowEditBpRefNo={allowEditBusinessPartnerReferenceNumber === "dontAllow" ? false : true} warehouseLayout={warehouseName} data={completeOrder === "atLeastOnePosition" ? orderItemDataAtLeastOnePosition : orderItemDataAllPositions} templateType={displayedColumn} previewHeight={568} previewWidth={320} />);
            case QUICK_CHECK:
                return setActivePreview(<ListOfOrderPositionsPage headerTitle={"Goods Receipt"} newPositionAddable={newPositionAddable === "disable" ? false : true} completeOrderType={completeOrder} quickCheck={quickCheck === "disable" ? false : true} allowEditBpRefNo={allowEditBusinessPartnerReferenceNumber === "dontAllow" ? false : true} warehouseLayout={warehouseName} data={completeOrder === "atLeastOnePosition" ? orderItemDataAtLeastOnePosition : orderItemDataAllPositions} templateType={displayedColumn} previewHeight={568} previewWidth={320} />);
            case WAREHOUSE_NAME:
                return setActivePreview(<ListOfOrderPositionsPage headerTitle={"Goods Receipt"} newPositionAddable={newPositionAddable === "disable" ? false : true} completeOrderType={completeOrder} quickCheck={quickCheck === "disable" ? false : true} allowEditBpRefNo={allowEditBusinessPartnerReferenceNumber === "dontAllow" ? false : true} warehouseLayout={warehouseName} data={completeOrder === "atLeastOnePosition" ? orderItemDataAtLeastOnePosition : orderItemDataAllPositions} templateType={displayedColumn} previewHeight={568} previewWidth={320} />);
            case COMPLETE_ORDER:
                return setActivePreview(<ListOfOrderPositionsPage headerTitle={"Goods Receipt"} newPositionAddable={newPositionAddable === "disable" ? false : true} completeOrderType={completeOrder} quickCheck={quickCheck === "disable" ? false : true} allowEditBpRefNo={allowEditBusinessPartnerReferenceNumber === "dontAllow" ? false : true} warehouseLayout={warehouseName} data={completeOrder === "atLeastOnePosition" ? orderItemDataAtLeastOnePosition : orderItemDataAllPositions} templateType={displayedColumn} previewHeight={568} previewWidth={320} />);
            case ORDER_POSITION_COUNT:
                return setActivePreview(<ListSelectionPage previewWidth={320} previewHeight={568} headerTitle={"Goods Receipt"} listSelectionModalTitle={"Choose an Order"} data={orderSelectionData} dataTitle="Ontego One" listTypes={selectionOfOrders} showCount={orderPositionCount === "show" ? true : false} isModalOpen={true} />);
            case GS1_128_BARCODE_SCAN:
                if (gs1128BarcodeScan === "dontAllow") {
                    return setActivePreview(<OrderPositionDetailsPage notAllowScanGS1128={true} headerTitle={"Goods Receipt"} allowedTypes={moreOrLessQuantitiesAllowed} data={detailPosition} previewHeight={568} previewWidth={320} />);
                } else {
                    return setActivePreview(<ListOfOrderPositionsPage allowScanGS1128={true} headerTitle={"Goods Receipt"} newPositionAddable={newPositionAddable === "disable" ? false : true} completeOrderType={completeOrder} quickCheck={quickCheck === "disable" ? false : true} allowEditBpRefNo={allowEditBusinessPartnerReferenceNumber === "dontAllow" ? false : true} warehouseLayout={warehouseName} data={completeOrder === "atLeastOnePosition" ? orderItemDataAtLeastOnePosition : orderItemDataAllPositions} templateType={displayedColumn} previewHeight={568} previewWidth={320} />);
                }
            case GS1_128_BARCODE_SCAN_LEADING_ZEROS:
                return setActivePreview(<LeadingZerosPage addZeros={gs1128BarcodeScanLeadingZeros === "dontAdd" ? false : true} previewHeight={568} previewWidth={320} />);
            case ALLOW_EDIT_BUSINESS_PARTNER_REFERENCE_NUMBER:
                return setActivePreview(<ListOfOrderPositionsPage headerTitle={"Goods Receipt"} newPositionAddable={newPositionAddable === "disable" ? false : true} completeOrderType={completeOrder} quickCheck={quickCheck === "disable" ? false : true} allowEditBpRefNo={allowEditBusinessPartnerReferenceNumber === "dontAllow" ? false : true} warehouseLayout={warehouseName} data={completeOrder === "atLeastOnePosition" ? orderItemDataAtLeastOnePosition : orderItemDataAllPositions} templateType={displayedColumn} previewHeight={568} previewWidth={320} />);
            case USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM:
                if (useOneBatchWithinOneStorageBinForAnItem === "disable") {
                    return setActivePreview(<BatchesPage oneBatchOnly={true} totalBatches={8} bbdInputFieldType={bestBeforeDateInputField} bbdType={bestBeforeDate} data={[]} previewHeight={568} previewWidth={320} />);
                } else {
                    return setActivePreview(<OrderPositionDetailsPage notAllowScanGS1128={true} headerTitle={"Goods Receipt"} allowedTypes={moreOrLessQuantitiesAllowed} data={detailPosition} previewHeight={568} previewWidth={320} />);
                }
            case DRAFT_DOCUMENTS_FOR_NEW_LINE:
                return setActivePreview(<ListOfOrderPositionsPage draftDocumentSetting={{ setting: true, draft: draftDocumentsForNewLine === "enable" ? true : false }} headerTitle={"Goods Receipt"} newPositionAddable={newPositionAddable === "disable" ? false : true} completeOrderType={completeOrder} quickCheck={quickCheck === "disable" ? false : true} allowEditBpRefNo={allowEditBusinessPartnerReferenceNumber === "dontAllow" ? false : true} warehouseLayout={warehouseName} data={completeOrder === "atLeastOnePosition" ? orderItemDataAtLeastOnePosition : orderItemDataAllPositions} templateType={displayedColumn} previewHeight={568} previewWidth={320} />);
            case FIELDS_DISPLAYED:
                return setActivePreview(<OrderPositionDetailsPage headerTitle={"Goods Receipt"} allowedTypes={moreOrLessQuantitiesAllowed} data={detailPosition} previewHeight={568} previewWidth={320} />);
            case BEST_BEFORE_DATE:
                return setActivePreview(<BatchesPage oneBatchOnly={true} totalBatches={8} bbdInputFieldType={bestBeforeDateInputField} bbdType={bestBeforeDate} data={[]} previewHeight={568} previewWidth={320} />);
            case BEST_BEFORE_DATE_INPUT_FIELD:
                return setActivePreview(<BatchesPage oneBatchOnly={true} totalBatches={8} bbdInputFieldType={bestBeforeDateInputField} bbdType={"showBbd"} data={[]} previewHeight={568} previewWidth={320} />);
            case MORE_OR_LESS_QUANTITIES_ALLOWED:
            case LESS_QUANTITIES_SECURITY_CHECK:
            case OVERBOOKING_SECURITY_CHECK:
                return setActivePreview(<OrderPositionDetailsPage headerTitle={"Goods Receipt"} allowedTypes={moreOrLessQuantitiesAllowedSecurityCheck} data={detailPosition} previewHeight={568} previewWidth={320} />);
            case SERIAL_NUMBER_TYPE:
                return setActivePreview(<SerialNumberType totalSerialNumbers={1} value={serialNumberType} data={[]} previewHeight={568} previewWidth={320} />)
            default:
                return setActivePreview(id);
        }
    }

    React.useEffect(() => {
        handleActivePreview(activeOption);

        GoodsReceiptMenus.forEach(menu => {
            menu.submenus.forEach((subMenu: any) => {
                if (subMenu.id === activeOption) {
                    setActiveMenu(menu)
                    setActiveOptionName(subMenu.label)
                }

                subMenu.submenus.forEach((thirdSubmenu: any) => {
                    if (thirdSubmenu.id === activeOption) {
                        setActiveMenu(menu)
                        setActiveOptionName(thirdSubmenu.label)
                    };
                })
            });
        })

    }, [activeOption, entryOption, displayedColumn, listOfSuppliers, warehouseName, orderPositionCount, selectionOfOrders, typeOfOrderInput, fieldsDisplayed, bestBeforeDate, bestBeforeDateInputField, moreOrLessQuantitiesAllowed, lessQuantitiesSecurityCheck, overbookingSecurityCheck, itemSelection, allowEditBusinessPartnerReferenceNumber, completeOrder, quickCheck, newPositionAddable, gs1128BarcodeScan, gs1128BarcodeScanLeadingZeros, useOneBatchWithinOneStorageBinForAnItem, draftDocumentsForNewLine, serialNumberType]);

    const viewPortCallback = () => {
        // handleChangeActiveOption(SETTINGS_FOR_ENTRY)
        handleChangeActiveOption(ENTRY_OPTION)

        handleChangeActiveOption(LIST_OF_SUPPLIERS)
        handleChangeActiveOption(SELECTION_OF_ORDERS)

        handleChangeActiveOption(TYPE_OF_ORDER_INPUT)

        // handleChangeActiveOption(SETTINGS_FOR_LIST_OF_POSITION)
        handleChangeActiveOption(DISPLAYED_COLUMN)

        handleChangeActiveOption(NEW_POSITION_ADDABLE)
        handleChangeActiveOption(ITEM_SELECTION)

        handleChangeActiveOption(QUICK_CHECK)
        handleChangeActiveOption(WAREHOUSE_NAME)
        handleChangeActiveOption(COMPLETE_ORDER)
        handleChangeActiveOption(ORDER_POSITION_COUNT)

        handleChangeActiveOption(GS1_128_BARCODE_SCAN)
        handleChangeActiveOption(GS1_128_BARCODE_SCAN_LEADING_ZEROS)

        handleChangeActiveOption(ALLOW_EDIT_BUSINESS_PARTNER_REFERENCE_NUMBER)
        handleChangeActiveOption(USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM)
        handleChangeActiveOption(DRAFT_DOCUMENTS_FOR_NEW_LINE)

        // handleChangeActiveOption(SETTINGS_FOR_POSITION_DETAILS)
        handleChangeActiveOption(FIELDS_DISPLAYED)
        handleChangeActiveOption(MORE_OR_LESS_QUANTITIES_ALLOWED)
        handleChangeActiveOption(LESS_QUANTITIES_SECURITY_CHECK)
        handleChangeActiveOption(OVERBOOKING_SECURITY_CHECK)

        handleChangeActiveOption(BEST_BEFORE_DATE)
        handleChangeActiveOption(BEST_BEFORE_DATE_INPUT_FIELD)

        handleChangeActiveOption(SERIAL_NUMBER_TYPE)
    }

    React.useEffect(() => {
        setActiveOption(ENTRY_OPTION)
    }, [])

    const GoodsReceiptContextValue: IGoodsReceiptContextInterface = {
        defaultFunctionType: defaultFunctionType,
        activeOption: activeOption,
        setActiveOption: handleChangeActiveOption,

        entryOption: entryOption,
        handleChangeEntryOption: handleChangeEntryOption,

        viaSuppliers: viaSuppliers,
        handleChangeViaSuppliers: handleChangeViaSuppliers,
        viaOrderNumber: viaOrderNumber,
        handleChangeViaOrderNumber: handleChangeViaOrderNumber,

        handleChangeListOfSuppliers: handleChangeListOfSuppliers,
        listOfSuppliers: listOfSuppliers,
        selectionOfOrders: selectionOfOrders,
        handleChangeSelectionOfOrders: handleChangeSelectionOfOrders,
        typeOfOrderInput: typeOfOrderInput,
        handleChangeTypeOfOrderInput: handleChangeTypeOfOrderInput,

        displayedColumn: displayedColumn,
        handleChangeDisplayedColumn: handleChangeDisplayedColumn,
        newPositionAddable: newPositionAddable,
        handleChangeNewPositionAddable: handleChangeNewPositionAddable,
        itemSelection: itemSelection,
        handleChangeItemSelection: handleChangeItemSelection,
        quickCheck: quickCheck,
        handleChangeQuickCheck: handleChangeQuickCheck,
        warehouseName: warehouseName,
        handleChangeWarehouseName: handleChangeWarehouseName,
        completeOrder: completeOrder,
        handleChangeCompleteOrder: handleChangeCompleteOrder,
        orderPositionCount: orderPositionCount,
        handleChangeOrderPositionCount: handleChangeOrderPositionCount,
        gs1128BarcodeScan: gs1128BarcodeScan,
        handleChangeGs1128BarcodeScan: handleChangeGs1128BarcodeScan,
        gs1128BarcodeScanLeadingZeros: gs1128BarcodeScanLeadingZeros,
        handleChangeGs1128BarcodeScanLeadingZeros: handleChangeGs1128BarcodeScanLeadingZeros,
        allowEditBusinessPartnerReferenceNumber: allowEditBusinessPartnerReferenceNumber,
        handleChangeAllowEditBusinessPartnerReferenceNumber: handleChangeAllowEditBusinessPartnerReferenceNumber,
        useOneBatchWithinOneStorageBinForAnItem,
        handleChangeUseOneBatchWithinOneStorageBinForAnItem,
        draftDocumentsForNewLine,
        handleChangeDraftDocumentsForNewLine,

        fieldsDisplayed: fieldsDisplayed,
        handleChangeFieldsDisplayed: handleChangeFieldsDisplayed,
        bestBeforeDate: bestBeforeDate,
        handleChangeBestBeforeDate: handleChangeBestBeforeDate,
        bestBeforeDateInputField: bestBeforeDateInputField,
        handleChangeBestBeforeDateInputField: handleChangeBestBeforeDateInputField,
        moreOrLessQuantitiesAllowed: moreOrLessQuantitiesAllowed,
        handleChangeMoreOrLessQuantitiesAllowed: handleChangeMoreOrLessQuantitiesAllowed,

        lessQuantitiesSecurityCheck: lessQuantitiesSecurityCheck,
        handleChangeLessQuantitiesSecurityCheck: handleChangeLessQuantitiesSecurityCheck,
        overbookingSecurityCheck: overbookingSecurityCheck,
        handleChangeOverbookingSecurityCheck: handleChangeOverbookingSecurityCheck,

        serialNumberType: serialNumberType,
        handleChangeSerialNumberType: handleChangeSerialNumberType
    };

    return (
        <ConfigurationContainer
            detectViewportCallback={viewPortCallback}
            title={`Goods Receipt`}
            icon={<FunctionGoodsReceipt />}
            activeMenu={activeMenu}
            activeViewport={activePreview}
            activeOption={activeOption}
            activeOptionName={activeOptionName}
            sidebarMenus={GoodsReceiptMenus}
            saveConfiguration={handleClickSaveConfiguration}
            setIsModified={setIsModified}
            isModified={isModified}
            setListError={setListError}
            listError={listError}
            setConfigurationToDefault={() => setConfiguration(defaultFunctionType)}
            setConfigurationToPreviousSetting={() => setConfiguration(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION))}
        >
            <GoodsReceiptContextProvider value={GoodsReceiptContextValue}>
                <SettingsForEntry />
                <SettingsForListPositions />
                <SettingsForPositionDetails />
            </GoodsReceiptContextProvider>
        </ConfigurationContainer>
    )
};

export default GoodsReceiptConfiguration;