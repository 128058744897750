import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import * as React from 'react';
import classnames from 'classnames'; // Custom Styles

import { useStyles } from "./KeyboardCompleteModalStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Modal } from "../../index";
import { GreyButton } from "../../index"; // Icon

import Space from "../../../icons/Space/Space";
import Capslock from "../../../icons/Capslock/Capslock";
import ChevronLeft from "../../../icons/Chevron/Left";
import ChevronRight from "../../../icons/Chevron/Right";
import Backspace from "../../../icons/Backspace/Backspace";
export var KeyboardCompleteModal = function KeyboardCompleteModal(props) {
  var _classnames, _classnames2;

  var classes = useStyles(props);

  var _React$useState = React.useState(""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      display = _React$useState2[0],
      setDisplay = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      capslock = _React$useState4[0],
      setCapslock = _React$useState4[1];

  var _React$useState5 = React.useState(0),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      keyboardIndex = _React$useState6[0],
      setKeyboardIndex = _React$useState6[1];

  var isModalOpen = props.isModalOpen,
      title = props.title,
      setInitialValue = props.setInitialValue,
      setOpenModalState = props.setOpenModalState,
      initialValue = props.initialValue;
  React.useEffect(function () {
    setDisplay(initialValue);
  }, [initialValue, isModalOpen]);

  var handleAppendChange = function handleAppendChange(value) {
    if (capslock) {
      value = value.toUpperCase();
    }

    setDisplay(display.concat(value));
  };

  var handleEraseChange = function handleEraseChange() {
    setDisplay(display.substring(0, display.length - 1));
  };

  var changeKeyboardIndexReverse = function changeKeyboardIndexReverse() {
    if (keyboardIndex === 0) {
      setKeyboardIndex(4);
    } else {
      setKeyboardIndex(keyboardIndex - 1);
    }
  };

  var changeKeyboardIndexForward = function changeKeyboardIndexForward() {
    if (keyboardIndex === 4) {
      setKeyboardIndex(0);
    } else {
      setKeyboardIndex(keyboardIndex + 1);
    }
  };

  var changeCapslock = function changeCapslock() {
    setCapslock(!capslock);
  };

  var modalWidth = 285;
  var modalHeight = 350;
  var keys = [{
    alphabet: true,
    rows: [{
      cols: [{
        value: "a"
      }, {
        value: "b"
      }, {
        value: "c"
      }, {
        value: "d"
      }, {
        value: "e"
      }]
    }, {
      cols: [{
        value: "f"
      }, {
        value: "g"
      }, {
        value: "h"
      }, {
        value: "i"
      }, {
        value: "j"
      }]
    }, {
      cols: [{
        value: "k"
      }, {
        value: "l"
      }, {
        value: "m"
      }, {
        value: "n"
      }, {
        value: "o"
      }]
    }]
  }, {
    alphabet: true,
    rows: [{
      cols: [{
        value: "p"
      }, {
        value: "q"
      }, {
        value: "r"
      }, {
        value: "s"
      }, {
        value: "t"
      }]
    }, {
      cols: [{
        value: "u"
      }, {
        value: "v"
      }, {
        value: "w"
      }, {
        value: "x"
      }, {
        value: "y"
      }]
    }, {
      cols: [{
        value: "z"
      }, {
        value: "ä"
      }, {
        value: "ö"
      }, {
        value: "ü"
      }, {
        value: "ß"
      }]
    }]
  }, {
    alphabet: false,
    rows: [{
      cols: [{
        value: "+"
      }, {
        value: "*"
      }, {
        value: "="
      }, {
        value: "("
      }, {
        value: ")"
      }]
    }, {
      cols: [{
        value: "-"
      }, {
        value: "_"
      }, {
        value: "["
      }, {
        value: "]"
      }, {
        value: "'"
      }]
    }, {
      cols: [{
        value: "`"
      }, {
        value: "`"
      }, {
        value: "<"
      }, {
        value: ">"
      }, {
        value: "#"
      }]
    }]
  }, {
    alphabet: false,
    rows: [{
      cols: [{
        value: "/"
      }, {
        value: "?"
      }, {
        value: "|"
      }, {
        value: "{"
      }, {
        value: "}"
      }]
    }, {
      cols: [{
        value: "~"
      }, {
        value: "$"
      }, {
        value: "&"
      }, {
        value: "%"
      }, {
        value: "$"
      }]
    }, {
      cols: [{
        value: "$"
      }, {
        value: '"'
      }, {
        value: "!"
      }, {
        value: "*"
      }, {
        value: "^"
      }]
    }]
  }, {
    alphabet: false,
    rows: [{
      cols: [{
        value: "1"
      }, {
        value: "2"
      }, {
        value: "3"
      }, {
        value: "4"
      }, {
        value: "5"
      }]
    }, {
      cols: [{
        value: "6"
      }, {
        value: "7"
      }, {
        value: "8"
      }, {
        value: "9"
      }, {
        value: "0"
      }]
    }, {
      cols: [{
        value: ","
      }, {
        value: ";"
      }, {
        value: "."
      }, {
        value: ":"
      }, {
        value: "@"
      }]
    }]
  }];
  var capslockClasses = classnames((_classnames = {}, _defineProperty(_classnames, classes.keyboardIcon, true), _defineProperty(_classnames, classes.keyboardIconActive, capslock), _classnames));
  var keyboardButtonClasses = classnames((_classnames2 = {}, _defineProperty(_classnames2, classes.keyboardButton, true), _defineProperty(_classnames2, classes.keyboardButtonCapslock, capslock), _classnames2));
  return React.createElement(Modal, {
    isModalOpen: isModalOpen,
    width: modalWidth,
    height: modalHeight
  }, React.createElement("div", {
    className: classes.floatingDiv
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.floatingDivBarTop
  }, React.createElement("p", null, title)), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.keyboardDisplay
  }, React.createElement("p", null, display)), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.keyboardButtonDiv
  }, keys[keyboardIndex].rows.map(function (row) {
    return React.createElement(Grid, {
      container: true
    }, row.cols.map(function (col) {
      return React.createElement(Grid, {
        item: true,
        md: "auto"
      }, React.createElement(Button, {
        className: keyboardButtonClasses,
        onClick: function onClick() {
          return handleAppendChange(col.value);
        }
      }, React.createElement("p", null, col.value)));
    }));
  }), React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: "auto"
  }, React.createElement(Button, {
    className: classes.keyboardButtonFix,
    onClick: function onClick() {
      return changeKeyboardIndexReverse();
    }
  }, keyboardIndex === 0 ? React.createElement("p", null, "123") : React.createElement(ChevronLeft, {
    className: classes.keyboardIcon
  }))), React.createElement(Grid, {
    item: true,
    md: "auto"
  }, React.createElement(Button, {
    className: classes.keyboardButtonFix,
    onClick: function onClick() {
      return changeKeyboardIndexForward();
    }
  }, React.createElement(ChevronRight, {
    className: classes.keyboardIcon
  }))), keys[keyboardIndex].alphabet ? React.createElement(Grid, {
    item: true,
    md: "auto"
  }, React.createElement(Button, {
    className: classes.keyboardButtonFix,
    onClick: function onClick() {
      return changeCapslock();
    }
  }, React.createElement(Capslock, {
    className: capslockClasses
  }))) : React.createElement(Grid, {
    item: true,
    md: "auto"
  }, React.createElement(Button, {
    className: classes.keyboardButtonFix,
    onClick: function onClick() {}
  }, React.createElement("p", null, ""))), React.createElement(Grid, {
    item: true,
    md: "auto"
  }, React.createElement(Button, {
    className: classes.keyboardButtonFix,
    onClick: function onClick() {
      return handleAppendChange(" ");
    }
  }, React.createElement(Space, {
    className: classes.keyboardIcon
  }))), React.createElement(Grid, {
    item: true,
    md: "auto"
  }, React.createElement(Button, {
    className: classes.keyboardButtonFix,
    onClick: function onClick() {
      return handleEraseChange();
    }
  }, React.createElement(Backspace, {
    className: classes.keyboardIcon
  }))))), React.createElement(Grid, {
    item: true,
    md: 6,
    className: classes.floatingDivBarBottom
  }, React.createElement(GreyButton, {
    type: "left",
    onClick: function onClick() {
      setOpenModalState(false);
      setDisplay(initialValue);
    }
  }, "Cancel")), React.createElement(Grid, {
    item: true,
    md: 6,
    className: classes.floatingDivBarBottom
  }, React.createElement(GreyButton, {
    type: "right",
    onClick: function onClick() {
      setInitialValue(display);
      setOpenModalState(false);
      setDisplay("");
    }
  }, "Confirm")))));
};
KeyboardCompleteModal.defaultProps = {
  setInitialValue: function setInitialValue() {},
  setOpenModalState: function setOpenModalState() {},
  initialValue: ""
};
export default React.memo(KeyboardCompleteModal);