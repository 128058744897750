import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// React Lib
import React, { useState } from "react";
import classnames from 'classnames'; // Component Styles

import { useStyles } from "./SystemStyles"; // Material UI Core

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Grid from '@material-ui/core/Grid'; // @otg-one/ui_components/components

import { Button, Chip, Typography, Tooltip, OverflowMenu } from "../index"; // @otg-one/ui_components/icons

import { ShowMoreHorizontal, ShowMoreVertical, Database, CPU, Delete, Device, Bug, ArrowUp, EmptySystem } from "../../icons"; // @otg-one/ui_components/constants

import { Color, FONT_FAMILY } from "../../constants";
import Switch from "../Switch";
import { getFunctionIcon } from "../../utils/Utils";
import { useMediaQuery, useTheme } from "@material-ui/core";

var DeviceCount = function DeviceCount(props) {
  var classes = useStyles(props);
  var theme = useTheme();
  var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  var data = props.data,
      activeDevice = props.activeDevice,
      deviceLimit = props.deviceLimit;
  return React.createElement("div", {
    className: classes.systemDeviceCountContainer
  }, React.createElement("div", {
    className: classes.systemDeviceCount
  }, React.createElement(Tooltip, {
    title: "Device Count",
    placement: "right"
  }, React.createElement("div", {
    style: {
      marginTop: 2
    }
  }, React.createElement(Device, {
    style: {
      color: Color.primary[400]
    }
  }))), data.deploymentEnv === 0 ? React.createElement(React.Fragment, null, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_BOLD,
    size: isMobile ? 35 : 48,
    color: Color.neutral[400],
    style: {
      letterSpacing: -1.7,
      marginLeft: 5
    }
  }, deviceLimit), React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_BOLD,
    size: 18,
    color: Color.neutral[600],
    style: {
      marginLeft: 10,
      marginTop: -5
    }
  }, "Parallel Device Max")) : React.createElement(React.Fragment, null, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_BOLD,
    size: isMobile ? 35 : 48,
    color: Color.neutral[400],
    style: {
      letterSpacing: -1.7,
      marginLeft: 5
    }
  }, activeDevice), React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_BOLD,
    size: 18,
    color: Color.neutral[600],
    style: {
      marginLeft: 10,
      marginTop: -25,
      width: 50
    }
  }, "/ ".concat(deviceLimit)), React.createElement("div", {
    style: {
      width: "100%",
      height: 5,
      background: Color.neutral[200]
    }
  }, React.createElement("div", {
    style: {
      width: "".concat(activeDevice / deviceLimit * 100, "%"),
      height: 5,
      background: Color.gradient[100]
    }
  })))));
};

var ApplicationFunction = function ApplicationFunction(props) {
  var classes = useStyles(props);
  var theme = useTheme();
  var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  var data = props.data;
  return React.createElement(React.Fragment, null, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.CAPITOLIUM,
    size: 16,
    color: Color.neutral[900],
    style: {
      marginTop: isMobile ? 10 : 35
    }
  }, "Application Functions"), React.createElement("div", {
    className: classes.systemApplicationFunctionIconListContainer,
    style: {
      marginTop: 5
    }
  }, data.functions.map(function (func, index) {
    var _classnames;

    var isActive = func.isActive === 1;
    var functionClasses = classnames((_classnames = {}, _defineProperty(_classnames, classes.activeFunction, isActive), _defineProperty(_classnames, classes.inactiveFunction, !isActive), _classnames));
    return React.createElement("div", {
      className: classes.systemApplicationFunction,
      style: {
        color: Color.primary[500],
        marginRight: index < data.functions.length - 1 && 20
      }
    }, React.createElement(Tooltip, {
      title: func.name,
      placement: "right"
    }, React.createElement("div", {
      className: functionClasses
    }, getFunctionIcon(func.functionTypeId))), React.createElement(Tooltip, {
      title: "Customized",
      placement: "right"
    }, React.createElement("div", {
      className: classes.systemApplicationFunctionIconStatus,
      style: {
        display: func.isChanged ? "block" : "none",
        color: Color.neutral[0]
      }
    })));
  })));
};

var desc = function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

var stableSort = function stableSort(array, cmp) {
  var stabilizedThis = array.map(function (el, index) {
    return [el, index];
  });
  stabilizedThis.sort(function (a, b) {
    var order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(function (el) {
    return el[0];
  });
};

var getSorting = function getSorting(order, orderBy) {
  return order === 'desc' ? function (a, b) {
    return desc(a, b, orderBy);
  } : function (a, b) {
    return -desc(a, b, orderBy);
  };
};

var headCells = [{
  id: 'appName',
  left: true,
  sortable: false,
  label: "App. Name"
}, {
  id: 'status',
  left: true,
  sortable: false,
  label: "Status - PIN No."
}, {
  id: 'dbName',
  left: true,
  sortable: false,
  label: "DB Name"
}, {
  id: 'systemName',
  left: true,
  sortable: false,
  label: "Syst Name"
}, {
  id: 'device',
  left: false,
  sortable: false,
  label: "Device"
}, {
  id: 'appFunctions',
  left: true,
  sortable: false,
  label: "App Function"
}, {
  id: 'debug',
  left: true,
  sortable: false,
  label: "Debug Mode"
}, {
  id: 'moreAction',
  left: false,
  sortable: false,
  label: ""
}];

var CustomTableHead = function CustomTableHead(props) {
  var classes = useStyles(props);
  var order = props.order,
      orderBy = props.orderBy,
      onRequestSort = props.onRequestSort;

  var createSortHandler = function createSortHandler(property) {
    return function (event) {
      onRequestSort(event, property);
    };
  };

  return React.createElement(TableHead, null, React.createElement(TableRow, {
    style: {
      borderBottom: "2px solid ".concat(Color.neutral[300])
    }
  }, headCells.map(function (headCell) {
    return React.createElement(TableCell, {
      key: headCell.id,
      align: headCell.left ? 'left' : 'right',
      sortDirection: orderBy === headCell.id ? order : false,
      style: {
        fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
        color: Color.neutral[600]
      }
    }, headCell.sortable ? React.createElement(TableSortLabel, {
      active: orderBy === headCell.id,
      direction: orderBy === headCell.id ? order : 'asc',
      onClick: headCell.sortable && createSortHandler(headCell.id),
      IconComponent: ArrowUp
    }, headCell.label, orderBy === headCell.id ? React.createElement("span", {
      className: classes.visuallyHidden
    }, order === 'desc' ? 'sorted descending' : 'sorted ascending') : null) : React.createElement(React.Fragment, null, headCell.label));
  })));
};

var SystemListHeader = function SystemListHeader(props) {
  var classes = useStyles(props);

  var _useState = useState('asc'),
      _useState2 = _slicedToArray(_useState, 2),
      order = _useState2[0],
      setOrder = _useState2[1];

  var _useState3 = useState('cost'),
      _useState4 = _slicedToArray(_useState3, 2),
      orderBy = _useState4[0],
      setOrderBy = _useState4[1];

  var id = props.id,
      data = props.data,
      children = props.children;

  var handleRequestSort = function handleRequestSort(event, property) {
    var isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return React.createElement("div", {
    className: classes.root
  }, React.createElement(TableContainer, null, React.createElement(Table, {
    className: classes.table
  }, React.createElement(CustomTableHead, {
    classes: classes,
    order: order,
    orderBy: orderBy,
    onRequestSort: handleRequestSort,
    rowCount: data.length
  }), React.createElement(TableBody, null, children))));
};

var SystemView = function SystemView(props) {
  var classes = useStyles(props);
  var theme = useTheme();
  var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  var id = props.id,
      systemView = props.systemView,
      data = props.data,
      _onClick = props.onClick,
      setActiveSystem = props.setActiveSystem,
      setDeleteSystemDialogOpen = props.setDeleteSystemDialogOpen,
      handleEditSystemConfiguration = props.handleEditSystemConfiguration,
      setCopyFunctionConfigurationDialogOpen = props.setCopyFunctionConfigurationDialogOpen,
      setEditSystemDeviceLimitDialogOpen = props.setEditSystemDeviceLimitDialogOpen;

  var _useState5 = useState(null),
      _useState6 = _slicedToArray(_useState5, 2),
      anchorEl = _useState6[0],
      setAnchorEl = _useState6[1];

  var _useState7 = useState(null),
      _useState8 = _slicedToArray(_useState7, 2),
      isSystemNameHovered = _useState8[0],
      setIsSystemNameHovered = _useState8[1];

  var _useState9 = useState(false),
      _useState10 = _slicedToArray(_useState9, 2),
      isDebugMode = _useState10[0],
      setIsDebugMode = _useState10[1];

  var deviceLimit = data.deploymentEnv === 0 || data.deviceLimit == null ? data.defaultDeviceLimit : data.deviceLimit;
  var activeDevice = data.activeDevice > deviceLimit ? deviceLimit : data.activeDevice;

  var handleChangeIsDebugMode = function handleChangeIsDebugMode() {
    setIsDebugMode(function (prev) {
      return !prev;
    });
  };

  var handleClickTableCell = function handleClickTableCell(event) {
    event.stopPropagation();

    _onClick();
  };

  var stopClick = function stopClick(e) {
    e.stopPropagation();
  };

  var handleSystemNameOnMouseOver = function handleSystemNameOnMouseOver() {
    setIsSystemNameHovered(true);
  };

  var handleSystemNameOnMouseOut = function handleSystemNameOnMouseOut() {
    setIsSystemNameHovered(false);
  };

  var handleClickShowMoreIcon = function handleClickShowMoreIcon(event) {
    event.stopPropagation();

    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }

    ;
  };

  var menuItems = [{
    text: "Go to System dashboard",
    onClick: function onClick() {
      _onClick();
    }
  }, {
    text: "Change System Configuration",
    onClick: function onClick() {
      handleEditSystemConfiguration(data);
    }
  }, {
    text: "Copy Function Configuration",
    onClick: function onClick() {
      setIsDebugMode(true);
      setActiveSystem(data);
      setCopyFunctionConfigurationDialogOpen(true);
    }
  }, {
    text: "Activate Debug Mode",
    onClick: function onClick() {
      setIsDebugMode(true);
    },
    divider: true
  }, {
    text: "Delete",
    icon: React.createElement(Delete, {
      style: {
        color: Color.danger[500]
      }
    }),
    destructive: true,
    onClick: function onClick() {
      setActiveSystem(data);
      setDeleteSystemDialogOpen(true);
    }
  }];

  if (data.deploymentEnv === 1) {
    menuItems.splice(3, 0, {
      text: "Change Device Limit",
      onClick: function onClick() {
        setActiveSystem(data);
        setEditSystemDeviceLimitDialogOpen(true);
      }
    });
  }

  if (data.isDemo) {
    menuItems.splice(1, 1);
  }

  return React.createElement(React.Fragment, null, systemView === "GRID" ? React.createElement("div", {
    id: "".concat(id, "-container"),
    style: {
      position: "relative"
    }
  }, React.createElement("div", {
    id: id,
    className: classes.systemBoxContainer,
    style: {
      background: data.deploymentEnv === 0 ? Color.neutral[200] : 'transparent',
      border: data.deploymentEnv === 0 && "4px solid ".concat(Color.neutral[200])
    }
  }, React.createElement("div", {
    className: classes.systemBoxHeader
  }, React.createElement(Chip, {
    id: "".concat(id, "-system-deploymentEnv"),
    backgroundColor: data.deploymentEnv === 0 ? Color.danger[400] : Color.gradient[100],
    style: {
      marginRight: 16
    }
  }, data.deploymentEnv === 0 ? "TEST" : "PRODUCTION"), data.deploymentEnv === 0 && React.createElement(Typography, {
    id: "".concat(id, "-system-pin"),
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 12,
    color: Color.primary[400]
  }, data.pin ? "PIN: ".concat(data.pin) : "")), React.createElement(Typography, {
    id: "".concat(id, "-system-name"),
    fontFamily: FONT_FAMILY.CAPITOLIUM_BOLD,
    size: isMobile ? 24 : 30,
    color: isSystemNameHovered ? Color.primary[500] : Color.neutral[700],
    style: {
      marginTop: 26,
      textDecoration: isSystemNameHovered ? "underline" : "none",
      // width: "max-content",
      cursor: "pointer",
      transition: "0.3s all ease-in-out"
    },
    onMouseOver: handleSystemNameOnMouseOver,
    onMouseOut: handleSystemNameOnMouseOut,
    onClick: _onClick
  }, data.name), React.createElement(Typography, {
    id: "".concat(id, "-b1-system-id"),
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 12,
    color: Color.neutral[600]
  }, data.otgOneId), React.createElement("div", {
    className: classes.systemBoxDatabase
  }, React.createElement("div", {
    className: classes.systemBox
  }, React.createElement("div", {
    style: {
      marginTop: 2
    }
  }, React.createElement(Tooltip, {
    title: "Database Name",
    placement: "right"
  }, React.createElement("div", null, React.createElement(Database, null)))), React.createElement(Typography, {
    id: "".concat(id, "-system-name"),
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 14,
    color: Color.neutral[500],
    style: {
      marginLeft: 8
    }
  }, data.dbName ? data.dbName : "-")), React.createElement("div", {
    className: classes.systemBox
  }, React.createElement("div", {
    style: {
      marginTop: 2
    }
  }, React.createElement(Tooltip, {
    title: "System ID",
    placement: "right"
  }, React.createElement("div", null, React.createElement(CPU, null)))), React.createElement(Typography, {
    id: "".concat(id, "-system-id"),
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 14,
    color: Color.neutral[500],
    style: {
      marginLeft: 8
    }
  }, data.b1SystemId ? data.b1SystemId : "-"))), React.createElement(DeviceCount, {
    data: data,
    activeDevice: activeDevice,
    deviceLimit: deviceLimit
  }), React.createElement(ApplicationFunction, {
    data: data
  }), React.createElement("div", {
    style: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end'
    }
  }, React.createElement(Button, {
    type: "secondary",
    alignIcon: "right",
    style: {
      marginTop: 20,
      outline: "none"
    },
    onClick: _onClick
  }, "Go to System Dashboard")), React.createElement(ShowMoreHorizontal, {
    id: "".concat(id, "-client-showmore-horiz-icon"),
    style: {
      position: "absolute",
      top: 16,
      right: 16,
      width: 24,
      height: 24,
      cursor: "pointer",
      color: Color.primary[400]
    },
    onClick: handleClickShowMoreIcon
  }))) : React.createElement(TableRow, {
    hover: true,
    onClick: handleClickTableCell,
    tabIndex: -1,
    key: data.name,
    style: {
      height: 85,
      borderBottom: "1px solid ".concat(Color.neutral[400]),
      zIndex: 1000,
      cursor: "pointer"
    }
  }, React.createElement(TableCell, {
    align: 'left',
    style: {
      fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
      color: Color.neutral[600]
    }
  }, data.name), React.createElement(TableCell, {
    align: 'left',
    style: {
      fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
      color: Color.neutral[600]
    }
  }, React.createElement("div", {
    style: {
      display: 'flex',
      alignItems: 'center'
    }
  }, React.createElement(Chip, {
    backgroundColor: data.deploymentEnv === 0 ? Color.danger[400] : Color.gradient[100],
    style: {
      marginRight: 10
    }
  }, data.deploymentEnv === 0 ? "TEST" : "PRODUCTION"), data.deploymentEnv === 0 && data.pin)), React.createElement(TableCell, {
    align: 'left',
    style: {
      fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
      color: Color.neutral[600]
    }
  }, data.dbName), React.createElement(TableCell, {
    align: 'left',
    style: {
      fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
      color: Color.neutral[600]
    }
  }, data.systemName), React.createElement(TableCell, {
    align: 'right',
    style: {
      fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
      color: Color.neutral[600]
    }
  }, "".concat(activeDevice, " / ").concat(deviceLimit)), React.createElement(TableCell, {
    align: 'left',
    style: {
      fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
      color: Color.neutral[600]
    }
  }, React.createElement("div", {
    className: classes.systemApplicationFunctionIconListContainer
  }, data.functions.map(function (func, index) {
    var _classnames2;

    var isActive = func.isActive === 1;
    var functionClasses = classnames((_classnames2 = {}, _defineProperty(_classnames2, classes.activeFunction, isActive), _defineProperty(_classnames2, classes.inactiveFunction, !isActive), _classnames2));
    return React.createElement("div", {
      className: classes.systemApplicationFunction,
      style: {
        color: Color.primary[500],
        marginRight: index < data.functions.length - 1 && 10
      }
    }, React.createElement(Tooltip, {
      title: func.name,
      placement: "right"
    }, React.createElement("div", {
      className: functionClasses
    }, getFunctionIcon(func.functionTypeId))), React.createElement(Tooltip, {
      title: "Customized",
      placement: "right"
    }, React.createElement("div", {
      className: classes.systemApplicationFunctionIconStatus,
      style: {
        display: func.isChanged ? "block" : "none"
      }
    })));
  }))), React.createElement(TableCell, {
    align: 'right',
    onClick: stopClick
  }, React.createElement("div", {
    style: {
      display: "flex",
      alignItems: "center",
      marginTop: -4,
      zIndex: 100000
    }
  }, React.createElement(Switch, null), React.createElement(Bug, {
    style: {
      width: 24,
      height: 24,
      color: Color.neutral[400],
      marginLeft: 6
    }
  }))), React.createElement(TableCell, {
    align: 'right',
    style: {
      position: "relative"
    }
  }, React.createElement("div", {
    className: classes.moreActionIconContainer
  }, React.createElement(ShowMoreVertical, {
    style: {
      color: Color.primary[500]
    },
    onClick: handleClickShowMoreIcon
  })))), React.createElement(OverflowMenu, {
    anchorElement: anchorEl,
    setAnchorEl: setAnchorEl,
    menuItems: menuItems
  }));
};

export var System = function System(props) {
  var systemView = props.systemView,
      data = props.data,
      onClick = props.onClick,
      setActiveSystem = props.setActiveSystem,
      setDeleteSystemDialogOpen = props.setDeleteSystemDialogOpen,
      handleEditSystemConfiguration = props.handleEditSystemConfiguration,
      setCopyFunctionConfigurationDialogOpen = props.setCopyFunctionConfigurationDialogOpen,
      setEditSystemDeviceLimitDialogOpen = props.setEditSystemDeviceLimitDialogOpen;
  return React.createElement(Grid, {
    container: true,
    style: {
      flexGrow: 1
    },
    spacing: 4
  }, data.length > 0 ? React.createElement(React.Fragment, null, systemView === "GRID" ? React.createElement(React.Fragment, null, data.map(function (system, index) {
    return React.createElement(Grid, {
      item: true,
      md: 6,
      sm: 12,
      xs: 12,
      key: "system-".concat(index)
    }, React.createElement(SystemView, {
      systemView: systemView,
      data: system,
      onClick: onClick(system),
      setActiveSystem: setActiveSystem,
      setDeleteSystemDialogOpen: setDeleteSystemDialogOpen,
      handleEditSystemConfiguration: handleEditSystemConfiguration,
      setCopyFunctionConfigurationDialogOpen: setCopyFunctionConfigurationDialogOpen,
      setEditSystemDeviceLimitDialogOpen: setEditSystemDeviceLimitDialogOpen
    }));
  })) : React.createElement(SystemListHeader, {
    data: data
  }, data.map(function (system, index) {
    return React.createElement(SystemView, {
      systemView: systemView,
      data: system,
      onClick: onClick(system),
      setActiveSystem: setActiveSystem,
      setDeleteSystemDialogOpen: setDeleteSystemDialogOpen,
      handleEditSystemConfiguration: handleEditSystemConfiguration,
      setCopyFunctionConfigurationDialogOpen: setCopyFunctionConfigurationDialogOpen,
      setEditSystemDeviceLimitDialogOpen: setEditSystemDeviceLimitDialogOpen
    });
  }))) : React.createElement("div", {
    style: {
      width: "100%",
      textAlign: "center"
    }
  }, React.createElement("div", null, React.createElement(EmptySystem, null)), React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
    size: 24,
    color: Color.neutral[400]
  }, "No system created for this client.")));
};
System.defaultProps = {
  data: [{
    deploymentEnv: 0,
    pin: "1234",
    name: "System Name",
    dbName: "North",
    b1SystemId: "North East",
    otgOneId: "AB1238",
    activeDevice: 10,
    deviceLimit: 50,
    defaultDeviceLimit: 25,
    isDemo: false,
    functions: [{
      name: "Goods Receipt",
      functionTypeId: 1,
      isActive: 1,
      isChanged: true
    }, {
      name: "Pick & Pack",
      functionTypeId: 2,
      isActive: 0,
      isChanged: false
    }, {
      name: "Inventory Status",
      functionTypeId: 3,
      isActive: 0,
      isChanged: false
    }, {
      name: "Inventory Transfer",
      functionTypeId: 4,
      isActive: 0,
      isChanged: true
    }, {
      name: "Inventory Counting",
      functionTypeId: 5,
      isActive: 0,
      isChanged: false
    }, {
      name: "Delivery",
      functionTypeId: 6,
      isActive: 0,
      isChanged: false
    }]
  }],
  onClick: function onClick() {},
  setActiveSystem: null,
  setDeleteSystemDialogOpen: function setDeleteSystemDialogOpen() {},
  handleEditSystemConfiguration: function handleEditSystemConfiguration() {}
};
export default React.memo(System);