// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Color } from '@otg-one/ui_components';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        accountSettingsContainer: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
            }
        }
    })
)