// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    whiteContentTopRadius: {
      backgroundColor: "#ffffff",
      marginTop: "-10px !important",
      textAlign: "center",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      paddingBottom: "20px",
      overflow: "hidden"
    },
    barcodeContainerWrapper: {
      width: "100%",
      position: "relative"
    },
    barcodeContainer: {
      position: "absolute",
      left: "50%",
      top: "50%",
      width: "100%",
      fontSize: "14px"
    },
    scannerIcon: {
      fontSize: "90px !important",
      color: "black !important",
      width: "90px !important",
      height: "90px !important"
    },
    whiteContentBarContainer: {
      paddingLeft: "20px",
      paddingRight: "20px"
    }
  });
});