// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    whiteContentTopRadius: {
      backgroundColor: "#ffffff",
      marginTop: "-10px !important",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      overflow: "hidden"
    },
    listOrderItemsDiv: {
      overflow: "auto"
    },
    contentBarBackLayer: {
      backgroundColor: "#f1f1f1",
      paddingBottom: 3
    },
    contentBar: {
      backgroundColor: "#FFFFFF",
      height: "45px",
      display: "flex",
      alignItems: "center",
      borderBottom: "2px solid #c4c4c7",
      cursor: "pointer",
      "&:hover": {
        borderLeft: "2px solid #fbc700",
        backgroundColor: "#f1f1f1"
      }
    },
    headerContentBar: {
      padding: "8px 15px",
      backgroundColor: "#f4f5f5",
      fontSize: "12px",
      color: "#9D9D9D",
      display: "flex",
      justifyContent: "space-between",
      "& span": {
        width: 25
      }
    },
    contentStyle: {
      fontSize: "12px",
      color: "black"
    },
    blackLeftContent: {
      paddingLeft: "15px",
      textAlign: "left"
    },
    blackMiddleContent: {
      textAlign: "center"
    },
    blackRightContent: {
      paddingRight: "15px",
      textAlign: "right"
    }
  });
});