import _toConsumableArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./ListOfPickPackPositionsPageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { WhiteContentBar } from "../../index";
import { BlueLink } from "../../index";
export var ListOfPickPackPositionsPage = function ListOfPickPackPositionsPage(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      warehouseLayout = props.warehouseLayout,
      data = props.data,
      completeOrderType = props.completeOrderType,
      quickCheck = props.quickCheck,
      newPositionAddable = props.newPositionAddable;

  var _React$useState = React.useState(data),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      listData = _React$useState2[0],
      setListData = _React$useState2[1];

  React.useEffect(function () {
    setListData(data);
  }, [data]);
  var isConfirmed = false;

  if (completeOrderType === "atLeastOnePosition") {
    var tempStatusConfirmed = false;
    listData.map(function (data) {
      if (data.released === data.picked) {
        tempStatusConfirmed = true;
      }
    });

    if (tempStatusConfirmed) {
      isConfirmed = true;
    } else {
      isConfirmed = false;
    }
  } else {
    var _tempStatusConfirmed = true;
    listData.map(function (data) {
      if (data.released !== data.picked) {
        _tempStatusConfirmed = false;
      }
    });

    if (_tempStatusConfirmed) {
      isConfirmed = true;
    } else {
      isConfirmed = false;
    }
  }

  var editListData = function editListData(data) {
    var tempListData = _toConsumableArray(listData);

    tempListData.map(function (tempData) {
      if (tempData.id === data.id) {
        if (tempData.released === tempData.picked) {
          tempData.picked = 0;
        } else {
          tempData.picked = tempData.released;
        }
      }
    });
    setListData(tempListData);
  };

  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "small",
    showScanner: true,
    previewHeight: previewHeight
  }, React.createElement("p", null, "Pick and Pack"))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentTopRadius,
    style: {
      height: previewHeight - 75 - 50 + 10
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "Positions",
      rightContent: "4"
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.listOrderItemsDiv,
    style: {
      height: previewHeight - 75 - 50 - 40 + 10
    }
  }, React.createElement(Grid, {
    container: true,
    className: classes.listOrderItemsContainer
  }, listData.map(function (pickPackItem) {
    return React.createElement(Button, {
      className: classes.listOrderItemsButton
    }, React.createElement(Grid, {
      item: true,
      md: 12
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      item: true,
      md: 5
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12
    }, "Item"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, pickPackItem.item), React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12,
      style: {
        marginTop: "5px"
      }
    }, "Released / Picked"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, pickPackItem.released + " / " + pickPackItem.picked + " " + pickPackItem.releasedPickedType))), quickCheck && !pickPackItem.mandatoryBatchOrSerialNumber ? React.createElement(React.Fragment, null, React.createElement(Grid, {
      item: true,
      md: 6
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12
    }, "Description"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, pickPackItem.description), React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12,
      style: {
        marginTop: "5px"
      }
    }, "Warehouse"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, warehouseLayout === "warehouseName" ? pickPackItem.warehouseName : pickPackItem.warehouseCode))), React.createElement(Grid, {
      container: true,
      item: true,
      md: 1,
      alignItems: "center",
      justify: "flex-end"
    }, React.createElement("input", {
      type: "checkbox",
      name: name,
      id: pickPackItem.id,
      checked: pickPackItem.released === pickPackItem.picked ? true : false,
      onChange: function onChange(e) {
        editListData(pickPackItem);
      }
    }))) : React.createElement(Grid, {
      item: true,
      md: 7
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12
    }, "Description"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, pickPackItem.description), React.createElement(Grid, {
      className: classes.listOrderItemsButtonTitle,
      item: true,
      md: 12,
      style: {
        marginTop: "5px"
      }
    }, "Warehouse"), React.createElement(Grid, {
      className: classes.listOrderItemsButtonLeftContent,
      item: true,
      md: 12
    }, warehouseLayout === "warehouseName" ? pickPackItem.warehouseName : pickPackItem.warehouseCode))))));
  }), newPositionAddable && React.createElement(Grid, {
    container: true,
    className: classes.whiteContentBarContainer
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueLink, null, "Add New Row"))))))), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "left"
  }, "Back")), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "right",
    disabled: !isConfirmed
  }, "Add"))));
};
export default React.memo(ListOfPickPackPositionsPage);