

import axios from 'axios';

import { getApiUrl } from '../../../Constants/Api';


export class SystemService {

    getSystemsCostAndLogin(systemKey: string, year: number = -1) {
        return axios.get(getApiUrl() + "systems/" + systemKey + "/systemcostandlogin?year=" + year);
    }

    createSystem(userKey: string, clientKey: string, system: object) {
        return axios.post(getApiUrl() + "systems?userkey=" + userKey + "&clientkey=" + clientKey, system);
    }

    editSystem(userKey: string, system: any) {
        return axios.put(getApiUrl() + "systems/" + system.key + "?userkey=" + userKey, system);
    }

    getFunctions(userKey: string, systemKey?: string) {
        if (systemKey) {
            return axios.get(getApiUrl() + "functions?userkey=" + userKey + "&systemkey=" + systemKey);
        } else {
            return axios.get(getApiUrl() + "functions?userkey=" + userKey);
        }
    }

    activateFunction(userKey: string, functionKey: string) {
        return axios.put(getApiUrl() + "functions/" + functionKey + "/activate?userkey=" + userKey);
    }

    deactivateFunction(userKey: string, functionKey: string) {
        return axios.put(getApiUrl() + "functions/" + functionKey + "/deactivate?userkey=" + userKey);
    }

    updateSystemDeviceLimit(userKey: string, system: any) {
        return axios.put(getApiUrl() + "systems/" + system.key + "/devicelimit?userkey=" + userKey, system);
    }

    getUserRoles(userKey: string, systemKey: string) {
        return axios.get(getApiUrl() + "userroles?userkey=" + userKey + "&systemkey=" + systemKey);
    }

    createUserRole(userKey: string, systemKey: string, userRole: object) {
        return axios.post(getApiUrl() + "userroles?userkey=" + userKey + "&systemkey=" + systemKey, userRole);
    }

    editUserRole(userKey: string, userRole: any) {
        return axios.put(getApiUrl() + "userroles/" + userRole.key + "?userkey=" + userKey, userRole);
    }

    deleteUserRole(userKey: string, userRoleKey: string) {
        return axios.delete(getApiUrl() + "userroles/" + userRoleKey + "?userkey=" + userKey);
    }
}

export default new SystemService();