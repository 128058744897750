export var ColorName = ["primary", "neutral", "danger", "success", "alert", "gradient"];
export var ColorCode = [900, 800, 700, 600, 500, 400, 300, 200, 100, 0];
export var Color = {
  primary: {
    900: "#00527F",
    800: "#006197",
    700: "#006BA6",
    600: "#0077B6",
    500: "#008CBA",
    400: "#00A7DD",
    300: "#75C3F3",
    200: "#8FCFF6",
    100: "#C6E5F7"
  },
  neutral: {
    900: "#383D47",
    800: "#4C515B",
    700: "#585D67",
    600: "#62666E",
    500: "#96999E",
    400: "#ABADB1",
    300: "#D0D0D0",
    200: "#E6E3E3",
    100: "#F5F5F5",
    0: "#FFFFFF"
  },
  danger: {
    800: "#b30d05",
    400: "#EE4137",
    300: "#F1675E",
    100: "#ffbfbd"
  },
  success: {
    800: "#008051",
    500: "#009C63",
    400: "#31AF81",
    300: "#00d185",
    200: "#05ffa4"
  },
  alert: {
    800: "#877503",
    700: "#D1B704",
    600: "#E5CB14",
    500: "#FFD235",
    300: "#fcdd71"
  },
  // Still uses hardcoded primary[600] and primary[900]
  gradient: {
    100: "linear-gradient(-90deg, #0077B6 0%, #00527F 100%)",
    0: "linear-gradient(-90deg, #FFFFFF 0%, #FFFFFF 100%)"
  }
};