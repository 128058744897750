import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  var _snackbarContainer;

  return createStyles({
    '@-webkit-keyframes progressKeyframe': {
      '0%': {
        width: 0
      },
      '100%': {
        width: '100%'
      }
    },
    '@keyframes progressKeyframe': {
      '0%': {
        width: 0
      },
      '100%': {
        width: '100%'
      }
    },
    snackbarWrapper: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: 56,
      zIndex: 13000,
      backgroundColor: Color.primary[400]
    },
    snackbarContainer: (_snackbarContainer = {}, _defineProperty(_snackbarContainer, theme.breakpoints.up('md'), {
      paddingTop: 4,
      paddingLeft: "10%",
      paddingRight: "10%"
    }), _defineProperty(_snackbarContainer, theme.breakpoints.up('lg'), {
      paddingTop: 4,
      paddingLeft: "15%",
      paddingRight: "15%"
    }), _snackbarContainer),
    snackbarIconContainer: {
      "& svg": {
        width: 40,
        height: 40,
        color: Color.neutral[0],
        marginRight: 50
      }
    },
    meter: {
      height: 8,
      background: Color.gradient[100],
      overflow: 'hidden',
      animation: "$progressKeyframe 5000ms linear",
      webkitAnimation: "$progressKeyframe 5000ms linear"
    }
  });
});