export class InventoryTransfer {
	public static SELECT_ITEM_INPUT_MANUAL = "manual";
	public static SELECT_ITEM_INPUT_SCAN = "scan";
	public static SELECT_ITEM_INPUT_OCR = "ocr"; // UNAVAILABLE
	public static SELECT_ITEM_INPUT_SHOPPING_ARTICLE = "shopping_article";

	public static WAREHOUSE_LAYOUT_WAREHOUSE_NAME = "warehouseName";
	public static WAREHOUSE_LAYOUT_WAREHOUSE_CODE = "warehouseCode";

	public static QUANTITY_AVAILABLE_OFF = "off";
	public static QUANTITY_AVAILABLE_ON_QUESTION = "onQuestion";
	public static QUANTITY_AVAILABLE_ON = "on";

	public static BATCH_AVAILABLE_OFF = "off";
	public static BATCH_AVAILABLE_ON_QUESTION = "onQuestion";
	public static BATCH_AVAILABLE_ON = "on";

	public static SERIAL_NUMBER_AVAILABLE_OFF = "off";
	public static SERIAL_NUMBER_AVAILABLE_ON_QUESTION = "onQuestion";
	public static SERIAL_NUMBER_AVAILABLE_ON = "on";

	public static SELECT_WAREHOUSE_INPUT_MANUAL = "manual";
	public static SELECT_WAREHOUSE_INPUT_SCAN = "scan";
	public static SELECT_WAREHOUSE_INPUT_OCR = "ocr"; // UNAVAILABLE

	public selectItemInput: string[]; // DO NOT SHOW! It exists in mapper, but commented out in UI
	public warehouseLayout: string;
	public additionalBinLocs: boolean;
	public quantityAvailable: string; // DO NOT SHOW! It exists in mapper, but commented out in UI
	public batchAvailable: string; // DO NOT SHOW! It exists in mapper, but commented out in UI
	public serialNumberAvailable: string; // DO NOT SHOW! It exists in mapper, but commented out in UI
	public allowOneBatchForOneBinLoc: boolean;
	public selectWarehouseInput: string[];

	public constructor() {
		this.selectItemInput = [];
		this.warehouseLayout = InventoryTransfer.WAREHOUSE_LAYOUT_WAREHOUSE_NAME;
		this.additionalBinLocs = false;
		this.quantityAvailable = InventoryTransfer.QUANTITY_AVAILABLE_OFF;
		this.batchAvailable = InventoryTransfer.BATCH_AVAILABLE_OFF;
		this.serialNumberAvailable = InventoryTransfer.SERIAL_NUMBER_AVAILABLE_OFF;
		this.allowOneBatchForOneBinLoc = false;
		this.selectWarehouseInput = [InventoryTransfer.SELECT_WAREHOUSE_INPUT_MANUAL, InventoryTransfer.SELECT_WAREHOUSE_INPUT_SCAN];
	}
}
