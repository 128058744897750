import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
import { SVG_GRADIENT_BLUE } from '../Button/ButtonStyles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    // For System View
    systemBoxContainer: {
      position: "relative",
      boxSizing: "border-box",
      padding: "20px 24px 20px 24px",
      width: "100%",
      background: "transparent",
      backgroundSize: "0% 100%",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "border-box",
      border: "4px solid ".concat(Color.primary[600]),
      borderImage: "url('data:image/svg+xml;base64,".concat(SVG_GRADIENT_BLUE, "') 10% stretch"),
      transition: "all .2s ease-in-out"
    },
    systemBoxHeader: {
      display: "inline-flex",
      alignItems: "center"
    },
    systemBoxShowMore: {
      "& svg": {
        position: "absolute",
        top: 16,
        right: 16,
        width: 20,
        height: 20,
        color: Color.primary[400]
      }
    },
    systemBoxDatabase: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 17,
      "& svg": {
        color: Color.primary[400]
      }
    },
    systemBox: {
      display: "flex",
      alignItems: "center"
    },
    systemDeviceCountContainer: {
      display: "block",
      width: "100%"
    },
    systemDeviceCount: {
      display: "inline-flex",
      alignItems: "center",
      width: "100%"
    },
    systemApplicationFunctionIconListContainer: _defineProperty({
      display: "inline-flex",
      alignItems: "center",
      width: "100%",
      marginTop: 5
    }, theme.breakpoints.down('sm'), {
      flexWrap: 'wrap'
    }),
    systemApplicationFunctionIconStatus: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      background: Color.alert[700],
      position: "absolute",
      top: 0,
      left: 0
    },
    systemApplicationFunction: _defineProperty({
      position: "relative",
      '& svg': {
        fontSize: 40
      }
    }, theme.breakpoints.down('sm'), {
      '& svg': {
        fontSize: 22
      }
    }),
    activeFunction: {
      '& svg': {
        color: Color.primary[400]
      }
    },
    inactiveFunction: {
      '& svg': {
        color: Color.neutral[500]
      }
    },
    moreActionIconContainer: {
      position: "absolute",
      top: 30,
      right: 40,
      cursor: "pointer"
    },
    // End System View
    // For List Header
    root: {
      width: '100%'
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 750
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    }
  });
});