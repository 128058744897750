// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    backdrop: {
      position: "fixed",
      backgroundColor: "".concat(Color.primary[900], " !important"),
      opacity: '0.8 !important'
    },
    dialogRoot: {},
    dialogPaper: {
      opacity: 1,
      borderRadius: 0,
      boxShadow: "0px 2px 34px 0px rgba(0, 0, 0, 0.23)",
      padding: 24
    },
    dialogTitleRoot: {
      margin: 0,
      padding: 0,
      paddingBottom: 6
    },
    header: {
      fontFamily: "capitolium-bold",
      fontSize: 24,
      color: Color.neutral[900]
    },
    overline: {
      fontFamily: "roboto",
      fontSize: 12,
      color: Color.neutral[500]
    },
    dialogTitleCloseButton: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: Color.primary[900],
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    dialogContentRoot: {
      padding: 0
    }
  });
});