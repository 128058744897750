import React, { useContext } from 'react';

// Components
import {
    DialogBox,
    Banner,
    Button
} from '@otg-one/ui_components';

import Grid from '@material-ui/core/Grid';
import CustomerUsageService from '../CustomerUsageService';
import { AppContext } from '../../../../App';

const IgnoreDeviceDialog = (props: any) => {
    const { open, onClose, deviceId, temp, setTemp } = props;
    const { setSnackbarMessage, handleShowSnackbar, currentUserData } = useContext(AppContext);

    const handleSubmit = () => {
        CustomerUsageService.ignoreDevice(currentUserData.key, deviceId).then((res: any) => {
            if (res.status === 200) {
                // let t = temp

                onClose()
                // setTemp(t++)
                setSnackbarMessage(`Device ID ${deviceId} ignored successfully`)
                handleShowSnackbar();
            }
        })
    }

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title={`Confirm Ignore Device ID`}
            content={
                <div style={{ width: 550, paddingTop: 16, overflow: 'hidden' }}>
                    <Grid container>
                        <Grid item md={12}>
                            <Banner width="100%">
                                {`Caution! Ignoring Device with ID: ${deviceId}`}
                                <br />
                                {`All future device count related to this device id will be ignored!`}
                            </Banner>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: 45 }}>
                                <Button type="secondary" onClick={onClose}>{`Cancel`}</Button>
                                <Button style={{ marginLeft: 16 }} onClick={handleSubmit}>{`Ignore Device`}</Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            }
        />
    )
};

export default IgnoreDeviceDialog;