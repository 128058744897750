import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    totalCostContainer: _defineProperty({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      boxSizing: "border-box",
      padding: 24,
      width: "100%",
      height: 208,
      backgroundColor: Color.primary[400],
      color: Color.neutral[0]
    }, theme.breakpoints.down('xs'), {
      padding: 15
    }),
    totalCost: _defineProperty({
      textAlign: "center",
      marginTop: -10
    }, theme.breakpoints.down('xs'), {
      marginTop: 0
    })
  });
});