import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// React libraries
import React from 'react';
import classnames from 'classnames'; // Component Style

import { useStyles } from "./DialogBoxStyles"; // Material UI Core

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent'; // Icons

import { Close } from "../../icons";
export var DialogBox = function DialogBox(props) {
  var classes = useStyles(props);
  var open = props.open,
      handleClose = props.handleClose,
      title = props.title,
      titleType = props.titleType,
      content = props.content,
      style = props.style;
  var dialogBoxClasses = {
    root: classes.dialogRoot,
    paper: classes.dialogPaper
  };
  var dialogBoxTitleClasses = {
    root: classes.dialogTitleRoot
  };
  var dialogBoxContentClasses = {
    root: classes.dialogContentRoot
  };
  var backdropProps = {
    className: classes.backdrop
  };
  var dialogTitleTextClasses = classnames(_defineProperty({}, classes[titleType], titleType));
  return React.createElement(Dialog, {
    open: open,
    onClose: handleClose,
    classes: dialogBoxClasses,
    BackdropProps: backdropProps,
    maxWidth: false,
    style: style
  }, React.createElement(DialogTitle, {
    classes: dialogBoxTitleClasses,
    disableTypography: true
  }, React.createElement(Typography, {
    className: dialogTitleTextClasses,
    style: {
      maxWidth: "95%"
    }
  }, title), React.createElement(IconButton, {
    className: classes.dialogTitleCloseButton,
    onClick: handleClose,
    disableRipple: true
  }, React.createElement(Close, null))), React.createElement(DialogContent, {
    classes: dialogBoxContentClasses
  }, content));
};

var DefaultContent = function DefaultContent(props) {
  return React.createElement(React.Fragment, null, React.createElement(Typography, {
    gutterBottom: true
  }, "Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros."), React.createElement(Typography, {
    gutterBottom: true
  }, "Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor."), React.createElement(Typography, {
    gutterBottom: true
  }, "Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla."));
};

DialogBox.defaultProps = {
  title: "Dialog Box Title",
  titleType: "header",
  content: React.createElement(DefaultContent, null)
};
export default React.memo(DialogBox);