import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./WarehouseModusStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { ScanContentBar } from "../../index";
export var WarehouseModus = function WarehouseModus(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      data = props.data,
      totalWarehouse = props.totalWarehouse,
      warehouseLayout = props.warehouseLayout,
      scanContentBarText = props.scanContentBarText;

  var _React$useState = React.useState(data),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      warehouseList = _React$useState2[0],
      setWarehouseList = _React$useState2[1];

  var _React$useState3 = React.useState(0),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      totalQuantity = _React$useState4[0],
      setTotalQuantity = _React$useState4[1];

  React.useEffect(function () {
    setWarehouseList(data);
  }, [data]);
  React.useEffect(function () {
    setTotalQuantity(warehouseList.length);
  }, [warehouseList]);
  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "smallWithSub",
    showScanner: true,
    previewHeight: previewHeight
  }, React.createElement("p", null, "Inventory Counting"), React.createElement("sub", null, "Inventory List ", React.createElement("b", null, "40")))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentTopRadius,
    style: {
      height: previewHeight - 90 - 40
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(ScanContentBar, {
    text: scanContentBarText
  })), totalWarehouse > 0 && React.createElement(Grid, {
    container: true,
    className: classes.progressBar
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, totalQuantity + " from " + totalWarehouse + " completed")), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.greyContentPlain,
    style: {
      height: previewHeight - 90 - 40 - 40 - (totalWarehouse === 0 ? 0 : 28)
    }
  }, React.createElement(Grid, {
    container: true
  }, warehouseList.length > 0 && warehouseList.map(function (data) {
    return React.createElement(React.Fragment, null, React.createElement(Grid, {
      item: true,
      md: 12,
      className: classes.whiteContentBarWithCloseButton
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.contentTitle,
      item: true,
      md: 12
    }, "Warehouse"), React.createElement(Grid, {
      className: classes.blackLeftContent,
      item: true,
      md: 12
    }, warehouseLayout === 'warehouseName' ? data.warehouseName : data.warehouseCode))), React.createElement(Grid, {
      item: true,
      md: 12,
      className: classes.ContentBorder
    }));
  }))))), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "left"
  }, "Back")), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "right"
  }, "Add"))));
};
WarehouseModus.defaultProps = {
  scanContentBarText: "Please scan wh/bin...",
  totalWarehouse: 0
};
export default React.memo(WarehouseModus);