import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React Lib
import React, { useState } from "react"; // Component Styles

import { useStyles } from "./OpenTicketStyles"; // @otg-one/ui_components/components

import { Typography, Button } from "../index"; // @otg-one/ui_components/constants

import { Color, FONT_FAMILY } from "../../constants"; // Utils

import { formatNumericalWithThousandSeparator } from "../../utils/Utils";
export var OpenTicket = function OpenTicket(props) {
  var classes = useStyles(props);

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isHover = _useState2[0],
      setIsHover = _useState2[1];

  var id = props.id,
      ticket = props.ticket;

  var handleOnMouseOver = function handleOnMouseOver() {
    setIsHover(true);
  };

  var handleOnMouseOut = function handleOnMouseOut() {
    setIsHover(false);
  };

  return React.createElement("div", {
    id: "".concat(id, "-open-ticket-container"),
    className: classes.openTicketContainer,
    onMouseOver: handleOnMouseOver,
    onMouseOut: handleOnMouseOut
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
    size: 16,
    color: isHover ? Color.neutral[0] : Color.primary[600],
    style: {
      transition: "all .2s ease-in-out"
    }
  }, "Open Tickets"), React.createElement("div", {
    id: "".concat(id, "-open-ticket"),
    className: classes.openTicket
  }, React.createElement(Typography, {
    id: "".concat(id, "-open-ticket-count"),
    fontFamily: FONT_FAMILY.CAPITOLIUM_BOLD,
    size: 60,
    color: isHover ? Color.neutral[0] : Color.neutral[800],
    style: {
      transition: "all .2s ease-in-out"
    }
  }, "".concat(formatNumericalWithThousandSeparator(ticket))), React.createElement(Typography, {
    fontFamily: FONT_FAMILY.CAPITOLIUM,
    size: 24,
    color: isHover ? Color.neutral[0] : Color.primary[600],
    style: {
      marginLeft: 8,
      transition: "all .2s ease-in-out"
    }
  }, "Open Tickets Available")), isHover ? React.createElement("a", {
    target: "_blank",
    href: "https://support.commsult.de/",
    style: {
      textDecoration: "none"
    }
  }, React.createElement(Button, {
    id: "".concat(id, "-open-ticket-button"),
    type: "secondary",
    color: "white",
    alignIcon: "right",
    style: {
      marginTop: 20,
      outline: "none"
    }
  }, "Register to support portal")) : React.createElement("a", {
    target: "_blank",
    href: "https://support.commsult.de/",
    style: {
      textDecoration: "none"
    }
  }, React.createElement(Button, {
    id: "".concat(id, "-open-ticket-button"),
    type: "secondary",
    alignIcon: "right",
    style: {
      marginTop: 20,
      outline: "none"
    }
  }, "Register to support portal")));
};
OpenTicket.defaultProps = {
  ticket: 3
};
export default React.memo(OpenTicket);