import React, { useState } from 'react';

// Components
import {
    Typography,
    FONT_FAMILY,
    Color,
    DialogBox
} from '@otg-one/ui_components';

const ActivationFailedDialog = (props: any) => {
    const { open, onClose } = props

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title="User Activation"
            content={
                <div style={{ width: 480, paddingTop: 16 }}>
                    <Typography
                        fontFamily={FONT_FAMILY.CAPITOLIUM_ITALIC}
                        size={16}
                        color={Color.neutral[900]}
                        style={{ marginBottom: 13 }}
                    >
                        {`Your activation failed! Try to resend the activation link.`}
                    </Typography>
                </div>
            }
        />
    )
};

export default ActivationFailedDialog;