import _objectWithoutProperties from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["classes"];
import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import MUISwitch from '@material-ui/core/Switch';
import { Color } from '../../constants';
var IOSSwitch = withStyles(function (theme) {
  return createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0
    },
    switchBase: {
      padding: 1,
      top: 0,
      left: 2,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        top: 0,
        left: 0,
        '& + $track': {
          backgroundColor: Color.primary[500],
          opacity: 1,
          border: 'none'
        }
      },
      '&$focusVisible $thumb': {
        color: Color.primary[500]
      }
    },
    thumb: {
      marginTop: 1,
      width: 22,
      height: 22
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: Color.neutral[500],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border'])
    },
    checked: {},
    focusVisible: {}
  });
})(function (_ref) {
  var classes = _ref.classes,
      props = _objectWithoutProperties(_ref, _excluded);

  return React.createElement(MUISwitch, Object.assign({
    focusVisibleClassName: classes.focusVisible,
    disableRipple: true,
    classes: {
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked
    }
  }, props));
});
export var Switch = function Switch(props) {
  var checked = props.checked,
      handleChange = props.handleChange,
      value = props.value;
  return React.createElement(IOSSwitch, {
    checked: checked,
    onChange: handleChange,
    value: value
  });
};
export default Switch;