import React from 'react';

// Components
import {
    DialogBox,
    Button,
    Typography,
    FONT_FAMILY,
    Color,
    ArrowDown,
} from '@otg-one/ui_components';

const ScenarioInstallationGuideDialog = (props: any) => {
    const { open, onClose, newScenarioAvailable,handleUpdateScenarioVersionForUser } = props

    let badgeNew = <div></div>;
    if(newScenarioAvailable){
        badgeNew = <div style={{ outline: 'none', float: "right", marginTop: 10, marginRight: -20, backgroundColor: "#ffdf00", zIndex: 2, borderRadius: "50%", padding: 5, fontSize: 12, textDecoration: "none", color:"#000000" }}>{'New'}</div>;
    }

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title="Scenario Installation Guide"
            style={{ height: '90%', marginTop: 70 }}
            content={
                <div style={{ width: 710 }}>
                    <Typography
                        fontFamily={FONT_FAMILY.CAPITOLIUM_ITALIC}
                        size={16}
                        color={Color.neutral[900]}
                        style={{ marginTop: 10 }}
                    >
                        {`Download & Installation Logistic Scenario`}
                    </Typography>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                        size={14}
                        color={Color.neutral[900]}
                        style={{ width: 570, marginTop: 20, marginBottom: 20 }}
                    >
                        {`In order to use OntegoOne you have to implement the connection interface in the Business One target system. OntegoOne uses a standard scenario for the B1 Integration Framework. The following video shows the necessary steps:`}
                    </Typography>

                    <iframe
                        title="otg-one-installation-video"
                        width="100%"
                        height="400"
                        src="https://www.youtube.com/embed/NGs1jBCexBo"
                    />

                    <div style={{ overflow: "hidden", marginBottom: 20 }}>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                            size={14}
                            color={Color.neutral[900]}
                            style={{ width: 450, marginTop: 20, float: "left" }}
                        >
                            {`Here you can download the OntegoOne Logistics Scenario for the B1 Integration Framework (free of charge):`}
                        </Typography>
                        <a href="https://www.ontego.one/static/DefaultLogistics_Scenario-1_2_44_Export.zip" download="" style={{ textDecoration: "none" }}>
                            <Button
                                icon={<ArrowDown />}
                                alignIcon="left"
                                style={{ outline: 'none', float: "right", marginTop: 20 }}
                                onClick={handleUpdateScenarioVersionForUser}
                            >
                                {`Download Package`}
                            </Button>
                        </a>
                        {badgeNew}
                    </div>

                </div>
            }
        />
    )
};

export default ScenarioInstallationGuideDialog;