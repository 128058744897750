import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React Lib
import React, { useEffect, useState } from "react"; // Component Styles

import { useStyles } from "./EditDeviceLimitStyles"; // @otg-one/ui_components/components

import { Typography, DialogBox, Form, Button } from "../index"; // @otg-one/ui_components/constants

import { Color, FONT_FAMILY, DeviceLimitType } from "../../constants";
export var EditDeviceLimit = function EditDeviceLimit(props) {
  var classes = useStyles(props);
  var open = props.open,
      type = props.type,
      data = props.data,
      onClose = props.onClose,
      handleSubmitDeviceLimit = props.handleSubmitDeviceLimit;

  var _useState = useState(data.deviceLimit == null ? data.defaultDeviceLimit : data.deviceLimit),
      _useState2 = _slicedToArray(_useState, 2),
      deviceLimit = _useState2[0],
      setDeviceLimit = _useState2[1];

  var _useState3 = useState(data.deviceLimit == null),
      _useState4 = _slicedToArray(_useState3, 2),
      useClientLimit = _useState4[0],
      setUseClientLimit = _useState4[1];

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      isError = _useState6[0],
      setIsError = _useState6[1];

  var _useState7 = useState(""),
      _useState8 = _slicedToArray(_useState7, 2),
      errorMessage = _useState8[0],
      setErrorMessage = _useState8[1];

  var _useState9 = useState(false),
      _useState10 = _slicedToArray(_useState9, 2),
      confirmLowerDialogOpen = _useState10[0],
      setConfirmLowerDialogOpen = _useState10[1];

  useEffect(function () {
    setDeviceLimit(data.deviceLimit == null ? data.defaultDeviceLimit : data.deviceLimit);
    setUseClientLimit(data.deviceLimit == null);
  }, [open]);

  var handleCloseConfirmLowerDialog = function handleCloseConfirmLowerDialog() {
    setConfirmLowerDialogOpen(false);
  };

  var handleChangeDeviceLimit = function handleChangeDeviceLimit(e) {
    setDeviceLimit(e.target.value === "" ? null : +e.target.value);
    setUseClientLimit(false);
    setIsError(false);
    setErrorMessage("");
  };

  var handleSubmit = function handleSubmit() {
    if (deviceLimit == null) {
      setIsError(true);
      setErrorMessage("Limit cannot be empty");
    } else if (deviceLimit < data.activeDevice) {
      setIsError(true);
      setErrorMessage("Limit cannot be set below the current Active Device count, which is ".concat(data.activeDevice));
    } else if (type === DeviceLimitType.SYSTEM && deviceLimit > data.defaultDeviceLimit) {
      setIsError(true);
      setErrorMessage("Limit cannot be above Client's limit, which is ".concat(data.defaultDeviceLimit));
    } else if (type === DeviceLimitType.CLIENT && deviceLimit < data.deviceLimit) {
      setConfirmLowerDialogOpen(true);
    } else {
      handleConfirmSubmit();
    }
  };

  var handleConfirmSubmit = function handleConfirmSubmit() {
    data.deviceLimit = deviceLimit;
    handleSubmitDeviceLimit(data);
    setConfirmLowerDialogOpen(false);
    onClose();
  };

  return React.createElement(React.Fragment, null, React.createElement(DialogBox, {
    open: open,
    handleClose: onClose,
    title: "Change ".concat(type, " Device Limit"),
    content: React.createElement("div", {
      className: classes.editDeviceLimitDialogContainer
    }, React.createElement(Typography, {
      fontFamily: FONT_FAMILY.CAPITOLIUM_ITALIC,
      size: 16,
      color: Color.neutral[900],
      style: {
        marginBottom: 13
      }
    }, data.name), React.createElement(Form, {
      number: true,
      max: data.defaultDeviceLimit,
      min: data.activeDevice,
      placeholder: "Enter Device Limit",
      value: deviceLimit,
      onChange: handleChangeDeviceLimit,
      error: isError,
      errorMessage: errorMessage,
      style: {
        maxWidth: 300
      }
    }), useClientLimit && React.createElement(Typography, {
      fontFamily: FONT_FAMILY.CAPITOLIUM_ITALIC,
      size: 14,
      color: Color.neutral[300],
      style: {
        marginBottom: 13
      }
    }, "System Device Limit is not set. Customer Device Limit is being used."), React.createElement("div", {
      className: classes.editDeviceLimitDialogActions
    }, React.createElement(Button, {
      type: "secondary",
      style: {
        outline: 'none'
      },
      onClick: onClose
    }, "Cancel"), React.createElement(Button, {
      style: {
        marginLeft: 16,
        outline: 'none'
      },
      disabled: isError,
      onClick: handleSubmit
    }, "Change Limit")))
  }), type === DeviceLimitType.CLIENT && React.createElement(DialogBox, {
    open: confirmLowerDialogOpen,
    handleClose: handleCloseConfirmLowerDialog,
    title: "Confirm Lowering Customer Device Limit?",
    content: React.createElement("div", {
      className: classes.editDeviceLimitDialogContainer
    }, React.createElement(Typography, {
      fontFamily: FONT_FAMILY.CAPITOLIUM_ITALIC,
      size: 16,
      color: Color.neutral[900],
      style: {
        marginBottom: 50
      }
    }, "This will also lower the device limit of all systems that have set higher device limit than the new customer device limit (".concat(deviceLimit, ").")), React.createElement("div", {
      className: classes.editDeviceLimitDialogActions
    }, React.createElement(Button, {
      type: "secondary",
      style: {
        outline: 'none'
      },
      onClick: handleCloseConfirmLowerDialog
    }, "Cancel"), React.createElement(Button, {
      style: {
        marginLeft: 16,
        outline: 'none'
      },
      onClick: handleConfirmSubmit
    }, "Confirm")))
  }));
};
EditDeviceLimit.defaultProps = {
  type: DeviceLimitType.CLIENT,
  data: {
    name: "Data Name",
    deviceLimit: 20,
    defaultDeviceLimit: 25
  },
  onClose: function onClose() {},
  handleSubmitDeviceLimit: function handleSubmitDeviceLimit(data) {}
};
export default React.memo(EditDeviceLimit);