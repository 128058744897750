// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    whiteContentTopRadius: {
      backgroundColor: "#ffffff",
      marginTop: "-10px !important",
      textAlign: "center",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      paddingBottom: "20px",
      overflow: "hidden"
    },
    greyContent: {
      backgroundColor: "#f3f6f6",
      overflow: "auto",
      padding: "15px",
      paddingBottom: "7px"
    }
  });
});