

import axios from 'axios';

import { getApiUrl } from '../../Constants/Api';

export class ConfigurationService {

    saveConfiguration(userKey: string, functionKey: string, configuration: any) {
        return axios.post(getApiUrl() + "functions/" + functionKey + "?userkey=" + userKey, configuration);
    }

    getFunctionType(userKey: string, functionTypeId: string) {
        return axios.get(getApiUrl() + "functiontypes/" + functionTypeId + "?userkey=" + userKey);
    }
}

export default new ConfigurationService();