// React lib
import React, { useState, createContext, useContext, useEffect } from 'react';
import { AppContext } from '../../../App';

// Components
import ConfigurationContainer from '../ConfigurationContainer';
import SettingsForEntry from './SettingsForEntry';

import { FunctionInventoryStatus, ItemInventoryStatusListPage, EnterItemNumberPage, MenuPage, NoPreviewPage } from '@otg-one/ui_components';
import { VIA_SCAN_OF_ITEM, VIA_SCAN_OF_WAREHOUSE, SETTINGS_FOR_INVENTORY_STATUS, WAREHOUSE_NAME, ITEM_SELECTION, WAREHOUSE_SELECTION, SETTINGS_FOR_ENTRY, ENTRY_OPTION } from './InventoryStatusIds';

// Models
import { Stack } from '../../../Models/Stack';
import { InventoryStatus } from '../../../Models/InventoryStatus'

// Service
import ConfigurationService from "../ConfigurationService";

// Utils
import { inventoryStatusLocalMapToDatabaseValue, arraysMatch, arrayAdjustmentDefault, inventoryStatusDatabaseMapToLocalValue } from "../../../Utils/Utils"
import { STACK_TYPE_APP_FUNCTION } from '../../../Constants/StackType';

export const InventoryStatusContext = createContext<any>(null);
const InventoryStatusContextProvider = InventoryStatusContext.Provider;

// Interface
export interface IInventoryStatusContextInterface {
    defaultFunctionType: any,
    activeOption: string,
    setActiveOption: any,
    entryOption: string[],
    handleChangeEntryOption: any,
    viaScanOfItem: boolean,
    handleChangeViaScanOfItem: any,
    viaScanOfWarehouse: boolean,
    handleChangeViaScanOfWarehouse: any,
    warehouseName: string,
    handleChangeWarehouseName: any,
    itemSelection: string[],
    handleChangeItemSelection: any,
    warehouseSelection: string[],
    handleChangeWarehouseSelection: any
}

const InventoryStatusConfiguration = (props: any) => {
    const {
        breadcrumb,
        currentUserData,
        getCurrentBreadcrumbData,
        handleShowSnackbar,
        setSnackbarMessage
    } = useContext(AppContext);

    const [appData, setAppData] = useState(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION));
    const [defaultFunctionType, setDefaultFunctionType] = useState(null as any);

    const [isLoaded, setIsLoaded] = useState(false)
    const [isModified, setIsModified] = useState(false)
    const [listError, setListError] = useState([] as string[])

    const [activeOption, setActiveOption] = useState("")
    const [activeOptionName, setActiveOptionName] = useState("")

    const [activePreview, setActivePreview] = useState("" as any)
    const [activeMenu, setActiveMenu] = useState({})

    const [entryOption, setEntryOption] = useState([] as string[])

    const [viaScanOfItem, setViaScanOfItem] = useState(false)
    const [viaScanOfWarehouse, setViaScanOfWarehouse] = useState(false)

    const [warehouseName, setWarehouseName] = useState(`warehouseName`)
    const [itemSelection, setItemSelection] = useState(["textInput"] as string[])
    const [warehouseSelection, setWarehouseSelection] = useState(["textInput"] as string[])

    const loadDefault = async () => {
        const response = await ConfigurationService.getFunctionType(currentUserData.key, appData.functionTypeId)
        setDefaultFunctionType(response.data);
    }

    const setConfiguration = (masterData: any) => {
        if (masterData.config) {
            Object.keys(masterData.config).map((key: any, index: any) => {
                if (key === "entry") {
                    setEntryOption(inventoryStatusDatabaseMapToLocalValue("entry", masterData.config[key]) as string[]);
                } else if (key === "warehouseLayout") {
                    setWarehouseName(inventoryStatusDatabaseMapToLocalValue("warehouseLayout", masterData.config[key]) as string);
                }
                else if (key === "selectItemInput") {
                    setItemSelection(inventoryStatusDatabaseMapToLocalValue("selectItemInput", masterData.config[key]) as string[]);
                }
                else if (key === "selectWarehouseInput") {
                    setWarehouseSelection(inventoryStatusDatabaseMapToLocalValue("selectWarehouseInput", masterData.config[key]) as string[]);
                }
            });
        }
    };

    React.useEffect(() => {
        setAppData(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION));
    }, [breadcrumb]);


    React.useEffect(() => {
        if (Object.keys(appData).length !== 0) {
            loadDefault();

            new Promise((resolve, reject) => {
                setConfiguration(appData);

                resolve(true)
            }).then(res => {
                setIsLoaded(true);
            })
        }
    }, [appData]);

    const InventoryStatusMenus = [
        {
            id: SETTINGS_FOR_ENTRY,
            label: "Settings For Entry",
            subtitle: `There are two options for the entry to the Inventory Status process. One the one hand there is the scan of the item and on the other hand there is the scan for the warehouse. If both options are chosen a sub menu will be shown to the user to decide over the preferred entry method.`,
            isCustomized: (defaultFunctionType && (!arraysMatch(defaultFunctionType.config.entry, inventoryStatusLocalMapToDatabaseValue("entry", entryOption) as string[]) ||
                defaultFunctionType.config.warehouseLayout !== inventoryStatusLocalMapToDatabaseValue("warehouseLayout", warehouseName) ||
                !arraysMatch(defaultFunctionType.config.selectItemInput, inventoryStatusLocalMapToDatabaseValue("selectItemInput", itemSelection) as string[]) ||
                !arraysMatch(defaultFunctionType.config.selectWarehouseInput, inventoryStatusLocalMapToDatabaseValue("selectWarehouseInput", warehouseSelection) as string[])
            )) ? true : false,
            submenus: [
                {
                    id: ENTRY_OPTION,
                    label: "Entry Option",
                    isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.entry, inventoryStatusLocalMapToDatabaseValue("entry", entryOption) as string[])) ? true : false,
                    renderSubmenu: entryOption.length > 0,
                    submenus: [
                        {
                            id: WAREHOUSE_NAME,
                            label: "Warehouse Name",
                            isCustomized: (defaultFunctionType && defaultFunctionType.config.warehouseLayout !== inventoryStatusLocalMapToDatabaseValue("warehouseLayout", warehouseName)) ? true : false,
                            isShown: entryOption.includes(VIA_SCAN_OF_ITEM)
                        },
                        {
                            id: ITEM_SELECTION,
                            label: "Item Selection",
                            isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.selectItemInput, inventoryStatusLocalMapToDatabaseValue("selectItemInput", itemSelection) as string[])) ? true : false,
                            isShown: entryOption.includes(VIA_SCAN_OF_ITEM)
                        },

                        {
                            id: WAREHOUSE_SELECTION,
                            label: "Warehouse Selection",
                            isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.selectWarehouseInput, inventoryStatusLocalMapToDatabaseValue("selectWarehouseInput", warehouseSelection) as string[])) ? true : false,
                            isShown: entryOption.includes(VIA_SCAN_OF_WAREHOUSE)
                        }
                    ]
                }
            ]
        },
    ]

    const handleClickSaveConfiguration = () => {
        const tempAppData = { ...appData };

        //to avoid different position number in the object - for customized in system dashboard page
        tempAppData.config = {}

        Object.keys(defaultFunctionType.config).map((key: any) => {
            if (key === "entry") {
                tempAppData.config.entry = arrayAdjustmentDefault(inventoryStatusLocalMapToDatabaseValue("entry", entryOption) as any[], defaultFunctionType.config.entry);
            } else if (key === "warehouseLayout") {
                tempAppData.config.warehouseLayout = inventoryStatusLocalMapToDatabaseValue("warehouseLayout", warehouseName);
            }
            else if (key === "selectItemInput") {
                tempAppData.config.selectItemInput = arrayAdjustmentDefault(inventoryStatusLocalMapToDatabaseValue("selectItemInput", itemSelection) as any[], defaultFunctionType.config.selectItemInput);
            }
            else if (key === "selectWarehouseInput") {
                tempAppData.config.selectWarehouseInput = arrayAdjustmentDefault(inventoryStatusLocalMapToDatabaseValue("selectWarehouseInput", warehouseSelection) as any[], defaultFunctionType.config.selectWarehouseInput);
            } else {
                tempAppData.config[key] = defaultFunctionType.config[key];
            }
        })

        return ConfigurationService.saveConfiguration(currentUserData.key, tempAppData.key, tempAppData).then((res: any) => {
            setIsModified(false);
            setSnackbarMessage(`${breadcrumb[breadcrumb.length - 2].data.name} - ${appData.name} Configuration has been changed`)
            handleShowSnackbar();
        });
    }

    React.useEffect(() => {
        if (isLoaded) {
            setIsModified(true);
        }

    }, [entryOption, viaScanOfItem, viaScanOfWarehouse, warehouseName, itemSelection, warehouseSelection]);


    React.useEffect(() => {
        if (isLoaded && entryOption.includes(VIA_SCAN_OF_ITEM)) {
            if (itemSelection.length <= 0) {
                setListError([...listError, ITEM_SELECTION]);
            } else {
                setListError(listError.filter((event) => (event !== ITEM_SELECTION)));
            }
        }
    }, [itemSelection]);

    React.useEffect(() => {
        if (isLoaded && entryOption.includes(VIA_SCAN_OF_WAREHOUSE)) {
            if (warehouseSelection.length <= 0) {
                setListError([...listError, WAREHOUSE_SELECTION]);
            } else {
                setListError(listError.filter((event) => (event !== WAREHOUSE_SELECTION)));
            }
        }
    }, [warehouseSelection]);

    React.useEffect(() => {
        if (isLoaded) {
            if (entryOption.length <= 0) {
                setListError([...listError, ENTRY_OPTION]);
            } else {
                setListError(listError.filter((event) => (event !== ENTRY_OPTION)));
            }
        }
    }, [entryOption]);

    const handleChangeEntryOption = (e: any) => {
        if (entryOption.includes(e.target.value)) {
            setEntryOption(entryOption.filter((event) => (event !== e.target.value)));
        } else {
            setEntryOption([...entryOption, e.target.value]);
            if (e.target.value === VIA_SCAN_OF_ITEM) {
                setWarehouseName(inventoryStatusDatabaseMapToLocalValue("warehouseLayout", defaultFunctionType.config.warehouseLayout) as string);
                setItemSelection(inventoryStatusDatabaseMapToLocalValue("selectItemInput", defaultFunctionType.config.selectItemInput) as string[])
            }
            if (e.target.value === VIA_SCAN_OF_WAREHOUSE) {
                setWarehouseSelection(inventoryStatusDatabaseMapToLocalValue("selectWarehouseInput", defaultFunctionType.config.selectWarehouseInput) as string[]);
            }
        }
    }

    const handleChangeViaScanOfItem = () => {
        setViaScanOfItem(prev => !prev)
    }

    const handleChangeViaScanOfWarehouse = () => {
        setViaScanOfWarehouse(prev => !prev)
    }

    const handleChangeWarehouseName = (e: any) => {
        setWarehouseName(e.target.value)
    }

    const handleChangeItemSelection = (e: any) => {
        if (itemSelection.includes(e.target.value)) {
            setItemSelection(itemSelection.filter((event) => (event !== e.target.value)));
        } else {
            setItemSelection([...itemSelection, e.target.value]);
        }
    }

    const handleChangeWarehouseSelection = (e: any) => {
        if (warehouseSelection.includes(e.target.value)) {
            setWarehouseSelection(warehouseSelection.filter((event) => (event !== e.target.value)));
        } else {
            setWarehouseSelection([...warehouseSelection, e.target.value]);
        }
    }

    const handleChangeActiveOption = (id: string) => {
        let element = document.getElementById(id)

        if (element) {
            const bounding = element.getBoundingClientRect()

            if (
                bounding.top >= 0 &&
                bounding.top <= 400 &&
                bounding.left >= 0 &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            ) {
                if (id === SETTINGS_FOR_INVENTORY_STATUS) {
                    setActiveOption(VIA_SCAN_OF_ITEM)
                }
                else {
                    setActiveOption(id)
                }
            }
        }
    }

    const handleActivePreview = (id: string) => {
        const itemInventoryStatusListData =
            [
                {
                    id: "1",
                    warehouseCode: "01",
                    warehouseName: "WH General",
                    stock: 6,
                    stockType: "Pallette(n)",
                },
                {
                    id: "2",
                    warehouseCode: "02",
                    warehouseName: "WH General",
                    stock: 1,
                    stockType: "pc",
                },
                {
                    id: "3",
                    warehouseCode: "03",
                    warehouseName: "WH General",
                    stock: 12,
                    stockType: "pc",
                },
                {
                    id: "4",
                    warehouseCode: "04",
                    warehouseName: "WH General",
                    stock: 6,
                    stockType: "pc",
                },
            ];

        const menuData = [
            {
                name: "Select Item",
                function: () => { }
            },
            {
                name: "Select Warehouse",
                function: () => { }
            }
        ]

        switch (id) {
            case ENTRY_OPTION:
                if (entryOption.includes(VIA_SCAN_OF_ITEM) && entryOption.includes(VIA_SCAN_OF_WAREHOUSE)) {
                    return setActivePreview(<MenuPage headerTitle="Inventory Status" data={menuData} previewWidth={320} previewHeight={568} />);
                } else if (entryOption.includes(VIA_SCAN_OF_ITEM)) {
                    return setActivePreview(<ItemInventoryStatusListPage itemName={"LeMon 4029 Printhead"} totalItem={26} totalItemType={"Palette(n)"} warehouseLayout={warehouseName === "warehouseName" ? "warehouseName" : "warehouseCode"} data={itemInventoryStatusListData} previewHeight={568} previewWidth={320} />);
                } else if (entryOption.includes(VIA_SCAN_OF_WAREHOUSE)) {
                    return setActivePreview(<EnterItemNumberPage data={{ inputMessage: "Please enter the warehouse or bin location", barcodeMessage: "Please scan the barcode of the warehouse or bin location" }} headerTitle={"Inventory Status"} inputTypes={warehouseSelection} previewHeight={568} previewWidth={320} />);
                } else {
                    return setActivePreview(<NoPreviewPage type={"emptyValue"} previewHeight={568} previewWidth={320} />);
                }
            case WAREHOUSE_NAME:
                return setActivePreview(<ItemInventoryStatusListPage itemName={"LeMon 4029 Printhead"} totalItem={26} totalItemType={"Palette(n)"} warehouseLayout={warehouseName === "warehouseName" ? "warehouseName" : "warehouseCode"} data={itemInventoryStatusListData} previewHeight={568} previewWidth={320} />);
            case ITEM_SELECTION:
                if (itemSelection.length > 0) {
                    return setActivePreview(<EnterItemNumberPage data={{ inputMessage: "Please enter the item number", barcodeMessage: "Please scan the barcode of the item" }} headerTitle={"Inventory Status"} inputTypes={itemSelection} previewHeight={568} previewWidth={320} />);
                } else {
                    return setActivePreview(<NoPreviewPage type={"emptyValue"} previewHeight={568} previewWidth={320} />);
                }
            case WAREHOUSE_SELECTION:
                if (warehouseSelection.length > 0) {
                    return setActivePreview(<EnterItemNumberPage data={{ inputMessage: "Please enter the warehouse or bin location", barcodeMessage: "Please scan the barcode of the warehouse or bin location" }} headerTitle={"Inventory Status"} inputTypes={warehouseSelection} previewHeight={568} previewWidth={320} />);
                } else {
                    return setActivePreview(<NoPreviewPage type={"emptyValue"} previewHeight={568} previewWidth={320} />);
                }
            default:
                return setActivePreview(id);
        }
    }

    useEffect(() => {
        handleActivePreview(activeOption);

        InventoryStatusMenus.forEach(menu => {
            menu.submenus.forEach((subMenu: any) => {
                if (subMenu.id === activeOption) {
                    setActiveMenu(menu)
                    setActiveOptionName(subMenu.label)
                }

                subMenu.submenus.forEach((thirdSubmenu: any) => {
                    if (thirdSubmenu.id === activeOption) {
                        setActiveMenu(menu)
                        setActiveOptionName(thirdSubmenu.label)
                    };
                })
            });
        })

    }, [activeOption, entryOption, warehouseName, itemSelection, warehouseSelection]);

    React.useEffect(() => {
        setActiveOption(ENTRY_OPTION)
    }, [])

    const viewPortCallback = () => {
        // handleChangeActiveOption(SETTINGS_FOR_INVENTORY_STATUS)
        handleChangeActiveOption(ENTRY_OPTION)
        // handleChangeActiveOption(VIA_SCAN_OF_ITEM)
        handleChangeActiveOption(WAREHOUSE_NAME)
        handleChangeActiveOption(ITEM_SELECTION)
        // handleChangeActiveOption(VIA_SCAN_OF_WAREHOUSE)
        handleChangeActiveOption(WAREHOUSE_SELECTION)
    }

    const DeliveryContextValue: IInventoryStatusContextInterface = {
        defaultFunctionType: defaultFunctionType,
        activeOption: activeOption,
        setActiveOption: handleChangeActiveOption,

        entryOption: entryOption,
        handleChangeEntryOption: handleChangeEntryOption,

        viaScanOfItem,
        handleChangeViaScanOfItem,
        viaScanOfWarehouse,
        handleChangeViaScanOfWarehouse,
        warehouseName: warehouseName,
        handleChangeWarehouseName: handleChangeWarehouseName,
        itemSelection: itemSelection,
        handleChangeItemSelection: handleChangeItemSelection,
        warehouseSelection: warehouseSelection,
        handleChangeWarehouseSelection: handleChangeWarehouseSelection
    };

    return (
        <ConfigurationContainer
            detectViewportCallback={viewPortCallback}
            title={`Inventory Status`}
            icon={<FunctionInventoryStatus />}
            activeMenu={activeMenu}
            activeViewport={activePreview}
            activeOption={activeOption}
            activeOptionName={activeOptionName}
            sidebarMenus={InventoryStatusMenus}
            saveConfiguration={handleClickSaveConfiguration}
            setIsModified={setIsModified}
            isModified={isModified}
            setListError={setListError}
            listError={listError}
            setConfigurationToDefault={() => setConfiguration(defaultFunctionType)}
            setConfigurationToPreviousSetting={() => setConfiguration(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION))}
        >
            <InventoryStatusContextProvider value={DeliveryContextValue}>
                <SettingsForEntry />
            </InventoryStatusContextProvider>
        </ConfigurationContainer>
    )
};

export default InventoryStatusConfiguration;