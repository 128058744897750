// React Lib
import React from "react"; // Component Styles

import { useStyles } from "./SnackbarStyles"; // @otg-one/ui_components/components

import { Typography, Button } from '../index'; // @otg-one/ui_components/icons
// import { OntegoOneLogoWhiteAlt, Settings, Alert } from '../../icons';
// @otg-one/ui_components/constants

import { Color, FONT_FAMILY } from "../../constants"; // Material UI Core

import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
export var Snackbar = function Snackbar(props) {
  var classes = useStyles(props);
  var id = props.id,
      show = props.show,
      onClose = props.onClose,
      icon = props.icon,
      children = props.children,
      closeButton = props.closeButton;
  return React.createElement(Slide, {
    direction: "up",
    in: show,
    mountOnEnter: true,
    unmountOnExit: true
  }, React.createElement("div", {
    className: classes.snackbarWrapper
  }, React.createElement("div", {
    className: classes.meter
  }), React.createElement("div", {
    className: classes.snackbarContainer
  }, React.createElement(Grid, {
    container: true,
    style: {
      flexGrow: 1
    },
    spacing: 2
  }, React.createElement(Grid, {
    item: true,
    md: 2
  }, React.createElement("div", {
    style: {
      display: 'flex',
      alignItems: 'center'
    }
  }, React.createElement("div", {
    className: classes.snackbarIconContainer
  }, icon))), React.createElement(Grid, {
    item: true,
    md: 8
  }, React.createElement("div", {
    style: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 10
    }
  }, React.createElement(Typography, {
    fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
    size: 14,
    color: Color.neutral[0]
  }, children))), React.createElement(Grid, {
    item: true,
    md: 2
  }, React.createElement("div", {
    style: {
      display: 'flex',
      alignItems: 'center'
    }
  }, closeButton && React.createElement(Button, {
    color: "white",
    style: {
      marginLeft: 100
    },
    onClick: onClose
  }, "Close")))))));
};
Snackbar.defaultProps = {
  closeButton: false
};
export default React.memo(Snackbar);