import React, { useState, useEffect } from 'react';

// Components
import {
    Typography,
    FONT_FAMILY,
    Color,
    Button,
    DialogBox
} from '@otg-one/ui_components';
import SystemService from './SystemService';

interface ConfirmDeleteUserRoleProps {
    open: boolean;
    onClose: () => any;
    currentUserData: any;
    userRole: any;
    getUserRoles: () => any;
}

const ConfirmDeleteUserRole = (props: ConfirmDeleteUserRoleProps) => {
    const { open, onClose, currentUserData, userRole, getUserRoles } = props;

    const handleSubmitDeleteUserRole = () => {
        SystemService.deleteUserRole(currentUserData.key, userRole.key).then((res: any) => {
            if (res.status === 200) {
                onClose();
                getUserRoles();
            }
        });
    };

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title={`Confirm Delete User Role "${userRole && userRole.name}" ?`}
            content={
                <div style={{ width: 500, paddingTop: 16 }}>
                    <Typography
                        fontFamily={FONT_FAMILY.CAPITOLIUM_ITALIC}
                        size={16}
                        color={Color.neutral[900]}
                        style={{ marginBottom: 50 }}
                    >
                        {`The User Role will be permanently deleted.`}
                    </Typography>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: 45 }}>
                        <Button type="secondary" style={{ outline: 'none' }} onClick={onClose}>Cancel</Button>
                        <Button style={{ marginLeft: 16, outline: 'none' }} onClick={handleSubmitDeleteUserRole}>Confirm</Button>
                    </div>
                </div>
            }
        />
    )
};

export default ConfirmDeleteUserRole;