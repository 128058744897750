// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    floatingDiv: {
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0 0 5px 1px #d0d0d0",
      overflow: "hidden"
    },
    floatingDivBarBottom: {
      height: "50px"
    },
    floatingDivContent: {
      overflow: "auto",
      position: "relative",
      padding: "20px",
      textAlign: "center"
    },
    greenButton: {
      backgroundColor: "#39af81",
      width: "100% !important",
      height: "50px !important",
      color: "white !important",
      fontSize: "16px !important",
      borderRadius: "0 !important",
      textTransform: "none"
    },
    redButton: {
      backgroundColor: "#f1675e",
      width: "100% !important",
      height: "50px !important",
      color: "white !important",
      fontSize: "16px !important",
      borderRadius: "0 !important",
      textTransform: "none"
    }
  });
});