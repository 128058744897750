import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../App';

// Material-UI Core
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
// import Grow from '@material-ui/core/Grow';

// Components
import {
    Typography,
    Tabs,
    Chart,
    FONT_FAMILY,
    Color,
    SystemLog,
    UserRole,
    Dropdown,
    MaximizedSystemLog,
    // Database,
    // CPU,
    ApplicationCard,
    BreadcrumbType,
    Chip,
    Switch,
    Bug,
    Device,
    ShowMoreHorizontal,
    OverflowMenu,
    DeviceLimit,
    DeviceLimitType,
    EmptyChartData
} from '@otg-one/ui_components';
import { useStyles } from './SystemDashboardStyles';

import { Stack } from '../../../Models/Stack';

import DeactivateApplication from './DeactivateApplication';

import SystemService from "./SystemService";
import PartnerService from "../Partner/PartnerService";

import { getFunctionRoutePath, getUpperBoundValue, getTicks, generateDropDownItems, getLastNYear } from "../../../Utils/Utils";
import { STACK_TYPE_APP_FUNCTION, STACK_TYPE_SYSTEM } from '../../../Constants/StackType';
import UserRoleDialog from './UserRoleDialog';
import InputUserRole from './InputUserRole';
import ConfirmDeleteUserRole from './ConfirmDeleteUserRole';
import SystemInfo from './SystemInfo';
import { Hidden } from '@material-ui/core';

const SystemCostAndLogin = (props: any) => {
    const {
        getCurrentBreadcrumbData,
    } = useContext(AppContext);

    const [systemCostAndLogin, setSystemCostAndLogin] = useState(() => [])
    const [system] = useState(getCurrentBreadcrumbData(STACK_TYPE_SYSTEM));

    useEffect(() => {
        if (Object.keys(system).length !== 0) {
            SystemService.getSystemsCostAndLogin(system.key, props.selectedYear).then((res: any) => {
                setSystemCostAndLogin(res.data);
            });
        }
    }, [system, props.selectedYear]);

    let noChart = true;
    let costMaxValue = 0;
    let loginMaxValue = 0;

    systemCostAndLogin.forEach((row: any, index: any) => {
        if (index > 0) {
            if (row[2] !== 0)
                noChart = false;
            if (row[1] > costMaxValue)
                costMaxValue = row[1];
            if (row[2] > loginMaxValue)
                loginMaxValue = row[2];
        }
    })

    const costUpperBound = getUpperBoundValue(costMaxValue);
    const loginUpperBound = getUpperBoundValue(loginMaxValue);

    return (
        <div style={{ paddingTop: 30 }}>
            <Chart
                chartType="ColumnChart"
                chartData={systemCostAndLogin}
                chartWidth={"100%"}
                chartHeight={"300px"}
                chartOptions={{
                    chartArea: {
                        left: "9%",
                        right: '4%',
                        top: 5,
                        bottom: 50,
                        width: "100%",
                        height: "350"
                    },
                    backgroundColor: "transparent",
                    colors: [Color.primary[900], Color.primary[200]],
                    animation: {
                        startup: true,
                        easing: 'out',
                        duration: 500,
                    },
                    legend: { position: 'bottom' },
                    series: {
                        0: { axis: 'cost', targetAxisIndex: 0 },
                        1: { axis: 'login', targetAxisIndex: 1 }
                    },
                    vAxes: {
                        0: {
                            textStyle: {
                                fontFamily: FONT_FAMILY.ROBOTO_LIGHT,
                                color: Color.neutral[400],
                            },
                            ticks: getTicks(costUpperBound),
                            viewWindow: {
                                min: 0,
                                max: costUpperBound
                            },
                            format: '#,##0.00 €'
                        },
                        1: {
                            textStyle: {
                                fontFamily: FONT_FAMILY.ROBOTO_LIGHT,
                                color: Color.neutral[400],
                            },
                            ticks: getTicks(loginUpperBound),
                            viewWindow: {
                                min: 0,
                                max: loginUpperBound
                            },
                        }
                    },
                    hAxis: {
                        textStyle: {
                            fontFamily: FONT_FAMILY.ROBOTO_LIGHT,
                            color: Color.neutral[400],
                        }
                    },
                    bar: {
                        groupWidth: "64"
                    },
                    tooltip: { isHtml: true, trigger: "visible" }
                }}
                chartEvents={
                    [
                        {
                            eventName: 'ready',
                            callback: (ce: any) => {
                                const chart = ce.chartWrapper.getChart();
                                var defses = document.getElementsByTagName('defs');
                                var gradientInjectFound = false;
                                Array.prototype.forEach.call(defses, function (defs) {
                                    if (defs.getAttribute('id') === 'gradientInject') {
                                        gradientInjectFound = true;
                                    }
                                });
                                if (!gradientInjectFound) {
                                    Array.prototype.forEach.call(defses, function (defs) {
                                        defs.insertAdjacentHTML('afterend', '<defs id="gradientInject"><linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%"><stop offset="0%" style="stop-color:#0077b6;stop-opacity:1" /><stop offset="100%" style="stop-color:#00527f;stop-opacity:1" /></linearGradient></defs>');
                                    });
                                }
                                var rects = chart.container.getElementsByTagName('rect');
                                Array.prototype.forEach.call(rects, function (rect) {
                                    if (rect.getAttribute('fill') === '#00527f') {
                                        rect.setAttribute('fill', 'url(#grad1)');
                                    }
                                });
                                ce.google.visualization.events.addListener(chart, "onmouseover", (e: any) => {
                                    var rects = chart.container.getElementsByTagName('rect');
                                    Array.prototype.forEach.call(rects, function (rect) {
                                        if (rect.getAttribute('fill') === '#00527f') {
                                            rect.setAttribute('fill', 'url(#grad1)');
                                        }
                                    });
                                });
                                ce.google.visualization.events.addListener(chart, "onmouseout", (e: any) => {
                                    var rects = chart.container.getElementsByTagName('rect');
                                    Array.prototype.forEach.call(rects, function (rect) {
                                        if (rect.getAttribute('fill') === '#00527f') {
                                            rect.setAttribute('fill', 'url(#grad1)');
                                        }
                                    });
                                });
                                ce.google.visualization.events.addListener(chart, "animationfinish", (e: any) => {
                                    var rects = chart.container.getElementsByTagName('rect');
                                    Array.prototype.forEach.call(rects, function (rect) {
                                        if (rect.getAttribute('fill') === '#00527f') {
                                            rect.setAttribute('fill', 'url(#grad1)');
                                        }
                                    });
                                });
                            },
                        },
                        {
                            eventName: 'select',
                            callback: (ce: any) => {
                                const chart = ce.chartWrapper.getChart();
                                var rects = chart.container.getElementsByTagName('rect');
                                Array.prototype.forEach.call(rects, function (rect) {
                                    if (rect.getAttribute('fill') === '#00527f') {
                                        rect.setAttribute('fill', 'url(#grad1)');
                                    }
                                });
                            },
                        },
                    ]
                }
            />
            {noChart && (
                <EmptyChartData></EmptyChartData>
            )}
        </div>
    )
}

const USER_ROLES_DATA = [
    {
        name: "Admin",
        goodsReceipt: true,
        pickPack: true,
        inventoryStatus: true,
        inventoryTransfer: false,
        inventoryCounting: false,
        delivery: true,
    },
    {
        name: "VE",
        goodsReceipt: false,
        pickPack: false,
        inventoryStatus: true,
        inventoryTransfer: false,
        inventoryCounting: true,
        delivery: true,
    },
    {
        name: "Quality Check",
        goodsReceipt: true,
        pickPack: true,
        inventoryStatus: true,
        inventoryTransfer: false,
        inventoryCounting: false,
        delivery: true,
    },
    {
        name: "Process",
        goodsReceipt: false,
        pickPack: true,
        inventoryStatus: true,
        inventoryTransfer: true,
        inventoryCounting: true,
        delivery: true,
    }
]

const SystemDashboard = (props: any) => {
    const classes = useStyles(props);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [checked, setChecked] = useState(false);

    const {
        breadcrumb,
        getCurrentBreadcrumbData,
        currentUserData,
        handleChangeBreadcrumb,
        handleShowSnackbar,
        handleShowAlertSnackbar,
        setSnackbarMessage
    } = useContext(AppContext);

    const [system, setSystem] = useState(getCurrentBreadcrumbData(STACK_TYPE_SYSTEM));
    const [functions, setFunctions] = useState([]);
    const [userRoles, setUserRoles] = useState([]);

    const [deactivateApplicationDialogOpen, setDeactivateApplicationDialogOpen] = useState(false);
    const [activeApplication, setActiveApplication] = useState(null);

    const [isDebugMode, setIsDebugMode] = useState(false);

    const [userRoleDialogOpen, setUserRoleDialogOpen] = useState(false);

    const [inputUserRoleDialogOpen, setInputUserRoleDialogOpen] = useState(false);
    const [inspectedUserRole, setInspectedUserRole] = useState(null);

    const [confirmDeleteUserRoleDialogOpen, setConfirmDeleteUserRoleDialogOpen] = useState(false);
    const [toBeDeletedUserRole, setToBeDeletedUserRole] = useState(null);

    useEffect(() => {
        if (inspectedUserRole != null) {
            setInputUserRoleDialogOpen(true);
        }
    }, [inspectedUserRole]);

    useEffect(() => {
        if (!inputUserRoleDialogOpen) {
            setInspectedUserRole(null);
        }
    }, [inputUserRoleDialogOpen]);

    useEffect(() => {
        if (toBeDeletedUserRole != null) {
            setConfirmDeleteUserRoleDialogOpen(true);
        }
    }, [toBeDeletedUserRole]);

    useEffect(() => {
        if (!confirmDeleteUserRoleDialogOpen) {
            setToBeDeletedUserRole(null);
        }
    }, [confirmDeleteUserRoleDialogOpen]);

    const [deviceLog, setDeviceLog] = useState([]);

    const [systemLog, setSystemLog] = useState([]);

    const [selectedYear, setSelectedYear] = useState(-1);

    const handleDropDownClick = (value: any) => {
        value = typeof value === 'string' ? -1 : value;
        setSelectedYear(value);
    }

    const DROPDOWN_MENU = generateDropDownItems(getLastNYear(), handleDropDownClick);

    const handleOpenUserRoleDialog = () => {
        setUserRoleDialogOpen(true)
    };

    const handleCloseUserDialog = () => {
        setUserRoleDialogOpen(false)
    };

    const handleCreateUserRole = () => {
        setInputUserRoleDialogOpen(true);
    };

    const handleEditUserRole = (userRole: any) => () => {
        setInspectedUserRole(userRole);
    };

    const handleCloseInputUserRoleDialog = () => {
        setInputUserRoleDialogOpen(false);
    };

    const handleDeleteUserRole = (userRole: any) => () => {
        setToBeDeletedUserRole(userRole);
    };

    const handleCloseConfirmDeleteUserRoleDialog = () => {
        setConfirmDeleteUserRoleDialogOpen(false);
    };

    const handleChangeIsDebugMode = () => {
        setIsDebugMode(prev => !prev);
    };

    const handleChangeIsDebugModeList = (e: any, log: any) => {
        const newDeviceLog = [...deviceLog]

        newDeviceLog.forEach((lg: any) => {
            if (lg.deviceId === log.deviceId) {
                lg.isDebug = !lg.isDebug
            }
        })

        setDeviceLog(newDeviceLog)
    };

    useEffect(() => {
        setSystem(getCurrentBreadcrumbData(STACK_TYPE_SYSTEM));
    }, [breadcrumb]);

    useEffect(() => {
        if (Object.keys(system).length !== 0) {
            getFunctions();
            getUserRoles();
            getSystemLog();
        }
    }, [system]);

    const getFunctions = () => {
        SystemService.getFunctions(currentUserData.key, system.key).then((res: any) => {
            setFunctions(res.data);
        });
    };

    const getUserRoles = () => {
        SystemService.getUserRoles(currentUserData.key, system.key).then((res: any) => {
            setUserRoles(res.data);
        });
    };

    const getSystemLog = () => {
        PartnerService.getSystemLog(currentUserData.key, system.key).then((res: any) => {
            setSystemLog(res.data);
        }).catch(error => {
            // TODO: error handling
            return error;
        });
    };

    const handleSubmitDeviceLimit = (data: any) => {
        SystemService.updateSystemDeviceLimit(currentUserData.key, data).then((res: any) => {
            getSystemLog();

            setSnackbarMessage(`Device limit has been changed for system: ${data.name}`);
            handleShowAlertSnackbar();
        });
    };

    const activateApplication = (app: any) => {
        SystemService.activateFunction(currentUserData.key, app.key).then((res: any) => {
            getFunctions();

            setSnackbarMessage(`${system.name} - ${app.name} has been activated`)
            handleShowSnackbar();
        });
    };

    const deactivateApplication = (app: any) => {
        SystemService.deactivateFunction(currentUserData.key, app.key).then((res: any) => {
            handleCloseDeactivateApplicationDialog();
            getFunctions();

            setSnackbarMessage(`${system.name} - ${app.name} has been deactivated`)
            handleShowAlertSnackbar();
        });
    };

    const handleCloseDeactivateApplicationDialog = () => {
        setDeactivateApplicationDialogOpen(false)
        setActiveApplication(null)
    }

    const handleClickApplicationCard = (app: any) => () => {
        handleChangeBreadcrumb(BreadcrumbType.PUSH, new Stack(STACK_TYPE_APP_FUNCTION, app.name, getFunctionRoutePath(app.functionTypeId), app));
    };

    return (
        <>
            <div id="systemDashboardContainer" className={classes.dashboardContainer}>
                <Grid container style={{ flexGrow: 1 }} spacing={2}>
                    <Grid item md={12} xs={12}>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                            size={16}
                            color={Color.neutral[600]}
                            style={{ marginBottom: 9 }}
                        >
                            {`OntegoOne ID: ${system.otgOneId}`}
                        </Typography>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <Chip
                                backgroundColor={system.deploymentEnv === 0 ? Color.danger[400] : Color.gradient[100]}
                            >
                                {system.deploymentEnv === 0 ? "TEST" : "PRODUCTION"}
                            </Chip>
                            {system.deploymentEnv === 0 && (
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                    size={12}
                                    color={Color.primary[400]}
                                    style={{ marginLeft: 12 }}
                                >
                                    {system.pin ? `PIN: ${system.pin}` : ``}
                                </Typography>
                            )}
                        </div>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <div className={classes.customerCostAndLoginChart}>
                            {/** TITLE */}
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={18}
                                color={Color.neutral[900]}
                            >
                                {`System Cost And Devices`}
                            </Typography>
                            {/** TABS */}
                            <div
                                style={{
                                    marginTop: 35
                                }}
                            >
                                <SystemCostAndLogin selectedYear={selectedYear} />
                            </div>
                            <div className={classes.chartDropdown}>
                                {<Dropdown
                                    menu={DROPDOWN_MENU}
                                    selected={DROPDOWN_MENU[0].text}
                                    type="SELECTION"
                                    backgroundColor={Color.neutral[0]}
                                />}
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <DeviceLimit
                            data={system}
                            type={DeviceLimitType.SYSTEM}
                            handleSubmitDeviceLimit={handleSubmitDeviceLimit}
                        />
                    </Grid>
                </Grid>

                <div style={{ marginTop: 8 }}>
                    <Grid container style={{ flexGrow: 1, position: "relative" }} spacing={2}>
                        <Grid item md={8} xs={12}>
                            <SystemLog logs={systemLog} global={false} />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <UserRole userRoleCount={userRoles.length} onClick={handleOpenUserRoleDialog} />
                        </Grid>
                    </Grid>
                </div>
            </div>

            <div className={classes.systemDashboardApplicationFunctions}>
                <div className={classes.systemDashboardTopbar}>
                    <Grid container style={{ flexGrow: 1 }} spacing={2}>
                        <Grid item md={8} xs={12}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={isMobile ? 16 : 20}
                                color={Color.neutral[900]}
                            >
                                {`Application Functions`}
                            </Typography>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            {/** SWITCH */}
                            { /*<div style={{ display: 'flex', alignItems: "center", justifyContent: 'flex-end' }}>
                                <Switch checked={isDebugMode} handleChange={handleChangeIsDebugMode} value={isDebugMode} />
                                <Bug style={{ width: 24, height: 24, color: isDebugMode ? Color.primary[500] : Color.neutral[300], marginLeft: 8 }} />
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                    size={12}
                                    color={Color.neutral[900]}
                                    style={{ marginLeft: 12 }}
                                >
                                    {`Debug Mode ${isDebugMode ? "on" : "off"}`}
                                </Typography>
                            </div> */ }
                        </Grid>
                    </Grid>
                </div>
                <div
                    style={{
                        marginTop: isMobile ? 0 : 30
                    }}
                >
                    <Grid container style={{ flexGrow: 1 }} spacing={2}>
                        {/* <Grid item md={12}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={24}
                                color={Color.neutral[400]}
                            >
                                {system.name}
                            </Typography>
                        </Grid>
                        {system.dbName &&
                            <Grid item md={12}>
                                <div style={{ display: "inline-flex", marginTop: 10 }}>
                                    <Database
                                        style={{
                                            width: 24,
                                            height: 24,
                                            color: Color.primary[400]
                                        }}
                                    />
                                    <Typography
                                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                        size={14}
                                        color={Color.neutral[500]}
                                        style={{ marginLeft: 5 }}
                                    >
                                        {system.dbName}
                                    </Typography>
                                </div>
                            </Grid>
                        }
                        {system.b1SystemId &&
                            <Grid item md={12}>
                                <div style={{ display: "inline-flex" }}>
                                    <CPU
                                        style={{
                                            width: 24,
                                            height: 24,
                                            color: Color.primary[400]
                                        }}
                                    />
                                    <Typography
                                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                        size={14}
                                        color={Color.neutral[500]}
                                        style={{ marginLeft: 5 }}
                                    >
                                        {system.b1SystemId}
                                    </Typography>
                                </div>
                            </Grid>
                        } */}

                        <Grid item md={12} xs={12}>
                            <div style={{ marginTop: isMobile ? 0 : 45, display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                                {functions.map((app, index) => (
                                    <>
                                        <div className={classes.aplicationCard}>
                                            <ApplicationCard
                                                application={app}
                                                onClick={handleClickApplicationCard(app)}
                                                setActiveApplication={setActiveApplication}
                                                setDeactivateApplicationDialogOpen={setDeactivateApplicationDialogOpen}
                                                activateApplication={activateApplication}
                                            />
                                        </div>
                                        <Hidden xsDown>
                                            {index === functions.length / 3 && (<div style={{ flexBasis: "100%", height: 0 }} />)}
                                        </Hidden>
                                    </>
                                ))}
                            </div>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: isMobile ? 'center' : 'flex-start', marginTop: isMobile ? 25 : 140 }}>
                                <div
                                    style={{
                                        width: 16,
                                        height: 16,
                                        backgroundColor: Color.primary[400]
                                    }}
                                />
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                    size={12}
                                    color={Color.neutral[900]}
                                    style={{ marginLeft: 5 }}
                                >
                                    {`- Active`}
                                </Typography>

                                <div
                                    style={{
                                        width: 16,
                                        height: 16,
                                        backgroundColor: Color.neutral[500],
                                        marginLeft: 60
                                    }}
                                />
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                    size={12}
                                    color={Color.neutral[900]}
                                    style={{ marginLeft: 5 }}
                                >
                                    {`- Inactive`}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>

            {/* <div className={classes.systemDashboardMaximizedSystemLog}>
                <div className={classes.systemDashboardTopbar}>
                    <Grid container style={{ flexGrow: 1 }} spacing={2}>
                        <Grid item md={12}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={20}
                                color={Color.neutral[100]}
                            >
                                Device Log
                        </Typography>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ width: "100%", marginTop: -10 }}>
                    <MaximizedSystemLog logs={deviceLog} handleChangeDebug={handleChangeIsDebugModeList} />
                </div>
            </div> */}

            <div className={classes.systemDashboardSystemInfo}>
                <SystemInfo system={system} />
            </div>

            {activeApplication && (
                <DeactivateApplication
                    open={deactivateApplicationDialogOpen}
                    onClose={handleCloseDeactivateApplicationDialog}
                    application={activeApplication}
                    deactivateApplication={deactivateApplication}
                />
            )}

            <UserRoleDialog
                open={userRoleDialogOpen}
                onClose={handleCloseUserDialog}
                functions={functions}
                userRoles={userRoles}
                handleCreateNewRole={handleCreateUserRole}
                handleEditRole={handleEditUserRole}
                handleDeleteRole={handleDeleteUserRole}
            />

            <InputUserRole
                open={inputUserRoleDialogOpen}
                onClose={handleCloseInputUserRoleDialog}
                currentUserData={currentUserData}
                functions={functions}
                userRole={inspectedUserRole == null ? { system } : inspectedUserRole}
                getUserRoles={getUserRoles}
            />

            <ConfirmDeleteUserRole
                open={confirmDeleteUserRoleDialogOpen}
                onClose={handleCloseConfirmDeleteUserRoleDialog}
                currentUserData={currentUserData}
                userRole={toBeDeletedUserRole}
                getUserRoles={getUserRoles}
            />
        </>
    )
};

export default SystemDashboard;