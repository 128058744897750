import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../App';

import { Grid, Hidden } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Breadcrumb, Typography, FONT_FAMILY, Color, Form, Separator, TotalCost, Button } from '@otg-one/ui_components';

import { useStyles } from './BillingStyles';
import BillingHistory from './BillingHistory';
import BillingService from './BillingService';
import PartnerService from '../Partner/PartnerService';
import Store from './Store';

const BILLING_DATA = [
    {
        date: "1 Jan 2019",
        totalUsers: 315,
        paid: true,
        currency: "€",
        price: "12,285",
        clients: [
            {
                name: "First Company",
                systems: [
                    {
                        name: "System A",
                        totalUsers: 50,
                        price: "1,950"
                    },
                    {
                        name: "System B",
                        totalUsers: 42,
                        price: "1,218"
                    }
                ]
            },
            {
                name: "Second Company",
                systems: [
                    {
                        name: "System A",
                        totalUsers: 50,
                        price: "1,950"
                    },
                    {
                        name: "System B",
                        totalUsers: 42,
                        price: "1,218"
                    }
                ]
            },
            {
                name: "Company Omega",
                systems: [
                    {
                        name: "System Alpha",
                        totalUsers: 100,
                        price: "2,900"
                    },
                    {
                        name: "System Beta",
                        totalUsers: 31,
                        price: "899"
                    }
                ]
            }
        ]
    },
    {
        date: "1 Feb 2019",
        totalUsers: 551,
        paid: true,
        currency: "€",
        price: "21,489",
        clients: [
            {
                name: "First Company",
                systems: [
                    {
                        name: "System A",
                        totalUsers: 50,
                        price: "1,950"
                    },
                    {
                        name: "System B",
                        totalUsers: 42,
                        price: "1,218"
                    }
                ]
            },
            {
                name: "Second Company",
                systems: [
                    {
                        name: "System A",
                        totalUsers: 50,
                        price: "1,950"
                    },
                    {
                        name: "System B",
                        totalUsers: 42,
                        price: "1,218"
                    }
                ]
            },
            {
                name: "Company Omega",
                systems: [
                    {
                        name: "System Alpha",
                        totalUsers: 100,
                        price: "2,900"
                    },
                    {
                        name: "System Beta",
                        totalUsers: 31,
                        price: "899"
                    }
                ]
            }
        ]
    },
    {
        date: "1 Mar 2019",
        totalUsers: 315,
        paid: true,
        currency: "€",
        price: "21,801",
        clients: [
            {
                name: "First Company",
                systems: [
                    {
                        name: "System A",
                        totalUsers: 50,
                        price: "1,950"
                    },
                    {
                        name: "System B",
                        totalUsers: 42,
                        price: "1,218"
                    }
                ]
            },
            {
                name: "Second Company",
                systems: [
                    {
                        name: "System A",
                        totalUsers: 50,
                        price: "1,950"
                    },
                    {
                        name: "System B",
                        totalUsers: 42,
                        price: "1,218"
                    }
                ]
            },
            {
                name: "Company Omega",
                systems: [
                    {
                        name: "System Alpha",
                        totalUsers: 100,
                        price: "2,900"
                    },
                    {
                        name: "System Beta",
                        totalUsers: 31,
                        price: "899"
                    }
                ]
            }
        ]
    },
    {
        date: "1 Apr 2019",
        totalUsers: 8000,
        paid: true,
        currency: "€",
        price: "312,000",
        clients: [
            {
                name: "First Company",
                systems: [
                    {
                        name: "System A",
                        totalUsers: 50,
                        price: "1,950"
                    },
                    {
                        name: "System B",
                        totalUsers: 42,
                        price: "1,218"
                    }
                ]
            },
            {
                name: "Second Company",
                systems: [
                    {
                        name: "System A",
                        totalUsers: 50,
                        price: "1,950"
                    },
                    {
                        name: "System B",
                        totalUsers: 42,
                        price: "1,218"
                    }
                ]
            },
            {
                name: "Company Omega",
                systems: [
                    {
                        name: "System Alpha",
                        totalUsers: 100,
                        price: "2,900"
                    },
                    {
                        name: "System Beta",
                        totalUsers: 31,
                        price: "899"
                    }
                ]
            }
        ]
    }
];

const Billing = (props: any) => {
    const classes = useStyles(props);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const {
        breadcrumb,
        currentUserData,
        totalCost,
        setTotalCost
    } = useContext(AppContext);

    const [billingOrgName, setBillingOrgName] = useState(currentUserData.billingOrgName)
    const [billingContactName, setBillingContactName] = useState(currentUserData.billingContactName)
    const [billingContactMail, setBillingContactMail] = useState(currentUserData.billingContactMail)
    const [tokenizationKeys, setTokenizationKeys] = useState("");
    const [dropInCallback, setDropInCallback] = useState({})
    const [message, setMessage] = useState("")

    const [errorBillingOrgName, setErrorBillingOrgName] = useState(false)
    const [errorMsgBillingOrgName, setErrorMsgBillingOrgName] = useState("")
    const [errorBillingContactName, setErrorBillingContactName] = useState(false)
    const [errorMsgBillingContactName, setErrorMsgBillingContactName] = useState("")
    const [errorBillingContactMail, setErrorBillingContactMail] = useState(false)
    const [errorMsgBillingContactMail, setErrorMsgBillingContactMail] = useState("")

    const handleChangeBillingOrgName = (e: React.FormEvent<HTMLInputElement>) => {
        setErrorBillingOrgName(false);
        setErrorMsgBillingOrgName("");
        setBillingOrgName(e.currentTarget.value);
    };

    const handleChangeBillingContactMail = (e: React.FormEvent<HTMLInputElement>) => {
        setErrorBillingContactMail(false);
        setErrorMsgBillingContactMail("");
        setBillingContactMail(e.currentTarget.value);
    };

    const handleChangeBillingContactName = (e: React.FormEvent<HTMLInputElement>) => {
        setErrorBillingContactName(false);
        setErrorMsgBillingContactName("");
        setBillingContactName(e.currentTarget.value);
    };

    const registerChildCallback = (dropInCallback: any) => {
        setDropInCallback({ dropInCallback });
    };

    const validateInputs = () => {
        let error = false;
        if (billingContactName.length < 1) {
            setErrorBillingContactName(true);
            setErrorMsgBillingContactName("Please enter a billing contact name!");
            error = true;
        } else if (billingContactName.indexOf(' ') < 0) {
            setErrorBillingContactName(true);
            setErrorMsgBillingContactName("Please enter a full billing contact name with first and last name!");
            error = true;
        }
        if (billingOrgName.length < 1) {
            setErrorBillingOrgName(true);
            setErrorMsgBillingOrgName("Please enter a company name!");
            error = true;
        }
        if (billingContactMail.length < 1) {
            setErrorBillingContactMail(true);
            setErrorMsgBillingContactMail("Please enter a billing email address!");
            error = true;
        } else if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(billingContactMail))) {
            setErrorBillingContactMail(true);
            setErrorMsgBillingContactMail("Please enter a valid billing email address!");
            error = true;
        }
        if (error) {
            setMessage("Please check your entered values!");
            return false;
        }
        return true;
    };

    const handleUploadBillingInformation = () => {
        if (!validateInputs()) {
            return;
        }
        PartnerService.updateBillingDetail(currentUserData, billingOrgName, billingContactName, billingContactMail).then((res: any) => {
            if (res.status === 200) {
                currentUserData.billingOrgName = billingOrgName;
                currentUserData.billingContactName = billingContactName;
                currentUserData.billingContactMail = billingContactMail;
                BillingService.uploadBillingInformation(currentUserData.key, { billingOrgName, billingContactName, billingContactMail, dropInCallback }).then((res: any) => {
                    setMessage("Billing details successfully updated!");
                }).catch((err: any) => {
                    setMessage("Something went wrong when updating billing details!");
                });
            }
        }, (error: any) => {
            setMessage("Something went wrong when updating billing details!");
        });
    };

    const handleUpdateBillingDetail = () => {
        if (!validateInputs()) {
            return;
        }
        PartnerService.updateBillingDetail(currentUserData, billingOrgName, billingContactName, billingContactMail).then((res: any) => {
            if (res.status === 200) {
                currentUserData.billingOrgName = billingOrgName;
                currentUserData.billingContactName = billingContactName;
                currentUserData.billingContactMail = billingContactMail;
                setMessage("Billing details successfully updated!");
            } else {
                setMessage("Something went wrong when updating billing details!");
            }
        });
    };

    const getClientToken = () => {
        let clientToken = BillingService.getClientToken(currentUserData.key);
        clientToken.then((res: any) => {
            setTokenizationKeys(res.data.clientToken);
        }).catch((err: any) => {
            console.error(err);
        });
    };


    useEffect(() => {
        if (Object.keys(currentUserData).length !== 0) {
            const page = breadcrumb[breadcrumb.length - 1];

            if (page && page.path.split("#")[1] === 'billingHistory') {
                const offsetTop = document.getElementById('billingHistory')!.offsetTop

                window.scrollTo({
                    top: offsetTop - 126, behavior: "smooth"
                })
            }
            getClientToken();

            PartnerService.getTotalCost(currentUserData.key).then((res: any) => {
                setTotalCost(res.data);
            });
        }
    }, [currentUserData])

    return (
        <>
            <div className={classes.billingContainer}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <Typography
                            fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                            size={20}
                            color={Color.neutral[900]}
                        >
                            Billing Detail
                        </Typography>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Grid container spacing={3} style={{ alignItems: "center" }}>
                            <Grid item md={4} xs={12}>
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                    size={14}
                                    color={Color.neutral[600]}
                                >
                                    Organization Name
                                </Typography>
                            </Grid>
                            <Grid item md={8} xs={12}>
                                <Form
                                    color="grey"
                                    placeholder="Organization Name"
                                    required
                                    value={billingOrgName}
                                    onChange={handleChangeBillingOrgName}
                                    error={errorBillingOrgName}
                                    errorMessage={errorMsgBillingOrgName}
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Separator type="n200" />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                    size={14}
                                    color={Color.neutral[600]}
                                >
                                    Contact Name
                                </Typography>
                            </Grid>
                            <Grid item md={8} xs={12}>
                                <Form
                                    color="grey"
                                    placeholder="Contact Name"
                                    value={billingContactName}
                                    onChange={handleChangeBillingContactName}
                                    error={errorBillingContactName}
                                    errorMessage={errorMsgBillingContactName}
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Separator type="n200" />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                    size={14}
                                    color={Color.neutral[600]}
                                >
                                    Billing Email
                                </Typography>
                            </Grid>
                            <Grid item md={8} xs={12}>
                                <Form
                                    color="grey"
                                    placeholder="Billing Email"
                                    value={billingContactMail}
                                    onChange={handleChangeBillingContactMail}
                                    error={errorBillingContactMail}
                                    errorMessage={errorMsgBillingContactMail}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                    size={14}
                                    color={Color.neutral[600]}
                                >

                                </Typography>
                            </Grid>
                            <Grid item md={8} xs={12}>
                                <div className={classes.saveButton}>
                                    <Button
                                        onClick={handleUpdateBillingDetail}
                                        style={{
                                            width: isMobile ? '-webkit-fill-available' : 'auto',
                                            justifyContent: isMobile ? 'center' : 'flex-start'
                                        }}
                                    >
                                        Save Changes
                                    </Button>
                                </div>
                            </Grid>

                            <Grid item md={12} xs={12}>
                                <div
                                    style={{
                                        boxSizing: "border-box",
                                        padding: 10,
                                        fontFamily: FONT_FAMILY.ROBOTO_MEDIUM,
                                        color: message === "Billing details successfully updated!" ? Color.success[500] : Color.danger[300],
                                        fontSize: 16,
                                        textAlign: "center"
                                    }}
                                >
                                    {message}
                                </div>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Separator type="n200" />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                    size={14}
                                    color={Color.neutral[600]}
                                >
                                    Price per Mobile Device
                                </Typography>
                            </Grid>
                            <Grid item md={8} xs={12} style={{ display: "inline-flex" }}>
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                    size={16}
                                    color={Color.neutral[500]}
                                    style={{ paddingRight: 5, minWidth: 32 }}
                                >
                                    35 €
                                </Typography>
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                    size={14}
                                    color={Color.neutral[600]}
                                >
                                    per device for the first 100 active devices in a month
                                </Typography>
                            </Grid>
                            <Hidden smDown>
                                <Grid item md={4} >
                                </Grid>
                            </Hidden>
                            <Grid item md={8} xs={12} style={{ display: "inline-flex" }}>
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                    size={16}
                                    color={Color.neutral[500]}
                                    style={{ paddingRight: 5, minWidth: 32 }}
                                >
                                    29 €
                                </Typography>
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                    size={14}
                                    color={Color.neutral[600]}
                                >
                                    per device for more than 100 active devices in a month
                                </Typography>
                            </Grid>
                            <Hidden smDown>
                                <Grid item md={4}>
                                </Grid>
                            </Hidden>
                            <Grid item md={8} xs={12} style={{ display: "inline-flex" }}>
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                    size={16}
                                    color={Color.neutral[500]}
                                    style={{ paddingRight: 5, minWidth: 32 }}
                                >
                                    19 €
                                </Typography>
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                    size={14}
                                    color={Color.neutral[600]}
                                >
                                    per device for more than 250 active devices in a month
                                </Typography>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Separator type="n200" />
                            </Grid>
                            <Grid item md={4} >
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                    size={14}
                                    color={Color.neutral[600]}
                                >
                                    Next Payment
                                </Typography>
                            </Grid>
                            <Grid item md={8} xs={12}>
                                <TotalCost totalCost={totalCost} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.billingPaymentContainer}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12} style={{ marginTop: 25 }}>
                        <Typography
                            fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                            size={20}
                            color={Color.neutral[900]}
                        >
                            Payment Detail
                        </Typography>
                    </Grid>

                    <Grid item md={8} xs={12} style={{ marginTop: 0 }}>
                        <Grid container >
                            <Store tokenizationKeys={tokenizationKeys} registerDropInCallback={registerChildCallback}>
                            </Store>
                        </Grid>
                        <Grid item md={4} xs={12} style={{ marginTop: 25 }}>
                            <Button
                                onClick={handleUploadBillingInformation}
                                style={{
                                    width: isMobile ? '-webkit-fill-available' : 'auto',
                                    justifyContent: isMobile ? 'center' : 'flex-start'
                                }}
                            >
                                Update Payment Method
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            { /* <div id="billingHistory" className={classes.billingContainer}>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Typography
                            fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                            size={20}
                            color={Color.neutral[900]}
                        >
                            Billing History
                        </Typography>
                    </Grid>
                    <Grid item md={10}>
                        {BILLING_DATA.map((billing: any) => (
                            <BillingHistory data={billing} />
                        ))}
                        </Grid>
                </Grid>
            </div> */ }
        </>
    )
};

export default Billing;