// React libraries
import * as React from 'react'; // Custom Styles

import { useStyles } from "./BlueLinkStyles"; // Material-UI Core

import Link from '@material-ui/core/Link';
export var BlueLink = function BlueLink(props) {
  var classes = useStyles(props);
  var onClick = props.onClick;
  return React.createElement(React.Fragment, null, props.rightIcon ? React.createElement(Link, {
    className: classes.blueLink,
    onClick: onClick
  }, React.createElement("p", null, props.children, " ", React.createElement(props.rightIcon, {
    className: classes.rightIcon
  }))) : React.createElement(Link, {
    className: classes.blueLink,
    onClick: onClick
  }, React.createElement("p", null, props.children)));
};
BlueLink.defaultProps = {
  onClick: function onClick() {}
};
export default React.memo(BlueLink);