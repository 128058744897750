import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// React libraries
import * as React from 'react';
import classnames from 'classnames'; // Custom Styles

import { useStyles } from "./GreyButtonStyles"; // Material-UI Core

import Button from '@material-ui/core/Button';
export var GreyButton = function GreyButton(props) {
  var _classnames;

  var classes = useStyles(props);
  var onClick = props.onClick,
      disabled = props.disabled,
      selected = props.selected,
      type = props.type;
  var greyButtonClasses = classnames((_classnames = {}, _defineProperty(_classnames, classes.greyButton, true), _defineProperty(_classnames, classes.greyButtonLeft, type === "left"), _defineProperty(_classnames, classes.greyButtonRight, type === "right"), _defineProperty(_classnames, classes.greyButtonSelected, selected), _defineProperty(_classnames, classes.greyButtonDisabled, disabled), _classnames));
  return React.createElement(Button, {
    className: greyButtonClasses,
    disabled: disabled,
    onClick: onClick
  }, props.children);
};
export default React.memo(GreyButton);