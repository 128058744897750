import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./PickPackConfirmItemPageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { ScanContentBar } from "../ScanContentBar";
import EditIcon from '@material-ui/icons/Edit';
import { WhiteContentBar } from "../WhiteContentBar";
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
export var PickPackConfirmItemPage = function PickPackConfirmItemPage(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      data = props.data,
      scanContentBarText = props.scanContentBarText,
      isConfirmAllExist = props.isConfirmAllExist;

  var _React$useState = React.useState(data),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      listData = _React$useState2[0],
      setListData = _React$useState2[1];

  React.useEffect(function () {
    setListData(data);
  }, [data]);
  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "smallWithSub",
    showScanner: true,
    previewHeight: previewHeight
  }, React.createElement("p", null, "Pick and Pack"), React.createElement("sub", null, "Order - ", React.createElement("b", null, "2286")))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentTopRadius,
    style: {
      height: previewHeight - 90 - 50 + 10
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "Item / Batch",
      rightContent: "B10000 / B1-00078"
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(ScanContentBar, {
    text: scanContentBarText,
    applyBorderRadius: false
  })), isConfirmAllExist && React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.confirmAllButton
  }, "Confirm All"), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.listItemsDiv,
    style: {
      height: previewHeight - 90 - 40 - 40 - 40 - (isConfirmAllExist ? 33 : 0)
    }
  }, React.createElement(Grid, {
    container: true,
    className: classes.listItemsContainer
  }, listData.map(function (data) {
    return React.createElement(Button, {
      className: classes.listItemsButton
    }, React.createElement(Grid, {
      item: true,
      md: 12
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      item: true,
      md: 10
    }, React.createElement(Grid, {
      container: true,
      alignItems: "center"
    }, React.createElement(Grid, {
      item: true,
      md: 3,
      className: classes.listItemsButtonQuantity
    }, data.quantity + " pc"), React.createElement(Grid, {
      item: true,
      md: 2
    }, React.createElement(Grid, {
      container: true,
      justifyContent: "center"
    }, React.createElement(LabelOutlinedIcon, null))), React.createElement(Grid, {
      item: true,
      md: 6
    }, React.createElement(Grid, {
      item: true,
      md: 5,
      className: classes.listItemsButtonBoxTitle
    }, "Box"), React.createElement(Grid, {
      item: true,
      md: 5,
      className: classes.listItemsButtonBox
    }, data.box1Value), React.createElement(Grid, {
      item: true,
      md: 5,
      className: classes.listItemsButtonBox
    }, data.box2Value)))), React.createElement(Grid, {
      container: true,
      item: true,
      md: 2,
      alignItems: "center",
      justify: "flex-end",
      style: {
        color: "#2d9dd5"
      }
    }, React.createElement(EditIcon, null)))));
  }))))), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "left"
  }, "Back")), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "right"
  }, "Next"))));
};
PickPackConfirmItemPage.defaultProps = {
  scanContentBarText: "Please scan box..."
};
export default React.memo(PickPackConfirmItemPage);