import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// React libraries
import React from 'react';
import classnames from 'classnames'; // Component Style

import { useStyles } from "./SeparatorStyles";
export var Separator = function Separator(props) {
  var _classnames;

  var classes = useStyles(props);
  var type = props.type,
      width = props.width,
      marginTop = props.marginTop,
      marginBottom = props.marginBottom;
  var separatorClasses = classnames((_classnames = {}, _defineProperty(_classnames, classes.separator, true), _defineProperty(_classnames, classes[type], type), _classnames));
  return React.createElement("hr", {
    className: separatorClasses,
    style: {
      marginTop: marginTop,
      marginBottom: marginBottom,
      borderTopWidth: width
    }
  });
};
Separator.defaultProps = {
  type: "n400",
  width: 1,
  marginTop: "0px",
  marginBottom: "0px"
};
export default React.memo(Separator);