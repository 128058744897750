import React from 'react';

// Components
import {
    Banner,
    Typography,
    FONT_FAMILY,
    Color,
    Button,
    DialogBox,
} from '@otg-one/ui_components';

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const DeactivateApplication = (props: any) => {
    const { application, open, onClose, deactivateApplication } = props;

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    // const [confirmationText, setConfirmationText] = useState("")
    // const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true)

    // useEffect(() => {
    //     if (confirmationText === `DELETE-CLIENT-${convertToUpperCase(client.name)}`) {
    //         setIsDeleteButtonDisabled(false)
    //     }
    //     else {
    //         setIsDeleteButtonDisabled(true)
    //     }
    // }, [confirmationText])

    // const convertToUpperCase = (string: string) => {
    //     return string.replace(/ /g, `-`).toUpperCase()
    // }

    // const handleChangeConfirmationText = (e: any) => {
    //     setConfirmationText(e.target.value)
    // }

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title="Deactivate Application"
            content={
                <div style={{ maxWidth: isMobile ? 'auto' : 630, paddingTop: 16 }}>
                    <Typography
                        fontFamily={FONT_FAMILY.CAPITOLIUM_ITALIC}
                        size={16}
                        color={Color.neutral[900]}
                        style={{ marginBottom: 13 }}
                    >
                        {`Proceed deactivating "${application.name}"?`}
                    </Typography>
                    <Banner maxWidth="100%">
                        {`${application.name} application will be deactivated!`}
                    </Banner>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: 45 }}>
                        <Button type="secondary" onClick={onClose}>Cancel</Button>
                        <Button style={{ marginLeft: 16 }} onClick={() => deactivateApplication(application)}>Deactivate Application</Button>
                    </div>
                </div>
            }
        />
    )
};

export default DeactivateApplication;