import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  var _topNavbar, _topNavbarChildDropdo;

  return createStyles({
    topNavbarContainer: _defineProperty({
      position: "fixed",
      width: "100%",
      top: 0,
      zIndex: 1100
    }, theme.breakpoints.down('sm'), {
      zIndex: 1500
    }),
    topNavbar: (_topNavbar = {
      display: "flex",
      alignItems: "center"
    }, _defineProperty(_topNavbar, theme.breakpoints.up('md'), {
      paddingLeft: "5%",
      paddingRight: "5%"
    }), _defineProperty(_topNavbar, theme.breakpoints.up('lg'), {
      paddingLeft: "10%",
      paddingRight: "10%"
    }), _defineProperty(_topNavbar, theme.breakpoints.down('md'), {
      paddingLeft: "5%",
      paddingRight: "5%"
    }), _defineProperty(_topNavbar, "height", "64px"), _defineProperty(_topNavbar, "background", Color.gradient[100]), _topNavbar),
    topNavbarIcon: {
      marginRight: "100px"
    },
    topNavbarLogo: {
      width: 75,
      height: 37,
      cursor: "pointer"
    },
    topNavbarChildContainer: _defineProperty({
      height: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexGrow: 1,
      justifyContent: "flex-end"
    }, theme.breakpoints.down('sm'), {
      display: 'none'
    }),
    topNavbarSettingsIconContainer: _defineProperty({
      width: 60,
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 10,
      cursor: "pointer"
    }, theme.breakpoints.down('sm'), {
      width: 'auto'
    }),
    topNavbarSettingsIcon: {
      width: 32,
      height: 32
    },
    topNavbarAccountImage: _defineProperty({
      width: 32,
      height: 32,
      borderRadius: "50%",
      // backgroundColor: Color.primary[100],
      marginRight: 24,
      cursor: "pointer"
    }, theme.breakpoints.down('sm'), {
      marginRight: 10
    }),
    topNavbarAvatarImage: _defineProperty({
      width: 32,
      height: 32,
      marginRight: 24,
      color: Color.primary[100],
      cursor: "pointer"
    }, theme.breakpoints.down('sm'), {
      marginRight: 10
    }),
    topNavbarChildDropdown: (_topNavbarChildDropdo = {
      position: "absolute",
      backgroundColor: Color.neutral[0],
      boxSizing: "border-box"
    }, _defineProperty(_topNavbarChildDropdo, theme.breakpoints.up('md'), {
      paddingLeft: "5%",
      paddingRight: "5%"
    }), _defineProperty(_topNavbarChildDropdo, theme.breakpoints.up('lg'), {
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: 24
    }), _defineProperty(_topNavbarChildDropdo, "width", "100%"), _defineProperty(_topNavbarChildDropdo, "height", 145), _defineProperty(_topNavbarChildDropdo, "boxShadow", "0px 20px 29px 0px rgba(0, 0, 0, 0.15)"), _topNavbarChildDropdo),
    topNavbarMenuDropdown: _defineProperty({
      display: "none",
      height: "100%"
    }, theme.breakpoints.down('sm'), {
      display: "inline-flex",
      alignItems: "center",
      flexGrow: 1,
      justifyContent: "flex-end"
    }),
    drawer: {
      width: '100%',
      flexShrink: 0
    },
    drawerPaper: {
      width: '100%'
    },
    anchorTop: {
      top: '64px !important'
    },
    drawerList: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flexGrow: 1,
      justifyContent: "center"
    },
    drawerChild: {
      width: '90%',
      padding: "18px 0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "roboto-medium",
      fontSize: "14px",
      margin: "auto",
      "&:active": {
        borderColor: Color.primary[200],
        backgroundColor: Color.neutral[300]
      }
    },
    separator: {
      width: '90%',
      margin: 'auto'
    }
  });
});