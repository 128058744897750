import _objectSpread from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./InventoryTransferPositionDetailsPageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { WhiteContentBar } from "../../index";
import { WhiteContentBarButton } from "../../index";
import { KeyboardNumberModal } from "../../index";
export var InventoryTransferPositionDetailsPage = function InventoryTransferPositionDetailsPage(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      data = props.data;

  var _React$useState = React.useState(data),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      positionDetailData = _React$useState2[0],
      setPositionDetailData = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      isKeyboardQuantityModalOpen = _React$useState4[0],
      setIsKeyboardQuantityModalOpen = _React$useState4[1];

  var _React$useState5 = React.useState(""),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      quantity = _React$useState6[0],
      setQuantity = _React$useState6[1];

  var _React$useState7 = React.useState(false),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      isConfirmed = _React$useState8[0],
      setIsConfirmed = _React$useState8[1];

  React.useEffect(function () {
    setPositionDetailData(_objectSpread(_objectSpread({}, positionDetailData), {}, {
      coveredQuantity: parseInt(quantity) ? parseInt(quantity) : 0
    }));

    if (quantity !== "") {
      setIsConfirmed(true);
    } else {
      setIsConfirmed(false);
    }
  }, [quantity]);
  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "small",
    showScanner: true,
    previewHeight: previewHeight
  }, React.createElement("p", null, "Inventory Transfer"))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentTopRadius,
    style: {
      height: previewHeight - 75 - 50 + 10
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "Item",
      rightContent: positionDetailData.item
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "Description",
      rightContent: positionDetailData.description
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "Covered Quantity",
      rightContent: positionDetailData.coveredQuantity + " " + positionDetailData.coveredQuantityType
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBar, {
    data: {
      leftContent: "From Warehouse",
      rightContent: positionDetailData.fromWarehouse
    }
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.greyContent,
    style: {
      height: previewHeight - 75 - 50 - 160 + 10
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBarButton, {
    data: {
      leftContent: "Enter Quantity *",
      leftContentWithRightContent: "Quantity",
      rightContent: quantity
    },
    onClick: function onClick() {
      setIsKeyboardQuantityModalOpen(!isKeyboardQuantityModalOpen);
    }
  })))))), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "left"
  }, "Cancel")), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "right",
    disabled: !isConfirmed
  }, "Next"))), React.createElement(KeyboardNumberModal, {
    title: "Quantity",
    initialValue: quantity,
    isModalOpen: isKeyboardQuantityModalOpen,
    setInitialValue: setQuantity,
    setOpenModalState: setIsKeyboardQuantityModalOpen
  }));
};
export default React.memo(InventoryTransferPositionDetailsPage);