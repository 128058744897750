import React from 'react';

// Components
import {
    DialogBox,
    Button,
    Typography,
    FONT_FAMILY,
    Color,
    ArrowDown
} from '@otg-one/ui_components'

import Grid from '@material-ui/core/Grid';

import { useTheme } from '@material-ui/core/styles';
import {useMediaQuery} from '@material-ui/core'




const RestrictedCockpitDialog = (props: any) => {
    
    const {open, onOK, onCancel} = props
    
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

    return(
        <DialogBox
        open={open}
        handleClose={onOK}
        title="Restricted Cockpit Mode"
        content={
           <div style={{ maxWidth:700, overflow:'hidden' }}>
               <Grid item xs={12} > 
                <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                        size={14}
                        color={Color.neutral[900]}
                        style={{ maxWidth:650, paddingTop:20 }}
                    >
                    {`The restricted cockpit mode is active due to the display size used. For full functionality, please open the cockpit on a desktop.`}
                </Typography>
                {/* <div style={{ alignItems:'flex-end', paddingTop:40, paddingBottom:10, position:'relative', }}>
                    <div style={{ display:'inline-flex' }}>
                        <Button type="secondary" style={{ float:'left' }} onClick={onCancel}>
                            {`Cancel`}
                        </Button>
                        <Button style={{ outline: 'none',float:'right' }} onClick={onOK}>
                            {`OK`}
                        </Button>
                    </div>
                </div> */}
                <Button type="secondary" style={{ float:'left', paddingTop:10 }} onClick={onCancel}>
                            {`Cancel`}
                        </Button>
                        <Button style={{ outline: 'none',float:'right' }} onClick={onOK}>
                            {`OK`}
                        </Button>
                </Grid>      
           </div>
        }
    />
    )
}


export default RestrictedCockpitDialog;


