// React libraries
import React from "react"; // Component Styles

import { useStyles } from "./EmptyChartDataStyles"; // Material UI Core

import Grid from '@material-ui/core/Grid'; // Icon

import NoChart from "../../icons/NoChart/NoChart";
export var EmptyChartData = function EmptyChartData(props) {
  var classes = useStyles(props);
  return React.createElement(Grid, {
    container: true,
    alignItems: "center",
    justify: "center",
    className: classes.noChartWrapper
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12,
    container: true,
    justify: "center",
    alignItems: "center"
  }, React.createElement(NoChart, {
    className: classes.noChartIcon
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    container: true,
    justify: "center",
    alignItems: "center"
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12,
    container: true,
    justify: "center",
    alignItems: "center"
  }, React.createElement("span", {
    className: classes.noChartContent
  }, "Not enough data to show"))), React.createElement(Grid, {
    item: true,
    md: 12,
    container: true,
    justify: "center",
    alignItems: "center"
  }, React.createElement("span", {
    className: classes.noChartContentSmall
  }, "Please use our system for at least a month to see your first monthly chart"))))));
};
export default React.memo(EmptyChartData);