import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./InventoryCountingItemSelectionPageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { WhiteContentBarButton } from "../../index";
import { KeyboardCompleteModal } from "../../index"; // Icon

import Barcode from "../../../icons/Barcode/Barcode";
export var InventoryCountingItemSelectionPage = function InventoryCountingItemSelectionPage(props) {
  var classes = useStyles(props);

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      isKeyboardItemNumberModalOpen = _React$useState2[0],
      setIsKeyboardItemNumberModalOpen = _React$useState2[1];

  var _React$useState3 = React.useState(""),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      itemNumber = _React$useState4[0],
      setItemNumber = _React$useState4[1];

  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      inputTypes = props.inputTypes;
  var showScanner;

  if (inputTypes.includes("barcodeScan")) {
    showScanner = true;
  } else {
    showScanner = false;
  } // Close Dialog On Option Change


  React.useEffect(function () {
    setIsKeyboardItemNumberModalOpen(false);
  }, [inputTypes]);
  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "smallWithSub",
    showScanner: showScanner,
    previewHeight: previewHeight
  }, React.createElement("p", null, "Inventory Counting"), React.createElement("sub", null, "Bin Location - ", React.createElement("b", null, "05-G01-R01-P01")))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentTopRadius,
    style: {
      height: previewHeight - 90 - 50 + 10
    }
  }, React.createElement(Grid, {
    container: true,
    className: classes.contentWrapper
  }, React.createElement(Grid, {
    item: true,
    md: 12,
    style: {
      fontSize: "14px"
    }
  }, React.createElement("p", null, "Scan Item")), inputTypes.includes("barcodeScan") && React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(Barcode, {
    className: classes.scannerIcon
  })), inputTypes.includes("textInput") && React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBarButton, {
    backgroundColor: "grey",
    data: {
      leftContent: "Enter Manually",
      leftContentWithRightContent: "Enter Manually",
      rightContent: itemNumber
    },
    onClick: function onClick() {
      setIsKeyboardItemNumberModalOpen(!isKeyboardItemNumberModalOpen);
    }
  }))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.greyContentBar
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    className: classes.greyLeftContent,
    item: true,
    md: 8
  }, React.createElement("p", null, "Item")), React.createElement(Grid, {
    className: classes.greyCenterContent,
    item: true,
    md: 4
  }, React.createElement("p", null, "Counted Quantity")))), React.createElement(Grid, {
    className: classes.greyCenterContent,
    item: true,
    md: 12
  }, React.createElement("p", {
    style: {
      marginTop: "20px"
    }
  }, "No Position Available"))), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "left"
  }, "Cancel")), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "right",
    disabled: false
  }, "Add"))), React.createElement(KeyboardCompleteModal, {
    title: "Enter Item Number",
    isModalOpen: isKeyboardItemNumberModalOpen,
    initialValue: itemNumber,
    setInitialValue: setItemNumber,
    setOpenModalState: setIsKeyboardItemNumberModalOpen
  }));
};
export default React.memo(InventoryCountingItemSelectionPage);