// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Color } from '@otg-one/ui_components';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dashboardContainer: {
            [theme.breakpoints.up('xs')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
            },
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
            }
        },
        dashboardClientList: {
            [theme.breakpoints.up('xs')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
            },
            [theme.breakpoints.up('md')]: {
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "1%"
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%"
            },
            marginTop: 32,
            minHeight: 600,
            backgroundColor: Color.neutral[100]
        },
        dashboardClientListTopbar: {
            paddingTop: 24,
            marginBottom: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },
        dashboardClientListTopSearchbar: {
            width: "100%",
            display: "inline-flex",
            alignItems: "center"
        },

        iconContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 28,
            height: 28,
            background: 'transparent',
            borderRadius: '50%',
            transition: 'all 0.3s ease',
            "& svg": {
                cursor: "pointer",
                width: 24,
                height: 24,
            },
            "&:hover": {
                background: Color.primary[100],
                transition: 'all 0.3s ease',
            }
        },

        customerCostAndLoginChart: {
            position: "relative",
            width: "100%",
            height: 435,
            backgroundColor: Color.neutral[100],
            boxSizing: "border-box",
            padding: "16px 24px 16px 24px",

            [theme.breakpoints.down('xs')]: {
                padding: '10px 15px',

                '& > div:nth-child(2)': {
                    marginTop: '50px !important'
                }
            },
        },

        chartDropdown: {
            position: "absolute",
            top: 16,
            right: 16,

            [theme.breakpoints.down('xs')]: {
                top: 40,
                right: 10,
            },
        }
    })
)