// React lib
import React, { useState, useEffect, memo } from 'react'

// Components
import {
    Typography,
    Chart,
    FONT_FAMILY,
    Color,
} from '@otg-one/ui_components'

const CountryRankBar = (props: any) => {
    const { data, maxDevice } = props

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                    size={12}
                    color={Color.neutral[900]}
                >
                    {data.countryName}
                </Typography>
                <Typography
                    fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                    size={12}
                    color={Color.neutral[700]}
                >
                    {data.devices}
                </Typography>
            </div>
            <div style={{ width: '100%', height: 2, backgroundColor: Color.neutral[200], marginTop: 4, marginBottom: 8 }}>
                <div style={{ width: `${(data.devices / maxDevice) * 100}%`, height: 2, background: Color.primary[400], transition: '0.3s width linear' }} />
            </div>
        </>
    )
}

const DeviceWorldMap = (props: any) => {
    const { data, region } = props

    const [geoChartData, setGeoChartData] = useState([])
    const [maxDevices, setMaxDevices] = useState(0)

    useEffect(() => {
        const newGeoChartData = [] as any
        /** Header */
        newGeoChartData.push(['Country', 'Device'])

        let temp = 0

        data.forEach((d: any) => {
            if (temp < d.devices) {
                temp = d.devices
            }
            newGeoChartData.push([d.country, d.devices])
        })

        setMaxDevices(temp)
        setGeoChartData(newGeoChartData)
    }, [data])

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
            }}
        >
            <div style={{ width: '50%', height: '100%' }}>
                <Chart
                    chartType="GeoChart"
                    chartData={geoChartData}
                    chartWidth={"100%"}
                    chartHeight={"100%"}
                    // mapsApiKey={process.env.REACT_APP_MAPSCHARTKEY}
                    rootProps={{ 'data-testid': '1' }}
                    chartOptions={{
                        region: region,
                        colorAxis: { colors: [Color.primary[100], Color.primary[900]] },
                        backgroundColor: 'transparent',
                        legend: 'none',
                        datalessRegionColor: Color.neutral[200],
                    }}
                />
            </div>
            <div style={{ width: '50%', height: 500, boxSizing: 'border-box', paddingLeft: 50, overflowY: 'scroll' }}>
                {data.map((d: any) => {
                    if (region === 'world' || region === d.region) {
                        return (
                            <CountryRankBar
                                data={d}
                                maxDevice={maxDevices}
                            />
                        )
                    }
                })}
            </div>
        </div>
    )
}

export default memo(DeviceWorldMap)