import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  var _userRoleContainer, _userRoleBox;

  return createStyles({
    userRoleContainer: (_userRoleContainer = {
      position: "relative",
      boxSizing: "border-box",
      padding: 24,
      width: "100%"
    }, _defineProperty(_userRoleContainer, theme.breakpoints.up('md'), {
      height: 260
    }), _defineProperty(_userRoleContainer, theme.breakpoints.down('sm'), {
      height: 140
    }), _defineProperty(_userRoleContainer, "backgroundColor", Color.primary[400]), _defineProperty(_userRoleContainer, "color", Color.neutral[0]), _userRoleContainer),
    userRoleBox: (_userRoleBox = {
      textAlign: "center"
    }, _defineProperty(_userRoleBox, theme.breakpoints.up('md'), {
      marginTop: 15
    }), _defineProperty(_userRoleBox, theme.breakpoints.down('sm'), {
      marginTop: 5
    }), _userRoleBox)
  });
});