// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./NoPreviewPageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { PageWrapper } from "../../index"; // Icon

import NoPreview from "../../../icons/NoPreview/NoPreview";
import Alert from "../../../icons/Alert/Alert";
export var NoPreviewPage = function NoPreviewPage(props) {
  var classes = useStyles(props);
  var type = props.type,
      previewWidth = props.previewWidth,
      previewHeight = props.previewHeight;
  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true,
    alignItems: "center",
    justify: "center",
    style: {
      width: previewWidth,
      height: previewHeight
    },
    className: classes.noPreviewWrapper
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12,
    container: true,
    justify: "center",
    alignItems: "center"
  }, type === "noPreview" ? React.createElement(NoPreview, {
    className: classes.noPreviewIcon
  }) : React.createElement(Alert, {
    className: classes.noPreviewIcon
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    container: true,
    justify: "center",
    alignItems: "center"
  }, React.createElement("span", {
    className: classes.noPreviewContent
  }, type === "noPreview" ? "No Preview Available" : "Please select at least one of the option"))))));
};
NoPreviewPage.defaultProps = {
  type: "noPreview"
};
export default React.memo(NoPreviewPage);