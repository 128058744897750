import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
export var NoMessage = function NoMessage(props) {
  return React.createElement(SvgIcon, Object.assign({}, props), React.createElement("svg", {
    viewBox: "0 0 110 110",
    version: "1.1"
  }, React.createElement("g", {
    id: "Empty-state",
    stroke: "none",
    "stroke-width": "1",
    fill: "none",
    "fill-rule": "evenodd"
  }, React.createElement("g", {
    id: "Main-Dashboard---First-time",
    transform: "translate(-674.000000, -691.000000)"
  }, React.createElement("g", {
    id: "message-24px",
    transform: "translate(672.000000, 689.000000)"
  }, React.createElement("g", {
    id: "Group"
  }, React.createElement("path", {
    d: "M92.2115491,17.6619718 L24.5180281,17.6619718 C19.8640986,17.6619718 16.0986465,21.4697324 16.0986465,26.1236619 L16.056338,102.278873 L32.9797183,85.3554928 L92.2115491,85.3554928 C96.8654787,85.3554928 100.673239,81.5477322 100.673239,76.8938027 L100.673239,26.1236619 C100.673239,21.4697324 96.8654787,17.6619718 92.2115491,17.6619718 Z M60.2754937,66.6980281 L32.9797183,66.6980281 L32.9797183,60.2754929 L60.2754937,60.2754929 L60.2754937,66.6980281 Z M83.4769028,66.6980281 L64.36986,66.6980281 L64.36986,60.2754929 L83.4769028,60.2754929 L83.4769028,66.6980281 Z M67.0994375,53.8529577 L32.9797183,53.8529577 L32.9797183,47.4304225 L67.0994375,47.4304225 L67.0994375,53.8529577 Z M83.4769028,53.8529577 L71.1938038,53.8529577 L71.1938038,47.4304225 L83.4769028,47.4304225 L83.4769028,53.8529577 Z M46.627606,41.0078873 L32.9797183,41.0078873 L32.9797183,34.5853521 L46.627606,34.5853521 L46.627606,41.0078873 Z M83.5046335,41.0078873 L50.749703,41.0078873 L50.749703,34.5853521 L83.5046335,34.5853521 L83.5046335,41.0078873 Z",
    id: "Shape",
    fill: "#F8F5FB"
  }), React.createElement("path", {
    d: "M-10.4366197,50.5774648 L124.43662,50.5774648 C127.983688,50.5774648 130.859155,53.4529317 130.859155,57 C130.859155,60.5470683 127.983688,63.4225352 124.43662,63.4225352 L-10.4366197,63.4225352 C-13.983688,63.4225352 -16.8591549,60.5470683 -16.8591549,57 C-16.8591549,53.4529317 -13.983688,50.5774648 -10.4366197,50.5774648 Z",
    id: "Rectangle",
    fill: "#FFFFFF",
    opacity: "0.455054874",
    transform: "translate(57.000000, 57.000000) rotate(-45.000000) translate(-57.000000, -57.000000) "
  })))))));
};
export default NoMessage;