import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// React libraries
import React from 'react';
import classnames from 'classnames'; // Component Style

import { useStyles } from "./StepperStyles"; // Material UI Core

import Typography from '@material-ui/core/Typography';
import MUIStepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel'; // Icons

import { Checkmark } from "../../icons"; // Components

import { Button } from "../index";
import { Hidden } from '@material-ui/core';
export var Stepper = function Stepper(props) {
  var classes = useStyles(props);
  var steps = props.steps,
      activeStep = props.activeStep,
      data = props.data,
      nextCallback = props.nextCallback,
      backCallback = props.backCallback,
      submitCallback = props.submitCallback,
      handleCancel = props.handleCancel,
      submitButtonLabel = props.submitButtonLabel;

  var handleNext = function handleNext() {
    nextCallback();
  };

  var handleBack = function handleBack() {
    backCallback();
  };

  var handleSubmit = function handleSubmit() {
    submitCallback(data);
  };

  var stepClasses = {
    horizontal: classes.horizontal
  };
  var labelClasses = {
    iconContainer: classes.iconContainer,
    completed: classes.labelCompleted,
    active: classes.labelActive,
    label: classes.label
  };

  var StepperStepIcon = function StepperStepIcon(props) {
    var _classnames;

    var active = props.active,
        completed = props.completed;
    var stepIconClasses = classnames((_classnames = {}, _defineProperty(_classnames, classes.root, true), _defineProperty(_classnames, classes.active, active), _defineProperty(_classnames, classes.completed, completed), _classnames));
    return React.createElement("div", {
      className: classes.root
    }, completed ? React.createElement(Checkmark, {
      style: {
        width: "24px",
        height: "24px"
      },
      className: classes.completed
    }) : React.createElement("div", {
      className: stepIconClasses
    }, React.createElement(Typography, {
      className: classes.iconText,
      gutterBottom: true
    }, props.icon)));
  };

  var content = steps[activeStep].content;
  return React.createElement("div", {
    className: classes.rootContainer
  }, React.createElement("div", {
    className: classes.stepperContainer
  }, React.createElement(MUIStepper, {
    className: classes.stepper,
    activeStep: activeStep
  }, steps.map(function (step) {
    return React.createElement(Step, {
      key: step.label,
      classes: stepClasses
    }, React.createElement(StepLabel, {
      StepIconComponent: StepperStepIcon,
      classes: labelClasses
    }, step.label));
  })), React.createElement("div", null, content)), React.createElement("div", {
    className: classes.bottomNav
  }, activeStep !== 0 ? React.createElement(Button, {
    type: "secondary",
    color: "white",
    alignIcon: "left",
    onClick: handleBack
  }, "Previous") : React.createElement(Hidden, {
    smDown: true
  }, React.createElement("div", null)), React.createElement(Hidden, {
    mdUp: true
  }, React.createElement(Button, {
    type: "secondary",
    color: "white",
    onClick: handleCancel,
    style: {
      outline: "none",
      margin: "0 10px"
    }
  }, "Cancel")), React.createElement("div", {
    style: {
      display: 'flex',
      alignItems: 'center'
    }
  }, React.createElement(Hidden, {
    smDown: true
  }, React.createElement(Button, {
    type: "secondary",
    color: "white",
    onClick: handleCancel,
    style: {
      marginRight: 100,
      outline: "none"
    }
  }, "Cancel")), activeStep !== steps.length - 1 ? React.createElement(Button, {
    type: "secondary",
    color: "white",
    alignIcon: "right",
    onClick: handleNext,
    style: {
      outline: "none"
    }
  }, "Next") : React.createElement(Button, {
    type: "primary",
    color: "white",
    alignIcon: "right",
    onClick: handleSubmit,
    style: {
      outline: "none"
    }
  }, submitButtonLabel))));
};
Stepper.defaultProps = {
  steps: [],
  activeStep: 0,
  currentData: {},
  nextCallback: function nextCallback() {},
  backCallback: function backCallback() {},
  submitCallback: function submitCallback() {},
  handleCancel: function handleCancel() {},
  submitButtonLabel: "Save"
};
export default React.memo(Stepper);