import React, { useState, useEffect, useContext } from 'react';

// Components
import {
    Typography,
    FONT_FAMILY,
    Color,
    Button,
    DialogBox,
    Carousel,
    BreadcrumbType
} from '@otg-one/ui_components';

// Material UI Core
import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { ReactComponent as ActiveDeviceCostIllustration } from './pricing_license_1.svg';
import { ReactComponent as MoreThan100DeviceIllustration } from './pricing_license_2.svg';
import { ReactComponent as MoreThan250DeviceIllustration } from './pricing_license_3.svg';
import { ReactComponent as AppConnectAtLeastIllustration } from './pricing_license_4.svg';
import { ReactComponent as AppNeedsToReconnectAfterIllustration } from './pricing_license_5.svg';
import { ReactComponent as ForDailyWorkIllustration } from './pricing_license_6.svg';
import { AppContext } from '../../App';

const ActiveDeviceCost = (props: any) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <div>
            <Typography
                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                size={isMobile ? 16 : 24}
                color={Color.neutral[900]}
                style={{ marginTop: -5, marginBottom: 23, letterSpacing: `0px`, lineHeight: `28px` }}
            >
                {`Every Active Device Cost`}
            </Typography>

            {isMobile ? (
                <Grid style={{ flexGrow: 1 }} container>
                    <Grid item sm={12} xs={12} style={{ height: 350, textAlign: 'center' }}>
                        <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -30 }}
                            >
                                {`35`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -18, marginLeft: 6 }}
                            >
                                {`€`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={20}
                                color={Color.neutral[400]}
                                style={{ marginTop: 12, marginLeft: 6 }}
                            >
                                {`/ Month`}
                            </Typography>
                        </div>
                        <ActiveDeviceCostIllustration style={{ height: 125 }} />
                        <div style={{ textAlign: 'left' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                size={14}
                                color={Color.neutral[500]}
                                style={{ marginTop: 24 }}
                            >
                                {`You pay only for mobile devices on which the OntegoOne app is actively used by one of your customers.`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                size={14}
                                color={Color.neutral[500]}
                                style={{ marginTop: 24 }}
                            >
                                {`Mobile devices are declared as active when a user logs in at least once per month to the OntegoOne app.`}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            ) : (
                <Grid style={{ flexGrow: 1 }} container spacing={2}>
                    <Grid item md={6}>
                        <div style={{ display: 'flex', textAlign: 'center' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -30 }}
                            >
                                {`35`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -18, marginLeft: 6 }}
                            >
                                {`€`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={30}
                                color={Color.neutral[400]}
                                style={{ marginTop: 12, marginLeft: 6 }}
                            >
                                {`/ Month`}
                            </Typography>
                        </div>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                            size={14}
                            color={Color.neutral[500]}
                            style={{ width: 312, marginTop: 24 }}
                        >
                            {`You pay only for mobile devices on which the OntegoOne app is actively used by one of your customers.`}
                        </Typography>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                            size={14}
                            color={Color.neutral[500]}
                            style={{ width: 312, marginTop: 24 }}
                        >
                            {`Mobile devices are declared as active when a user logs in at least once per month to the OntegoOne app.`}
                        </Typography>
                    </Grid>
                    <Grid item md={6}>
                        <div style={{ height: 300, textAlign: "right", marginRight: 30 }}>
                            <ActiveDeviceCostIllustration />
                        </div>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

const MoreThan100Device = (props: any) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <div>
            <Typography
                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                size={isMobile ? 16 : 24}
                color={Color.neutral[900]}
                style={{ marginTop: -5, marginBottom: 23, letterSpacing: `0px`, lineHeight: `28px` }}
            >
                {`If You have more than 100 Device`}
            </Typography>

            {isMobile ? (
                <Grid style={{ flexGrow: 1 }} container>
                    <Grid item sm={12} xs={12} style={{ height: 350, textAlign: 'center' }}>
                        <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -30 }}
                            >
                                {`29`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -18, marginLeft: 6 }}
                            >
                                {`€`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={20}
                                color={Color.neutral[400]}
                                style={{ marginTop: 12, marginLeft: 6 }}
                            >
                                {`/ Month`}
                            </Typography>
                        </div>
                        <MoreThan100DeviceIllustration style={{ height: 125 }} />
                        <div style={{ textAlign: 'left' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                size={14}
                                color={Color.neutral[500]}
                                style={{ marginTop: 24 }}
                            >
                                {`If you have more than 100 active mobile devices in one month the price for all devices
reduces to 29 € per month and device.`}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            ) : (
                <Grid style={{ flexGrow: 1 }} container spacing={2}>
                    <Grid item md={6}>
                        <div style={{ display: 'flex', textAlign: 'center' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -30 }}
                            >
                                {`29`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -18, marginLeft: 6 }}
                            >
                                {`€`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={30}
                                color={Color.neutral[400]}
                                style={{ marginTop: 12, marginLeft: 6 }}
                            >
                                {`/ Month`}
                            </Typography>
                        </div>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                            size={14}
                            color={Color.neutral[500]}
                            style={{ width: 312, marginTop: 24 }}
                        >
                            {`If you have more than 100 active mobile devices in one month the price for all devices
reduces to 29 € per month and device.`}
                        </Typography>
                    </Grid>
                    <Grid item md={6}>
                        <div style={{ height: 300, textAlign: "right", marginRight: 30 }}>
                            <MoreThan100DeviceIllustration />
                        </div>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

const MoreThan250Device = (props: any) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <div>
            <Typography
                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                size={isMobile ? 16 : 24}
                color={Color.neutral[900]}
                style={{ marginTop: -5, marginBottom: 23, letterSpacing: `0px`, lineHeight: `28px` }}
            >
                {`If You have more than 250 Device`}
            </Typography>

            {isMobile ? (
                <Grid style={{ flexGrow: 1 }} container>
                    <Grid item sm={12} xs={12} style={{ height: 350, textAlign: 'center' }}>
                        <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -30 }}
                            >
                                {`19`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -18, marginLeft: 6 }}
                            >
                                {`€`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={20}
                                color={Color.neutral[400]}
                                style={{ marginTop: 12, marginLeft: 6 }}
                            >
                                {`/ Month`}
                            </Typography>
                        </div>
                        <MoreThan250DeviceIllustration style={{ height: 125 }} />
                        <div style={{ textAlign: 'left' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                size={14}
                                color={Color.neutral[500]}
                                style={{ marginTop: 24 }}
                            >
                                {`If you have more than 250 active mobile devices in one month the price for all devices
reduces to 19 € per month and device.`}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            ) : (
                <Grid style={{ flexGrow: 1 }} container spacing={2}>
                    <Grid item md={6}>
                        <div style={{ display: 'flex', textAlign: 'center' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -30 }}
                            >
                                {`19`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -18, marginLeft: 6 }}
                            >
                                {`€`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={30}
                                color={Color.neutral[400]}
                                style={{ marginTop: 12, marginLeft: 6 }}
                            >
                                {`/ Month`}
                            </Typography>
                        </div>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                            size={14}
                            color={Color.neutral[500]}
                            style={{ width: 312, marginTop: 24 }}
                        >
                            {`If you have more than 250 active mobile devices in one month the price for all devices
reduces to 19 € per month and device.`}
                        </Typography>
                    </Grid>
                    <Grid item md={6}>
                        <div style={{ height: 300, textAlign: "right", marginRight: 30 }}>
                            <MoreThan250DeviceIllustration />
                        </div>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

const AppConnectAtLeast = (props: any) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <div>
            <Typography
                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                size={isMobile ? 16 : 24}
                color={Color.neutral[900]}
                style={{ marginTop: -5, marginBottom: 23, letterSpacing: `0px`, lineHeight: `28px` }}
            >
                {`App connect at least`}
            </Typography>

            {isMobile ? (
                <Grid style={{ flexGrow: 1 }} container>
                    <Grid item sm={12} xs={12} style={{ height: 350, textAlign: 'center' }}>
                        <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -30 }}
                            >
                                {`1`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -30, marginLeft: 6 }}
                            >
                                {`x`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={20}
                                color={Color.neutral[400]}
                                style={{ textAlign: 'left', marginTop: 12, marginLeft: 6 }}
                            >
                                {`/ Month via Internet`}
                            </Typography>
                        </div>
                        <AppConnectAtLeastIllustration style={{ height: 125 }} />
                        <div style={{ textAlign: 'left' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                size={14}
                                color={Color.neutral[500]}
                                style={{ marginTop: 24 }}
                            >
                                {`If you have more than 250 active mobile devices in one month the price for all devices
reduces to 19 € per month and device.`}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            ) : (
                <Grid style={{ flexGrow: 1 }} container spacing={2}>
                    <Grid item md={6}>
                        <div style={{ display: 'flex', textAlign: 'center' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -30 }}
                            >
                                {`1`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -30, marginLeft: 6 }}
                            >
                                {`x`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={24}
                                color={Color.neutral[400]}
                                style={{ textAlign: 'left', marginTop: 12, marginLeft: 6 }}
                            >
                                {`Per Month via Internet`}
                            </Typography>
                        </div>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                            size={14}
                            color={Color.neutral[500]}
                            style={{ width: 312, marginTop: 24 }}
                        >
                            {`With every login the app tries to connect to the OntegoOne Cloud.`}
                        </Typography>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                            size={14}
                            color={Color.neutral[500]}
                            style={{ width: 312, marginTop: 24 }}
                        >
                            {`When successful the license is renewed and the client does not need an internet connection for the next 20 days.`}
                        </Typography>
                    </Grid>
                    <Grid item md={6}>
                        <div style={{ height: 300, textAlign: "right", marginRight: 30 }}>
                            <AppConnectAtLeastIllustration />
                        </div>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

const AppNeedsToReconnectAfter = (props: any) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <div>
            <Typography
                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                size={isMobile ? 16 : 24}
                color={Color.neutral[900]}
                style={{ marginTop: -5, marginBottom: 23, letterSpacing: `0px`, lineHeight: `28px` }}
            >
                {`App needs to reconnect after`}
            </Typography>

            {isMobile ? (
                <Grid style={{ flexGrow: 1 }} container>
                    <Grid item sm={12} xs={12} style={{ height: 350, textAlign: 'center' }}>
                        <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -50, letterSpacing: -2 }}
                            >
                                {`20`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={20}
                                color={Color.neutral[400]}
                                style={{ textAlign: 'left', marginLeft: 12 }}
                            >
                                {`Days`}
                            </Typography>
                        </div>
                        <AppNeedsToReconnectAfterIllustration style={{ height: 125 }} />
                        <div style={{ textAlign: 'left' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                size={14}
                                color={Color.neutral[500]}
                                style={{ marginTop: 24 }}
                            >
                                {`After license renewal the app can be used for 20 days even without an internet
connection. If the the reconnection fails or is not done in the time period the app will be
blocked for use.`}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            ) : (
                <Grid style={{ flexGrow: 1 }} container spacing={2}>
                    <Grid item md={6}>
                        <div style={{ display: 'flex', textAlign: 'center' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -30, letterSpacing: -2 }}
                            >
                                {`20`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={24}
                                color={Color.neutral[400]}
                                style={{ textAlign: 'left', marginTop: 12, marginLeft: 12 }}
                            >
                                {`Days`}
                            </Typography>
                        </div>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                            size={14}
                            color={Color.neutral[500]}
                            style={{ width: 312, marginTop: 24 }}
                        >
                            {`After license renewal the app can be used for 20 days even without an internet
connection. If the the reconnection fails or is not done in the time period the app will be
blocked for use.`}
                        </Typography>
                    </Grid>
                    <Grid item md={6}>
                        <div style={{ height: 300, textAlign: "right", marginRight: 30 }}>
                            <AppNeedsToReconnectAfterIllustration />
                        </div>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

const ForDailyWork = (props: any) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <div>
            <Typography
                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                size={isMobile ? 16 : 24}
                color={Color.neutral[900]}
                style={{ marginTop: -5, marginBottom: 23, letterSpacing: `0px`, lineHeight: `28px` }}
            >
                {`For Daily work your Customer doesn’t need to connect to OntegoOne`}
            </Typography>

            {isMobile ? (
                <Grid style={{ flexGrow: 1 }} container>
                    <Grid item sm={12} xs={12} style={{ height: 350, textAlign: 'center' }}>
                        <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', visibility: 'hidden' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -50, letterSpacing: -2 }}
                            >
                                {`20`}
                            </Typography>
                        </div>
                        <ForDailyWorkIllustration style={{ height: 125 }} />
                        <div style={{ textAlign: 'left' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                size={14}
                                color={Color.neutral[500]}
                                style={{ marginTop: 24 }}
                            >
                                {`Mobile workers only need their companies Wi-Fi to work within the intranet and access their Business One System.`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                size={14}
                                color={Color.neutral[500]}
                                style={{ marginTop: 24 }}
                            >
                                {`Only for license renewal and once per month the internet checkin is needed.`}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            ) : (
                <Grid style={{ flexGrow: 1 }} container spacing={2}>
                    <Grid item md={6}>
                        <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', visibility: 'hidden' }}>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={72}
                                color={Color.neutral[600]}
                                style={{ marginTop: -30, letterSpacing: -2 }}
                            >
                                {`20`}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                size={24}
                                color={Color.neutral[400]}
                                style={{ textAlign: 'left', marginTop: 12, marginLeft: 12 }}
                            >
                                {`Days`}
                            </Typography>
                        </div>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                            size={14}
                            color={Color.neutral[500]}
                            style={{ width: 312, marginTop: 24 }}
                        >
                            {`Mobile workers only need their companies Wi-Fi to work within the intranet and access their Business One System.`}
                        </Typography>
                        <Typography
                            fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                            size={14}
                            color={Color.neutral[500]}
                            style={{ width: 312, marginTop: 24 }}
                        >
                            {`Only for license renewal and once per month the internet checkin is needed.`}
                        </Typography>
                    </Grid>
                    <Grid item md={6}>
                        <div style={{ height: 300, textAlign: "right", marginRight: 30 }}>
                            <ForDailyWorkIllustration />
                        </div>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

const PricingAndLicenseDialog = (props: any) => {
    const { open, onClose } = props

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const {
        handleChangeBreadcrumb
    } = useContext(AppContext);

    /** Init nav length */
    const navLength = 6;
    /** Set carousel nav starting index */
    const [navIndex, setNavIndex] = useState(0);

    useEffect(() => {
        setNavIndex(0)
    }, [open])

    /** Set carousel onClick */
    const handleCarouselNavOnClick = (index: number) => () => {
        setNavIndex(index)
    };

    const handleGoToBilling = () => {
        handleChangeBreadcrumb(BreadcrumbType.BILLING_DETAIL);
        onClose();
    };

    const nav = [
        <ActiveDeviceCost />,
        <MoreThan100Device />,
        <MoreThan250Device />,
        <AppConnectAtLeast />,
        <AppNeedsToReconnectAfter />,
        <ForDailyWork />,
    ];

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title={`Pricing And License`}
            titleType="overline"
            content={
                <div style={{ width: isMobile ? 'auto' : 750, height: isMobile ? 'auto' : 510, overflowX: 'hidden' }}>
                    {nav[navIndex]}

                    <div style={{ width: '100%', textAlign: 'center', marginTop: isMobile ? 20 : 40, marginBottom: 20 }}>
                        <Carousel
                            length={navLength}
                            index={navIndex}
                            onClick={handleCarouselNavOnClick}
                            isMobile={isMobile}
                        />
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: isMobile ? 'space-around' : 'space-between', alignItems: "flex-end", marginTop: 24 }}>
                        {!isMobile && (
                            <div>
                                <Button type="secondary" onClick={onClose} style={{ outline: 'none' }}>
                                    {`Skip`}
                                </Button>
                            </div>
                        )}
                        <div style={{ display: 'flex' }}>
                            {navIndex > 0 && (
                                <Button alignIcon="left" style={{ outline: 'none', marginRight: 20 }} onClick={handleCarouselNavOnClick(navIndex - 1)}>
                                    {`Back`}
                                </Button>
                            )}
                            <Button alignIcon="right" style={{ outline: 'none' }} onClick={navIndex === navLength - 1 ? handleGoToBilling : handleCarouselNavOnClick(navIndex + 1)}>
                                {navIndex === navLength - 1 ? 'To billing' : 'Next'}
                            </Button>
                        </div>
                    </div>
                </div>
            }
        />
    )
};

export default PricingAndLicenseDialog;