export class Stack {
    type: string;
    label: string;
    path: string;
    data: any;

    constructor(type: string, label: string, path: string, data?: any) {
        this.type = type;
        this.label = label;
        this.path = path;
        this.data = data;
    }
}