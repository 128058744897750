// React lib
import React, { useContext, useState } from 'react';
import { AppContext } from '../../App';

// Styles
import { useStyles } from './CreateNewUserStyles';

// Components
import {
    Button,
    Form,
    Typography,
    FONT_FAMILY,
    Color,
    Separator,
    Breadcrumb
} from '@otg-one/ui_components';

// Material-UI Core
import Grid from '@material-ui/core/Grid';

const CreateNewUser = (props: any) => {
    const classes = useStyles(props)

    const {
        breadcrumb,
        currentUserData,
        handleChangeBreadcrumb
    } = useContext(AppContext);

    return (
        <div className={classes.createNewUserContainer}>
            <Grid container style={{ flexGrow: 1 }} spacing={2}>
                <Grid item md={4}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                        size={14}
                        color={Color.neutral[600]}
                    >
                        User Name
                    </Typography>
                </Grid>
                <Grid item md={8}>
                    <Form
                        style={{ width: 480 }}
                        placeholder="Insert User's Name"
                        required
                    />
                </Grid>
                <Grid item md={12}>
                    <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
                </Grid>

                <Grid item md={4}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                        size={14}
                        color={Color.neutral[600]}
                    >
                        Email
                        </Typography>
                </Grid>
                <Grid item md={8}>
                    <Form
                        style={{ width: 480 }}
                        placeholder="Insert Email"
                        required
                    />
                </Grid>
                <Grid item md={12}>
                    <Separator type="n200" width={2} marginTop={10} marginBottom={20} />
                </Grid>

                <Grid item md={4}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                        size={14}
                        color={Color.neutral[600]}
                    >
                        Password
                        </Typography>
                </Grid>
                <Grid item md={8}>
                    <Form
                        style={{ width: 480 }}
                        placeholder="Min 8 Character, contain number and special character"
                        required
                        password
                    />
                </Grid>

                <Grid item md={4}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                        size={14}
                        color={Color.neutral[600]}
                    >
                        Confirm Password
                    </Typography>
                </Grid>
                <Grid item md={8}>
                    <Form
                        style={{ width: 480 }}
                        placeholder="Repeat Password"
                        required
                        password
                    />
                </Grid>

                <Grid item md={4}></Grid>
                <Grid item md={8}>
                    <div>
                        <Button style={{ marginTop: 50 }}>
                            Create User
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default CreateNewUser