// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Color } from '@otg-one/ui_components';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        qrCodeContainer: {
            display: "flex",
            justifyContent: "space-between",

            [theme.breakpoints.down('sm')]: {
                flexDirection: "column",
                justifyContent: "center",
            }
        },

        storeIcon: {
            marginTop: 40,
            maxHeight: 40,

            [theme.breakpoints.down('sm')]: {
                marginTop: 20,
            }
        }
    })
)