// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    whiteContentTopRadius: {
      backgroundColor: "#ffffff",
      marginTop: "-10px !important",
      textAlign: "center",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      paddingBottom: "20px",
      overflow: "hidden"
    },
    greyContentPlain: {
      // backgroundColor: "#f3f6f6",
      overflow: "auto"
    },
    greyContent: {
      backgroundColor: "#f3f6f6",
      overflow: "auto",
      padding: "15px"
    },
    noContentBar: {
      padding: "8px 15px",
      backgroundColor: "#f1f1f1",
      fontSize: "12px",
      color: "#a8a0a5",
      textAlign: "center"
    },
    whiteContentBar: {
      backgroundColor: "#ffffff",
      height: "45px",
      display: "flex",
      alignItems: "center"
    },
    ContentBorder: {
      height: '4px',
      borderTop: '1px solid #9D9D9D',
      backgroundColor: '#EFEFEF'
    },
    contentTitle: {
      fontSize: "10px",
      color: "#9D9D9D",
      marginBottom: 2,
      textAlign: "left",
      padding: "0 15px"
    },
    blackLeftContent: {
      fontSize: "12px",
      color: "black",
      marginTop: 0,
      textAlign: "left",
      textTransform: "capitalize",
      paddingLeft: "15px"
    },
    whiteContentBarCloseButton: {
      color: "#00a7dd",
      right: 15,
      cursor: "pointer"
    }
  });
});