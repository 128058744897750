export class InventoryCounting {
	public static SELECT_ITEM_INPUT_MANUAL = "manual";
	public static SELECT_ITEM_INPUT_SCAN = "scan";
	public static SELECT_ITEM_INPUT_OCR = "ocr"; // UNAVAILABLE
	public static SELECT_ITEM_INPUT_SHOPPING_ARTICLE = "shopping_article"; // DO NOT SHOW! It exists in mapper, but not shown in UI

	public static WAREHOUSE_LAYOUT_WAREHOUSE_NAME = "warehouseName";
	public static WAREHOUSE_LAYOUT_WAREHOUSE_CODE = "warehouseCode";

	public static BATCH_AND_SERIAL_NUMBER_NO = "no";
	public static BATCH_AND_SERIAL_NUMBER_ON_DIFF = "ondiff"; // UNAVAILABLE
	public static BATCH_AND_SERIAL_NUMBER_YES = "yes"; // UNAVAILABLE

	public static BEST_BEFORE_INPUT_TYPE_INPUT_FIELD = "inputField";
	public static BEST_BEFORE_INPUT_TYPE_DATE_PICKER = "datePicker";

	public static BEST_BEFORE_DATE_OFF = "OFF";
	public static BEST_BEFORE_DATE_OPTIONAL = "OPTIONAL";
	public static BEST_BEFORE_DATE_ON = "ON";

	public static SELECT_WAREHOUSE_INPUT_MANUAL = "manual";
	public static SELECT_WAREHOUSE_INPUT_SCAN = "scan";
	public static SELECT_WAREHOUSE_INPUT_OCR = "ocr"; // UNAVAILABLE

	public selectItemInput: string[];
	public warehouseLayout: string;
	public showExpectedQuantity: boolean;
	public allowAddInventory: boolean;
	public batchAndSerialNumber: string;
	public allowOneBatchForOneBinLoc: boolean;
	public bestBeforeInputType: string;
	public bestBeforeDate: string;
	public selectWarehouseInput: string[];

	public constructor() {
		this.selectItemInput = [InventoryCounting.SELECT_ITEM_INPUT_MANUAL, InventoryCounting.SELECT_ITEM_INPUT_SCAN];
		this.warehouseLayout = InventoryCounting.WAREHOUSE_LAYOUT_WAREHOUSE_NAME;
		this.showExpectedQuantity = false;
		this.allowAddInventory = false;
		this.batchAndSerialNumber = InventoryCounting.BATCH_AND_SERIAL_NUMBER_NO;
		this.allowOneBatchForOneBinLoc = false;
		this.bestBeforeInputType = InventoryCounting.BEST_BEFORE_INPUT_TYPE_INPUT_FIELD;
		this.bestBeforeDate = InventoryCounting.BEST_BEFORE_DATE_OFF;
		this.selectWarehouseInput = [InventoryCounting.SELECT_WAREHOUSE_INPUT_MANUAL, InventoryCounting.SELECT_WAREHOUSE_INPUT_SCAN];
	}
}
