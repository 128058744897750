import React, { useState, useEffect } from 'react';

// Components
import {
    Typography,
    FONT_FAMILY,
    Color,
    Button,
    DialogBox,
    Form,
    Checkbox
} from '@otg-one/ui_components';
import SystemService from './SystemService';

interface InputUserRoleProps {
    open: boolean;
    onClose: () => any;
    currentUserData: any;
    functions: any[];
    userRole: any;
    getUserRoles: () => any;
}

const InputUserRole = (props: InputUserRoleProps) => {
    const { open, onClose, currentUserData, functions, userRole, getUserRoles } = props;

    const initialData = {
        name: "",
        roles: {} as any
    };

    for (const func of functions) {
        initialData.roles[func.functionTypeId] = false;
    }

    let editMode = false;
    if (userRole.key) {
        editMode = true;
    }

    const [data, setData] = useState(editMode ? userRole : initialData);

    useEffect(() => {
        setData(editMode ? userRole : initialData);
    }, [open]);

    const handleChangeData = (property: string) => (e: React.FormEvent<HTMLInputElement>) => {
        const newData: any = { ...data };
        if (property === "name") {
            newData[property] = e.currentTarget.value;
        } else {
            newData.roles[property] = e.currentTarget.checked;
        }
        setData(newData);
    };

    const handleSubmitUserRole = () => {
        if (editMode) {
            SystemService.editUserRole(currentUserData.key, data).then((res: any) => {
                if (res.status === 200) {
                    onClose();
                    getUserRoles();
                }
            });
        } else {
            SystemService.createUserRole(currentUserData.key, userRole.system.key, data).then((res: any) => {
                if (res.status === 200) {
                    onClose();
                    getUserRoles();
                }
            });
        }
    };

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title={editMode ? "Edit User Role" : "Create New Role"}
            content={
                <div style={{ width: 500, paddingTop: 16 }}>
                    <Form
                        required
                        placeholder="Role Name"
                        value={data.name}
                        onChange={handleChangeData("name")}
                        style={{ width: 300 }}
                    />

                    <Typography
                        fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                        size={18}
                        color={Color.neutral[600]}
                        style={{ marginTop: 32, marginBottom: 16 }}
                    >
                        {`App Function for this role`}
                    </Typography>

                    {functions.map(func => (
                        <Checkbox
                            name="app_function_checkbox"
                            label={func.name}
                            value={func.name}
                            checked={data.roles[func.functionTypeId]}
                            onChange={handleChangeData(func.functionTypeId)}
                        />
                    ))}

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: 45 }}>
                        <Button style={{ outline: 'none' }} disabled={data.name.length < 1} onClick={handleSubmitUserRole}>{editMode ? "Save Changes" : "Create Role"}</Button>
                    </div>
                </div>
            }
        />
    )
};

export default InputUserRole;