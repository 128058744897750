// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    latestActivityContainer: {
      boxSizing: "border-box",
      padding: 24,
      width: "100%",
      height: 300,
      backgroundColor: Color.primary[900]
    },
    latestActivityListContainer: {
      maxHeight: 201,
      overflowY: "scroll"
    },
    latestActivityList: {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      height: 67,
      transition: "all .2s ease-in-out",
      "&:hover": {
        backgroundColor: Color.primary[800]
      }
    },
    activityListPicture: {
      width: 40,
      height: 40,
      borderRadius: "50%",
      marginLeft: 16,
      backgroundColor: Color.neutral[100]
    },
    activityListName: {
      marginLeft: 18,
      width: 192,
      textOverflow: "ellipsis"
    },
    activityListInfo: {
      marginLeft: 35,
      width: 400,
      textOverflow: "ellipsis"
    },
    activityListDate: {
      width: 115,
      textOverflow: "ellipsis"
    },
    activityListTime: {
      marginLeft: 105,
      width: 70,
      textOverflow: "ellipsis"
    },
    activityListAction: {
      marginLeft: 16
    }
  });
});