import React, { useContext, useEffect } from 'react';

// Components
import {
    DialogBox,
    Button,
    Typography,
    FONT_FAMILY,
    Color,
} from '@otg-one/ui_components';

const RevertToPreviousSettingDialog = (props: any) => {
    const { open, onClose, handleRevert } = props

    const handleRevertToPreviousSetting = () => {
        handleRevert()
        onClose()
    }

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title="Revert To Previous Setting?"
            content={
                <div style={{ width: 600 }}>
                    <Typography
                        fontFamily={FONT_FAMILY.CAPITOLIUM_ITALIC}
                        size={16}
                        color={Color.neutral[900]}
                        style={{ marginBottom: 50 }}
                    >
                        {`Changes setting to previous saved configuration`}
                    </Typography>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={onClose} type="secondary" style={{ outline: 'none' }}>
                            {`Cancel`}
                        </Button>
                        <div>
                            <Button
                                style={{ outline: 'none' }}
                                onClick={handleRevertToPreviousSetting}
                            >
                                {`Revert to Previous Setting`}
                            </Button>
                        </div>
                    </div>
                </div>
            }
        />
    )
};

export default RevertToPreviousSettingDialog;