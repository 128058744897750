export const SETTINGS_FOR_DELIVERY = `settingsForDelivery`

export const SETTINGS_FOR_ENTRY = `settingsForEntry`
export const ENTRY_OPTION = `entryOption`
export const VIA_SUPPLIERS = `viaSuppliers`
export const LIST_OF_SUPPLIERS = `listOfSuppliers`
export const SELECTION_OF_ORDERS = `selectionOfOrders`

export const VIA_ORDER_NUMBER = `viaOrderNumber`
export const TYPE_OF_ORDER_INPUT = `typeOfOrderInput`

export const SETTINGS_FOR_LIST_OF_POSITION = `settingsForListOfPositions`
export const DISPLAYED_COLUMN = `displayedColumn`
export const NEW_POSITION_ADDABLE = `newPositionAddable`
export const ITEM_SELECTION = `itemSelection`
export const QUICK_CHECK = `quickCheck`
export const WAREHOUSE_NAME = `warehouseName`
export const COMPLETE_ORDER = `completeOrder`
export const ORDER_POSITION_COUNT = `orderPositionCount`

export const SETTINGS_FOR_POSITION_DETAILS = `settingsForPositionDetails`
export const FIELDS_DISPLAYED = `fieldsDisplayed`
export const MORE_OR_LESS_QUANTITIES_ALLOWED = `moreOrLessQuantitiesAllowed`

export const LESS_QUANTITIES_SECURITY_CHECK = `lessQuantitiesSecurityCheck`
export const OVERBOOKING_SECURITY_CHECK = `overbookingSecurityCheck`
export const SERIAL_NUMBER_TYPE = `serialNumberType`