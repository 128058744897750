// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var SVG_GRADIENT_BLUE = "PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZlcnNpb249JzEuMScgd2lkdGg9JzUwJyBoZWlnaHQ9JzUwJz48ZGVmcz48bGluZWFyR3JhZGllbnQgaWQ9J3JlZGdyYWRpZW50Jz48c3RvcCBvZmZzZXQ9JzAnIHN0b3AtY29sb3I9JyMwMDUyN0YnLz48c3RvcCBvZmZzZXQ9JzEnIHN0b3AtY29sb3I9JyMwMDc3QjYnLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48ZyBpZD0nTGF5ZXJfMSc+PHBhdGggZD0nTTAsMCBMNTAsMCBMNTAsNTAgTDAsNTAgTDAsMCB6JyBmaWxsPSd1cmwoI3JlZGdyYWRpZW50KScgd2lkdGg9JzEwMCUnIGhlaWdodD0nMTAwJScvPjwvZz48L3N2Zz4=";
export var SVG_GRADIENT_WHITE = "PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZlcnNpb249JzEuMScgd2lkdGg9JzUwJyBoZWlnaHQ9JzUwJz48ZGVmcz48bGluZWFyR3JhZGllbnQgaWQ9J3JlZGdyYWRpZW50Jz48c3RvcCBvZmZzZXQ9JzAnIHN0b3AtY29sb3I9JyNGRkZGRkYnLz48c3RvcCBvZmZzZXQ9JzEnIHN0b3AtY29sb3I9JyNGRkZGRkYnLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48ZyBpZD0nTGF5ZXJfMSc+PHBhdGggZD0nTTAsMCBMNTAsMCBMNTAsNTAgTDAsNTAgTDAsMCB6JyBmaWxsPSd1cmwoI3JlZGdyYWRpZW50KScgd2lkdGg9JzEwMCUnIGhlaWdodD0nMTAwJScvPjwvZz48L3N2Zz4=";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    '@keyframes switch-arrow': {
      from: {
        backgroundSize: "0% 100%",
        opacity: 1
      },
      to: {
        backgroundSize: "100% 100%",
        opacity: 1
      }
    },
    button: {
      textAlign: "center",
      cursor: "pointer",
      userSelect: "none",
      zIndex: 1,
      fontSize: "14px"
    },
    withIcon: {
      alignItems: "center",
      paddingLeft: "16px !important",
      paddingRight: "16px !important"
    },
    buttonIconLeft: {
      display: "flex",
      "& svg": {
        marginRight: 24
      }
    },
    buttonIconRight: {
      display: "flex",
      "& svg": {
        marginLeft: 24
      }
    },
    primary: {
      display: "inline-flex",
      fontFamily: "roboto-medium",
      padding: "10px 32px",
      // lineHeight: "42px",
      backgroundSize: "0% 100%",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "border-box",
      transition: "all .2s ease-in-out, background-size .3s ease",
      "&:hover": {
        backgroundSize: "100% 100%"
      },
      "&:active": {
        borderImage: "none",
        transition: "all .0s",
        backgroundImage: "none"
      }
    },
    primaryblue: {
      border: "2px solid ".concat(Color.primary[600]),
      borderImage: "url('data:image/svg+xml;base64,".concat(SVG_GRADIENT_BLUE, "') 10% stretch"),
      backgroundImage: Color.gradient[100],
      color: Color.primary[600],
      "&:hover": {
        color: Color.neutral[0]
      },
      "&:active": {
        borderColor: Color.primary[900],
        backgroundColor: Color.primary[900],
        color: Color.neutral[0]
      }
    },
    primarywhite: {
      border: "2px solid ".concat(Color.neutral[0]),
      borderImage: "url('data:image/svg+xml;base64,".concat(SVG_GRADIENT_WHITE, "') 10% stretch"),
      backgroundImage: Color.gradient[0],
      color: Color.neutral[0],
      "&:hover": {
        color: Color.primary[600]
      },
      "&:active": {
        borderColor: Color.neutral[0],
        backgroundColor: Color.neutral[0],
        color: Color.primary[900]
      }
    },
    secondary: {
      position: "relative",
      fontFamily: "roboto-medium",
      lineHeight: "24px",
      paddingTop: "3px",
      paddingBottom: "3px",
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
      borderBottom: "2px solid transparent",
      display: "inline-flex",
      alignItems: "center",
      transition: "all .3s ease-in-out",
      "&::after": {
        position: "absolute",
        left: 0,
        bottom: 0,
        content: '""',
        width: "100%",
        transform: "scaleX(0)",
        transformOrigin: "0% 100%",
        transition: "all .3s ease-in-out"
      },
      "&:hover": {
        "&::after": {
          transform: "scaleX(1)"
        }
      }
    },
    secondaryblue: {
      color: Color.primary[600],
      "&:hover": {
        color: Color.primary[900]
      },
      "&::after": {
        borderBottom: "2px solid ".concat(Color.primary[900])
      }
    },
    secondarywhite: {
      color: Color.neutral[0],
      "&:hover": {
        color: Color.neutral[0]
      },
      "&::after": {
        borderBottom: "2px solid ".concat(Color.neutral[0])
      }
    },
    tertiary: {
      position: "relative",
      fontFamily: "capitolium-bold",
      fontSize: "14px",
      lineHeight: "24px",
      paddingTop: "3px",
      paddingBottom: "3px",
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
      borderBottom: "2px solid transparent",
      display: "inline-flex",
      alignItems: "center",
      transition: "all .3s ease-in-out",
      "&::after": {
        position: "absolute",
        left: 0,
        bottom: 0,
        content: '""',
        width: "100%",
        transform: "scaleX(0)",
        transformOrigin: "0% 100%",
        transition: "all .3s ease-in-out"
      },
      "&:hover": {
        "&::after": {
          transform: "scaleX(1)"
        }
      }
    },
    tertiaryblue: {
      color: Color.primary[400],
      "&:hover": {
        color: Color.primary[400]
      },
      "&::after": {
        borderBottom: "2px solid ".concat(Color.primary[400])
      }
    },
    tertiarywhite: {
      color: Color.neutral[0],
      "&:hover": {
        color: Color.neutral[0]
      },
      "&::after": {
        borderBottom: "2px solid ".concat(Color.neutral[0])
      }
    },
    primarydisabledblue: {
      pointerEvents: "none",
      border: "2px solid ".concat(Color.neutral[400]),
      background: Color.neutral[400],
      color: Color.neutral[300]
    },
    primarydisabledwhite: {
      pointerEvents: "none",
      border: "2px solid ".concat(Color.neutral[400]),
      background: Color.neutral[400],
      color: Color.neutral[300]
    },
    secondarydisabledblue: {
      pointerEvents: "none",
      color: Color.neutral[500]
    },
    secondarydisabledwhite: {
      pointerEvents: "none",
      color: Color.primary[200]
    },
    tertiarydisabledblue: {
      pointerEvents: "none",
      color: Color.neutral[400]
    },
    tertiarydisabledwhite: {
      pointerEvents: "none",
      color: Color.neutral[400]
    }
  });
});