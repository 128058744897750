// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    whiteContentBarButton: {
      height: "40px",
      padding: "10px 13px 10px 13px",
      borderRadius: "4px",
      marginBottom: "8px",
      width: "100%"
    },
    leftContent: {
      fontSize: "11px",
      color: "black",
      marginTop: 0,
      textAlign: "left",
      textTransform: "capitalize"
    },
    rightContent: {
      fontSize: "11px",
      color: "#00a7dd",
      marginTop: 0,
      textAlign: "right",
      textTransform: "none"
    }
  });
});