import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./InventoryCountingBinLocationsStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { ScanContentBar } from "../../index";
export var InventoryCountingBinLocations = function InventoryCountingBinLocations(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      data = props.data,
      scanContentBarText = props.scanContentBarText,
      isListPrefilled = props.isListPrefilled;

  var _React$useState = React.useState(data),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      binLocationList = _React$useState2[0],
      setBinLocationList = _React$useState2[1];

  React.useEffect(function () {
    setBinLocationList(data);
  }, [data]);
  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "smallWithSub",
    showScanner: true,
    previewHeight: previewHeight
  }, React.createElement("p", null, "Inventory Counting"), React.createElement("sub", null, "Inventory List ", React.createElement("b", null, "41")))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentTopRadius,
    style: {
      height: previewHeight - 90 - 40
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(ScanContentBar, {
    text: scanContentBarText
  })), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.greyContentPlain,
    style: {
      height: previewHeight - 90 - 40 - 40
    }
  }, isListPrefilled ? React.createElement(Grid, {
    container: true
  }, binLocationList.length > 0 && binLocationList.map(function (data) {
    return React.createElement(React.Fragment, null, React.createElement(Grid, {
      item: true,
      md: 12,
      className: classes.whiteContentBar
    }, React.createElement(Grid, {
      container: true
    }, React.createElement(Grid, {
      className: classes.contentTitle,
      item: true,
      md: 12
    }, "Bin Location"), React.createElement(Grid, {
      className: classes.blackLeftContent,
      item: true,
      md: 12
    }, data.binLocation))), React.createElement(Grid, {
      item: true,
      md: 12,
      className: classes.ContentBorder
    }));
  })) : React.createElement(Grid, {
    container: true,
    justifyContent: "center",
    className: classes.noContentBar
  }, React.createElement("span", null, React.createElement("b", null, "Please Scan bin location")))))), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "left"
  }, "Back")), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(GreyButton, {
    onClick: function onClick() {},
    type: "right"
  }, "Add"))));
};
InventoryCountingBinLocations.defaultProps = {
  scanContentBarText: "Please scan wh/bin..."
};
export default React.memo(InventoryCountingBinLocations);