// React libraries
import React from 'react'; // Component Style

import { useStyles } from './CreatableStyles'; // Assets

import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Close } from '../../icons';
export var Creatable = function Creatable(props) {
  var disabled = props.disabled,
      style = props.style,
      initialValue = props.initialValue,
      setInitialValue = props.setInitialValue,
      placeholder = props.placeholder,
      color = props.color;

  var handleChange = function handleChange(newValue, actionMeta) {
    setInitialValue(newValue);
  };

  var MultiValueRemove = function MultiValueRemove(props) {
    return React.createElement(components.MultiValueRemove, Object.assign({}, props), React.createElement(Close, null));
  };

  return React.createElement("div", {
    style: style
  }, React.createElement(CreatableSelect, {
    isMulti: true,
    components: {
      MultiValueRemove: MultiValueRemove
    },
    onChange: handleChange,
    isDisabled: disabled,
    defaultValue: initialValue,
    placeholder: placeholder,
    styles: useStyles(color)
  }));
};
Creatable.defaultProps = {
  disabled: false,
  placeholder: "Select...",
  color: "grey"
};
export default React.memo(Creatable);