import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React Lib
import React, { useState, useEffect } from "react"; // Component Styles

import { useStyles } from "./DrawerStyles"; // @otg-one/ui_components/components

import { Typography } from "../index"; // @otg-one/ui_components/constants

import { FONT_FAMILY, Color } from "../../constants"; // Material UI Core

import Collapse from '@material-ui/core/Collapse';
import Tooltip from "../Tooltip";
export var timeout = function timeout(ms) {
  return new Promise(function (resolve) {
    return setTimeout(resolve, ms);
  });
};
export var Drawer = function Drawer(props) {
  var classes = useStyles(props);

  var _useState = useState({}),
      _useState2 = _slicedToArray(_useState, 2),
      activeMenu = _useState2[0],
      setActiveMenu = _useState2[1];

  var _useState3 = useState({}),
      _useState4 = _slicedToArray(_useState3, 2),
      activeSubmenu = _useState4[0],
      setActiveSubmenu = _useState4[1];

  var _useState5 = useState({}),
      _useState6 = _slicedToArray(_useState5, 2),
      activeThirdSubmenu = _useState6[0],
      setActiveThirdSubmenu = _useState6[1];

  var id = props.id,
      activeOption = props.activeOption,
      menus = props.menus,
      isScrolling = props.isScrolling,
      setIsScrolling = props.setIsScrolling;
  useEffect(function () {
    if (activeOption !== "" && !isScrolling) {
      menus.forEach(function (menu) {
        menu.submenus.forEach(function (submenu) {
          if (submenu.id === activeOption) {
            setActiveMenu(menu);
            setActiveSubmenu(submenu);
            setActiveThirdSubmenu({});
            var submenuElement = document.getElementById(submenu.label);
            var topPosSubmenu = submenuElement.offsetTop;
            document.getElementById("".concat(menu.id, "-scrolling-submenu-container")).scrollTo({
              top: topPosSubmenu,
              behavior: 'smooth'
            });
          }

          submenu.submenus.forEach(function (thirdSubmenu) {
            if (thirdSubmenu.id === activeOption) {
              setActiveMenu(menu);
              setActiveSubmenu(submenu);
              setActiveThirdSubmenu(thirdSubmenu);

              var _submenuElement = document.getElementById(submenu.label);

              var thirdSubmenuContainer = document.getElementById("".concat(submenu.label, "-third-submenu-container"));
              var thirdSubmenuElement = document.getElementById(thirdSubmenu.label);
              var topPosSubmenuElement = _submenuElement.offsetTop;
              var topPosThirdSubmenuContainer = thirdSubmenuContainer.offsetTop;
              var topPosThirdSubmenu = thirdSubmenuElement.offsetTop;
              document.getElementById("".concat(menu.id, "-scrolling-submenu-container")).scrollTo({
                top: topPosSubmenuElement + topPosThirdSubmenuContainer + topPosThirdSubmenu,
                behavior: 'smooth'
              });
            }
          });
        });
      });
    }
  }, [activeOption]);

  var getPosition = function getPosition(menu, submenu, type) {
    // console.log(menu)
    // console.log(submenu)
    // console.log(type)
    if (typeof menu !== 'undefined' && typeof submenu !== 'undefined') {
      var submenuContainer = document.getElementById("".concat(menu.label, "-").concat(type, "-container"));
      var submenuElement = document.getElementById(submenu.label); // const verticalPos = document.getElementById(`${submenu.label}-third-submenu-vertical-position`)

      var top = 18;

      if (submenuContainer && submenuElement) {
        // if (submenu.id !== activeOption) {
        //     verticalPos.style.visibility = 'hidden'
        // }
        // else {
        //     verticalPos.style.visibility = 'visible'
        // }
        var containerBound = submenuContainer.getBoundingClientRect();
        var submenuBound = submenuElement.getBoundingClientRect();
        top = submenuBound.top - containerBound.top + 4;
      }

      return top;
    }
  };

  var handleThirdSubmenuOnClick = function handleThirdSubmenuOnClick(submenu, thirdSubmenu) {
    return function (e) {
      e.stopPropagation(); // console.log("3rd", thirdSubmenu)

      setIsScrolling(true);
      setActiveSubmenu(submenu);
      setActiveThirdSubmenu(thirdSubmenu);
      var link = document.getElementById("link-drawer");

      if (link) {
        link.href = "#".concat(thirdSubmenu.id);
        link.click();
      }
    };
  };

  var handleSubmenuOnClick = function handleSubmenuOnClick(submenu) {
    return function (e) {
      e.stopPropagation(); // console.log("2nd", submenu)

      setIsScrolling(true);
      setActiveSubmenu(submenu);
      setActiveThirdSubmenu({});
      var link = document.getElementById("link-drawer");

      if (link) {
        link.href = "#".concat(submenu.id);
        link.click();
      }
    };
  };

  var handleMenuOnClick = function handleMenuOnClick(menu) {
    return function () {
      setIsScrolling(true);
      setActiveMenu(menu);
      setActiveSubmenu(menu.submenus[0]);
      setActiveThirdSubmenu({});
      document.getElementById("".concat(menu.id, "-scrolling-submenu-container")).scrollTo({
        top: 0
      });
    };
  }; // calculate max sub menu height


  var subMenuHeight = 160;
  menus.forEach(function (menu) {
    subMenuHeight += 70;
  });
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: classes.drawerContainer
  }, menus.map(function (menu) {
    return React.createElement("div", {
      className: classes.drawerMenu
    }, menu.label !== "" && React.createElement(React.Fragment, null, React.createElement("a", {
      style: {
        textDecoration: "none"
      },
      href: "#".concat(menu.submenus[0].id)
    }, React.createElement("div", {
      className: classes.drawerMenuLabel,
      onClick: handleMenuOnClick(menu)
    }, React.createElement(Typography, {
      fontFamily: menu.label === activeMenu.label ? FONT_FAMILY.ROBOTO_BOLD : FONT_FAMILY.ROBOTO_REGULAR,
      size: 16,
      color: menu.label === activeMenu.label ? Color.neutral[900] : Color.neutral[400],
      style: {
        marginLeft: 8
      }
    }, menu.label))), React.createElement(React.Fragment, null, menu.isCustomized && React.createElement(Tooltip, {
      title: "Customized",
      placement: "right"
    }, React.createElement("div", {
      className: classes.drawerMenuIsCustomizedTag
    })))), React.createElement(Collapse, {
      in: menu.label === activeMenu.label
    }, React.createElement("div", {
      id: "".concat(menu.id, "-scrolling-submenu-container"),
      style: {
        overflow: "auto",
        maxHeight: "calc(100vh - " + subMenuHeight + "px)"
      }
    }, React.createElement("div", {
      id: "".concat(menu.label, "-submenu-container"),
      className: classes.drawerSubmenuContainer,
      style: {
        marginTop: menu.label === "" ? 0 : 16
      }
    }, React.createElement("div", {
      className: classes.drawerSubmenuVerticalBar
    }), React.createElement("div", {
      className: classes.drawerSubmenuVerticalPosition,
      style: {
        top: getPosition(activeMenu, activeSubmenu, 'submenu')
      }
    }), menu.submenus.map(function (submenu, index) {
      return React.createElement("div", {
        className: classes.drawerSubmenu,
        onClick: handleSubmenuOnClick(submenu),
        style: submenu.submenus.length > 0 && submenu.renderSubmenu ? {
          paddingBottom: 0
        } : {}
      }, React.createElement("div", {
        id: submenu.label,
        className: classes.drawerSubmenuLabel
      }, React.createElement(Typography, {
        fontFamily: submenu.label === activeSubmenu.label ? FONT_FAMILY.ROBOTO_BOLD : FONT_FAMILY.ROBOTO_REGULAR,
        size: 16,
        color: submenu.label === activeSubmenu.label ? Color.neutral[900] : Color.neutral[400],
        style: {
          marginLeft: 60
        }
      }, submenu.label), submenu.isCustomized && React.createElement(Tooltip, {
        title: "Customized",
        placement: "right"
      }, React.createElement("div", {
        className: classes.drawerSubmenuIsCustomizedTag
      })), submenu.submenus.length > 0 && React.createElement(Collapse, {
        in: submenu.renderSubmenu
      }, React.createElement("div", {
        id: "".concat(submenu.label, "-third-submenu-container"),
        className: classes.drawerThirdSubmenuContainer
      }, React.createElement("div", {
        className: classes.drawerThirdSubmenuVerticalBar
      }), React.createElement("div", {
        id: "".concat(submenu.label, "-third-submenu-vertical-position"),
        className: classes.drawerThirdSubmenuVerticalPosition,
        style: {
          top: getPosition(activeSubmenu, activeThirdSubmenu, 'third-submenu'),
          visibility: submenu.id === activeSubmenu.id && activeThirdSubmenu.id === activeOption ? 'visible' : 'hidden'
        }
      }), submenu.submenus.map(function (thirdSubmenu, index) {
        return React.createElement(Collapse, {
          in: thirdSubmenu.isShown
        }, React.createElement("div", {
          className: classes.drawerThirdSubmenu,
          onClick: handleThirdSubmenuOnClick(submenu, thirdSubmenu)
        }, React.createElement("div", {
          id: thirdSubmenu.label,
          className: classes.drawerSubmenuLabel
        }, React.createElement(Typography, {
          fontFamily: thirdSubmenu.label === activeThirdSubmenu.label ? FONT_FAMILY.ROBOTO_BOLD : FONT_FAMILY.ROBOTO_REGULAR,
          size: 16,
          color: thirdSubmenu.label === activeThirdSubmenu.label ? Color.neutral[900] : Color.neutral[400],
          style: {
            marginLeft: 80
          }
        }, thirdSubmenu.label), thirdSubmenu.isCustomized && React.createElement(Tooltip, {
          title: "Customized",
          placement: "right"
        }, React.createElement("div", {
          className: classes.drawerThirdSubmenuIsCustomizedTag
        })))));
      })))));
    })))));
  })), React.createElement("a", {
    id: "link-drawer",
    style: {
      visibility: "hidden"
    }
  }));
};
Drawer.defaultProps = {
  menus: [{
    id: "menu1",
    label: "Menu 1",
    isCustomized: false,
    submenus: [{
      label: "Submenu 1",
      isCustomized: true,
      submenus: [{
        label: "Submenu 1.1",
        isCustomized: true
      }]
    }, {
      label: "Submenu 2",
      isCustomized: true,
      submenus: [{
        label: "Submenu 2.1",
        isCustomized: false
      }, {
        label: "Submenu 2.2",
        isCustomized: false
      }]
    }]
  }]
};
export default React.memo(Drawer);