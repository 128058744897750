// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    drawerContainer: {
      width: "100%"
    },
    drawerMenu: {
      position: "relative",
      marginTop: 32
    },
    drawerMenuLabel: {
      cursor: "pointer"
    },
    drawerSubmenuContainer: {
      position: "relative",
      width: "100%",
      marginTop: 16,
      overflow: "auto"
    },
    drawerThirdSubmenuContainer: {
      position: "relative",
      width: "100%",
      marginTop: 16
    },
    drawerSubmenu: {
      boxSizing: "border-box",
      paddingTop: 14,
      paddingBottom: 14,
      backgroundColor: Color.neutral[0],
      cursor: "pointer",
      "&:hover": {
        background: Color.neutral[100]
      }
    },
    drawerThirdSubmenu: {
      boxSizing: "border-box",
      paddingTop: 14,
      paddingBottom: 14,
      backgroundColor: Color.neutral[0],
      cursor: "pointer",
      "&:hover": {
        background: Color.neutral[100]
      }
    },
    drawerSubmenuLabel: {
      position: "relative"
    },
    drawerSubmenuVerticalBar: {
      position: "absolute",
      top: 18,
      left: 40,
      bottom: 18,
      marginRight: 16,
      borderLeft: "2px solid ".concat(Color.neutral[200]),
      zIndex: 100000
    },
    drawerSubmenuVerticalPosition: {
      height: 16,
      position: "absolute",
      left: 40,
      marginRight: 16,
      transition: "all 0.2s linear",
      borderLeft: "2px solid ".concat(Color.neutral[700]),
      zIndex: 100001
    },
    drawerThirdSubmenuVerticalBar: {
      position: "absolute",
      top: 18,
      left: 60,
      bottom: 18,
      marginRight: 16,
      borderLeft: "2px solid ".concat(Color.neutral[200]),
      zIndex: 100000
    },
    drawerThirdSubmenuVerticalPosition: {
      height: 16,
      position: "absolute",
      left: 60,
      marginRight: 16,
      transition: "all 0.2s linear",
      borderLeft: "2px solid ".concat(Color.neutral[700]),
      zIndex: 100001
    },
    drawerMenuIsCustomizedTag: {
      position: "absolute",
      top: 0,
      left: 0,
      width: 8,
      height: 8,
      background: Color.alert[700],
      borderRadius: "50%"
    },
    drawerSubmenuIsCustomizedTag: {
      position: "absolute",
      top: 0,
      left: 50,
      width: 8,
      height: 8,
      background: Color.alert[700],
      borderRadius: "50%"
    },
    drawerThirdSubmenuIsCustomizedTag: {
      position: "absolute",
      top: 0,
      left: 70,
      width: 8,
      height: 8,
      background: Color.alert[700],
      borderRadius: "50%"
    }
  });
});