// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./MenuPageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { BlueButton } from "../../index";
export var MenuPage = function MenuPage(props) {
  var classes = useStyles(props);
  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      headerTitle = props.headerTitle,
      data = props.data;
  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "small",
    showScanner: false,
    previewHeight: previewHeight
  }, React.createElement("p", null, headerTitle))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentNormal,
    style: {
      height: previewHeight - 75 - 50 + 10
    }
  }, React.createElement(Grid, {
    container: true,
    spacing: 3
  }, Object.values(data).map(function (data) {
    return React.createElement(Grid, {
      item: true,
      md: 12
    }, React.createElement(BlueButton, null, data.name));
  }))), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(GreyButton, null, "Cancel"))));
};
MenuPage.defaultProps = {
  onClick: function onClick() {}
};
export default React.memo(MenuPage);