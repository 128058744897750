// React lib
import React, { useState, createContext, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { AppContext } from '../../../App';

// Components
import ConfigurationContainer from '../ConfigurationContainer';
import SettingsForInventoryTransfer from './SettingsForInventoryTransfer';

import { FunctionInventoryTransfer, EnterItemNumberPage, BatchesPage, InventoryTransferPositionDetailsPage, BreadcrumbType, NoPreviewPage, SerialNumberType } from '@otg-one/ui_components';
import { SETTINGS_FOR_INVENTORY_TRANSFER, SELECT_SOURCE_WAREHOUSE, USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM, SERIAL_NUMBER_TYPE } from './InventoryTransferIds';
import { STACK_TYPE_SYSTEM, STACK_TYPE_APP_FUNCTION } from '../../../Constants/StackType';

// Models
import { Stack } from '../../../Models/Stack';
import { InventoryTransfer } from '../../../Models/InventoryTransfer'

// Service
import ConfigurationService from "../ConfigurationService";

// Utils
import { inventoryTransferLocalMapToDatabaseValue, arraysMatch, arrayAdjustmentDefault, inventoryTransferDatabaseMapToLocalValue } from "../../../Utils/Utils"

export const InventoryTransferContext = createContext<any>(null);
const InventoryTransferContextProvider = InventoryTransferContext.Provider;

// Interface
export interface IInventoryTransferContextInterface {
    appData: any,
    defaultFunctionType: any,
    activeOption: string,
    setActiveOption: any,
    allowAdditionalBinLocations: boolean,
    handleChangeAllowAdditionalBinLocations: any,
    selectSourceWarehouse: string[],
    handleChangeSelectSourceWarehouse: any,
    useOneBatchWithinOneStorageBinForAnItem: string,
    handleChangeUseOneBatchWithinOneStorageBinForAnItem: any,
    serialNumberType: string,
    handleChangeSerialNumberType: any
}

const InventoryCountingConfiguration = (props: any) => {
    const history = useHistory();
    const {
        breadcrumb,
        currentUserData,
        getCurrentBreadcrumbData,
        handleShowSnackbar,
        setSnackbarMessage
    } = useContext(AppContext);

    const [appData, setAppData] = useState(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION));
    const [defaultFunctionType, setDefaultFunctionType] = useState(null as any);

    const [isLoaded, setIsLoaded] = useState(false)
    const [isModified, setIsModified] = useState(false)
    const [listError, setListError] = useState([] as string[])

    const [activeOption, setActiveOption] = useState("")
    const [activeOptionName, setActiveOptionName] = useState("")

    const [activePreview, setActivePreview] = useState("" as any)

    const [allowAdditionalBinLocations, setAllowAdditionalBinLocations] = useState(false)
    const [selectSourceWarehouse, setSelectSourceWarehouse] = useState(["textInput"])
    const [useOneBatchWithinOneStorageBinForAnItem, setUseOneBatchWithinOneStorageBinForAnItem] = useState(`disable`)

    const [serialNumberType, setSerialNumberType] = useState('SER')

    const loadDefault = async () => {
        const response = await ConfigurationService.getFunctionType(currentUserData.key, appData.functionTypeId);
        setDefaultFunctionType(response.data);
    }

    const setConfiguration = (masterData: any) => {
        if (masterData.config) {
            Object.keys(masterData.config).map((key: any, index: any) => {
                if (key === "selectWarehouseInput") {
                    setSelectSourceWarehouse(inventoryTransferDatabaseMapToLocalValue("selectWarehouseInput", masterData.config[key]));
                } else if (key === "allowOneBatchForOneBinLoc") {
                    setUseOneBatchWithinOneStorageBinForAnItem(inventoryTransferDatabaseMapToLocalValue("allowOneBatchForOneBinLoc", masterData.config[key]));
                } else if (key === "serialType") {
                    setSerialNumberType(inventoryTransferDatabaseMapToLocalValue("serialType", masterData.config[key]))
                }
            });
        }
    };

    React.useEffect(() => {
        setAppData(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION));
    }, [breadcrumb]);

    React.useEffect(() => {
        if (Object.keys(appData).length !== 0) {
            loadDefault();

            new Promise((resolve, reject) => {
                setConfiguration(appData);

                resolve(true)
            }).then(res => {
                setIsLoaded(true);
            })
        }
    }, [appData]);

    const TransferMenus = [
        {
            id: SETTINGS_FOR_INVENTORY_TRANSFER,
            label: "",
            isCustomized: false,
            submenus: [
                {
                    id: SELECT_SOURCE_WAREHOUSE,
                    label: "Select Source Warehouse",
                    isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.selectWarehouseInput, inventoryTransferLocalMapToDatabaseValue("selectWarehouseInput", selectSourceWarehouse) as string[])) ? true : false,
                    submenus: []
                },
                {
                    id: USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM,
                    label: "Use One Batch Within One Storage Bin For an Item",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.allowOneBatchForOneBinLoc !== inventoryTransferLocalMapToDatabaseValue("allowOneBatchForOneBinLoc", useOneBatchWithinOneStorageBinForAnItem)) ? true : false,
                    submenus: []
                },
                {
                    id: SERIAL_NUMBER_TYPE,
                    label: "Serial Number Type",
                    isCustomized: (defaultFunctionType && defaultFunctionType.config.serialType !== inventoryTransferLocalMapToDatabaseValue("serialType", serialNumberType)) ? true : false,
                    renderSubmenu: false,
                    submenus: []
                }
            ]
        }
    ]

    const handleClickSaveConfiguration = () => {
        const tempAppData = { ...appData };

        //to avoid different position number in the object - for customized in system dashboard page
        tempAppData.config = {}

        Object.keys(defaultFunctionType.config).map((key: any, index: any) => {
            if (key === "selectWarehouseInput") {
                tempAppData.config.selectWarehouseInput = arrayAdjustmentDefault(inventoryTransferLocalMapToDatabaseValue("selectWarehouseInput", selectSourceWarehouse), defaultFunctionType.config.selectWarehouseInput);
            } else if (key === "allowOneBatchForOneBinLoc") {
                tempAppData.config.allowOneBatchForOneBinLoc = inventoryTransferLocalMapToDatabaseValue("allowOneBatchForOneBinLoc", useOneBatchWithinOneStorageBinForAnItem);
            } else if (key === "serialType") {
                tempAppData.config.serialType = inventoryTransferLocalMapToDatabaseValue("serialType", serialNumberType);
            } else {
                tempAppData.config[key] = defaultFunctionType.config[key];
            }
        })

        return ConfigurationService.saveConfiguration(currentUserData.key, tempAppData.key, tempAppData).then((res: any) => {
            setIsModified(false);
            setSnackbarMessage(`${breadcrumb[breadcrumb.length - 2].data.name} - ${appData.name} Configuration has been changed`)
            handleShowSnackbar();
        });
    }

    React.useEffect(() => {
        if (isLoaded) {
            setIsModified(true);
        }
    }, [selectSourceWarehouse, useOneBatchWithinOneStorageBinForAnItem, serialNumberType]);

    React.useEffect(() => {
        if (isLoaded) {
            if (selectSourceWarehouse.length <= 0) {
                setListError([...listError, SELECT_SOURCE_WAREHOUSE]);
            } else {
                setListError(listError.filter((event) => (event !== SELECT_SOURCE_WAREHOUSE)));
            }
        }
    }, [selectSourceWarehouse]);


    const handleChangeAllowAdditionalBinLocations = () => {
        setAllowAdditionalBinLocations(prev => !prev)
    }

    const handleChangeSelectSourceWarehouse = (e: any) => {
        if (selectSourceWarehouse.includes(e.target.value)) {
            setSelectSourceWarehouse(selectSourceWarehouse.filter((event) => (event !== e.target.value)));
        } else {
            setSelectSourceWarehouse([...selectSourceWarehouse, e.target.value]);
        }
    }

    const handleChangeUseOneBatchWithinOneStorageBinForAnItem = (e: any) => {
        setUseOneBatchWithinOneStorageBinForAnItem(e.target.value)
    }

    const handleChangeSerialNumberType = (e: any) => {
        setSerialNumberType(e.target.value)
    }

    const handleChangeActiveOption = (id: string) => {
        let element = document.getElementById(id)

        if (element) {
            const bounding = element.getBoundingClientRect()

            if (
                bounding.top >= 0 &&
                bounding.top <= 400 &&
                bounding.left >= 0 &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            ) {
                if (id === SETTINGS_FOR_INVENTORY_TRANSFER) {
                    setActiveOption(SELECT_SOURCE_WAREHOUSE)
                }
                else {
                    setActiveOption(id)
                }
            }
        }
    }

    const handleActivePreview = (id: string) => {

        const inventoryTransferDetailPosition = {
            item: "LM4029PH",
            description: "LeMon 4029 Printhead",
            coveredQuantity: 0,
            coveredQuantityType: "Palette(n)",
            fromWarehouse: "02"
        }

        switch (id) {
            case SELECT_SOURCE_WAREHOUSE:
                if (selectSourceWarehouse.length > 0) {
                    return setActivePreview(<EnterItemNumberPage data={{ inputMessage: "Please enter the warehouse or bin location", barcodeMessage: "Please scan the barcode of the warehouse or bin location" }} headerTitle={"Inventory Transfer"} inputTypes={selectSourceWarehouse} previewHeight={568} previewWidth={320} />);
                } else {
                    return setActivePreview(<NoPreviewPage type={"emptyValue"} previewHeight={568} previewWidth={320} />);
                }
            case USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM:
                if (useOneBatchWithinOneStorageBinForAnItem) {
                    return setActivePreview(<InventoryTransferPositionDetailsPage data={inventoryTransferDetailPosition} previewHeight={568} previewWidth={320} />)
                } else {
                    return setActivePreview(<BatchesPage oneBatchOnly={true} totalBatches={8} bbdInputFieldType={"inputField"} bbdType={"noBbd"} data={[]} previewHeight={568} previewWidth={320} />)
                }
            case SERIAL_NUMBER_TYPE:
                return setActivePreview(<SerialNumberType totalSerialNumbers={1} value={serialNumberType} data={[]} previewHeight={568} previewWidth={320} />)
            default:
                return setActivePreview(id);
        }
    }

    React.useEffect(() => {
        handleActivePreview(activeOption);

        TransferMenus.forEach(menu => {
            menu.submenus.forEach((subMenu: any) => {
                if (subMenu.id === activeOption) {
                    setActiveOptionName(subMenu.label)
                }

                subMenu.submenus.forEach((thirdSubmenu: any) => {
                    if (thirdSubmenu.id === activeOption) {
                        setActiveOptionName(thirdSubmenu.label)
                    };
                })
            });
        })

    }, [activeOption, selectSourceWarehouse, useOneBatchWithinOneStorageBinForAnItem, serialNumberType]);

    const viewPortCallback = () => {
        handleChangeActiveOption(SETTINGS_FOR_INVENTORY_TRANSFER)
        handleChangeActiveOption(SELECT_SOURCE_WAREHOUSE)
        handleChangeActiveOption(USE_ONE_BATCH_WITHIN_ONE_STORAGE_BIN_FOR_AN_ITEM)
        handleChangeActiveOption(SERIAL_NUMBER_TYPE)
    }

    const CountingContextValue: IInventoryTransferContextInterface = {
        appData: appData,
        defaultFunctionType: defaultFunctionType,
        activeOption: activeOption,
        setActiveOption: handleChangeActiveOption,
        allowAdditionalBinLocations,
        handleChangeAllowAdditionalBinLocations,
        selectSourceWarehouse,
        handleChangeSelectSourceWarehouse,
        useOneBatchWithinOneStorageBinForAnItem,
        handleChangeUseOneBatchWithinOneStorageBinForAnItem,
        serialNumberType: serialNumberType,
        handleChangeSerialNumberType: handleChangeSerialNumberType
    };

    return (
        <ConfigurationContainer
            detectViewportCallback={viewPortCallback}
            activeViewport={activePreview}
            activeOption={activeOption}
            activeOptionName={activeOptionName}
            title={`Inventory Transfer`}
            icon={<FunctionInventoryTransfer />}
            sidebarMenus={TransferMenus}
            saveConfiguration={handleClickSaveConfiguration}
            setIsModified={setIsModified}
            isModified={isModified}
            setListError={setListError}
            listError={listError}
            setConfigurationToDefault={() => setConfiguration(defaultFunctionType)}
            setConfigurationToPreviousSetting={() => setConfiguration(getCurrentBreadcrumbData(STACK_TYPE_APP_FUNCTION))}
        >
            <InventoryTransferContextProvider value={CountingContextValue}>
                <SettingsForInventoryTransfer />
            </InventoryTransferContextProvider>
        </ConfigurationContainer>
    )
};

export default InventoryCountingConfiguration;