export const LOGIN = '/login'
export const REGISTER = '/register'

export const PARTNER_DASHBOARD = '/partnerDashboard'
export const CLIENT_DASHBOARD = '/clientDashboard'
export const SYSTEM_DASHBOARD = '/systemDashboard'

export const CUSTOMER_USAGE_DASHBOARD = '/customerUsageDashboard'

export const ORGANIZATION = '/organization'
export const BILLING = '/billing'

export const CREATENEWCLIENT = '/createNewClient'
export const CREATENEWUSER = '/createNewUser'

export const SYSTEM_CONFIGURATION = '/systemConfiguration'

export const ACCOUNT_SETTINGS = '/accountSettings'
export const CHANGE_PASSWORD = '/changePassword'

export const GOODS_RECEIPT_CONFIGURATION = '/goodsReceiptConfiguration'
export const DELIVERY_CONFIGURATION = '/deliveryConfiguration'
export const PICK_AND_PACK_CONFIGURATION = '/pickAndPackConfiguration'
export const INVENTORY_STATUS_CONFIGURATION = '/inventoryStatusConfiguration'
export const INVENTORY_COUNTING_CONFIGURATION = '/inventoryCountingConfiguration'
export const INVENTORY_TRANSFER_CONFIGURATION = '/inventoryTransferConfiguration'
export const RECEIPT_FROM_PRODUCTION = '/receiptFromProduction'
export const ISSUE_FROM_PRODUCTION_CONFIGURATION = '/issueFromProductionConfiguration'
