import _slicedToArray from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
// React libraries
import React from "react"; // Custom Styles

import { useStyles } from "./NewPositionAddablePageStyles"; // Material-UI Core

import Grid from '@material-ui/core/Grid';
import { BlueGradientHeader } from "../../index";
import { PageWrapper } from "../../index";
import { GreyButton } from "../../index";
import { WhiteContentBarButton } from "../../index";
import { BlueLink } from "../../index";
import { KeyboardCompleteModal } from "../../index"; // Icon

import Barcode from "../../../icons/Barcode/Barcode";
import Search from "../../../icons/Search/Search";
export var NewPositionAddablePage = function NewPositionAddablePage(props) {
  var classes = useStyles(props);

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      isKeyboardItemNumberModalOpen = _React$useState2[0],
      setIsKeyboardItemNumberModalOpen = _React$useState2[1];

  var _React$useState3 = React.useState(""),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      itemNumber = _React$useState4[0],
      setItemNumber = _React$useState4[1];

  var previewWidth = props.previewWidth,
      previewHeight = props.previewHeight,
      inputTypes = props.inputTypes;
  var barcodeContainerWrapperHeight;
  var barcodeContainerHeight;
  var showScanner;
  var textInputHeight;
  var itemsSearchHeight;

  if (inputTypes.includes("textInput")) {
    textInputHeight = 40;
  } else {
    textInputHeight = 0;
  }

  if (inputTypes.includes("itemsSearch")) {
    itemsSearchHeight = 40;
  } else {
    itemsSearchHeight = 0;
  }

  if (inputTypes.includes("barcodeScan")) {
    showScanner = true;
    barcodeContainerHeight = 130;
  } else {
    showScanner = false;
    barcodeContainerHeight = 40;
  }

  barcodeContainerWrapperHeight = previewHeight - 90 - 50 - textInputHeight - itemsSearchHeight + 10 - 20; // Close Dialog On Option Change

  React.useEffect(function () {
    setIsKeyboardItemNumberModalOpen(false);
  }, [inputTypes]);
  return React.createElement(PageWrapper, {
    previewHeight: previewHeight,
    previewWidth: previewWidth
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueGradientHeader, {
    type: "smallWithSub",
    showScanner: showScanner,
    previewHeight: previewHeight
  }, React.createElement("p", null, "Add New Row"), React.createElement("sub", null, "Business Tool AG - ", React.createElement("b", null, "2352")))), React.createElement(Grid, {
    item: true,
    md: 12,
    className: classes.whiteContentTopRadius,
    style: {
      height: previewHeight - 90 - 50 + 10
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement("div", {
    className: classes.barcodeContainerWrapper,
    style: {
      height: barcodeContainerWrapperHeight
    }
  }, React.createElement("div", {
    className: classes.barcodeContainer,
    style: {
      marginLeft: previewWidth / 2 * -1,
      marginTop: barcodeContainerHeight / 2 * -1,
      height: barcodeContainerHeight
    }
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    md: 3
  }), React.createElement(Grid, {
    item: true,
    md: 6
  }, React.createElement(Grid, {
    container: true
  }, inputTypes.includes("barcodeScan") ? React.createElement(React.Fragment, null, React.createElement(Grid, {
    item: true,
    md: 12
  }, "Please scan the barcode of the order"), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(Barcode, {
    className: classes.scannerIcon
  }))) : React.createElement(Grid, {
    item: true,
    md: 12
  }, "Please scan the purchase order number"))), React.createElement(Grid, {
    item: true,
    md: 3
  }))))), inputTypes.includes("textInput") && React.createElement(Grid, {
    container: true,
    className: classes.whiteContentBarContainer
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(WhiteContentBarButton, {
    backgroundColor: "grey",
    data: {
      leftContent: "Enter Manually",
      leftContentWithRightContent: "Enter Manually",
      rightContent: itemNumber
    },
    onClick: function onClick() {
      setIsKeyboardItemNumberModalOpen(!isKeyboardItemNumberModalOpen);
    }
  }))), inputTypes.includes("itemsSearch") && React.createElement(Grid, {
    container: true,
    className: classes.whiteContentBarContainer
  }, React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(BlueLink, {
    rightIcon: Search
  }, "Search for Items"))))), React.createElement(Grid, {
    item: true,
    md: 12
  }, React.createElement(GreyButton, null, "Cancel"))), React.createElement(KeyboardCompleteModal, {
    title: "Enter Item Number",
    isModalOpen: isKeyboardItemNumberModalOpen,
    initialValue: itemNumber,
    setInitialValue: setItemNumber,
    setOpenModalState: setIsKeyboardItemNumberModalOpen
  }));
};
export default React.memo(NewPositionAddablePage);