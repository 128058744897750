// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    popoverRoot: {
      zIndex: 12000 + " !important"
    },
    menuRoot: {
      overflow: "visible",
      borderRadius: 0,
      boxShadow: "0px 2px 29px 0px rgba(3, 3, 3, 0.15)",
      marginTop: 50,
      width: 180
    },
    menuList: {
      padding: 0
    },
    menuItem: {
      height: 36,
      fontFamily: "roboto-light",
      fontSize: 12,
      color: Color.neutral[900],
      cursor: "pointer",
      display: "flex",
      overflow: "visible",
      "& a": {
        color: Color.neutral[900]
      },
      "&:hover": {
        backgroundColor: Color.neutral[100]
      }
    },
    menuItemText: {
      alignItems: "center",
      display: "flex"
    },
    menuItemCheckIcon: {
      minWidth: 0,
      marginTop: 4,
      marginRight: 8
    }
  });
});