import React from 'react';

// Components
import {
    DialogBox,
    Typography,
    Color,
    FONT_FAMILY,
    Checkmark,
    Edit,
    Delete,
    Separator,
    Plus,
    Button
} from '@otg-one/ui_components';

import Grid from '@material-ui/core/Grid';
import { WarningOutlined } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        deviceText: {
            fontFamily: FONT_FAMILY.ROBOTO_REGULAR,
            fontSize: 14,
            color: Color.neutral[900],
            userSelect: 'none',
            cursor: 'pointer',
            transition: '0.3s all ease',
            '&:hover': {
                color: Color.danger[400],
                transition: '0.3s all ease'
            }
        },
        ignoredDeviceText: {
            fontFamily: FONT_FAMILY.ROBOTO_MEDIUM_ITALIC,
            fontSize: 14,
            color: Color.neutral[300],
            userSelect: 'none'
        }
    })
)

const DeviceCountDetailDialog = (props: any) => {
    const { open, onClose, deviceDetail, ignoreDevice } = props;

    const classes = useStyles()

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            title="Device Detail Information"
            content={
                <div style={{ width: 350, paddingTop: 16, overflow: 'hidden' }}>
                    <Grid container>
                        <Grid item md={5}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[900]}
                            >
                                {`Partner:`}
                            </Typography>
                        </Grid>
                        <Grid item md={7}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                size={14}
                                color={Color.neutral[900]}
                            >
                                {deviceDetail.partner}
                            </Typography>
                        </Grid>

                        <Grid item md={5}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[900]}
                            >
                                {`Client:`}
                            </Typography>
                        </Grid>
                        <Grid item md={7}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                size={14}
                                color={Color.neutral[900]}
                            >
                                {deviceDetail.client}
                            </Typography>
                        </Grid>

                        <Grid item md={5}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[900]}
                            >
                                {`Ontego One ID:`}
                            </Typography>
                        </Grid>
                        <Grid item md={7}>
                            <div
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                <Typography
                                    fontFamily={deviceDetail.ontego_id ? FONT_FAMILY.ROBOTO_REGULAR : FONT_FAMILY.ROBOTO_REGULAR_ITALIC}
                                    size={14}
                                    color={deviceDetail.ontego_id ? Color.neutral[900] : Color.danger[300]}
                                >
                                    {deviceDetail.ontego_id ?? 'Keine ID'}
                                </Typography>
                                {!deviceDetail.ontego_id && (
                                    <WarningOutlined style={{ marginLeft: 6, width: 12, height: 12, color: Color.danger[300] }} />
                                )}
                            </div>
                        </Grid>

                        <Grid item md={5}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                size={14}
                                color={Color.neutral[900]}
                            >
                                {`Device(s):`}
                            </Typography>
                        </Grid>
                        <Grid item md={7}>
                            <Typography
                                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                                size={14}
                                color={Color.neutral[900]}
                            >
                                {deviceDetail.system.devices.length}
                            </Typography>
                        </Grid>

                        <Grid container>
                            <Grid item md={6}>
                                <Typography
                                    fontFamily={FONT_FAMILY.ROBOTO_BOLD}
                                    size={14}
                                    color={Color.neutral[900]}
                                >
                                    {`Device ID:`}
                                </Typography>
                            </Grid>
                        </Grid>

                        <div style={{ marginTop: 25 }} />
                        {deviceDetail.system.devices.map((device: any, index: number) => (
                            <Grid item md={12}>
                                <div style={{ marginTop: 4, marginBottom: 4 }}>
                                    <span
                                        className={device.ignored ? classes.ignoredDeviceText : classes.deviceText}
                                        onClick={device.ignored ? undefined : ignoreDevice(device.device)}
                                    >
                                        {device.device}
                                    </span>
                                </div>
                                {index !== deviceDetail.system.devices.length - 1 && (
                                    <Separator type="n200" />
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </div>
            }
        />
    )
};

export default DeviceCountDetailDialog;