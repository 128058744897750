// React lib
import React, { useContext } from 'react';

// Context
import { InventoryStatusContext } from './InventoryStatusConfiguration';

// Components
import ConfigurationTitle from '../ConfigurationTitle';
import ConfigurationOption from '../ConfigurationOption';

// Constants
import { OPTION_TYPE_RADIO, OPTION_TYPE_CHECKBOX } from '../../../Constants/OptionType';
import { SETTINGS_FOR_INVENTORY_STATUS, VIA_SCAN_OF_ITEM, WAREHOUSE_NAME, ITEM_SELECTION, VIA_SCAN_OF_WAREHOUSE, WAREHOUSE_SELECTION, ENTRY_OPTION } from './InventoryStatusIds';

// Utils
import { inventoryStatusLocalMapToDatabaseValue, arraysMatch } from "../../../Utils/Utils"

const SettingsForEntry = (props: any) => {
    const {
        defaultFunctionType,
        activeOption,
        setActiveOption,
        entryOption,
        handleChangeEntryOption,
        viaScanOfItem,
        handleChangeViaScanOfItem,
        viaScanOfWarehouse,
        handleChangeViaScanOfWarehouse,
        warehouseName,
        handleChangeWarehouseName,
        itemSelection,
        handleChangeItemSelection,
        warehouseSelection,
        handleChangeWarehouseSelection
    } = useContext(InventoryStatusContext);

    const entryOptionOption = {
        id: ENTRY_OPTION,
        heading: '',
        title: `Entry Option*`,
        subtitle: ' ',
        isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.entry, inventoryStatusLocalMapToDatabaseValue("entry", entryOption) as string[])) ? true : false,
        optionType: OPTION_TYPE_CHECKBOX,
        allowEmpty: false,
        isValid: entryOption.length > 0 ? true : false,
        options: [
            {
                value: VIA_SCAN_OF_ITEM,
                onChange: handleChangeEntryOption,
                checked: entryOption.includes(VIA_SCAN_OF_ITEM),
                disabled: false,
                label: `Via Scan of Item`,
                subLabel: `The status of the item is shown for available warehouses after scanning the item.`,
            },
            {
                value: VIA_SCAN_OF_WAREHOUSE,
                onChange: handleChangeEntryOption,
                checked: entryOption.includes(VIA_SCAN_OF_WAREHOUSE),
                disabled: false,
                label: `Via Scan of Warehouse`,
                subLabel: `The status of available items for the warehouse is shown after scanning the warehouse.`,
            }
        ],
        collapsedOption: []
    }

    const warehouseNameOption = {
        id: WAREHOUSE_NAME,
        heading: `SETTINGS FOR ENTRY - VIA SCAN OF ITEM`,
        title: `Warehouse Name`,
        subtitle: `The status of the item is shown for available warehouses after scanning the item`,
        isCustomized: (defaultFunctionType && defaultFunctionType.config.warehouseLayout !== inventoryStatusLocalMapToDatabaseValue("warehouseLayout", warehouseName)) ? true : false,
        optionType: OPTION_TYPE_RADIO,
        allowEmpty: true,
        isValid: true,
        options: [
            {
                label: `Show warehouse name`,
                value: `warehouseName`,
                onChange: handleChangeWarehouseName,
                checked: warehouseName === `warehouseName`,
                disabled: false,
            },
            {
                label: `Show warehouse code`,
                value: `warehouseCode`,
                onChange: handleChangeWarehouseName,
                checked: warehouseName === `warehouseCode`,
                disabled: false,
            }
        ],
        collapsedOption: []
    }

    const itemSelectionOption = {
        id: ITEM_SELECTION,
        heading: `SETTINGS FOR ENTRY - VIA SCAN OF ITEM`,
        title: `Item Selection`,
        subtitle: `How should the item be selected?`,
        isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.selectItemInput, inventoryStatusLocalMapToDatabaseValue("selectItemInput", itemSelection) as string[])) ? true : false,
        optionType: OPTION_TYPE_CHECKBOX,
        allowEmpty: false,
        isValid: itemSelection.length > 0 ? true : false,
        options: [
            {
                label: `Manual text input of the item number`,
                value: `textInput`,
                checked: itemSelection.includes("textInput"),
                onChange: handleChangeItemSelection,
                disabled: false
            },
            {
                label: `Barcode Scan`,
                value: `barcodeScan`,
                checked: itemSelection.includes("barcodeScan"),
                onChange: handleChangeItemSelection,
                disabled: false
            },
            {
                label: `OCR Scan ( Unavailable )`,
                value: `OCR Scan ( Unavailable )`,
                disabled: true
            },
            {
                label: `Items Search`,
                value: `itemsSearch`,
                checked: itemSelection.includes("itemsSearch"),
                onChange: handleChangeItemSelection,
                disabled: false
            }
        ],
        collapsedOption: []
    }

    const warehouseSelectionOption = {
        id: WAREHOUSE_SELECTION,
        heading: `SETTINGS FOR ENTRY - VIA SCAN OF WAREHOUSE`,
        title: `Warehouse Selection`,
        subtitle: `Defines which types of input are available for the user`,
        isCustomized: (defaultFunctionType && !arraysMatch(defaultFunctionType.config.selectWarehouseInput, inventoryStatusLocalMapToDatabaseValue("selectWarehouseInput", warehouseSelection) as string[])) ? true : false,
        optionType: OPTION_TYPE_CHECKBOX,
        allowEmpty: false,
        isValid: warehouseSelection.length > 0 ? true : false,
        options: [
            {
                label: `Manual text input of the warehouse number`,
                value: `textInput`,
                checked: warehouseSelection.includes("textInput"),
                onChange: handleChangeWarehouseSelection,
                disabled: false
            },
            {
                label: `Barcode scan`,
                value: `barcodeScan`,
                checked: warehouseSelection.includes("barcodeScan"),
                onChange: handleChangeWarehouseSelection,
                disabled: false
            },
            {
                label: `OCR scan (Unavailable)`,
                value: `OCR scan (Unavailable)`,
                disabled: true
            },
        ]
    }

    return (
        <>
            <ConfigurationOption configurationOption={entryOptionOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            {entryOption.includes(VIA_SCAN_OF_ITEM) && (
                <>
                    <ConfigurationOption configurationOption={warehouseNameOption} activeOption={activeOption} setActiveOption={setActiveOption} />
                    <ConfigurationOption configurationOption={itemSelectionOption} activeOption={activeOption} setActiveOption={setActiveOption} />
                </>
            )}
            {entryOption.includes(VIA_SCAN_OF_WAREHOUSE) && (
                <ConfigurationOption configurationOption={warehouseSelectionOption} activeOption={activeOption} setActiveOption={setActiveOption} />
            )}
        </>
    );
};

export default SettingsForEntry;