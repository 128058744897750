export const SETTINGS_FOR_ENTRY = `settingsForEntry`
export const TYPE_OF_ORDER_INPUT = `typeOfOrderInput`

export const SETTINGS_FOR_LIST_OF_POSITION = `settingsForListOfPosition`
export const DISPLAYED_COLUMN = `displayedColumn`
export const QUICK_CHECK = `quickCheck`
export const WAREHOUSE_NAME = `warehouseName`
export const COMPLETE_ORDER = `completeOrder`

export const SETTINGS_FOR_POSITION_DETAILS = `settingsForPositionDetails`
export const MORE_OR_LESS_QUANTITIES_ALLOWED = `moreOrLessQuantitiesAllowed`

export const LESS_QUANTITIES_SECURITY_CHECK = `lessQuantitiesSecurityCheck`
export const OVERBOOKING_SECURITY_CHECK = `overbookingSecurityCheck`