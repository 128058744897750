// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
import { SVG_GRADIENT_BLUE } from '../Button/ButtonStyles';
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    userAccountBox: {
      position: "relative",
      boxSizing: "border-box",
      width: 376,
      height: 442,
      paddingBottom: 75,
      backgroundSize: "0% 100%",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "border-box",
      border: "4px solid ".concat(Color.primary[600]),
      borderImage: "url('data:image/svg+xml;base64,".concat(SVG_GRADIENT_BLUE, "') 10% stretch"),
      // backgroundImage: Color.gradient[100],
      backgroundColor: "transparent",
      transition: "all .2s ease-in-out",
      display: "block",
      textAlign: "center",
      textOverflow: "ellipsis",
      "& svg": {
        position: "absolute",
        top: 8,
        right: 12,
        color: Color.primary[500]
      }
    },
    userAccountPicture: {
      display: "inline-block",
      width: 152,
      height: 152,
      borderRadius: "50%",
      backgroundColor: Color.neutral[200],
      marginTop: 70
    }
  });
});