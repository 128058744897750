import _defineProperty from "/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    applicationCardContainer: function applicationCardContainer(props) {
      return _defineProperty({
        position: "relative",
        width: 178,
        height: 173,
        backgroundColor: props.application.isActive === 1 ? Color.primary[400] : Color.neutral[500],
        pointerEvents: props.application.isActive === 1 ? "auto" : "none",
        cursor: props.application.isActive === 1 ? "pointer" : "auto"
      }, theme.breakpoints.down('xs'), {
        width: "auto"
      });
    },
    applicationCard: {
      display: "block",
      textAlign: "center",
      "& svg": {
        marginTop: 55,
        width: 40,
        height: 40,
        color: Color.neutral[0]
      }
    },
    applicationCardShowMoreIcon: {
      position: "absolute",
      top: 16,
      right: 16,
      width: 24,
      height: 24,
      color: Color.neutral[0],
      pointerEvents: "auto",
      cursor: "pointer"
    }
  });
});