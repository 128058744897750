// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    dropdownContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      boxSizing: "border-box",
      padding: "16px 12px 16px 12px",
      width: 180,
      height: 44,
      backgroundColor: Color.neutral[100],
      cursor: "pointer"
    },
    dropdownText: {
      textOverflow: "ellipsis"
    },
    dropdownArrowIcon: {
      color: Color.primary[600],
      width: 24,
      height: 24
    }
  });
});