// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles'; // Constants

import { Color } from "../../constants/Colors";
export var useStyles = makeStyles(function (theme) {
  return createStyles({
    deviceLimitContainer: {
      position: "relative",
      width: "100%",
      height: 435,
      backgroundColor: Color.neutral[100],
      boxSizing: "border-box",
      padding: "16px 24px 16px 24px"
    }
  });
});